// eslint-disable max-len

import * as React from 'react'

const ZLetterIcon = ({ color }: any) => (
  <svg width="20" height="18" viewBox="0 0 20 18">
    <path
      fill={color}
      fillRule="nonzero"
      d="M14.55 11.726L14.222 15H5.116v-.417l6.269-8.654H9.259c-.433 0-.745.012-.937.037a1.866 1.866 0 0 0-.54.154c-.196.073-.412.266-.65.578-.236.312-.553.833-.95 1.562H5.63l.3-2.96h8.436v.417L8.097 14.37h2.44c.766 0 1.286-.03 1.562-.092s.477-.172.605-.332c.26-.314.5-.66.721-1.035.221-.376.425-.772.612-1.186h.513z"
      opacity=".9"
    />
  </svg>
)

export const Z_LETTER_ICON = <ZLetterIcon color="#788596" />
export const Z_LETTER_ICON_LIGHT = <ZLetterIcon color="#FFFFFF" />
