import * as React from 'react'
import { bindBem } from '../../../bem'
import { ADMIN } from '../../../messages'

import { Button } from '../../Button'

import { useFormik } from 'formik'
import { DatePicker, UTCDate } from 'components/DatePicker'

import close from '../../../static/close-dialog.svg'

import './ExtendTokensForm.scss'

export interface IExtendTokenForm {
  isoDate: Date | null
}

export interface IProps {
  organization: IOrganization
  tokens: IToken[]
  onClose: () => void
  onExtend: (tokens: IToken[], values: IExtendTokenForm) => void
}

export const ExtendTokensForm: React.FC<IProps> = props => {
  const { tokens, onClose, onExtend } = props

  const { block, element } = bindBem('ExtendTokensForm')

  const onSubmit = React.useCallback(
    (values: IExtendTokenForm) => {
      onExtend(tokens, values)
      onClose()
    },
    [tokens]
  )

  const formik = useFormik<IExtendTokenForm>({
    initialValues: {
      isoDate: props.organization?.hardTerminationDate ?? new Date(),
    },
    onSubmit,
  })

  const onDateChange = React.useCallback(
    (value: UTCDate | null) => {
      formik.setFieldValue('isoDate', value?.toJSDate() ?? null)
    },
    [formik]
  )

  return (
    <div className={block()}>
      <img className={element('Close')} src={close} onClick={onClose} />
      <div className={element('Title')}>{ADMIN.TOKENS.MODALS.EXTEND.TITLE}</div>
      <div className={element('Content')}>
        <strong>{ADMIN.TOKENS.MODALS.EXTEND.SELECTED}</strong>
        <ul className={element('TokensList')}>
          {tokens.map(token => (
            <li key={token.id}>{token.token}</li>
          ))}
        </ul>
      </div>
      <div className={element('Form')}>
        <div className={element('Row')}>
          <label htmlFor="isoDate">{ADMIN.TOKENS.MODALS.EXTEND.EXTEND_UNTIL}</label>
          <DatePicker
            onChange={onDateChange}
            name="isoDate"
            value={
              formik.values.isoDate
                ? UTCDate.fromISOString(formik.values.isoDate.toISOString())
                : null
            }
          />
        </div>
      </div>
      <div className={element('Bottom')}>
        <Button
          className={element('Extend')}
          onClick={formik.submitForm}
          text={ADMIN.TOKENS.MODALS.EXTEND.BUTTON}
          size="small"
          style="primary"
        />
      </div>
    </div>
  )
}
