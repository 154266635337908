// eslint-disable max-len

import * as React from 'react'

const FLetterIcon = (props: { color: string }) => (
  <svg width="20" height="18" viewBox="0 0 20 18">
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M10.953 6.631l-.942 3.536c-.44 1.67-.869 2.879-1.287 3.629-.419.75-.908 1.305-1.469 1.664-.56.36-1.203.54-1.927.54-.456 0-.792-.083-1.006-.249-.215-.166-.322-.36-.322-.585 0-.21.088-.39.266-.544.177-.153.41-.23.7-.23.241 0 .426.06.555.177a.576.576 0 0 1 .193.444c0 .163-.04.284-.12.363-.081.08-.121.132-.121.157l.04.054c.032.026.07.038.112.038a.608.608 0 0 0 .41-.145c.285-.24.497-.531.636-.873.097-.235.282-.855.556-1.86L8.868 6.63H7.75l.265-.896c.403.006.695-.043.877-.145.183-.102.376-.332.58-.69.59-1.035 1.212-1.778 1.867-2.226A3.783 3.783 0 0 1 13.527 2c.515 0 .89.095 1.123.283.234.19.35.434.35.735 0 .266-.078.476-.233.632a.786.786 0 0 1-.58.233.757.757 0 0 1-.531-.191.6.6 0 0 1-.21-.46c0-.127.045-.262.133-.405.089-.143.133-.24.133-.29a.175.175 0 0 0-.06-.139.228.228 0 0 0-.157-.053c-.317 0-.641.186-.974.559-.547.597-.987 1.541-1.32 2.832h1.167l-.281.896h-1.134V6.63z"
    />
  </svg>
)

const FLetterIconSm = (props: { color: string }) => (
  <svg width="10" height="10" viewBox="0 0 10 10">
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M5.793 3.646l-.514 2.021c-.24.954-.474 1.645-.702 2.073-.229.429-.496.746-.801.952A1.84 1.84 0 0 1 2.724 9c-.248 0-.431-.047-.548-.142A.42.42 0 0 1 2 8.523c0-.12.048-.223.145-.31a.547.547 0 0 1 .382-.131c.131 0 .232.033.303.1.07.067.105.152.105.254 0 .093-.022.162-.066.208-.044.045-.066.075-.066.09l.022.03a.093.093 0 0 0 .062.022.322.322 0 0 0 .223-.083c.156-.137.271-.304.347-.5.053-.133.154-.487.303-1.062l.895-3.495h-.61l.145-.511c.22.003.38-.025.479-.084.1-.058.204-.19.316-.393.321-.592.66-1.017 1.018-1.273A1.998 1.998 0 0 1 7.197 1c.28 0 .485.054.612.162.127.108.191.248.191.42a.5.5 0 0 1-.127.36.419.419 0 0 1-.316.134.402.402 0 0 1-.29-.11.35.35 0 0 1-.114-.262c0-.072.024-.15.072-.231a.438.438 0 0 0 .072-.167.102.102 0 0 0-.032-.078.121.121 0 0 0-.086-.03c-.173 0-.35.106-.531.318-.299.342-.539.881-.72 1.619h.636l-.153.512h-.618z"
    />
  </svg>
)

export const F_LETTER_ICON = <FLetterIcon color="#788596" />
export const F_LETTER_ICON_LIGHT = <FLetterIcon color="#FFFFFF" />
export const F_LETTER_ICON_LIGHT_SM = <FLetterIconSm color="#FFFFFF" />
