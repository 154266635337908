import * as React from 'react'
import { bindBem } from 'bem'

import TickIcon from 'static/tick.svg'

import './SubMenuItem.scss'

export enum Theme {
  NORMAL,
  DANGER,
}

export enum Type {
  NORMAL,
  TICK,
}

export interface IProps {
  text: string
  theme?: Theme
  disabledTooltip?: string
  type?: Type
  selected?: boolean
  disabled?: boolean // used by IconSubMenuOption
  name: string // used by IconSubMenuOption
}

export const SubMenuItem: React.FC<IProps> = ({
  text,
  disabled = false,
  disabledTooltip = '',
  theme = Theme.NORMAL,
  type = Type.NORMAL,
  selected = false,
}) => {
  const { block, element } = bindBem('SubMenuItem')
  return (
    <div
      className={block({
        disabled,
        danger: theme === Theme.DANGER,
      })}
      title={disabled ? disabledTooltip : null}
    >
      <div className={element('Text')}>{text}</div>
      {type === Type.TICK && selected && (
        <div className={element('Tick')}>
          <img src={TickIcon} alt="tick icon" />
        </div>
      )}
    </div>
  )
}
