// eslint-disable max-lines
import * as React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import { bindBem } from '../bem'
import FunctionsContainer from '../containers/FunctionsContainer'
import { SIDEBAR, TOOLTIPS } from '../messages'
import {
  getAggregation,
  getMinFrequency,
  hasTrendLine,
  haveSameFrequency,
  isLogScaleDisabled,
} from '../services/series'
import { TABS } from '../services/sidebar'
import { IRootState } from '../store'
import { ACTIONS as DATABASE_ACTIONS } from '../store/Database'
import { ACTIONS as SERIES_ACTIONS, setZoom, toggleCorrelation } from '../store/Series'
import {
  addAggregation,
  addInterpolation,
  allHaveInterpolation,
  hasAggregation,
  hasInterpolation,
  removeAggregation,
  removeInterpolation,
} from '../store/Transformations'
import { AsideDialog } from './Modal'
import { AggregationDialog } from './Sidebar/AggregationDialog'
import {
  ButtonSeparator,
  CorrelationButton,
  FxButton,
  InterpolationButton,
  LogScaleButton,
  ZoomButton,
} from './Sidebar/Buttons'
import { CollapsedDropdownButton, DropdownButton } from './Sidebar/DropdownButton'
import { GraphTypeDropdown } from './Sidebar/GraphTypeDropdown'
import { ZoomDialog } from './Sidebar/ZoomDialog'

import './MenuToolbar.scss'

export interface IProps {
  isCorrelationEnabled: boolean
  zoom: Zoom
  isCollapsed?: boolean
  modal: SeriesModal
  variables: IDataSeries[]
  graphType: ISeriesType
  aggregation?: Aggregation
  yAxisType: YAxisType
}

export interface IActionProps {
  selectTab: (t: TABS) => void
  setGraphZoom: (zoom: Zoom) => void
  setActiveModal: (m: SeriesModal) => void
  setSeriesType: (type: ISeriesType) => void
  addInterpolationHandler: (
    series: IDataSeries,
    index: number,
    cancellable?: boolean
  ) => void
  removeInterpolationHandler: (
    series: IDataSeries,
    index: number,
    cancellable?: boolean
  ) => void
  addAggregationHandler: (variables: IDataSeries[], aggregation: Aggregation) => void
  removeAggregationHandler: (variables: IDataSeries[]) => void
  toggleCorrelation: (enabled: boolean) => void
  setYAxisType: (type: YAxisType) => void
}

interface IState {
  isFunctionsModalOpen: boolean
}

export class MenuToolbar extends React.Component<IProps & IActionProps, IState> {
  state: IState = { isFunctionsModalOpen: false }

  handleInterpolationClick = () => {
    const { variables, addInterpolationHandler, removeInterpolationHandler } = this.props
    const interpolationActive = allHaveInterpolation(variables)
    interpolationActive
      ? variables.forEach(
          (series, index) =>
            !hasTrendLine(series) &&
            hasInterpolation(series) &&
            removeInterpolationHandler(series, index, false)
        )
      : variables.forEach(
          (series, index) =>
            !hasTrendLine(series) &&
            !hasInterpolation(series) &&
            addInterpolationHandler(series, index, false)
        )
  }

  render() {
    const { block, element } = bindBem('MenuToolbar')
    const { variables, isCollapsed, yAxisType } = this.props
    const { setSeriesType, graphType } = this.props
    const { setActiveModal, aggregation, isCorrelationEnabled } = this.props
    const DropdownComponent = isCollapsed ? CollapsedDropdownButton : DropdownButton
    const interpolationActive = allHaveInterpolation(variables)
    const noVariables = variables.length === 0
    const seriesActionsDisabled = noVariables ? 'disabled' : ''
    const logScaleDisabled = isLogScaleDisabled(yAxisType, variables)

    return (
      <div className={`${block({ isCollapsed })} row between-xs middle-xs`}>
        <div className={`${element('LeftButtons')} row middle-xs center-xs`}>
          <GraphTypeDropdown
            onChange={setSeriesType}
            value={graphType}
            stackedBarDisabled={
              !haveSameFrequency(variables) || this.haveTrendline(variables)
            }
            disabled={noVariables}
            title={TOOLTIPS.TOOLBAR.GRPAH_TYPE}
          />
          <FxButton
            onClick={this.onToggleFunctionsModal}
            disabled={noVariables}
            title={TOOLTIPS.TOOLBAR.FUNCTION}
          />
          <InterpolationButton
            active={interpolationActive}
            onClick={this.handleInterpolationClick}
            disabled={noVariables}
            title={TOOLTIPS.TOOLBAR.INTERPOLATION}
          />
          <CorrelationButton
            active={isCorrelationEnabled}
            onClick={this.handleCorrelationClick}
            disabled={noVariables}
          />
        </div>

        <div className={`${element('RightButtons')} row middle-xs center-xs`}>
          {!isCollapsed && <ButtonSeparator />}
          <LogScaleButton
            onClick={this.toggleYAxisType}
            disabled={logScaleDisabled}
            active={this.props.yAxisType === 'log'}
          />
          <ButtonSeparator isCollapsed={isCollapsed} />
          <ZoomButton
            onClick={() => setActiveModal('zoom')}
            disabled={noVariables}
            active={this.props.zoom !== 'default'}
          />
          <ButtonSeparator isCollapsed={isCollapsed} />
          <DropdownComponent title={TOOLTIPS.GRAPH_SETTINGS}>
            <ul>
              <li
                className={seriesActionsDisabled}
                onClick={() => setActiveModal('scalingOptions')}
              >
                {SIDEBAR.SCALING_OPTIONS.TITLE}
              </li>
              <li className="separator" />
              <li
                className={seriesActionsDisabled}
                onClick={() => setActiveModal('aggregation')}
              >
                {SIDEBAR.AGGREGATION.TITLE}
              </li>
            </ul>
          </DropdownComponent>
        </div>

        <AsideDialog
          isOpen={this.props.modal === 'zoom'}
          onClose={this.closeModal}
          title={SIDEBAR.GRAPH_OPTIONS.ZOOM_DIALOG.TITLE}
        >
          <ZoomDialog zoom={this.props.zoom} onSave={this.onZoomSave} />
        </AsideDialog>

        <AsideDialog
          isOpen={this.props.modal === 'aggregation'}
          onClose={this.closeModal}
          title={SIDEBAR.AGGREGATION.TITLE}
        >
          <AggregationDialog
            onSave={this.onAggregationSave}
            minFrequency={getMinFrequency(variables)}
            aggregation={aggregation}
          />
        </AsideDialog>
        {variables.length > 0 && (
          <AsideDialog
            title={SIDEBAR.FUNCTIONS}
            isOpen={this.state.isFunctionsModalOpen}
            onClose={this.onToggleFunctionsModal}
          >
            <FunctionsContainer applyTo="all" onClose={this.onToggleFunctionsModal} />
          </AsideDialog>
        )}
      </div>
    )
  }

  private handleCorrelationClick = () => {
    this.props.toggleCorrelation(!this.props.isCorrelationEnabled)
  }

  private onZoomSave = (zoom: Zoom) => {
    this.closeModal()
    this.props.setGraphZoom(zoom)
  }

  private onAggregationSave = (aggregation: Aggregation) => {
    this.closeModal()
    if (aggregation) {
      this.props.addAggregationHandler(this.props.variables, aggregation)
    } else {
      this.props.removeAggregationHandler(this.props.variables)
    }
  }

  private closeModal = () => this.props.setActiveModal(null)
  private onToggleFunctionsModal = () =>
    this.setState({ isFunctionsModalOpen: !this.state.isFunctionsModalOpen })

  private haveTrendline = (variables: IDataSeries[]) =>
    !!variables.filter(s => hasTrendLine(s)).length

  private toggleYAxisType = () =>
    this.props.setYAxisType(this.props.yAxisType === 'log' ? 'linear' : 'log')
}

const baseSelector = (state: IRootState) => state.series
const activeModalSelector = (state: IRootState) => state.series.activeModal
const settingsSelector = createSelector(baseSelector, state => state.seriesSettings)
const correlationSelector = createSelector(baseSelector, state => state.correlation)
const isScatterPlotSelector = createSelector(
  settingsSelector,
  settings => settings.isScatterPlot
)
const variablesSelector = createSelector(baseSelector, state => state.variables)
const zoomSelector = createSelector(baseSelector, state => state.seriesSettings.graphZoom)
const graphTypeSelector = createSelector(
  baseSelector,
  isScatterPlotSelector,
  (state, isScatterPlot) => {
    const graphTypes = new Set(state.seriesSettings.graphTypes)
    const graphType = graphTypes.size === 1 ? graphTypes.values().next().value : null
    return isScatterPlot ? 'SCATTER' : graphType
  }
)
const aggregationSelector = createSelector(variablesSelector, variables => {
  const aggregations = new Set(
    variables
      .map(v => (hasAggregation(v) ? getAggregation(v) : null))
      .filter(a => a !== null)
  )
  const aggregation = aggregations.size === 1 ? aggregations.values().next().value : null
  return aggregation
})

const mapStateToProps = (state: IRootState): Omit<IProps, 'isCollapsed'> => {
  return {
    variables: variablesSelector(state),
    zoom: zoomSelector(state),
    modal: activeModalSelector(state),
    graphType: graphTypeSelector(state),
    aggregation: aggregationSelector(state),
    isCorrelationEnabled: correlationSelector(state).enabled,
    yAxisType: settingsSelector(state).yAxisType,
  }
}

const mapDispatchToProps: IActionProps = {
  selectTab: DATABASE_ACTIONS.openTab,
  setGraphZoom: setZoom,
  setActiveModal: SERIES_ACTIONS.setActiveModal,
  setSeriesType: SERIES_ACTIONS.setSeriesType,
  addInterpolationHandler: addInterpolation,
  removeInterpolationHandler: removeInterpolation,
  addAggregationHandler: addAggregation,
  removeAggregationHandler: removeAggregation,
  toggleCorrelation,
  setYAxisType: SERIES_ACTIONS.setYAxisType,
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuToolbar)
