// eslint-disable max-lines
import * as React from 'react'
import { cmp } from 'utils'

import { DB_STATS, DB_TABS_TITLES, IMPORT_EXPORT } from '../../messages'
import { Checkbox } from '../Checkbox'
import { FilePickerLink } from '../FilePicker'
import { KebabMenu } from '../KebabMenu'
import { Link } from '../Link'
import { PackageIcon } from '../SVG/PackageIcon'
import { Switch } from '../Sidebar/Switch'
import {
  IItemRendererProps,
  ItemSelector,
  SelectorTabs,
  SelectorTabsHeader,
  Tab,
} from './ItemSelector'
import { listToTxt, txtToList } from './utils'

import './TabbedSelectors.scss'

interface IOrganizationProps {
  databases: { id: string; name: string }[]
  packages: IDBPackage[]
  selectedDatabases: string[]
  previewedDatabases: string[]
  selectedPackages: string[]
  databasesCount: number
  onDatabasesChange: (selected: string[], preview: string[]) => void
  onPackagesChange: (packages: string[]) => void
  onDatabasesImport?: (count: number) => void
}

const PackageCheckbox = <T extends {}>(
  props: IItemRendererProps<T> & { packages: IDBPackage[] }
) => {
  const isPackage = props.packages.length > 0
  const isSelected = props.isSelected || isPackage

  return (
    <div className="PackageCheckbox">
      <Checkbox
        checked={isSelected}
        label={props.item.name}
        onChange={isPackage ? undefined : props.onChange}
        big
        disabled={isPackage}
      />
      {isPackage && (
        <PackageIcon
          title={`In package: ${props.packages.map(p => p.name).join(', ')}`}
          className="PackageIndicator"
        />
      )}
      {!isSelected && props.onPreviewChange && (
        <Switch
          title="preview"
          value={props.isPreview}
          onChange={props.onPreviewChange}
        />
      )}
    </div>
  )
}

export const OrganizationTabs: React.FC<IOrganizationProps> = ({
  databases,
  selectedDatabases,
  previewedDatabases,
  selectedPackages,
  packages,
  databasesCount,
  onDatabasesChange,
  onPackagesChange,
  onDatabasesImport,
}) => {
  const databasesFromSelectedPackages = () => {
    const pkgDbs: string[] = selectedPackages.reduce((dbFromPackages, packageId) => {
      const pkg = packages.find(p => p.id === packageId)
      return pkg ? [...dbFromPackages, ...pkg.databases] : [...dbFromPackages]
    }, [])

    return Array.from(new Set(pkgDbs))
  }

  const databasePackages = React.useMemo<Map<string, IDBPackage[]>>(
    () =>
      new Map<string, IDBPackage[]>(
        databases.map(db => [
          db.id,
          packages.filter(
            pkg =>
              pkg.isEnabled &&
              selectedPackages.includes(pkg.id) &&
              pkg.databases.includes(db.id)
          ),
        ])
      ),
    [selectedPackages, packages, databases]
  )

  const titles = [
    `${DB_TABS_TITLES.DATABASES} (${databasesCount})`,
    `${DB_TABS_TITLES.PACKAGES} (${selectedPackages.length})`,
  ]

  const pkgDatabases = databasesFromSelectedPackages().filter(
    db => !selectedDatabases.includes(db)
  )

  const stats = [
    {
      label: DB_STATS.DIRECT_LABEL,
      value: `${selectedDatabases.length}`,
    },
    {
      label: DB_STATS.FROM_PACKAGES_LABEL,
      value: `${pkgDatabases.length}`,
    },
  ]

  const exportSelections = () => {
    listToTxt([...selectedDatabases, ...pkgDatabases.map(db => `${db} [P]`)])
  }

  const importSelections = async (file: File) => {
    const importedDatabases = await txtToList(file)
    const databaseIds = databases.map(database => database.id)
    const filteredToImport = importedDatabases.filter(dbToImport =>
      databaseIds.includes(dbToImport)
    )
    onDatabasesImport(filteredToImport.length)

    const databasesWithImported = Array.from(
      new Set([...selectedDatabases, ...filteredToImport])
    )
    onDatabasesChange(databasesWithImported, previewedDatabases)
  }

  const MenuRenderer = React.useMemo(
    () => () => {
      return (
        <KebabMenu>
          <FilePickerLink
            onFilePicked={importSelections}
            accept=".txt"
            text={IMPORT_EXPORT.IMPORT}
          />
          <Link disabled={selectedDatabases.length === 0} onClick={exportSelections}>
            {IMPORT_EXPORT.EXPORT}
          </Link>
        </KebabMenu>
      )
    },
    [selectedDatabases]
  )

  const ItemRenderer = React.useMemo(
    () => (props: IItemRendererProps<string>) => {
      const relatedPackages = databasePackages.get(props.item.id)

      return <PackageCheckbox {...props} packages={relatedPackages} />
    },
    [selectedPackages, databases]
  )

  const itemCompare = (a: any, b: any) =>
    cmp(
      databasePackages.get(a.id).length ? -1 : 1,
      databasePackages.get(b.id).length ? -1 : 1
    )

  const [tab, setTab] = React.useState(0)

  return (
    <SelectorTabs>
      <SelectorTabsHeader changeTab={setTab} active={tab} names={titles} />
      <Tab active={tab === 0}>
        <ItemSelector
          stats={stats}
          menuRenderer={MenuRenderer}
          items={databases}
          selectedIds={selectedDatabases}
          previewedIds={previewedDatabases}
          onChange={onDatabasesChange}
          ItemRenderer={ItemRenderer}
          itemCompare={itemCompare}
        />
      </Tab>
      <Tab active={tab === 1}>
        <ItemSelector
          items={packages}
          selectedIds={selectedPackages}
          onChange={onPackagesChange}
        />
      </Tab>
    </SelectorTabs>
  )
}

interface Stat {
  label: string
  value: string
}

interface IDatabaseProps {
  stats?: Stat[]
  organizations: { id: number; name: string }[]
  organizationsCount: number
  packages: IDBPackage[]
  selectedOrganizations: number[]
  previewedOrganizations: number[]
  selectedPackages: string[]
  onOrganizationsChange: (selected: number[], previewedOrganizations: number[]) => void
  onPackagesChange: (packages: string[]) => void
}

export const DatabaseTabs: React.FC<IDatabaseProps> = ({
  stats,
  organizations,
  organizationsCount,
  selectedOrganizations,
  previewedOrganizations,
  selectedPackages,
  packages,
  onOrganizationsChange,
  onPackagesChange,
}) => {
  const titles = [
    `Organizations (${organizationsCount})`,
    `Packages (${selectedPackages.length})`,
  ]

  const organizationPackages = React.useMemo<Map<number, IDBPackage[]>>(
    () =>
      new Map<number, IDBPackage[]>(
        organizations.map(org => [
          org.id,
          packages.filter(
            pkg =>
              pkg.isEnabled &&
              selectedPackages.includes(pkg.id) &&
              pkg.organizations.includes(org.id)
          ),
        ])
      ),
    [selectedPackages, packages, organizations]
  )

  const ItemRenderer = React.useMemo<React.FC<IItemRendererProps<number>>>(
    () => props => {
      // eslint-disable-next-line react/prop-types
      const relatedPackages = organizationPackages.get(props.item.id)

      return <PackageCheckbox {...props} packages={relatedPackages} />
    },
    [selectedPackages, organizations]
  )

  const itemCompare = (a: any, b: any) =>
    cmp(
      organizationPackages.get(a.id).length ? -1 : 1,
      organizationPackages.get(b.id).length ? -1 : 1
    )

  const [tab, setTab] = React.useState(0)

  return (
    <SelectorTabs>
      <SelectorTabsHeader changeTab={setTab} active={tab} names={titles} />
      <Tab active={tab === 0}>
        <ItemSelector
          stats={stats}
          items={organizations}
          selectedIds={selectedOrganizations}
          previewedIds={previewedOrganizations}
          onChange={onOrganizationsChange}
          ItemRenderer={ItemRenderer}
          itemCompare={itemCompare}
        />
      </Tab>
      <Tab active={tab === 1}>
        <ItemSelector
          items={packages}
          selectedIds={selectedPackages}
          onChange={onPackagesChange}
        />
      </Tab>
    </SelectorTabs>
  )
}
