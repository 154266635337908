// eslint-disable max-len
import * as React from 'react'

const ILetterIcon = ({ color }: any) => (
  <svg width="20" height="18" viewBox="0 0 20 18">
    <path
      fill={color}
      fillRule="nonzero"
      d="M11.706 13H8.312v-.392c.093-.007.184-.016.273-.027a.954.954 0 0 0 .231-.054.445.445 0 0 0 .242-.185.636.636 0 0 0 .07-.32V8.896a.627.627 0 0 0-.086-.33.711.711 0 0 0-.215-.228 1.14 1.14 0 0 0-.293-.124c-.13-.039-.25-.064-.357-.075v-.392l2.632-.14.08.081v4.265a.6.6 0 0 0 .081.32c.054.087.13.152.231.195a1.813 1.813 0 0 0 .505.14V13zm-.784-7.326a.908.908 0 0 1-.314.695c-.21.192-.458.288-.744.288-.29 0-.54-.096-.75-.288a.908.908 0 0 1-.314-.695c0-.272.105-.505.315-.698.209-.194.459-.29.749-.29s.539.096.746.29a.92.92 0 0 1 .312.698z"
    />
  </svg>
)

const ILetterIconSm = ({ color }: any) => (
  <svg width="10" height="10" viewBox="0 0 10 10">
    <path
      fill={color}
      fillRule="nonzero"
      d="M7 8H4.114v-.33c.08-.006.157-.014.233-.023a.818.818 0 0 0 .196-.045.378.378 0 0 0 .206-.156.531.531 0 0 0 .06-.269V4.545a.524.524 0 0 0-.074-.278.601.601 0 0 0-.182-.192.975.975 0 0 0-.25-.104A1.637 1.637 0 0 0 4 3.908v-.33l2.237-.118.069.068v3.59c0 .106.023.195.068.27a.414.414 0 0 0 .197.164A1.551 1.551 0 0 0 7 7.67V8zm-.667-6.168c0 .23-.089.424-.267.586a.91.91 0 0 1-.632.242.917.917 0 0 1-.637-.242.762.762 0 0 1-.267-.586c0-.23.089-.425.267-.588A.912.912 0 0 1 5.434 1c.246 0 .458.081.634.244a.771.771 0 0 1 .265.588z"
    />
  </svg>
)

export const I_LETTER_ICON = <ILetterIcon color="#788596" />
export const I_LETTER_ICON_LIGHT = <ILetterIcon color="#FFFFFF" />
export const I_LETTER_ICON_LIGHT_SM = <ILetterIconSm color="#FFFFFF" />
