import * as React from 'react'
import { bindBem } from 'bem'

import { IconOption } from 'containers/MenusAdmin/Options/IconOption'
import { useClickOutside } from 'hooks/useClickOutside.hook'
import { Portal } from 'react-portal'
import { useScrollAnywhere } from 'hooks/useScrollAnywhere.hook'
import { useWindowResize } from 'hooks/useWindowResize.hook'
import { IProps as ISubMenuItemProps } from './SubMenuItem'

import './IconSubMenuOption.scss'

export interface IProps {
  icon: string
  tooltip: string
  children:
    | React.ReactElement<ISubMenuItemProps>
    | React.ReactElement<ISubMenuItemProps>[]
  onClick: (itemName: string) => void
}

export const IconSubMenuOption: React.FC<IProps> = ({
  icon,
  tooltip,
  children,
  onClick,
}) => {
  const { block, element } = bindBem('IconSubMenuOption')
  const subMenuRef = React.useRef<HTMLDivElement>()
  const blockRef = React.useRef<HTMLDivElement>()
  const [isOpen, setOpen] = React.useState(false)
  const [subMenuStyles, setSubMenuStyles] = React.useState<React.CSSProperties>({})
  const openMenu = React.useCallback(() => {
    setOpen(true)
    setSubMenuStyles({
      left: 0,
      top: 0,
    })
  }, [])
  const closeMenu = React.useCallback(() => setOpen(false), [])
  useClickOutside(subMenuRef, isOpen, closeMenu)
  useScrollAnywhere(isOpen, closeMenu)
  useWindowResize(isOpen, closeMenu)

  React.useEffect(() => {
    if (isOpen) {
      // Recalculate submenu position
      if (!blockRef.current || !subMenuRef.current) {
        return
      }
      const blockRect = blockRef.current.getBoundingClientRect()
      const subMenuRect = subMenuRef.current.getBoundingClientRect()
      setSubMenuStyles({
        visibility: 'visible',
        top: Math.min(blockRect.bottom, window.innerHeight - subMenuRect.height),
        left: Math.max(blockRect.right - subMenuRect.width, 0),
      })
    }
  }, [isOpen])

  const handleClick = React.useCallback(
    (childName?: string, disabled?: boolean) => {
      if (!disabled) {
        if (childName) {
          onClick(childName)
        }
        closeMenu()
      }
    },
    [onClick]
  )

  const mapChildren = React.useCallback(
    (child: React.ReactElement<ISubMenuItemProps>) => {
      return (
        <div onClick={() => handleClick(child.props?.name, child.props?.disabled)}>
          {child}
        </div>
      )
    },
    [handleClick]
  )

  return (
    <div ref={blockRef} className={block()}>
      <IconOption icon={icon} tooltip={tooltip} onClick={openMenu} />
      {isOpen && (
        <Portal>
          <div ref={subMenuRef} className={element('SubMenu')} style={subMenuStyles}>
            {React.Children.map(children, mapChildren)}
          </div>
        </Portal>
      )}
    </div>
  )
}
