import * as React from 'react'
import { bindBem } from 'bem'

import './EditInput.scss'

export interface IProps {
  startValue: string
  onSave: (newName: string) => void
}

export const EditInput: React.FC<IProps> = ({ startValue, onSave }) => {
  const { block, element } = bindBem('EditInput')
  const inputRef = React.useRef<HTMLInputElement>()
  const [value, setValue] = React.useState(startValue)

  const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }, [])

  const handleSave = React.useCallback(() => {
    const trimmedValue = value.trim()
    if (trimmedValue !== '') {
      onSave(trimmedValue)
    } else {
      onSave(startValue)
    }
  }, [value, onSave])

  const handleKeyUp = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleSave()
      }
    },
    [handleSave]
  )

  React.useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <div className={block()}>
      <input
        className={element('Input')}
        ref={inputRef}
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={handleSave}
        onKeyUp={handleKeyUp}
      />
    </div>
  )
}
