import * as React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { bindBem } from '../../bem'
import { ADMIN } from 'messages'

import Users from './../../components/Admin/Users/Users'
import Tokens from './../../components/Admin/Users/Tokens'
import Databases from './../../components/Admin/Databases'
import Packages from './../../components/Admin/Packages'
import { IRootState, TDispatch } from 'store'
import * as admin from 'store/Admin'
import { connect } from 'react-redux'
import { isHaverAdmin } from 'services/user'

import './OrganizationPane.scss'

export interface IStateProps {
  me: IUser
}

export interface IActionProps {
  fetchUsers: (orgId: number) => void
  clearUsers: () => void
  fetchTokens: (orgId: number) => void
  clearTokens: () => void
}

interface IProps {
  organization: IOrganization
}

export const OrganizationPane = ({
  me,
  organization,
  fetchUsers,
  clearUsers,
  fetchTokens,
  clearTokens,
}: IProps & IStateProps & IActionProps) => {
  const { block } = bindBem('OrganizationPane')

  React.useEffect(() => {
    if (organization) {
      fetchUsers(organization.id)
      fetchTokens(organization.id)
    }
    return () => {
      clearUsers()
      clearTokens()
    }
  }, [organization?.id])

  return (
    <div className={block()}>
      <Tabs>
        <TabList>
          <Tab>
            {ADMIN.TABS.LABELS.USERS}
            <div className="suffix">{organization?.usersCount}</div>
          </Tab>
          <Tab>{ADMIN.TABS.LABELS.TOKENS}</Tab>
          <Tab>
            {ADMIN.TABS.LABELS.DATABASES}
            <div className="suffix">{organization?.databasesCount}</div>
          </Tab>
          {isHaverAdmin(me.role) && (
            <Tab>
              {ADMIN.TABS.LABELS.PACKAGES}
              <div className="suffix">{organization?.packages.length}</div>
            </Tab>
          )}
        </TabList>
        <TabPanel>{organization && <Users organization={organization} />}</TabPanel>
        <TabPanel>{organization && <Tokens organization={organization} />}</TabPanel>
        <TabPanel>{organization && <Databases organization={organization} />}</TabPanel>
        {isHaverAdmin(me.role) && (
          <TabPanel>{organization && <Packages organization={organization} />}</TabPanel>
        )}
      </Tabs>
    </div>
  )
}

const mapStateToProps = (state: IRootState): IStateProps => {
  const { user: me } = state.user

  return {
    me,
  }
}

const mapDispatchToProps = (dispatch: TDispatch): IActionProps => ({
  fetchUsers: (orgId: number) => dispatch(admin.fetchUsers(orgId)),
  clearUsers: () => dispatch(admin.ACTIONS.setUsers(null)),
  fetchTokens: (orgId: number) => dispatch(admin.fetchTokens(orgId)),
  clearTokens: () => dispatch(admin.ACTIONS.setUsers(null)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationPane)
