// eslint-disable max-lines

import * as React from 'react'
import { connect } from 'react-redux'
import ReactTable, { Column, SortingRule } from 'react-table'

import * as admin from '../store/Admin'
import * as API from '../api2/admin'
import { IRootState, TDispatch } from '../store'
import { ADMIN } from '../messages'
import { bindBem } from '../bem'

import AdminHeader from '../components/Admin/AdminHeader'

import {
  HeaderCell,
  Text,
  ClickCell,
  ActivityDotCell,
} from 'components/Admin/AdminTableCells'
import { BigSwitch } from '../components/Admin/BigSwitch'
import { Input } from '../components/Input'
import { Dialog, Modal } from '../components/Modal'
import { DatabaseForm } from '../components/Admin/DatabaseForm'
import { RemoveDatabaseConfirmation } from '../components/Admin/RemoveOrgConfirmation'
import { DatabaseKebabMenu } from 'components/Admin/DatabaseKebabMenu'
import NoDataComponent from 'components/ReactTableNoDataComponent'
import { track } from 'analytics'

import { ICON_SEARCH_SLATE } from '../components/SVG/Search'

import './DatabaseAdmin.scss'

export interface IProps {
  databases: IDatabase[]
  databasesWithMenuPages: string[]
  cTreeDatabases: string[]
  organizations: IOrganization[]
  packages: IDBPackage[]
}

export interface IActionProps {
  patchDatabase: (data: Partial<IDatabaseDetails>) => void
  deleteDatabase: (db: IDatabaseDetails) => void
  uploadMenuPages: (db: string, file: File) => Promise<void>
}

export interface IState {
  sortedColumn: string
  desc: boolean
  filter: string
  loading: boolean
  db: IDatabaseDetails
  isDeleteDatabaseModalOpen: boolean
  selectedDatabase: IDatabaseDetails
}

export class DatabaseAdmin extends React.Component<IProps & IActionProps, IState> {
  state: IState = {
    sortedColumn: 'name',
    desc: false,
    filter: '',
    loading: false,
    db: null,
    isDeleteDatabaseModalOpen: false,
    selectedDatabase: null,
  }

  private COLUMNS: Column[] = [
    {
      Header: ADMIN.DB.DATABASE_NAME,
      id: 'name',
      accessor: (db: IDatabase | ICTreeDatabase) =>
        db.description ? `${db.name} — ${db.description}` : db.name,
      Cell: ({ value, original }) =>
        original.$exists ? (
          <ClickCell value={value} onClick={() => this.openModalFor(original.name)} />
        ) : (
          <Text value={value} />
        ),
    },
    {
      Header: ADMIN.DB.CTREE,
      id: 'ctreeAvailable',
      accessor: (db: IDatabase | ICTreeDatabase) =>
        this.props.cTreeDatabases.includes(db.name.toUpperCase()),
      Cell: ({ value }) => (
        <ActivityDotCell
          title={value ? 'Database available in CTree' : 'Database missing in CTree'}
          value={value}
        />
      ),
      maxWidth: 80,
    },
    {
      Header: 'HV',
      id: 'hvAvailable',
      accessor: (db: IDatabase | ICTreeDatabase) => !!db.$exists,
      Cell: ({ value }) => (
        <ActivityDotCell
          title={
            value ? 'Database available in HaverView' : 'Database missing in HaverView'
          }
          value={value}
        />
      ),
      maxWidth: 80,
    },
    {
      Header: ADMIN.DB.MENUS,
      id: 'menusAvailable',
      accessor: (db: IDatabase | ICTreeDatabase) =>
        this.props.databasesWithMenuPages.includes(db.name.toUpperCase()),
      Cell: ({ value, original }) =>
        original.$exists ? (
          <ActivityDotCell
            value={value ? (!!original.topMenuName ? true : null) : false}
            title={
              value
                ? !!original.topMenuName
                  ? 'Menu pages available'
                  : 'Menu pages not linked'
                : 'Menu pages missing'
            }
          />
        ) : (
          <ActivityDotCell value={null} />
        ),
      maxWidth: 80,
    },
    {
      Header: ADMIN.DB.STATUS,
      accessor: 'isEnabled',
      maxWidth: 100,
      Cell: ({ original }) =>
        original.$exists ? (
          <BigSwitch
            value={original.isEnabled}
            onChange={value => this.onChangeStatus(original, value)}
          />
        ) : (
          <BigSwitch
            value={null}
            disabled={true}
            onChange={() => {
              return
            }}
          />
        ),
    },
  ]

  render() {
    const { block, element } = bindBem('DatabaseAdmin')
    const { db } = this.state
    const filteredDatabases = this.filterDatabases()

    return (
      <div className={block()}>
        <AdminHeader />
        <div className={element('Search')}>
          <Input
            name="filter"
            prefix={ICON_SEARCH_SLATE}
            placeholder={ADMIN.DB.SEARCH_PLACEHOLDER}
            value={this.state.filter}
            onChange={this.onFilterChange}
          />
        </div>
        <ReactTable
          className={element('Table')}
          data={filteredDatabases}
          columns={this.getColumns()}
          onSortedChange={this.onSortedChange}
          sorted={[{ id: this.state.sortedColumn, desc: this.state.desc }]}
          showPagination={false}
          sortable={false}
          resizable={false}
          pageSize={filteredDatabases.length}
          loadingText="Loading..."
          loading={
            !this.props.cTreeDatabases.length ||
            !this.props.databases.length ||
            !this.props.databasesWithMenuPages.length
          }
          getNoDataProps={({ loading }) => ({ loading })}
          NoDataComponent={NoDataComponent}
        />
        {db && (
          <Dialog
            isOpen
            onClose={() => this.setState({ db: null })}
            title={db.description ? db.description : db.name}
            className="DatabaseDialog"
          >
            <DatabaseForm
              packages={this.props.packages}
              organizations={this.props.organizations}
              database={db}
              onSave={this.patchDatabase}
            />
          </Dialog>
        )}
        <Modal
          isOpen={this.state.isDeleteDatabaseModalOpen}
          onClose={this.onToggleDeleteDatabaseModal}
        >
          <RemoveDatabaseConfirmation
            callback={this.confirmDeleteDatabase}
            onClose={this.onToggleDeleteDatabaseModal}
            obj={this.state.selectedDatabase}
          />
        </Modal>
      </div>
    )
  }

  private onChangeStatus = (db: IDatabase, isEnabled: boolean) =>
    db.isEnabled !== isEnabled && this.props.patchDatabase({ ...db, isEnabled })

  private onSortedChange = (sorting: SortingRule[]) => {
    if (!sorting.length) {
      return this.setState({ sortedColumn: '', desc: true })
    }
    const { id, desc } = sorting[0]
    this.setState({ sortedColumn: id, desc })
  }

  private normalizeDbName = (name: string): string => name.toUpperCase()

  private notImportedCTreeDatabases = (): string[] => {
    const cTreeMap = this.props.cTreeDatabases.reduce<{
      [key: string]: boolean
    }>((acc, cTreeDb) => {
      acc[this.normalizeDbName(cTreeDb)] = false
      return acc
    }, {})
    for (const db of this.props.databases) {
      cTreeMap[this.normalizeDbName(db.name)] = true
    }
    return Object.entries(cTreeMap).reduce<string[]>((acc, [name, imported]) => {
      if (!imported) {
        acc.push(name)
      }
      return acc
    }, [])
  }

  private allDatabases = (): (ICTreeDatabase | IDatabaseDetails)[] => {
    const mapFunc = (db: string): ICTreeDatabase => ({ name: db })
    return []
      .concat(this.notImportedCTreeDatabases().map(db => mapFunc(db)))
      .concat(this.props.databases.map(db => (db.$exists = true) && db))
  }

  private onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ filter: e.currentTarget.value })

  private filterDatabases = () => {
    const filter = this.state.filter.toLowerCase()
    return this.allDatabases().filter(
      d =>
        d.name.toLowerCase().includes(filter) ||
        (d.description ?? '').toLowerCase().includes(filter)
    )
  }

  private onToggleDeleteDatabaseModal = () =>
    this.setState(() => ({
      isDeleteDatabaseModalOpen: !this.state.isDeleteDatabaseModalOpen,
    }))

  private deleteDatabase = (db: IDatabaseDetails) =>
    this.setState(() => ({
      selectedDatabase: db,
      isDeleteDatabaseModalOpen: true,
    }))

  private confirmDeleteDatabase = () => {
    this.props.deleteDatabase(this.state.selectedDatabase)
    this.onToggleDeleteDatabaseModal()
  }

  private getColumns = (): Column[] => {
    const columns = this.COLUMNS.map(col => ({
      ...col,
      Header: (
        <HeaderCell
          isSorting={this.state.sortedColumn === (col.id ?? col.accessor)}
          text={col.Header as string}
          desc={this.state.desc}
        />
      ),
      sortable: true,
      sortMethod: (a, b) => {
        if (typeof a === 'string') {
          return a.localeCompare(b, 'en-US', { numeric: true })
        }
        return a === b ? 0 : a < b ? -1 : 1
      },
    }))

    return [
      ...columns,
      {
        Header: '',
        sortable: false,
        maxWidth: 50,
        Cell: ({ original }) => {
          const onImport = (file: File) => {
            track('menu pages', 'import', { db: original.name })
            this.props.uploadMenuPages(original.name, file)
          }
          const onDelete = original.$exists ? () => this.deleteDatabase(original) : null
          return <DatabaseKebabMenu onImport={onImport} onDelete={onDelete} />
        },
      },
    ]
  }

  private openModalFor = async (name: string) => {
    const db = await API.fetchDatabase(name)
    this.setState({ db: { ...db } })
  }

  private patchDatabase = async (db: IDatabaseDetails) => {
    this.setState({ db: null })
    await this.props.patchDatabase(db)
  }
}

const mapStateToProps = (state: IRootState): IProps => ({
  databases: state.admin.databases,
  databasesWithMenuPages: state.admin.databasesWithMenuPages,
  cTreeDatabases: state.admin.cTreeDatabases,
  organizations: state.admin.organizations,
  packages: state.admin.packages,
})

const mapDispatchToProps = (dispatch: TDispatch): IActionProps => ({
  patchDatabase: (data: IDatabaseDetails) => dispatch(admin.updateDatabase(data)),
  deleteDatabase: (db: IDatabaseDetails) => dispatch(admin.deleteDatabase(db)),
  uploadMenuPages: (db: string, file: File) => dispatch(admin.uploadMenuPages(db, file)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DatabaseAdmin)
