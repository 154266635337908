import * as React from 'react'
import { bindBem } from '../bem'
import { Portal } from 'react-portal'

import CloseIcon from '../static/close.svg'

import './Toast.scss'

interface IProps {
  toast: IToast
  onClose: () => void
}

export const Toast = ({ toast, onClose }: IProps) => {
  const { block, element } = bindBem('Toast')
  return (
    <Portal>
      <div
        className={block({
          isOpen: toast.isOpen,
          [toast.type]: true,
          [toast.position]: true,
        })}
      >
        <div>
          <div className={element('Title')}>{toast.title}</div>
          <div className={element('Subtitle')}>{toast.subtitle}</div>
        </div>
        {toast.aside && <div className={element('Aside')}>{toast.aside}</div>}
        {!toast.autoClose && (
          <img
            className={element('Close')}
            src={CloseIcon}
            alt="close icon"
            onClick={onClose}
          />
        )}
      </div>
    </Portal>
  )
}
