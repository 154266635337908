import * as React from 'react'
import * as FauxDOM from 'react-faux-dom'
import * as d3 from 'd3'
import { YScale, ScatterScale } from '../../services/hd3'
import * as scatter from '../../services/hd3-utils/scatter-plot'

export interface IProps {
  variables: IDataSeries[]
  xScale: ScatterScale
  yScale: YScale
  settings: IScatterSettings
  onHover: (data: IDataPoint[]) => void
}

const ScatterPlot = ({ variables, xScale, yScale, settings, onHover }: IProps) => {
  const svg = FauxDOM.createElement('g')
  const selection = d3.select(svg)
  scatter.renderScatter(selection, xScale, yScale, variables, onHover, settings)

  return <>{React.Children.map(svg.toReact(), c => c.props.children)}</>
}

export default React.memo(ScatterPlot)
