// eslint-disable max-lines
import * as Sentry from '@sentry/react'
import * as d3 from 'd3'
import * as _ from 'lodash'
import * as React from 'react'
import { connect } from 'react-redux'
import { ACTIONS } from 'store/Series'

import { CHART_GROUP_ID, MARGINS, MOBILE_MARGINS } from '../../constants'
import {
  AnyXScale,
  CLIP_PATH_ID,
  XScale,
  YScale,
  getDataPointBisector,
  getLinearScale,
  getLogScale,
  getTimeScale,
} from '../../services/hd3'
import * as scatter from '../../services/hd3-utils/scatter-plot'
import { IStackedConfig } from '../../services/hd3-utils/stacked-bar'
import {
  COLORS,
  canApplyDataLabels,
  formatLabel,
  freqToInterval,
  getDataPoint,
  getMaxFrequency,
  getSeriesLabel,
} from '../../services/series'
import { calculateAxis } from '../../utils'
import { Tooltip } from '../Tooltip'
import Area from './Area'
import { AxisXGrid, AxisYGrid } from './AxisGrid'
import Bars from './Bars'
import ClipPath from './ClipPath'
import Correlation from './Correlation'
import DataLabels from './DataLabels'
import DateXScale from './DateXScale'
import DragNDrop from './DragNDrop'
import DropShadow from './DropShadow'
import Legend from './Legend'
import Line from './Line'
import Recession from './Recession'
import ScatterInfo from './ScatterInfo'
import ScatterPlot from './ScatterPlot'
import ScatterXAxis from './ScatterXAxis'
import SeriesAssignments from './SeriesAssignments'
import Source from './Source'
import StackedBars from './StackedBars'
import YAxis from './YAxis'

export interface IProps {
  size: {
    width: number
    height: number
  }
  variables: IDataSeries[]
  startZoomDate?: Date
  endZoomDate?: Date
  recessions?: IRecession[]
  recessionType: IRecessionType
  trendlineBoundaries: TrendlineBoundaries
  onEditTitle: () => void
  settings: ISeriesSettings
  isLegendShown: boolean
  disableLegendEdition?: boolean
  scatterSettings: IScatterSettings
  scrollByPeriod?: (period: number) => void
  stackedConfig: IStackedConfig
  scale: IScale<IncrementalAxis>
  correlation: ICorrelation
}

export interface IState {
  tooltip: {
    date: Date
    position: number
    values: string[]
    dates?: Date[]
    mouseY: number
  }
  legendHeight: number
  color?: string
}

interface IActionProps {
  openScalingOptionsModal: () => void
}

const LINE_TYPES: LineType[] = ['LINE', 'STEPLINE', 'DOTTEDLINE', 'DASHEDLINE']
const BAR_TYPES: BarType[] = ['BAR', 'SHADED_BAR']

function memo<T>(fn: () => T) {
  let previousResult: T = null
  let prevDeps: {}[] = []
  return (deps: {}[]) => {
    if (deps.every((d, i) => prevDeps[i] === d)) {
      return previousResult
    }
    prevDeps = deps
    previousResult = fn()
    return previousResult
  }
}

export class Chart extends React.Component<IProps & IActionProps, IState> {
  state: IState = { tooltip: null, legendHeight: 300 }

  private _computeScales = memo(() => {
    const { variables, scale } = this.props
    let x: AnyXScale = this.getXScale()
    const height = this.getChartHeight()

    const isCommonScale = ['uniscale', 'both'].includes(scale.type)

    const scaleBuilder = (isLog: boolean) => (isLog ? getLogScale : getLinearScale)

    let scaleParameters = this.getYScaleParameters(isCommonScale ? undefined : 'left')
    const yLeft = scaleBuilder(scale.leftAxis.isLog)(height, scaleParameters.domain)
    let yRight = yLeft
    if (!isCommonScale) {
      scaleParameters = this.getYScaleParameters('right')
      yRight = scaleBuilder(scale.rightAxis.isLog)(height, scaleParameters.domain)
    }

    if (this.isScatter() && variables[0] && variables[1]) {
      const [min, max] = scatter.getValuesExtent(variables[0])
      const axis = calculateAxis(min, max, 7)
      x = scatter.getLinearScale(this.getWidth(), 0, [axis.min, axis.max])
    }
    return { yLeft, yRight, x }
  })

  render() {
    const { settings, variables, size } = this.props
    const { legendHeight, tooltip } = this.state
    const { yLeft, yRight, x } = this._computeScales([size, variables, legendHeight])
    const legendSize = { ...size, height: legendHeight }
    const showRightYAxis =
      !this.isScatter() || this.props.scatterSettings.axisType === 'both'
    const margins = this.margins()

    if (size.width === 0 || size.height === 0) {
      return null
    }

    return (
      <>
        <svg
          id="chart"
          viewBox={`0 0 ${size.width} ${size.height}`}
          style={{ height: size.height + 'px', background: 'white' }}
          preserveAspectRatio="xMinYMin meet"
          version="1.1"
        >
          <Correlation correlation={this.props.correlation} variables={variables} />
          <Legend
            readOnly={this.props.disableLegendEdition}
            title={settings.title}
            titles={settings.titles}
            onClick={this.props.onEditTitle}
            width={legendSize.width}
            height={legendSize.height}
            hasYAxisLabels={this.props.settings.yAxisLabels}
            hasLegend={this.props.settings.isLegendShown}
            variables={variables}
            isScatter={this.isScatter()}
            onHeightUpdate={this.onLegendHeightUpdate}
          />
          <g transform={`translate(${margins.left}, ${this.state.legendHeight})`}>
            <ClipPath width={this.getWidth()} height={this.getChartHeight()} />
            <DropShadow />

            {!scatter.isScatterScale(x) && this.props.recessions && (
              <Recession
                xScale={x}
                height={this.getChartHeight()}
                recessions={this.props.recessions}
                recessionType={this.props.recessionType}
              />
            )}
            <YAxis
              scale={yLeft}
              axis={this.props.scale.leftAxis}
              side="left"
              offset={0}
            />
            {showRightYAxis && (
              <YAxis
                scale={yRight}
                axis={this.props.scale.rightAxis}
                side="right"
                offset={this.getWidth()}
              />
            )}
            {settings.grid.horizontal && (
              <AxisXGrid
                yScale={yLeft}
                ticks={this.props.scale.leftAxis.values}
                width={this.getWidth()}
              />
            )}
            {!this.isScatter() && (
              <SeriesAssignments
                variables={variables}
                height={this.getChartHeight()}
                width={this.getWidth()}
                onClick={this.openScalingOptions}
              />
            )}
            {settings.grid.vertical && (
              <AxisYGrid
                xScale={x}
                height={this.getChartHeight()}
                width={this.getWidth()}
                frequency={getMaxFrequency(variables)}
              />
            )}
            {scatter.isScatterScale(x) ? (
              <ScatterXAxis scale={x} height={this.getChartHeight()} />
            ) : (
              <DateXScale
                scale={x}
                frequency={getMaxFrequency(variables)}
                height={this.getChartHeight()}
                ticksType={settings.tickmarksType}
              />
            )}
            <g id={CHART_GROUP_ID}>
              {!this.isScatter() && (
                <>
                  <this.RenderedStacked yLeft={yLeft} yRight={yRight} x={x as XScale} />
                  {variables.map(v => (
                    <this.RenderedSeries
                      yLeft={yLeft}
                      yRight={yRight}
                      x={x}
                      key={v.uuid}
                      series={v}
                    />
                  ))}
                </>
              )}
              {scatter.isScatterScale(x) && (
                <ScatterPlot
                  xScale={x}
                  yScale={yLeft}
                  variables={variables}
                  settings={this.props.scatterSettings}
                  onHover={this.handleScatterHover}
                />
              )}
            </g>
            {tooltip && !this.isScatter() && (
              <this.LineTooltip yLeft={yLeft} yRight={yRight} x={x as XScale} />
            )}
            {tooltip && this.isScatter() && (
              <circle
                className="hover-circle"
                cx={x(parseFloat(tooltip.values[0]))}
                cy={yLeft(tooltip.values[1])}
                r={5}
                strokeWidth={0}
                fill="red"
                clipPath={`url(#${CLIP_PATH_ID})`}
                style={{ opacity: 0.3, pointerEvents: 'none' }}
              />
            )}
            {!this.isScatter() &&
              variables.map((s, i) =>
                canApplyDataLabels(settings.graphTypes[i]) || !s.hasDataLabels ? (
                  <DataLabels
                    key={s.uuid}
                    xScale={x as XScale}
                    yScale={s.axisAssignment === 'left' ? yLeft : yRight}
                    colorIndex={s.colorIndex}
                    dataPoints={s.dataPoints}
                    decimalPrecision={s.decimalPrecision}
                    width={this.getWidth()}
                    height={this.getChartHeight()}
                    type={s.hasDataLabels ? settings.dataLabelsType : 'NONE'}
                  />
                ) : null
              )}
          </g>
          <Source height={size.height} value={variables[0]?.sourceName} />
          {this.isScatter() && (
            <ScatterInfo
              variables={variables}
              right={this.getWidth() + margins.left}
              top={size.height}
            />
          )}
        </svg>
        {this.state.tooltip && <Tooltip {...this.tooltipProps()} />}

        {!this.isScatter() && (
          <DragNDrop
            left={margins.left}
            top={this.state.legendHeight}
            width={this.getWidth()}
            height={this.getChartHeight()}
            onMouseMove={this.handleMouseMove}
            onMouseOut={this.handleMouseOut}
            onDragEnd={offset => this.onDrag(x as XScale, offset)}
          />
        )}
      </>
    )
  }

  RenderedSeries: React.FC<{
    series: IDataSeries
    yLeft: YScale
    yRight: YScale
    x: AnyXScale
  }> = ({ series, x, yLeft, yRight }) => {
    const { settings, variables } = this.props
    const i = variables.indexOf(series)
    const seriesType = settings.graphTypes[i]

    if (this.isLineType(seriesType)) {
      return (
        <Line
          series={series}
          xScale={x as XScale}
          yScale={series.axisAssignment === 'left' ? yLeft : yRight}
          hasLineShadow={settings.lineShadow}
          isSplineLine={settings.splineLine}
          style={seriesType}
          key={series.uuid}
          trendlineBoundaries={this.props.trendlineBoundaries[series.uuid]}
        />
      )
    }
    if (['BAR', 'SHADED_BAR'].includes(seriesType)) {
      let selectedIndex = -1
      if (this.state.tooltip) {
        const selected = this.state.tooltip.date
        const bisector = getDataPointBisector().left
        selectedIndex = bisector(series.dataPoints, { date: selected, value: 0 }) || -1
      }
      return (
        <Bars
          series={series}
          xScale={x as XScale}
          yScale={series.axisAssignment === 'left' ? yLeft : yRight}
          isShaded={seriesType === 'SHADED_BAR'}
          totalCount={this.getBarCount()}
          barIndex={this.getBarIndex(series)}
          selected={selectedIndex}
        />
      )
    }
    if (seriesType === 'AREA') {
      return (
        <Area
          series={series}
          xScale={x as XScale}
          yScale={series.axisAssignment === 'left' ? yLeft : yRight}
          hasLineShadow={settings.lineShadow}
          key={series.uuid}
        />
      )
    }
    return null
  }

  RenderedStacked: React.FC<{ yLeft: YScale; yRight: YScale; x: XScale }> = ({
    x,
    yLeft,
    yRight,
  }) => {
    const { stackedConfig } = this.props
    if (!this.hasStacked()) {
      return null
    }
    const yScale = stackedConfig.axisAssignment === 'left' ? yLeft : yRight
    return (
      <StackedBars
        config={this.props.stackedConfig}
        xScale={x}
        yScale={yScale}
        selectedDate={this.state.tooltip?.date}
        barVariablesCount={this.getBarCount()}
      />
    )
  }

  private hasStacked = () => {
    const { stackedConfig } = this.props
    return stackedConfig?.types.length > 0
  }

  private margins = () => {
    return this.props.disableLegendEdition ? MOBILE_MARGINS : MARGINS
  }

  private getBarCount = () => {
    const barVariables = this.props.settings.graphTypes.filter(this.isBarType).length
    const stackedVariable = this.props.stackedConfig ? 1 : 0
    return barVariables + stackedVariable
  }

  private getBarIndex = (s: IDataSeries) => {
    const index = this.props.variables.indexOf(s)
    return (
      this.props.settings.graphTypes.slice(0, index + 1).filter(this.isBarType).length - 1
    )
  }

  private isLineType = (t: ISeriesType): t is LineType =>
    LINE_TYPES.includes(t as LineType)

  private isBarType = (t: ISeriesType): t is BarType => BAR_TYPES.includes(t as BarType)

  private LineTooltip = (props: { yLeft: YScale; yRight: YScale; x: XScale }) => {
    const { tooltip } = this.state
    const { yLeft, yRight, x } = props
    const { variables } = this.props
    return (
      <>
        <path
          className="hover-line"
          d={`M ${x(tooltip.date)} 0 L ${x(tooltip.date)} ${this.getChartHeight()}`}
          stroke="#6f7886"
          fill="none"
        />
        {tooltip.values.map((val, i) =>
          !val ||
          !(this.typeOf(i) === 'AREA' || this.isLineType(this.typeOf(i))) ? null : (
            <circle
              key={i}
              className="hover-circle"
              cx={tooltip.position}
              cy={(variables[i].axisAssignment === 'left' ? yLeft : yRight)(
                parseFloat(val)
              )}
              r={4}
              strokeWidth={2}
              stroke="white"
              fill={COLORS[variables[i].colorIndex] || COLORS[0]}
              clipPath={`url(#${CLIP_PATH_ID})`}
            />
          )
        )}
      </>
    )
  }

  private typeOf = (i: number) => this.props.settings.graphTypes[i]

  private getScales = () =>
    this._computeScales([this.props.size, this.props.variables, this.state.legendHeight])

  private tooltipProps = () => {
    const { tooltip } = this.state
    const { variables, size } = this.props
    const freqs = new Set(variables.map(v => v.frequency))
    // Not entirely correct: each freq can be shown more than once
    const tooltipHeight = 20 * variables.length + 34 * freqs.size
    const chartYPosition = 100 // temporary
    const isScatter = this.isScatter()
    const x = isScatter ? tooltip.position : tooltip.position + this.margins().left
    const y = isScatter
      ? tooltip.mouseY
      : tooltip.mouseY -
        chartYPosition -
        (tooltip.mouseY > size.height - tooltipHeight / 2 && tooltipHeight)

    let lastFreq = -1
    const sections = tooltip.values.map((value, i) => {
      const series = variables[i]
      const title = tooltip.dates?.[i]
        ? formatLabel(tooltip.dates[i], series.frequency)
        : formatLabel(tooltip.date, series.frequency)
      const color = series.colorIndex
      const name = series.transformation ? getSeriesLabel(series) : series.id

      if (series.frequency !== lastFreq) {
        lastFreq = series.frequency
        return { title, name, value, color }
      }
      return { name, value, color }
    })

    return {
      sections,
      x,
      y,
      toLeft: size.width - x < 350,
    }
  }

  private onLegendHeightUpdate = (legendHeight: number) => {
    if (legendHeight !== this.state.legendHeight) {
      this.setState(() => ({ legendHeight }))
    }
  }

  private getWidth = () =>
    Math.max(0, this.props.size.width - this.margins().left - this.margins().right)

  private getChartHeight = () =>
    Math.max(0, this.props.size.height - this.state.legendHeight - this.margins().bottom)

  private getXScale(): AnyXScale {
    const { startZoomDate, endZoomDate, variables } = this.props
    const width = this.getWidth()

    if (this.isScatter() && variables.length > 0) {
      const [min, max] = scatter.getValuesExtent(variables[0])
      const axis = calculateAxis(min || 0, max || 0, 7)
      return scatter.getLinearScale(width, 0, [axis.min, axis.max])
    }
    return getTimeScale(width, startZoomDate, endZoomDate)
  }

  private isScatter = () =>
    this.props.settings.isScatterPlot && scatter.isApplicable(this.props.variables)

  private getYScaleParameters(axis?: AxisAssignment): {
    domain: [number, number]
    ticks: number[]
  } {
    const { leftAxis, rightAxis } = this.props.scale
    const a = axis === 'right' ? rightAxis : leftAxis
    return { domain: [_.first(a.values), _.last(a.values)], ticks: a.values }
  }

  private handleMouseMove: React.MouseEventHandler = e => {
    const rect = (e.currentTarget as HTMLElement).getBoundingClientRect()
    const position = e.clientX - rect.left
    const { x } = this.getScales()
    const positionDate = x.invert(position) as Date
    const { variables } = this.props

    if (this.getWidth() - position < 1) {
      return this.setState(() => ({ tooltip: null }))
    }

    const points = variables.map(({ dataPoints, frequency, decimalPrecision }) => {
      const dp = dataPoints.length
        ? getDataPoint(dataPoints, positionDate, frequency)
        : null
      if (!dp) {
        return null
      }
      const valueStr =
        decimalPrecision && dp.value && !Number.isNaN(dp.value)
          ? dp.value.toFixed(decimalPrecision)
          : String(dp.value ?? 'N/A')
      return { ...dp, valueStr }
    })

    const firstPoint = _.first(points.filter(p => p !== null))
    if (!firstPoint) {
      return this.setState(() => ({ tooltip: null }))
    }

    this.setState({
      tooltip: {
        date: firstPoint.date,
        position: x(firstPoint.date),
        values: points.map(v => (v ? v.valueStr : null)),
        dates: points.map(v => (v ? v.date : null)),
        mouseY: e.clientY,
      },
    })
  }

  private handleMouseOut = () => this.setState(() => ({ tooltip: null }))

  private handleScatterHover = (data: IDataPoint[]) => {
    if (!d3.event || d3.event.type === 'mouseout') {
      return this.setState(() => ({ tooltip: null }))
    }
    const [p1, p2] = data
    const { variables } = this.props
    const values = [p1.value, p2.value].map((v, i) =>
      isNaN(v) ? 'NaN' : v.toFixed(variables[i].decimalPrecision)
    )
    const tooltip = {
      date: p1.date,
      position: d3.event.layerX,
      values,
      mouseY: d3.event.layerY - 75,
    }
    this.setState(() => ({
      tooltip,
    }))
  }

  private onDrag = (x: XScale, offset: number) => {
    const { scrollByPeriod, variables } = this.props
    if (!scrollByPeriod) {
      return
    }
    const interval = freqToInterval(variables[0].frequency)
    const [startDate, endDate] = x.domain()
    const periodCount = interval.range(startDate, endDate).length
    const periodWidth = x.range()[1] / periodCount
    const periodsOffset = offset / periodWidth
    scrollByPeriod(-periodsOffset)
  }

  openScalingOptions = () => this.props.openScalingOptionsModal()
}

const mapDispatchToProps = (dispatch: any): IActionProps => ({
  openScalingOptionsModal: () => dispatch(ACTIONS.setActiveModal('scalingOptions')),
})

export default connect(null, mapDispatchToProps)(Sentry.withProfiler(Chart))
