import React from 'react'

import { isFunction } from '../../store/Transformations'
import {
  IParametrizedProps,
  IState,
  Variants,
  getParentVariant,
} from './AdvancedFunctionUtils'
import { FnChangeForm } from './FnChangeForm'
import { FnIndexForm } from './FnIndexForm'
import { FnSAForm } from './FnSAForm'
import { FnValueForm } from './FnValueForm'

const getComponent = (func: AdvancedFunction) => {
  if (func in Variants) {
    return {
      component: FnChangeForm,
      args: [12],
    }
  }
  if (func === 'index') {
    return {
      component: FnIndexForm,
      args: [null, 100],
    }
  }
  if (func === 'value') {
    return {
      component: FnValueForm,
      args: [],
    }
  }
  if (func === 'sa') {
    return {
      component: FnSAForm,
      args: [],
    }
  }
  return { component: null, args: [] }
}

const getState = (props: IParametrizedProps) => {
  const { series } = props
  const baseFunc = props.function
  const transformation = series as ITransformation
  const transformationFunc = (transformation.func || '').replace(
    'C',
    ''
  ) as AdvancedFunction
  const shouldLoadArgs =
    isFunction(series) && baseFunc === getParentVariant(transformationFunc)
  const args = shouldLoadArgs
    ? transformation.args.slice(1)
    : getComponent(props.function).args
  const func = shouldLoadArgs ? transformationFunc : baseFunc
  return {
    func,
    baseFunc,
    args,
    centered: shouldLoadArgs && transformation.func.endsWith('C'),
  }
}

export class AdvancedFunctionForm extends React.Component<IParametrizedProps, IState> {
  state = getState(this.props)

  onSave = () =>
    this.props.onSave({
      func: (this.state.func + (this.state.centered ? 'C' : '')) as AdvancedFunction,
      args: [this.props.series, ...this.state.args],
    })

  componentDidMount() {
    if (!this.props.isNested) {
      this.onSave()
    }
  }

  componentDidUpdate = (prevProps: IParametrizedProps, prevState: IState) => {
    const series = this.props.series as ITransformation
    const prevSeries = prevProps.series as ITransformation
    if (
      series.func === prevSeries.func &&
      series.func === this.state.func &&
      JSON.stringify(series.args) !== JSON.stringify(prevSeries.args) &&
      JSON.stringify(series.args.slice(1)) !== JSON.stringify(prevState.args)
    ) {
      this.setState({ args: series.args.slice(1) })
    }
  }

  render() {
    const setForm = (s: IState) => this.setState(s, this.onSave)
    const props = {
      ...this.props,
      state: this.state,
      setForm,
    }
    const Component = getComponent(this.props.function).component
    return <Component {...props} />
  }
}
