import { CLIENT } from '../api/config'

export async function fetchAll<T>(
  url: string,
  params: SMap<string | number> = {}
): Promise<T[]> {
  let data = (await CLIENT.get<IPagination<T>>(url, { params })).data
  let result = data.data
  while (data.next) {
    data = (await CLIENT.get<IPagination<T>>(data.next, { params })).data
    result = result.concat(data.data)
  }
  return result
}
