import * as d3 from 'd3'
import * as React from 'react'
import * as FauxDOM from 'react-faux-dom'

import { renderCorrLabel } from '../../services/hd3'
import { isCorrLabelShown } from '../../services/series'

export interface IProps {
  correlation: ICorrelation
  variables: IDataSeries[]
}

const Correlation = ({ correlation, variables }: IProps) => {
  if (!isCorrLabelShown(variables, correlation)) {
    return null
  }
  const svg = FauxDOM.createElement('g')
  const selection = d3.select(svg)
  renderCorrLabel(selection, correlation)

  return <>{React.Children.map(svg.toReact(), c => c.props.children)}</>
}

export default React.memo(Correlation)
