import * as React from 'react'
import { bindBem } from '../bem'
import { RadioContext, IContextProps } from './RadioGroup'

import './RadioButton.scss'

export interface IProps {
  value: string
  children?: any
  selected: boolean
  disabled?: boolean
  onChange: (value: string) => void
}

export const RadioOption = ({
  value,
  children,
  selected,
  onChange,
  disabled,
}: IProps) => {
  const { block, element } = bindBem('RadioButton')

  return (
    <div
      tabIndex={1}
      className={block({ selected, disabled })}
      onClick={() => (disabled ? null : onChange(value))}
      data-value={value}
    >
      <div className={element('Circle')} />
      <span className={element('Label')}>{children}</span>
    </div>
  )
}

export default (props: {
  disabled?: boolean
  value: string
  children: React.ReactNode
}) => (
  <RadioContext.Consumer>
    {({ selectedValue, onChange }: IContextProps) => (
      <RadioOption
        onChange={onChange}
        selected={props.value === selectedValue}
        value={props.value}
        disabled={props.disabled}
      >
        {props.children}
      </RadioOption>
    )}
  </RadioContext.Consumer>
)
