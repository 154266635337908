import { connect } from 'react-redux'

import { ScalingOptionsDialog, IStateProps, IActionProps } from './ScalingOptionsDialog'
import { IRootState } from '../../store'
import { setScallingOptions } from '../../store/Series/thunks'
import * as selectors from '../SeriesContainer/selectors'

export const mapStateToProps = (state: IRootState): IStateProps => {
  const { variables, scale, seriesSettings, initialScallingOptions } = state.series

  const scaleSelector = selectors.isGlobalScale(scale.type)
    ? selectors.globalScaleSelector
    : selectors.localScaleSelector

  const selectorState: selectors.State = {
    scale,
    variables,
    settings: seriesSettings,
    width: 500,
  }

  return {
    initialScallingOptions,
    variables,
    scale,
    computedScale: scaleSelector(selectorState),
  }
}

export const mapActionsToProps: IActionProps = {
  setScallingOptions,
}

export default connect(mapStateToProps, mapActionsToProps)(ScalingOptionsDialog)
