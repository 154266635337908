import * as React from 'react'
import { connect } from 'react-redux'
import { match } from 'react-router-dom'
import { IRootState, TDispatch } from '../store'
import * as admin from '../store/Admin'
import { bindBem } from '../bem'
import { IBadRequestErrorData } from 'api/config'
import { openToast as openToastAction } from '../store/User'
import { goBack as back } from 'connected-react-router'
import { isAdmin, isHaverAdmin } from '../services/user'
import { dbsCompare } from '../services/databases'

import EditOrgForm, { Fields } from 'components/Admin/EditOrgForm'
import OrganizationPane from 'components/Admin/OrganizationPane'
import { Modal } from 'components/Modal'
import { RemoveOrgConfirmation } from 'components/Admin/RemoveOrgConfirmation'

import './Organization.scss'

interface IProps {
  match: match<{ id: string }>
}

interface IStateProps {
  me: IUser
  organizationUsers: IUser[]
  organization: IOrganization
  packages: IDBPackage[]
  databases: { id: string; name: string }[]
}

interface IActionProps {
  fetchOrganization: (id: number) => void
  goBack: () => void
  deleteOrganization: (org: IOrganization) => void
  patchOrganization: (
    id: number,
    data: Partial<IOrganization>,
    onError: (errors: IBadRequestErrorData) => void
  ) => void
  openToast: (toast: Partial<IToast>) => void
  clearOrganization: () => void
}

const Organization: React.FC<IProps & IStateProps & IActionProps> = ({
  me,
  fetchOrganization,
  organization,
  databases,
  patchOrganization,
  goBack,
  packages,
  openToast,
  clearOrganization,
  deleteOrganization,
  match: {
    params: { id },
  },
}) => {
  const { block, element } = bindBem('Organization')

  const organizationId = React.useCallback(
    () => Number(isHaverAdmin(me.role) ? Number(id) : me.organizationId),
    [me, id]
  )

  const [isDeleteOrgModalOpen, setDeleteOrgModalOpen] = React.useState(false)

  React.useEffect(() => {
    fetchOrganization(organizationId())
    return clearOrganization
  }, [organizationId])

  const onToggleDeleteOrgModal = () => {
    setDeleteOrgModalOpen(!isDeleteOrgModalOpen)
  }

  const confirmDeleteOrg = () => {
    deleteOrganization(organization)
    onToggleDeleteOrgModal()
    goBack()
  }

  return (
    <div className={block()}>
      <div className={element('Sidebar')}>
        {isAdmin(me.role) && organization && (
          <EditOrgForm
            org={organization}
            databases={databases}
            onEdit={patchOrganization}
            goBack={goBack}
            onDelete={onToggleDeleteOrgModal}
            packages={packages}
            openToast={openToast}
            interactive={isHaverAdmin(me.role)}
            hideFields={
              isHaverAdmin(me.role) ? [] : [Fields.ACCOUNT_NUMBER, Fields.ORG_STATUS]
            }
          />
        )}
      </div>
      <div className={element('Pane')}>
        <OrganizationPane organization={organization} />
      </div>
      <Modal isOpen={isDeleteOrgModalOpen} onClose={onToggleDeleteOrgModal}>
        <RemoveOrgConfirmation
          callback={confirmDeleteOrg}
          onClose={onToggleDeleteOrgModal}
          obj={organization}
        />
      </Modal>
    </div>
  )
}

const mapStateToProps = (state: IRootState): IStateProps => {
  const { user: me } = state.user
  const { dbNames } = state.databases
  const { users, selectedOrganization: organization, packages } = state.admin

  const databases = Object.keys(dbNames)
    .sort(dbsCompare)
    .map(k => ({
      id: k,
      name: dbNames[k] ? `${k} ${dbNames[k]}` : k,
    }))

  const organizationUsers = (users || []).filter(
    u => organization && u.organizationId === organization.id
  )

  return {
    me,
    organization,
    packages,
    organizationUsers,
    databases,
  }
}
const mapDispatchToProps = (dispatch: TDispatch): IActionProps => ({
  patchOrganization: (
    id: number,
    data: Partial<IOrganization>,
    onError: (errors: IBadRequestErrorData) => void
  ) => dispatch(admin.patchOrganization(id, data, onError)),
  deleteOrganization: (org: IOrganization) => dispatch(admin.deleteOrganization(org)),
  fetchOrganization: (id: number) => dispatch(admin.fetchOrganization(id)),
  clearOrganization: () => dispatch(admin.ACTIONS.deselectOrganization()),
  goBack: () => dispatch(back()),
  openToast: (toast: Partial<IToast>) => dispatch(openToastAction(toast)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Organization)
