import * as React from 'react'
import { bindBem } from '../../bem'
import { KebabMenu } from '../KebabMenu'
import { Link } from '../Link'
import { FilePickerLink } from '../FilePicker'
import { ADMIN } from '../../messages'

import './DatabaseKebabMenu.scss'

interface IProps {
  onImport?: (file: File) => void
  onDelete?: () => void
}

export const DatabaseKebabMenu: React.FunctionComponent<IProps> = ({
  onImport = null,
  onDelete = null,
}) => {
  const { block } = bindBem('DatabaseKebabMenu')

  return (
    <div className={block()}>
      <KebabMenu>
        {onImport && (
          <FilePickerLink
            onFilePicked={onImport}
            accept=".xml,.zip"
            text={ADMIN.DB.ACTIONS.LABELS.IMPORT}
          />
        )}
        {onDelete && <Link onClick={onDelete}>{ADMIN.DB.ACTIONS.LABELS.DELETE}</Link>}
      </KebabMenu>
    </div>
  )
}
