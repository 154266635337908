import React from 'react'

import { block } from '../bem'
import { Button } from './Button'
import { withResponsive } from './Responsive'
import { INFO, INFO_LIGHT } from './SVG/Info'

import './ZendeskMobileButton.scss'

type Props = {
  floating?: boolean
  isMobile: boolean
}

export function ZendeskButton(props: Props) {
  if (!props.isMobile) {
    return null
  }
  function onClick() {
    window.zE?.(() => window.zE.activate())
  }
  const { floating } = props
  const className = block('ZendeskMobileButton', { floating })
  return (
    <Button
      onClick={onClick}
      style={floating ? 'dark' : 'text'}
      icon={floating ? INFO_LIGHT : INFO}
      size="small"
      className={className}
    />
  )
}

export default withResponsive<Props>(ZendeskButton)
