// eslint-disable max-len
// eslint-disable max-lines
// eslint-disable no-template-curly-in-string
import { Freq } from './services/series'

export const LOGIN_ERROR = 'Invalid email/password combination. Please try again.'
export const RESTRICTED_LOGIN_ERROR = 'Login from an unauthorized network address denied.'
export const USER_INACTIVE = 'Your account is inactive. Please contact client support.'
export const ORG_INACTIVE =
  'Your organization is inactive. Please contact client support.'
export const FORGOT_PASSWORD = 'I forgot my password'
export const LOGIN = 'Login'
export const LABEL_EMAIL = 'email'
export const LABEL_PASSWORD = 'password'
export const LABEL_TOKEN = 'enter code'
export const RESEND_OTP = 'Resend code'
export const CONTINUE = 'Continue'
export const MFA_TITLE = '2-STEP VERIFICATION'
export const MFA_SENT = (email: string) =>
  `We sent an email with a verification code to: <strong>${email}</strong>`
export const MFA_SENT_RAW = 'We sent an email with a verification code to your mailbox'
export const MFA_INVALID = 'Code is not valid anymore'
export const MFA_EXPIRED = 'Code expired. Use Resend code link to acquire new one.'
export const MFA_INCORRECT = 'Code is not correct'

export const USER_FORM_TOASTS = {
  RESEND_SUCCESS: 'The invitation email was sent.',
  RESEND_FAILED: 'Could not resend invitation.',
}

const currentYear = new Date().getFullYear()
export const CLIENT_SUPPORT = {
  HEADER: 'Client Support',
  DESCRIPTION:
    'Please call our client support line staffed by database managers with extensive knowledge of economic data, or email our support staff with any inquires.',
  AVAILABLE1: 'Available 24hr',
  AVAILABLE2: '8AM Sun - 7PM Fri ET',
  EMAIL_ADDRESS: 'Email address:',
  FOOTER: 'Email address:',
  COPYRIGHT: `Copyright ${currentYear} by Haver Analytics. All rights reserved.`,
  FEEDBACK: 'Feedback',
  CONTACT_US: 'Contact Us',
  DISCLAIMER: 'Disclaimer',
  PRIVACY: 'Privacy',
  PRIVACY_POLICY: 'Privacy Policy',
  LEGAL: 'Legal',
  HELP: 'Help',
  ABOUT: 'About',
  DATA_NEWS: 'Data News',
  RELEASE_CALENDARS: 'Release Calendars',
}

export const SEARCH_PANEL = {
  TITLE: 'Search',
  PLACEHOLDER: 'Enter keywords to Search',
  RESULTS: 'Results for',
  INFO: 'Start searching',
  FINDING: 'Finding results',
  SUBINFO: 'To display results',
  DATABASES: 'databases',
  SERIES: 'series',
  NO_RESULTS_INFO: 'No results for',
  NO_RESULTS_SUBINFO: 'Try different query',
  BACK: 'Back to directory',
  HINT: 'Enter keywords or series name',
}

export const SHORTCUTS_PANEL = {
  TITLE: 'Keyboard shortcuts',
  SHORTCUTS: {
    DISPLAY_DATABASE_LIST: 'Display the Database List',
    PRINT: 'Print',
    ZOOM: 'Open Zoom Panel',
    SET_TITLES: 'Set Titles',
    TOGGLE_LEGENDS: 'Show/Hide Legends',
    SCROLL_LEFT: 'Scroll left',
    SCROLL_RIGHT: 'Scroll right',
    SCROLL_LEFT_BY_PERIOD: 'Scroll left by period',
    SCROLL_RIGHT_BY_PERIOD: 'Scroll right by period',
    INCREMENT_OFFSET: 'Lead series',
    DECREMENT_OFFSET: 'Lag series',
    TOGGLE_LOG_SCALE: 'Toggle Log Scale',
  },
}

export const DATA_DIRECTORY_BANNER = {
  MAIN_TITLE: 'Are you interested in this database?',
  EMAIL_US: 'Email us:',
}

export const SELECT_SERIES_PANEL = {
  TITLE: 'Add a series',
  SUBTITLE: 'Up to 8 series can be added',
}

export const SIDEBAR = {
  DATA_DIRECTORY: 'DATA DIRECTORY',
  DIRECTORY: 'Directory',
  GRAPH: 'Graph',
  TOOLS: 'Tools',
  FUNCTIONS: 'Functions',
  EXPORT: 'Export',
  SERIES: 'Series',
  ADD_SERIES: 'Add series',
  UNFREEZE_SERIES: 'Unfreeze',
  START_NEW: 'Start new graph',
  COPY: 'Copy',
  COPIED: 'Copied',
  DATA_DIRECTORY_ALL: 'ALL',
  DATA_DIRECTORY_STARRED: 'FAVORITES',
  DEFAULT_MENU_DESCRIPTION: 'Variants available',

  SERIES_TAB: {
    INFORMATION: 'Information',
    PARAMETERS: 'Parameters',
    UNDO: 'Undo',
    VARIABLE_REMOVED: 'Series removed!',
    MAX_VARIABLES_ERROR: 'You have added the maximum number of series.',
    DATA_MARKERS: 'Data markers',
    DATA_LABELS: 'Data labels',
    TREND_LINE: 'Trend Line',
    REPLACE_SERIES: 'Replace Series',
  },
  EXPORT_TAB: {
    TITLE: 'Export & Sharing',
    PRINT: 'Print',
    PORTRAIT: 'PORTRAIT',
    LANDSCAPE: 'LANDSCAPE',
    GRAPH_N_DATA: 'Graph & Data',
    GRAPH_ONLY: 'Graph only version',
    DOWNLOAD_DATA: 'DOWNLOAD DATA',
    DOWNLOAD: 'Download...',
    EXPORT_GRAPH: 'EXPORT GRAPH',
    COPY_DATA_LINK: 'Copy Data Link',
    SHARE_LINK: {
      TITLE: 'Share link',
      UPDATE: 'Update',
      GENERATE: 'Generate link',
      GENERATE_NEW: 'Generate new link',
      PNG_LABEL: 'Png',
      SVG_LABEL: 'Svg',
    },
    EXPORT_API_DATA: {
      TITLE: 'Export API data',
      URL: 'URL',
      PAYLOAD: 'Payload',
    },
  },
  GRAPH_OPTIONS: {
    TITLE: 'Graph options',
    GENERAL: 'General',
    TICKS: 'Ticks',
    X_AXIS_TICKS: 'X Axis Ticks',
    ALL_TICKS_MARKS: 'All ticks marks',
    MAJOR_TICKS_MARKS: 'Major ticks marks',
    GRIDS: 'Grids',
    GRAPH_TYPES: 'Graph Types',
    LINE: 'Line Graph',
    SPLINE_LINE: 'Spline line',
    LINE_SHADOW: 'Line Shadow',
    BAR: 'Bar Graph',
    LEGEND: 'Legend',
    LEGEND_ON: 'Show',
    LEGEND_OFF: 'Hide',
    DATA_MARKERS: 'Data markers',
    DATA_LABELS: 'Data labels',
    ALL_DATA_LABELS: 'All',
    LAST_DATA_LABELS: 'Last',
    RECESSION: 'Recession',
    Y_AXIS_TITLES: 'Y-axis titles',
    Y_AXIS_LOG_SCALE: 'Y-axis Log Scale',
    HORIZONTAL_GRID: 'Horizontal grid',
    VERTICAL_GRID: 'Vertical grid',
    SHADING: 'Shading',
    LINES: 'Lines',
    COUNTRY: 'Country',
    SET_TITLES: 'Set / Update Legend...',
    RESET_ALL: 'Reset all',
    COUNTRY_DIALOG: {
      TITLE: 'Recession country',
      INPUT_PLACEHOLDER: 'Type to filter',
    },
    ZOOM: 'Zoom',
    ZOOM_DIALOG: {
      TITLE: 'Zoom',
      AXIS: 'axis',
      NOTE: 'Note: Leave blank and click "Apply" to see the total available time span.',
      NOTE_OPTIONS:
        'Use: d - days, w - week, m - month, q - quarter for more precise zoom. Example: type 3m for 3 month zoom',
      INFO: 'of Years',
    },
  },
  AGGREGATION: {
    TITLE: 'Aggregation',
  },
  SCALING_OPTIONS: {
    TITLE: 'Scaling options',
  },
  TRANSFORMATION: {
    TITLE: 'Perform Transformation',
  },
  PARAMETERS: {
    TITLE: 'Series Parameters',
    COPY: 'Copy to clipboard',
    COPIED: 'Copied!',
    FREQUENCY: 'Frequency',
    START_DATE: 'Start date',
    END_DATE: 'End Date',
    NO_OF_PERIODS: '# of Periods',
    MAX_NO_OF_PERIODS: 'MAX # of Periods',
    MAGNITUDE: 'Magnitude',
    DECIMAL_PRECISION: 'Decimal precision',
    DIFFERENCE_TYPE: 'Difference type',
    AGGREGATION_TYPE: 'Aggregation type',
    DATA_TYPE: 'Data type',
    UPDATE_GROUP: 'Update group',
    GEOGRAPHY: 'Geography',
    LAST_MODIFIED: 'Last modified',
    SHORT_SOURCE: 'Short source',
    LONG_SOURCE: 'Long source',
  },
  UNDEFINED: 'Undefined',
  TOOLTIPS: {
    DRAG_AND_DROP: {
      HEADER: 'Now you can use Drag & Drop',
      REARRANGE: 'Rearrange order of the series',
      TRANSFORMATION: 'Perform Transformation',
      BUTTON: 'I got it',
    },
  },
  DATA_DIRECTORY_TAB: {
    OTHER_DATABASES: 'Others',
  },
}

export const AGGREGATION_LABEL: { [index: number]: string } = {
  [Freq.Mon]: 'Weekly',
  [Freq.Monthly]: 'Monthly',
  [Freq.Quarterly]: 'Quarterly',
  [Freq.Annually]: 'Annual',
}
export const AGGREGATION_TYPES: { [index: number]: string } = {
  1: 'Average',
  2: 'Sum',
  3: 'End of Period',
  0: 'Undefined',
  9: 'Not Allowed',
}

export const SETTINGS_DROPDOWN = {
  ADMIN_SETTINGS: 'Admin Settings',
  CHANGE_PASSWORD: 'Change password',
  PROFILE: 'Profile',
  LOGOUT: 'Logout',
}

export const GRAPH: { [index: string]: string } = {
  [Freq.Annually]: 'Annually',
  [Freq.Quarterly]: 'Quarterly',
  [Freq.Monthly]: 'Monthly',
  [Freq.Mon]: 'Weekly',
  [Freq.Tue]: 'Weekly',
  [Freq.Wen]: 'Weekly',
  [Freq.Thu]: 'Weekly',
  [Freq.Fri]: 'Weekly',
  [Freq.Daily]: 'Daily',
  SOURCE: 'Source',
  SCATTER_RANGE: (start: string, end: string) => `${start} - ${end}`,
}

export const EDIT_TITLES_DIALOG = {
  TITLE: 'Title & Legend',
  SERIES_NAME_PLACEHOLDER: 'TYPE SERIES NAME HERE...',
  SERIES_TITLE_TOO_LONG: 'Series name must be shorter than 80 characters.',
  RESET_ALL: 'Reset All',
  RESET: 'Reset',
  APPLY: 'Apply',
}

export const ZOOM_DIALOG = {
  TITLE: 'Zoom',
  RESET: 'Reset',
  APPLY: 'Apply',
  ZOOM_FORMAT: 'Zoom should be a number with an optional unit',
  ZOOM_TO_LARGE: 'Zoom cannot be larger than 100 years',
  ZOOM_WRONG_UNIT: 'Accepted units are: y, q, m, w, d.',
}

export const USER_PROFILE = {
  TITLE: 'User Profile',
  CHANGE_PASSWORD: {
    TITLE: 'Change password',
    CURRENT_PASSWORD: 'Current password',
    CURRENT_PASSWORD_PLACEHOLDER: 'Type your current password',
    NEW_PASSWORD: 'New password',
    NEW_PASSWORD_PLACEHOLDER: 'Your new password',
    CONFIRM_NEW_PASSWORD: 'Confirm new password',
    CONFIRM_NEW_PASSWORD_PLACEHOLDER: 'Re-enter your new password',
    BUTTON_LABEL: 'Update my password',
    ERRORS: {
      REQUIRED: 'This field is required',
      BAD_ARGUMENT:
        'Passwords should contain at least: 1 uppercase letter, 1 lowercase letter, 1 digit, 1 special character and should be at least 8 characters long',
      WRONG_PASSWORD: 'Wrong password',
      DO_NOT_MATCH: 'Passwords do not match',
    },
  },
}

export const ADMIN = {
  STATUS: {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    EXPIRING_SOON: 'EXPIRING SOON',
    GRACE_PERIOD_UNTIL: (date: string) => `GRACE PERIOD UNTIL ${date}`,
    EXPIRED: 'EXPIRED',
    DEACTIVATED: 'DEACTIVATED',
    GRACE_PERIOD: 'GRACE PERIOD',
  },
  ORG: {
    TITLE: 'Organizations',
    SEARCH_PLACEHOLDER: 'Filter through organizations…',
    ACCOUNT_NUMBER: 'Account Number',
    ADD_ORGANIZATION: 'Add Organization',
    EXPORT: 'Export',
    ORG_NAME: 'Organization name',
    USERS: 'Users',
    DATABASES: 'Databases',
    STATUS: 'Status',
    PATCH_ERROR: (orgName: string) => `${orgName} could not be updated`,
    ACTIONS: {
      LABELS: {
        DUPLICATE: 'Duplicate',
        ACTIVATE: 'Activate',
        DEACTIVATE: 'Deactivate',
        DELETE: 'Delete',
      },
    },
  },
  DB: {
    TITLE: 'Databases',
    SEARCH_PLACEHOLDER: 'Filter through databases…',
    DATABASE_NAME: 'Database name',
    ORGANIZATIONS: 'Organizations',
    CTREE: 'CTree',
    MENUS: 'Menus',
    STATUS: 'Status',
    ACTIONS: {
      LABELS: {
        IMPORT: 'Import',
        DELETE: 'Delete',
      },
    },
    TOASTS: {
      MENU_PAGES_IMPORT_ERROR: 'There was a problem during menu pages import',
      MENU_PAGES_IMPORTED: 'Menu pages were successfully imported',
      DATABASE_REMOVED: 'The database was successfully removed',
    },
  },
  PACKAGES: {
    TITLE: 'Packages',
    SEARCH_PLACEHOLDER: 'Filter through packages…',
    ADD_PACKAGE: 'Add Package',
    PACKAGE_NAME: 'Package name',
    ORGANIZATIONS: 'Organizations',
    DATABASES: 'Databases',
    STATUS: 'Status',
  },
  USERS: {
    TITLE: 'LIST OF USERS',
    ADD_USER: 'Add User',
    NAME: 'NAME',
    EMAIL_ADDRESS: 'EMAIL ADDRESS',
    TYPE: 'TYPE',
    STATUS: 'STATUS',
    IS_MIGRATED: 'MIGRATED?',
    API_STATUS: 'API STATUS',
    ROLES: {
      user: 'USER',
      org_admin: 'ADMIN',
      account_admin: 'ACCOUNT ADMIN',
      admin: 'SUPER ADMIN',
    },
    USER_LIMIT_ERROR: [
      'You have reached the user limit.',
      'You must delete a user in order to add a new one.',
    ],
    API_USAGE_TOOLTIP: (num: number) =>
      `API tokens have been used from ${num} unique IPs in the last 24 hours`,
    IMPORT: 'Import from file',
    INVALID_FILE_TITLE: 'INVALID FILE',
    INVALID_FILE_MESSAGE: `We cannot import this file.<br/>
    Please correct the file and make sure that it contains names and email addresses in separate columns.`,
  },
  DB_CONFIG_NAME: {
    US: 'US',
    INT: 'International',
  },
  TOKENS: {
    TITLE: 'LIST OF TOKENS',
    NO_TOKENS: 'No tokens yet for this user',
    BUTTONS: {
      DEACTIVATE: 'Deactivate',
      EXTEND: 'Extend',
      REFRESH: 'Refresh',
    },
    TOASTS: {
      TOKEN_EXTENDED: 'The token was successfully extended',
      EXTENDING_ERROR: 'Could not extend the token',
      TOKEN_REVOKED: 'The token was successfully revoked',
      REVOKING_ERROR: 'Could not revoke the token',
      TOKENS_REVOKED: 'The tokens were successfully revoked',
      TOKENS_REVOKING_ERROR: 'Could not revoke the tokens',
      TOKENS_EXTENDED: 'The tokens were successfully extended',
      TOKENS_EXTENDING_ERROR: 'Could not extend the tokens',
      TOKENS_REFRESHED: 'The tokens expiry dates were successfully refreshed',
      TOKENS_REFRESHING_ERROR: 'Could not refresh the tokens expiry dates',
    },
    LABELS: {
      ID: 'ID',
      TOKEN: 'TOKEN',
      TOKEN_TYPE: 'TOKEN TYPE',
      ACTIVE_UNTIL: 'ACTIVE UNTIL',
      CREATED: 'CREATED',
      STATUS: 'STATUS',
      LAST_USAGE: 'LAST USAGE',
      USER_NAME: 'USER NAME',
    },
    MODALS: {
      DEACTIVATE: {
        TITLE: 'DEACTIVATE TOKENS',
        INFO: 'Deactivation is a permanent process. Please be careful to double check you have selected the correct tokens. Contact us with any questions.',
        SELECTED: 'SELECTED TOKENS',
        BUTTON: 'Deactivate',
      },
      EXTEND: {
        TITLE: 'EXTEND TOKENS',
        SELECTED: 'SELECTED TOKENS',
        EXTEND_UNTIL: 'Extend until (UTC)',
        BUTTON: 'Extend',
      },
      REFRESH: {
        TITLE: 'REFRESH TOKENS',
        INFO: 'Selected tokens will have their expiry dates refreshed.',
        SELECTED: 'SELECTED TOKENS',
        BUTTON: 'Refresh',
      },
    },
    STATUS: {
      ACTIVE: 'ACTIVE',
      DEACTIVATED: 'DEACTIVATED',
      EXPIRED: 'EXPIRED',
    },
  },
  MENUS: {
    TITLE: 'MENU LAYOUTS',
    ADD_NEW_TITLE: 'ADD NEW MENU LAYOUT',
    SAVE: 'Save',
    SAVING: 'Saving...',
    SEPARATOR: {
      LABEL: '(SEPARATOR)',
    },
    DEFAULT_NEW_GROUP_NAME: 'New group',
    DEFAULT_NEW_CATEGORY_NAME: 'New category',
    MENU_OPTIONS: {
      DELETE_TOOLTIP: 'Delete menu layout',
      EDIT_NAME_TOOLTIP: 'Edit menu name',
      COLLAPSE_ALL: 'Collapse all',
      EXPAND_ALL: 'Expand all',
    },
    GROUP_OPTIONS: {
      ADD_DB_TOOLTIP: 'Add database',
      EDIT_NAME_TOOLTIP: 'Edit group name',
      MORE_OPTIONS_TOOLTIP: 'Other options',
      ADD_NEW_GROUP: 'Add new group',
      ADD_NEW_CATEGORY: 'Add category',
      ADD_NEW_SEPARATOR: 'Add separator',
      COLLAPSE_BY_DEFAULT: 'Collapse by default',
      DELETE_GROUP: 'Delete',
      DELETE_GROUP_DISABLED_TOOLTIP: 'You can delete only empty groups',
      EXPAND: 'Expand',
      COLLAPSE: 'Collapse',
    },
    CATEGORY_OPTIONS: {
      EDIT_NAME_TOOLTIP: 'Edit category name',
      MORE_OPTIONS_TOOLTIP: 'Other options',
      ADD_NEW_GROUP: 'Add new group',
      DELETE_CATEGORY: 'Delete',
      DELETE_CATEGORY_DISABLED_TOOLTIP: 'You can delete only empty categories',
    },
    SEPARATOR_OPTIONS: {
      DELETE_SEPARATOR: 'Delete separator',
    },
    DB_OPTIONS: {
      DELETE_DATABASE: 'Delete database',
    },
    MODALS: {
      ADD_MENU: {
        TITLE: 'ADD NEW MENU LAYOUT',
        NAME_PLACEHOLDER: 'Enter name of new layout',
        COPY_FROM_PLACEHOLDER: 'Select layout to copy from',
        ADD: 'Add',
        ADDING: 'Adding...',
        VALIDATION: {
          NAME_REQUIRED: 'Please enter the menu layout name',
          NAME_FORMAT: 'Name can contain only letters, numbers and spaces',
          NAME_EXISTS: 'Menu with this name already exists',
          COPY_FROM_REQUIRED: 'Please select menu layout to copy from',
        },
      },
      ADD_DB: {
        TITLE: 'SELECT DATABASE',
        PLACEHOLDER: 'Search',
        ADD: 'Add',
        ADDING: 'Adding...',
        NO_UNASSIGNED_DBS: 'No unassigned databases',
        NO_MATCH: (match: string) =>
          `No matching unassigned databases for "${
            match.length > 10 ? `${match.substring(0, 10)}...` : match
          }"`,
      },
      EDIT_NAME: {
        TITLE: 'EDIT NAME OF THE MENU LAYOUT',
        NAME_PLACEHOLDER: 'Enter new name of the menu layout',
        SAVE: 'Save',
        SAVING: 'Saving...',
        VALIDATION: {
          NAME_REQUIRED: 'Please enter new name of the menu layout',
          NAME_FORMAT: 'Name can contain only letters, numbers and spaces',
          NAME_EXISTS: 'Menu with this name already exists',
          NAME_SAME: 'Please enter new name of the menu layout',
        },
      },
      DELETE: {
        TITLE: 'DELETE MENU LAYOUT',
        INFO: 'You are about to delete menu layout which might be already in use. Please, pick another menu to be assigned as a replacement.',
        NO_REPLACEMENT_INFO:
          'Unfortunately, there is no other menus you can use as a replacement. Add another menu layout and try again.',
        SAVE: 'Save',
        SAVING: 'Saving...',
      },
    },
    TOASTS: {
      MENUS_SAVED: 'The menu layouts were successfully saved',
      MENUS_SAVING_ERROR: 'Could not save the menu layouts',
      MENU_SAVED: 'The menu layout was successfully saved',
      MENU_SAVING_ERROR: 'Could not save the menu layout',
      MENU_SAVING_CONFLICT:
        'Database order has been changed in the meantime. Please refresh the page and redo your changes',
      MENU_CREATED: 'The menu layout were successfully created',
      MENU_CREATING_ERROR: 'Could not create the menu layout',
      MENU_DELETED: 'The menu layout was successfully deleted',
      MENU_DELETING_ERROR: 'Could not delete the menu layout',
    },
  },
  TABS: {
    LABELS: {
      USERS: 'USERS',
      TOKENS: 'TOKENS',
      DATABASES: 'DATABASES',
      PACKAGES: 'PACKAGES',
    },
  },
}

export const FORGOT_PASSWORD_FORM = {
  FORGOT_YOUR_PASSWORD: 'Forgot your password?',
  YOUR_EMAIL_ADDRESS: 'Your email address',
  SIGN_IN: 'Sign In',
  RESET_MY_PASSWORD: 'Reset my password',
  INSTRUCTIONS_SENT:
    'We sent you an email with instructions on how to reset your password.',
  INVALID_EMAIL: 'Invalid email address.',
}

export const RESET_PASSWORD_FORM = {
  RESET_YOUR_PASSWORD: 'Reset your password',
  CREATE_YOUR_PASSWORD: 'Create your password',
  PASSWORD_SHOULD: 'Password should',
  SHOULD_BE_LONG: 'be at least 8 characters long',
  SHOULD_CONTAIN_CAPITAL_LETTER: 'contain at least 1 capital letter',
  SHOULD_CONTAIN_DIGIT: 'contain at least 1 digit',
  SHOULD_CONTAIN_SPECIAL_CHAR: 'contain at least 1 special character (!@#$%&*)',
  PASSWORD: 'password',
  NEW_PASSWORD: 'new password',
  REENTER_NEW_PASSWORD: 're-enter new password',
  PASSWORDS_DONT_MATCH: 'Re-entered password does not match.',
  CHANGE_MY_PASSWORD: 'Change my password',
  CREATE_MY_PASSWORD: 'Create my password',
  CONFIRMATION: 'Your password has been created. You can now log into your account',
  CONNECTION_ERROR: 'We encountered a problem. Try again later.',
  REVOKED_TOKEN: 'This link is no longer valid.',
}

export const ORGANIZATION_FORM = {
  TITLE: 'ADD NEW ORGANIZATION',
  NAME_LABEL: 'ORGANIZATION NAME',
  NAME_PLACEHOLDER: 'TYPE ORGANIZATION NAME',
  USER_LIMIT_LABEL: 'USER LIMIT',
  USER_LIMIT_PLACEHOLDER: 'SET USER LIMIT',
  ACCOUNT_NUMBER_LABEL: 'ACCOUNT NUMBER',
  ACCOUNT_NUMBER_PLACEHOLDER: 'TYPE ACCOUNT NUMBER',
  TYPE_LABEL: 'TYPE',
  DATABASES: 'DATABASES',
  PACKAGES: 'PACKAGES',
  CHECK_ALL: 'CHECK ALL',
  FILTER_PLACEHOLDER: 'FILTER THROUGH',
  ORG_STATUS: 'ORGANIZATION STATUS',
  API_STATUS: 'API STATUS',
  ADD: 'Add',
  PREVIEWED_DATABASES: 'PREVIEWED DATABASES',
  DATA_TAB: 'ORGANIZATION DATA',
  DATABASES_TAB: 'DATABASES',
  VALIDATIONS: {
    REQUIRED: 'This field is required',
    NAME_TOO_LONG: 'Name must be at most ${max} characters long',
    NAME_TOO_SHORT: 'Name must be at least ${min} characters long',
    USERS_LIMIT_NUMBER: 'User limit must be an integer equal or greater than 0',
    ACCOUNT_NUMBER_TOO_LONG: 'Account Number must be at most ${max} characters long',
    CIDRS: 'Provide at least single IP in CIDRS field if organization is restricted',
    INVALID_RATELIMIT: 'The rate limit format is incorrect.',
  },
  MENU_LAYOUT: 'MENU LAYOUT',
  DUPLICATED_DATA_FROM_LABEL: 'DUPLICATED DATA FROM',
  IS_MIGRATED: 'MIGRATED?',
  MFA_STATUS_LABEL: '2 FACTOR AUTHENTICATION',
  CIDRS: 'CIDRS LIST',
  IS_RESTRICTED: 'IP RESTRICTION',
  RENEWAL_DATE: 'RENEWAL DATE',
  RATELIMIT: 'RATE LIMIT',
  PLACEHOLDERS: {
    RENEWAL_DATE: 'i.e. 12/23/2022',
    RATELIMIT: 'N per (second / minute / ...)',
  },
}

export const USER_FORM = {
  ADD_BULK_TITLE: 'ADD NEW USERS',
  ADD_TITLE: 'ADD NEW USER',
  EDIT_TITLE: 'EDIT USER',
  NAME: 'NAME',
  EMAIL: 'EMAIL',
  API_STATUS: 'API STATUS',
  ACCESS: 'ACCESS',
  ROLE: 'TYPE',
  IP: 'IP',
  RATELIMIT: 'RATE LIMIT',
  PLACEHOLDER: {
    USERNAME: 'TYPE FIRST AND LAST NAME',
    EMAIL: 'TYPE EMAIL ADDRESS',
    IP: 'TYPE A PUBLIC IP ADDRESS',
    RATELIMIT: 'N per (second / minute / ...)',
  },
  ACCESS_TYPES: ['UNRESTRICTED', 'RESTRICTED'],
  ERROR_SAME_ORG:
    'This email address already exists in this organization. Please try again with a different email address or contact support.',
  ERROR_OTHER_ORG:
    "You cannot add this email address because it's linked with another organization. Please try again with a different email address or contact support.",
  VALIDATIONS: {
    REQUIRED: 'This field is required.',
    INVALID_IP: 'The IP address is invalid.',
    INVALID_EMAIL: 'The email address is invalid.',
    INVALID_RATELIMIT: 'The rate limit format is incorrect.',
  },
  ADD: 'Add',
  EDIT: 'Save',
  TOKEN_ACTIONS: {
    SEND_INVITE: 'Send Invite',
    COPY_LINK: 'Copy Invite Link',
    COPY_LINK_TOAST: 'Copied invite link to clipboard.',
    REVOKE: 'Revoke Invite',
  },
}

export const DEACTIVATE_ORG = {
  TITLE: 'DEACTIVATE ORGANIZATION',
  INFO1:
    'Upon deactivation, all of the user accounts (along with assigned tokens) will stop working.',
  INFO2: 'However, you can reactivate the organization (and its users) at any time.',
  DEACTIVATE: 'Deactivate',
}

export const REMOVE_ORG = {
  TITLE: 'REMOVE ORGANIZATION',
  QUESTION1: 'Do you want to remove',
  QUESTION2: ({ count }: any) => `which currently has ${count} users?`,
  INFO: 'This action will delete the organization and all of its users from the system. Are you sure you want to remove this organization?',
  REMOVE: 'Remove',
}

export const REMOVE_DATABASE = {
  TITLE: 'REMOVE DATABASE',
  QUESTION1: 'Do you want to remove',
  QUESTION2: ({ count }: any) => `used by ${count} organizations?`,
  INFO: 'This action will delete the database making it inaccessible for all of its users. Are you sure you want to remove this database?',
  REMOVE: 'Remove',
}

export const REMOVE_USER = {
  TITLE: 'REMOVE USER',
  QUESTION1: 'Are you sure you want to remove',
  QUESTION2: 'from the system?',
  INFO: 'They will lose access to HaverView.',
  REMOVE: 'Remove',
}

export const REMOVE_USERS = {
  TITLE: 'REMOVE USERS',
  QUESTION: 'Are you sure you want to remove these users from the system?',
  INFO: 'They will lose access to HaverView.',
  REMOVE: 'Remove',
}

export const REMOVE_PACKAGE = {
  TITLE: 'REMOVE PACKAGE',
  QUESTION1: 'Are you sure you want to permanently remove',
  QUESTION2: 'from the system?',
  INFO: 'All organizations will lose access to databases from this package.',
  REMOVE: 'Remove',
}

export const FREQUENCY_LABEL: { [index: number]: string } = {
  [Freq.Annually]: 'Year',
  [Freq.Quarterly]: 'Quarter',
  [Freq.Monthly]: 'Month',
  [Freq.Mon]: 'Week',
  [Freq.Tue]: 'Week',
  [Freq.Wen]: 'Week',
  [Freq.Thu]: 'Week',
  [Freq.Fri]: 'Week',
  [Freq.Sat]: 'Week',
  [Freq.Sun]: 'Week',
  [Freq.Daily]: 'Day',
}

export const FUNCTIONS: { [key in BasicFunction]: string } = {
  DIFA: 'Diff Annual Rate',
  'DIFA%': '% Annual Rate',
  'DIFF%': '% Period',
  'YRYR%': '% Year/Year',
  YRYR: 'Diff Year/Year',
  DIFF: 'Diff Period',
  DIFAL: 'Diff Log Annual Rate',
  DIFFL: 'Diff log Period',
  YRYRL: 'Diff Log Year/Year',
  LEVEL: 'Level (default)',
}

const PERIODS: { [key: number]: string } = {
  [Freq.Annually]: 'Years',
  [Freq.Quarterly]: 'Quarters',
  [Freq.Monthly]: 'Months',
  [Freq.Daily]: 'Days',
  [Freq.Mon]: 'Weeks',
}

export const ADVANCED_FUNCTIONS = {
  diff: 'Change',
  'diff%': '% Change',
  diffl: 'Log Change',
  movv: 'Level',
  index: 'Index',
  value: 'Value',
  ytd: 'Year To Date',
  dytd: 'Reverse YTD',
  zs: 'Z-Score',
  na2z: 'N/A to Zero',
  z2na: 'Zero to N/A',
  sa: 'Seasonal Adjustment',
  OPTIONS: {
    diff: 'Point-to-point',
    difa: 'Annualized',
    difv: 'Average',
    'diff%': 'Point-to-point',
    'difa%': 'Annualized',
    'difv%': 'Average',
    diffl: 'Point-to-point',
    difal: 'Annualized',
    difvl: 'Average',
    movv: 'Average',
    movt: 'Total',
    mova: 'Annualized',
  },
  PERIODS,
}

export const FUNCTIONS_DIALOG = {
  BASIC: 'BASIC',
  ADVANCED: 'ADVANCED',
  RESET: 'Reset',
  APPLY: 'Apply',
  ALLOW_NESTING: 'ALLOW NESTING',
  SEASONAL_ADJUSTMENT: 'Seasonal adjustment',
  ADD: 'Add',
  CANCEL: 'Cancel',
  CENTERED: 'Centered',
  CURRENCY_CONVERSION: 'Currency conversion',
  CURRENCY_DIALOG_TITLE: 'Currency Conversion Options',
  CURRENCY: 'Currency',
  CANT_SET_SEASONAL_ADJUSTMENT:
    'Seasonal adjustment is available only to series with Monthly or Quarterly frequency.',
  FORMAT_VALIDATION_MESSAGE: (field: string, formats: string[]) =>
    `${field} must match formats: ${formats.join(', ')}`,
  VALUE_VALIDATION_MESSAGE: (format: string, min: number, max: number) =>
    `${format} must be between ${min} and ${max}`,
  AT_LEAST_3_YEARS_REQUIRED: 'Start date and end date must be at least 3 years apart',
  DATE_AFTER_2099: 'Year cannot be greater than 2099',
  SA_BEGIN_DATE_LABEL: 'Begin date',
  SA_BEGIN_DATE_REQUIRED: 'Begin date is required',
  SA_END_DATE_LABEL: 'End date',
  SA_NOTE: 'Note: Leave blank to see the total available time span.',
}

export const SCATTER_MENU = {
  SCATTERED_GRAPH: 'Scatter graph',
  CORRELATION: 'Correlation',
  DESCRIPTION: 'To use different series drag & drop it between the 2 top series',
  NOT_ENOUGH_VARS: 'You need at least two series.',
  VAR_MISMATCH: 'These series have different frequency.',
  SCATTER_VAR_MISMATCH_HINT: 'To use Scatter Graph agregate',
  CORRELATION_VAR_MISMATCH_HINT: 'To use Correlation agregate',
  CANT_BE_AGGREATED: "can't be aggregated.",
  VAR_MISMATCH_TO: 'to',
  AGGREGATE: 'AGGREGATE',
  CANCEL: 'CANCEL',
  REGRESSION_LINE: 'Regression Line',
  REGRESSION_LINEAR: 'Linear',
  REGRESSION_EXP: 'Exponential',
  REGRESSION_LOG: 'Logarithmic',
  TIME_LINE: 'Time Line',
  TYPE_OF_AXIS: 'Type of scale',
  LEFT_AXIS: 'Left axis',
  LEFT_AND_RIGHT_AXIS: 'Left and right axis',
}

export const SERIESBOX_TRANSFORMATION = {
  UNIT_MISMATCH: (type1: string, type2: string) =>
    `Adding a series of type [${type1}] to a series of type [${type2}] is not recommended.`,
  FREQ_MISMATCH: (frequency: string) =>
    `Series have different frequencies. Result of this operation will be aggregated to ${frequency}.`,
}

export const TICKS_SCALE_FORM = {
  NUMBER_OF_TICKS: 'Number of ticks',
  AUTOMATIC: 'AUTOMATIC',
  INVERT_SCALE: 'Invert scale',
  MINMAX: 'MIN / MAX',
  INCREMENTAL: 'INCREMENTAL',
  VALUES: 'VALUES',
  VALUES_PLACEHOLDER: 'E.g. 1, 2, 3',
  MIN_SHOULD_BE_A_NUMBER: 'Min should be a number',
  MAX_SHOULD_BE_A_NUMBER: 'Max should be a number',
  SHOULD_BE_A_NUMBER: 'You must specify a number',
  IS_REQUIRED: 'This field is required',
  IS_NUMBER_ARRAY: 'Specify a sequence of numbers in format v1, v2, v3...',
  MORE_THAN_ONE_TICK: 'Specify more than one tick',
}

export const SCALING_OPTIONS_DIALOG = {
  RESET_ALL: 'Reset All',
  APPLY: 'Apply',
  TYPE_OF_SCALE: 'Type of scale',
  NEITHER: 'Neither (Default)',
  UNISCALE: 'Uniscale',
  GLOBAL: 'Global',
  BOTH: 'Both',
  SERIES_ASSIGNMENT: 'Series Assignment',
  MORE_OPTIONS: 'More options',
  LESS_OPTIONS: 'Less options',
  LEFT_SCALE: 'Left scale',
  RIGHT_SCALE: 'Right scale',
}

export const TOOLTIPS = {
  SHARE: 'Share',
  GRAPH_SETTINGS: 'Graph Settings',
  GRAPH_TYPE: 'Graph type',
  FUNCTION: 'Function',
  INTERPOLATION: 'Interpolation',
  CORRELATION: 'Correlation',
  LOG_SCALE: 'Log scale',
  ZOOM: 'Zoom',
  KEYBOARD_SHORTCUTS: 'Keyboard shortcuts',
  SUPPORT: 'Support',
  UNDO: 'Undo',
  RESET: 'Reset',
  TOOLBAR: {
    GRPAH_TYPE: 'Graph type – globally',
    FUNCTION: 'Function – globally',
    INTERPOLATION: 'Interpolation – globally',
  },
}

export const DEFAULT_GRAPH_TITLE = 'Add title'
export const DEFAULT_EXPORT_FILENAME = 'Haver - Export'

export const AGGREGATION_FORBIDDEN_TOAST = {
  title: 'Aggregation was not applied to all series',
  description: 'Performing aggregation is not allowed to ',
}

export const DB_STATS = {
  DIRECT_LABEL: 'Direct',
  FROM_PACKAGES_LABEL: 'From packages',
}

export const IMPORT_EXPORT = {
  IMPORT: 'Import selections',
  EXPORT: 'Export selections',
}

export const DB_TABS_TITLES = {
  DATABASES: 'Databases',
  PACKAGES: 'Packages',
}

export const API_ERRORS = {
  USERS: {
    GENERAL: {
      TITLE: 'Can not perform given operation.',
      SUBTITLE: (msg?: string) => msg || 'Please try again',
    },
  },
  DATABASES: {
    GENERAL: {
      TITLE: 'Database is unavailable.',
    },
  },
  SERIES: {
    NOT_FOUND: {
      TITLE: 'Selected series is currently not available.',
      SUBTITLE: '',
    },
    GENERAL: {
      TITLE: 'Please try again with another one.',
      SUBTITLE: 'Server error. Try again or contact helpdesk',
    },
    CORRELATION_INCORRECT_RANGE: {
      TITLE: 'Could not load correlation.',
      SUBTITLE: 'Not enough observations',
    },
    CORRELATION_BEFORE_MIN: {
      TITLE: 'Could not load correlation.',
      SUBTITLE: 'Time range must start after 1921-01-01',
    },
    PERMISSIONS: {
      TITLE: 'Please try again with another one.',
      SUBTITLE: "You don't have permission to display it",
    },
    SA_INCORRECT_RANGE: {
      TITLE: 'Invalid date range for seasonal adjustment.',
      SUBTITLE: 'Time range must include at least 3 years',
    },
    TRDLN_BEFORE_MIN: {
      TITLE: 'Invalid date range for trendline.',
      SUBTITLE: 'Time range must start after 1921-01-01',
    },
  },
}

export const MAINTENANCE = {
  TITLE: 'Maintenance mode',
  CONTENT:
    'HaverView is temporarily unavailable while we perform system maintenance and upgrades. The site will be operational shortly. Don’t refresh your page if you don’t want to lose your data/progress. Thank you for your patience.',
}

export const DUPLICATED_VALUE = (key: string, value: string) =>
  `Duplicated ${key} for ${value}`
export const PLEASE_WAIT = 'Please wait'
