import * as React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { bindBem } from 'bem'
import { ADMIN } from 'messages'
import { ITree } from 'containers/MenusAdmin/services/menu.types'
import { useUnassignedDbs } from 'containers/MenusAdmin/useUnassignedDbs.hook'
import { Dropdown } from 'components/Dropdown'
import { Button } from 'components/Button'

import close from '../../static/close-dialog.svg'

import './AddDbModal.scss'

export interface IProps {
  onClose: () => void
  onAddDb: (dbName: string) => void
  treeData: ITree
  databases: IDatabase[]
}

export interface IAddDbForm {
  db: string
}

export const AddDbModal: React.FC<IProps> = ({
  treeData,
  databases,
  onAddDb,
  onClose,
}) => {
  const { block, element } = bindBem('AddDbModal')
  const [isAdding, setAdding] = React.useState(false)

  const unassignedDbs = useUnassignedDbs(databases, treeData)
  const onSubmit = React.useCallback((values: IAddDbForm) => {
    setAdding(true)
    onAddDb(values.db)
  }, [])

  const options = React.useMemo(
    () =>
      unassignedDbs.map(db => ({
        value: db.name,
        label: db.description ? `${db.name} — ${db.description}` : db.name,
      })),
    [unassignedDbs]
  )

  const validationSchema = React.useMemo(
    () =>
      Yup.object({
        db: Yup.string().required(),
      }),
    []
  )

  const formik = useFormik<IAddDbForm>({
    onSubmit,
    validationSchema,
    initialValues: {
      db: null,
    },
  })

  return (
    <div className={block()}>
      <img className={element('Close')} src={close} alt="close icon" onClick={onClose} />
      <div className={element('Title')}>{ADMIN.MENUS.MODALS.ADD_DB.TITLE}</div>
      <div className={element('Content')}>
        <Dropdown
          name="db"
          value={formik.values.db}
          placeholder={ADMIN.MENUS.MODALS.ADD_DB.PLACEHOLDER}
          onChange={val => formik.setFieldValue('db', val)}
          options={options}
          isSearchable
          noOptionsMessage={({ inputValue }) =>
            inputValue
              ? ADMIN.MENUS.MODALS.ADD_DB.NO_MATCH(inputValue)
              : ADMIN.MENUS.MODALS.ADD_DB.NO_UNASSIGNED_DBS
          }
        />
      </div>
      <div className={element('Bottom')}>
        <Button
          className={element('Submit')}
          onClick={formik.submitForm}
          disabled={isAdding || !formik.dirty || !formik.isValid}
          text={
            isAdding ? ADMIN.MENUS.MODALS.ADD_DB.ADDING : ADMIN.MENUS.MODALS.ADD_DB.ADD
          }
          size="small"
          style="dark"
        />
      </div>
    </div>
  )
}
