import * as React from 'react'

import { Button, IProps as IPropsButton } from './Button'
import { Link } from './Link'

import './FilePicker.scss'

export interface IProps extends IPropsButton {
  accept: string
  onFilePicked: (file: File) => void
}

interface IClickComponent {
  onClick: (e: React.MouseEvent) => void
}

export const FilePicker =
  (ClickComponent: React.ComponentType<IClickComponent>) =>
  ({ accept, onFilePicked, ...rest }: IProps) => {
    const fileInputRef = React.useRef<HTMLInputElement>()

    const onBtnClick = () => {
      fileInputRef.current.click()
    }

    const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files[0]
      fileInputRef.current.value = null

      onFilePicked(file)
    }

    return (
      <div className="FilePicker">
        <input ref={fileInputRef} type="file" accept={accept} onChange={handleUpload} />
        <ClickComponent {...rest} onClick={onBtnClick} />
      </div>
    )
  }

export const FilePickerButton = FilePicker(Button)
export const FilePickerLink = FilePicker(Link)
