import { VERSION } from 'config'
import { CLIENT_SUPPORT } from 'messages'
import * as React from 'react'

import { bindBem } from '../bem'
import * as constants from '../constants'

import './ClientSupport.scss'

const ClientSupport: React.FC = () => {
  const { block, element } = bindBem('ClientSupportPanel')

  return (
    <div className={block()}>
      <p className={element('Description')}>{CLIENT_SUPPORT.DESCRIPTION}</p>
      <p className={element('Availability')}>
        {CLIENT_SUPPORT.AVAILABLE1},
        <br />
        {CLIENT_SUPPORT.AVAILABLE2}
      </p>
      <div className={element('PhoneNumbers')}>
        <div>
          <a href={`tel:${constants.US_PHONE_NUMBER}`}>US {constants.US_PHONE_NUMBER}</a>
        </div>
        <div>
          <a href={`tel:${constants.UK_PHONE_NUMBER}`}>UK {constants.UK_PHONE_NUMBER}</a>
        </div>
        <div>
          <a href={`tel:${constants.SG_PHONE_NUMBER}`}>
            Singapore {constants.SG_PHONE_NUMBER}
          </a>
        </div>
      </div>
      <div className={element('EmailAddress')}>
        {CLIENT_SUPPORT.EMAIL_ADDRESS}
        <a href={`mailto:${constants.EMAIL_ADDRESS}`}>{constants.EMAIL_ADDRESS}</a>
      </div>
      <div className={element('Section')}>
        <h3>{CLIENT_SUPPORT.LEGAL}</h3>
        <a href={constants.DISCLAIMER_URL} target="_blank" rel="noreferrer">
          <span>{CLIENT_SUPPORT.DISCLAIMER}</span>
        </a>
        <a href={constants.PRIVACY_URL} target="_blank" rel="noreferrer">
          <span>{CLIENT_SUPPORT.PRIVACY_POLICY}</span>
        </a>
      </div>
      <div className={element('Section')}>
        <h3>{CLIENT_SUPPORT.HELP}</h3>
        <a href={constants.FEEDBACK_URL} target="_blank" rel="noreferrer">
          <span>{CLIENT_SUPPORT.FEEDBACK}</span>
        </a>
      </div>
      <div className={element('Section')}>
        <h3>{CLIENT_SUPPORT.ABOUT}</h3>
        <a href={constants.DATA_NEWS_URL} target="_blank" rel="noreferrer">
          <span>{CLIENT_SUPPORT.DATA_NEWS}</span>
        </a>
        <a href={constants.RELEASE_CALENDARS_URL} target="_blank" rel="noreferrer">
          <span>{CLIENT_SUPPORT.RELEASE_CALENDARS}</span>
        </a>
      </div>
      {VERSION && <div className={element('Version')}>Version: {VERSION}</div>}
    </div>
  )
}

export default ClientSupport
