import * as React from 'react'
import * as FauxDOM from 'react-faux-dom'
import * as d3 from 'd3'
import { addDropShadow } from '../../services/hd3'

const DropShadow = () => {
  const svg = FauxDOM.createElement('g')
  const selection = d3.select(svg)
  addDropShadow(selection)

  return <>{React.Children.map(svg.toReact(), c => c.props.children)}</>
}

export default React.memo(DropShadow)
