// eslint-disable max-lines
import React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { isLogScaleDisabled } from 'services/series'

import { SHORTCUTS_PANEL } from '../messages'
import { TABS } from '../services/sidebar'
import { IRootState } from '../store'
import * as database from '../store/Database'
import * as series from '../store/Series'

type Shortcuts = { [key: string]: keyof typeof SHORTCUTS_PANEL.SHORTCUTS }

const KEYBOARD_SHORTCUTS: Shortcuts = {
  KeyD: 'DISPLAY_DATABASE_LIST',
  KeyZ: 'ZOOM',
  KeyT: 'SET_TITLES',
  KeyL: 'TOGGLE_LOG_SCALE',
  'alt+KeyL': 'TOGGLE_LEGENDS',

  Left: 'SCROLL_LEFT',
  Right: 'SCROLL_RIGHT',
  ArrowLeft: 'SCROLL_LEFT',
  ArrowRight: 'SCROLL_RIGHT',

  'shift+Left': 'SCROLL_LEFT_BY_PERIOD',
  'shift+Right': 'SCROLL_RIGHT_BY_PERIOD',
  'shift+ArrowLeft': 'SCROLL_LEFT_BY_PERIOD',
  'shift+ArrowRight': 'SCROLL_RIGHT_BY_PERIOD',

  'alt+Left': 'DECREMENT_OFFSET',
  'alt+Right': 'INCREMENT_OFFSET',
  'alt+ArrowLeft': 'DECREMENT_OFFSET',
  'alt+ArrowRight': 'INCREMENT_OFFSET',
}

interface IStateProps {
  variables: IDataSeries[]
  yAxisType: YAxisType
  isLogScaleDisabled: boolean
}

interface IActionProps {
  openDatabases: () => void
  openSeries: () => void
  openZoom: () => void
  openEditTitles: () => void
  toggleLegends: () => void
  scrollLeft: () => void
  scrollRight: () => void
  scrollLeftByPeriod: () => void
  scrollRightByPeriod: () => void
  incrementLag: () => void
  decrementLag: () => void
  setYAxisType: (type: YAxisType) => void
  updateComputedParameters: () => void
  goRoot: () => void
}

type Props = IStateProps & IActionProps & React.HTMLAttributes<HTMLDivElement>

export class KeyboardShortcutHandler extends React.Component<Props> {
  render(): JSX.Element {
    return null
  }
  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown)
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown)
  }
  private onKeyDown = (e: KeyboardEvent) => {
    const tagName = (e.target as Element).tagName.toUpperCase()
    if (['INPUT', 'TEXTAREA'].includes(tagName)) {
      return
    }

    const pressedModifiers = [e.ctrlKey, e.altKey, e.shiftKey, e.metaKey]
    const modifiers = ['ctrl', 'alt', 'shift', 'meta'].filter(
      (_v, i) => pressedModifiers[i]
    )
    const pressedKey = e.code

    const combination = modifiers.length
      ? `${modifiers.join('+')}+${pressedKey}`
      : pressedKey

    const matchedShortcut = this.interpretCombination(combination)
    if (matchedShortcut) {
      e.preventDefault()
    }
  }

  private interpretCombination = (combination: string) => {
    switch (KEYBOARD_SHORTCUTS[combination]) {
      case 'DISPLAY_DATABASE_LIST':
        this.openDirectory()
        break
      case 'ZOOM':
        this.openZoom()
        break
      case 'SET_TITLES':
        this.openEditTitles()
        break
      case 'TOGGLE_LEGENDS':
        this.toggleLegends()
        break
      case 'SCROLL_LEFT':
        this.scrollLeft()
        this.props.updateComputedParameters()
        break
      case 'SCROLL_RIGHT':
        this.scrollRight()
        this.props.updateComputedParameters()
        break
      case 'SCROLL_LEFT_BY_PERIOD':
        this.scrollLeftByPeriod()
        this.props.updateComputedParameters()
        break
      case 'SCROLL_RIGHT_BY_PERIOD':
        this.scrollRightByPeriod()
        this.props.updateComputedParameters()
        break
      case 'INCREMENT_OFFSET':
        this.decrementLag()
        this.props.updateComputedParameters()
        break
      case 'DECREMENT_OFFSET':
        this.incrementLag()
        this.props.updateComputedParameters()
        break
      case 'TOGGLE_LOG_SCALE':
        this.toggleYAxisType()
        this.props.updateComputedParameters()
        break
      default:
        return false
    }
    return true
  }
  private openDirectory = () => {
    this.props.openDatabases()
    this.props.goRoot()
  }
  private openZoom = () => {
    if (!this.isSeriesOpen()) {
      return
    }
    this.props.openSeries()
    this.props.openZoom()
  }
  private openEditTitles = () => this.isSeriesOpen() && this.props.openEditTitles()
  private toggleLegends = () => this.isSeriesOpen() && this.props.toggleLegends()
  private scrollLeft = () => this.isSeriesOpen() && this.props.scrollLeft()
  private scrollRight = () => this.isSeriesOpen() && this.props.scrollRight()
  private scrollLeftByPeriod = () =>
    this.isSeriesOpen() && this.props.scrollLeftByPeriod()
  private scrollRightByPeriod = () =>
    this.isSeriesOpen() && this.props.scrollRightByPeriod()
  private incrementLag = () => this.isSeriesOpen() && this.props.incrementLag()
  private decrementLag = () => this.isSeriesOpen() && this.props.decrementLag()
  private toggleYAxisType = () => {
    if (!this.props.isLogScaleDisabled) {
      this.props.setYAxisType(this.props.yAxisType === 'log' ? 'linear' : 'log')
    }
  }
  private isSeriesOpen = () => this.props.variables.length > 0
}

const seriesSelector = (state: IRootState) => state.series

const variablesSelector = createSelector(
  seriesSelector,
  seriesStore => seriesStore.variables
)
const axisTypeSelector = createSelector(
  seriesSelector,
  seriesStore => seriesStore.seriesSettings.yAxisType
)
const logScaleDisabledSelector = createSelector(
  variablesSelector,
  axisTypeSelector,
  (vars, axisType) => isLogScaleDisabled(axisType, vars)
)

const mapStateToProps = (state: IRootState): IStateProps => {
  return {
    variables: variablesSelector(state),
    yAxisType: axisTypeSelector(state),
    isLogScaleDisabled: logScaleDisabledSelector(state),
  }
}

const mapDispatchToProps = (dispatch: any): IActionProps => ({
  openDatabases: () => dispatch(database.openDatabases()),
  openSeries: () => dispatch(database.ACTIONS.openTab(TABS.SERIES)),
  openZoom: () => dispatch(series.ACTIONS.setActiveModal('zoom')),
  openEditTitles: () => dispatch(series.ACTIONS.setActiveModal('editTitles')),
  toggleLegends: () => dispatch(series.toggleLegend()),
  scrollLeft: () => dispatch(series.ACTIONS.scrollChart(1)),
  scrollRight: () => dispatch(series.ACTIONS.scrollChart(-1)),
  scrollLeftByPeriod: () => dispatch(series.ACTIONS.scrollByPeriod(-1)),
  scrollRightByPeriod: () => dispatch(series.ACTIONS.scrollByPeriod(1)),
  incrementLag: () => dispatch(series.ACTIONS.changeOffset(1)),
  decrementLag: () => dispatch(series.ACTIONS.changeOffset(-1)),
  setYAxisType: (type: YAxisType) => dispatch(series.ACTIONS.setYAxisType(type)),
  updateComputedParameters: () => dispatch(series.updateTimeSpanFunctions()),
  goRoot: () => dispatch(database.goRoot()),
})

export default connect(mapStateToProps, mapDispatchToProps)(KeyboardShortcutHandler)
