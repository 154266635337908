// eslint-disable max-lines

import React, { Component } from 'react'
import { bindBem } from 'bem'
import { ITreeItem } from 'containers/MenusAdmin/services/menu.types'

import './NodeContentRenderer.scss'

function isDescendant(older, younger) {
  return (
    !!older.children &&
    typeof older.children !== 'function' &&
    older.children.some(child => child === younger || isDescendant(child, younger))
  )
}

class NodeContentRenderer extends Component<any> {
  isEditingNameMode = () => {
    const entryNode = this.props.node as ITreeItem
    switch (entryNode.original.type) {
      case 'group':
      case 'category':
        return entryNode.editingName
      default:
        return false
    }
  }
  render() {
    const {
      scaffoldBlockPxWidth,
      toggleChildrenVisibility = null,
      connectDragPreview,
      connectDragSource,
      isDragging,
      canDrop = false,
      canDrag = false,
      node,
      title = null,
      subtitle = null,
      draggedNode = null,
      path,
      treeIndex,
      isSearchMatch = false,
      isSearchFocus = false,
      icons = [],
      buttons = [],
      className = '',
      style = {},
      didDrop,
      swapFrom = null,
      swapLength = null,
      swapDepth = null,
      treeId,
      isOver,
      parentNode = null,
      rowDirection = 'ltr',
      toolbarRenderer = null,
      editingNameRenderer = null,
      ...otherProps
    } = this.props
    const { block, element } = bindBem('NodeContentRenderer')
    const entryNode = node as ITreeItem
    let nodeTitle = title || node.title

    let handle = null
    if (canDrag) {
      handle = connectDragSource(
        <div className={element('MoveHandle')}>
          <div />
          <div />
          <div />
        </div>,
        {
          dropEffect: 'copy',
        }
      )
    }

    const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node)
    const isLandingPadActive = !didDrop && isDragging

    nodeTitle =
      typeof nodeTitle === 'function' ? nodeTitle({ node, path, treeIndex }) : nodeTitle

    return (
      <div style={{ height: '100%' }} {...otherProps} className={block()}>
        <div className={element('RowWrapper')}>
          {/* Set the row preview to be used during drag and drop */}
          {connectDragPreview(
            <div
              className={
                element('Row', {
                  landingPad: isLandingPadActive,
                  cancelPad: isLandingPadActive && !canDrop,
                  searchMatch: isSearchMatch,
                  searchFocus: isSearchFocus,
                }) + ` ${className}`
              }
              style={{
                opacity: isDraggedDescendant ? 0.5 : 1,
                ...style,
              }}
            >
              {handle}

              <div
                className={element('RowContents', {
                  dragDisabled: !canDrag,
                  database: entryNode.original.type === 'db',
                })}
              >
                <div className={element('RowLabel')}>
                  {this.isEditingNameMode() &&
                    editingNameRenderer &&
                    editingNameRenderer()}
                  {!this.isEditingNameMode() && (
                    <span
                      className={element('RowTitle', {
                        category: entryNode.original.type === 'category',
                        group: entryNode.original.type === 'group',
                        groupCollapsed:
                          entryNode.original.type === 'group' &&
                          !entryNode.original.expanded,
                        db: entryNode.original.type === 'db',
                        separator: entryNode.original.type === 'separator',
                      })}
                      title={nodeTitle}
                    >
                      {nodeTitle}
                    </span>
                  )}
                </div>

                <div className={element('RowToolbar')}>{toolbarRenderer?.()}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default NodeContentRenderer
