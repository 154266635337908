import * as React from 'react'

import { ICON_BELL } from './SVG/Bell'
import { bindBem } from '../bem'
import { ENV, IS_PRODUCTION } from '../config'
import { UPDATES_URL } from '../constants'

import './SidebarHeader.scss'

export interface IProps {
  isCollapsed?: boolean
}

export const SidebarHeader = (props: IProps) => {
  const { block, element } = bindBem('SidebarHeader')
  const isCollapsed = props.isCollapsed || false

  return (
    <nav className={`${block({ isCollapsed })} row middle-xs between-xs`}>
      <div className={`${element('Logo')}`} />

      {!isCollapsed && (
        <div>
          <div className={`${element('HaverName')} box`}>
            {!IS_PRODUCTION && <div className={element('Env')}>{ENV}</div>}
          </div>
        </div>
      )}

      <div
        className={`${element('Buttons')}`}
        onClick={() => window.open(UPDATES_URL, '_blank')}
      >
        {!isCollapsed && <button>{ICON_BELL}</button>}
      </div>
    </nav>
  )
}
