import * as React from 'react'
import { bindBem } from '../../bem'

import { F_LETTER_ICON_LIGHT_SM } from '../SVG/FLetter'
import { I_LETTER_ICON_LIGHT_SM } from '../SVG/ILetter'
import { A_LETTER_ICON_LIGHT_SM } from '../SVG/ALetter'
import { T_LETTER_ICON } from '../SVG/TLetter'
import { CLOSE_SM } from '../SVG/Close'
import { UNDO_ICON } from '../SVG/Undo'

import './Buttons.scss'

const { block, element } = bindBem('TableButton')

interface IProps {
  active?: boolean
  disabled?: boolean
  noBorder?: boolean
  graphButton?: boolean
  fxButton?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
  children?: React.ReactNode
  close?: React.ReactNode
  title?: string
}

export const Button = (props: IProps) => {
  const className = block({
    active: props.active,
    noBorder: props.noBorder,
    graph: props.graphButton,
    fx: props.fxButton,
  })
  return (
    <div
      className={className}
      disabled={props.disabled}
      onClick={props.onClick}
      title={props.title}
    >
      {props.children}

      {props.onClick && <div className={element('Close')}>{props.close || CLOSE_SM}</div>}
    </div>
  )
}

export const FxButton = (props: IProps & { isArithmetic?: boolean }) => (
  <Button fxButton {...props} close={UNDO_ICON} title={props.title}>
    {props.isArithmetic ? T_LETTER_ICON : F_LETTER_ICON_LIGHT_SM}
  </Button>
)

export const InterpolationButton = (props: any) => (
  <Button {...props}>{I_LETTER_ICON_LIGHT_SM}</Button>
)

export const AggregationButton = (props: any) => (
  <Button {...props}>{A_LETTER_ICON_LIGHT_SM}</Button>
)
