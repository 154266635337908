import * as React from 'react'
import { bindBem } from 'bem'

import { IconOption } from 'containers/MenusAdmin/Options/IconOption'
import { ADMIN } from 'messages'

import PencilIcon from 'static/pencil.svg'
import ChevronDownIcon from 'static/chevron-down-reverse.svg'
import ChevronUpIcon from 'static/chevron-up-reverse.svg'
import BinIcon from 'static/red-bin.svg'

import './MenuOptions.scss'

export interface IProps {
  edit: () => void
  remove: () => void
  collapseAll: () => void
  expandAll: () => void
  areAllGroupsCollapsed: boolean
}

export const MenuOptions: React.FC<IProps> = ({
  edit,
  remove,
  collapseAll,
  expandAll,
  areAllGroupsCollapsed,
}) => {
  const { block } = bindBem('MenuOptions')

  return (
    <div className={block()}>
      <IconOption
        icon={PencilIcon}
        onClick={edit}
        tooltip={ADMIN.MENUS.MENU_OPTIONS.EDIT_NAME_TOOLTIP}
      />
      <IconOption
        icon={areAllGroupsCollapsed ? ChevronDownIcon : ChevronUpIcon}
        onClick={areAllGroupsCollapsed ? expandAll : collapseAll}
        tooltip={
          areAllGroupsCollapsed
            ? ADMIN.MENUS.MENU_OPTIONS.EXPAND_ALL
            : ADMIN.MENUS.MENU_OPTIONS.COLLAPSE_ALL
        }
      />
      <IconOption
        icon={BinIcon}
        onClick={remove}
        tooltip={ADMIN.MENUS.MENU_OPTIONS.DELETE_TOOLTIP}
      />
    </div>
  )
}
