import React from 'react'

export const AutoSave: React.FC<{
  onSubmit: () => void
  values: any
  isValid: boolean
  callback?: () => void
}> = ({ onSubmit, values, isValid, callback }) => {
  const [lastValues, setValues] = React.useState(values)
  React.useEffect(() => {
    if (lastValues !== values && isValid) {
      onSubmit()
      setValues(values)
    }
    if (callback) {
      callback()
    }
  })
  return null
}
