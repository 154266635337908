import React from 'react'
import { DropdownButton } from './DropdownButton'
import { TransformationButton } from './Buttons'
import { RadioGroup, Option, Separator } from '../RadioGroup'
import * as ICONS from '../SVG/Transformation'

export const TYPES: { [key in TransformationType]: JSX.Element[] } = {
  ADD: [ICONS.ADD_ICON, ICONS.ADD_ICON_LIGHT],
  SUBTRACT: [ICONS.SUBTRACT_ICON, ICONS.SUBTRACT_ICON_LIGHT],
  MULTIPLY: [ICONS.MULTIPLY_ICON, ICONS.MULTIPLY_ICON_LIGHT],
  DIVIDE: [ICONS.DIVIDE_ICON, ICONS.DIVIDE_ICON_LIGHT],
  PERCENT: [ICONS.PERCENT_ICON, ICONS.PERCENT_ICON_LIGHT],
}

interface IProps {
  value: TransformationType
  onChange: (type: TransformationType) => void
}

export const TransformationTypeDropdown = ({ value, onChange }: IProps) => {
  const wrapperRef = React.createRef<DropdownButton>()

  const Button = (props: { active: boolean; onClick: () => void }) => (
    <TransformationButton
      icons={value ? TYPES[value] : TYPES.ADD}
      {...props}
      active={false}
    />
  )
  return (
    <DropdownButton buttonRenderer={Button} stickTo="left" ref={wrapperRef}>
      <RadioGroup
        value={value}
        onChange={val => {
          onChange(val as TransformationType)
          wrapperRef.current.togglePortal()
        }}
      >
        <Option value="ADD">{TYPES.ADD}Add</Option>
        <Separator />
        <Option value="SUBTRACT">{TYPES.SUBTRACT}Subtract</Option>
        <Separator />
        <Option value="MULTIPLY">{TYPES.MULTIPLY}Multiply</Option>
        <Separator />
        <Option value="DIVIDE">{TYPES.DIVIDE}Divide</Option>
        <Separator />
        <Option value="PERCENT">{TYPES.PERCENT}Percent</Option>
      </RadioGroup>
    </DropdownButton>
  )
}
