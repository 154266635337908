// eslint-disable max-len

import * as React from 'react'

export const ICON_FOLDER = (
  <svg width="14px" height="11px" viewBox="0 0 14 11" version="1.1">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="_Directory-/-Level-1"
        transform="translate(-24.000000, -319.000000)"
        fill="#B8E0F6"
      >
        <g id="_Directory">
          <g id="List" transform="translate(24.000000, 204.000000)">
            <g id="Folders" transform="translate(0.000000, 4.000000)">
              <path
                d="M14,118.842857 L14,121.2 C14,121.641828 13.6418278,122 13.2,122 L0.799999952,122 C0.358172179,122 1.14618533e-15,121.641828 1.11022302e-16,121.2 L1.11022302e-16,113.371429 C5.69140056e-17,112.929601 0.358172179,112.571429 0.799999952,112.571429 L5.72727273,112.571429 L6.75986468,111.296494 C6.91175961,111.10895 7.14020143,111 7.38154087,111 L13.2,111 C13.6418278,111 14,111.358172 14,111.8 L14,118.842857 Z"
                id="Combined-Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
