import * as React from 'react'

import { Button } from '../Button'
import { bindBem } from '../../bem'
import { Footnote } from './Footnote'
import { HierarchyPopover } from './HierarchyPopover'
import Options from './Options'
import { withResponsive } from '../Responsive'

import backButton from '../../static/back-btn.svg'
import { ICON_HIERARCHY_LIGHT } from '../SVG/Hierarchy'

import './DataDirectoryHeader.scss'

interface IProps {
  entries: IDirectoryEntry[]
  onNavigateBack: (levels: number) => void
  onFootnoteClick: () => void
  isMobile?: boolean
}

interface IState {
  isExpanded: boolean
}

export class DataDirectoryHeader extends React.Component<IProps, IState> {
  state: IState = { isExpanded: false }

  render() {
    const { entries, onNavigateBack, onFootnoteClick, isMobile } = this.props
    const { isExpanded } = this.state
    const lastEntry = entries[entries.length - 1]
    const { block, element } = bindBem('DataDirectoryHeader')
    return (
      <div className={block({ isExpanded, isMobile })}>
        <div className={element('Row')}>
          {isExpanded && (
            <HierarchyPopover
              entries={entries}
              expanded={this.state.isExpanded}
              onClose={() => this.toggle(false)}
              onNavigateBack={this.onNavigateBack}
              onFootnoteClick={onFootnoteClick}
            />
          )}
          <Button
            icon={<img src={backButton} />}
            className="Back"
            size="small"
            style="pale"
            onClick={() => onNavigateBack(1)}
          />
          <div className={element('Title')}>
            {lastEntry.description}
            <Footnote footnoteId={lastEntry.footnoteId} onClick={onFootnoteClick} />
          </div>
          <Button
            icon={ICON_HIERARCHY_LIGHT}
            className="Hierarchy"
            size="small"
            style="light"
            onClick={() => this.toggle(!this.state.isExpanded)}
          />
        </div>
        <Options />
      </div>
    )
  }

  private toggle = (isExpanded: boolean) => {
    this.setState(() => ({
      isExpanded: isExpanded && this.props.entries.length > 0,
    }))
  }

  private onNavigateBack = (num: number) => {
    this.props.onNavigateBack(num)
    this.toggle(false)
  }
}

export default withResponsive(DataDirectoryHeader)
