// eslint-disable max-lines
import * as ga from 'analytics'
import * as fns from 'date-fns'
import { subYears } from 'date-fns/fp'
import _ from 'lodash'
import { getZoomRange } from 'services/hd3'
import {
  COLORS,
  dataLabelsAvailability,
  dataMarkersAvailability,
  freqToInterval,
  globalDataLabels,
  globalDataMarkers,
  graphTypeForNewSeries,
  hasTrendLine,
} from 'services/series'
import { getType } from 'typesafe-actions'
import { reorder } from 'utils'

import { peelTransformation, updateLag } from '../Transformations'
import { ACTIONS, SeriesAction } from './actions'

export type State = {
  variables: IDataSeries[]
  recessionsByCountry: SMap<IRecession[]>
  recessionCountryList: string[]
  defaultRecessionCountry?: string
  foundSeries: IPagination<IFoundSeries>
  loadingSeriesId: string
  seriesSettings: ISeriesSettings
  colorIndexesPool: number[]
  correlation: ICorrelation
  scatterSettings: IScatterSettings
  scale: IScale
  activeModal?: SeriesModal
  link?: ILink
  trendlineBoundaries: SMap<Date[]>
  initialScallingOptions: { scale: IScale; assignments: AxisAssignment[] }
}

export const DEFAULT_SCATTER_SETTINGS: IScatterSettings = {
  regressionType: 'none',
  isTimelineEnabled: false,
  axisType: 'both',
}

export const DEFAULT_GRAPH_OPTIONS: IGraphOptions = {
  lineShadow: false,
  yAxisLabels: false,
  recessionType: 'SHADING',
  recessionCountry: null,
  grid: { horizontal: true, vertical: false },
  tickmarksType: 'ALL',
  splineLine: false,
  dataLabelsType: 'NONE',
  yAxisType: 'linear',
}

export const DEFAULT_SERIES_SETTINGS: ISeriesSettings = {
  ...DEFAULT_GRAPH_OPTIONS,
  graphTypes: [],
  isScatterPlot: false,
  isLegendShown: true,
  recessionCountry: null,
  graphZoom: 'default',
  endZoomDate: null,
  titles: [],
  title: '',
}

const DEFAULT_AXIS: AutomaticAxis = {
  type: 'automatic',
  invert: false,
  ticksCount: null,
}

export const DEFAULT_SCALE: IScale = {
  type: 'neither',
  leftAxis: { ...DEFAULT_AXIS },
  rightAxis: { ...DEFAULT_AXIS },
}

export const INITIAL_STATE: State = {
  variables: [],
  recessionsByCountry: {},
  recessionCountryList: [],
  foundSeries: { count: 0, data: [], pages: 0 },
  loadingSeriesId: null,
  seriesSettings: DEFAULT_SERIES_SETTINGS,
  trendlineBoundaries: {},
  correlation: { enabled: false, value: null },
  colorIndexesPool: _.range(COLORS.length),
  scatterSettings: DEFAULT_SCATTER_SETTINGS,
  scale: DEFAULT_SCALE,
}

function setSeries(
  state: State,
  action: ExtractActionParameters<SeriesAction, 'SET_SERIES'>
): State {
  const { seriesSettings } = state
  const series = action.payload.series
  const index = action.payload.index || 0
  const [...variables] = state.variables
  const noSeries = variables.length === 0
  const [...colorIndexesPool] = state.colorIndexesPool
  const variable = variables[index]

  let settingsToInherit: Pick<
    Partial<IDataSeries>,
    'axisAssignment' | 'dataMarkers' | 'hasDataLabels' | 'colorIndex'
  > = {}
  if (variable) {
    settingsToInherit = {
      axisAssignment: variable.axisAssignment,
      dataMarkers: variable.dataMarkers,
      hasDataLabels: variable.hasDataLabels,
      colorIndex: variable.colorIndex,
    }
  }

  if (settingsToInherit.colorIndex === undefined && colorIndexesPool.length > 0) {
    settingsToInherit.colorIndex = colorIndexesPool.shift()
  }
  variables[index] = {
    ...series,
    axisAssignment: noSeries ? 'left' : 'right',
    ...settingsToInherit,
  }
  const [...graphTypes] = seriesSettings.graphTypes
  if (graphTypes[index] === undefined) {
    graphTypes[index] =
      noSeries || hasTrendLine(series) ? 'LINE' : seriesSettings.graphTypes[0]
  }
  const [...titles] = seriesSettings.titles
  if (titles.length === 0) {
    titles.push('')
  } else {
    titles[index] = ''
  }

  return {
    ...state,
    variables,
    colorIndexesPool,
    seriesSettings: { ...seriesSettings, graphTypes, titles },
  }
}

function addVariable(
  state: State,
  action: ExtractActionParameters<SeriesAction, 'ADD_VARIABLE'>
): State {
  const { seriesSettings } = state
  const { series, index } = action.payload
  const { variables } = state

  const [...colorIndexesPool] = state.colorIndexesPool

  const graphType = graphTypeForNewSeries(series, seriesSettings.graphTypes, variables)

  const variable: IDataSeries = {
    ...series,
    axisAssignment:
      series.axisAssignment ||
      (variables.find(s => s.axisAssignment === 'left') === undefined ? 'left' : 'right'),
    hasDataLabels:
      series.hasDataLabels !== undefined
        ? series.hasDataLabels
        : dataLabelsAvailability[graphType] && seriesSettings.dataLabelsType !== 'NONE',
    dataMarkers:
      series.dataMarkers !== undefined
        ? series.dataMarkers
        : dataMarkersAvailability[graphType] &&
          globalDataMarkers(variables, seriesSettings.graphTypes),
  }

  const colorPoolIndex = colorIndexesPool.indexOf(series.colorIndex)
  if (colorPoolIndex === -1) {
    variable.colorIndex = colorIndexesPool.length > 0 ? colorIndexesPool.shift() : 0
  } else {
    colorIndexesPool.splice(colorPoolIndex, 1)
  }

  const [...newGraphTypes] = seriesSettings.graphTypes
  newGraphTypes.splice(index, 0, graphType)
  const [...newVariables] = state.variables
  newVariables.splice(index, 0, variable)
  const [...titles] = seriesSettings.titles
  titles.splice(index, 0, '')
  return {
    ...state,
    colorIndexesPool,
    variables: newVariables,
    seriesSettings: { ...seriesSettings, graphTypes: newGraphTypes, titles },
  }
}

function setTrendlineBoundary(
  state: State,
  action: ExtractActionParameters<SeriesAction, 'SET_TRENDLINE_BOUNDARY'>
): State {
  const { boundary, uuid } = action.payload
  const trendlineBoundaries = { ...state.trendlineBoundaries, [uuid]: boundary }
  return { ...state, trendlineBoundaries }
}

function setSeriesType(
  state: State,
  action: ExtractActionParameters<SeriesAction, 'SET_SERIES_TYPE'>
): State {
  const { seriesSettings } = state
  const { graphType, index } = action.payload
  const setAll = index === undefined
  ga.track('graph', 'settings', {
    graphType,
    type: setAll ? 'setAll' : 'setOne',
  })
  let [...graphTypes] = state.seriesSettings.graphTypes
  if (setAll && graphType === 'SCATTER') {
    return {
      ...state,
      seriesSettings: { ...seriesSettings, graphTypes, isScatterPlot: true },
    }
  }
  if (!setAll) {
    graphTypes[index] = graphType
  } else {
    const { variables } = state
    graphTypes = graphTypes.map((originalType, i) =>
      hasTrendLine(variables[i]) ? originalType : graphType
    )
  }
  return {
    ...state,
    seriesSettings: {
      ...seriesSettings,
      isScatterPlot: setAll ? false : seriesSettings.isScatterPlot,
      graphTypes,
    },
  }
}

function setGraphZoom(
  state: State,
  action: ExtractActionParameters<SeriesAction, 'SET_GRAPH_ZOOM'>
): State {
  const { seriesSettings } = state
  const graphZoom = action.payload
  const endZoomDate = graphZoom !== null ? state.seriesSettings.endZoomDate : null
  ga.track('graph', 'settings', {
    graphZoom:
      typeof graphZoom === 'string' ? graphZoom : graphZoom.value + graphZoom.unit,
  })
  return {
    ...state,
    seriesSettings: { ...seriesSettings, graphZoom, endZoomDate },
  }
}

function changeOffset(
  state: State,
  action: ExtractActionParameters<SeriesAction, 'CHANGE_OFFSET'>
): State {
  const { value } = action.payload

  return {
    ...state,
    variables: state.variables.map((oldVariable, index) => {
      if (index === 0) {
        const newVariable = { ...oldVariable }

        newVariable.offset = value !== undefined ? newVariable.offset + value : 0

        if (newVariable.transformation) {
          newVariable.transformation = updateLag(
            newVariable.transformation,
            newVariable.offset
          ) as ITransformation
        }

        return newVariable
      }
      return oldVariable
    }),
  }
}

function constrainZoomRange(range: Date[]) {
  const MIN_DATE = new Date(1900, 0, 1)
  const MAX_DATE = new Date(2100, 0, 1)
  const startDiff = fns.differenceInDays(MIN_DATE, range[0])
  if (startDiff > 0) {
    return range.map(d => fns.addDays(d, startDiff))
  }
  const endDiff = fns.differenceInDays(MAX_DATE, range[1])
  if (endDiff < 0) {
    return range.map(d => fns.addDays(d, endDiff))
  }
  return range
}

function scrollChart(
  state: State,
  action: ExtractActionParameters<SeriesAction, 'SCROLL_CHART'>
): State {
  const { value } = action.payload
  return scroll(state, subYears(value))
}

function scrollByPeriod(
  state: State,
  action: ExtractActionParameters<SeriesAction, 'SCROLL_BY_PERIOD'>
): State {
  const { value } = action.payload
  const { variables } = state
  const primarySeries = variables[0]
  const interval = freqToInterval(primarySeries.frequency)
  const transformDate = (d: Date) => interval.offset(d, value)

  return scroll(state, transformDate)
}

function scroll(state: State, transformDate: (d: Date) => Date): State {
  const { variables } = state
  const { graphZoom } = state.seriesSettings
  if (graphZoom === 'all') {
    return state
  }

  const currZoomRange = getZoomRange(
    variables,
    state.seriesSettings.endZoomDate,
    graphZoom
  )
  const newZoomRange = constrainZoomRange(currZoomRange.map(transformDate))

  return {
    ...state,
    seriesSettings: {
      ...state.seriesSettings,
      endZoomDate: newZoomRange[1],
    },
  }
}

function toggleDataMarkers(
  state: State,
  action: ExtractActionParameters<SeriesAction, 'TOGGLE_DATA_MARKERS'>
): State {
  const index = action.payload.index
  let [...variables] = state.variables

  if (index !== undefined) {
    const series = variables[index]
    variables[index] = {
      ...series,
      dataMarkers: !series.dataMarkers,
    }
  } else {
    const newValue = !globalDataMarkers(variables, state.seriesSettings.graphTypes)
    variables = variables.map(series => ({
      ...series,
      dataMarkers: newValue,
    }))
  }

  return {
    ...state,
    variables,
  }
}

function toggleDataLabels(
  state: State,
  action: ExtractActionParameters<SeriesAction, 'TOGGLE_DATA_LABELS'>
): State {
  const index = action.payload.index
  let [...variables] = state.variables
  let dataLabelsType = state.seriesSettings.dataLabelsType

  if (index !== undefined) {
    const series = variables[index]
    variables[index] = {
      ...series,
      hasDataLabels: !series.hasDataLabels,
    }
  } else {
    const newValue = !globalDataLabels(variables, state.seriesSettings.graphTypes)
    variables = variables.map(series => ({
      ...series,
      hasDataLabels: newValue,
    }))
  }

  if (variables.find(s => s.hasDataLabels) !== undefined && dataLabelsType === 'NONE') {
    dataLabelsType = 'ALL'
  } else if (
    variables.find(s => s.hasDataLabels) === undefined &&
    dataLabelsType !== 'NONE'
  ) {
    dataLabelsType = 'NONE'
  }

  return {
    ...state,
    variables,
    seriesSettings: {
      ...state.seriesSettings,
      dataLabelsType,
    },
  }
}

export function reducer(state = INITIAL_STATE, action: SeriesAction): State {
  const { seriesSettings } = state
  switch (action.type) {
    case getType(ACTIONS.setSeries): {
      return setSeries(state, action)
    }
    case getType(ACTIONS.setSeriesType): {
      return setSeriesType(state, action)
    }
    case getType(ACTIONS.setTrendlineBoundary): {
      return setTrendlineBoundary(state, action)
    }

    case getType(ACTIONS.deleteTrendlineBoundary): {
      const trendlineBoundaries = { ...state.trendlineBoundaries }
      delete trendlineBoundaries[action.payload.uuid]
      return { ...state, trendlineBoundaries }
    }

    case getType(ACTIONS.cancelScatterPlot):
      return {
        ...state,
        correlation: { enabled: false, value: null },
        seriesSettings: { ...state.seriesSettings, isScatterPlot: false },
      }
    case getType(ACTIONS.setRecessionType): {
      const recessionType = action.payload
      const { recessionCountry } = seriesSettings
      ga.track('graph', 'setRecession', { recessionType, recessionCountry })
      return { ...state, seriesSettings: { ...seriesSettings, recessionType } }
    }
    case getType(ACTIONS.setRecessionCountry): {
      const recessionCountry = action.payload
      const { recessionType } = seriesSettings
      ga.track('graph', 'setRecession', { recessionType, recessionCountry })
      return {
        ...state,
        seriesSettings: { ...seriesSettings, recessionCountry },
      }
    }
    case getType(ACTIONS.setRecessions):
      return { ...state, recessionsByCountry: action.payload }
    case getType(ACTIONS.setRecessionCountryList):
      return {
        ...state,
        recessionCountryList: action.payload,
        defaultRecessionCountry: action.payload[0],
      }
    case getType(ACTIONS.setSeriesTitles):
      ga.track('graph', 'settings', 'setTitle')
      ga.track('graph', 'setTitle')

      return {
        ...state,
        seriesSettings: {
          ...seriesSettings,
          titles: action.payload.titles,
          title: action.payload.title,
          isLegendShown: action.payload.isLegendShown,
        },
      }
    case getType(ACTIONS.setGraphZoom): {
      return setGraphZoom(state, action)
    }
    case getType(ACTIONS.setGraphGrid):
      const grid = action.payload
      ga.track('graph', 'settings', { ...grid })
      return { ...state, seriesSettings: { ...seriesSettings, grid } }
    case getType(ACTIONS.toggleDataMarkers): {
      return toggleDataMarkers(state, action)
    }
    case getType(ACTIONS.toggleDataLabels): {
      return toggleDataLabels(state, action)
    }
    case getType(ACTIONS.toggleYAxisLabels):
      return {
        ...state,
        seriesSettings: {
          ...seriesSettings,
          yAxisLabels: !seriesSettings.yAxisLabels,
        },
      }
    case getType(ACTIONS.toggleSplineLine):
      return {
        ...state,
        seriesSettings: {
          ...seriesSettings,
          splineLine: !seriesSettings.splineLine,
        },
      }
    case getType(ACTIONS.setYAxisType): {
      const yAxisType = action.payload
      ga.track('graph', 'axisType', { type: yAxisType })
      return { ...state, seriesSettings: { ...seriesSettings, yAxisType } }
    }
    case getType(ACTIONS.toggleLineShadow):
      return {
        ...state,
        seriesSettings: {
          ...seriesSettings,
          lineShadow: !seriesSettings.lineShadow,
        },
      }
    case getType(ACTIONS.resetGraphSettings):
      return {
        ...state,
        seriesSettings: {
          ...seriesSettings,
          ...DEFAULT_GRAPH_OPTIONS,
        },
        variables: state.variables.map(s => ({
          ...s,
          dataMarkers: false,
          hasDataLabels: false,
        })),
      }
    case getType(ACTIONS.setTickMarksType):
      return {
        ...state,
        seriesSettings: { ...seriesSettings, tickmarksType: action.payload },
      }
    case getType(ACTIONS.setDataLabelsType): {
      let [...variables] = state.variables
      const dataLabelsType = action.payload
      if (dataLabelsType === 'NONE') {
        variables = variables.map(series => ({
          ...series,
          hasDataLabels: false,
        }))
      } else if (seriesSettings.dataLabelsType === 'NONE') {
        variables = variables.map(series => ({
          ...series,
          hasDataLabels: true,
        }))
      }
      return {
        ...state,
        variables,
        seriesSettings: { ...seriesSettings, dataLabelsType: action.payload },
      }
    }
    case getType(ACTIONS.setLegend):
      const isLegendShown = action.payload
      ga.track('graph', 'settings', { isLegendShown })
      return { ...state, seriesSettings: { ...seriesSettings, isLegendShown } }
    case getType(ACTIONS.addVariable): {
      return addVariable(state, action)
    }
    case getType(ACTIONS.deleteVariable): {
      const index = action.payload
      const [...variables] = state.variables
      const [...colorIndexesPool] = state.colorIndexesPool
      const [...titles] = seriesSettings.titles
      const [...graphTypes] = seriesSettings.graphTypes
      const deleted = variables.splice(index, 1)[0]
      colorIndexesPool.push(deleted.colorIndex)
      ga.track('data directory', 'remove variable', {
        series: deleted.id,
        db: deleted.databaseId,
      })
      titles.splice(index, 1)
      graphTypes.splice(index, 1)
      return {
        ...state,
        variables,
        colorIndexesPool,
        seriesSettings: { ...seriesSettings, titles, graphTypes },
      }
    }
    case getType(ACTIONS.moveVariable): {
      const { index, targetIndex } = action.payload
      const variables = reorder([...state.variables], index, targetIndex)
      const { titles, graphTypes, ...settings } = state.seriesSettings
      return {
        ...state,
        variables,
        seriesSettings: {
          ...settings,
          titles: reorder([...titles], index, targetIndex),
          graphTypes: reorder([...graphTypes], index, targetIndex),
        },
      }
    }
    case getType(ACTIONS.setLoadingSeriesId):
      return { ...state, loadingSeriesId: action.payload }
    case getType(ACTIONS.resetGraph): {
      ga.track('graph', 'settings', 'reset')
      return INITIAL_STATE
    }
    case getType(ACTIONS.setSeriesAxisAssignment): {
      const { index, assignment } = action.payload
      const variables = [...state.variables]
      const series = variables[index]
      if (series.axisAssignment !== assignment) {
        ga.track('scale', 'axisAssignment', { to: assignment })
      }
      variables[index] = { ...series, axisAssignment: assignment }
      return { ...state, variables }
    }
    case getType(ACTIONS.setActiveModal):
      return { ...state, activeModal: action.payload }
    case getType(ACTIONS.nextPage):
      const paginator = state[action.payload.key]
      return {
        ...state,
        [action.payload.key]: {
          ...paginator,
          data: [
            ...paginator.data,
            ...(action.payload.data.data as typeof paginator.data),
          ],
        },
      }
    case getType(ACTIONS.removeTransformation): {
      const index = action.payload
      if (state.variables.length > index) {
        return {
          ...state,
          variables: state.variables.map((s, i) =>
            i === index ? peelTransformation(s) : s
          ),
        }
      }
      break
    }
    case getType(ACTIONS.toggleCorrelation): {
      const { enabled } = action.payload
      if (enabled) {
        ga.track('graph', 'correlation')
      }
      const value = enabled ? state.correlation.value : null
      return {
        ...state,
        correlation: { enabled, value },
      }
    }
    case getType(ACTIONS.setCorrelationValue): {
      const { value } = action.payload
      return {
        ...state,
        correlation: { ...state.correlation, value },
      }
    }
    case getType(ACTIONS.changeOffset): {
      return changeOffset(state, action)
    }
    case getType(ACTIONS.scrollChart): {
      return scrollChart(state, action)
    }
    case getType(ACTIONS.scrollByPeriod): {
      return scrollByPeriod(state, action)
    }
    case getType(ACTIONS.setRegressionType):
      return {
        ...state,
        scatterSettings: {
          ...state.scatterSettings,
          regressionType: action.payload,
        },
      }
    case getType(ACTIONS.setScatterTimeline):
      return {
        ...state,
        scatterSettings: {
          ...state.scatterSettings,
          isTimelineEnabled: action.payload,
        },
      }

    case getType(ACTIONS.setScatterAxisType):
      ga.track('graph', 'settings', { graphType: 'scatter' })
      return {
        ...state,
        scatterSettings: { ...state.scatterSettings, axisType: action.payload },
      }
    case getType(ACTIONS.setScale): {
      const scale = action.payload
      if (scale.type !== state.scale.type) {
        ga.track('graph', 'scaleType', { value: scale.type })
      }

      if (scale.leftAxis.type !== state.scale.leftAxis.type) {
        ga.track('graph', 'axisType', { value: scale.leftAxis.type })
      }
      if (scale.rightAxis.type !== state.scale.rightAxis.type) {
        ga.track('graph', 'axisType', { value: scale.rightAxis.type })
      }
      return { ...state, scale }
    }
    case getType(ACTIONS.setLink): {
      ga.track('graph', 'shareLink')
      const response = action.payload
      const link: ILink = {
        id: response.id,
        owner: response.userId,
        pngLink: response.pngUrl,
        svgLink: response.svgUrl,
      }
      const { variables } = state
      return {
        ...state,
        variables: variables.map(v => ({ ...v, isFrozen: true })),
        link,
      }
    }
    case getType(ACTIONS.setSeriesSettings):
      return { ...state, seriesSettings: action.payload }
    case getType(ACTIONS.loadSeriesDump): {
      const data = action.payload
      const settings: ISeriesSettings = {
        ...data.seriesSettings,
        endZoomDate: new Date(data.seriesSettings.endZoomDate),
      }
      const usedColorIndices = data.variables.map(v => v.colorIndex)
      return {
        ...state,
        variables: data.variables.map(v => ({
          ...v,
          startDate: new Date(v.startDate),
          lastModified: new Date(v.lastModified),
          isFrozen: true,
          dataPoints: v.dataPoints.map(d => ({ ...d, date: new Date(d.date) })),
        })),
        colorIndexesPool: state.colorIndexesPool.filter(
          color => !usedColorIndices.includes(color)
        ),
        seriesSettings: settings,
        correlation: data.correlation,
        scatterSettings: data.scatterSettings,
        scale: data.scale,
        trendlineBoundaries: Object.keys(data.trendlineBoundaries)
          .map(b => ({
            [b]: data.trendlineBoundaries[b].map(d => new Date(d)),
          }))
          .reduce((acc, w) => ({ ...acc, ...w }), {}),
      }
    }
    case getType(ACTIONS.setInitialScallingOptions): {
      const { scale, assignments } = action.payload

      return { ...state, initialScallingOptions: { scale, assignments } }
    }

    default:
      return state
  }
}
