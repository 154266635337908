import * as React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'

import { Input, Labeled } from '../../components/Input'
import { BigSwitch } from './BigSwitch'
import { SelectorTabs, ItemSelector, SelectorTabsHeader, Tab } from './ItemSelector'
import { Button } from '../Button'

import './PackageForm.scss'

const SCHEMA = yup.object({ name: yup.string().min(3) })

interface IProps {
  package?: IDBPackage
  databases: IDatabase[]
  organizations: IOrganization[]
  onSave: (p: IDBPackage) => void
}

export function PackageForm(props: IProps) {
  const [databases, setDatabases] = React.useState<string[]>(
    props.package ? props.package.databases : []
  )

  const [organizations, setOrganizations] = React.useState<number[]>(
    props.package ? props.package.organizations : []
  )

  const [tab, setTab] = React.useState(0)

  const onSubmit = (p: IDBPackage) => props.onSave({ ...p, databases, organizations })

  return (
    <Formik
      initialValues={{ ...((props.package || {}) as IDBPackage) }}
      validationSchema={SCHEMA}
      onSubmit={onSubmit}
    >
      {({ values, touched, errors, submitForm, handleChange, setFieldValue }) => (
        <div className="PackageForm">
          <Labeled error={touched.name && errors.name} label={'PACKAGE NAME'}>
            <Input
              name="name"
              value={values.name}
              error={touched.name && !!errors.name}
              onChange={handleChange}
            />
          </Labeled>
          {!props.package && (
            <Labeled error={touched.name && errors.name} label={'STATUS'}>
              <BigSwitch
                value={values.isEnabled}
                onChange={v => setFieldValue('isEnabled', v)}
              />
            </Labeled>
          )}
          <SelectorTabs>
            <SelectorTabsHeader
              active={tab}
              changeTab={setTab}
              names={[
                `Databases (${databases.length})`,
                `Organizations (${organizations.length})`,
              ]}
            />
            <Tab active={tab === 0}>
              <ItemSelector
                onChange={v => setDatabases(v)}
                items={props.databases.map(d => ({
                  name: d.description,
                  id: d.name,
                }))}
                selectedIds={databases}
              />
            </Tab>
            <Tab active={tab === 1}>
              <ItemSelector
                onChange={v => setOrganizations(v)}
                items={props.organizations}
                selectedIds={organizations}
              />
            </Tab>
          </SelectorTabs>
          <Button
            onClick={submitForm}
            size="small"
            text="Save"
            style="dark"
            className="ApplyBtn"
          />
        </div>
      )}
    </Formik>
  )
}
