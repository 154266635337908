import React from 'react'

export const useModalState = (): [boolean, () => void, () => void] => {
  const [isOpen, setOpen] = React.useState(false)
  const open = React.useCallback(() => {
    setOpen(true)
  }, [])
  const close = React.useCallback(() => {
    setOpen(false)
  }, [])

  return [isOpen, open, close]
}
