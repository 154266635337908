// eslint-disable max-len

import * as React from 'react'

export const Hamburger = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5">
    <path
      fill="#788596"
      fillRule="evenodd"
      d="M.5 0h4a.5.5 0 0 1 0 1h-4a.5.5 0 1 1 0-1zm0 2h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1 0-1zm0 2h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1 0-1z"
    />
  </svg>
)
