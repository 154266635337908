const isFunction = (f: any): f is () => void => 'function' === typeof f
const isValid = (pred: boolean | (() => boolean)) => (isFunction(pred) ? pred() : pred)
const generateWithModifiers = (
  name: string,
  modifiers: SMap<boolean | (() => boolean)> = {}
) => {
  return Object.keys(modifiers).reduce(
    (classNames, modifier) =>
      classNames + (isValid(modifiers[modifier]) ? ` ${name}--${modifier}` : ''),
    name
  )
}

export function element(
  className: string,
  value: string,
  modifiers: SMap<boolean | (() => boolean)> = {}
) {
  return generateWithModifiers(`${className}__${value}`, modifiers)
}

export function block(name: string, modifiers: SMap<boolean | (() => boolean)> = {}) {
  return generateWithModifiers(name, modifiers)
}

export function bindBem(name: string) {
  return {
    element: (elementName: string, modifiers: SMap<boolean | (() => boolean)> = {}) =>
      element(name, elementName, modifiers),
    block: (modifiers: SMap<boolean | (() => boolean)> = {}) => block(name, modifiers),
  }
}
