import * as React from 'react'

import { Options } from './Sidebar/Options'

interface IProps {
  value: string | any
  onSelect: (value: OrganizationType) => void
  disabled?: boolean
}
export const OrganizationTypeSelect: React.FunctionComponent<IProps> = props => {
  const options: OrganizationType[] = ['company', 'university']
  const value = options.indexOf(props.value)
  return (
    <Options
      options={options}
      selectedOption={value}
      setSeriesOptions={(selectedValue: number) => props.onSelect(options[selectedValue])}
      disabled={props.disabled ?? false}
    />
  )
}
