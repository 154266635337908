// eslint-disable max-len

import * as React from 'react'

export const EmptyStar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14">
    <path
      fill="none"
      fillRule="evenodd"
      stroke="#007CC3"
      d="M7.5 1.13L5.628 4.923l-4.186.608L4.47 8.484l-.715 4.17L7.5 10.685l3.744 1.969-.715-4.17 3.03-2.953-4.187-.608L7.5 1.13z"
    />
  </svg>
)

export const Star = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14">
    <path
      fill="#007CC3"
      fillRule="evenodd"
      d="M7.5 345.25L3.092 347.568 3.934 342.659 0.367 339.182 5.296 338.466 7.5 334 9.704 338.466 14.633 339.182 11.066 342.659 11.908 347.568z"
      transform="translate(0 -334)"
    />
  </svg>
)
