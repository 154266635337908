import * as React from 'react'
import { bindBem } from '../../bem'
import { RadioOption as RawOption } from '../../components/RadioButton'
import { COLORS, getSeriesLabel } from '../../services/series'

import './ScalingOptionsDialog.scss'
import './SeriesRow.scss'

interface IProps {
  series: IDataSeries
  value: AxisAssignment
  onChange: (c: AxisAssignment) => void
  hasLabels?: boolean
}

const COLUMNS: AxisAssignment[] = ['left', 'right']

export const SeriesRow: React.FC<IProps> = ({ series, value, onChange, hasLabels }) => {
  const { block, element } = bindBem('SeriesRow')

  return (
    <div className={block({ hasLabels })}>
      <div
        className={element('ColorIndicator')}
        style={{ backgroundColor: COLORS[series.colorIndex] }}
      />
      <div className={element('Title')}>{getSeriesLabel(series)}</div>
      <div className={element('Options')}>
        {COLUMNS.map(col => (
          <RawOption value={col} key={col} onChange={onChange} selected={col === value}>
            {hasLabels && col}
          </RawOption>
        ))}
      </div>
    </div>
  )
}

export default React.memo(SeriesRow)
