import { useFormik } from 'formik'
import keycode from 'keycode'
import * as React from 'react'
import * as yup from 'yup'

import { bindBem } from '../../bem'
import { SIDEBAR, ZOOM_DIALOG } from '../../messages'
import { Button } from '../Button'
import { Input } from '../Input'
import { ActionsBar } from '../Modal'

import './ZoomDialog.scss'

interface IProps {
  zoom: Zoom
  onSave: (zoom: Zoom) => void
}

type Form = {
  selectedZoom: string
}

export const ZoomDialog: React.FC<IProps> = (props: IProps) => {
  const handleSave = ({ selectedZoom }: Form) => {
    const newZoom = stringToZoom(selectedZoom)
    props.onSave(newZoom)
  }
  const formik = useFormik<Form>({
    initialValues: {
      selectedZoom: typeof props.zoom === 'string' ? '' : zoomToString(props.zoom),
    },
    onSubmit: handleSave,
    validationSchema: SCHEMA,
  })
  const { block, element } = bindBem('ZoomDialog')

  const onZoomReset = React.useCallback(() => {
    props.onSave('default')
  }, [])

  const handleReturnKey = (e: React.KeyboardEvent) =>
    e.which === keycode('enter') && formik.submitForm()

  return (
    <div className={block()} onKeyDown={handleReturnKey}>
      <ActionsBar>
        <Button
          size="small"
          text={ZOOM_DIALOG.RESET}
          style="light"
          onClick={onZoomReset}
        />
        <Button
          size="small"
          text={ZOOM_DIALOG.APPLY}
          style="dark"
          onClick={formik.submitForm}
          disabled={!formik.isValid}
        />
      </ActionsBar>
      <div className={element('Content')}>
        <div className={element('Input')}>
          <Input
            focus
            name="selectedZoom"
            placeholder=""
            value={formik.values.selectedZoom}
            onChange={formik.handleChange}
            error={formik.errors.selectedZoom !== undefined}
          />
          <div className={element('Info')}>{`# ${
            SIDEBAR.GRAPH_OPTIONS.ZOOM_DIALOG.INFO
          } [${zoomToString(props.zoom)}]`}</div>
        </div>
        {formik.errors.selectedZoom && (
          <p className={element('Validation')}>{formik.errors.selectedZoom}</p>
        )}
        <div className={element('Note')}>{SIDEBAR.GRAPH_OPTIONS.ZOOM_DIALOG.NOTE}</div>
        <div className={element('Note')}>
          {SIDEBAR.GRAPH_OPTIONS.ZOOM_DIALOG.NOTE_OPTIONS}
        </div>
      </div>
    </div>
  )
}

const REGEX = /^(\d+)(\w?)$/i
const acceptedUnits = 'yqmwd'

export const SCHEMA = yup.object({
  selectedZoom: yup
    .string()
    .test('hasFormat', ZOOM_DIALOG.ZOOM_FORMAT, (value?: string) => {
      if (value === undefined) {
        return true
      }
      return value.match(REGEX) !== null
    })
    .test('wrongUnit', ZOOM_DIALOG.ZOOM_WRONG_UNIT, (value?: string) => {
      if (!value || value.match(REGEX) === null) {
        return true
      }
      const [, , unit] = value.match(REGEX)

      return unit === '' || acceptedUnits.includes(unit.toLowerCase())
    })
    .test('tooLarge', ZOOM_DIALOG.ZOOM_TO_LARGE, (value?: string) => {
      if (!value || value.match(REGEX) === null) {
        return true
      }
      const zoom = stringToZoom(value)
      return zoomToYears(zoom) < 100
    }),
})
function stringToZoom(valueStr: string): Zoom {
  if (valueStr === null || valueStr === '') {
    return 'all'
  }
  const [, capuredValue, capturedUnit] = valueStr.trim().match(REGEX)
  const unit = capturedUnit !== '' ? (capturedUnit.toLowerCase() as ZoomUnit) : 'y'
  const value = parseInt(capuredValue, 10)
  return { value, unit }
}

function zoomToString(zoom: Zoom): string {
  if (zoom === 'all') {
    return 'All'
  }
  if (zoom === 'default') {
    return 'Default'
  }
  return zoom.value + (zoom.unit === 'y' ? '' : zoom.unit)
}

export function zoomToYears(zoom: Zoom): number {
  if (zoom === 'all' || zoom === 'default') {
    return 1
  }
  if (zoom.unit === 'd') {
    return zoom.value / 260
  } else if (zoom.unit === 'w') {
    return zoom.value / 52
  } else if (zoom.unit === 'm') {
    return zoom.value / 12
  } else if (zoom.unit === 'q') {
    return zoom.value / 4
  }
  return zoom.value
}
