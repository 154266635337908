import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect, match } from 'react-router-dom'
import { openToast } from 'store/User'

import * as ga from '../analytics'
import { CLIENT } from '../api/config'
import { resetPassword } from '../api/users'
import { bindBem } from '../bem'
import { Button } from '../components/Button'
import { PrimaryInput } from '../components/Input'
import { RESET_PASSWORD_FORM } from '../messages'
import { clearUserData } from '../services/user'
import { PasswordValidator } from '../services/validators'

import './SetPasswordForm.scss'
import logo from '../static/haver-logotype.svg'

interface IProps {
  match: match<{ id: string }>
  isCreating?: boolean
}

interface IState {
  password: string
  password1: string
  errorPassword: boolean
  errorRepeat: boolean
  connectionError: string
  isSent: boolean
}

interface IActionProps {
  openToast: (toast: Partial<IToast>) => void
}

export type Props = IProps & IActionProps

export class SetPasswordForm extends React.Component<IProps & IActionProps, IState> {
  state: IState = {
    password: '',
    password1: '',
    errorPassword: false,
    errorRepeat: false,
    isSent: false,
    connectionError: null,
  }

  componentDidMount() {
    delete CLIENT.defaults.headers.Authorization
    clearUserData()
  }

  render() {
    const { block, element } = bindBem('SetPasswordForm')
    const { isCreating } = this.props
    const { password, password1, errorPassword, errorRepeat, isSent, connectionError } =
      this.state

    return (
      <form className={block({ hasError: errorRepeat })} onSubmit={this.onSubmit}>
        <img className={element('Logo')} src={logo} alt="Haver Analytics" />
        <div className={element('Title')}>
          {isCreating
            ? RESET_PASSWORD_FORM.CREATE_YOUR_PASSWORD
            : RESET_PASSWORD_FORM.RESET_YOUR_PASSWORD}
        </div>
        {errorPassword && (
          <div className={element('HintBox')}>
            <p>{RESET_PASSWORD_FORM.PASSWORD_SHOULD}</p>
            <ul>
              <li>{RESET_PASSWORD_FORM.SHOULD_BE_LONG}</li>
              <li>{RESET_PASSWORD_FORM.SHOULD_CONTAIN_CAPITAL_LETTER}</li>
              <li>{RESET_PASSWORD_FORM.SHOULD_CONTAIN_DIGIT}</li>
              <li>{RESET_PASSWORD_FORM.SHOULD_CONTAIN_SPECIAL_CHAR}</li>
            </ul>
          </div>
        )}
        {errorRepeat && (
          <div className={element('Error')}>
            {RESET_PASSWORD_FORM.PASSWORDS_DONT_MATCH}
          </div>
        )}
        {connectionError && <div className={element('Error')}>{connectionError}</div>}
        <PrimaryInput
          focus
          name="password"
          value={password}
          error={errorPassword}
          onChange={e => this.onPasswordInput(e.currentTarget.value)}
          onBlur={() => this.validate('password')}
          label={RESET_PASSWORD_FORM.NEW_PASSWORD}
          type="password"
        />
        <PrimaryInput
          name="password1"
          value={password1}
          error={errorRepeat}
          onChange={e => this.onPassword1Input(e.currentTarget.value)}
          onBlur={() => this.validate('passwordRepeat')}
          label={RESET_PASSWORD_FORM.REENTER_NEW_PASSWORD}
          type="password"
        />
        <Button
          type="submit"
          onClick={undefined}
          disabled={errorPassword || errorRepeat}
          className={element('Submit')}
          text={
            isCreating
              ? RESET_PASSWORD_FORM.CREATE_MY_PASSWORD
              : RESET_PASSWORD_FORM.CHANGE_MY_PASSWORD
          }
          style="default"
          size="block"
        />
        {isSent && <Redirect to="/login" />}
      </form>
    )
  }

  private validate = (field?: string) => {
    let { errorPassword, errorRepeat } = this.state

    if (!field || field === 'password') {
      errorPassword = Boolean(PasswordValidator.general().validate(this.state.password))
    }
    if (!field || field === 'passwordRepeat') {
      errorRepeat = this.state.password !== this.state.password1
    }
    this.setState(() => ({ errorPassword, errorRepeat }))
  }

  private onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    this.validate()
    if (this.state.errorPassword || this.state.errorRepeat) {
      return
    }

    ga.track('auth', 'set password')

    try {
      await resetPassword(
        this.props.match.params.id,
        this.state.password,
        this.state.password1
      )
    } catch (e) {
      const status = e?.response?.status
      const type = e?.response?.data?.type
      const description = e?.response?.data?.description

      if (status === 401 && ['RevokedToken', 'ExpiredToken'].includes(type)) {
        return this.setState({
          connectionError: RESET_PASSWORD_FORM.REVOKED_TOKEN,
        })
      }
      if (status === 400 && description) {
        return this.setState({ connectionError: description })
      }
      return this.setState({
        connectionError: RESET_PASSWORD_FORM.CONNECTION_ERROR,
      })
    }
    this.props.openToast({ title: RESET_PASSWORD_FORM.CONFIRMATION })
    this.setState({ isSent: true, connectionError: null })
  }

  private onPasswordInput = (value: string) => this.setState(() => ({ password: value }))
  private onPassword1Input = (value: string) =>
    this.setState(() => ({ password1: value }))
}

const mapActionsToProps: IActionProps = {
  openToast,
}

export default connect(null, mapActionsToProps)(SetPasswordForm)
