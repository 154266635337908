import * as d3 from 'd3'
import * as React from 'react'
import * as FauxDOM from 'react-faux-dom'
import { COLORS, HIGHLIGHT_COLORS } from 'services/series'

import { XScale, YScale, getDataPointBisector, renderBars } from '../../services/hd3'
import { IStackedConfig, calculateRichPoints } from '../../services/hd3-utils/stacked-bar'

interface IProps {
  config: IStackedConfig
  xScale: XScale
  yScale: YScale
  selectedDate: Date
  barVariablesCount: number
}

function firstWithSameSign(points: any, seriesIndex: number, index: number) {
  const sign = Math.sign(points[seriesIndex][index].value)
  for (let i = seriesIndex - 1; i >= 0; i--) {
    const point = points[i][index]
    if (Math.sign(point.value) === sign) {
      return point.y
    }
  }
  return undefined
}

function StackedBars({
  config,
  xScale,
  yScale,
  selectedDate,
  barVariablesCount,
}: IProps) {
  const svg = FauxDOM.createElement('g')
  const selection = d3.select(svg)

  const richPoints = React.useMemo(
    () => calculateRichPoints(config, xScale, yScale),
    [config, xScale, yScale]
  )
  const bisector = getDataPointBisector().left
  const y0 = yScale(0)
  richPoints.map((points, i) => {
    const series = config.variables[i]
    const color = COLORS[series.colorIndex]
    const highlightColor = HIGHLIGHT_COLORS[series.colorIndex]
    const isShaded = config.types[i] === 'SHADED_STACKEDBAR'
    const getY0 =
      i === 0
        ? undefined
        : (index: number) => firstWithSameSign(richPoints, i, index) ?? y0

    let selectedIndex = -1
    if (selectedDate) {
      selectedIndex = bisector(points, { date: selectedDate, value: 0 })
    }

    renderBars(
      selection,
      yScale,
      series,
      selectedIndex,
      color,
      highlightColor,
      isShaded,
      points,
      barVariablesCount,
      barVariablesCount - 1, // always render as a last bar
      getY0
    )
  })

  return <>{React.Children.map(svg.toReact(), c => c.props.children)}</>
}

export default React.memo(StackedBars)
