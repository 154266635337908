import * as React from 'react'
import { SIDEBAR, DEFAULT_EXPORT_FILENAME } from '../../messages'
import { bindBem } from '../../bem'
import RadioButton from '../RadioButton'
import { RadioGroup } from '../RadioGroup'
import { Button } from '../Button'
import { ICON_HELP } from '../SVG/Help'

import LinkShare from './LinkShare'
import APILinkShare from './APILinkShare'
import { downloadData } from '../../services/export'
import * as ga from 'analytics'

import './ExportDialog.scss'

const { EXPORT_TAB } = SIDEBAR

export interface IProps {
  apiToken: string
  variables: IDataSeries[]
  onDownloadSVG: () => void
  onDownloadPNG: () => void
  title: string
}

const PrintTypes = ['portrait-full', 'portrait-data', 'landscape']
const DownloadTypes = ['csv', 'xlsx']
const ExportTypes = ['png', 'svg']

export interface IState {
  printType: string
  downloadType: string
  exportType: string
  showHelp: boolean
  showDownloadModal: boolean
}

export class ExportDialog extends React.Component<IProps, IState> {
  state: IState = {
    printType: PrintTypes[0],
    downloadType: DownloadTypes[0],
    exportType: ExportTypes[0],
    showHelp: false,
    showDownloadModal: false,
  }

  render() {
    const { apiToken } = this.props
    const { block, element } = bindBem('ExportDialog')

    return (
      <div className={block()}>
        <div className={element('Content')}>
          <div className={element('GroupTitle')}>{EXPORT_TAB.SHARE_LINK.TITLE}</div>
          <div className={element('Group')}>
            <LinkShare />
          </div>

          <div className={element('GroupTitle')}>{EXPORT_TAB.PRINT}</div>
          <div className={element('Group')}>
            <div className={element('PrintType')}>{EXPORT_TAB.GRAPH_ONLY}</div>
            <Button
              text={EXPORT_TAB.PRINT}
              onClick={() => this.print()}
              size="small"
              style="light"
            />
          </div>

          <div className={element('GroupTitle')}>{EXPORT_TAB.DOWNLOAD_DATA}</div>
          <div className={element('Group')}>
            <RadioGroup onChange={this.setDownloadType} value={this.state.downloadType}>
              <RadioButton value={DownloadTypes[0]}>.CSV</RadioButton>
              <RadioButton value={DownloadTypes[1]}>.XLSX</RadioButton>
            </RadioGroup>
            <Button
              text={EXPORT_TAB.DOWNLOAD}
              onClick={this.onDataDownload}
              size="small"
              style="light"
            />
          </div>

          <div className={element('GroupTitle')}>{EXPORT_TAB.EXPORT_GRAPH}</div>
          <div className={element('Group')}>
            <RadioGroup onChange={this.setExportType} value={this.state.exportType}>
              <RadioButton value={ExportTypes[0]}>.PNG</RadioButton>
              <RadioButton value={ExportTypes[1]}>.SVG</RadioButton>
            </RadioGroup>
            <Button
              text={SIDEBAR.EXPORT}
              onClick={this.onExportImage}
              size="small"
              style="light"
            />
          </div>

          {apiToken !== null && (
            <>
              <div className={element('GroupTitle')}>
                {EXPORT_TAB.EXPORT_API_DATA.TITLE}
                <div className={element('HelpIcon')} onClick={this.toggleHelp}>
                  {ICON_HELP}
                </div>
              </div>
              <div className={element('Group')}>
                <APILinkShare
                  apiToken={apiToken}
                  showHelp={this.state.showHelp}
                  toggleHelp={this.toggleHelp}
                />
              </div>
            </>
          )}
        </div>
      </div>
    )
  }

  private print = () => ga.track('graph', 'printGraph') && window.print()
  private toggleHelp = () => this.setState({ showHelp: !this.state.showHelp })

  private setExportType = (exportType: string) => this.setState({ exportType })
  private setDownloadType = (downloadType: string) => this.setState({ downloadType })
  private onExportImage = () => {
    const { exportType } = this.state
    ga.track('graph', 'exportImage', { type: exportType })
    exportType === 'svg' ? this.props.onDownloadSVG() : this.props.onDownloadPNG()
  }
  private onDataDownload = () => {
    ga.track('graph', 'exportData', { type: this.state.downloadType })
    downloadData(
      this.props.title || DEFAULT_EXPORT_FILENAME,
      this.props.variables,
      this.state.downloadType
    )
  }
}
