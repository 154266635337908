import { debounce } from 'lodash'
import React from 'react'

import { withResponsive } from './components/Responsive'

interface IProps {
  isMobile: boolean
  children?: React.ReactNode
}

class Resizer extends React.PureComponent<IProps> {
  onResize = debounce(() => {
    const vh = window.innerHeight
    document.body.style.setProperty('height', `${vh}px`)
  }, 16)

  removeExtraStyles = () => document.body.style.setProperty('height', null)

  attach = () => {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  }
  detach = () => {
    window.removeEventListener('resize', this.onResize)
    this.onResize.cancel()
    this.removeExtraStyles()
  }

  componentDidMount() {
    if (this.props.isMobile) {
      this.attach()
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (!prevProps.isMobile && this.props.isMobile) {
      this.attach()
    }
    if (prevProps.isMobile && !this.props.isMobile) {
      this.detach()
    }
  }

  componentWillUnmount() {
    this.detach()
  }
  render() {
    return this.props.children
  }
}

export default withResponsive(Resizer)
