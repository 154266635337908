import * as React from 'react'

import { HelpTooltip } from './../HelpTooltip'

import './IpRestrictionStatusTooltip.scss'

export const IpRestrictionStatusTooltip: React.FunctionComponent<{
  id: string
}> = ({ id }) => (
  <HelpTooltip className="IP-Restriction-Tooltip" id={id}>
    Users with a restricted IP access use organization IP ranges too (even though IP
    restriction on organization level is turned off).
  </HelpTooltip>
)
