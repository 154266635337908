// eslint-disable max-lines

import * as React from 'react'
import _ from 'lodash'
import keycode from 'keycode'
import { bindBem } from '../../bem'

import { Checkbox } from '../Checkbox'
import { ORGANIZATION_FORM, ADMIN } from '../../messages'
import { Input } from '../Input'
import { Switch } from '../Sidebar/Switch'
import { cmp } from 'utils'

import { ICON_LOUPE } from '../SVG/Loupe'

import './ItemSelector.scss'

const TabName: React.FC<{ onClick: () => void; selected: boolean }> = ({
  children,
  onClick,
  selected,
}) => {
  const { block } = bindBem('SelectorTabName')
  return (
    <div className={block({ selected })} onClick={onClick}>
      {children}
    </div>
  )
}

export const Tab: React.FC<{ active: boolean }> = ({ children, active }) => {
  if (!active) {
    return null
  }
  return <>{children}</>
}

export const SelectorTabs: React.FC = props => {
  const { block } = bindBem('SelectorTabs')
  return <div className={block()}>{props.children}</div>
}

type HeaderProps = {
  names: string[]
  active: number
  changeTab: (i: number) => void
}

export const SelectorTabsHeader: React.FC<HeaderProps> = props => {
  return (
    <div className="SelectorTabsHeader">
      {props.names.map((name, i) => (
        <TabName onClick={() => props.changeTab(i)} selected={i === props.active} key={i}>
          {name}
        </TabName>
      ))}
    </div>
  )
}

interface IItem<T> {
  name: string
  id: T
}

interface Stat {
  label: string
  value: string
}

export interface IItemRendererProps<T> {
  item: IItem<T>
  isSelected: boolean
  isPreview?: boolean
  onChange: (add: boolean) => void
  onPreviewChange?: (add: boolean) => void
  nonInteractive?: boolean
}

interface IProps<T> {
  stats?: Stat[]
  menuRenderer?: React.FC<{}>
  items: IItem<T>[]
  selectedIds: T[]
  previewedIds?: T[]
  onChange: (ids: T[], previewIds: T[]) => void
  ItemRenderer?: React.FC<IItemRendererProps<T>>
  itemCompare?: (a: any, b: any) => number
  nonInteractive?: boolean
  toggleAllDisabled?: boolean
}

export interface IState<T> {
  query: string
  shiftPressed: boolean
  lastIndex: number | null
  initialItemProps: {
    selectedIds: T[]
    previewedIds: T[]
  }
}

function allToggled<T>(items: IItem<T>[], selectedIds: T[]): boolean {
  return items.every(({ id }) => selectedIds.includes(id))
}

export class ItemSelector<T extends string | number> extends React.Component<
  IProps<T> & { title?: string },
  IState<T>
> {
  state: IState<T> = {
    query: '',
    shiftPressed: false,
    lastIndex: null,
    initialItemProps: {
      selectedIds: this.props.selectedIds,
      previewedIds: this.props.previewedIds,
    },
  }

  componentDidMount() {
    if (document.body) {
      document.body?.addEventListener('keydown', this.onShiftDown)
      document.body?.addEventListener('keyup', this.onShiftUp)
    }
  }

  componentWillUnmount() {
    if (document.body) {
      document.body.removeEventListener('keydown', this.onShiftDown)
      document.body.removeEventListener('keyup', this.onShiftUp)
    }
  }

  RenderStat: React.FC<{ label: string; value: string }> = ({ label, value }) => {
    return (
      <span>
        {label}: <span>{value}</span>
      </span>
    )
  }

  render() {
    const { selectedIds, previewedIds, title, stats } = this.props
    const { query } = this.state
    const { block, element } = bindBem('ItemSelector')
    const Row = this.props.ItemRenderer || CheckboxWithSwitch
    const Menu = this.props.menuRenderer
    const items = this.getItems()

    return (
      <div className={block()}>
        {title && (
          <div className={element('Title')}>
            <div>{title}</div>
          </div>
        )}
        <div className={element('Search')}>
          {(!this.props.nonInteractive || stats) && (
            <div className={element('Toolbar')}>
              <div className={element('ToolbarLCol')}>
                {!this.props.nonInteractive && !this.props.toggleAllDisabled && (
                  <Checkbox
                    checked={allToggled(items, selectedIds)}
                    onChange={this.toggleAll}
                    label={ORGANIZATION_FORM.CHECK_ALL}
                    big
                  />
                )}
              </div>
              <div className={element('ToolbarRCol')}>
                <div className={element('Stats')}>
                  {stats &&
                    stats.map(({ label, value }, i) => (
                      <this.RenderStat key={`stat-${i}`} label={label} value={value} />
                    ))}
                </div>
                {!this.props.nonInteractive && (
                  <div className={element('ImportExportMenu')}>{Menu && <Menu />}</div>
                )}
              </div>
            </div>
          )}
          <Input
            value={query}
            name="search"
            onChange={e => this.setState({ query: e.currentTarget.value })}
            placeholder={ORGANIZATION_FORM.FILTER_PLACEHOLDER}
            prefix={ICON_LOUPE}
          />
        </div>
        <div className={element('Results')}>
          {items.sort(this.sortItemsByInitialSelection).map(item => (
            <Row
              key={item.id}
              item={item}
              isSelected={selectedIds.includes(item.id)}
              isPreview={previewedIds?.includes(item.id)}
              onChange={add => this.toggleItem(add, item.id)}
              onPreviewChange={
                previewedIds === undefined
                  ? undefined
                  : add => this.togglePreview(add, item.id)
              }
              nonInteractive={this.props.nonInteractive}
            />
          ))}
        </div>
      </div>
    )
  }

  getItems = () => {
    const { query } = this.state
    const { items } = this.props

    if (query.length === 0) {
      return items
    }

    const queryParts = query
      .toLowerCase()
      .split(/\s/)
      .filter(part => part.length > 0)

    const includes = (attr: string) => queryParts.every(part => attr.includes(part))

    const filteredItems = items.filter(d =>
      includes(d.name.toLowerCase() + d.id.toString().toLowerCase())
    )
    return filteredItems
  }

  private sortItemsByInitialSelection = (a: IItem<T>, b: IItem<T>) => {
    const { itemCompare } = this.props
    const {
      initialItemProps: { selectedIds, previewedIds },
    } = this.state

    return (
      itemCompare?.(a, b) ||
      cmp(selectedIds.includes(a.id) ? -1 : 1, selectedIds.includes(b.id) ? -1 : 1) ||
      (previewedIds &&
        cmp(
          previewedIds.includes(a.id) ? -1 : 1,
          previewedIds.includes(b.id) ? -1 : 1
        )) ||
      a.name.localeCompare(b.name, 'en-US', { numeric: true })
    )
  }

  toggleItem = (add: boolean, id: T) => {
    const [...ids] = this.props.selectedIds
    const [...previewedIds] = this.props.previewedIds || []
    const index = this.props.items.findIndex(item => item.id === id)
    const { lastIndex, shiftPressed } = this.state

    this.setState(() => ({ lastIndex: index }))

    if (lastIndex !== null && shiftPressed) {
      return this.selectBulk(Math.min(lastIndex, index), Math.max(lastIndex, index) + 1)
    }
    if (add) {
      ids.push(id)
      if (previewedIds.includes(id)) {
        previewedIds.splice(previewedIds.indexOf(id), 1)
      }
    } else {
      ids.splice(ids.indexOf(id), 1)
    }
    this.props.onChange(ids, previewedIds)
  }

  selectBulk = (start: number, end: number) => {
    let [...ids] = this.props.selectedIds
    let [...previewedIds] = this.props.previewedIds || []

    const idsSpan = this.props.items.slice(start, end).map(item => item.id)
    const intersection = _.intersection(idsSpan, ids)

    if (intersection.length === idsSpan.length) {
      ids = _.difference(ids, idsSpan)
    } else {
      ids = ids.concat(_.difference(idsSpan, ids))
      previewedIds = _.difference(previewedIds, ids)
    }
    this.props.onChange(ids, previewedIds)
  }

  private toggleAll = (add: boolean) => {
    add ? this.addAllFiltered() : this.removeAllFiltered()
  }

  private addAllFiltered = () => {
    const items = this.getItems().map(d => d.id)
    const selectedItems = new Set([...this.props.selectedIds, ...items])
    const previewed = (this.props.previewedIds ?? []).filter(v => !selectedItems.has(v))

    this.props.onChange(Array.from(selectedItems.values()), previewed)
  }

  private removeAllFiltered = () => {
    const items = new Set(this.getItems().map(d => d.id))
    const selectedItems = this.props.selectedIds.filter(id => !items.has(id))

    this.props.onChange(Array.from(selectedItems.values()), this.props.previewedIds)
  }

  private togglePreview = (add: boolean, id: T) => {
    const [...ids] = this.props.selectedIds
    const [...previewedIds] = this.props.previewedIds || []

    if (add) {
      if (ids.includes(id)) {
        ids.splice(ids.indexOf(id), 1)
      }
      previewedIds.push(id)
    } else {
      previewedIds.splice(previewedIds.indexOf(id), 1)
    }
    this.props.onChange(ids, previewedIds)
  }

  onShiftDown = (e: KeyboardEvent) =>
    keycode(e) === 'shift' && this.setState({ shiftPressed: true })
  onShiftUp = (e: KeyboardEvent) =>
    keycode(e) === 'shift' && this.setState({ shiftPressed: false })
}

export const DatabaseSelector: React.FC<IProps<string>> = props => (
  <ItemSelector {...props} title={ORGANIZATION_FORM.DATABASES} />
)

export const OrganizationSelector: React.FC<IProps<number>> = props => (
  <ItemSelector {...props} title={ADMIN.DB.ORGANIZATIONS} />
)

export const CheckboxWithSwitch = <T extends {}>(props: IItemRendererProps<T>) => {
  return (
    <div className="CheckboxWithSwitch">
      <Checkbox
        label={props.item.name}
        checked={props.isSelected}
        onChange={props.onChange}
        big
      />
      {!props.isSelected && props.onPreviewChange && (
        <Switch
          title="preview"
          value={props.isPreview}
          onChange={props.onPreviewChange}
        />
      )}
    </div>
  )
}
