// eslint-disable max-len

import * as React from 'react'

const DotsIcon = ({ color }: any) => (
  <svg width="20" height="18" viewBox="0 0 20 18">
    <path
      fill={color}
      fillRule="evenodd"
      d="M10.5 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
    />
  </svg>
)

export const DOTS_ICON = <DotsIcon color="#8590A0" />
export const DOTS_ICON_LIGHT = <DotsIcon color="#FFFFFF" />
