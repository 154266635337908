import * as React from 'react'

import './ActivityDot.scss'

export interface IProps {
  size?: 'large' | 'medium' | 'small'
  title?: string
  className?: string
  value: boolean | null
}

export const ActivityDot = (props: IProps) => {
  const { size = 'medium', className = '', title = '', value } = props
  const status = value === null ? 'unknown' : value ? 'active' : 'inactive'
  return (
    <span
      className={`ActivityDot ActivityDot--${size} ActivityDot--${status} ${className}`}
      title={title}
    ></span>
  )
}
