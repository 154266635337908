import * as React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'

import { Input, Labeled } from '../../components/Input'
import { DatabaseTabs } from './TabbedSelectors'
import { Button } from '../Button'
import { DB_STATS } from '../../messages'

import './DatabaseForm.scss'

const SCHEMA = yup.object({ description: yup.string() })

interface IProps {
  database: IDatabaseDetails
  packages: IDBPackage[]
  organizations: IOrganization[]
  onSave: (database: IDatabaseDetails) => void
}

export function DatabaseForm(props: IProps) {
  const [packages, setPackages] = React.useState<string[]>(props.database.packages)

  const [organizations, setOrganizations] = React.useState<
    { name: string; id: number }[]
  >(props.database.organizations)

  const [previewOrganizations, setPreviewOrganizations] = React.useState<
    { name: string; id: number }[]
  >(props.database.previewOrganizations)

  const organizationsFromSelectedPackages = () => {
    const pkgOrgs: number[] = packages.reduce((orgsFromPackages, packageId) => {
      const pkg = props.packages.find(p => p.id === packageId)
      return pkg ? [...orgsFromPackages, ...pkg.organizations] : [...orgsFromPackages]
    }, [])
    return Array.from(new Set(pkgOrgs))
  }

  const pkgOrganizations = organizationsFromSelectedPackages().filter(
    orgId => !organizations.map(org => org.id).includes(orgId)
  )

  const stats = [
    {
      label: DB_STATS.DIRECT_LABEL,
      value: `${organizations.length}`,
    },
    {
      label: DB_STATS.FROM_PACKAGES_LABEL,
      value: `${pkgOrganizations.length}`,
    },
  ]

  const onChangeOrganizations = (selected: number[], preview: number[]) => {
    const orgs = props.organizations
      .filter(org => selected.includes(org.id))
      .map(org => ({ name: org.name, id: org.id }))

    const previewOrgs = props.organizations
      .filter(org => preview.includes(org.id))
      .map(org => ({ name: org.name, id: org.id }))

    setOrganizations(orgs)
    setPreviewOrganizations(previewOrgs)
  }

  const onChangePackages = (pkgs: string[]) => setPackages(pkgs)

  const onSubmit = (database: IDatabaseDetails) =>
    props.onSave({ ...database, packages, organizations, previewOrganizations })

  return (
    <Formik
      initialValues={{ ...props.database }}
      validationSchema={SCHEMA}
      onSubmit={onSubmit}
    >
      {({ values, touched, errors, submitForm, handleChange }) => (
        <div className="DatabaseForm">
          <Labeled
            error={touched.description && errors.description}
            label={'DATABASE DESCRIPTION'}
          >
            <Input
              name="description"
              value={values.description}
              error={touched.description && !!errors.description}
              onChange={handleChange}
            />
          </Labeled>
          <DatabaseTabs
            stats={stats}
            selectedPackages={packages}
            packages={props.packages}
            onPackagesChange={onChangePackages}
            organizations={props.organizations}
            organizationsCount={organizations.length + pkgOrganizations.length}
            selectedOrganizations={organizations.map(o => o.id)}
            previewedOrganizations={previewOrganizations.map(o => o.id)}
            onOrganizationsChange={onChangeOrganizations}
          />
          <Button
            onClick={submitForm}
            size="small"
            text="Save"
            style="dark"
            className="ApplyBtn"
          />
        </div>
      )}
    </Formik>
  )
}
