import React from 'react'
import { DropdownButton } from './DropdownButton'
import { GraphButton } from './Buttons'
import { RadioGroup, Option, Separator } from '../RadioGroup'
import * as ICONS from '../SVG/Chart'
import { TOOLTIPS } from '../../messages'

const TYPES: { [key in ISeriesType]: JSX.Element[] } = {
  LINE: [ICONS.ICON_LINE, ICONS.ICON_LINE_LIGHT],
  DOTTEDLINE: [ICONS.ICON_DOTTED_LINE, ICONS.ICON_DOTTED_LINE_LIGHT],
  DASHEDLINE: [ICONS.ICON_DASHED_LINE, ICONS.ICON_DASHED_LINE_LIGHT],
  AREA: [ICONS.ICON_AREA, ICONS.ICON_AREA_LIGHT],
  BAR: [ICONS.ICON_BAR, ICONS.ICON_BAR_LIGHT],
  SHADED_BAR: [ICONS.ICON_SHADED_BAR, ICONS.ICON_SHADED_BAR_LIGHT],
  STEPLINE: [ICONS.ICON_STEPLINE, ICONS.ICON_STEPLINE_LIGHT],
  STACKEDBAR: [ICONS.ICON_STACKED_BAR, ICONS.ICON_STACKED_BAR_LIGHT],
  SHADED_STACKEDBAR: [ICONS.ICON_SHADED_STACKED_BAR, ICONS.ICON_SHADED_STACKED_BAR_LIGHT],
  SCATTER: [ICONS.ICON_SCATTER_GRAPH, ICONS.ICON_SCATTER_GRAPH_LIGHT],
}

const BUTTON_TYPES: { [key in ISeriesType]: JSX.Element[] } = {
  ...TYPES,
  SHADED_BAR: [ICONS.ICON_SHADED_BAR, ICONS.ICON_SHADED_BAR_ACTIVE],
  SHADED_STACKEDBAR: [
    ICONS.ICON_SHADED_STACKED_BAR,
    ICONS.ICON_SHADED_STACKED_BAR_ACTIVE,
  ],
}

interface IProps {
  value: ISeriesType
  onChange: (type: ISeriesType) => void
  onlyLines?: boolean
  stackedBarDisabled?: boolean
  scatterDisabled?: boolean
  disabled?: boolean
  title?: string
}

export const GraphTypeDropdown = ({
  value,
  onChange,
  onlyLines,
  stackedBarDisabled,
  scatterDisabled,
  disabled,
  title,
}: IProps) => {
  const wrapperRef = React.createRef<DropdownButton>()

  const Button = (props: { active: boolean; onClick: () => void }) => (
    <GraphButton
      icons={value ? BUTTON_TYPES[value] : BUTTON_TYPES.LINE}
      {...props}
      disabled={disabled}
    />
  )

  return (
    <DropdownButton
      buttonRenderer={Button}
      stickTo="left"
      ref={wrapperRef}
      title={title || TOOLTIPS.GRAPH_TYPE}
    >
      <RadioGroup
        value={value}
        onChange={val => {
          onChange(val as ISeriesType)
          wrapperRef.current.togglePortal()
        }}
      >
        <Option value="LINE">{TYPES.LINE}Line</Option>
        <Option value="DOTTEDLINE">{TYPES.DOTTEDLINE}Line dotted</Option>
        <Option value="DASHEDLINE">{TYPES.DASHEDLINE}Line dashed</Option>
        {!onlyLines && (
          <>
            <Separator />
            <Option value="AREA">{TYPES.AREA}Area Graph</Option>
            <Separator />
            {!scatterDisabled && (
              <>
                <Option value="SCATTER">{TYPES.SCATTER}Scatter Graph</Option>
                <Separator />
              </>
            )}
            <Option value="STEPLINE">{TYPES.STEPLINE}Step Lines</Option>
            <Separator />
            <Option value="BAR">{TYPES.BAR}Bar Graph</Option>
            <Option value="SHADED_BAR">{TYPES.SHADED_BAR}Shaded Bar Graph</Option>
            <Separator />
            <Option value="STACKEDBAR" disabled={stackedBarDisabled}>
              {TYPES.STACKEDBAR}Stacked Graph
            </Option>
            <Option value="SHADED_STACKEDBAR" disabled={stackedBarDisabled}>
              {TYPES.SHADED_STACKEDBAR}Shaded Stacked Graph
            </Option>
          </>
        )}
      </RadioGroup>
    </DropdownButton>
  )
}
