import * as d3 from 'd3'
import * as _ from 'lodash'
import { MONOSPACE, COURIER_PRIME } from '../../constants'

export interface IDataLabelsContent {
  dataPoints: RichDataPoint[]
  color: string
  paleColor: string
  decimalPrecision: number
}

export interface INode extends RichDataPoint {
  fx: number
  fy: number
  color: string
  paleColor: string
  valueStr: string
  dpX?: number
  dpY?: number
}

function getWidth(str: string) {
  return str.length * 8
}

const labelHeight = 20
const steps = 10
const startOffset = 500

const formatValue = (value: number, decimalPrecision: number): string => {
  return value && !Number.isNaN(value) ? value.toFixed(decimalPrecision) : 'N/A'
}

const labelFactory = (selection: d3.Selection<d3.BaseType, INode, d3.BaseType, {}>) => {
  selection
    .append('rect')
    .attr('x', (d: INode) => d.x - getWidth(d.valueStr) / 2)
    .attr('y', (d: INode) => d.y - labelHeight / 2)
    .attr('width', d => getWidth(d.valueStr))
    .attr('height', labelHeight)
    .attr('rx', 2)
    .attr('ry', 2)
    .attr('stroke-width', 1)
    .attr('stroke', (d: INode) => d.color)
    .attr('fill', (d: INode) => d.paleColor)
    .attr('opacity', 0.8)

  selection
    .append('text')
    .text((d: INode) => d.valueStr)
    .attr('x', (d: INode) => d.x)
    .attr('y', (d: INode) => d.y + 4)
    .attr('fill', 'black')
    .style('font-size', '12px')
    .style('text-anchor', 'middle')
    .style('font-family', `${COURIER_PRIME}, ${MONOSPACE}`)
    .style('letter-spacing', -0.5)

  selection
    .append('line')
    .attr('x1', (d: INode) => d.x)
    .attr('y1', (d: INode) => {
      if (d.y > d.dpY) {
        return d.y - labelHeight / 2
      }
      return d.y + labelHeight / 2
    })
    .attr('x2', (d: INode) => d.dpX)
    .attr('y2', (d: INode) => d.dpY)
    .attr('stroke', (d: INode) => d.color)
    .attr('stroke-width', 0.5)

  selection
    .append('line2')
    .attr('x1', (d: INode) => d.x)
    .attr('y1', (d: INode) => {
      if (d.y > d.dpY) {
        return d.y - labelHeight / 2
      }
      return d.y + labelHeight / 2
    })
    .attr('x2', (d: INode) => d.dpX)
    .attr('y2', (d: INode) => d.dpY)
    .attr('stroke', (d: INode) => d.color)
    .attr('stroke-width', 0.5)
}

const convertToNode = (dp: RichDataPoint, series: IDataLabelsContent): INode => ({
  ...dp,
  fx: dp.x,
  fy: dp.y,
  color: series.color,
  paleColor: series.paleColor,
  valueStr: formatValue(dp.value, series.decimalPrecision),
})

const getAllPointNodes = (
  variables: IDataLabelsContent[],
  width: number,
  height: number
): INode[] => {
  const labelsCount = (height * width) / 10000
  const pointsCount = _.sumBy(variables, v => v.dataPoints.length)
  const pointsPerLabel = Math.ceil(pointsCount / labelsCount)
  const sampledVariables = variables.map(series => ({
    ...series,
    dataPoints: _.filter(series.dataPoints, (_dp, i) => i % pointsPerLabel === 0),
  }))
  const pointNodes: INode[] = []
  sampledVariables.forEach(series =>
    series.dataPoints.forEach(dp => pointNodes.push(convertToNode(dp, series)))
  )
  return pointNodes
}

const getLastPointNodes = (variables: IDataLabelsContent[]): INode[] => {
  const pointNodes: INode[] = []
  variables.forEach(series => {
    const dp = _.last(series.dataPoints)
    if (dp) {
      pointNodes.push(convertToNode(dp, series))
    }
  })
  return pointNodes
}

export const renderDataLabels = (
  selection: D3Selection,
  variables: IDataLabelsContent[],
  dataLabelsType: DataLabelsType,
  width: number,
  height: number
) => {
  const linkForceDistance = dataLabelsType === 'ALL' ? 50 : 0

  const filterAllPoints = (dp: INode) =>
    dp.value !== null &&
    dp.dpX !== undefined &&
    dp.y > labelHeight / 2 &&
    dp.y < height - labelHeight / 2 &&
    dp.x > getWidth(dp.valueStr) / 2 &&
    dp.x < width - getWidth(dp.valueStr) / 2 &&
    _.inRange(dp.dpY, 0, height) &&
    _.inRange(dp.dpX, 0, width)
  const filterLastPoints = (dp: INode) => dp.dpX !== undefined

  const pointNodes: INode[] =
    dataLabelsType === 'ALL'
      ? getAllPointNodes(variables, width, height)
      : getLastPointNodes(variables)

  const labelNodes: INode[] = pointNodes.map(dp => ({
    ...dp,
    fx: null,
    fy: null,
    y:
      dp.y -
      (dataLabelsType === 'ALL' ? labelHeight : 0) +
      (dp.y < height / 2 ? startOffset : -startOffset),
    x: dp.x - (dataLabelsType === 'ALL' ? 0 : getWidth(dp.valueStr)),
    dpX: dp.x,
    dpY: dp.y,
  }))

  const allNodes = labelNodes.concat(pointNodes)
  const links = labelNodes.map((_dp, i) => ({
    source: i,
    target: i + labelNodes.length,
  }))

  const collisionForce = d3
    .forceCollide()
    .radius((d: INode) => (d.dpX !== undefined ? getWidth(d.valueStr) / 2 : 0))
    .iterations(steps)

  const linkForce = d3.forceLink(links).distance(linkForceDistance).iterations(steps)

  const simulation = d3
    .forceSimulation(allNodes)
    .force('linkForce', linkForce)
    .force('collisionForce', collisionForce)

  for (let i = 0; i < steps; i++) {
    simulation.tick()
  }

  const finalPoints = _.filter(
    allNodes,
    dataLabelsType === 'ALL' ? filterAllPoints : filterLastPoints
  )
  selection
    .selectAll('.labelGroup')
    .data(finalPoints)
    .enter()
    .append('g')
    .attr('class', 'labelGroup')
    .call(labelFactory)
}
