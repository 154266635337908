import { CLIENT } from '../api/config'
import { fetchAll } from './common'

const FAV_DB_URL = '/v2/favourite_databases'
const MENU_SERIES_URL = '/v2/menu/series'

interface DataDirectoryLookup {
  data_json: any
  name: string
  databaseName: string
  isPreview: boolean
}

export const getDataDirectoryEntry = async (
  db: string,
  series: string
): Promise<DataDirectoryLookup> => {
  const response = await CLIENT.get<any>(`${MENU_SERIES_URL}/${db}/${series}`)
  return response.data
}

export const fetchFavouriteDatabases = async (): Promise<string[]> => {
  const response = await fetchAll<FavouriteDatabase>(`${FAV_DB_URL}`)
  return response.map(fav => fav.databaseName)
}

export const createFavouriteDatabase = async (
  databaseName: string
): Promise<FavouriteDatabase> => {
  const response = await CLIENT.post<FavouriteDatabase>(`${FAV_DB_URL}`, {
    databaseName,
  })
  return response.data
}

export const removeFavouriteDatabase = async (databaseName: string) =>
  CLIENT.delete(`${FAV_DB_URL}/${databaseName}`)
