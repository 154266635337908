import * as React from 'react'
import { Link } from 'react-router-dom'
import { ADMIN, API_ERRORS } from './messages'

export const DATABASE = {
  DELETED: (db: IDatabase) => ({
    title: (
      <span>
        <b>{db.name}</b> removed.
      </span>
    ),
  }),
}

export const ORGANIZATION = {
  DATABASES_IMPORTED: (count: number) => ({
    title: (
      <span>
        There have been <b>{count}</b> {count === 1 ? 'database' : 'databases'} selected
      </span>
    ),
  }),
  CREATED: (org: IOrganization) => ({
    title: (
      <span>
        <b>{org.name}</b> created.
      </span>
    ),
    aside: <Link to={`/admin/organizations/${org.id}`}>Go to entry</Link>,
  }),
  UPDATED: (org: IOrganization) => ({
    title: (
      <span>
        <b>{org.name}</b> updated.
      </span>
    ),
  }),
  STATUS_UPDATED: (org: IOrganization) => ({
    title: (
      <span>
        <b>{org.name}</b> {org.status ? 'reactivated' : 'deactivated'}.
      </span>
    ),
  }),
  PATCHED: (org: IOrganization, field?: string) => ({
    title: (
      <span>
        <b>{org.name}</b> {field} updated.
      </span>
    ),
  }),
  ACTIVATED: (org: IOrganization) => ({
    title: (
      <span>
        <b>{org.name}</b> activated.
      </span>
    ),
  }),
  DEACTIVATED: (org: IOrganization) => ({
    title: (
      <span>
        <b>{org.name}</b> deactivated.
      </span>
    ),
  }),
  DELETED: (org: IOrganization) => ({
    title: (
      <span>
        <b>{org.name}</b> removed.
      </span>
    ),
  }),
}

export const USER = {
  CREATED: (user: IUser) => ({
    title: (
      <span>
        <b>{user.username}</b> added.
      </span>
    ),
  }),
  UPDATED: (user: IUser) => ({
    title: (
      <span>
        <b>{user.username}</b> updated.
      </span>
    ),
  }),
  ROLE_UPDATED: (user: IUser) => ({
    title: (
      <span>
        <b>{user.username}</b> set to {ADMIN.USERS.ROLES[user.role]}.
      </span>
    ),
  }),
  STATUS_UPDATED: (user: IUser) => ({
    title: (
      <span>
        <b>{user.username}</b> {user.settings.isActive ? 'reactivated' : 'deactivated'}.
      </span>
    ),
  }),
  MIGRATION_STATUS_UPDATED: (user: IUser) => ({
    title: (
      <span>
        <b>{user.username}</b> is now marked as{' '}
        {user.settings.isMigrated ? 'migrated' : 'not migrated'}.
      </span>
    ),
  }),
  API_STATUS_UPDATED: (user: IUser) => ({
    title: (
      <span>
        <b>{user.username}</b> API status{' '}
        {user.settings.apiAccess ? 'enabled' : 'disabled'}.
      </span>
    ),
  }),
  DELETED: (user: IUser) => ({
    title: (
      <span>
        <b>{user.username}</b> removed.
      </span>
    ),
  }),
  PASSWORD_CHANGED: () => ({
    title: (
      <span>
        <b>User password</b> updated
      </span>
    ),
  }),
  DB_STATUS_UPDATED: (db: IDatabase) => ({
    title: (
      <span>
        <b>{db.name}</b> {db.isEnabled ? 'is now accessible' : 'access restricted'}.
      </span>
    ),
  }),
}

export const USERS = {
  GENERAL_API_ERROR: (msg?: string): Partial<IToast> => ({
    title: API_ERRORS.USERS.GENERAL.TITLE,
    subtitle: API_ERRORS.USERS.GENERAL.SUBTITLE(msg),
    type: 'error',
  }),
  BULK_UPDATED: (usersCount: number) => ({
    title: (
      <span>
        <b>{usersCount}</b> users were updated.
      </span>
    ),
  }),
  BULK_DELETED: (usersCount: number) => ({
    title: (
      <span>
        <b>{usersCount}</b> users were deleted.
      </span>
    ),
  }),
  BULK_CREATED: (usersCount: number) => ({
    title: (
      <span>
        <b>{usersCount}</b> users were created.
      </span>
    ),
  }),
}
