import * as React from 'react'

import { bindBem } from 'bem'
import { Options } from 'components/Sidebar/Options'
import { ADMIN } from 'messages'
import { Button } from 'components/Button'

import close from '../../static/close-dialog.svg'

import './DeleteMenuModal.scss'

export interface IProps {
  deleteMenu: (name: string) => void
  onClose: () => void
  replacementMenus: IMenuLayout[]
}

export const DeleteMenuModal: React.FC<IProps> = ({
  deleteMenu,
  onClose,
  replacementMenus,
}) => {
  const { block, element } = bindBem('DeleteMenuModal')

  const options = React.useMemo(
    () =>
      replacementMenus.map(menu => ({
        name: menu.label,
        value: menu.name,
      })),
    [replacementMenus]
  )

  const replacementMenusAvailable = React.useMemo(
    () => replacementMenus.length > 0,
    [replacementMenus]
  )

  const [isSaving, setSaving] = React.useState(false)
  const [selectedOption, setSelectedOption] = React.useState(null)

  const onSubmit = () => {
    setSaving(true)
    deleteMenu(selectedOption.value)
  }

  return (
    <div className={block()}>
      <img className={element('Close')} src={close} alt="close icon" onClick={onClose} />
      <div className={element('Title')}>{ADMIN.MENUS.MODALS.DELETE.TITLE}</div>
      <div className={element('Content')}>
        <div className={element('Info')}>
          {replacementMenusAvailable
            ? ADMIN.MENUS.MODALS.DELETE.INFO
            : ADMIN.MENUS.MODALS.DELETE.NO_REPLACEMENT_INFO}
        </div>
        {replacementMenusAvailable && (
          <div className={element('Options')}>
            <Options
              options={options}
              selectedOption={options.indexOf(selectedOption) || 0}
              disabled={isSaving}
              onSelect={setSelectedOption}
            />
          </div>
        )}
      </div>
      {replacementMenusAvailable && (
        <div className={element('Bottom')}>
          <div>
            <Button
              className={element('Submit')}
              onClick={onSubmit}
              disabled={isSaving || !selectedOption}
              text={
                isSaving
                  ? ADMIN.MENUS.MODALS.DELETE.SAVING
                  : ADMIN.MENUS.MODALS.DELETE.SAVE
              }
              size="small"
              style="dark"
            />
          </div>
        </div>
      )}
    </div>
  )
}
