import { TDispatch } from '.'

import Sentry from '../sentry'
import { logoutWithToast, openToast } from './User'

export const errorHandler = (err: any) => {
  // Do nothing if canceled
  if (err.toString() === 'Cancel') {
    return
  }

  Sentry.captureException(err)

  if (err?.response?.status === 401) {
    const toast: Partial<IToast> = {
      type: 'error',
      title: 'You need to login again',
      subtitle: 'In a moment you will be redirected to login screen.',
    }
    return (dispatch: TDispatch) => {
      dispatch(logoutWithToast(toast))
    }
  }

  if (err?.response?.status === 403) {
    const toast: Partial<IToast> = {
      type: 'error',
      title: 'Access denied',
      subtitle: 'You do not have enough permissions to access requested resource.',
    }
    return (dispatch: TDispatch) => {
      dispatch(openToast(toast))
    }
  }

  // re-raise
  throw err
}
