// eslint-disable max-len

import * as React from 'react'

interface IProps {
  color: string
}

const AddIcon = ({ color }: IProps) => (
  <svg width="20" height="18" viewBox="0 0 20 18">
    <path fill={color} fillRule="evenodd" d="M9 8V4h2v4h4v2h-4v4H9v-4H5V8h4z" />
  </svg>
)

const SubtractIcon = ({ color }: IProps) => (
  <svg width="20" height="18" viewBox="0 0 20 18">
    <path fill={color} fillRule="evenodd" d="M4 8h12v2H4z" />
  </svg>
)

const MultiplyIcon = ({ color }: IProps) => (
  <svg width="20" height="18" viewBox="0 0 20 18">
    <path
      fill={color}
      fillRule="evenodd"
      d="M10 7.586l2.828-2.829 1.415 1.415L11.414 9l2.829 2.828-1.415 1.415L10 10.414l-2.828 2.829-1.415-1.415L8.586 9 5.757 6.172l1.415-1.415L10 7.586z"
    />
  </svg>
)

const DivideIcon = ({ color }: IProps) => (
  <svg width="20" height="18" viewBox="0 0 20 18">
    <path fill={color} fillRule="evenodd" d="M5 12.485L13.485 4 14.9 5.414 6.414 13.9z" />
  </svg>
)

const PercentIcon = ({ color }: IProps) => (
  <svg width="20" height="18" viewBox="0 0 20 18">
    <path
      fill={color}
      fillRule="evenodd"
      d="M5 12.485L13.485 4 14.9 5.414 6.414 13.9 5 12.485zM7 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm6 8a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
    />
  </svg>
)

const CheckIcon = ({ color }: IProps) => (
  <svg width="20" height="18" viewBox="0 0 20 18">
    <path
      fill={color}
      fillRule="evenodd"
      d="M8.243 11.364L14.607 5l1.414 1.414-7.778 7.778L4 9.95l1.414-1.414z"
    />
  </svg>
)

const CrossIcon = ({ color }: IProps) => (
  <svg width="20" height="18" viewBox="0 0 20 18">
    <path
      fill={color}
      fillRule="evenodd"
      d="M11.718 9.303l3.889 3.89-1.415 1.414-3.889-3.89-3.889 3.89L5 13.192l3.89-3.889L5 5.414 6.414 4l3.89 3.89L14.191 4l1.415 1.414z"
    />
  </svg>
)

const RevertIcon = ({ color }: IProps) => (
  <svg width="20" height="18" viewBox="0 0 20 18">
    <g fill={color} fillRule="evenodd">
      <path
        fillRule="nonzero"
        d="M7.23 13.981A4.85 4.85 0 1 0 10 5.15a.85.85 0 1 0 0 1.7 3.15 3.15 0 1 1-1.799 5.736.85.85 0 1 0-.972 1.395z"
      />
      <path d="M10 2v8L6 6z" />
    </g>
  </svg>
)

const SwapIcon = ({ color }: IProps) => (
  <svg width="20" height="18" viewBox="0 0 20 18">
    <g fill="none" fillRule="evenodd">
      <path fill={color} d="M9 2v8L5 6z" />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.7"
        d="M9 6h6a3 3 0 0 1 3 3v.505"
      />
      <g>
        <path fill={color} d="M12 17V9l4 4z" />
        <path
          stroke={color}
          strokeLinecap="round"
          strokeWidth="1.7"
          d="M12 13H6a3 3 0 0 1-3-3v-.505"
        />
      </g>
    </g>
  </svg>
)

export const ADD_ICON = <AddIcon key="add" color="#788596" />
export const ADD_ICON_LIGHT = <AddIcon key="add-light" color="#FFFFFF" />
export const SUBTRACT_ICON = <SubtractIcon key="subtract" color="#788596" />
export const SUBTRACT_ICON_LIGHT = <SubtractIcon key="subtract-light" color="#FFFFFF" />
export const MULTIPLY_ICON = <MultiplyIcon key="multiply" color="#788596" />
export const MULTIPLY_ICON_LIGHT = <MultiplyIcon key="multiply-light" color="#FFFFFF" />
export const DIVIDE_ICON = <DivideIcon key="divide" color="#788596" />
export const DIVIDE_ICON_LIGHT = <DivideIcon key="divide-light" color="#FFFFFF" />
export const PERCENT_ICON = <PercentIcon key="percent" color="#788596" />
export const PERCENT_ICON_LIGHT = <PercentIcon key="percent-light" color="#FFFFFF" />
export const CHECK_ICON = <CheckIcon color="#788596" />
export const CROSS_ICON = <CrossIcon color="#788596" />
export const REVERT_ICON = <RevertIcon color="#788596" />
export const REVERT_ICON_LIGHT = <RevertIcon color="#FFFFFF" />
export const SWAP_ICON = <SwapIcon color="#788596" />

export const ADD_ICON_BLUE = <AddIcon color="#3fa2db" />
export const SUBTRACT_ICON_BLUE = <SubtractIcon color="#3fa2db" />
export const MULTIPLY_ICON_BLUE = <MultiplyIcon color="#3fa2db" />
export const DIVIDE_ICON_BLUE = <DivideIcon color="#3fa2db" />
export const PERCENT_ICON_BLUE = <PercentIcon color="#3fa2db" />
