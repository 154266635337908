import React from 'react'

export const useScrollAnywhere = (enabled: boolean, callback: () => void) => {
  const handleScroll = React.useCallback(() => {
    callback()
  }, [callback])
  React.useEffect(() => {
    if (enabled) {
      document.addEventListener('scroll', handleScroll, true)
      return () => {
        document.removeEventListener('scroll', handleScroll, true)
      }
    }
  }, [enabled])
}
