import * as React from 'react'

import { bindBem } from 'bem'
import { ADMIN } from 'messages'
import { IconOption } from 'containers/MenusAdmin/Options/IconOption'

import BinIcon from 'static/red-bin.svg'

import './DatabaseOptions.scss'

export interface IProps {
  deleteDatabase: () => void
}

export const DatabaseOptions: React.FC<IProps> = ({ deleteDatabase }) => {
  const { block } = bindBem('DatabaseOptions')

  return (
    <div className={block()}>
      <IconOption
        icon={BinIcon}
        onClick={deleteDatabase}
        tooltip={ADMIN.MENUS.DB_OPTIONS.DELETE_DATABASE}
      />
    </div>
  )
}
