// eslint-disable max-classes-per-file
import * as React from 'react'

import { bindBem } from '../bem'

import validationError from '../static/validation-error.svg'

import './Input.scss'

export interface IPrimaryInputProps {
  value: string
  name: string
  onChange?: React.FormEventHandler<HTMLInputElement>
  onBlur?: React.FormEventHandler<HTMLInputElement>
  onFocus?: React.FormEventHandler<HTMLInputElement>
  error?: boolean
  label?: string
  type?: 'text' | 'password' | 'number'
  focus?: boolean
  inputMode?: 'decimal' | 'numeric' | 'text' | 'search' | 'email' | 'none'
  pattern?: string
}

export class PrimaryInput extends React.Component<IPrimaryInputProps> {
  ref: HTMLInputElement

  componentDidMount() {
    if (this.ref && this.props.focus) {
      this.ref.focus()
    }
  }

  render() {
    const {
      type = 'text',
      error,
      value,
      label,
      onChange,
      onBlur,
      onFocus,
      name,
      inputMode = 'text',
      pattern,
    } = this.props
    const { block, element } = bindBem('PrimaryInput')

    return (
      <div className={block({ error, hasValue: !!value })}>
        {error && <img className={element('ErrorIcon')} src={validationError} />}
        <input
          pattern={pattern}
          inputMode={inputMode}
          ref={ref => (this.ref = ref)}
          name={name}
          type={type}
          className={element('InnerInput')}
          value={value || ''}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        {label && <label className={element('Label')}>{label}</label>}
      </div>
    )
  }
}

export interface IInputProps {
  value: number | string
  prefix?: React.ReactNode | JSX.Element
  postfix?: React.ReactNode
  error?: boolean | string
  focus?: boolean
}

export type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'prefix'> &
  IInputProps

export class Input extends React.Component<Props> {
  ref: HTMLInputElement

  componentDidMount() {
    if (this.ref && this.props.focus) {
      this.ref.focus()
    }
  }

  render() {
    const {
      value,
      error,
      type,
      prefix,
      postfix,
      disabled,
      focus,
      placeholder,
      ...props
    } = this.props
    const inputType = type || 'text'
    const { block, element } = bindBem('Input')

    return (
      <div
        className={block({
          error: !!error,
          withPrefix: !!prefix,
          withPostfix: !!postfix,
          disabled,
        })}
      >
        {prefix && <div className={element('Prefix')}>{prefix}</div>}
        <input
          ref={ref => (this.ref = ref)}
          className={element('InnerInput')}
          type={inputType}
          value={value ?? ''}
          autoComplete="off"
          placeholder={disabled ? '' : placeholder}
          disabled={disabled}
          {...props}
        />
        {postfix && <div className={element('Postfix')}>{postfix}</div>}
        {error && typeof error === 'string' && (
          <span className={element('Error')}>{error}</span>
        )}
      </div>
    )
  }
}

export const Labeled: React.FunctionComponent<{
  label: string
  error?: string
}> = props => {
  const { block, element } = bindBem('Labeled')

  return (
    <div className={block()}>
      {props.error && <p className={element('Error')}>{props.error}</p>}
      <div className={element('Input')}>
        <div className={element('Label')}>{props.label}</div>
        {props.children}
      </div>
    </div>
  )
}

export const VerticalLabeled: React.FunctionComponent<{
  label: string
  error?: string
}> = props => {
  const { block, element } = bindBem('VerticalLabeled')

  return (
    <div className={block()}>
      <div className={element('Input')}>
        <div className={element('Label')}>{props.label}</div>
        {props.children}
      </div>
      {props.error && <p className={element('Error')}>{props.error}</p>}
    </div>
  )
}

type TextAreaProps = Omit<React.TextareaHTMLAttributes<HTMLElement>, 'prefix'> &
  IInputProps

export class TextArea extends React.Component<TextAreaProps> {
  ref: HTMLTextAreaElement

  componentDidMount() {
    if (this.ref && this.props.focus) {
      this.ref.focus()
    }
  }

  render() {
    const { value, error, prefix, postfix, disabled, placeholder, ...props } = this.props
    const { block, element } = bindBem('TextArea')

    return (
      <div
        className={block({
          error,
          withPrefix: !!prefix,
          withPostfix: !!postfix,
          disabled,
        })}
      >
        {prefix && <div className={element('Prefix')}>{prefix}</div>}
        <textarea
          ref={ref => (this.ref = ref)}
          className={element('InnerInput')}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          disabled={disabled}
          {...props}
        />
        {postfix && <div className={element('Postfix')}>{postfix}</div>}
      </div>
    )
  }
}
