import React from 'react'
import { IFormProps, InnerForm, Variants } from './AdvancedFunctionUtils'
import { Formik } from 'formik'
import { Input } from '../Input'
import { bindBem } from '../../bem'
import { AutoSave } from './AutoSave'
import { ADVANCED_FUNCTIONS, FUNCTIONS_DIALOG } from '../../messages'
import { Freq, isWeekly } from '../../services/series'
import RadioButton from '../RadioButton'
import { RadioGroup } from '../RadioGroup'
import { Switch } from './Switch'
import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  value: Yup.number().min(1).max(200).required(),
})

export const FnChangeForm = (props: IFormProps) => {
  const { state, setForm, setFormValidation, isNested } = props
  const { centered, baseFunc } = state
  const f = state.func
  const value = state.args[0] as number
  const { block, element } = bindBem('FnChangeForm')
  const initialValues = { value: value !== null ? value : '' }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => setForm({ ...state, args: [values.value] })}
      validationSchema={validationSchema}
      isInitialValid={true}
    >
      {({ errors, values, submitForm, isValid, handleChange }) => (
        <InnerForm {...props} className={block()} onSave={submitForm} isValid={isValid}>
          <div className="row">
            <Input
              value={values.value}
              onChange={handleChange}
              type="number"
              name="value"
              error={errors.value && !!errors.value}
            />
            <div className={element('RightLabel')}>
              {isWeekly(props.frequency)
                ? ADVANCED_FUNCTIONS.PERIODS[Freq.Mon]
                : ADVANCED_FUNCTIONS.PERIODS[props.frequency]}
            </div>
          </div>
          {errors.value && (
            <div className="row">
              <div className={element('Error')}>{errors.value}</div>
            </div>
          )}

          <RadioGroup
            value={f}
            onChange={(func: AdvancedFunction) => setForm({ ...state, func })}
          >
            {Variants[baseFunc].map(o => (
              <RadioButton key={o} value={o}>
                {ADVANCED_FUNCTIONS.OPTIONS[o]}
              </RadioButton>
            ))}
          </RadioGroup>
          <Switch
            onChange={flag => setForm({ ...state, centered: flag })}
            value={centered}
            title=""
          >
            {FUNCTIONS_DIALOG.CENTERED}
          </Switch>

          {!isNested && (
            <AutoSave
              onSubmit={submitForm}
              values={values}
              isValid={isValid}
              callback={() => setFormValidation(isValid)}
            />
          )}
        </InnerForm>
      )}
    </Formik>
  )
}
