import * as Sentry from '@sentry/react'
import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect, match } from 'react-router-dom'

import { Desktop, Mobile } from '../../components/Responsive'
import { withSize } from '../../components/SizeHOC'
import { IRootState } from '../../store'
import { ACTIONS, loadSeriesFromLink, updateTimeSpanFunctions } from '../../store/Series'
import { removeInterpolation, removeTransformation } from '../../store/Transformations'
import { DesktopSeriesContainer } from './Desktop'
import Loader from './Loader'
import { MobileSeriesContainer } from './Mobile'
import { IActionProps, IProps, withResize } from './common'
import * as selectors from './selectors'

const mapStateToProps = (state: IRootState, { size }: IProps): Omit<IProps, 'size'> => {
  const {
    variables,
    recessionsByCountry,
    seriesSettings,
    scatterSettings,
    scale,
    correlation,
    activeModal,
  } = state.series
  const { recessionType, recessionCountry, isLegendShown } = seriesSettings
  const selectorState: selectors.State = {
    variables,
    settings: seriesSettings,
    width: size.width,
    scale,
  }
  const [startZoomDate, endZoomDate] = selectors.zoomSelector(selectorState)
  const scaleSelector = selectors.isGlobalScale(scale.type)
    ? selectors.globalScaleSelector
    : selectors.localScaleSelector

  return {
    variables: selectors.zoomedSeriesSelector(selectorState),
    offsetSeries: selectors.offsetSeriesSelector(selectorState),
    startZoomDate,
    endZoomDate,
    settings: seriesSettings,
    recessions: recessionsByCountry[recessionCountry],
    recessionType,
    isEditingTitles: state.series.activeModal === 'editTitles',
    isLegendShown,
    isLoading: variables.length === 0 && state.series.loadingSeriesId !== null,
    scatterSettings,
    scale: variables.length ? scaleSelector(selectorState) : null,
    stackedConfig: selectors.stackedConfigSelector(selectorState),
    correlation,
    stats: selectors.statsSelector(selectorState),
    activeModal,
    trendlineBoundaries: state.series.trendlineBoundaries,
  }
}

const mapDispatchToProps = (dispatch: any): IActionProps => ({
  onEditTitles: (title: string, titles: string[], isLegendShown: boolean) =>
    dispatch(ACTIONS.setSeriesTitles({ title, titles, isLegendShown })),
  setNamesModal: (isOpen: boolean) =>
    dispatch(ACTIONS.setActiveModal(isOpen ? 'editTitles' : null)),
  removeInterpolation: (series: IDataSeries, index: number) =>
    dispatch(removeInterpolation(series, index)),
  removeTransformation: (series: IDataSeries, index: number) =>
    dispatch(removeTransformation(series, index)),
  scrollByPeriod: (period: number) => {
    if (Math.abs(period) === 0) {
      return
    }
    dispatch(ACTIONS.scrollByPeriod(period))
    dispatch(updateTimeSpanFunctions())
  },
  setModal: modal => dispatch(ACTIONS.setActiveModal(modal)),
})

const DesktopContainer = withSize(
  withResize(connect(mapStateToProps, mapDispatchToProps)(DesktopSeriesContainer))
)

const MobileContainer = withSize(
  withResize(connect(mapStateToProps, mapDispatchToProps)(MobileSeriesContainer), true)
)

interface ISeriesContainerProps {
  match: match<{ id: string }>
  variables: IDataSeries[]
  link?: ILink
  isLoading: boolean
}

interface ISeriesContainerActionProps {
  loadSeriesFromLink: (id: string) => void
}

type Props = ISeriesContainerProps & ISeriesContainerActionProps

class SeriesContainer extends React.Component<Props> {
  isSharedLink = false

  constructor(props: Props) {
    super(props)
    this.isSharedLink = !!props.match?.params.id
  }

  componentDidMount = () => {
    if (this.isSharedLink && !this.props.link) {
      this.props.loadSeriesFromLink(this.props.match.params.id)
    }
  }

  render() {
    const { variables, link, isLoading } = this.props
    if (!variables.length) {
      return (this.isSharedLink && !link) || isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <Redirect to="/" />
      )
    }

    return (
      <>
        <Loader loading={isLoading}>
          <Desktop>
            <DesktopContainer />
          </Desktop>
          <Mobile>
            <MobileContainer />
          </Mobile>
        </Loader>
      </>
    )
  }
}

const mapStateToSeriesContainerProps = (
  state: IRootState
): Omit<ISeriesContainerProps, 'match'> => ({
  variables: state.series.variables,
  link: state.series.link,
  isLoading: !!state.series.loadingSeriesId,
})

const mapDispatchToSeriesContainerProps = (
  dispatch: any
): ISeriesContainerActionProps => ({
  loadSeriesFromLink: (id: string) => dispatch(loadSeriesFromLink(id)),
})

export default Sentry.withProfiler(
  connect(
    mapStateToSeriesContainerProps,
    mapDispatchToSeriesContainerProps
  )(SeriesContainer)
)
