export const isSuperAdmin = (role: Role) => role === 'admin'
export const isAccountAdmin = (role: Role) => role === 'account_admin'
export const isOrgAdmin = (role: Role) => role === 'org_admin'

export const isHaverAdmin = (role: Role) => isSuperAdmin(role) || isAccountAdmin(role)
export const isAdmin = (role: Role) =>
  isSuperAdmin(role) || isAccountAdmin(role) || isOrgAdmin(role)

export const getUserId = () => localStorage.getItem('userId')

export const clearUserData = () => {
  localStorage.removeItem('userId')
  localStorage.removeItem('sessionToken')
}

export const getResizeBarPositionKey = () => {
  const userId = getUserId()
  return userId ? `resizeBarPosition-${userId}` : null
}
export const getResizeBarPosition = () => {
  const resizeBarPositionKey = getResizeBarPositionKey()
  return resizeBarPositionKey
    ? parseInt(localStorage.getItem(resizeBarPositionKey), 10) || 0
    : 0
}

export const setResizeBarPosition = (yPosition: string) => {
  const resizeBarPositionKey = getResizeBarPositionKey()
  if (resizeBarPositionKey) {
    localStorage.setItem(resizeBarPositionKey, yPosition)
  }
}

export const getDragAndDropTooltipKey = () => {
  const userId = getUserId()
  return userId ? `dragAndDropTooltip-${userId}` : null
}

export const isDragAndDropTooltipVisible = () => {
  const key = getDragAndDropTooltipKey()
  return localStorage.getItem(key) !== '1'
}

export const hideDragAndDropTooltip = () => {
  const key = getDragAndDropTooltipKey()
  if (key) {
    localStorage.setItem(key, '1')
  }
}
