import * as Sentry from '@sentry/react'
import {
  ENV,
  IS_PRODUCTION,
  IS_LOCAL,
  VERSION,
  SENTRY_DSN,
  SENTRY_TRACES_SAMPLE_RATE,
  SENTRY_REPLAYS_SAMPLE_RATE,
} from './config'
import { history } from './store'

export const init = () => {
  if (!SENTRY_DSN || IS_LOCAL) {
    return
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENV,
    release: VERSION,
    debug: !IS_PRODUCTION,

    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,

    // You may want this to be 100% while in development and sample
    // at a lower rate in production.
    replaysSessionSampleRate: SENTRY_REPLAYS_SAMPLE_RATE,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    ignoreErrors: ['CanceledError'],

    integrations: [
      new Sentry.BrowserTracing({
        tracingOrigins: ['api.haverview.com'],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
    ],
  })
}

export const setUserScope = (user?: IUser) => {
  Sentry.configureScope(scope => {
    if (user) {
      scope.setUser({
        id: user.id.toString(),
        email: user.email,
        username: user.username,
      })
      scope.setTag('role', user.role)
    } else {
      scope.setUser(null)
      scope.setTag('role', undefined)
    }
  })
}

export default Sentry
