// eslint-disable max-len
// eslint-disable max-lines

import * as React from 'react'

interface IProps {
  color: string
}

interface IShadedProps extends IProps {
  lineColor: string
}

interface IShadedStackedProps {
  bgColor: string
  lineColor: string
  altLineColor: string
}

const LineIcon = ({ color }: IProps) => (
  <svg width="20" height="18" viewBox="0 0 20 18">
    <path
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeWidth="2"
      d="M2.72 14.69l4.26-5.517 3.826 3.076 6.509-6.836"
    />
  </svg>
)

export const ICON_LINE = <LineIcon key="line" color="#788596" />
export const ICON_LINE_LIGHT = <LineIcon key="line-light" color="#FFFFFF" />

const BarIcon = ({ color }: IProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
    <path
      fill={color}
      fillRule="evenodd"
      d="M5 8h3v5H5V8zm4.036-3h3v8h-3V5zm4 5h3v3h-3v-3z"
    />
  </svg>
)

export const ICON_BAR = <BarIcon key="bar" color="#788596" />
export const ICON_BAR_LIGHT = <BarIcon key="bar-light" color="#FFFFFF" />

const ShadedBarIcon = ({ color, lineColor }: IShadedProps) => {
  const d =
    'M14 4.589V6.71l-7.29 7.289H6V12.59zM14 8.589v2.12L10.71 14H8.589zM12.71 4L6 10.71V8.588L10.588 4zM8.31 4L6 6.309V4.188L6.189 4z'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
      <g fill="none" fillRule="evenodd">
        <path fill={color} d="M6 4h8v10H6z" />
        <path fill={lineColor} d={d} />
      </g>
    </svg>
  )
}

export const ICON_SHADED_BAR = (
  <ShadedBarIcon key="shaded-bar" color="#788596" lineColor="#C0C6CD" />
)
export const ICON_SHADED_BAR_ACTIVE = (
  <ShadedBarIcon key="shaded-bar-active" color="#FFFFFF" lineColor="#C0C6CD" />
)
export const ICON_SHADED_BAR_LIGHT = (
  <ShadedBarIcon key="shaded-bar-light" color="#FFFFFF" lineColor="#66B0DB" />
)

const StepLineIcon = ({ color }: IProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
    <path
      fill={color}
      fillRule="nonzero"
      d="M5 10.304h7.736V7h4.567V5h-6.567v3.304H3v4.69h2z"
    />
  </svg>
)

export const ICON_STEPLINE = <StepLineIcon key="stepline" color="#788596" />
export const ICON_STEPLINE_LIGHT = <StepLineIcon key="stepline-light" color="#FFFFFF" />

const AreaIcon = ({ color }: IProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
    <g fill={color} fillRule="evenodd">
      <path d="M2 11.67l5.045-4.91 3.826 3.076 7.217-6.143v10.41H2z" opacity=".401" />
      <path
        fillRule="nonzero"
        d="M7.213 8.179l3.748 3.013 7.143-7.502-1.449-1.38-5.874 6.17-3.904-3.138-4.884 6.324 1.583 1.222z"
      />
    </g>
  </svg>
)

export const ICON_AREA = <AreaIcon key="area" color="#788596" />
export const ICON_AREA_LIGHT = <AreaIcon key="area-light" color="#FFFFFF" />

const DottedLineIcon = ({ color }: IProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
    <path
      fill={color}
      fillRule="evenodd"
      d="M5 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm2-2a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm2-2a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm2 2a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm2-2a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm2-2a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
    />
  </svg>
)

export const ICON_DOTTED_LINE = <DottedLineIcon key="area" color="#788596" />
export const ICON_DOTTED_LINE_LIGHT = <DottedLineIcon key="area-light" color="#FFFFFF" />

const DashedLineIcon = ({ color }: IProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
    <path
      fill={color}
      fillRule="evenodd"
      d="M4.707 10.414l.707-.707a1 1 0 0 1 1.414 1.414l-.707.707a1 1 0 0 1-1.414-1.414zm3-3l.707-.707a1 1 0 0 1 1.414 1.414l-.707.707a1 1 0 1 1-1.414-1.414zm4.414.293l.707.707a1 1 0 0 1-1.414 1.414l-.707-.707a1 1 0 0 1 1.414-1.414zm3.707-.586l-.707.707a1 1 0 1 1-1.414-1.414l.707-.707a1 1 0 1 1 1.414 1.414z"
    />
  </svg>
)

export const ICON_DASHED_LINE = <DashedLineIcon key="area" color="#788596" />
export const ICON_DASHED_LINE_LIGHT = <DashedLineIcon key="area-light" color="#FFFFFF" />

const StackedBarIcon = ({ color }: IProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
    <g fill={color} fillRule="evenodd">
      <path d="M5 8h4v5H5V8zm6-3h4v8h-4V5z" opacity=".401" />
      <path d="M5 7h4v3H5V7zm6-3h4v5h-4V4z" />
    </g>
  </svg>
)

export const ICON_STACKED_BAR = <StackedBarIcon key="stacked-bar" color="#788596" />
export const ICON_STACKED_BAR_LIGHT = (
  <StackedBarIcon key="stacked-bar-light" color="#FFFFFF" />
)

const ShadedStackedBarIcon = ({
  bgColor,
  lineColor,
  altLineColor,
}: IShadedStackedProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
    <g fill="none" fillRule="evenodd">
      <path fill={lineColor} d="M6 4h8v10H6z" />
      <path
        fill={bgColor}
        d="M14 4.589V6.71L6.71 14h-.711v-1.41zM14 8.589v2.121L10.71 14H8.587zM12.71 4l-6.711 6.711V8.59L10.588 4zM8.31 4L5.999 6.312V4.19L6.188 4z"
      />
      <path fill={altLineColor} d="M6 10h8v4H6z" />
      <path
        fill={bgColor}
        d="M6.708 10L6 10.71V10zM13.998 10l.001.71L10.71 14H8.59l3.998-4zM10.709 10l-4 4H6v-1.411L8.588 10z"
        opacity=".623"
      />
    </g>
  </svg>
)

export const ICON_SHADED_STACKED_BAR = (
  <ShadedStackedBarIcon
    key="shaded-stack-bar"
    bgColor="#BFC6CE"
    lineColor="#758598"
    altLineColor="#DDE1E4"
  />
)
export const ICON_SHADED_STACKED_BAR_LIGHT = (
  <ShadedStackedBarIcon
    key="shaded-stack-bar-light"
    bgColor="#FFFFFF"
    lineColor="#66B0DB"
    altLineColor="#007CC3"
  />
)
export const ICON_SHADED_STACKED_BAR_ACTIVE = (
  <ShadedStackedBarIcon
    key="shaded-stack-bar-light"
    bgColor="#FFFFFF"
    lineColor="#C0C6CD"
    altLineColor="#788596"
  />
)

const ScatterGraphIcon = ({ color }: IProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
    <g fill={color} fillRule="evenodd">
      <circle cx="5" cy="8" r="1" />
      <circle cx="8" cy="10" r="1" />
      <circle cx="9" cy="6" r="1" />
      <circle cx="12" cy="12" r="1" />
      <circle cx="13" cy="8" r="1" />
      <circle cx="8" cy="14" r="1" />
      <circle cx="14" cy="5" r="1" />
    </g>
  </svg>
)

export const ICON_SCATTER_GRAPH = <ScatterGraphIcon key="area" color="#788596" />
export const ICON_SCATTER_GRAPH_LIGHT = (
  <ScatterGraphIcon key="area-light" color="#FFFFFF" />
)
