import * as React from 'react'

import { SETTINGS_DROPDOWN } from '../messages'
import { bindBem } from '../bem'

import * as ICONS from '../components/SVG/AvatarMenuIcons'
import { Link } from 'react-router-dom'
import { isHaverAdmin, isOrgAdmin } from '../services/user'
import { ResponsiveContext } from './Responsive'

import './SettingsDropdown.scss'

interface IProps {
  role: Role
  onLogout: () => void
  isFooter?: boolean
  openChangePasswordModal: () => void
}

export const SettingsDropdown = (props: IProps) => {
  const { block, element } = bindBem('SettingsDropdown')
  const { role, onLogout, isFooter } = props
  return (
    <div className={block({ isFooter })}>
      <ResponsiveContext.Consumer>
        {ctx => {
          if (ctx.isMobile) {
            return null
          }
          if (isHaverAdmin(role)) {
            return (
              <Link className={element('Entry')} to="/admin/organizations">
                <div className={element('Img')}>{ICONS.ADMIN_ICON}</div>
                {SETTINGS_DROPDOWN.ADMIN_SETTINGS}
              </Link>
            )
          }
          if (isOrgAdmin(role)) {
            return (
              <Link className={element('Entry')} to="/admin">
                <div className={element('Img')}>{ICONS.ADMIN_ICON}</div>
                {SETTINGS_DROPDOWN.ADMIN_SETTINGS}
              </Link>
            )
          }
        }}
      </ResponsiveContext.Consumer>

      <div className={element('Entry')} onClick={onLogout}>
        <div className={element('Img')}>{ICONS.LOGOUT_ICON}</div>
        {SETTINGS_DROPDOWN.LOGOUT}
      </div>
    </div>
  )
}
