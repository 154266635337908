import * as React from 'react'
import { bindBem } from 'bem'

import { ADMIN } from 'messages'
import { IconSubMenuOption } from 'containers/MenusAdmin/Options/IconSubMenuOption'
import { SubMenuItem, Theme } from 'containers/MenusAdmin/Options/SubMenuItem'
import { IconOption } from 'containers/MenusAdmin/Options/IconOption'

import ThreeDotIcon from 'static/three-dots-menu.svg'
import PencilIcon from 'static/pencil.svg'

import './CategoryOptions.scss'

export enum Options {
  ADD_NEW_GROUP = 'add-new-group',
  DELETE_CATEGORY = 'delete-category',
}

export interface IProps {
  addNewGroup: () => void
  editCategoryName: () => void
  deleteCategory: () => void
}

export const CategoryOptions: React.FC<IProps> = ({
  addNewGroup,
  editCategoryName,
  deleteCategory,
}) => {
  const { block } = bindBem('CategoryOptions')

  const handleClick = React.useCallback(
    (name: Options) => {
      switch (name) {
        case Options.ADD_NEW_GROUP:
          addNewGroup()
          break
        case Options.DELETE_CATEGORY:
          deleteCategory()
          break
      }
    },
    [addNewGroup, deleteCategory]
  )

  return (
    <div className={block()}>
      <IconOption
        icon={PencilIcon}
        onClick={editCategoryName}
        tooltip={ADMIN.MENUS.CATEGORY_OPTIONS.EDIT_NAME_TOOLTIP}
      />
      <IconSubMenuOption
        icon={ThreeDotIcon}
        tooltip={ADMIN.MENUS.CATEGORY_OPTIONS.MORE_OPTIONS_TOOLTIP}
        onClick={handleClick}
      >
        <SubMenuItem
          text={ADMIN.MENUS.CATEGORY_OPTIONS.ADD_NEW_GROUP}
          name={Options.ADD_NEW_GROUP}
        />
        <SubMenuItem
          text={ADMIN.MENUS.CATEGORY_OPTIONS.DELETE_CATEGORY}
          name={Options.DELETE_CATEGORY}
          theme={Theme.DANGER}
          disabledTooltip={ADMIN.MENUS.CATEGORY_OPTIONS.DELETE_CATEGORY_DISABLED_TOOLTIP}
        />
      </IconSubMenuOption>
    </div>
  )
}
