import * as React from 'react'
import { BigSwitch } from './../BigSwitch'
import { Options } from '../../Sidebar/Options'
import { Labeled, Input } from '../../Input'
import { USER_FORM } from '../../../messages'

export const UserFormSuperAdmin = (formik: any, element: (name: string) => string) => (
  <>
    <div className={element('APIStatus')}>
      <Labeled label={USER_FORM.API_STATUS}>
        <BigSwitch
          value={formik.values.settings.apiAccess}
          onChange={value => formik.setFieldValue('settings.apiAccess', value)}
        />
      </Labeled>
    </div>
    <div className={element('Access')}>
      <Labeled label={USER_FORM.ACCESS}>
        <Options
          options={USER_FORM.ACCESS_TYPES}
          selectedOption={Number(formik.values.settings.isRestricted)}
          setSeriesOptions={value => formik.setFieldValue('settings.isRestricted', value)}
        />
      </Labeled>
    </div>
    <Labeled label={USER_FORM.IP} error={formik.errors.settings?.restrictedIp}>
      <Input
        name="settings.restrictedIp"
        disabled={!formik.values.settings.isRestricted}
        onChange={formik.handleChange}
        onBlur={() => formik.setFieldTouched('settings.isRestricted')}
        value={formik.values.settings.restrictedIp}
        error={!!formik.errors.settings?.restrictedIp}
        placeholder={formik.values.settings.isRestricted ? USER_FORM.PLACEHOLDER.IP : ''}
      />
    </Labeled>
    <Labeled label={USER_FORM.RATELIMIT} error={formik.errors.settings?.ratelimit}>
      <Input
        name="settings.ratelimit"
        onChange={formik.handleChange}
        value={formik.values.settings.ratelimit}
        error={!!formik.errors.settings?.ratelimit}
        placeholder={USER_FORM.PLACEHOLDER.RATELIMIT}
      />
    </Labeled>
  </>
)
