import * as React from 'react'

import { HelpTooltip } from './../HelpTooltip'

import './MfaStatusTooltip.scss'

export const MfaStatusTooltip: React.FunctionComponent<{ id: string }> = ({ id }) => (
  <HelpTooltip className="MFA-Status-Tooltip" id={id}>
    <ol>
      <li>Users with a restricted IP address are not required to use 2FA.</li>
      <li>
        Users with API status OFF are obliged to authenticate the account every Monday.
      </li>
      <li>
        Users with API status ON are obliged to authenticate the account on the 1st of
        every month.
      </li>
    </ol>
  </HelpTooltip>
)
