import * as d3 from 'd3'
import * as React from 'react'
import * as FauxDOM from 'react-faux-dom'

import { XScale, YScale, extrapolateBoundaries, renderLine } from '../../services/hd3'
import { COLORS } from '../../services/series'

export interface IProps {
  series: IDataSeries
  xScale: XScale
  yScale: YScale
  hasLineShadow: boolean
  isSplineLine: boolean
  style: LineType
  trendlineBoundaries?: Date[]
}

const Line = ({
  series,
  xScale,
  yScale,
  hasLineShadow,
  isSplineLine,
  style,
  trendlineBoundaries,
}: IProps) => {
  const svg = FauxDOM.createElement('g')
  const selection = d3.select(svg)
  let curveFactory: d3.CurveFactory = isSplineLine && d3.curveCatmullRom

  if (style === 'STEPLINE') {
    curveFactory = d3.curveStepAfter
  }
  let richDataPoints: RichDataPoint[] = React.useMemo(
    () =>
      series.dataPoints.map(p => ({
        ...p,
        x: xScale(p.date),
        y: yScale(p.value),
      })),
    [series, xScale, yScale, trendlineBoundaries]
  )
  if (trendlineBoundaries !== undefined) {
    const chartWidth = xScale.range()[1]
    richDataPoints = extrapolateBoundaries(
      richDataPoints,
      trendlineBoundaries,
      chartWidth
    )
  }
  const res = renderLine(
    selection,
    series,
    COLORS[series.colorIndex],
    hasLineShadow,
    richDataPoints,
    curveFactory
  )

  if (style === 'DASHEDLINE') {
    res.style('stroke-dasharray', '8, 6')
  } else if (style === 'DOTTEDLINE') {
    res.style('stroke-dasharray', '1, 6').attr('stroke-width', 2.5)
  }

  return <>{React.Children.map(svg.toReact(), c => c.props.children)}</>
}

export default React.memo(Line)
