// eslint-disable max-len

import * as React from 'react'

const CloseIcon = ({ color }: any) => (
  <svg width="50" height="50" viewBox="0 0 50 50">
    <g fill="none" fillRule="evenodd">
      <rect width="50" height="50" rx="2" />
      <path
        fill={color}
        d="M24.835 24.268l5.454-5.453 1.35 1.35-5.454 5.453 5.454 5.453-1.35 1.35-5.454-5.453-5.453 5.453-1.35-1.35 5.453-5.453-5.453-5.453 1.35-1.35 5.453 5.453z"
      />
    </g>
  </svg>
)

const CloseIconSm = ({ color }: any) => (
  <svg width="10" height="10" viewBox="0 0 10 10">
    <path
      fill={color}
      fillRule="nonzero"
      d="M3.522 3L3 3.522l.263.26L4.478 5 3.263 6.218 3 6.478 3.522 7l.26-.263L5 5.522l1.218 1.215.26.263L7 6.478l-.263-.26L5.522 5l1.215-1.218.263-.26L6.478 3l-.26.263L5 4.478 3.782 3.263z"
    />
  </svg>
)

const ClearInput = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path
      fill="#D8D8D8"
      fillRule="evenodd"
      d="M8.536 6.121l2.828-2.828a1 1 0 0 1 1.414 1.414L9.95 7.536l2.828 2.828a1 1 0 0 1-1.414 1.414L8.536 8.95l-2.829 2.828a1 1 0 1 1-1.414-1.414L7.12 7.536 4.293 4.707a1 1 0 0 1 1.414-1.414L8.536 6.12z"
    />
  </svg>
)

export const CLOSE = <CloseIcon color="#FFFFFF" />
export const CLOSE_SM = <CloseIconSm color="#B6C2D4" />
export const CLEAR_INPUT = <ClearInput />
