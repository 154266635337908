import { MARGINS } from '../../../constants'

export const GLOBAL_SCALES: IScale['type'][] = ['global', 'both']

export const isGlobalScale = (scaleType: IScale['type']) =>
  GLOBAL_SCALES.includes(scaleType)

export type State = {
  variables: IDataSeries[]
  settings: ISeriesSettings
  width: number
  scale: IScale
}

export const getSeries = (state: State) => state.variables

export const getSettings = (state: State) => state.settings
export const getWidth = (state: State) => state.width - MARGINS.left - MARGINS.right
export const getScale = (state: State) => state.scale
export const getGraphTypes = (state: State) => state.settings.graphTypes
