import * as d3 from 'd3'
import * as React from 'react'
import * as FauxDOM from 'react-faux-dom'

import { ScatterScale, renderAxisStyles } from '../../services/hd3'
import * as scatter from '../../services/hd3-utils/scatter-plot'

interface IProps {
  scale: ScatterScale
  height: number
}

const ScatterXAxis = (props: IProps) => {
  const svg = FauxDOM.createElement('g')
  const selection = d3.select(svg)
  scatter.renderXAxis(selection, props.scale, props.height)
  renderAxisStyles(selection)

  return <>{React.Children.map(svg.toReact(), c => c.props.children)}</>
}

export default React.memo(ScatterXAxis, (prevProps, props) => {
  const KEYS = Object.keys(props).filter(key => key !== 'scale') as keyof IProps[]
  if (!KEYS.every(k => prevProps[k] === props[k])) {
    return false
  }
  const [oldDomain, domain] = [prevProps.scale.domain(), props.scale.domain()]
  const [oldRange, range] = [prevProps.scale.range(), props.scale.range()]

  return (
    oldDomain.every((v, i) => v === domain[i]) && oldRange.every((v, i) => v === range[i])
  )
})
