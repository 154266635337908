import * as React from 'react'
import Select, { GroupBase, Props } from 'react-select'
import { bindBem } from 'bem'

import './Dropdown.scss'

export interface Option {
  value: string
  label: string
}

export interface IProps<
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> extends Omit<Props<Option, IsMulti, Group>, 'value' | 'onChange'> {
  value: string
  onChange: (value: string) => void
  options: Option[]
  error?: boolean | string
}

export const Dropdown: React.FC<IProps> = ({
  name,
  value,
  onChange,
  options,
  error,
  ...otherProps
}) => {
  const { block, element } = bindBem('Dropdown')
  const optionValue = React.useMemo(
    () => options.find(option => option.value === value),
    [value, options]
  )
  const selectChange = React.useCallback(
    (option: Option) => onChange(option.value),
    [onChange]
  )
  const styles = React.useMemo(
    () =>
      error
        ? {
            control: (provided: any) => ({
              ...provided,
              border: 'solid 1px #dd3d71',
            }),
          }
        : {},
    [error]
  )

  return (
    <div className={block()}>
      <Select
        name={name}
        value={optionValue}
        onChange={selectChange}
        options={options}
        styles={styles}
        {...otherProps}
      />
      {error && typeof error === 'string' && (
        <span className={element('Error')}>{error}</span>
      )}
    </div>
  )
}
