import { useFormik } from 'formik'
import * as React from 'react'
import { connect } from 'react-redux'
import { TDispatch } from 'store'
import { openToast, resendInvite } from 'store/User'

import { validateEmail } from '../../../api2/admin'
import { bindBem } from '../../../bem'
import { USER_FORM } from '../../../messages'
import { getUserSchema } from '../../../utils'
import { Button } from '../../Button'
import { Input, Labeled } from '../../Input'
import { Link } from '../../Link'
import { UserFormSuperAdmin } from './UserFormSuperAdmin'

import close from '../../../static/close-dialog.svg'

import './UserForm.scss'

export interface IProps {
  organizationId: number
  user: IUser
  isSuperAdmin: boolean
  onSave: (user: IUser) => void
  onClose: () => void
  openToast: (toast: Partial<IToast>) => void
  resendInvite: (user: IUser) => void
  deleteUsers: (users: IUser[]) => void
}

const DEFAULT_USER: IUser = {
  id: null,
  organizationId: null,
  username: '',
  email: '',
  settings: {
    apiAccess: true,
    isActive: true,
    isMigrated: false,
    isRestricted: false,
    restrictedIp: '',
    ratelimit: null,
  },
  apiToken: '',
  role: 'user',
  dbConfigName: 'us',
  mfaStatus: false,
}

export const UserFormComponent: React.FC<IProps> = props => {
  const schema = getUserSchema()

  const [emailTakenBy, setEmailTakenBy] = React.useState(null)
  const emailTaken = emailTakenBy !== null
  const { onClose, isSuperAdmin } = props
  const { block, element } = bindBem('UserForm')
  const isNew = !props.user
  const title = isNew ? USER_FORM.ADD_TITLE : USER_FORM.EDIT_TITLE
  const submit = isNew ? USER_FORM.ADD : USER_FORM.EDIT
  const thisUser = props.user as IUser

  const onCopyInviteLink = async () => {
    const location = window.location

    if (!thisUser.pendingActivationToken) {
      return
    }

    const activationUrl = `${location.protocol}//${location.host}/activate-account/${thisUser.pendingActivationToken}`

    navigator.clipboard
      .writeText(activationUrl)
      .then(() => props.openToast({ title: USER_FORM.TOKEN_ACTIONS.COPY_LINK_TOAST }))
      .catch(() => {
        /* eat any errors - don't show toast */
      })
  }

  const onSendInvite = () => {
    // Currently it's probably only ran on user creation
    if (thisUser.pendingActivationToken) {
      props.resendInvite(thisUser)
    }
  }

  const onSave = async (user: IUser) => {
    if (!formik.isValid || emailTaken || (await onValidateEmail())) {
      return
    }
    props.onSave(user)
    props.onClose()
  }

  const formik = useFormik<IUser>({
    initialValues: props.user || {
      ...DEFAULT_USER,
      organizationId: props.organizationId,
    },
    onSubmit: onSave,
    validationSchema: schema,
  })

  const onValidateEmail = async () => {
    const { email } = formik.values
    if (!email) {
      setEmailTakenBy(null)
    }
    if (props.user?.email === email) {
      return
    }
    const organizationId = await validateEmail(email)
    setEmailTakenBy(organizationId)
    return organizationId !== null
  }

  const focusCaretRight = (event: React.FormEvent<HTMLInputElement>) => {
    const input = event.currentTarget
    const len = input.value.length
    input.setSelectionRange(len, len)
  }

  return (
    <div className={block()}>
      <img className={element('Close')} src={close} onClick={onClose} />
      <div className={element('Title')}>{title}</div>
      <div className={element('Content')}>
        <Labeled label={USER_FORM.NAME} error={formik.errors.username}>
          <Input
            focus
            name="username"
            onChange={formik.handleChange}
            value={formik.values.username}
            error={!!formik.errors.username}
            placeholder={USER_FORM.PLACEHOLDER.USERNAME}
            onFocus={focusCaretRight}
          />
        </Labeled>
        <Labeled label={USER_FORM.EMAIL} error={formik.errors.email}>
          <Input
            name="email"
            onChange={formik.handleChange}
            onBlur={onValidateEmail}
            value={formik.values.email}
            error={!!formik.errors.email}
            placeholder={USER_FORM.PLACEHOLDER.EMAIL}
          />
        </Labeled>
        {isSuperAdmin && UserFormSuperAdmin(formik, element)}
        <div className={element('Bottom')}>
          {emailTaken && (
            <div className={element('Error')}>
              {emailTakenBy === props.organizationId
                ? USER_FORM.ERROR_SAME_ORG
                : USER_FORM.ERROR_OTHER_ORG}
            </div>
          )}
          {!emailTaken && thisUser.pendingActivationToken && (
            <span className={element('BottomActions')}>
              <Link text={USER_FORM.TOKEN_ACTIONS.SEND_INVITE} onClick={onSendInvite} />
              <Link text={USER_FORM.TOKEN_ACTIONS.COPY_LINK} onClick={onCopyInviteLink} />
            </span>
          )}
          <Button
            className={element('Submit')}
            onClick={formik.submitForm}
            disabled={!formik.isValid || emailTaken}
            text={submit}
            size="small"
            style="dark"
          />
        </div>
      </div>
    </div>
  )
}

const mapDispatch = (dispatch: TDispatch): Partial<IProps> => {
  return {
    resendInvite: (user: IUser) => dispatch(resendInvite(user)),
    openToast: (toast: Partial<IToast>) => dispatch(openToast(toast)),
  }
}

export const UserForm = connect(null, mapDispatch)(UserFormComponent)
