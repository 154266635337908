// eslint-disable max-len

import * as React from 'react'

const IconBell = ({ color }: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      fill={color}
      fillRule="nonzero"
      d="M12 22c1.034 0 1.88-.855 1.88-1.9h-3.76c0 1.045.846 1.9 1.88 1.9zm6.11-5.7v-5.225c0-2.945-1.974-5.32-4.7-5.985v-.665C13.41 3.665 12.752 3 12 3c-.752 0-1.41.665-1.41 1.425v.665c-2.726.665-4.7 3.04-4.7 5.985V16.3l-1.88 1.9v.95h15.98v-.95l-1.88-1.9zm-1.88.95H7.77v-6.175C7.77 8.7 9.65 6.8 12 6.8c2.35 0 4.23 1.9 4.23 4.275v6.175z"
    />
  </svg>
)

export const ICON_BELL = <IconBell color="#3FA2DB" />
