import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { last } from 'lodash'
import { format } from 'date-fns'
import { bindBem } from '../../bem'
import { ActionsBar } from '../Modal'
import { Button } from '../Button'
import { SIDEBAR, GRAPH, AGGREGATION_TYPES } from '../../messages'
import { formatLabel, COLORS, getSeriesLabel } from '../../services/series'

import './ParametersDialog.scss'

const MESSAGES = SIDEBAR.PARAMETERS

type MessagesMapping = { key: keyof typeof MESSAGES; value: string | number }[]

interface IState {
  isCopied: boolean
}
export class ParametersDialog extends React.Component<{ series: IDataSeries }, IState> {
  state: IState = { isCopied: false }

  render() {
    const { series } = this.props
    const { isCopied } = this.state
    const { block, element } = bindBem('ParametersDialog')

    const values = this.getValues()
    const valuesCSV = values.reduce(
      (v, pair) => `${v}\n${MESSAGES[pair.key]}: ${pair.value}\n`,
      `${getSeriesLabel(series)}`
    )

    return (
      <div className={block()}>
        <ActionsBar>
          <CopyToClipboard text={valuesCSV}>
            <Button
              onClick={this.onCopy}
              style="light"
              text={isCopied ? MESSAGES.COPIED : MESSAGES.COPY}
              size="small"
            />
          </CopyToClipboard>
        </ActionsBar>
        <div className={element('Content')}>
          <div className={element('Name')}>
            <span
              className={element('Marker')}
              style={{ backgroundColor: COLORS[series.colorIndex] }}
            />
            {series.description || getSeriesLabel(series)}
          </div>
          {values.map(({ key, value }) => (
            <div className={element('Row')} key={key}>
              <div className={element('Key')}>{MESSAGES[key]}</div>
              <div className={element('Value')}>{value}</div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  private onCopy = () => this.setState({ isCopied: true })

  private getValues = (): MessagesMapping => {
    const { series } = this.props
    const mapping: { key: keyof typeof MESSAGES; value?: string | number }[] = [
      { key: 'FREQUENCY', value: GRAPH[series.frequency] },
      {
        key: 'START_DATE',
        value: formatLabel(series.startDate, series.frequency),
      },
      {
        key: 'END_DATE',
        value:
          last(series.dataPoints) &&
          formatLabel(last(series.dataPoints).date, series.frequency),
      },
      { key: 'NO_OF_PERIODS', value: series.dataPoints.length },
      // { key: 'MAX_NO_OF_PERIODS', value: undefined }, // [mc] TODO: uncomment when it's clear what goes in here
      { key: 'MAGNITUDE', value: series.magnitude },
      { key: 'DECIMAL_PRECISION', value: series.decimalPrecision },
      { key: 'DIFFERENCE_TYPE', value: Number(series.differenceType) },
      {
        key: 'AGGREGATION_TYPE',
        value: AGGREGATION_TYPES[series.aggregationType],
      },
      { key: 'DATA_TYPE', value: series.dataType },
      { key: 'UPDATE_GROUP', value: series.groupName },
      {
        key: 'GEOGRAPHY',
        value: `${series.geography}${series.geography2 ? ':' + series.geography2 : ''}`,
      },
      {
        key: 'LAST_MODIFIED',
        value: series.lastModified
          ? format(series.lastModified, 'EEE MMM dd HH:mm:ss yyyy')
          : null,
      },
      { key: 'SHORT_SOURCE', value: series.shortSourceName },
      { key: 'LONG_SOURCE', value: series.sourceName },
    ]

    return mapping.map(({ key, value }) => ({
      key,
      value: !value && value !== 0 ? SIDEBAR.UNDEFINED : value,
    }))
  }
}
