// eslint-disable max-lines

import * as React from 'react'
import { connect } from 'react-redux'
import ReactTable, { Column, SortingRule } from 'react-table'

import * as admin from '../store/Admin'
import { IRootState } from '../store'
import { ADMIN } from '../messages'
import { bindBem } from '../bem'

import AdminHeader from '../components/Admin/AdminHeader'

import {
  HeaderCell,
  CenterText,
  ClickCell,
  DeleteCell,
} from 'components/Admin/AdminTableCells'
import { BigSwitch } from '../components/Admin/BigSwitch'
import { Input } from '../components/Input'
import { ICON_SEARCH_SLATE } from '../components/SVG/Search'
import { Modal, Dialog } from '../components/Modal'
import { Button } from '../components/Button'
import { PackageForm } from '../components/Admin/PackageForm'
import { RemovePackageConfirmation } from '../components/Admin/RemoveOrgConfirmation'
import NoDataComponent from 'components/ReactTableNoDataComponent'

import './PackageAdmin.scss'

export interface IProps {
  databases: IDatabase[]
  organizations: IOrganization[]
  packages: IDBPackage[]
}

export interface IActionProps {
  patchPackage: (pkg: IDBPackage) => void
  removePackage: (pkg: IDBPackage) => void
  createPackage: (pkg: IDBPackage) => void
}

export interface IState {
  sortedColumn: string
  desc: boolean
  filter: string
  loading: boolean
  selectedPackage: IDBPackage
  openModal: 'add' | 'edit' | 'delete' | null
}

export class PackageAdmin extends React.Component<IProps & IActionProps, IState> {
  state: IState = {
    sortedColumn: 'name',
    desc: false,
    filter: '',
    loading: false,
    selectedPackage: null,
    openModal: null,
  }

  private COLUMNS: Column[] = [
    {
      Header: ADMIN.PACKAGES.PACKAGE_NAME,
      accessor: 'name',
      Cell: ({ value, original }) => (
        <ClickCell value={value} onClick={() => this.openModalFor(original, 'edit')} />
      ),
    },
    {
      Header: ADMIN.PACKAGES.ORGANIZATIONS,
      id: 'organizationsCount',
      accessor: (pkg: IDBPackage) => pkg.organizations.length,
      maxWidth: 140,
      Cell: CenterText,
    },
    {
      Header: ADMIN.PACKAGES.DATABASES,
      id: 'databasesCount',
      accessor: (pkg: IDBPackage) => pkg.databases.length,
      maxWidth: 120,
      Cell: CenterText,
    },
    {
      Header: ADMIN.PACKAGES.STATUS,
      accessor: 'isEnabled',
      maxWidth: 140,
      Cell: ({ original }) => (
        <BigSwitch
          value={original.isEnabled}
          onChange={value => this.onChangeStatus(original, value)}
        />
      ),
    },
    {
      Header: '',
      sortable: false,
      maxWidth: 84,
      Cell: ({ original }) => (
        <DeleteCell onClick={() => this.openModalFor(original, 'delete')} />
      ),
    },
  ]

  render() {
    const { block, element } = bindBem('PackageAdmin')
    const { selectedPackage, openModal } = this.state
    const filteredPackages = this.filterPackages()

    return (
      <div className={block()}>
        <AdminHeader />
        <Button
          className={element('AddPackageButton')}
          onClick={this.onOpenAddPackage}
          text={ADMIN.PACKAGES.ADD_PACKAGE}
          style="light"
          size="small"
        />
        <div className={element('Search')}>
          <Input
            name="filter"
            prefix={ICON_SEARCH_SLATE}
            placeholder={ADMIN.PACKAGES.SEARCH_PLACEHOLDER}
            value={this.state.filter}
            onChange={this.onFilterChange}
          />
        </div>
        <ReactTable
          className={element('Table')}
          data={filteredPackages}
          sorted={[{ id: this.state.sortedColumn, desc: this.state.desc }]}
          columns={this.getColumns()}
          onSortedChange={this.onSortedChange}
          showPagination={false}
          sortable={false}
          resizable={false}
          pageSize={filteredPackages.length}
          loadingText="Loading..."
          loading={this.props.packages.length === 0}
          getNoDataProps={({ loading }) => ({ loading })}
          NoDataComponent={NoDataComponent}
        />
        {selectedPackage && openModal === 'edit' && (
          <Dialog
            isOpen
            onClose={this.closeModal}
            title={selectedPackage.name}
            className="PackageDialog"
          >
            <PackageForm
              databases={this.props.databases}
              organizations={this.props.organizations}
              package={selectedPackage}
              onSave={this.patchPackage}
            />
          </Dialog>
        )}
        {openModal === 'add' && (
          <Dialog
            isOpen
            onClose={this.closeModal}
            title={ADMIN.PACKAGES.ADD_PACKAGE}
            className="PackageDialog"
          >
            <PackageForm
              databases={this.props.databases}
              organizations={this.props.organizations}
              onSave={this.createPackage}
            />
          </Dialog>
        )}
        {openModal === 'delete' && (
          <Modal isOpen onClose={this.closeModal} onClosed={this.closeModal}>
            <RemovePackageConfirmation
              onClose={this.closeModal}
              obj={selectedPackage}
              callback={this.removePackage}
            />
          </Modal>
        )}
      </div>
    )
  }

  private onChangeStatus = (pkg: IDBPackage, isEnabled: boolean) =>
    pkg.isEnabled !== isEnabled && this.props.patchPackage({ ...pkg, isEnabled })

  private onSortedChange = (sorting: SortingRule[]) => {
    if (!sorting.length) {
      return this.setState({ sortedColumn: '', desc: true })
    }
    const { id, desc } = sorting[0]
    this.setState({ sortedColumn: id, desc })
  }

  private onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ filter: e.currentTarget.value })

  private filterPackages = () => {
    const filter = this.state.filter.toLowerCase()
    return this.props.packages.filter(d => d.name.toLowerCase().includes(filter))
  }

  private getColumns = (): Column[] => {
    return this.COLUMNS.map(col => ({
      ...col,
      Header: (
        <HeaderCell
          isSorting={this.state.sortedColumn === (col.id ?? col.accessor)}
          text={col.Header as string}
          desc={this.state.desc}
        />
      ),
      sortable: true,
      sortMethod: (a, b) => {
        if (typeof a === 'string') {
          return a.localeCompare(b, 'en-US', { numeric: true })
        }
        return a === b ? 0 : a < b ? -1 : 1
      },
    }))
  }

  private openModalFor = async (pkg: IDBPackage, openModal: IState['openModal']) => {
    this.setState({ selectedPackage: { ...pkg }, openModal })
  }

  private patchPackage = async (pkg: IDBPackage) => {
    this.closeModal()
    this.props.patchPackage(pkg)
  }

  private onOpenAddPackage = () => this.setState({ openModal: 'add' })

  private createPackage = async (pkg: IDBPackage) => {
    this.closeModal()
    this.props.createPackage(pkg)
  }

  private removePackage = async () => {
    const p = this.state.selectedPackage
    this.closeModal()
    this.props.removePackage(p)
  }

  private closeModal = () => this.setState({ openModal: null, selectedPackage: null })
}

const mapStateToProps = (state: IRootState): IProps => ({
  databases: state.admin.databases.map(d => ({
    ...d,
    description: `${d.description} (${d.name})`,
  })),
  organizations: state.admin.organizations,
  packages: state.admin.packages,
})

const mapDispatchToProps: IActionProps = {
  patchPackage: admin.updatePackage,
  removePackage: admin.deletePackage,
  createPackage: admin.createPackage,
}

export default connect(mapStateToProps, mapDispatchToProps)(PackageAdmin)
