// eslint-disable max-lines

import * as React from 'react'
import { connect } from 'react-redux'
import ReactTable, { Column, SortingRule } from 'react-table'

import * as admin from '../store/Admin'
import { IRootState } from '../store'
import { IAddOrgForm, fetchUsers } from '../api2/admin'
import { ADMIN } from '../messages'
import { bindBem } from '../bem'

import {
  LinkCell,
  HeaderCell,
  CenterText,
  Text,
  OrgStatusCell,
} from 'components/Admin/AdminTableCells'
import { Button } from '../components/Button'
import AddOrgForm from '../components/Admin/AddOrgForm'
import { Modal } from '../components/Modal'
import { Input } from '../components/Input'

import { RemoveOrgConfirmation } from '../components/Admin/RemoveOrgConfirmation'
import { DeactivateOrgConfirmation } from '../components/Admin/DeactivateOrgConfirmation'
import AdminHeader from '../components/Admin/AdminHeader'
import { IBadRequestErrorData } from 'api/config'
import { dbsCompare } from '../services/databases'
import NoDataComponent from 'components/ReactTableNoDataComponent'
import { Badge } from 'components/Badge'
import { openToast } from '../store/User'
import OrgKebabMenu from 'components/Admin/OrgKebabMenu'
import { list2csv, orgStatusAsText, orgStatusResolver } from 'components/Admin/utils'

import { ICON_SEARCH_SLATE } from 'components/SVG/Search'

import './OrgAdmin.scss'

export interface IProps {
  organizations: IOrganization[]
  databases: { id: string; name: string }[]
  packages: IDBPackage[]
}

export interface IActionProps {
  createOrganization: (
    form: IAddOrgForm,
    onError: (errors: IBadRequestErrorData) => void,
    onSuccess: () => void
  ) => void
  activateOrganization: (org: IOrganization) => void
  deactivateOrganization: (org: IOrganization) => void
  deleteOrganization: (org: IOrganization) => void
  patchOrganization: (id: number, data: Partial<IOrganization>) => void
  openToast: (toast: Partial<IToast>) => void
}

export interface IState {
  sortedColumn: string
  desc: boolean
  filter: string
  isAddOrgModalOpen: boolean
  isDeactivateOrgModalOpen: boolean
  isDeleteOrgModalOpen: boolean
  selectedOrganization: IOrganization
}

export class OrgAdmin extends React.Component<IProps & IActionProps, IState> {
  state: IState = {
    sortedColumn: 'name',
    desc: false,
    filter: '',
    isAddOrgModalOpen: false,
    isDeactivateOrgModalOpen: false,
    isDeleteOrgModalOpen: false,
    selectedOrganization: null,
  }

  private COLUMNS: Column[] = [
    { Header: ADMIN.ORG.ORG_NAME, accessor: 'name', Cell: OrgLinkCell },
    {
      Header: ADMIN.ORG.ACCOUNT_NUMBER,
      accessor: 'accountNumber',
      maxWidth: 150,
      Cell: Text,
    },
    {
      Header: ADMIN.ORG.USERS,
      accessor: 'usersCount',
      maxWidth: 120,
      Cell: CenterText,
    },
    {
      Header: ADMIN.ORG.STATUS,
      accessor: 'status',
      maxWidth: 250,
      Cell: data => <OrgStatusCell org={data.original} />,
    },
  ]

  render() {
    const { block, element } = bindBem('OrgAdmin')
    const { organizations, databases, packages } = this.props
    const filteredOrganizations = this.filterOrganizations()

    return (
      <div className={block()}>
        <AdminHeader />
        <div className={element('Search')}>
          <Input
            name="filter"
            prefix={ICON_SEARCH_SLATE}
            placeholder={ADMIN.ORG.SEARCH_PLACEHOLDER}
            value={this.state.filter}
            onChange={this.onFilterChange}
          />
        </div>
        <Button
          className={element('AddOrgButton')}
          onClick={this.onAddOrganization}
          text={ADMIN.ORG.ADD_ORGANIZATION}
          style="light"
          size="small"
        />
        <Button
          className={element('ExportButton')}
          onClick={this.onExport}
          text={ADMIN.ORG.EXPORT}
          style="light"
          size="small"
          disabled={organizations.length === 0}
        />
        <ReactTable
          defaultSorted={[{ id: 'name', desc: false }]}
          sorted={[{ id: this.state.sortedColumn, desc: this.state.desc }]}
          className={element('Table')}
          data={filteredOrganizations}
          columns={this.getColumns()}
          onSortedChange={this.onSortedChange}
          showPagination={false}
          sortable={false}
          resizable={false}
          pageSize={filteredOrganizations.length}
          loading={organizations.length === 0}
          getNoDataProps={({ loading }) => ({ loading })}
          NoDataComponent={NoDataComponent}
        />
        <Modal isOpen={this.state.isAddOrgModalOpen} onClose={this.onToggleAddOrgModal}>
          <AddOrgForm
            templateOrg={this.state.selectedOrganization}
            databases={databases}
            packages={packages}
            onSave={this.props.createOrganization}
            onClose={this.onToggleAddOrgModal}
            openToast={this.props.openToast}
          />
        </Modal>
        <Modal
          isOpen={this.state.isDeactivateOrgModalOpen}
          onClose={this.onToggleDeactivateOrgModal}
        >
          <DeactivateOrgConfirmation
            callback={this.confirmDeactivateOrg}
            onClose={this.onToggleDeactivateOrgModal}
          />
        </Modal>
        <Modal
          isOpen={this.state.isDeleteOrgModalOpen}
          onClose={this.onToggleDeleteOrgModal}
        >
          <RemoveOrgConfirmation
            callback={this.confirmDeleteOrg}
            onClose={this.onToggleDeleteOrgModal}
            obj={this.state.selectedOrganization}
          />
        </Modal>
      </div>
    )
  }

  private onExport = async () => {
    const { organizations } = this.props
    const users = await fetchUsers(null, 800)

    const fields = [
      'Organization Name',
      'Acccount Number',
      'Organization Status',
      'User Name',
      'User Email',
      'User Role',
      'User Status',
      'User API Status',
    ]
    const data: any[] = []

    organizations.forEach(org => {
      const orgUsers = users.filter(user => user.organizationId === org.id)

      const orgStatus = orgStatusResolver(org)
      const orgStatusText = orgStatusAsText(orgStatus, org)

      orgUsers.forEach(user => {
        data.push({
          'Organization Name': org.name,
          'Acccount Number': org.accountNumber,
          'Organization Status': orgStatusText,
          'User Name': user.username,
          'User Email': user.email,
          'User Role': user.role,
          'User Status': user.settings.isActive ? 'On' : 'Off',
          'User Migrated?': user.settings.isMigrated ? 'Yes' : 'No',
          'User API Status': user.settings.apiAccess ? 'On' : 'Off',
        })
      })
    })

    list2csv(data, fields, 'Haver-organization-users.csv')
  }

  private onAddOrganization = () => {
    this.setState({ selectedOrganization: null }, this.onToggleAddOrgModal)
  }

  private onToggleAddOrgModal = () =>
    this.setState(() => ({ isAddOrgModalOpen: !this.state.isAddOrgModalOpen }))

  private activateOrg = (org: IOrganization) => this.props.activateOrganization(org)

  private onToggleDeactivateOrgModal = () =>
    this.setState(() => ({
      isDeactivateOrgModalOpen: !this.state.isDeactivateOrgModalOpen,
    }))

  private deactivateOrg = (org: IOrganization) =>
    this.setState(() => ({
      selectedOrganization: org,
      isDeactivateOrgModalOpen: true,
    }))

  private confirmDeactivateOrg = () => {
    this.props.deactivateOrganization(this.state.selectedOrganization)
    this.onToggleDeactivateOrgModal()
  }

  private onToggleDeleteOrgModal = () =>
    this.setState(() => ({
      isDeleteOrgModalOpen: !this.state.isDeleteOrgModalOpen,
    }))

  private deleteOrg = (org: IOrganization) =>
    this.setState(() => ({
      selectedOrganization: org,
      isDeleteOrgModalOpen: true,
    }))

  private confirmDeleteOrg = () => {
    this.props.deleteOrganization(this.state.selectedOrganization)
    this.onToggleDeleteOrgModal()
  }

  private onSortedChange = (sorting: SortingRule[]) => {
    if (!sorting.length) {
      return this.setState({ sortedColumn: '', desc: true })
    }
    const { id, desc } = sorting[0]
    this.setState({ sortedColumn: id, desc })
  }

  private onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ filter: e.currentTarget.value })

  private filterOrganizations = () => {
    const filter = this.state.filter.toLowerCase()
    return this.props.organizations.filter(
      org =>
        org.name.toLowerCase().includes(filter) ||
        (org.accountNumber ?? '').toLowerCase().includes(filter)
    )
  }

  private duplicateOrg = (org: IOrganization) =>
    this.setState({ selectedOrganization: org }, this.onToggleAddOrgModal)

  private getColumns = (): Column[] => {
    const columns = this.COLUMNS.map(col => ({
      ...col,
      Header: (
        <HeaderCell
          isSorting={this.state.sortedColumn === (col.id ?? col.accessor)}
          text={col.Header as string}
          desc={this.state.desc}
        />
      ),
      sortable: true,
      sortMethod: (a, b) => {
        if (typeof a === 'string') {
          return a.localeCompare(b, 'en-US', { numeric: true })
        }
        return a === b ? 0 : a < b ? -1 : 1
      },
    }))

    return [
      ...columns,
      {
        Header: '',
        sortable: false,
        maxWidth: 50,
        Cell: data => (
          <OrgKebabMenu
            isActive={data.original.status === true}
            onDelete={() => this.deleteOrg(data.original)}
            onActivate={() => this.activateOrg(data.original)}
            onDeactivate={() => this.deactivateOrg(data.original)}
            onDuplicate={() => this.duplicateOrg(data.original)}
          />
        ),
      },
    ]
  }
}

const OrgLinkCell = (data: any) => (
  <div>
    {data.original.mfaStatus && <Badge>2FA</Badge>}
    {data.original.isRestricted && <Badge>IP</Badge>}
    <LinkCell to={`/admin/organizations/${data.original.id}`}>{data.value}</LinkCell>
  </div>
)

const mapStateToProps = (state: IRootState): IProps => {
  const { organizations, packages } = state.admin
  const databases = Object.keys(state.databases.dbNames)
    .sort(dbsCompare)
    .map(k => ({
      id: k,
      name: `${state.databases.dbNames[k]} (${k})`,
    }))

  return {
    organizations,
    databases,
    packages,
  }
}

const mapDispatchToProps = (dispatch: any): IActionProps => {
  return {
    createOrganization: (
      form: IAddOrgForm,
      onError: (errors: IBadRequestErrorData) => void,
      onSuccess: () => void
    ) => dispatch(admin.createOrganization(form, onError, onSuccess)),
    activateOrganization: (org: IOrganization) =>
      dispatch(admin.activateOrganization(org)),
    deactivateOrganization: (org: IOrganization) =>
      dispatch(admin.deactivateOrganization(org)),
    deleteOrganization: (org: IOrganization) => dispatch(admin.deleteOrganization(org)),
    patchOrganization: (id: number, data: Partial<IOrganization>) =>
      dispatch(admin.patchOrganization(id, data)),
    openToast: (toast: Partial<IToast>) => dispatch(openToast(toast)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgAdmin)
