import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'
import { Base64 } from 'js-base64'
import { formatValue, getDatesExtent, getVariableValue } from './table'
import { formatLabel, freqToInterval, getSeriesLabel } from './series'
import { FREQUENCY_LABEL } from '../messages'

const CHART_ID = '#chart'
const NO_PRINT = '.no-print'

type XLSHeader = string[]
type XLSRow = [string, ...string[]]
type XLSTable = [XLSHeader?, ...XLSRow[]]
type XLSMeta = [string[]?]

const METAS: {
  label: string
  attr?: keyof IDataSeries
  renderer?: (variable: IDataSeries) => string
}[] = [
  { label: '', renderer: variable => getSeriesLabel(variable) },
  { label: '.desc', attr: 'description' },
  { label: '.source', attr: 'sourceName' },
  { label: '.dtlm', attr: 'lastModified' },
  { label: '.frq', renderer: variable => FREQUENCY_LABEL[variable.frequency] },
]

export const getSvgBlob = (node: HTMLElement) => {
  const html = new XMLSerializer().serializeToString(node)
  try {
    return new Blob([html], { type: 'image/svg+xml' })
  } catch (e) {
    const w = window as any
    const BlobBuilder =
      w.BlobBuilder || w.WebKitBlobBuilder || w.MozBlobBuilder || w.MSBlobBuilder
    if (!BlobBuilder) {
      // eslint-disable-next-line no-console
      return console.error('Blob cannot be built')
    }
    const bb = new BlobBuilder()
    bb.append(html)
    return bb.getBlob('text/plain')
  }
}

export const downloadChartSVG = (name: string) => {
  const node = makeNodePrintable(getChartNode())
  saveAs(getSvgBlob(node), `Haver - ${name}.svg`)
}

export const getChartNode = () => {
  return document.querySelector(CHART_ID).cloneNode(true) as HTMLElement
}

export const makeNodePrintable = (node: HTMLElement) => {
  const printable = node.cloneNode(true) as HTMLElement
  printable.querySelectorAll(NO_PRINT).forEach(e => e.remove())
  return printable
}

export const getPngBlob = (node: HTMLElement, callback: (blob: Blob) => void) => {
  const targetWidth = 1920
  const [width, height] = node
    .getAttribute('viewBox')
    .split(' ')
    .slice(2, 4)
    .map(parseFloat)

  const ratio = targetWidth / width
  node.setAttribute('width', targetWidth.toString())
  node.setAttribute('height', (height * ratio).toString())
  node.style.height = null

  const canvas = document.createElement('canvas')
  canvas.setAttribute('width', targetWidth.toString())
  canvas.setAttribute('height', (height * ratio).toString())
  const html = new XMLSerializer().serializeToString(node)
  const saveBlob = () => canvas.toBlob(callback)

  drawToCanvas(canvas, html, saveBlob)
}

export const downloadChartPNG = (name: string) => {
  const node = makeNodePrintable(getChartNode())
  const callback = (blob: Blob) => saveAs(blob, `Haver - ${name}.png`)
  getPngBlob(node, callback)
}

const drawToCanvas = (canvas: HTMLCanvasElement, html: string, onSave: () => void) => {
  const ctx = canvas.getContext('2d')
  ctx.fillStyle = 'white'
  const img = new Image()
  img.onload = () => {
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
    onSave()
  }
  img.src = `data:image/svg+xml;base64,${Base64.encode(html)}`
}

export const convertVariablesToAoA = (variables: IDataSeries[], headers = true) => {
  const frequency = Math.max(...variables.map(v => v.frequency)) as Frequency
  const [startDate, endDate] = getDatesExtent(variables.filter(v => v.dataPoints.length))

  const interval = freqToInterval(frequency)
  const dates = interval.range(interval.floor(startDate), endDate)

  const data: XLSTable = []

  if (headers) {
    const header: XLSHeader = ['', ...variables.map(variable => getSeriesLabel(variable))]
    data.push(header)
  }

  dates.forEach(date => {
    const row: XLSRow = [formatLabel(date, frequency)]
    variables.forEach(variable => {
      const value = getVariableValue(
        date,
        interval.offset(date, 1),
        variable.dataPoints,
        variable.frequency,
        frequency
      )
      const formattedValue = formatValue(value, variable.decimalPrecision)
      row.push(formattedValue)
    })
    data.push(row)
  })
  return data
}

export const convertVariablesMetadataToAoA = (variables: IDataSeries[]) => {
  const meta: XLSMeta = []

  METAS.forEach(({ label, attr, renderer }) => {
    const row = [label]

    variables.forEach(variable => {
      const value = renderer ? renderer(variable) : String(variable[attr])
      row.push(value)
    })
    meta.push(row)
  })

  return meta
}

export const downloadData = (
  title: string,
  variables: IDataSeries[],
  bookType: string
) => {
  const data = convertVariablesToAoA(variables, false)
  const meta = convertVariablesMetadataToAoA(variables)
  const worksheet = XLSX.utils.aoa_to_sheet([...meta, ...data])
  const workbook = XLSX.utils.book_new()
  const opts: XLSX.WritingOptions = {
    type: 'string',
    bookType: bookType as XLSX.BookType,
  }
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Data')
  XLSX.writeFile(workbook, `${title}.${bookType}`, opts)
}
