import * as React from 'react'

import { bindBem } from '../../bem'

import { SIDEBAR } from '../../messages'
import { RadioGroup } from '../RadioGroup'
import RadioButton from '../RadioButton'
import { Input } from '../Input'
import { Button } from '../Button'

import close from '../../static/close-dialog.svg'
import search from '../../static/small-search-icon.svg'

import './OptionsListDialog.scss'

interface IProps {
  currentValue: string
  title: string
  values: string[]
  onClose: (country: string) => void
}

interface IState {
  value: string
  filter: string
}

export class OptionsListDialog extends React.Component<IProps, IState> {
  state = { value: this.props.currentValue, filter: '' }
  handleClickOutside = this.props.onClose
  render() {
    const { block, element } = bindBem('OptionsListDialog')
    const { values: countries, onClose, title } = this.props
    const { value, filter } = this.state
    const upperCaseFilter = filter.toUpperCase()
    const filteredCountries = countries.filter(c =>
      c.toUpperCase().includes(upperCaseFilter)
    )
    return (
      <div className={block()}>
        <div className={element('Title')}>{title}</div>
        <div className={element('Filter')}>
          <Input
            focus
            name="filter"
            prefix={<img className={element('Icon')} src={search} />}
            placeholder={`${SIDEBAR.GRAPH_OPTIONS.COUNTRY_DIALOG.INPUT_PLACEHOLDER}...`}
            value={filter}
            onChange={e => this.setFilter(e.currentTarget.value)}
          />
        </div>
        <div className={element('List')}>
          <RadioGroup onChange={v => this.setState(() => ({ value: v }))} value={value}>
            {filteredCountries.map(v => (
              <RadioButton key={v} value={v}>
                {v}
              </RadioButton>
            ))}
          </RadioGroup>
        </div>
        <div className={element('Bottom')}>
          <Button size="small" text="Apply" style="dark" onClick={() => onClose(value)} />
        </div>
        <img className={element('Close')} src={close} onClick={() => onClose(value)} />
      </div>
    )
  }

  private setFilter = (filter: string) => this.setState(() => ({ filter }))
}
