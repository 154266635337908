import * as React from 'react'

import { bindBem } from '../../bem'
import { Button } from '../Button'
import { RadioGroup } from '../RadioGroup'
import RadioButton from '../RadioButton'
import { ActionsBar } from '../Modal'
import { Freq } from '../../services/series'
import { EDIT_TITLES_DIALOG, AGGREGATION_LABEL } from '../../messages'

import './AggregationDialog.scss'

interface IProps {
  onSave: (aggregation: Aggregation) => void
  minFrequency: number
  aggregation?: Aggregation
}

interface IState {
  selectedAggregation?: Aggregation
}

export class AggregationDialog extends React.Component<IProps, IState> {
  state: IState = { selectedAggregation: this.props.aggregation }

  handleAggregationChange = (value: string) => {
    const selectedAggregation = parseInt(value, 10) as Aggregation
    this.setState({ selectedAggregation })
  }

  onSave = () => this.props.onSave(this.state.selectedAggregation)

  render() {
    const { block, element } = bindBem('AggregationDialog')
    const { minFrequency } = this.props
    const { selectedAggregation } = this.state

    return (
      <div className={block()}>
        <ActionsBar>
          <Button
            text={EDIT_TITLES_DIALOG.APPLY}
            style="dark"
            onClick={this.onSave}
            size="small"
            className={element('Submit')}
          />
        </ActionsBar>

        <RadioGroup
          onChange={this.handleAggregationChange}
          value={selectedAggregation ? selectedAggregation.toString() : ''}
        >
          <RadioButton value={''}>None</RadioButton>

          {/* API needs to receive Freq.Fri == 55. The other comparison
              is still valid, and the message catalog can stay as-is. */}
          <RadioButton value={Freq.Fri.toString()} disabled={minFrequency < Freq.Mon}>
            {AGGREGATION_LABEL[Freq.Mon]}
          </RadioButton>

          <RadioButton
            value={Freq.Monthly.toString()}
            disabled={minFrequency < Freq.Monthly}
          >
            {AGGREGATION_LABEL[Freq.Monthly]}
          </RadioButton>

          <RadioButton
            value={Freq.Quarterly.toString()}
            disabled={minFrequency < Freq.Quarterly}
          >
            {AGGREGATION_LABEL[Freq.Quarterly]}
          </RadioButton>

          {/* Biannual is defined, but not used by any real series */}
          <RadioButton
            value={Freq.Annually.toString()}
            disabled={minFrequency < Freq.Annually}
          >
            {AGGREGATION_LABEL[Freq.Annually]}
          </RadioButton>
        </RadioGroup>
      </div>
    )
  }
}
