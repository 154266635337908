import _ from 'lodash'
import React from 'react'
import BaseResponsive, { MediaQueryTypes, useMediaQuery } from 'react-responsive'

import * as utils from '../utils'

const TABLET_PORTRAIT = 600

const SMALL_DESKTOP = 1023
const DESKTOP = 1400
const TABLET_LANDSCAPE = 900
export const PHONE_HEIGHT = 500

const BIG_DESKTOP_QUERY = `print, (min-width: ${DESKTOP}px)`
const SMALL_DESKTOP_QUERY = `(min-width: ${SMALL_DESKTOP}px) and (max-width: ${
  DESKTOP - 1
}px)`

// Tablet
const TABLET_PORTRAIT_QUERY = `screen and (min-width: ${TABLET_PORTRAIT}px) and (max-width: ${
  SMALL_DESKTOP - 1
}px) and (min-height: ${PHONE_HEIGHT}px)`

const TABLET_LANDSCAPE_QUERY =
  `screen and (orientation: landscape) and (min-width: ${TABLET_LANDSCAPE}px)` +
  ` and (max-width: ${SMALL_DESKTOP - 1}px) and (min-height: ${PHONE_HEIGHT}px)`
const TABLET_ALL_QUERY = [TABLET_PORTRAIT_QUERY, TABLET_LANDSCAPE_QUERY].join(',')

// phone
// portrait < 600
const PHONE_PORTRAIT_QUERY = `screen and (max-width: ${TABLET_PORTRAIT - 1}px)`

// landscape >= 900, height <= 500
const PHONE_LANDSCAPE_QUERY = `screen and (orientation: landscape) and (max-width: ${
  TABLET_LANDSCAPE - 1
}px) and (max-height: ${PHONE_HEIGHT - 1}px)`
const PHONE_ALL = [PHONE_PORTRAIT_QUERY, PHONE_LANDSCAPE_QUERY].join(',')

interface IProps extends MediaQueryTypes {
  extraMatch?: () => boolean
  parentMatch?: boolean
}
const DEFAULT_EXTRA_MATCH = () => true

export const Responsive: React.FunctionComponent<IProps> = ({
  children,
  parentMatch = false,
  extraMatch = DEFAULT_EXTRA_MATCH,
  ...props
}) => {
  const render = (match: boolean) => {
    const willMatch = (match && extraMatch()) || parentMatch
    if (typeof children === 'function') {
      return (children as unknown as Function)(willMatch)
    }
    if (!willMatch) {
      return null
    }
    return children
  }
  return <BaseResponsive {...props}>{match => render(match)}</BaseResponsive>
}

const useIsDesktop = () => {
  const smallDesktop = useMediaQuery({ query: SMALL_DESKTOP_QUERY })
  const bigDesktop = useMediaQuery({ query: BIG_DESKTOP_QUERY })
  const notMobile = !utils.isMobile()
  return (notMobile && smallDesktop) || bigDesktop
}

function useIsTablet() {
  const isMobile = utils.isMobile()
  const smallDesktop = useMediaQuery({ query: SMALL_DESKTOP_QUERY })
  const tablet = useMediaQuery({ query: TABLET_ALL_QUERY })
  return (isMobile && smallDesktop) || tablet
}

const useIsPhone = () => useMediaQuery({ query: PHONE_ALL })

const useIsMobile = () => {
  const isPhone = useIsPhone()
  const isTablet = useIsTablet()
  return isPhone || isTablet
}

export const useIsLandscapeMobile = () =>
  useMediaQuery({ maxHeight: 400, orientation: 'landscape' })

interface ICtx {
  isMobile: boolean
  isDesktop: boolean
  isTablet: boolean
  isPhone: boolean
}

const INITIAL_CTX_VALUE: ICtx = {
  isDesktop: false,
  isMobile: true,
  isTablet: false,
  isPhone: true,
}
export const ResponsiveContext = React.createContext<ICtx>(INITIAL_CTX_VALUE)

export function ResponsiveProvider(props: { children?: JSX.Element }) {
  const isDesktop = useIsDesktop()
  const isTablet = useIsTablet()
  const isPhone = useIsPhone()
  const contextValue = React.useMemo<ICtx>(
    () => ({
      isDesktop,
      isPhone,
      isTablet,
      isMobile: isPhone || isTablet,
    }),
    [isDesktop, isTablet, isPhone]
  )
  return (
    <ResponsiveContext.Provider value={contextValue}>
      {props.children}
    </ResponsiveContext.Provider>
  )
}

export const Desktop = (props: { children: React.ReactElement }) => {
  const isDesktop = useIsDesktop()
  return isDesktop ? props.children : null
}

export const Tablet: React.FC = ({ children }) => (
  <ResponsiveContext.Consumer>
    {({ isTablet }) => (isTablet ? children : null)}
  </ResponsiveContext.Consumer>
)

export const Phone: React.FC = ({ children }) => (
  <ResponsiveContext.Consumer>
    {({ isPhone }) => (isPhone ? children : null)}
  </ResponsiveContext.Consumer>
)

export const Mobile: React.FC = ({ children }) => {
  const isMobile = useIsMobile()
  return isMobile ? <>{children}</> : null
}

export const withResponsive = <P extends Partial<ICtx>>(
  component: React.FC<P & Partial<ICtx>> | React.ComponentClass<P & Partial<ICtx>>
) => {
  const Component = component as any
  const WithResponsive: React.FC<Omit<P, keyof ICtx>> = props => {
    const isDesktop = useIsDesktop()
    const isTablet = useIsTablet()
    const isPhone = useIsPhone()
    const isMobile = useIsMobile()
    const extraProps: ICtx = { isDesktop, isTablet, isPhone, isMobile }
    return <Component {...props} {...extraProps} />
  }

  return WithResponsive
}
