// eslint-disable max-len

import * as React from 'react'

const IconSearch = ({ color }: any) => (
  <svg width="18px" height="18px" viewBox="0 0 19 19">
    <g id="Screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Styleguide"
        transform="translate(-659.000000, -429.000000)"
        stroke={color}
        strokeWidth="2"
      >
        <g id="Group-2" transform="translate(116.000000, 250.000000)">
          <g id="Group" transform="translate(479.000000, 0.000000)">
            <g id="Group-5" transform="translate(44.000000, 159.000000)">
              <g id="Group-6" transform="translate(21.000000, 21.000000)">
                <g id="icon---Search">
                  <path d="M16.4583333,16.4583333 L12.4985354,12.4985354" id="Shape" />
                  <circle id="Oval" cx="6.33333333" cy="6.33333333" r="6.33333333" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const IconSearchBig = ({ color }: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd" stroke={color} strokeWidth="2">
      <path fill="#FFF" d="M20.458 20.458l-3.96-3.96" />
      <circle cx="10.333" cy="10.333" r="6.333" />
    </g>
  </svg>
)

export const ICON_SEARCH = <IconSearch color="#007CC3" />
export const ICON_SEARCH_SLATE = <IconSearch color="#485465" />
export const ICON_SEARCH_LIGHT = <IconSearch color="#FFFFFF" />

export const ICON_SEARCH_BIG = <IconSearchBig color="#3FA2DB" />
