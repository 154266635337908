import * as React from 'react'
import * as draggable from '../../services/hd3-utils/draggable'
import { CHART_GROUP_ID } from '../../constants'

interface IProps {
  left: number
  top: number
  width: number
  height: number
  onMouseMove: React.MouseEventHandler
  onMouseOut: React.MouseEventHandler
  onDragEnd: (x: number) => void
}

const DragNDrop = ({
  left,
  top,
  width,
  height,
  onMouseMove,
  onMouseOut,
  onDragEnd,
}: IProps) => {
  const [isDragging, setIsDragging] = React.useState(false)
  const [offset, setOffset] = React.useState(0)
  const [origin, setOrigin] = React.useState(0)

  const onDrag: React.MouseEventHandler = e => setOffset(e.clientX - origin)
  const onDragStart: React.MouseEventHandler = e => {
    setOrigin(e.clientX)
    setIsDragging(true)
  }
  const endDrag: React.MouseEventHandler = () => {
    setIsDragging(false)
    onDragEnd(offset)
    setOffset(0)
  }

  const style: React.CSSProperties = {
    position: 'absolute',
    top,
    left,
    width,
    height,
  }

  return (
    <svg id={draggable.svgId} style={style} width={width} height={height}>
      {isDragging && (
        <use x={offset} href={`#${CHART_GROUP_ID}`} id={draggable.dndChartGroupId}></use>
      )}
      <g>
        <rect
          onMouseMove={isDragging ? onDrag : onMouseMove}
          onMouseDown={onDragStart}
          onMouseUp={endDrag}
          onMouseOut={onMouseOut}
          fill="white"
          width={width}
          height={height}
          opacity={isDragging ? 0.8 : 0}
        ></rect>
      </g>
    </svg>
  )
}

export default DragNDrop
