// eslint-disable max-len

import * as React from 'react'

export const ICON_DATABASE = (
  <svg width="13px" height="15px" viewBox="0 0 13 15" version="1.1">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="_Directory-/-Level-0"
        transform="translate(-24.000000, -233.000000)"
        fill="#B8E0F6"
        fillRule="nonzero"
      >
        <g id="_Directory">
          <g id="Content" transform="translate(12.000000, 148.000000)">
            <g id="database" transform="translate(12.000000, 11.000000)">
              <path d="M6.5,84.5263158 C3.25,84.5263158 0,83.627193 0,82.1578947 C0,81.6900585 0,80.9883041 0,80.0526316 C1.48571429,81.0394737 3.71428571,81.6315789 6.5,81.6315789 C9.28571429,81.6315789 11.5142857,81.0394737 13,80.0526316 C13,80.9883041 13,81.6900585 13,82.1578947 C13,83.627193 9.75,84.5263158 6.5,84.5263158 Z M6.5,89 C3.25,89 0,88.1008772 0,86.6315789 C0,86.1637427 0,85.4619883 0,84.5263158 C1.48571429,85.5131579 3.71428571,86.1052632 6.5,86.1052632 C9.28571429,86.1052632 11.5142857,85.5131579 13,84.5263158 C13,85.4619883 13,86.1637427 13,86.6315789 C13,88.1008772 9.75,89 6.5,89 Z M6.5,79.9210526 C3.25,79.9210526 0,79.0328947 0,76.9605263 C0,74.8881579 3.25,74 6.5,74 C9.75,74 13,74.8881579 13,76.9605263 C13,79.0328947 9.75,79.9210526 6.5,79.9210526 Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
