import { FUNCTIONS_DIALOG } from '../../messages'

const { VALUE_VALIDATION_MESSAGE } = FUNCTIONS_DIALOG

export const errorMessages = {
  year: VALUE_VALIDATION_MESSAGE('YYYY', 1800, 2999),
  quarter: VALUE_VALIDATION_MESSAGE('Q', 1, 4),
  month: VALUE_VALIDATION_MESSAGE('MM', 1, 12),
  day: VALUE_VALIDATION_MESSAGE('DD', 1, 31),
}

const createValueValidator =
  (groupName: string, min: number, max: number) =>
  (regexp: RegExp) =>
  (value: string) => {
    if (!regexp.test(value)) {
      return true
    }
    const regExpGroups = regexp.exec(value)
    if (!regExpGroups) {
      return false
    }
    const { groups } = regExpGroups
    return !!(groups?.[groupName] >= min && groups?.[groupName] <= max)
  }

export const validators = {
  year: createValueValidator('year', 1800, 2999),
  quarter: createValueValidator('quarter', 1, 4),
  month: createValueValidator('month', 1, 12),
  day: createValueValidator('day', 1, 31),
}

export const regExps = {
  year: /^(?<year>[0-9]{4})$/,
  quarter: /^(?<year>[0-9]{4})(?<quarter>[0-9]{1})$/,
  month: /^(?<year>[0-9]{4})(?<month>[0-9]{2})$/,
  day: /^(?<year>[0-9]{4})(?<month>[0-9]{2})(?<day>[0-9]{2})$/,
}
