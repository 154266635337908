// eslint-disable max-lines

import * as React from 'react'
import SortableTree, {
  ExtendedNodeData,
  NodeData,
  OnDragPreviousAndNextLocation,
} from 'react-sortable-tree'
import { connect } from 'react-redux'

import { bindBem } from 'bem'
import { ColumnHeader } from './ColumnHeader'
import { theme } from './Theme'
import { GroupOptions } from './Options/GroupOptions'
import * as menuService from './services/menu.service'
import {
  ITreeItem,
  ITree,
  ITreeItemGroup,
  Path,
  ITreeItemCategory,
} from './services/menu.types'
import { EditInput } from './EditInput'
import * as admin from 'store/Admin'
import { Modal } from 'components/Modal'
import { EditMenuNameModal } from './EditMenuNameModal'
import { DeleteMenuModal } from './DeleteMenuModal'
import { TDispatch } from 'store'

import { useModalState } from 'hooks/useModalState.hook'
import { AddDbModal } from 'containers/MenusAdmin/AddDbModal'
import { SeparatorOptions } from 'containers/MenusAdmin/Options/SeparatorOptions'
import { useAreGroupsCollapsed } from 'containers/MenusAdmin/useAreGroupsCollapsed.hook'
import { CategoryOptions } from 'containers/MenusAdmin/Options/CategoryOptions'
import { DatabaseOptions } from 'containers/MenusAdmin/Options/DatabaseOptions'

import './MenuColumn.scss'

export interface IProps {
  menu: IMenuLayout
  allMenus: IMenuLayout[]
  treeData: ITree
  databases: IDatabase[]
  onChange: (originalLayoutName: string, data: ITree) => void
}

export interface IActionProps {
  renameMenu: (menu: IMenuLayout, newLabel: string) => Promise<void>
  deleteMenu: (menu: IMenuLayout, newName: string) => Promise<void>
}

export const MenuColumn: React.FC<IProps & IActionProps> = props => {
  const {
    menu,
    treeData,
    databases,
    allMenus,
    onChange: propsOnChange,
    renameMenu: propsRenameMenu,
    deleteMenu: propsDeleteMenu,
  } = props
  const { block, element } = bindBem('MenuColumn')

  const [addDbConfig, setAddDbConfig] = React.useState<{
    path: Path
    node: ITreeItemGroup
  }>()

  const [editMenuNameModalOpen, openEditMenuNameModal, closeEditMenuNameModal] =
    useModalState()
  const [deleteMenuModalOpen, openDeleteMenuModal, closeDeleteMenuModal] = useModalState()
  const [addDbModalOpen, openAddDbModal, closeAddDbModal] = useModalState()

  const prepareAndOpenAddDbModal = React.useCallback(
    (node: ITreeItemGroup, path: Path) => {
      setAddDbConfig({ node, path })
      openAddDbModal()
    },
    []
  )

  const onChange = React.useCallback(
    data => {
      propsOnChange(menu.name, data)
    },
    [propsOnChange, menu]
  )

  const canDrop = React.useCallback(
    (data: OnDragPreviousAndNextLocation & NodeData): boolean =>
      menuService.checkIfNodeCanBeDropped(
        data.node as ITreeItem,
        data.nextParent as ITreeItem
      ),
    []
  )

  const addNewGroup = React.useCallback(
    (path: Path, addInside = false) => {
      onChange(menuService.addNewGroup(treeData, path, addInside))
    },
    [treeData, onChange]
  )

  const addNewCategory = React.useCallback(
    (path: Path) => {
      onChange(menuService.addNewCategory(treeData, path))
    },
    [treeData, onChange]
  )

  const enterEditGroupNameMode = React.useCallback(
    (node: ITreeItemGroup, path: Path) => {
      onChange(menuService.enterEditGroupNameMode(treeData, node, path))
    },
    [treeData, onChange]
  )

  const enterEditCategoryNameMode = React.useCallback(
    (node: ITreeItemCategory, path: Path) => {
      onChange(menuService.enterEditCategoryNameMode(treeData, node, path))
    },
    [treeData, onChange]
  )

  const changeGroupName = React.useCallback(
    (node: ITreeItemGroup, path: Path, newName: string) => {
      onChange(menuService.changeGroupName(treeData, node, path, newName))
    },
    [treeData, onChange]
  )

  const changeCategoryName = React.useCallback(
    (node: ITreeItemCategory, path: Path, newName: string) => {
      onChange(menuService.changeCategoryName(treeData, node, path, newName))
    },
    [treeData, onChange]
  )

  const deleteItem = React.useCallback(
    (path: Path) => {
      onChange(menuService.deleteItem(treeData, path))
    },
    [treeData, onChange]
  )

  const addDb = React.useCallback(
    (dbName: string) => {
      const withExpandedGroup = menuService.setTreeItemExpanded(
        treeData,
        addDbConfig.node,
        addDbConfig.path,
        true
      )
      onChange(menuService.addDb(withExpandedGroup, addDbConfig.path, dbName))
      closeAddDbModal()
    },
    [addDbConfig, treeData, onChange]
  )

  const addSeparator = React.useCallback(
    (node: ITreeItemGroup, path: Path) => {
      const withExpandedGroup = menuService.setTreeItemExpanded(
        treeData,
        node,
        path,
        true
      )
      onChange(menuService.addSeparator(withExpandedGroup, path))
    },
    [treeData, onChange]
  )

  const renameMenu = React.useCallback(
    (newName: string) => {
      propsRenameMenu(menu, newName).then(() => {
        closeEditMenuNameModal()
      })
    },
    [menu]
  )

  const deleteMenu = React.useCallback(
    (newName: string) => {
      propsDeleteMenu(menu, newName).then(() => {
        closeDeleteMenuModal()
      })
    },
    [menu]
  )

  const toggleCollapseByDefault = React.useCallback(
    (node: ITreeItemGroup, path: Path) => {
      onChange(menuService.toggleCollapseByDefault(treeData, node, path))
    },
    [treeData, onChange]
  )

  const toggleTreeItemExpand = React.useCallback(
    (node: ITreeItem, path: Path) => {
      onChange(menuService.toggleTreeItemExpand(treeData, node, path))
    },
    [treeData, onChange]
  )

  const collapseAllGroups = React.useCallback(() => {
    onChange(menuService.collapseGroups(treeData))
  }, [treeData, onChange])

  const expandAllGroups = React.useCallback(() => {
    onChange(menuService.expandGroups(treeData))
  }, [treeData, onChange])

  const generateMenuColumnNodeProps = React.useCallback(
    ({ node, path, parentNode }: ExtendedNodeData): { [index: string]: any } => {
      const entryNode = node as ITreeItem

      return {
        title: menuService.generateTitle(entryNode),
        toolbarRenderer: () => {
          switch (entryNode.original.type) {
            case 'group':
              const groupNode = entryNode as ITreeItemGroup
              return (
                <GroupOptions
                  groupNode={groupNode}
                  addNewGroup={() => addNewGroup(path)}
                  addNewCategory={() => addNewCategory(path)}
                  addNewDb={() => prepareAndOpenAddDbModal(groupNode, path)}
                  editGroupName={() => enterEditGroupNameMode(groupNode, path)}
                  deleteGroup={() => deleteItem(path)}
                  toggleCollapseByDefault={() => toggleCollapseByDefault(groupNode, path)}
                  addNewSeparator={() => addSeparator(groupNode, path)}
                  toggleTreeItemExpand={() => toggleTreeItemExpand(groupNode, path)}
                  isCollapsedByDefault={!groupNode.original.expanded}
                  canAddCategory={!parentNode}
                />
              )
            case 'separator':
              return <SeparatorOptions deleteSeparator={() => deleteItem(path)} />
            case 'category':
              const categoryNode = entryNode as ITreeItemCategory
              return (
                <CategoryOptions
                  addNewGroup={() => addNewGroup(path, true)}
                  editCategoryName={() => enterEditCategoryNameMode(categoryNode, path)}
                  deleteCategory={() => deleteItem(path)}
                />
              )
            case 'db':
              return <DatabaseOptions deleteDatabase={() => deleteItem(path)} />
            default:
              return null
          }
        },
        editingNameRenderer: () => {
          switch (entryNode.original.type) {
            case 'group':
              return (
                <EditInput
                  startValue={entryNode.original.label}
                  onSave={(newName: string) =>
                    changeGroupName(entryNode as ITreeItemGroup, path, newName)
                  }
                />
              )
            case 'category':
              return (
                <EditInput
                  startValue={entryNode.original.label}
                  onSave={(newName: string) =>
                    changeCategoryName(entryNode as ITreeItemCategory, path, newName)
                  }
                />
              )
            default:
              return null
          }
        },
      }
    },
    [
      addNewGroup,
      changeGroupName,
      enterEditGroupNameMode,
      deleteItem,
      toggleCollapseByDefault,
      addSeparator,
      toggleTreeItemExpand,
      addNewCategory,
      changeCategoryName,
    ]
  )

  const areAllGroupsCollapsed = useAreGroupsCollapsed(treeData)

  return (
    <div className={block()}>
      <ColumnHeader
        menu={menu}
        areAllGroupsCollapsed={areAllGroupsCollapsed}
        renameMenuClick={openEditMenuNameModal}
        deleteMenuClick={openDeleteMenuModal}
        expandAllClick={expandAllGroups}
        collapseAllClick={collapseAllGroups}
      />
      <div className={element('Content')}>
        <SortableTree
          treeData={treeData}
          onChange={onChange}
          theme={theme}
          generateNodeProps={generateMenuColumnNodeProps}
          canNodeHaveChildren={menuService.checkIfNodeCanHaveChildren}
          canDrop={canDrop}
        />
      </div>
      <Modal isOpen={editMenuNameModalOpen} onClose={closeEditMenuNameModal}>
        <EditMenuNameModal
          editMenuName={renameMenu}
          menus={allMenus}
          currentName={menu.label}
          onClose={closeEditMenuNameModal}
        />
      </Modal>
      <Modal isOpen={deleteMenuModalOpen} onClose={closeDeleteMenuModal}>
        <DeleteMenuModal
          deleteMenu={deleteMenu}
          replacementMenus={allMenus.filter(m => m.name !== menu.name)}
          onClose={closeDeleteMenuModal}
        />
      </Modal>
      <Modal isOpen={addDbModalOpen} onClose={closeAddDbModal}>
        <AddDbModal
          onClose={closeAddDbModal}
          onAddDb={addDb}
          treeData={treeData}
          databases={databases}
        />
      </Modal>
    </div>
  )
}

const mapDispatchToProps = (dispatch: TDispatch): IActionProps => ({
  renameMenu: (menu: IMenuLayout, newLabel: string) =>
    dispatch(admin.renameMenu(menu, newLabel)),
  deleteMenu: (menu: IMenuLayout, newName: string) =>
    dispatch(admin.deleteMenu(menu, newName)),
})

export default connect(null, mapDispatchToProps)(MenuColumn)
