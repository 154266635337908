// eslint-disable max-lines
import { TDispatch } from '.'
import {
  LOGIN_ERROR,
  MFA_EXPIRED,
  MFA_INVALID,
  MFA_SENT_RAW,
  ORG_INACTIVE,
  RESTRICTED_LOGIN_ERROR,
  USER_FORM_TOASTS,
  USER_INACTIVE,
} from 'messages'
import { fetchMenu } from 'store/Menu'
import { ActionType, createAction, getType } from 'typesafe-actions'

import * as ga from '../analytics'
import { fetchOrganization } from '../api2/admin'
import { CLIENT } from '../api/config'
import * as users from '../api/users'
import * as sentry from '../sentry'
import { RequestError } from './../api/config'
import { fetchDatabases } from './Database'
import { ACTIONS as ROOT_ACTIONS } from './Root'
import { setLocalToken } from '../api/users'
import { NEW_PROD_URL } from 'config'

const INVITES_URL = '/v2/invites'

export const ACTIONS = {
  setUser: createAction('SET_USER', resolve => (user: IUser) => resolve(user)),
  openToast: createAction('OPEN_TOAST', resolve => (toast: IToast) => resolve(toast)),
  closeToast: createAction('CLOSE_TOAST'),
  failLogin: createAction('FAIL_LOGIN', resolve => (flag: boolean) => resolve(flag)),
  mfaRequired: createAction('MFA_REQUIRED', resolve => (flag: boolean) => resolve(flag)),
  loginPending: createAction(
    'PENDING_LOGIN',
    resolve => (flag: boolean) => resolve(flag)
  ),
  setCredentials: createAction(
    'SET_CREDENTIALS',
    resolve => (credentials: ILoginForm) => resolve(credentials)
  ),
  setSignatureHash: createAction(
    'SET_SIGNATURE_HASH',
    resolve => (hash: string) => resolve(hash)
  ),
  setSessionToken: createAction(
    'SET_SESSION_TOKEN',
    resolve => (token: string) => resolve(token)
  ),
  setLoginError: createAction(
    'SET_LOGIN_ERROR',
    resolve => (message: string) => resolve(message)
  ),
}

export type State = {
  user: IUser
  isLoggedIn: boolean
  toast: IToast
  loginFailed: boolean
  mfaRequired: boolean
  loginPending: boolean
  signatureHash: string
  sessionToken: string
  loginError: string
}

const INITIAL_STATE = (): State => ({
  user: null,
  isLoggedIn: !!localStorage.getItem('sessionToken'),
  sessionToken: localStorage.getItem('sessionToken'),
  toast: {
    isOpen: false,
    title: '',
    subtitle: '',
    type: 'info',
    position: 'bottom-left',
    autoClose: true,
  },
  loginFailed: null,
  mfaRequired: null,
  loginPending: null,
  signatureHash: null,
  loginError: null,
})

export function reducer(state = INITIAL_STATE(), action: UsersAction): State {
  switch (action.type) {
    case getType(ACTIONS.setUser):
      return { ...state, user: { ...action.payload }, isLoggedIn: true }
    case getType(ACTIONS.openToast):
      return { ...state, toast: action.payload }
    case getType(ACTIONS.closeToast):
      return { ...state, toast: { ...state.toast, isOpen: false } }
    case getType(ACTIONS.mfaRequired):
      return { ...state, mfaRequired: Boolean(action.payload) }
    case getType(ACTIONS.loginPending):
      return { ...state, loginPending: Boolean(action.payload) }
    case getType(ACTIONS.failLogin):
      return { ...state, loginFailed: Boolean(action.payload) }
    case getType(ACTIONS.setSignatureHash):
      return { ...state, signatureHash: action.payload }
    case getType(ACTIONS.setSessionToken):
      return { ...state, sessionToken: action.payload }
    case getType(ACTIONS.setLoginError):
      return { ...state, loginError: action.payload }
    default:
      return state
  }
}

export const setSessionData =
  (sessionToken?: string, signatureHash?: string) => async (dispatch: TDispatch) => {
    setLocalToken(undefined, sessionToken)
    dispatch(ACTIONS.setSessionToken(sessionToken))
    dispatch(ACTIONS.setSignatureHash(signatureHash))
  }

export type UsersAction = ActionType<(typeof ACTIONS)[keyof typeof ACTIONS]>

export const resendInvite = (user: IUser) => async (dispatch: TDispatch) => {
  ga.track('auth', 'resendInvite')
  try {
    await CLIENT.put(`${INVITES_URL}/${user.id}`)
    dispatch(openToast({ title: USER_FORM_TOASTS.RESEND_SUCCESS }))
  } catch (e) {
    dispatch(openToast({ title: USER_FORM_TOASTS.RESEND_FAILED }))
  }
}

export function loginUserWithToken(sessionToken: string) {
  return async (dispatch: TDispatch) => {
    ga.track('auth', 'login-with-token')

    dispatch(ACTIONS.setLoginError(null))
    dispatch(ACTIONS.loginPending(true))

    try {
      dispatch(fetchMe(sessionToken))
    } catch (e) {
      dispatch(loginErrorHandler(e, sessionToken))
    }
  }
}

export function loginUserWithTokenAndOtp(
  sessionToken: string,
  signature: string,
  otp: string
) {
  return async (dispatch: TDispatch) => {
    ga.track('auth', 'login-with-token-and-otp')

    dispatch(ACTIONS.setLoginError(null))
    dispatch(ACTIONS.loginPending(true))
    dispatch(fetchMeWithOtp(sessionToken, signature, otp))
  }
}

export function resendOtp(sessionToken: string) {
  return async (dispatch: TDispatch) => {
    ga.track('auth', 'resendOtp')
    dispatch(ACTIONS.setLoginError(null))

    const {
      data: { signature_hash },
    } = await users.resendOtp(sessionToken)

    dispatch(
      openToast({
        title: MFA_SENT_RAW,
      })
    )

    dispatch(ACTIONS.setSignatureHash(signature_hash))
  }
}

export const loginErrorHandler =
  (error: RequestError, sessionToken: string) => async (dispatch: TDispatch) => {
    dispatch(ACTIONS.loginPending(false))

    const status = error?.response?.status
    const type = error?.response?.data?.type

    ga.track('auth', 'loginError', { status, type })

    if (status === 422) {
      dispatch(ACTIONS.failLogin(true))

      if (type === 'UserInactiveError') {
        dispatch(ACTIONS.setLoginError(USER_INACTIVE))
      } else if (type === 'OrganizationInactiveError') {
        dispatch(ACTIONS.setLoginError(ORG_INACTIVE))
      } else if (type === 'WrongIPError') {
        dispatch(ACTIONS.setLoginError(RESTRICTED_LOGIN_ERROR))
      } else {
        dispatch(ACTIONS.setLoginError(LOGIN_ERROR))
      }
    } else if (status === 400) {
      dispatch(ACTIONS.setLoginError(LOGIN_ERROR))
      dispatch(ACTIONS.failLogin(true))
    } else if (status === 403) {
      dispatch(ACTIONS.mfaRequired(true))
      dispatch(resendOtp(sessionToken))
    } else if (status === 401) {
      if (type === 'MFANotValid') {
        dispatch(ACTIONS.setLoginError(MFA_INVALID))
        dispatch(ACTIONS.failLogin(true))
      } else if (type === 'MFAExpired') {
        dispatch(ACTIONS.setLoginError(MFA_EXPIRED))
        dispatch(ACTIONS.failLogin(true))
      }
    } else {
      throw error
    }
  }

export function logout() {
  return async (dispatch: TDispatch) => {
    dispatch(ACTIONS.loginPending(true))
    ga.track('auth', 'logout')
    ga.resetTrackingFields()
    await users.logout()
    sentry.setUserScope(null)
    setSessionData(undefined, undefined)
    dispatch(ROOT_ACTIONS.resetApp({ keepMaintenance: true }))
  }
}

export function logoutWithToast(toast: Partial<IToast>, timeout = 3000) {
  return async (dispatch: TDispatch) => {
    dispatch(openToast(toast))
    setTimeout(() => dispatch(logout()), timeout)
  }
}

export function fetchMe(sessionToken?: string) {
  return async (dispatch: TDispatch) => {
    let user: IUser = null

    try {
      user = await users.fetchUser(sessionToken)

      if (user.settings.isMigrated) {
        return window.location.assign(NEW_PROD_URL)
      }

      users.setLocalToken(user.id.toString(), user.sessionToken)
    } catch (err) {
      users.removeLocalToken()
      dispatch(ROOT_ACTIONS.resetApp({ keepMaintenance: true }))
    }

    if (!user) {
      return
    }

    dispatch(setUser(user))
  }
}

export function fetchMeWithOtp(sessionToken?: string, signature?: string, otp?: string) {
  return async (dispatch: TDispatch) => {
    let user: IUser = null

    try {
      user = await users.fetchUserWithOtpVerification(sessionToken, {
        signature_hash: signature,
        otp,
      })
    } catch (e) {
      dispatch(loginErrorHandler(e, sessionToken))
    }

    users.setLocalToken(user.id.toString(), user.sessionToken)

    if (!user) {
      return
    }

    dispatch(setUser(user))
  }
}

export const trackUserOrganization = async (user: IUser) => {
  const org = await fetchOrganization(user.organizationId)
  ga.setTrackingFields(user.id, org.id, org.name, org.type, user.email)
}

let timeoutId: any

export function openToast(toast: Partial<IToast>) {
  return async (dispatch: TDispatch) => {
    const defaultToast: IToast = {
      isOpen: true,
      title: '',
      subtitle: '',
      type: 'info',
      position: 'bottom-left',
      autoClose: true,
    }
    clearTimeout(timeoutId)
    const fullToast = { ...defaultToast, ...toast, isOpen: true }
    dispatch(ACTIONS.openToast(fullToast))
    if (fullToast.autoClose) {
      timeoutId = setTimeout(() => dispatch(ACTIONS.closeToast()), 5000)
    }
  }
}

export const setUser = (user: IUser) => {
  return async (dispatch: TDispatch) => {
    sentry.setUserScope(user)

    dispatch(ACTIONS.setUser(user))

    window.zE?.(() =>
      window.zE('webWidget', 'identify', {
        name: user.username,
        email: user.email,
      })
    )

    dispatch(fetchDatabases(user))
    dispatch(fetchMenu(user))

    trackUserOrganization(user)
  }
}
