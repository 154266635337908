import * as React from 'react'
import { bindBem } from 'bem'

import { ADMIN } from 'messages'
import { IconSubMenuOption } from 'containers/MenusAdmin/Options/IconSubMenuOption'
import { SubMenuItem, Theme, Type } from 'containers/MenusAdmin/Options/SubMenuItem'
import { IconOption } from 'containers/MenusAdmin/Options/IconOption'
import { ITreeItemGroup } from 'containers/MenusAdmin/services/menu.types'

import ThreeDotIcon from 'static/three-dots-menu.svg'
import PencilIcon from 'static/pencil.svg'
import PlusIcon from 'static/plus.svg'
import ChevronDownIcon from 'static/chevron-down-reverse.svg'
import ChevronUpIcon from 'static/chevron-up-reverse.svg'

import './GroupOptions.scss'

export enum Options {
  ADD_NEW_GROUP = 'add-new-group',
  ADD_NEW_CATEGORY = 'add-new-category',
  ADD_NEW_SEPARATOR = 'add-new-separator',
  DELETE_GROUP = 'delete-group',
  COLLAPSE_BY_DEFAULT = 'collapse-by-default',
}

export interface IProps {
  groupNode: ITreeItemGroup
  addNewGroup: () => void
  addNewCategory: () => void
  addNewDb: () => void
  editGroupName: () => void
  deleteGroup: () => void
  toggleCollapseByDefault: () => void
  toggleTreeItemExpand: () => void
  addNewSeparator: () => void
  isCollapsedByDefault: boolean
  canAddCategory: boolean
}

export const GroupOptions: React.FC<IProps> = ({
  groupNode,
  addNewGroup,
  addNewCategory,
  addNewDb,
  editGroupName,
  deleteGroup,
  addNewSeparator,
  toggleCollapseByDefault,
  isCollapsedByDefault,
  toggleTreeItemExpand,
  canAddCategory,
}) => {
  const { block } = bindBem('GroupOptions')

  const handleClick = React.useCallback(
    (name: Options) => {
      switch (name) {
        case Options.ADD_NEW_GROUP:
          addNewGroup()
          break
        case Options.DELETE_GROUP:
          deleteGroup()
          break
        case Options.ADD_NEW_SEPARATOR:
          addNewSeparator()
          break
        case Options.COLLAPSE_BY_DEFAULT:
          toggleCollapseByDefault()
          break
        case Options.ADD_NEW_CATEGORY:
          addNewCategory()
          break
      }
    },
    [addNewGroup, deleteGroup, addNewSeparator, toggleCollapseByDefault]
  )

  return (
    <div className={block()}>
      <IconOption
        icon={PlusIcon}
        onClick={addNewDb}
        tooltip={ADMIN.MENUS.GROUP_OPTIONS.ADD_DB_TOOLTIP}
      />
      <IconOption
        icon={PencilIcon}
        onClick={editGroupName}
        tooltip={ADMIN.MENUS.GROUP_OPTIONS.EDIT_NAME_TOOLTIP}
      />
      <IconOption
        icon={groupNode.expanded ? ChevronUpIcon : ChevronDownIcon}
        onClick={toggleTreeItemExpand}
        tooltip={
          groupNode.expanded
            ? ADMIN.MENUS.GROUP_OPTIONS.COLLAPSE
            : ADMIN.MENUS.GROUP_OPTIONS.EXPAND
        }
      />
      <IconSubMenuOption
        icon={ThreeDotIcon}
        tooltip={ADMIN.MENUS.GROUP_OPTIONS.MORE_OPTIONS_TOOLTIP}
        onClick={handleClick}
      >
        <SubMenuItem
          text={ADMIN.MENUS.GROUP_OPTIONS.ADD_NEW_GROUP}
          name={Options.ADD_NEW_GROUP}
        />
        {canAddCategory && (
          <SubMenuItem
            text={ADMIN.MENUS.GROUP_OPTIONS.ADD_NEW_CATEGORY}
            name={Options.ADD_NEW_CATEGORY}
          />
        )}
        <SubMenuItem
          text={ADMIN.MENUS.GROUP_OPTIONS.ADD_NEW_SEPARATOR}
          name={Options.ADD_NEW_SEPARATOR}
        />
        <SubMenuItem
          text={ADMIN.MENUS.GROUP_OPTIONS.COLLAPSE_BY_DEFAULT}
          name={Options.COLLAPSE_BY_DEFAULT}
          type={Type.TICK}
          selected={isCollapsedByDefault}
        />
        <SubMenuItem
          text={ADMIN.MENUS.GROUP_OPTIONS.DELETE_GROUP}
          name={Options.DELETE_GROUP}
          theme={Theme.DANGER}
          disabledTooltip={ADMIN.MENUS.GROUP_OPTIONS.DELETE_GROUP_DISABLED_TOOLTIP}
        />
      </IconSubMenuOption>
    </div>
  )
}
