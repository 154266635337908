import { subMonths, format } from 'date-fns'
import { saveAs } from 'file-saver'
import { parse as json2csv } from 'json2csv'

import { ADMIN } from './../../messages'

export const txtToList = (file: File) => {
  return new Promise<string[]>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = evt => {
      const lines = String(evt.target.result)
        .split(/\r?\n|\r/)
        .map(line => line.trim())
      resolve(lines)
    }
    reader.onerror = reject
    reader.readAsText(file)
  })
}

export const listToTxt = (list: string[], filename = 'Haver-selections.txt') => {
  const blob = new Blob([list.join('\n')], {
    type: 'text/plain',
  })
  saveAs(blob, filename)
}

export const list2csv = (
  list: any[],
  fields: string[],
  filename = 'Haver-export.csv'
) => {
  const csv = json2csv(list, { fields })

  const blob = new Blob([csv], {
    type: 'text/csv',
  })
  saveAs(blob, filename)
}

export enum OrgStatus {
  Active,
  ExpiringSoon,
  GracePeriod,
  Expired,
  Deactivated,
}

// Determines status for a given org
//
// active
// expiring soon (which will be displayed 1 month before the renewal date)
// grace period until mm/dd (grace period starts on the renewal date and lasts 2 months)
// expired (when the grace period ends)
// deactivated (when superadmin deactivated the company manually)
//
// Returns OrgStatus Enum
export const orgStatusResolver = (
  { status, softTerminationDate, hardTerminationDate }: IOrganization,
  at: Date = new Date()
): OrgStatus => {
  if (!status) {
    return OrgStatus.Deactivated
  }
  if (!softTerminationDate) {
    return OrgStatus.Active
  }

  const monthBeforeSoftTerminationDate = subMonths(softTerminationDate, 1)

  if (at >= hardTerminationDate) {
    return OrgStatus.Expired
  }
  if (at >= monthBeforeSoftTerminationDate && at < softTerminationDate) {
    return OrgStatus.ExpiringSoon
  }
  if (at >= softTerminationDate && at < hardTerminationDate) {
    return OrgStatus.GracePeriod
  }
  return OrgStatus.Active
}

export const orgStatusAsText = (status: OrgStatus, org?: IOrganization): string => {
  switch (+status) {
    case OrgStatus.Active:
      return ADMIN.STATUS.ACTIVE
    case OrgStatus.Deactivated:
      return ADMIN.STATUS.DEACTIVATED
    case OrgStatus.Expired:
      return ADMIN.STATUS.EXPIRED
    case OrgStatus.ExpiringSoon:
      return ADMIN.STATUS.EXPIRING_SOON
    case OrgStatus.GracePeriod:
      return org
        ? ADMIN.STATUS.GRACE_PERIOD_UNTIL(format(org.hardTerminationDate, 'MM/dd'))
        : ADMIN.STATUS.GRACE_PERIOD
  }
}

export const orgStatusClassName = (status: OrgStatus): string => {
  switch (+status) {
    case OrgStatus.Active:
      return 'orgStatusActive'
    case OrgStatus.Deactivated:
      return 'orgStatusDeactivated'
    case OrgStatus.Expired:
      return 'orgStatusExpired'
    case OrgStatus.ExpiringSoon:
      return 'orgStatusExpiringSoon'
    case OrgStatus.GracePeriod:
      return 'orgStatusGracePeriod'
  }
}

export enum TokenStatus {
  ACTIVE,
  EXPIRED,
  DEACTIVATED,
}

export const tokenStatusAsText = (status: TokenStatus): string => {
  switch (status) {
    case TokenStatus.ACTIVE:
      return ADMIN.TOKENS.STATUS.ACTIVE
    case TokenStatus.DEACTIVATED:
      return ADMIN.TOKENS.STATUS.DEACTIVATED
    case TokenStatus.EXPIRED:
      return ADMIN.TOKENS.STATUS.EXPIRED
  }
}
