import elementResizeDetectorMaker from 'element-resize-detector'
import { debounce } from 'lodash'
import * as React from 'react'
import * as ReactDOM from 'react-dom'

interface IBaseProps {
  width: number
  height: number
}

export const withSize = (Component: React.ComponentType<any>) => {
  return class Inner extends React.Component<any, IBaseProps> {
    state: IBaseProps = { width: 0, height: 0 }
    erd: elementResizeDetectorMaker.Erd
    elem: HTMLElement
    private onUpdate = debounce(() => {
      const width = this.elem.clientWidth
      const height = this.elem.clientHeight
      this.setState({ width, height })
    }, 16)

    render() {
      return <Component {...this.props} size={this.state} />
    }
    componentDidMount() {
      // eslint-disable-next-line react/no-find-dom-node
      this.elem = ReactDOM.findDOMNode(this) as HTMLElement
      if (this.elem === null) {
        return
      }
      this.erd = elementResizeDetectorMaker({ strategy: 'scroll' })
      this.erd.listenTo(this.elem, this.onUpdate)
      this.setState({
        width: this.elem.clientWidth,
        height: this.elem.clientHeight,
      })
    }
    componentWillUnmount() {
      this.onUpdate.flush()
      if (this.erd) {
        this.erd.uninstall(this.elem)
      }
    }
  }
}
