// eslint-disable max-len

import * as React from 'react'

export const IconHelp = ({ color }: any) => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
    <g id="Screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Styleguide" transform="translate(-697.000000, -489.000000)">
        <g id="Group-2" transform="translate(116.000000, 250.000000)">
          <g id="Group" transform="translate(479.000000, 0.000000)">
            <g id="Group-5" transform="translate(44.000000, 159.000000)">
              <g id="Group-4" transform="translate(21.000000, 81.000000)">
                <g id="icon---Help" transform="translate(38.000000, 0.000000)">
                  <circle
                    id="Oval-Copy"
                    stroke={color}
                    strokeWidth="2"
                    fill="none"
                    cx="8"
                    cy="8"
                    r="8"
                  />
                  <path
                    d="M8.00113438,13.3584 C8.68596747,13.3584 9.24113438,12.8032331 9.24113438,12.1184 C9.24113438,11.4335669 8.68596747,10.8784 8.00113438,10.8784 C7.31630129,10.8784 6.76113438,11.4335669 6.76113438,12.1184 C6.76113438,12.8032331 7.31630129,13.3584 8.00113438,13.3584 Z"
                    id="Oval"
                    fill={color}
                    fillRule="nonzero"
                  />
                  <path
                    d="M6.60458705,4.67447467 C7.4274442,3.86568533 9.21139848,3.88002133 9.56138705,5.271808 C10.0161528,7.07874133 7.97601563,7.12533333 7.97601563,9.81333333"
                    id="Shape"
                    stroke={color}
                    strokeWidth="2"
                    fill="none"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const IconHelpBig = ({ color }: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M12 21a9 9 0 1 1 0-18 9 9 0 0 1 0 18zm0-2a7 7 0 1 0 0-14 7 7 0 0 0 0 14z"
      />
      <path
        fill={color}
        fillRule="nonzero"
        d="M12.001 17.358a1.24 1.24 0 1 0 0-2.48 1.24 1.24 0 0 0 0 2.48z"
      />
      <path
        stroke={color}
        strokeWidth="2"
        d="M10.605 8.674c.822-.808 2.606-.794 2.956.598.455 1.807-1.585 1.853-1.585 4.541"
      />
    </g>
  </svg>
)

export const ICON_HELP = <IconHelp color="#007CC3" />
export const ICON_HELP_LIGHT = <IconHelp color="#FFFFFF" />

export const ICON_HELP_BIG = <IconHelpBig color="#3FA2DB" />
