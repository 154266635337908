import Axios from 'axios'
import pako from 'pako'

import { IRootState } from '.'
import * as API2 from '../api2/series'

import { dumpSeriesState } from '../services/series'
import {
  getSvgBlob,
  getPngBlob,
  getChartNode,
  makeNodePrintable,
} from '../services/export'
import { AsyncAction, ACTIONS } from './Series'
import { openToast } from './User'

const uploadSVG = (config: IImageUploadPayload, linkId: string) => {
  const blob = getSvgBlob(makeNodePrintable(getChartNode()))
  API2.uploadImage(blob, config, `${linkId}.svg`)
}

const uploadPNG = (config: IImageUploadPayload, linkId: string) => {
  const node = makeNodePrintable(getChartNode())
  const callback = (blob: Blob) => API2.uploadImage(blob, config, `${linkId}.png`)
  getPngBlob(node, callback)
}

const uploadJson = (data: ISeriesDump, config: IImageUploadPayload, linkId: string) => {
  const packed = pako.gzip(JSON.stringify(data))

  const blob = new Blob([packed], { type: 'application/json' })
  API2.uploadImage(blob, config, `${linkId}.json.gz`)
}

const uploadData = async (response: IShareLinkResponse, data: ISeriesDump) => {
  const { id } = response
  await Promise.all([
    uploadSVG(response.uploadSvgUrl as IImageUploadPayload, id),
    uploadPNG(response.uploadPngUrl as IImageUploadPayload, id),
  ])
  uploadJson(data, response.uploadJsonUrl as IImageUploadPayload, id)
}

export const generateSharedLink =
  (): AsyncAction => async (dispatch: any, getState: () => IRootState) => {
    const seriesStore = getState().series
    const seriesStateDump = dumpSeriesState(seriesStore)

    const response = await API2.createLinkShareData()
    await uploadData(response, seriesStateDump)

    dispatch(ACTIONS.setLink(response))
  }

export const updateSharedLink =
  (): AsyncAction => async (dispatch: any, getState: () => IRootState) => {
    const seriesStore = getState().series
    const seriesStateDump = dumpSeriesState(seriesStore)

    const response = await API2.updateLinkShareData(seriesStore.link.id)
    await uploadData(response, seriesStateDump)

    dispatch(ACTIONS.setLink(response))
    dispatch(
      openToast({
        title: 'Link successfully updated.',
      })
    )
  }

export const loadSeriesFromLink =
  (linkId: string): AsyncAction =>
  async (dispatch: any) => {
    dispatch(ACTIONS.setLoadingSeriesId('LOADING_SNAPSHOT'))

    try {
      const response = await API2.getSharedLinkData(linkId)
      const { data } = await Axios.get(response.jsonUrl, {
        headers: { 'Content-Encoding': 'gzip' },
      })

      dispatch(ACTIONS.loadSeriesDump(data))
      dispatch(ACTIONS.setLink(response))
    } catch (error) {
      if (error?.response?.status === 403) {
        dispatch(
          openToast({
            title: 'Selected series is not available.',
            subtitle: "You don't have a permissions to database which series belongs to",
            type: 'error',
          })
        )
      } else {
        // Re-throw to manage with other errors, like 500, on the global level
        throw error
      }
    } finally {
      dispatch(ACTIONS.setLoadingSeriesId(null))
    }
  }
