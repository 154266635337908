import * as React from 'react'
import * as FauxDOM from 'react-faux-dom'
import * as d3 from 'd3'
import { XScale, renderRecession } from '../../services/hd3'

export interface IProps {
  xScale: XScale
  height: number
  recessions: IRecession[]
  recessionType: IRecessionType
}

const Recesion = ({ xScale, height, recessions, recessionType }: IProps) => {
  const svg = FauxDOM.createElement('g')
  const selection = d3.select(svg)

  recessions.forEach(r => renderRecession(selection, xScale, height, r, recessionType))

  return <>{React.Children.map(svg.toReact(), c => c.props.children)}</>
}

export default React.memo(Recesion)
