import * as React from 'react'

export const ICON_SET_TO_DEFAULT = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14">
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#3FA2DB"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path d="M6.594 6.995l3-3-3-3" />
      <path d="M9.594 3.995h-4a4 4 0 0 0 0 8h4M12.5 4.035h4M12.5 8.035h4M12.5 12.035h4" />
    </g>
  </svg>
)
