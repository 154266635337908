// eslint-disable max-len

import * as React from 'react'

export const RhoIconD =
  'M9.234 5.878c0-.614.079-1.054.234-1.307.134-.217.39-.323.78-.323.64 0 1.21.291 1.745.89.534.596.804 1.427.804 2.47 0 .812-.195 1.472-.582 1.958-.378.475-.833.706-1.39.706-.471 0-.847-.142-1.148-.435-.298-.29-.443-.713-.443-1.29V5.877zm4.42-1.829C13.025 3.353 12.185 3 11.159 3c-.938 0-1.805.327-2.578.974-.786.657-1.184 1.671-1.184 3.011v4.493c0 1.205-.097 2.111-.288 2.694L7 14.5h1.934l.043-.197c.17-.779.257-1.743.257-2.865v-.599c.493.263 1.01.395 1.542.395 1.002 0 1.9-.453 2.67-1.345.763-.882 1.149-1.937 1.149-3.135 0-1.105-.316-2.015-.94-2.705z'

const RhoIcon = ({ color }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
      <path fill={color} fillRule="evenodd" d={RhoIconD} />
    </svg>
  )
}

export const RHO_ICON = <RhoIcon color="#788596" />
export const RHO_ICON_LIGHT = <RhoIcon color="#FFFFFF" />
