import { differenceInYears, parse } from 'date-fns'
import { Formik } from 'formik'
import * as _ from 'lodash'
import React from 'react'
import * as Yup from 'yup'

import { bindBem } from '../../bem'
import { FUNCTIONS_DIALOG } from '../../messages'
import { Input, Labeled } from '../Input'
import { IFormProps, InnerForm } from './AdvancedFunctionUtils'
import { AutoSave } from './AutoSave'
import { errorMessages, regExps, validators } from './FnFormUtils'

const { FORMAT_VALIDATION_MESSAGE, AT_LEAST_3_YEARS_REQUIRED, DATE_AFTER_2099 } =
  FUNCTIONS_DIALOG

const validateYear = (value: string) =>
  validators.year(regExps.month)(value) || validators.year(regExps.quarter)(value)
const validateMonthOrQuarter = (value: string) =>
  validators.month(regExps.month)(value) || validators.quarter(regExps.quarter)(value)

const testRegexp = (value: string) =>
  !value || regExps.month.test(value) || regExps.quarter.test(value)

const VALIDATION_SCHEMA = Yup.object().shape({
  beginDate: Yup.string()
    .test(
      'match-full',
      FORMAT_VALIDATION_MESSAGE(FUNCTIONS_DIALOG.SA_BEGIN_DATE_LABEL, [
        'YYYYMM',
        'YYYYQ',
      ]),
      testRegexp
    )
    .test('match', errorMessages.year, validateYear)
    .test('match', errorMessages.month, validateMonthOrQuarter)
    .test('required', FUNCTIONS_DIALOG.SA_BEGIN_DATE_REQUIRED, function (value) {
      // eslint-disable-next-line no-invalid-this
      return !(this.parent.endDate && !value)
    })
    .test('isBefore2100', DATE_AFTER_2099, value => {
      return isBefore2100(value)
    }),
  endDate: Yup.string()
    .test(
      'match-full',
      FORMAT_VALIDATION_MESSAGE(FUNCTIONS_DIALOG.SA_END_DATE_LABEL, ['YYYYMM', 'YYYYQ']),
      testRegexp
    )
    .test('match', errorMessages.year, validateYear)
    .test('match', errorMessages.month, validateMonthOrQuarter)
    .test('threeYearsApart', AT_LEAST_3_YEARS_REQUIRED, function (value) {
      // eslint-disable-next-line no-invalid-this
      return isThreeYearsApart(this.parent.beginDate, value)
    })
    .test('isBefore2100', DATE_AFTER_2099, value => {
      return isBefore2100(value)
    }),
})
const parseDate = (val: string) => parse(val, val.length === 5 ? 'yyyyQ' : 'yyyyMM', 0)

const isThreeYearsApart = (beg: number | undefined, end: number | undefined) => {
  if (beg === undefined || end === undefined) {
    return true
  }
  const startDate = parseDate(beg.toString())
  const endDate = parseDate(end.toString())
  return differenceInYears(endDate, startDate) >= 3
}
const isBefore2100 = (value: number | undefined) => {
  if (value === undefined) {
    return true
  }
  const date = parseDate(value.toString())
  return date.getFullYear() < 2100
}

export const FnSAForm = (props: IFormProps) => {
  const { state, setForm, setFormValidation, isNested } = props
  const beginDate = state.args[0] as number
  const endDate = state.args[1] as number

  const { block, element } = bindBem('FnSAForm')
  const initialValues = { beginDate, endDate }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values =>
        setForm({
          ...state,
          args: [values.beginDate, values.endDate].filter(Boolean),
        })
      }
      validationSchema={VALIDATION_SCHEMA}
      enableReinitialize
    >
      {({ errors, values, submitForm, handleChange }) => (
        <InnerForm
          {...props}
          className={block()}
          onSave={submitForm}
          isValid={_.isEmpty(errors)}
        >
          <div className="row">
            <div className={element('InputWrapper')}>
              <Labeled label={FUNCTIONS_DIALOG.SA_BEGIN_DATE_LABEL} />
              <Input
                value={values.beginDate}
                onChange={handleChange}
                type="number"
                name="beginDate"
                error={errors.beginDate && !!errors.beginDate}
              />
            </div>

            <div className={element('InputWrapper')}>
              <Labeled label={FUNCTIONS_DIALOG.SA_END_DATE_LABEL} />
              <Input
                value={values.endDate}
                onChange={handleChange}
                type="number"
                name="endDate"
                error={errors.endDate && !!errors.endDate}
              />
            </div>
          </div>
          {errors && (
            <div className="row">
              {errors.beginDate && (
                <div className={element('Error')}>{errors.beginDate}</div>
              )}
              {errors.endDate && <div className={element('Error')}>{errors.endDate}</div>}
            </div>
          )}

          <div className="row">
            <div className={element('Note')}>{FUNCTIONS_DIALOG.SA_NOTE}</div>
          </div>
          {!isNested && (
            <AutoSave
              onSubmit={submitForm}
              values={values}
              isValid={_.isEmpty(errors)}
              callback={() => setFormValidation(_.isEmpty(errors))}
            />
          )}
        </InnerForm>
      )}
    </Formik>
  )
}
