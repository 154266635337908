import * as React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { bindBem } from 'bem'
import { Input } from 'components/Input'
import { ADMIN } from 'messages'
import { Button } from 'components/Button'

import close from '../../static/close-dialog.svg'

import './EditMenuNameModal.scss'

export interface IProps {
  editMenuName: (name: string) => void
  onClose: () => void
  currentName: string
  menus: IMenuLayout[]
}

export interface IFormEditMenuName {
  name?: string
}

export const EditMenuNameModal: React.FC<IProps> = ({
  editMenuName,
  onClose,
  currentName,
  menus,
}) => {
  const { block, element } = bindBem('EditMenuNameModal')
  const [isSaving, setSaving] = React.useState(false)

  const validationSchema = React.useMemo(
    () =>
      Yup.object({
        name: Yup.string()
          .trim()
          .uppercase()
          .required(ADMIN.MENUS.MODALS.EDIT_NAME.VALIDATION.NAME_REQUIRED)
          .matches(
            /^[a-zA-Z0-9\s]+$/,
            ADMIN.MENUS.MODALS.EDIT_NAME.VALIDATION.NAME_FORMAT
          )
          .notOneOf(
            menus
              .filter(menu => menu.label !== currentName)
              .map(menu => menu.label.toUpperCase()),
            ADMIN.MENUS.MODALS.EDIT_NAME.VALIDATION.NAME_EXISTS
          ),
      }),
    [menus]
  )

  const onSubmit = React.useCallback((values: IFormEditMenuName) => {
    setSaving(true)
    editMenuName(values.name.trim())
  }, [])

  const formik = useFormik<IFormEditMenuName>({
    initialValues: {
      name: currentName,
    },
    validationSchema,
    onSubmit,
  })

  return (
    <div className={block()}>
      <img className={element('Close')} src={close} alt="close icon" onClick={onClose} />
      <div className={element('Title')}>{ADMIN.MENUS.MODALS.EDIT_NAME.TITLE}</div>
      <div className={element('Content')}>
        <div className={element('Name')}>
          <Input
            name="name"
            disabled={isSaving}
            onChange={formik.handleChange}
            onBlur={() => formik.setFieldTouched('name')}
            value={formik.values.name}
            error={formik.touched.name ? formik.errors.name : false}
            placeholder={ADMIN.MENUS.MODALS.EDIT_NAME.NAME_PLACEHOLDER}
          />
        </div>
      </div>
      <div className={element('Bottom')}>
        <div>
          <Button
            className={element('Submit')}
            onClick={formik.submitForm}
            disabled={isSaving || !formik.dirty || !formik.isValid || formik.isSubmitting}
            text={
              isSaving
                ? ADMIN.MENUS.MODALS.EDIT_NAME.SAVING
                : ADMIN.MENUS.MODALS.EDIT_NAME.SAVE
            }
            size="small"
            style="dark"
          />
        </div>
      </div>
    </div>
  )
}
