// eslint-disable max-len

import * as React from 'react'

const TLetterIcon = (props: { color: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
    <path
      fill={props.color}
      fillRule="nonzero"
      d="M4.109 8.823c-.371 0-.643-.075-.814-.226-.172-.15-.258-.366-.258-.647 0-.09.006-.175.017-.255.012-.08.028-.17.047-.272l.908-3.914H2.966l.124-.534h1.066l.41-1.716h1.055l-.399 1.716h1.635l-.123.534H5.111l-.75 3.228a6.757 6.757 0 0 0-.088.43 2.18 2.18 0 0 0-.035.355c0 .215.054.372.161.472.108.1.302.15.583.15.102 0 .244-.013.428-.039a2.01 2.01 0 0 0 .428-.096l-.1.427c-.273.106-.531.197-.773.273a2.862 2.862 0 0 1-.856.114z"
    />
  </svg>
)

export const T_LETTER_ICON = <TLetterIcon color="#FFFFFF" />
