// eslint-disable max-len
// eslint-disable max-lines

import * as d3 from 'd3'
import {
  scaleDiscontinuous,
  discontinuitySkipWeekends,
  ScaleDiscontinuous,
} from 'd3fc-discontinuous-scale'
import * as _ from 'lodash'
import * as fns from 'date-fns'
import { GRAPH } from '../messages'
import {
  pxPerMonths,
  COLORS,
  getMiddleDate,
  getStartDate,
  freqToInterval,
  Freq,
  getScaledFrequency,
  getDefaultTimeSpan,
  simplifyDataPointsLTTB,
} from './series'
import * as labelFormat from './dateformat'
import { chunkBy } from '../utils'
import {
  ITextBlockConfig,
  ITextBlockContent,
  ISVGRect,
  renderTextBlockLine,
  ITitleTextBlockConfig,
} from './hd3-text-block'
import { SANS_SERIF, MONOSPACE, COURIER_PRIME } from '../constants'
import { RhoIconD } from '../components/SVG/Rho'

export type XScale = ScaleDiscontinuous<number, number>
export type YScale = d3.ScaleLinear<number, number> | d3.ScaleLogarithmic<number, number>
export type ScatterScale = d3.ScaleLinear<number, number>
export type AnyXScale = XScale | ScatterScale

const SHADOW_FILTER_ID = 'drop-shadow'
export const CLIP_PATH_ID = 'clip-path'

export const LINE_TYPES: ISeriesType[] = [
  'LINE',
  'AREA',
  'STEPLINE',
  'DOTTEDLINE',
  'DASHEDLINE',
]

const getYAxis = (y: YScale) => d3.axisLeft(y).tickSize(0)

export const getLastDate = (series: IDataSeries[]) =>
  fns.max(series.filter(s => s.dataPoints.length > 0).map(s => _.last(s.dataPoints).date))

export function getZoomRange(
  series: IDataSeries[],
  endZoomDate?: Date,
  zoom?: Zoom,
  isScatter = false
): Date[] {
  const firstSeries = series[0]
  if (!firstSeries) {
    return []
  }
  let endDate: Date = endZoomDate
  if (!endDate) {
    const frequency = Math.min(...series.map(s => s.frequency), Freq.Monthly) as Frequency
    const interval = freqToInterval(frequency)
    endDate = getLastDate(series.filter(v => v.dataPoints.length))
    endDate = interval.offset(interval.floor(endDate))
  }

  const startDate = calculateStartDate(series, zoom, endDate)

  if (isScatter && series.length > 1) {
    const newEnd = _.last(series[1].dataPoints).date
    if (fns.isAfter(endDate, newEnd)) {
      return getZoomRange(series, newEnd, zoom, false)
    }
  }
  return [startDate, endDate]
}

function calculateStartDate(series: IDataSeries[], zoom: Zoom, endDate: Date): Date {
  if (zoom === 'all') {
    return _.min(series.map(s => s.startDate))
  }
  if (zoom === 'default') {
    return fns.subYears(endDate, getDefaultTimeSpan(series[0].frequency))
  }
  const sub = {
    y: fns.subYears,
    q: fns.subQuarters,
    m: fns.subMonths,
    w: fns.subWeeks,
    d: (d: Date, days: number) =>
      fns.subDays(fns.subWeeks(d, Math.floor(days / 5)), days % 5),
  }[zoom.unit]
  return sub(endDate, zoom.value)
}

export const attachPattern = (svg: D3Selection, color: string, bgColor: string) => {
  const addPattern = (id: string, opacity: number) => {
    const pattern = svg
      .append('defs')
      .append('pattern')
      .attr('id', id)
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('width', 8)
      .attr('height', 8)
      .attr('patternTransform', 'rotate(45)')

    pattern
      .append('rect')
      .attr('width', 8)
      .attr('height', 8)
      .attr('fill', bgColor)
      .style('opacity', opacity)

    pattern
      .append('rect')
      .attr('width', 3)
      .attr('height', 8)
      .attr('transform', 'translate(0,0)')
      .attr('fill', color)
      .style('opacity', opacity)

    return id
  }
  const colorId = color.substring(1)
  return [
    addPattern(`bar-pattern-${colorId}`, 0.7),
    addPattern(`bar-pattern-${colorId}-selected`, 1),
  ]
}

export const getDataPointBisector = () =>
  d3.bisector<IDataPoint, IDataPoint>((a, b) => a.date.valueOf() - b.date.valueOf())

export function getZoomedSeries(
  series: IDataSeries,
  startZoomDate: Date,
  endZoomDate: Date,
  includeOuterPoints = false
) {
  if (!startZoomDate) {
    return series
  }
  const bisector = getDataPointBisector()
  const startIndex = bisector.left(series.dataPoints, {
    date: startZoomDate,
    value: 0,
  })
  const endIndex = bisector.right(series.dataPoints, {
    date: endZoomDate,
    value: 0,
  })
  return {
    ...series,
    dataPoints: series.dataPoints.slice(
      includeOuterPoints ? Math.max(startIndex - 1, 0) : startIndex,
      includeOuterPoints ? endIndex + 1 : endIndex
    ),
  }
}

export function applyOffset(series: IDataSeries) {
  const interval = freqToInterval(series.frequency)
  const dataPoints = series.dataPoints.map(dp => ({
    ...dp,
    date: interval.offset(dp.date, -series.offset),
  }))
  return {
    ...series,
    dataPoints,
  }
}

export function getTimeScale(width: number, startZoomDate: Date, endZoomDate: Date) {
  return scaleDiscontinuous(d3.scaleTime<number, number>())
    .discontinuityProvider(discontinuitySkipWeekends())
    .range([0, width])
    .domain([startZoomDate, endZoomDate]) as ScaleDiscontinuous<number, number>
}

function linearExtrapolation(targetX: number, datapoints: RichDataPoint[]): number {
  const [p1, p2] = datapoints
  return p1.y + ((targetX - p1.x) / (p2.x - p1.x)) * (p2.y - p1.y)
}

export function extrapolateBoundaries(
  datapoints: RichDataPoint[],
  trendlineBoundaries: Date[],
  chartWidth: number
): RichDataPoint[] {
  if (datapoints.length < 2) {
    return datapoints
  }
  datapoints = [...datapoints]
  const [start, end] = trendlineBoundaries
  const begY = linearExtrapolation(0, datapoints.slice(0, 2))
  const endY = linearExtrapolation(chartWidth, datapoints.slice(-2))
  if (fns.isAfter(datapoints[0].date, start)) {
    datapoints.unshift({ x: 0, y: begY, date: null, value: begY })
  }
  if (fns.isBefore(_.last(datapoints).date, end)) {
    datapoints.push({ x: chartWidth, y: endY, date: null, value: begY })
  }
  return datapoints
}

export function getLinearScale(height: number, domain: number[]) {
  const y = d3.scaleLinear().range([height, 0])
  y.domain(domain)
  return y
}

export function getLogScale(height: number, domain: number[]) {
  const y = d3.scaleSymlog().range([height, 0])
  y.domain(domain)
  return y
}

export const renderSource = (svg: D3Selection, text: string, height: number) => {
  svg
    .append('text')
    .text(`${GRAPH.SOURCE}: ${text || 'Haver Analytics'}`)
    .attr('x', 24)
    .attr('y', height - 14)
    .style('font-size', 11)
    .attr('fill', '#788596')
    .style('letter-spacing', '0.5px')
    .style('font-family', SANS_SERIF)
}

const FREQ_SIZES: { [key: number]: number } = {
  [Freq.Annually]: 14,
  [Freq.Quarterly]: 10,
  [Freq.Monthly]: 7,
  [Freq.Mon]: 4,
}

export const renderXAxis = (
  svg: D3Selection,
  x: XScale,
  width: number,
  height: number,
  frequency: Frequency,
  ticksType: TickmarksType
) => {
  renderTicksAxis(svg, x, width, height, frequency, ticksType)
  if (!isNaN(x.domain()[0].getTime())) {
    renderXAxisLabels(svg, x, width, height, frequency)
  }
}

const renderTicksAxis = (
  svg: D3Selection,
  x: XScale,
  width: number,
  height: number,
  frequency: Frequency,
  ticksType: TickmarksType
) => {
  if (ticksType === 'NONE') {
    return
  }
  const [start, end] = x.domain()
  const pxDensity = pxPerMonths(start, end, width)
  const PX_DENSITY_WEEKLY = 60
  const PX_DENSITY_MONTHLY = 7
  const PX_DENSITY_QUARTERLY = 3.3

  if (ticksType === 'MAJOR') {
    frequency = Freq.Annually
  }
  if (pxDensity > PX_DENSITY_WEEKLY && frequency > Freq.Monthly) {
    renderTicks(svg, x, height, Freq.Mon)
  }
  if (pxDensity > PX_DENSITY_MONTHLY && frequency > Freq.Quarterly) {
    renderTicks(svg, x, height, Freq.Monthly)
  }
  if (pxDensity > PX_DENSITY_QUARTERLY && frequency > Freq.Annually) {
    renderTicks(svg, x, height, Freq.Quarterly)
  }
  renderTicks(svg, x, height, getScaledFrequency(pxDensity, false))
}

export const getXTickValues = (x: XScale, frequency: Frequency) => {
  const [start, end] = x.domain()
  const interval = freqToInterval(frequency)
  const rangeTicks = interval.range(getStartDate(start, frequency), fns.addDays(end, 1))

  if (rangeTicks[0] < start) {
    rangeTicks.shift()
  }
  return rangeTicks
}

export const renderTicks = (
  svg: D3Selection,
  x: XScale,
  height: number,
  frequency: Frequency
) => {
  const axis = d3.axisBottom<Date>(x)
  const rangeTicks = getXTickValues(x, frequency)
  axis
    .scale(x)
    .tickSizeInner(FREQ_SIZES[frequency] || FREQ_SIZES[Freq.Annually])
    .tickValues(rangeTicks)
    .tickFormat(_v => '')
    .tickSizeOuter(frequency <= Freq.Annually ? 0 : 6)
  svg
    .append('g')
    .attr('class', 'x axis ticks-axis')
    .attr('transform', `translate(0, ${height})`)
    .call(axis)
    .selectAll('text')
}

const renderXAxisLabels = (
  svg: D3Selection,
  x: XScale,
  width: number,
  height: number,
  frequency: Frequency
) => {
  const [start, end] = x.domain()
  const pxDensity = pxPerMonths(start, end, width)
  let majorAxis: d3.Axis<Date>
  let minorAxis: d3.Axis<Date>

  const hasMajorAxis = pxDensity < 60

  if (!hasMajorAxis && frequency >= Freq.Monthly) {
    minorAxis = getLabelsAxis(x, Freq.Monthly)
    minorAxis = formatTicks(minorAxis, labelFormat.formatMonthlyWithYear)
  } else if (pxDensity > 14.5 && frequency >= Freq.Monthly) {
    const fmt =
      pxDensity > 31
        ? labelFormat.formatMonthly
        : labelFormat.even(labelFormat.formatMonthly)
    minorAxis = getLabelsAxis(x, Freq.Monthly)
    minorAxis = formatTicks(minorAxis, fmt)
  } else if (pxDensity > 7 && frequency === Freq.Quarterly) {
    minorAxis = getLabelsAxis(x, Freq.Quarterly)
    minorAxis = formatTicks(minorAxis, labelFormat.formatQuarterly)
  } else if (!hasMajorAxis && frequency === Freq.Annually) {
    minorAxis = getLabelsAxis(x, Freq.Annually)
    minorAxis = formatTicks(minorAxis, labelFormat.formatAnnually)
  }

  if (hasMajorAxis) {
    majorAxis = getLabelsAxis(x, getScaledFrequency(pxDensity, false))
    majorAxis = formatTicks(majorAxis, labelFormat.formatAnnually)
  }
  if (minorAxis) {
    renderTickLabels(svg, minorAxis, height, false)
  }
  if (majorAxis) {
    renderTickLabels(svg, majorAxis, height, true)
  }
}

export const renderYAxisLabels = (
  svg: D3Selection,
  config: ITextBlockConfig,
  content: ITextBlockContent,
  y: number,
  position: 'left' | 'right',
  width: number
) => {
  if (!content || content.columns.length < 1) {
    return
  }
  const column = content.columns[0]
  const x = position === 'left' ? config.margin.left : width - config.margin.right

  column.each(function () {
    const text = svg
      .append('text')
      .attr('fill', '#485465')
      .attr('y', y + config.margin.top)
      .attr('font-size', config.fontSize)
      .style('font-weight', config.fontWeight)
      .style('text-anchor', 'middle')

    // eslint-disable-next-line no-invalid-this
    const children = this.childNodes as ISVGRect[]
    renderTextBlockLine(text, x, children[0], config, children.length > 1)
  })
}

const trimLabelTicks = (x: XScale, ticks: Date[], freq: Frequency) => {
  const [start, end] = x.domain()
  if (getMiddleDate(start, freq) < _.first(ticks) || x(start) - x(ticks[0]) > 12) {
    ticks.shift()
  }
  if (ticks.length === 0) {
    return ticks
  }
  if (getMiddleDate(start, freq) > _.last(ticks) || x(_.last(ticks)) - x(end) > 12) {
    ticks.pop()
  }
  return ticks
}

export const getLabelsAxis = (x: XScale, frequency: Frequency): d3.Axis<Date> => {
  const axis = d3.axisBottom<Date>(x)
  const [start, end] = x.domain()
  const interval = freqToInterval(frequency)
  const offsetEnd =
    getMiddleDate(end, frequency) > end ? end : interval.floor(interval.offset(end))
  let labelsTicks = interval.range(interval.floor(start), offsetEnd)

  if (labelsTicks.length === 0) {
    labelsTicks = [start]
  }
  labelsTicks = labelsTicks.map((d: Date) => getMiddleDate(d, frequency))
  labelsTicks = trimLabelTicks(x, labelsTicks, frequency)

  return axis.scale(x).tickSize(0).tickValues(labelsTicks)
}

export const formatTicks = (
  scale: d3.Axis<Date>,
  formatFn: (d: Date, index?: number) => string
): d3.Axis<Date> => {
  return scale.tickFormat(formatFn)
}

export const renderTickLabels = (
  svg: D3Selection,
  axis: d3.Axis<Date>,
  height: number,
  major: boolean
) => {
  svg
    .append('g')
    .attr('class', `x axis ticks-axis ticks${major ? '-major' : ''}`)
    .attr('transform', `translate(0, ${height})`)
    .call(axis)
    .selectAll('text')
    .attr('dy', major ? 25 : 10)
}

const Y_AXIS_SIDES: AxisAssignment[] = ['left', 'right']

const getRounding = (ticks: number[]): number => {
  if (ticks.length === 0) {
    return 0
  }
  const diffs = ticks.map((v, i) => Math.abs(v - (i === 0 ? 0 : ticks[i - 1])))
  const lowestDiff = _.min(diffs.filter(v => v > 0))
  if (!lowestDiff) {
    return 0
  }
  const magnitude = Math.log10(Math.abs(lowestDiff))
  if (magnitude >= 0) {
    return 0
  }
  return -Math.floor(magnitude)
}

export const renderYAxis = (
  svg: D3Selection,
  y: YScale,
  width: number,
  ticks: number[],
  side: 'left' | 'right' | 'both'
) => {
  const sides = side === 'both' ? Y_AXIS_SIDES : [side]
  const rounding = getRounding(ticks)

  const maxLength = Math.max(...ticks.map(el => el.toString().length))
  const offsets = { left: 0, right: width + 25 }

  if (maxLength > 6) {
    offsets.right = offsets.right + 25
  } else if (maxLength > 4) {
    offsets.right = offsets.right + 15
  }

  sides.forEach(axisType =>
    svg
      .append('g')
      .attr('class', `y axis ${axisType}`)
      .attr('transform', `translate(${offsets[axisType]}, 0)`)
      .call(
        getYAxis(y)
          .tickValues(ticks)
          .tickFormat((v: number) => v.toFixed(rounding))
      )
  )
}

export const renderAxisStyles = (svg: D3Selection) => {
  svg.selectAll('.y.axis .domain').remove()

  svg.selectAll('.domain, .tick line').attr('stroke', '#dfe4ec')

  svg
    .selectAll('.axis')
    .style('font-size', 12)
    .style('font-family', `${COURIER_PRIME}, ${MONOSPACE}`)
    .style('letter-spacing', 0.5)
    .selectAll('.tick text')
    .attr('fill', '#485465')

  svg.selectAll('.y.axis.left .tick text').attr('x', '-12')
  svg.selectAll('.y.axis.right .tick text').attr('x', '12')
  svg.selectAll('.x.axis .tick text').attr('y', '14')
  svg.selectAll('.x.axis.major .tick text').attr('y', '28')
}

export const renderXGrid = (
  svg: D3Selection,
  y: YScale,
  width: number,
  ticks: number[]
) => {
  const selection = svg
    .append('g')
    .attr('class', 'grid')
    .call(
      getYAxis(y)
        .tickSize(-width)
        .tickFormat(_v => '')
        .tickValues(ticks)
    )
  selection.select('.domain').remove()
  renderAxisStyles(selection)
}

export const renderYGrid = (
  svg: D3Selection,
  x: XScale,
  width: number,
  height: number,
  freq: Frequency
) => {
  const [start, end] = x.domain()
  const pxDensity = pxPerMonths(start, end, width)
  const gridFreq = getScaledFrequency(pxDensity, true)
  const finalFreq = Math.min(gridFreq, freq) as Frequency
  const ticks = getXTickValues(x, finalFreq)
  const selection = svg
    .append('g')
    .attr('class', 'grid')
    .call(
      d3
        .axisBottom(x)
        .scale(x)
        .tickSize(height)
        .tickFormat(_v => '')
        .tickValues(ticks)
    )
  selection.select('.domain').remove()
  renderAxisStyles(selection)
}

const addTriangleMarker = (selection: D3Selection, id: string, color: string) => {
  selection
    .append('svg:defs')
    .append('svg:marker')
    .attr('id', `triangle-${id}`)
    .attr('viewBox', '0 0 20 20')
    .attr('markerWidth', 17)
    .attr('markerHeight', 13)
    .attr('refY', 10)
    .attr('refX', 10)
    .append('svg:path')
    .attr('d', 'M10.5 4.827L3.849 15H17.15L10.5 4.827z')
    .attr('fillRule', 'evenodd')
    .attr('stroke', '#ffffff')
    .attr('fill', color)
}

export const addDropShadow = (selection: D3Selection) => {
  const filter = selection
    .append('svg:defs')
    .append('svg:filter')
    .attr('id', SHADOW_FILTER_ID)

  filter.append('svg:feGaussianBlur').attr('in', 'SourceAlpha').attr('stdDeviation', '1')

  filter.append('svg:feOffset').attr('result', 'offsetBlur').attr('dy', '2')
  filter
    .append('svg:feComponentTransfer')
    .append('svg:feFuncA')
    .attr('type', 'linear')
    .attr('slope', '0.3')

  const merge = filter.append('svg:feMerge')

  merge.append('svg:feMergeNode')
  merge.append('svg:feMergeNode').attr('in', 'SourceGraphic')
}
export const addClipPath = (selection: D3Selection, width: number, height: number) => {
  const filter = selection
    .append('svg:defs')
    .append('svg:clipPath')
    .attr('id', CLIP_PATH_ID)

  return filter
    .append('rect')
    .attr('x', '0')
    .attr('y', '0')
    .attr('width', width)
    .attr('height', height)
}

export const renderLine = (
  selection: D3Selection,
  series: IDataSeries,
  color: string,
  lineShadow: boolean,
  richDataPoints: RichDataPoint[],
  curveFactory?: d3.CurveFactory
) => {
  for (const points of chunkBy(richDataPoints, d => d.value !== null)) {
    const isSpline = curveFactory === d3.curveStepAfter
    const simplified =
      series.dataMarkers || isSpline ? points : simplifyDataPointsLTTB(points)
    const line = d3
      .line<RichDataPoint>()
      .x((p: RichDataPoint) => p.x)
      .y((p: RichDataPoint) => p.y)

    if (curveFactory) {
      line.curve(curveFactory)
    }
    const path = selection
      .append('path')
      .datum(simplified)
      .attr('class', `line line-${series.uuid}`)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', 2)
      .attr('stroke', color)
      .attr('fill', 'none')
      .attr('d', line)
      .attr('clip-path', `url(#${CLIP_PATH_ID})`)

    if (series.dataMarkers) {
      addTriangleMarker(selection, series.uuid, color)
      path.attr('marker-mid', `url(#triangle-${series.uuid})`)
      path.attr('marker-start', `url(#triangle-${series.uuid})`)
      path.attr('marker-end', `url(#triangle-${series.uuid})`)
    }
    if (lineShadow) {
      path.attr('filter', `url(#${SHADOW_FILTER_ID})`)
    }
  }
  return selection.selectAll(`.line.line-${series.uuid}`)
}

export const renderBarsAsPath = (
  richDataPoints: RichDataPoint[],
  selection: D3Selection,
  series: IDataSeries,
  color: string,
  getY0: (i: number) => number
) => {
  let offset = 0
  for (const points of chunkBy(richDataPoints, d => d.value !== null)) {
    const area = d3
      .area<RichDataPoint>()
      .x((p: RichDataPoint) => p.x)
      .y0((_p: RichDataPoint, i: number) => getY0(i + offset))
      .y1((p: RichDataPoint) => p.y)
    area.curve(d3.curveStep)
    selection
      .datum(points)
      .append('path')
      .attr('class', `bars bars-${series.uuid}`)
      .attr('stroke-width', 0)
      .attr('fill', color)
      .attr('d', area)
      .attr('clip-path', `url(#${CLIP_PATH_ID})`)

    offset += points.length
  }
  return selection.selectAll(`.bars-${series.uuid}`)
}

export const renderBars = (
  selection: D3Selection,
  y: YScale,
  series: IDataSeries,
  selectedIndex: number,
  color: string,
  highlightColor: string,
  isShaded: boolean,
  richDataPoints: RichDataPoint[],
  barSeriesCount: number,
  barSeriesIndex: number,
  overrideY0?: (i: number) => number
) => {
  if (richDataPoints.length === 0) {
    return
  }
  const [patternId, patternIdSelected] = isShaded
    ? attachPattern(selection, highlightColor, color)
    : []

  const BARS_WIDTH_RATIO = 0.6

  const [min, max] = y.domain()
  const y0 = min > 0 ? y(min) : max < 0 ? y(max) : y(0)
  const getY0 = overrideY0 ?? (() => y0)
  const bandwidth =
    BARS_WIDTH_RATIO *
    Math.min(
      ...richDataPoints.slice(1, 12).map((val, index) => val.x - richDataPoints[index].x)
    )
  const barWidth = Math.max(1, BARS_WIDTH_RATIO * bandwidth)

  const halfWidth = barWidth / 2
  const offsetMultiplier = getOffsetMultiplier(barSeriesCount, barSeriesIndex)
  const offsetScale = 2.1 // larger value -> less spread between bars
  const offset = (halfWidth * offsetMultiplier) / offsetScale
  if (bandwidth < 0.6) {
    const bars = renderBarsAsPath(richDataPoints, selection, series, color, getY0)
    bars.attr('transform', `translate(${offsetMultiplier} 0)`)
  }
  const bar = selection
    .insert('g', ':first-child')
    .attr('class', `bars bars-${series.uuid}`)
    .selectAll('.rect')
    .data(richDataPoints)
    .enter()
    .append('rect')
    .attr('class', 'bar')
    .attr('x', (r: RichDataPoint) => r.x - barWidth / 2 + offset)
    .attr('width', barWidth)
    .attr('y', (r: RichDataPoint, i) => (r.value >= 0 ? r.y : getY0(i)))
    .attr('height', (r: RichDataPoint, i) =>
      r.value >= 0 ? getY0(i) - r.y : r.y - getY0(i)
    )
    .attr('clip-path', `url(#${CLIP_PATH_ID})`)

  if (isShaded) {
    bar
      .style('stroke', color)
      .style('stroke-width', 1)
      .attr('fill', (_t, i) =>
        i === selectedIndex ? `url(#${patternIdSelected})` : `url(#${patternId})`
      )
  } else {
    bar
      .attr('stroke', 'none')
      .attr('fill', (_t, i) => (i === selectedIndex ? highlightColor : color))
  }
}

export const getOffsetMultiplier = (total: number, index: number) => {
  /**
   * Offset depends on total number of variables and the current index.
   * It's negative for index less than total number/2 and positive for values bigger than number/2.
   * It returns 0 (no offset) in two cases:
   * - total == 1: there's just one variable, so no need to apply any offset
   * - total is an odd number and index is in the middle, e.g. total=3, index=1
   */
  return total === 1 ? 0 : index - (total - 1) / 2
}

export const renderArea = (
  selection: D3Selection,
  y: YScale,
  series: IDataSeries,
  color: string,
  paleColor: string,
  lineShadow: boolean,
  richDataPoints: RichDataPoint[]
) => {
  const [min, max] = y.domain()
  const y0 = min > 0 ? y(min) : max < 0 ? y(max) : y(0)

  for (const points of chunkBy(richDataPoints, d => d.value !== null)) {
    const simplified = simplifyDataPointsLTTB(points)
    const area = d3
      .area<RichDataPoint>()
      .x((p: RichDataPoint) => p.x)
      .y0(y0)
      .y1((p: RichDataPoint) => p.y)

    selection
      .append('path')
      .attr('class', `area area-${series.uuid}`)
      .datum(simplified)
      .attr('fill', paleColor)
      .style('opacity', 0.7)
      .attr('stroke', color)
      .attr('stroke-width', 1)
      .attr('d', area)
      .attr('clip-path', `url(#${CLIP_PATH_ID})`)
  }
  if (lineShadow) {
    renderLine(selection, series, color, lineShadow, richDataPoints)
  }
  return selection.selectAll(`.area.area-${series.uuid}`)
}

export const renderHoverElement = (
  svg: D3Selection,
  onMouseMove: () => void,
  onMouseOut: () => void,
  width: number,
  height: number
) => {
  svg
    .append('rect')
    .attr('x', 0)
    .attr('y', 0)
    .attr('width', width)
    .attr('height', height)
    .attr('class', 'hover')
    .attr('fill', 'transparent')
    .on('mousemove', onMouseMove)
    .on('mouseout', onMouseOut)
}

export const renderSelection = (
  svg: D3Selection,
  y: YScale[],
  height: number,
  position: number,
  values: string[],
  colorIndexes: number[],
  graphTypes: ISeriesType[]
) => {
  svg
    .append('path')
    .attr('class', 'hover-line')
    .attr('d', `M ${position} 0 L ${position} ${height}`)
    .attr('stroke', '#6f7886')
    .attr('fill', 'none')

  values.forEach(
    (val, i) =>
      val &&
      graphTypes[i] &&
      LINE_TYPES.includes(graphTypes[i]) &&
      svg
        .append('circle')
        .attr('class', 'hover-circle')
        .attr('cx', position)
        .attr('cy', y[i](val))
        .attr('r', '4')
        .attr('stroke-width', '2')
        .attr('stroke', 'white')
        .attr('fill', colorIndexes.length > i ? COLORS[colorIndexes[i]] : COLORS[0])
        .attr('clip-path', `url(#${CLIP_PATH_ID})`)
  )
}

export const renderRecession = (
  svg: D3Selection,
  x: XScale,
  height: number,
  recession: IRecession,
  recessionType: IRecessionType
) => {
  const range = x.domain()

  const startDate = recession.startDate
  const endDate = recession.endDate || range[1]

  if (!fns.isBefore(startDate, endDate)) {
    return
  }
  if (
    !fns.areIntervalsOverlapping(
      { start: range[0], end: range[1] },
      { start: startDate, end: endDate }
    )
  ) {
    return
  }

  const start = x(fns.max([startDate, range[0]]))
  const end = x(fns.min([endDate, range[1]]))

  const width = end - start
  const xPos = start
  const yPos = 0
  if (recessionType === 'SHADING') {
    svg
      .append('rect')
      .attr('x', xPos)
      .attr('y', yPos)
      .attr('width', width)
      .attr('height', height)
      .attr('fill', '#ecedef')
      .attr('class', 'recession')
  } else {
    ;[start, end].forEach(pos =>
      svg
        .append('line')
        .attr('x1', pos)
        .attr('y1', yPos)
        .attr('x2', pos)
        .attr('y2', height)
        .attr('stroke', '#b6c2d4')
        .attr('stroke-dasharray', '5,5')
        .attr('class', 'recession')
    )
  }
}

export const renderCorrLabel = (svg: D3Selection, correlation: ICorrelation) => {
  const labelWidth = 60
  const labelHeight = 28
  const labelX = 20
  const labelY = 16

  const rhoIconX = labelX
  const rhoIconY = labelY + 5

  const textX = labelX + 20
  const textY = labelY + labelHeight / 2 + 3

  const g = svg.append('g')
  g.append('rect')
    .attr('fill', '#788596')
    .attr('width', labelWidth)
    .attr('height', labelHeight)
    .attr('y', labelY)
    .attr('x', labelX)
    .attr('rx', 2)
    .attr('ry', 2)

  g.append('path')
    .attr('d', RhoIconD)
    .attr('fill-rule', 'evenodd')
    .attr('fill', 'white')
    .attr('transform', `translate(${rhoIconX}, ${rhoIconY})`)

  g.append('text')
    .text(`= ${correlation.value.toFixed(2)}`)
    .attr('x', textX)
    .attr('y', textY)
    .attr('font-size', 10)
    .attr('fill', 'white')
    .style('font-weight', 600)
}

export const renderTitle = (
  svg: D3Selection,
  config: ITitleTextBlockConfig,
  content: ITextBlockContent
) => {
  if (content.columns.length < 1) {
    return
  }
  const column = content.columns[0]
  let height = 0
  const ColumnXOffset = config.screenWidth / 2

  column.each(function () {
    const text = svg
      .append('text')
      .attr('fill', config.fontColor)
      .attr('x', ColumnXOffset)
      .attr('y', config.margin.top + height)
      .attr('font-size', config.fontSize)
      .style('font-weight', config.fontWeight)
      .style('text-anchor', 'middle')
      .attr('class', config.printable ? 'legend-title' : 'legend-title no-print')

    // eslint-disable-next-line no-invalid-this
    const children = this.childNodes as ISVGRect[]
    if (!children) {
      return
    }

    if (config.textWrapping) {
      for (const elem of children) {
        renderTextBlockLine(text, ColumnXOffset, elem, config)
      }
    } else {
      renderTextBlockLine(text, ColumnXOffset, children[0], config, children.length > 1)
    }

    const child = children[0] as ISVGRect
    if (child !== undefined && child.getBoundingClientRect !== undefined) {
      height += child.getBoundingClientRect().height
    }
  })
}

export const renderLegendWrapper = (
  svg: D3Selection,
  width: number,
  height: number,
  isHover: boolean,
  onMouseMove: () => void,
  onMouseOut: () => void,
  onClick: () => void
) => {
  const color = '#b6c2d4'
  const margin = 8
  const buttonWidth = 34
  const buttonHeight = 14
  const buttonMarginTop = 3
  const buttonMarginRight = 5
  const buttonX = width - margin - buttonWidth - buttonMarginRight
  const buttonY = margin + buttonHeight + buttonMarginTop

  const group = svg
    .append('g')
    .on('mouseover', onMouseMove)
    .on('mouseleave', onMouseOut)
    .on('click', onClick)
    .style('cursor', 'pointer')

  const rect = group
    .append('rect')
    .attr('x', margin)
    .attr('y', margin)
    .attr('width', width - margin * 2)
    .attr('height', height - margin * 2)
    .attr('rx', 4)
    .attr('ry', 4)
    .attr('fill', 'transparent')

  if (isHover) {
    rect.style('stroke', color).style('stroke-width', 1)

    const buttonGroup = group.append('g')

    buttonGroup
      .append('rect')
      .attr('fill', color)
      .attr('x', buttonX)
      .attr('y', buttonY - buttonHeight + 3)
      .attr('width', buttonWidth)
      .attr('height', buttonHeight)

    buttonGroup
      .append('text')
      .attr('font-size', 11)
      .attr('x', buttonX + buttonWidth / 2)
      .attr('y', buttonY)
      .text('edit')
      .attr('font-weight', 600)
      .attr('fill', 'white')
      .style('text-transform', 'uppercase')
      .style('text-anchor', 'middle')
      .style('letter-spacing', '0.3px')
  }
  return group
}

export const renderLegend = (
  svg: D3Selection,
  config: ITextBlockConfig,
  content: ITextBlockContent,
  yPosition: number
) => {
  content.columns.forEach((column: D3Selection, columnIndex: number) => {
    let height = 0

    const ColumnXOffset =
      (content.columnWidth + config.columnMargin + config.legendRectWidth) * columnIndex

    column.each(function (_item: string, entryIndex: number) {
      const color = content.colors[columnIndex][entryIndex]
      const x = config.margin.left + ColumnXOffset
      const text = svg
        .append('text')
        .attr('fill', '#485465')
        .attr('x', x)
        .attr('y', yPosition + height)
        .attr('class', 'legend-entry')
        .attr('font-size', config.fontSize)
        .style('font-weight', config.fontWeight)

      if (color) {
        svg
          .append('rect')
          .attr('class', 'colorBox')
          .attr('width', 6)
          .attr('height', 6)
          .attr('rx', 1)
          .attr('ry', 1)
          .attr('x', x)
          .attr('y', yPosition + height - 7)
          .attr('fill', color)
      }

      // eslint-disable-next-line no-invalid-this
      const children = this.childNodes as ISVGRect[]
      if (!children) {
        return
      }
      if (config.textWrapping) {
        for (const elem of children) {
          text.append('title').text(elem.textContent)
          renderTextBlockLine(text, x, elem, config)
        }
        const child = children[0]
        if (child !== undefined && child.getBoundingClientRect !== undefined) {
          // XXX: this does not work, the bounding client rect width and height is always zero here (Firefox, Chromium)
          height += children[0].getBoundingClientRect().height
        }
      } else {
        text.append('title').text(children[0].textContent)
        renderTextBlockLine(text, x, children[0], config, children.length > 1)
        height += config.oneLineHeight
      }
    })
  })
}

export const renderAxisAssignment = (
  svg: D3Selection,
  height: number,
  width: number,
  colors: string[],
  step: number,
  onClick: () => void
) => {
  const base = step * 2

  const handleMouseOver = () => {
    d3.select(`.${step < 0 ? 'left' : 'right'}HoverBox`).attr('fill', '#e0e4eb')
  }

  const handleMouseOut = () => {
    d3.select(`.${step < 0 ? 'left' : 'right'}HoverBox`).attr('fill', '#fff')
  }

  if (colors.length) {
    svg
      .append('rect')
      .attr('class', `${step < 0 ? 'left' : 'right'}HoverBox`)
      .attr('width', 14 + (step > 0 ? step : -step) * (colors.length - 1))
      .attr('height', 14)
      .attr('fill', '#fff')
      .attr('x', width + base - 4 + (step > 0 ? 0 : step * (colors.length - 1)))
      .attr('y', height + 16)
      .attr('rx', 7)
      .style('cursor', 'pointer')
      .on('click', onClick)
      .on('mouseover', handleMouseOver)
      .on('mouseout', handleMouseOut)
  }
  svg
    .selectAll(`.colorBox${step}`)
    .data(colors)
    .enter()
    .append('rect')
    .attr('class', `colorBox${step}`)
    .attr('width', 6)
    .attr('height', 6)
    .attr('rx', 1)
    .attr('ry', 1)
    .attr('x', (_color, i) => width + base + i * step)
    .attr('y', height + 20)
    .attr('fill', color => color)
    .style('cursor', 'pointer')
    .on('click', onClick)
    .on('mouseover', handleMouseOver)
    .on('mouseout', handleMouseOut)
}
