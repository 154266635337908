import { SIDEBAR } from '../messages'

import sidebarSeries from '../static/sidebar-group.svg'
import sidebarDirectoryActive from '../static/sidebar-directory-active.svg'

export const BUTTONS = [
  { name: SIDEBAR.DIRECTORY, path: sidebarDirectoryActive },
  { name: SIDEBAR.SERIES, path: sidebarSeries },
]

export enum TABS {
  DIRECTORY,
  SERIES,
}
