import * as React from 'react'
import * as FauxDOM from 'react-faux-dom'
import * as d3 from 'd3'
import { renderAxisAssignment } from '../../services/hd3'
import { COLORS } from '../../services/series'

interface IProps {
  variables: IDataSeries[]
  width: number
  height: number
  onClick: () => void
}

const SeriesAssignments = (props: IProps) => {
  const svg = FauxDOM.createElement('g')
  const selection = d3.select(svg)
  const leftColors: string[] = []
  const rightColors: string[] = []

  props.variables.forEach(v =>
    (v.axisAssignment === 'left' ? leftColors : rightColors).push(COLORS[v.colorIndex])
  )

  renderAxisAssignment(
    selection,
    props.height,
    0,
    leftColors.reverse(),
    -10,
    props.onClick
  )
  renderAxisAssignment(
    selection,
    props.height,
    props.width,
    rightColors,
    10,
    props.onClick
  )

  return <>{React.Children.map(svg.toReact(), c => c.props.children)}</>
}

export default React.memo(SeriesAssignments)
