import * as React from 'react'
import { bindBem } from '../../bem'

import './BigSwitch.scss'

interface IProps {
  value: boolean
  onChange: (value: boolean) => void
  disabled?: boolean
  labelOn?: string
  labelOff?: string
}

export class BigSwitch extends React.Component<IProps> {
  render() {
    const { value, disabled, labelOn = 'ON', labelOff = 'OFF' } = this.props
    const { block, element } = bindBem('BigSwitch')

    const hasValue = () => value != null

    return (
      <div
        className={block({
          on: hasValue() && value,
          off: hasValue() && !value,
          disabled,
        })}
      >
        <div className={element('On')} onClick={() => this.onChange(true)}>
          {labelOn}
        </div>
        <div className={element('Off')} onClick={() => this.onChange(false)}>
          {labelOff}
        </div>
      </div>
    )
  }

  private onChange = (value: boolean) =>
    !this.props.disabled && this.props.onChange(value)
}
