import * as React from 'react'
import { bindBem } from 'bem'
import { Checkbox } from '../Checkbox'
import { IItemRendererProps } from './ItemSelector'
import { Switch } from '../Sidebar/Switch'

import { PackageIcon } from '../SVG/PackageIcon'

import './PackageCheckbox.scss'

const PackageCheckbox = <T extends {}>(
  props: IItemRendererProps<T> & { packages: IDBPackage[] }
) => {
  const { block, element } = bindBem('PackageCheckbox')

  const isPackage = props.packages.length > 0
  const isSelected = props.isSelected || isPackage

  const shouldShowPreviewSwitch = React.useMemo(() => {
    if (props.nonInteractive) {
      return props.isPreview
    }
    return !isSelected && props.onPreviewChange
  }, [props.nonInteractive, props.isPreview, isSelected, props.onPreviewChange])

  return (
    <div className={block()}>
      {!props.nonInteractive && (
        <Checkbox
          checked={isSelected}
          label={props.item.name}
          onChange={isPackage ? undefined : props.onChange}
          big
          disabled={isPackage}
        />
      )}
      {props.nonInteractive && (
        <span className={element('Label')}>{props.item.name}</span>
      )}
      {isPackage && (
        <PackageIcon
          title={`In package: ${props.packages.map(p => p.name).join(', ')}`}
          className="PackageIndicator"
        />
      )}
      {shouldShowPreviewSwitch && (
        <Switch
          title="preview"
          value={props.isPreview}
          onChange={props.onPreviewChange}
          disabled={props.nonInteractive}
        />
      )}
    </div>
  )
}

export default PackageCheckbox
