// eslint-disable max-classes-per-file

import * as React from 'react'
import { connect } from 'react-redux'
import enhanceWithClickOutside from 'react-click-outside'
import { IRootState } from '../store'
import { logout } from '../store/User'
import { ACTIONS } from '../store/Series'

import { SettingsDropdown } from './SettingsDropdown'
import { bindBem } from '../bem'

import avatar from '../static/avatar.svg'
import chevronUpWhite from '../static/chevron-up-white.svg'
import chevronDownWhite from '../static/chevron-down-white.svg'
import chevronUpDark from '../static/chevron-up-dark.svg'
import chevronDownDark from '../static/chevron-down-dark.svg'

import './AvatarMenu.scss'

export interface IState {
  expanded: boolean
  isChangePasswordOpen: boolean
}

export interface IStateProps {
  user: IUser
}

export interface IActionProps {
  onLogout: () => void
  openChangePasswordModal: () => void
}

export interface IProps {
  small?: boolean
}

export class BaseAvatarMenu extends React.Component<
  IProps & IStateProps & IActionProps,
  IState
> {
  isFooter = false
  state: IState = {
    expanded: false,
    isChangePasswordOpen: false,
  }

  toggleExpand = () => this.setState(() => ({ expanded: !this.state.expanded }))

  handleClickOutside = () => this.state.expanded && this.toggleExpand()

  render() {
    const { expanded } = this.state
    const { user, onLogout, small } = this.props
    const iconPath = this.isFooter
      ? expanded
        ? chevronDownWhite
        : chevronUpDark
      : expanded
      ? chevronUpWhite
      : chevronDownDark
    const { block, element } = bindBem('AvatarMenu')
    return (
      <>
        <div
          className={block({ expanded, isFooter: this.isFooter, small })}
          onClick={this.toggleExpand}
        >
          <img className={element('Photo')} src={avatar} alt="Avatar" />
          {!small && <span className={element('Name')}>{user.username}</span>}
          {!small && <img className={element('Chevron')} src={iconPath} />}
          {expanded && (
            <SettingsDropdown
              isFooter={this.isFooter}
              role={user.role}
              onLogout={onLogout}
              openChangePasswordModal={this.props.openChangePasswordModal}
            />
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state: IRootState): IStateProps => {
  return { user: state.user.user }
}

const mapDispatchToProps = (dispatch: any): IActionProps => {
  return {
    onLogout: () => dispatch(logout()),
    openChangePasswordModal: () => dispatch(ACTIONS.setActiveModal('changePassword')),
  }
}

export class BaseFooterAvatarMenu extends BaseAvatarMenu {
  isFooter = true
}

export const AvatarMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(enhanceWithClickOutside(BaseAvatarMenu))

export const FooterAvatarMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(enhanceWithClickOutside(BaseFooterAvatarMenu))
