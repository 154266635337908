import * as React from 'react'

import { bindBem } from '../../bem'

import switchOff from '../../static/switch-off.svg'
import switchOn from '../../static/switch-on.svg'

import './Switch.scss'

interface IProps {
  title: string
  value: boolean
  onChange: (checked: boolean) => void
  uppercase?: boolean
  disabled?: boolean
  alt?: string
  children?: React.ReactNode
}

export class Switch extends React.Component<IProps> {
  render() {
    const { title, value, uppercase, disabled, alt } = this.props
    const { block, element } = bindBem('Switch')
    return (
      <div
        className={block({ on: value, uppercase, disabled })}
        onClick={disabled ? undefined : this.onChange}
        title={alt}
      >
        <div className={element('Title')}>{title}</div>
        <div>
          <img className={element('Arrow')} src={value ? switchOn : switchOff} />
          {this.props.children && <span>{this.props.children}</span>}
        </div>
      </div>
    )
  }
  onChange = () => this.props.onChange(!this.props.value)
}
