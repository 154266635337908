import nodeContentRenderer from './NodeContentRenderer'
import treeNodeRenderer from './TreeNodeRenderer'
import { ThemeProps } from 'react-sortable-tree'

export const theme: ThemeProps = {
  nodeContentRenderer,
  treeNodeRenderer,
  scaffoldBlockPxWidth: 15,
  rowHeight: 30,
} as any
