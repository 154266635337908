import { CLIENT } from './config'
import { IServerSeries, toFoundSeries } from '../services/series'
import Axios from 'axios'

const RECESSION_COUNTRIES_URL = 'v1/data/recessions/countries'
const SERIES_SEARCH_URL = 'v2/data/search'

export const getRecessionCountries = async (): Promise<string[]> =>
  (await CLIENT.get<string[]>(RECESSION_COUNTRIES_URL)).data

let lastToken = Axios.CancelToken.source()

export const searchSeries = async (
  query: string,
  database: string,
  page = 1
): Promise<IPagination<IFoundSeries>> => {
  lastToken.cancel()
  lastToken = Axios.CancelToken.source()
  const response = await CLIENT.get<IPagination<IServerSeries>>(SERIES_SEARCH_URL, {
    params: { query, database, per_page: 50, page },
    cancelToken: lastToken.token,
  })

  return { ...response.data, data: response.data.data.map(toFoundSeries) }
}
