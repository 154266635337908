import * as React from 'react'

import { Input, Props } from './Input'
import CopyToClipboard from 'react-copy-to-clipboard'
import { SIDEBAR } from '../messages'

export const InputCopy: React.FC<Props> = props => {
  const [isCopied, setCopied] = React.useState(false)
  return (
    <Input
      postfix={
        <CopyToClipboard text={String(props.value)}>
          <a
            className={'CopyLink'}
            onClick={() => setCopied(true)}
            style={{ cursor: 'pointer' }}
          >
            {isCopied ? SIDEBAR.COPIED : SIDEBAR.COPY}
          </a>
        </CopyToClipboard>
      }
      disabled
      {...props}
    />
  )
}
