// eslint-disable max-lines

import * as React from 'react'
import * as Sentry from '@sentry/react'
import ReactTable, {
  CellInfo,
  Column,
  Instance as ReactTableInstance,
  RowInfo,
  SortingRule,
} from 'react-table'
import { connect } from 'react-redux'
import { Checkbox } from '../../Checkbox'
import {
  DateText,
  HeaderCell,
  Text,
  TextWithHover,
  TokenStatusCell,
} from 'components/Admin/AdminTableCells'

import { ADMIN } from 'messages'
import { bindBem } from 'bem'

import { IRootState, TDispatch } from '../../../store'

import * as admin from '../../../store/Admin'
import { Button } from 'components/Button'
import { Modal } from 'components/Modal'
import { DeactivateTokensForm } from 'components/Admin/Users/DeactivateTokensForm'
import { getTokenStatus, isTokenDeactivated } from 'components/Admin/Users/utils'
import { isHaverAdmin, isSuperAdmin } from 'services/user'
import {
  ExtendTokensForm,
  IExtendTokenForm,
} from 'components/Admin/Users/ExtendTokensForm'
import { RefreshTokensForm } from 'components/Admin/Users/RefreshTokensForm'
import { TokenStatus } from 'components/Admin/utils'

import './Tokens.scss'

export interface IActionProps {
  deactivateTokens: (tokens: IToken[]) => void
  extendTokens: (tokens: IToken[], isoDate: Date | null) => void
  refreshTokens: (tokens: IToken[]) => void
}

export interface IProps {
  organization: IOrganization
}

interface IUsersMap {
  [key: number]: IUser
}

export interface IStateProps {
  me: IUser
  organizationTokens: IToken[]
  usersMap: IUsersMap
}

export interface IState {
  sortedColumn: string
  desc: boolean
  selectedTokens: number[]
  lastSelectedIndex: number
  sortedData: IToken[]
  isDeactivateTokenModalOpen: boolean
  isExtendTokenModalOpen: boolean
  isRefreshTokenModalOpen: boolean
}

export class Tokens extends React.Component<IProps & IActionProps & IStateProps, IState> {
  reactTable: React.RefObject<ReactTableInstance>

  state: IState = {
    sortedColumn: 'id',
    desc: true,
    selectedTokens: [],
    lastSelectedIndex: -1,
    sortedData: [],
    isDeactivateTokenModalOpen: false,
    isExtendTokenModalOpen: false,
    isRefreshTokenModalOpen: false,
  }

  private COLUMNS: Column[] = [
    ...(isHaverAdmin(this.props.me.role)
      ? [
          {
            Header: () => (
              <Checkbox
                checked={this.allSelected()}
                big={true}
                onChange={(checked: boolean) => this.onToggleCheckAll(checked)}
              />
            ),
            headerClassName: 'SelectAll',
            className: 'CheckboxCell',
            maxWidth: 50,
            sortable: false,
            Cell: ({ original }: CellInfo) => {
              if (isTokenDeactivated(original)) {
                return null
              }
              return (
                <Checkbox
                  checked={this.isTokenSelected(original)}
                  rawOnchange={e => this.handleCheckboxRawChange(e, original)}
                  big
                />
              )
            },
          },
        ]
      : []),
    {
      Header: ADMIN.TOKENS.LABELS.ID,
      maxWidth: 64,
      accessor: 'id',
      sortable: true,
      className: 'IdCell',
      Cell: Text,
    },
    ...(isSuperAdmin(this.props.me.role)
      ? [
          {
            Header: ADMIN.TOKENS.LABELS.TOKEN,
            maxWidth: 84,
            accessor: 'token',
            sortable: true,
            className: 'TokenCell',
            Cell: TextWithHover,
          },
        ]
      : []),
    {
      Header: ADMIN.TOKENS.LABELS.USER_NAME,
      id: 'username',
      accessor: (row: IToken) => this.props.usersMap[row.userId]?.username,
      sortable: true,
      className: 'UsernameCell',
      Cell: TextWithHover,
    },
    {
      Header: ADMIN.TOKENS.LABELS.TOKEN_TYPE,
      accessor: 'type',
      sortable: true,
      className: 'TypeCell',
      Cell: ({ value }) => <span>{value}</span>,
    },
    {
      Header: ADMIN.TOKENS.LABELS.STATUS,
      id: 'tokenStatus',
      accessor: (row: IToken) => getTokenStatus(row),
      sortable: true,
      className: 'StatusCell',
      Cell: ({ value }) => <TokenStatusCell status={value} />,
    },
    {
      Header: ADMIN.TOKENS.LABELS.ACTIVE_UNTIL,
      id: 'tokenActiveUntil',
      accessor: (row: IToken) =>
        isTokenDeactivated(row) ? row.revokedAt : row.expiresAt,
      sortable: true,
      className: 'ActiveUntilCell',
      Cell: ({ value }) => <DateText value={value} mark />,
    },
    {
      Header: ADMIN.TOKENS.LABELS.CREATED,
      accessor: 'createdAt',
      sortable: true,
      className: 'ActiveUntilCell',
      Cell: ({ value }) => <DateText value={value} />,
    },
    {
      Header: ADMIN.TOKENS.LABELS.LAST_USAGE,
      accessor: 'lastUsage',
      sortable: true,
      className: 'LastUsageAtCell',
      Cell: ({ value }) => <DateText value={value} />,
    },
  ]

  constructor(props: IProps & IActionProps & IStateProps) {
    super(props)
    this.reactTable = React.createRef()
  }

  get sortedTokens(): { _original: IToken }[] {
    return this.reactTable.current.getSortedData(
      this.reactTable.current.getResolvedState()
    ).sortedData
  }

  private selectedTokens = (): IToken[] =>
    this.props.organizationTokens.filter(t => this.state.selectedTokens.includes(t.id))

  private isTokenSelected = ({ id }: IToken): boolean =>
    this.state.selectedTokens.includes(id)

  private onSortedChange = (sorting: SortingRule[]) => {
    if (!sorting.length) {
      return this.setState({ sortedColumn: '', desc: true })
    }
    const { id, desc } = sorting[0]
    this.setState({ sortedColumn: id, desc })
  }

  private getColumns = (): Column[] => {
    return this.COLUMNS.map(col => {
      let mCol = { ...col }

      if (col.sortable) {
        mCol = {
          ...mCol,
          Header: (
            <HeaderCell
              isSorting={this.state.sortedColumn === (col.id ?? col.accessor)}
              text={col.Header as string}
              desc={this.state.desc}
            />
          ),
          sortable: true,
          sortMethod: (a, b) => {
            if (typeof a === 'string') {
              return a.localeCompare(b, 'en-US', { numeric: true })
            }
            return a === b ? 0 : a < b ? -1 : 1
          },
        }
      }

      const cellRenderer = mCol.Cell

      return { ...mCol, Cell: cellRenderer }
    })
  }

  private findSelectedIndex = (token: IToken) =>
    this.sortedTokens.findIndex((t: any) => t._original.id === token.id)

  private handleShiftCheckboxChange = (lastSelectedIndex: number, token: IToken) => {
    const index = this.findSelectedIndex(token)
    const toSelect = this.sortedTokens
      .slice(Math.min(lastSelectedIndex, index), Math.max(lastSelectedIndex, index) + 1)
      .filter(t => !isTokenDeactivated(t._original))
      .reduce((acc, t) => [...acc, t._original.id], [])

    this.setState(prev => ({
      selectedTokens: [...prev.selectedTokens, ...toSelect].filter(
        (v, i, a) => a.findIndex(t => t === v) === i
      ),
    }))
  }

  private handleNotShiftCheckboxChange = (checked: boolean, token: IToken) => {
    if (checked) {
      this.setState(prevState => ({
        lastSelectedIndex: this.findSelectedIndex(token),
        selectedTokens: [...prevState.selectedTokens, token.id],
      }))
    } else {
      this.setState(prevState => ({
        lastSelectedIndex: -1,
        selectedTokens: prevState.selectedTokens.filter(id => id !== token.id),
      }))
    }
  }

  private handleCheckboxRawChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    token: IToken
  ) => {
    const withShiftKey = (e.nativeEvent as MouseEvent)?.shiftKey
    const lastSelectedIndex = this.state.lastSelectedIndex
    const checked = e.target.checked

    if (withShiftKey && lastSelectedIndex > -1 && checked) {
      this.handleShiftCheckboxChange(lastSelectedIndex, token)
    } else {
      this.handleNotShiftCheckboxChange(checked, token)
    }
  }

  private onToggleDeactivateToken = () => {
    this.setState(prev => ({
      isDeactivateTokenModalOpen: !prev.isDeactivateTokenModalOpen,
    }))
  }

  private onToggleExtendToken = () => {
    this.setState(prev => ({
      isExtendTokenModalOpen: !prev.isExtendTokenModalOpen,
    }))
  }

  private onToggleRefreshToken = () => {
    this.setState(prev => ({
      isRefreshTokenModalOpen: !prev.isRefreshTokenModalOpen,
    }))
  }

  private onDeactivate = (tokens: IToken[]) => {
    this.props.deactivateTokens(tokens)
    this.setState({
      lastSelectedIndex: -1,
      selectedTokens: [],
    })
  }

  private onExtend = (tokens: IToken[], values: IExtendTokenForm) => {
    this.props.extendTokens(tokens, values.isoDate)
    this.setState({
      lastSelectedIndex: -1,
      selectedTokens: [],
    })
  }

  private onRefresh = (tokens: IToken[]) => {
    this.props.refreshTokens(tokens)
    this.setState({
      lastSelectedIndex: -1,
      selectedTokens: [],
    })
  }

  private allSelected = () => {
    const nonExpiredTokensCount = this.props.organizationTokens.reduce(
      (sum, t) => sum + (isTokenDeactivated(t) ? 0 : 1),
      0
    )
    return nonExpiredTokensCount === this.state.selectedTokens.length
  }

  private onToggleCheckAll = (status: boolean) => {
    let selectedTokens: number[] = []
    if (status) {
      selectedTokens = this.props.organizationTokens
        .filter(token => !isTokenDeactivated(token))
        .map(token => token.id)
    }
    this.setState({ selectedTokens })
  }

  render() {
    const { block, element } = bindBem('Tokens')
    const { organizationTokens, me } = this.props

    return (
      <div className={block()}>
        <div className={element('Header')}>
          <div>
            <h1>{ADMIN.TOKENS.TITLE}</h1>
            <h3>{organizationTokens.length} tokens</h3>
          </div>
          <div className="Buttons">
            {isHaverAdmin(me.role) && (
              <Button
                className={element('ExtendButton')}
                disabled={!this.state.selectedTokens.length}
                onClick={() => this.onToggleExtendToken()}
                text={ADMIN.TOKENS.BUTTONS.EXTEND}
                style="primary"
                size="small"
              />
            )}
            {isHaverAdmin(me.role) && (
              <Button
                className={element('RefreshButton')}
                disabled={!this.state.selectedTokens.length}
                onClick={() => this.onToggleRefreshToken()}
                text={ADMIN.TOKENS.BUTTONS.REFRESH}
                style="primary"
                size="small"
              />
            )}
            {isHaverAdmin(me.role) && (
              <Button
                className={element('DeactivateButton')}
                disabled={!this.state.selectedTokens.length}
                onClick={() => this.onToggleDeactivateToken()}
                text={ADMIN.TOKENS.BUTTONS.DEACTIVATE}
                style="danger"
                size="small"
              />
            )}
          </div>
        </div>

        <ReactTable
          ref={this.reactTable}
          className={element('Table')}
          data={organizationTokens}
          columns={this.getColumns()}
          onSortedChange={this.onSortedChange}
          sorted={[{ id: this.state.sortedColumn, desc: this.state.desc }]}
          showPagination={false}
          sortable={false}
          resizable={false}
          pageSize={organizationTokens.length}
          getTrProps={(_: any, rowInfo: RowInfo) => {
            const tokenStatus = getTokenStatus(rowInfo.original)
            if (
              tokenStatus === TokenStatus.EXPIRED ||
              tokenStatus === TokenStatus.DEACTIVATED
            ) {
              return {
                className: 'grayed-out',
              }
            }
            return {}
          }}
        />

        <Modal
          isOpen={this.state.isDeactivateTokenModalOpen}
          onClose={this.onToggleDeactivateToken}
        >
          <DeactivateTokensForm
            tokens={this.selectedTokens()}
            onClose={() => this.onToggleDeactivateToken()}
            onDeactivate={this.onDeactivate}
          />
        </Modal>

        <Modal
          isOpen={this.state.isExtendTokenModalOpen}
          onClose={this.onToggleExtendToken}
        >
          <ExtendTokensForm
            organization={this.props.organization}
            tokens={this.selectedTokens()}
            onClose={() => this.onToggleExtendToken()}
            onExtend={this.onExtend}
          />
        </Modal>

        <Modal
          isOpen={this.state.isRefreshTokenModalOpen}
          onClose={this.onToggleRefreshToken}
        >
          <RefreshTokensForm
            tokens={this.selectedTokens()}
            onClose={() => this.onToggleRefreshToken()}
            onRefresh={this.onRefresh}
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state: IRootState, { organization }: IProps): IStateProps => {
  const { user: me } = state.user
  const { users, tokens: organizationTokens } = state.admin
  const organizationUsers = (users || []).filter(
    u => organization && u.organizationId === organization.id
  )
  const usersMap = organizationUsers.reduce((map: IUsersMap, user: IUser) => {
    map[user.id] = user
    return map
  }, {})

  return {
    me,
    organizationTokens,
    usersMap,
  }
}
const mapDispatchToProps = (dispatch: TDispatch): IActionProps => ({
  deactivateTokens: (tokens: IToken[]) => dispatch(admin.deactivateTokens(tokens)),
  extendTokens: (tokens: IToken[], isoDate: Date | null) =>
    dispatch(admin.extendTokens(tokens, isoDate)),
  refreshTokens: (tokens: IToken[]) => dispatch(admin.refreshTokens(tokens)),
})

export default Sentry.withProfiler(connect(mapStateToProps, mapDispatchToProps)(Tokens))
