import * as React from 'react'

import Routing from './routing'
import Keep100vh from './Resizer'

import './styles/base.scss'
import 'react-sortable-tree/style.css?inline'

const App = () => (
  <Keep100vh>
    <Routing />
  </Keep100vh>
)

export default App
