import * as React from 'react'
import { bindBem } from '../bem'

import './Link.scss'

interface IProps {
  children?: string
  text?: string
  disabled?: boolean
  onClick: (e: React.MouseEvent) => void
}

export const Link = ({ children, onClick, text, disabled = false }: IProps) => {
  const { block } = bindBem('Link')

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()

    if (!disabled) {
      onClick(e)
    }
  }

  return (
    <a className={block({ disabled })} href="#" onClick={handleClick}>
      {children || text}
    </a>
  )
}
