import * as React from 'react'
import * as FauxDOM from 'react-faux-dom'
import * as d3 from 'd3'
import { XScale, YScale, renderArea } from '../../services/hd3'
import { COLORS, HIGHLIGHT_COLORS } from '../../services/series'

export interface IProps {
  series: IDataSeries
  xScale: XScale
  yScale: YScale
  hasLineShadow: boolean
}

const Area = ({ series, xScale, yScale, hasLineShadow }: IProps) => {
  const svg = FauxDOM.createElement('g')
  const selection = d3.select(svg)
  const richDataPoints: RichDataPoint[] = React.useMemo(
    () =>
      series.dataPoints.map(p => ({
        ...p,
        x: xScale(p.date),
        y: yScale(p.value),
      })),
    [series, xScale, yScale]
  )
  renderArea(
    selection,
    yScale,
    series,
    HIGHLIGHT_COLORS[series.colorIndex],
    COLORS[series.colorIndex],
    hasLineShadow,
    richDataPoints
  )

  return <>{React.Children.map(svg.toReact(), c => c.props.children)}</>
}

export default React.memo(Area)
