// eslint-disable max-lines
import { useFormik } from 'formik'
import * as React from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import * as yup from 'yup'

import { bindBem } from '../bem'
import { Button } from '../components/Button'
import { PrimaryInput } from '../components/Input'
import { Link as LinkComponent } from '../components/Link'
import AppLoader from '../components/AppLoader'
import * as messages from '../messages'
import { IRootState, TDispatch } from '../store'
import {
  loginUserWithToken,
  resendOtp,
  loginUserWithTokenAndOtp,
  setSessionData,
  logout,
} from '../store/User'
import { API_URL } from '../config'
import { USERS } from '../api/users'

import loginBtnLoader from './../static/loader@2x.gif'
import logo from './../static/haver-logotype.svg'

import './LoginForm.scss'
import { useEffect } from 'react'

export interface IProps {
  loginFailed: boolean
  loginPending: boolean
  loginError: string
}

export interface ILoginFormProps extends IProps {
  onLogin: (token: string) => void
  user: IUser
}

export interface IMFAFormProps extends IProps {
  sessionToken: string
  signatureHash: string
  setSessionData: (token?: string, signature?: string) => void
  onLoginWithOtp: (token: string, signature: string, otp: string) => void
  onOtpResend: (token: string) => void
  onLogout: () => void
}

const MFA_SCHEMA = yup.object({
  otp: yup
    .number()
    .required(messages.USER_FORM.VALIDATIONS.REQUIRED)
    .integer(messages.MFA_INCORRECT)
    .typeError(messages.MFA_INCORRECT),
})

interface IMFAFormValues {
  otp: string
}

export const Loader: React.FC = () => {
  const { element } = bindBem('LoginForm')

  return (
    <div className={element('AppLoader')}>
      <AppLoader />
    </div>
  )
}

export const MFAForm: React.FC<IMFAFormProps> = props => {
  const { sessionToken, signatureHash, onLogout } = props

  const onMFA = async ({ otp }: IMFAFormValues) => {
    if (!formik.isValid) {
      return
    }
    // coz API requires string
    await props.onLoginWithOtp(sessionToken, signatureHash, String(otp).trim())
  }

  const { errors, ...formik } = useFormik({
    validationSchema: MFA_SCHEMA,
    initialValues: { otp: '' },
    onSubmit: onMFA,
  })

  const { block, element } = bindBem('MFAForm')

  const loadingIcon = () => {
    if (props.loginPending) {
      return <img className={element('Loader')} src={loginBtnLoader} alt="Loading..." />
    }
  }

  const loadingText = () => {
    if (!props.loginPending) {
      return messages.CONTINUE
    }
  }

  return (
    <div className={block({ error: !!errors.otp || !!props.loginError })}>
      <div className={element('TopRow')}>
        {/*<Button type="button" text="Change account" onClick={onLogout} />*/}
      </div>
      <img className={element('Logo')} src={logo} alt="Haver Analytics" />
      <div className={element('Title')}>{messages.MFA_TITLE}</div>
      <div className={element('Info')}>{messages.MFA_SENT_RAW}</div>
      <form onSubmit={formik.handleSubmit}>
        <div className={element('Input')}>
          <PrimaryInput
            type="number"
            focus
            inputMode="numeric"
            pattern="[0-9]*"
            name="otp"
            value={formik.values.otp}
            error={!!errors.otp || !!props.loginError}
            label={messages.LABEL_TOKEN}
            onChange={formik.handleChange}
          />
          {(errors.otp || props.loginError) && (
            <div className={element('Error')}>{errors.otp || props.loginError}</div>
          )}
        </div>
        <div className={element('ResendOTP')}>
          <LinkComponent onClick={() => props.onOtpResend(sessionToken)}>
            {messages.RESEND_OTP}
          </LinkComponent>
        </div>
        <div className={element('Submit')}>
          <Button type="submit" text={loadingText()} icon={loadingIcon()} size="block" />
        </div>
      </form>
    </div>
  )
}

interface ILoginContainerProps extends ILoginFormProps, IMFAFormProps {
  mfaRequired: boolean
}

const useHashData = (setSessionData: ILoginContainerProps['setSessionData']) => {
  const { hash } = useLocation()
  const hashExists = (data: string) => data.startsWith('#') && data.length > 1

  useEffect(() => {
    if (hashExists(hash)) {
      const hashParams = new URLSearchParams(hash.slice(1))

      const signature = hashParams.get('signature')
      const token = hashParams.get('token')

      setSessionData(token, signature)

      window.history.replaceState(null, document.title, location.href.split('#')[0])
    }
  }, [hash])

  return hashExists(hash)
}

export const LoginContainer: React.FC<ILoginContainerProps> = props => {
  const {
    onLogin,
    onLoginWithOtp,
    onOtpResend,
    setSessionData,
    loginPending,
    loginFailed,
    loginError,
    sessionToken,
    signatureHash,
    onLogout,
  } = props

  const hash = useHashData(setSessionData)

  useEffect(() => {
    if (sessionToken && !signatureHash) {
      onLogin(sessionToken)
    }
  }, [sessionToken, signatureHash])

  useEffect(() => {
    if (!hash && !sessionToken && !signatureHash) {
      window.location.replace(`${API_URL}${USERS.LOGIN}`)
    }
  }, [hash])

  if (sessionToken && signatureHash) {
    return (
      <MFAForm
        loginError={loginError}
        onLoginWithOtp={onLoginWithOtp}
        onOtpResend={onOtpResend}
        loginPending={loginPending}
        loginFailed={loginFailed}
        setSessionData={setSessionData}
        sessionToken={sessionToken}
        signatureHash={signatureHash}
        onLogout={onLogout}
      />
    )
  }

  return <Loader />
}

const mapDispatchToProps = (dispatch: TDispatch): Partial<ILoginContainerProps> => {
  return {
    onLogin: (sessionToken: string) => dispatch(loginUserWithToken(sessionToken)),
    onLoginWithOtp: (sessionToken: string, signature: string, otp: string) =>
      dispatch(loginUserWithTokenAndOtp(sessionToken, signature, otp)),
    onOtpResend: (sessionToken: string) => dispatch(resendOtp(sessionToken)),
    setSessionData: (sessionToken?: string, signatureHash?: string) =>
      dispatch(setSessionData(sessionToken, signatureHash)),
    onLogout: () => dispatch(logout()),
  }
}

const mapStateToProps = ({
  user: {
    loginError,
    loginFailed,
    mfaRequired,
    loginPending,
    signatureHash,
    sessionToken,
    user,
  },
}: IRootState): Partial<ILoginContainerProps> => {
  return {
    loginFailed,
    mfaRequired,
    loginPending,
    loginError,
    signatureHash,
    sessionToken,
    user,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
