import * as _ from 'lodash'
import * as React from 'react'
import Draggable from 'react-draggable'
import { CSSTransition } from 'react-transition-group'

import { bindBem } from '../../bem'
import Chart from '../../components/AChart'
import { EditTitlesDialog } from '../../components/EditTitlesDialog'
import { AsideDialog } from '../../components/Modal'
import { FCTable as Table } from '../../components/Series/Table'
import { SERIES_CONTAINER_ID } from '../../constants'
import { EDIT_TITLES_DIALOG } from '../../messages'
import { Props, collapsedTableHeight } from './common'

import './Desktop.scss'

const getRelativeHeight = (totalHeight: number, ratio: number) => {
  return Math.floor(ratio * totalHeight)
}

export class DesktopSeriesContainer extends React.PureComponent<Props> {
  handleDrag = _.throttle((_e, ui) => {
    this.props.setResizeBarPosition(ui.y)
  }, 40)

  onModalOpen = (isOpen: boolean) => this.props.setNamesModal(isOpen)
  openModal = () => this.onModalOpen(true)

  getHeightRatio = () => {
    const { isTableHidden, isToggleButtonClicked } = this.props
    const CHART_ONLY = 1
    const DEFAULT_RATIO = 1 / 2
    const SMALL_SCREEN = 2 / 3
    if (isTableHidden && isToggleButtonClicked) {
      return CHART_ONLY
    }
    return this.props.size.height < 1024 ? SMALL_SCREEN : DEFAULT_RATIO
  }

  toggleTableHidden = () => this.props.setTableHidden(!this.props.isTableHidden)

  render() {
    const { block, element } = bindBem('SeriesContainer')
    const { variables, onEditTitles, settings, isLoading, scatterSettings, correlation } =
      this.props
    const { recessions, recessionType, isEditingTitles, isLegendShown, offsetSeries } =
      this.props
    const { size, scrollByPeriod, scale, startZoomDate, endZoomDate } = this.props
    const { isTableHidden, isToggleButtonClicked } = this.props
    const heightRatio = this.getHeightRatio()

    let chartHeight = getRelativeHeight(size.height, heightRatio)
    let tableHeight = getRelativeHeight(size.height, 1 - heightRatio)
    const originalChartHeight = chartHeight

    if (isTableHidden && isToggleButtonClicked) {
      chartHeight = size.height - collapsedTableHeight
      tableHeight = collapsedTableHeight
    } else {
      chartHeight += this.props.resizeBarPosition
      tableHeight -= this.props.resizeBarPosition
    }

    const minChartHeight = getRelativeHeight(size.height, 0.35)
    const minTableHeight = getRelativeHeight(size.height, 0.25)

    if (isLoading) {
      return <div />
    }

    const dragHandlers = {
      onDrag: this.handleDrag,
      axis: 'y',
      bounds: {
        top: -1 * (originalChartHeight - minChartHeight),
        bottom: size.height - originalChartHeight - minTableHeight,
      },
      position: { y: this.props.resizeBarPosition },
    }

    return (
      <div className={block()} id={SERIES_CONTAINER_ID}>
        <div className={element('Series')}>
          <Chart
            variables={variables}
            size={{ width: size.width, height: chartHeight }}
            startZoomDate={startZoomDate}
            endZoomDate={endZoomDate}
            settings={settings}
            onEditTitle={this.openModal}
            recessions={recessions}
            recessionType={recessionType}
            isLegendShown={isLegendShown}
            scatterSettings={scatterSettings}
            scrollByPeriod={scrollByPeriod}
            scale={scale}
            stackedConfig={this.props.stackedConfig}
            correlation={correlation}
            trendlineBoundaries={this.props.trendlineBoundaries}
          />
        </div>

        {!(isTableHidden && isToggleButtonClicked) && (
          <Draggable {...dragHandlers}>
            <div className={element('ResizeHandler')} />
          </Draggable>
        )}

        <CSSTransition
          in={isToggleButtonClicked}
          classNames="Table"
          timeout={300}
          onEntered={this.toggleTableHidden}
          onExited={this.toggleTableHidden}
        >
          <Table
            isHidden={isTableHidden && isToggleButtonClicked}
            handleToggle={this.props.setToggleTable}
            variables={offsetSeries}
            frequency={Math.max(...variables.map(v => v.frequency)) as Frequency}
            height={tableHeight}
            width={size.width}
            removeInterpolation={this.props.removeInterpolation}
            removeTransformation={this.props.removeTransformation}
            isScatterPlot={this.props.settings.isScatterPlot}
            stats={this.props.stats}
          />
        </CSSTransition>
        <AsideDialog
          onClose={() => this.onModalOpen(false)}
          isOpen={isEditingTitles}
          title={EDIT_TITLES_DIALOG.TITLE}
        >
          <EditTitlesDialog
            onClose={() => this.onModalOpen(false)}
            onSave={onEditTitles}
            series={variables}
            titles={settings.titles}
            title={settings.title}
            isLegendShown={isLegendShown}
          />
        </AsideDialog>
      </div>
    )
  }
}
