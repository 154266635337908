import * as React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { IRootState } from 'store'
import { isSuperAdmin } from 'services/user'

import { ADMIN } from '../../messages'

import './AdminHeader.scss'

export interface IStateProps {
  me: IUser
}

const AdminHeader = ({ me }: IStateProps) => (
  <div className="AdminHeader">
    <NavLink to="/admin/organizations" activeClassName="active">
      {ADMIN.ORG.TITLE}
    </NavLink>
    {isSuperAdmin(me.role) && (
      <>
        <NavLink to="/admin/databases" activeClassName="active">
          {ADMIN.DB.TITLE}
        </NavLink>
        <NavLink to="/admin/packages" activeClassName="active">
          {ADMIN.PACKAGES.TITLE}
        </NavLink>
        <NavLink to="/admin/menus" activeClassName="active">
          {ADMIN.MENUS.TITLE}
        </NavLink>
      </>
    )}
  </div>
)

const mapStateToProps = (state: IRootState): IStateProps => {
  const { user: me } = state.user

  return {
    me,
  }
}

export default connect(mapStateToProps)(AdminHeader)
