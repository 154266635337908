import { format, getQuarter } from 'date-fns'

export const formatAnnually = (date: Date) => {
  return format(date, 'yyyy')
}

export const formatQuarterly = (date: Date) => {
  const quarter = getQuarter(date)
  return `Q${quarter}`
}

export const formatMonthlyWithYear = (date: Date) => format(date, 'MMM yy').toUpperCase()

export const formatMonthly = (date: Date) => format(date, 'MMM').toUpperCase()

export const even = (fn: (date: Date) => string) => (d: Date, index: number) =>
  index % 2 ? fn(d) : ''
