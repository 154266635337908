import React from 'react'

import { fetchCurrencies } from '../../api2/currencies'
import { FUNCTIONS_DIALOG } from '../../messages'
import { OptionsListDialog } from './OptionsListDialog'

import './CurrencyDialog.scss'

export interface IProps {
  currency?: string
  onSubmit: (currency?: string) => void
}

export interface IState {
  currencies: ICurrency[]
  filter: string
  currency?: string
}

const currencyToISOName = (c: ICurrency) => `${c.name} (${c.iso}) (${c.countryArea})`

const nameToCurrency = (currencies: ICurrency[], nameWithISO: string) =>
  currencies.find(c => currencyToISOName(c) === nameWithISO)

class CurrencyDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = { currencies: [], filter: '', currency: this.props.currency }
  }
  async componentDidMount() {
    const c = await fetchCurrencies()
    this.setState({ currencies: c })
  }

  render() {
    return (
      <OptionsListDialog
        currentValue={this.props.currency}
        values={this.state.currencies.map(currencyToISOName)}
        onClose={this.submit}
        title={FUNCTIONS_DIALOG.CURRENCY_DIALOG_TITLE}
      />
    )
  }
  private submit = (value: string) => {
    if (!value) {
      return this.props.onSubmit(null)
    }
    const curr = nameToCurrency(this.state.currencies, value)
    this.props.onSubmit(curr.iso)
  }
}

export default CurrencyDialog
