import * as React from 'react'
import { connect } from 'react-redux'
import { IRootState } from '../../../store'
import { bindBem } from '../../bem'
import { KebabMenu } from '../KebabMenu'
import { Link } from '../Link'
import { ADMIN } from '../../messages'

import './OrgKebabMenu.scss'
import { isSuperAdmin } from 'services/user'

interface IProps {
  isActive: boolean
  onDelete: () => void
  onActivate: () => void
  onDeactivate: () => void
  onDuplicate: () => void
}

export interface IStateProps {
  me: IUser
}

export const OrgKebabMenu: React.FunctionComponent<IProps & IStateProps> = ({
  me,
  isActive,
  onDelete,
  onActivate,
  onDeactivate,
  onDuplicate,
}) => {
  const { block } = bindBem('OrgKebabMenu')

  return (
    <div className={block()}>
      <KebabMenu>
        <Link onClick={onDuplicate}>{ADMIN.ORG.ACTIONS.LABELS.DUPLICATE}</Link>
        {isSuperAdmin(me.role) && (
          <Link onClick={isActive ? onDeactivate : onActivate}>
            {isActive
              ? ADMIN.ORG.ACTIONS.LABELS.DEACTIVATE
              : ADMIN.ORG.ACTIONS.LABELS.ACTIVATE}
          </Link>
        )}
        {isSuperAdmin(me.role) && (
          <Link onClick={onDelete}>{ADMIN.ORG.ACTIONS.LABELS.DELETE}</Link>
        )}
      </KebabMenu>
    </div>
  )
}

const mapStateToProps = (state: IRootState): IStateProps => {
  const { user: me } = state.user

  return {
    me,
  }
}

export default connect(mapStateToProps)(OrgKebabMenu)
