import * as React from 'react'
import { bindBem } from '../bem'
import { RadioContext, IContextProps } from './RadioGroup'

import './SmallRadioButton.scss'

export interface IProps {
  value: string
  children?: any
  selected: boolean
  onChange: (value: string) => void
}

export const SmallRadioOption = ({ value, children, selected, onChange }: IProps) => {
  const { block, element } = bindBem('SmallRadioButton')
  const size = 13
  const middle = size / 2
  const CIRCLE_ICON = (
    <svg width={size} height={size}>
      {!selected && (
        <circle r="5.5" cx={middle} cy={middle} stroke="#c5e3f4" fill="white" />
      )}
      {selected && (
        <g>
          <circle r="6.5" cx={middle} cy={middle} fill="#3fa2db" />
          <circle r="2.5" cx={middle} cy={middle} fill="white" />
        </g>
      )}
    </svg>
  )
  return (
    <div
      tabIndex={1}
      className={block()}
      onClick={() => onChange(value)}
      data-value={value}
    >
      <span className={element('Label')}>{children}</span>
      {CIRCLE_ICON}
    </div>
  )
}

export default (props: { value: string; children: React.ReactNode }) => (
  <RadioContext.Consumer>
    {({ selectedValue, onChange }: IContextProps) => (
      <SmallRadioOption
        onChange={onChange}
        selected={props.value === selectedValue}
        value={props.value}
      >
        {props.children}
      </SmallRadioOption>
    )}
  </RadioContext.Consumer>
)
