import { API_URL } from '../config'
import Axios, { AxiosError, Cancel } from 'axios'

export const CLIENT = (() => {
  const token = localStorage.getItem('sessionToken')
  const headers: SMap<string> = {}

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  const client = Axios.create({
    baseURL: `${API_URL}/`,
    headers,
  })

  return client
})()

export interface IBadRequestErrorData {
  [attributeName: string]: string[]
}

export interface RequestError extends AxiosError, Cancel {
  __CANCEL__: boolean
}
