// eslint-disable max-lines
import * as d3 from 'd3'
// bugfix: building issue with using precompiled package
import d3Regression from 'd3-regression/dist/d3-regression.js'
import { isAfter } from 'date-fns'
import { createSelector } from 'reselect'

import { calculateAxis, generateTicks, zip } from '../../utils'
import * as hd3 from '../hd3'
import { isWeekly } from '../series'

const SCATTER_COLOR = '#9e5ada'
const TICKS_NUMBER = 7

const getSeries = (s: IDataSeries) => s

const getValuesSelector = createSelector(getSeries, series =>
  series.dataPoints.map(dp => dp.value)
)

const getTwoSeries = ([s1, s2, ..._reset]: IDataSeries[]) => [s1, s2]

const getDataSelector = createSelector(getTwoSeries, ([s1, s2]) => {
  if (!s1.dataPoints.length || !s2.dataPoints.length) {
    return []
  }
  const getIndex = (a: IDataSeries, b: IDataSeries) =>
    hd3.getDataPointBisector().left(a.dataPoints, b.dataPoints[0])

  const indices = isAfter(s1.dataPoints[0].date, s2.dataPoints[0].date)
    ? [0, getIndex(s2, s1)]
    : [getIndex(s1, s2), 0]

  const filterNullValues = (arr: IDataPoint[]) =>
    arr[0].value !== null && arr[1].value !== null

  return zip(s1.dataPoints.slice(indices[0]), s2.dataPoints.slice(indices[1])).filter(
    filterNullValues
  )
})

export function getLinearScale(width: number, height: number, domain: number[]) {
  const y = d3.scaleLinear().range([height, width])
  y.domain(domain)
  return y
}

export function getValuesExtent(series: IDataSeries, margin = 0.02) {
  const [min = 0, max = 0] = d3.extent(getValuesSelector(series))
  const diff = (max - min) * margin
  return [min - diff, max + diff]
}

export function getXAxis(x: hd3.ScatterScale) {
  const axis = d3.axisBottom(x)
  axis.scale(x)
  axis.tickSizeInner(6)
  axis.tickValues(getTicks(x))
  return axis
}

export function isScatterScale(scale: hd3.AnyXScale): scale is hd3.ScatterScale {
  return (scale as hd3.XScale).discontinuityProvider === undefined
}
export const isNormalScale = (scale: hd3.AnyXScale): scale is hd3.XScale =>
  !isScatterScale(scale)

export function renderXAxis(svg: D3Selection, x: hd3.ScatterScale, height: number) {
  const axis = getXAxis(x)
  svg
    .append('g')
    .attr('class', 'y axis bottom')
    .attr('transform', `translate(0, ${height})`)
    .call(axis)
    .selectAll('text')
    .attr('dy', 10)
}

export function renderYAxis(svg: D3Selection, y: hd3.YScale, width: number) {
  const [min, max] = y.domain()
  const axis = calculateAxis(min, max)
  const ticks = generateTicks([axis.min, axis.max], axis.ticks)
  return hd3.renderYAxis(svg, y, width, ticks, 'left')
}

function addClip(svg: D3Selection, xDomain: number[], yDomain: number[]) {
  svg
    .append('defs')
    .append('clipPath')
    .attr('id', 'clip-regression')
    .append('rect')
    .attr('x', xDomain[0])
    .attr('width', xDomain[1])
    .attr('y', yDomain[1])
    .attr('height', yDomain[0])
}

function renderRegression(
  svg: D3Selection,
  values: IDataPoint[][],
  type: RegressionType,
  scaleX: hd3.ScatterScale,
  scaleY: hd3.YScale
) {
  if (type === 'none') {
    return
  }
  const regressions: {
    [key in RegressionType]: d3Regression.IRegression<IDataPoint[]>
  } = {
    linear: d3Regression.regressionLinear(),
    exponential: d3Regression.regressionExp(),
    logarithmic: d3Regression.regressionLog(),
    none: null,
  }

  const regression = regressions[type]
    .x(([x, _y]) => x.value)
    .y(([_x, y]) => y.value)
    .domain(scaleX.domain())
  const line = d3
    .line<number[]>()
    .x(p => scaleX(p[0]))
    .y(p => scaleY(p[1]))
  addClip(svg, scaleX.range(), scaleY.range())
  svg
    .append('g')
    .attr('class', 'scatter-regression')
    .append('path')
    .datum(regression(values))
    .attr('stroke', '#485465')
    .attr('stroke-linejoin', 'round')
    .attr('stroke-linecap', 'round')
    .attr('stroke-width', 2)
    .attr('fill', 'none')
    .attr('stroke-dasharray', '10, 10')
    .attr('d', line)
    .attr('clip-path', 'url(#clip-regression)')
}

export function renderLine(
  svg: D3Selection,
  scaleX: hd3.ScatterScale,
  scaleY: hd3.YScale,
  values: IDataPoint[][]
) {
  const line = d3
    .line<IDataPoint[]>()
    .x(([x, _y]) => scaleX(x.value))
    .y(([_x, y]) => scaleY(y.value))

  svg
    .append('path')
    .datum(values)
    .attr('class', 'scatter-timeline')
    .attr('stroke-linejoin', 'round')
    .attr('stroke-linecap', 'round')
    .attr('stroke-width', 1.5)
    .attr('stroke', SCATTER_COLOR)
    .attr('opacity', 0.6)
    .attr('fill', 'none')
    .attr('d', line)
}

export function renderScatter(
  svg: D3Selection,
  scaleX: hd3.ScatterScale,
  scaleY: hd3.YScale,
  variables: IDataSeries[],
  onHover: (data: IDataPoint[]) => void,
  settings: IScatterSettings
) {
  const [s1, s2] = variables
  const data = getDataSelector(variables)
  const key = `${s1.uuid}-${s2.uuid}`
  const radius = 4
  if (settings.isTimelineEnabled) {
    renderLine(svg, scaleX, scaleY, data)
  }
  svg
    .append('g')
    .attr('class', `scatter scatter-${key}`)
    .selectAll('circle')
    .data(data)
    .enter()
    .append('circle')
    .attr('class', `scatter-circle scatter-circle-${key}`)
    .attr('cx', ([x, _y]) => scaleX(x.value))
    .attr('cy', ([_x, y]) => scaleY(y.value))
    .attr('r', radius)
    .attr('clip-path', `url(#${hd3.CLIP_PATH_ID})`)
    .attr('fill', SCATTER_COLOR)
    .on('mouseover', onHover)
    .on('mouseout', onHover)
  renderRegression(svg, data, settings.regressionType, scaleX, scaleY)
}

export const isApplicable = (variables: IDataSeries[]) => {
  if (variables.length < 2) {
    return false
  }
  const [f1, f2] = variables.map(v => v.frequency)
  return f1 === f2 || (isWeekly(f1) && isWeekly(f2))
}

const getTicks = (x: hd3.ScatterScale) => {
  const [min, max] = x.domain()
  const step = (max - min) / (TICKS_NUMBER - 1)
  return new Array(TICKS_NUMBER).fill(1).map((_num, i) => min + step * i)
}

export const renderYGrid = (svg: D3Selection, x: hd3.ScatterScale, height: number) => {
  const ticks = getTicks(x)
  const selection = svg
    .append('g')
    .attr('class', 'grid')
    .call(
      d3
        .axisBottom(x)
        .scale(x)
        .tickSize(height)
        .tickFormat(_v => '')
        .tickValues(ticks)
    )
  selection.select('.domain').remove()
  hd3.renderAxisStyles(selection)
}
