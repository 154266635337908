// eslint-disable max-lines

import * as React from 'react'
import * as yup from 'yup'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import { replace, push } from 'connected-react-router'

import { bindBem } from '../../bem'
import { getOrgSchema } from '../../utils'
import { ORGANIZATION_FORM } from '../../messages'

import { Button } from '../Button'
import { Labeled, Input, TextArea } from '../Input'
import { OrganizationTypeSelect } from '../OrganizationTypeSelect'
import DbConfigSelect from '../DbConfigSelect'
import { DatePicker, UTCDate } from '../DatePicker'

import { OrganizationTabs } from './TabbedSelectors'

import { IBadRequestErrorData } from 'api/config'
import { BigSwitch } from './BigSwitch'
import { MfaStatusTooltip } from './MfaStatusTooltip'
import { IpRestrictionStatusTooltip } from './IpRestrictionStatusTooltip'

import { orgStatusAsText, orgStatusClassName, orgStatusResolver } from './utils'

import { ORGANIZATION } from '../../toasts'
import OrgKebabMenu from 'components/Admin/OrgKebabMenu'
import * as admin from 'store/Admin'
import { DeactivateOrgConfirmation } from 'components/Admin/DeactivateOrgConfirmation'
import { Modal } from 'components/Modal'
import AddOrgForm from 'components/Admin/AddOrgForm'
import { IAddOrgForm } from 'api2/admin'
import { RemoveOrgConfirmation } from 'components/Admin/RemoveOrgConfirmation'
import { isHaverAdmin } from 'services/user'
import { IRootState } from 'store'

import backButton from '../../static/back-btn.svg'

import './EditOrgForm.scss'

export enum Fields {
  NAME,
  ACCOUNT_NUMBER,
  TYPE,
  USER_LIMIT,
  ORG_STATUS,
  RENEWAL_DATE,
  MENU_ORDER,
  MFA_STATUS,
  IS_RESTRICTED,
  CIDRS,
  RATELIMIT,
  IS_MIGRATED,
}

export interface IStateProps {
  me: IUser
}

export interface IProps {
  org: IOrganization
  databases: { id: string; name: string }[]
  packages: IDBPackage[]
  onEdit: (
    id: number,
    org: Partial<IOrganization>,
    onError: (errors: IBadRequestErrorData) => void
  ) => void
  goBack: () => void
  onDelete: () => void
  openToast?: (toast: Partial<IToast>) => void
  interactive: boolean
  hideFields?: Fields[]
}

export interface IOrganizationIndexable extends IOrganization {
  [key: string]: string | number | string[] | boolean | Date
}

export interface IActionProps {
  createOrganization: (
    form: IAddOrgForm,
    onError: (errors: IBadRequestErrorData) => void,
    onSuccess: () => void
  ) => void
  activateOrganization: (org: IOrganization) => void
  deactivateOrganization: (org: IOrganization) => void
  deleteOrganization: (org: IOrganization) => void
  replaceUrl: (url: string) => void
  pushUrl: (url: string) => void
}

interface IState {
  org: IOrganization
  validation: SMap<string>
  isDeactivateOrgModalOpen: boolean
  isAddOrgModalOpen: boolean
  isDeleteOrgModalOpen: boolean
}

export class EditOrgForm extends React.Component<
  IProps & IActionProps & IStateProps,
  IState
> {
  state: IState = {
    org: { ...this.props.org },
    validation: {
      name: null,
      usersLimit: null,
      accountNumber: null,
      cidrs: null,
    },
    isDeactivateOrgModalOpen: false,
    isAddOrgModalOpen: false,
    isDeleteOrgModalOpen: false,
  }
  private schema = getOrgSchema()

  componentDidMount() {
    this.updateState()
  }

  componentDidUpdate(prevProps: IProps) {
    if (!_.isEqual(prevProps.org, this.props.org)) {
      this.updateState()
    }
  }

  updateState() {
    const { org } = this.props
    this.setState(() => ({ org }))
  }

  render() {
    const { interactive } = this.props
    const { validation, org } = this.state
    const orgStatus = orgStatusResolver(org)

    const { block, element } = bindBem('EditOrgForm')

    return (
      <div className={block()}>
        <div className={element('Header')}>
          {isHaverAdmin(this.props.me.role) && (
            <Button
              icon={<img src={backButton} />}
              className="Back"
              size="small"
              style="pale"
              onClick={this.goToList}
            />
          )}
          <div className={element('Title')}> {this.props.org.name}</div>
          {interactive && (
            <div className={element('OrgMenu')}>
              <OrgKebabMenu
                isActive={this.props.org.status === true}
                onDelete={() => this.onToggleDeleteOrgModal()}
                onActivate={() => this.activateOrg()}
                onDeactivate={() => this.onToggleDeactivateOrgModal()}
                onDuplicate={() => this.onToggleAddOrgModal()}
              />
            </div>
          )}
        </div>
        <div className={element('Content')}>
          <div className={element('Settings')}>
            {!this.props.hideFields?.includes(Fields.IS_MIGRATED) && (
              <Labeled label={ORGANIZATION_FORM.IS_MIGRATED}>
                <div className="BigSwitchContainer">
                  <BigSwitch
                    value={org.isMigrated}
                    onChange={this.onMigrationStatusChange}
                    disabled={!interactive}
                    labelOn="YES"
                    labelOff="NO"
                  />
                </div>
              </Labeled>
            )}

            {!this.props.hideFields?.includes(Fields.NAME) && (
              <Labeled label={ORGANIZATION_FORM.NAME_LABEL} error={validation.name}>
                <Input
                  name="name"
                  onChange={e => this.onFieldChange('name', e.currentTarget.value)}
                  onBlur={this.onFieldChangeBlur('name')}
                  value={org.name}
                  error={!!validation.name}
                  placeholder={ORGANIZATION_FORM.NAME_PLACEHOLDER}
                  disabled={!interactive}
                />
              </Labeled>
            )}

            {!this.props.hideFields?.includes(Fields.ACCOUNT_NUMBER) && (
              <Labeled
                label={ORGANIZATION_FORM.ACCOUNT_NUMBER_LABEL}
                error={validation.accountNumber}
              >
                <Input
                  name="accountNumber"
                  onChange={e =>
                    this.onFieldChange('accountNumber', e.currentTarget.value)
                  }
                  onBlur={this.onFieldChangeBlur('accountNumber')}
                  value={org.accountNumber}
                  error={!!validation.accountNumber}
                  placeholder={ORGANIZATION_FORM.ACCOUNT_NUMBER_PLACEHOLDER}
                  disabled={!interactive}
                />
              </Labeled>
            )}

            {!this.props.hideFields?.includes(Fields.TYPE) && (
              <Labeled label={ORGANIZATION_FORM.TYPE_LABEL}>
                <OrganizationTypeSelect
                  value={org.type}
                  onSelect={this.onTypeChange}
                  disabled={!interactive}
                />
              </Labeled>
            )}

            {!this.props.hideFields?.includes(Fields.USER_LIMIT) && (
              <Labeled
                label={ORGANIZATION_FORM.USER_LIMIT_LABEL}
                error={validation.usersLimit}
              >
                <Input
                  name="usersLimit"
                  type="number"
                  onChange={e =>
                    this.onFieldChange<number>(
                      'usersLimit',
                      Number(e.currentTarget.value)
                    )
                  }
                  onBlur={this.onFieldChangeBlur('usersLimit')}
                  value={org.usersLimit}
                  error={!!validation.usersLimit}
                  placeholder={ORGANIZATION_FORM.USER_LIMIT_PLACEHOLDER}
                  disabled={!interactive}
                />
              </Labeled>
            )}

            {!this.props.hideFields?.includes(Fields.ORG_STATUS) && (
              <Labeled label={ORGANIZATION_FORM.ORG_STATUS}>
                <div className={element('OrgStatus')}>
                  <span
                    className={`OrgStatusDot ${orgStatusClassName(orgStatus)}`}
                  ></span>
                  <span className="OrgStatusText">{orgStatusAsText(orgStatus)}</span>
                </div>
              </Labeled>
            )}

            {!this.props.hideFields?.includes(Fields.RENEWAL_DATE) && (
              <Labeled label={ORGANIZATION_FORM.RENEWAL_DATE}>
                <div className={element('RenewalDate')}>
                  <DatePicker
                    value={
                      org.softTerminationDate
                        ? UTCDate.fromISOString(org.softTerminationDate.toISOString())
                        : null
                    }
                    onChange={this.onRenewalDateChange}
                    name="renewalDate"
                    disabled={!interactive}
                  />
                </div>
              </Labeled>
            )}

            {!this.props.hideFields?.includes(Fields.MENU_ORDER) && (
              <Labeled label={ORGANIZATION_FORM.MENU_LAYOUT}>
                <DbConfigSelect
                  value={org.dbConfigName}
                  onSelect={this.onDbConfigNameChange}
                  disabled={!interactive}
                />
              </Labeled>
            )}

            {!this.props.hideFields?.includes(Fields.RATELIMIT) && (
              <Labeled label={ORGANIZATION_FORM.RATELIMIT} error={validation.ratelimit}>
                <Input
                  name="ratelimit"
                  error={!!validation.ratelimit}
                  value={org.ratelimit}
                  placeholder={ORGANIZATION_FORM.PLACEHOLDERS.RATELIMIT}
                  onChange={e =>
                    this.onFieldChange<string>('ratelimit', e.currentTarget.value)
                  }
                  onBlur={this.onFieldChangeBlur('ratelimit')}
                  disabled={!interactive}
                />
              </Labeled>
            )}

            {!this.props.hideFields?.includes(Fields.MFA_STATUS) && (
              <Labeled label={ORGANIZATION_FORM.MFA_STATUS_LABEL}>
                <div className="BigSwitchContainer">
                  <BigSwitch
                    value={org.mfaStatus}
                    onChange={this.onMFAStatusChange}
                    disabled={!interactive}
                  />
                  <MfaStatusTooltip id="edit-form-mfastatus" />
                </div>
              </Labeled>
            )}

            {!this.props.hideFields?.includes(Fields.IS_RESTRICTED) && (
              <Labeled label={ORGANIZATION_FORM.IS_RESTRICTED}>
                <div className="BigSwitchContainer">
                  <BigSwitch
                    value={org.isRestricted}
                    onChange={this.onIpRestrictionChange}
                    disabled={!interactive}
                  />
                </div>
              </Labeled>
            )}

            {!this.props.hideFields?.includes(Fields.CIDRS) && (
              <Labeled label={ORGANIZATION_FORM.CIDRS} error={validation.cidrs}>
                <div className="FieldContainer Unbounded">
                  <TextArea
                    name="cidrs"
                    error={!!validation.cidrs}
                    value={this.cidrsToText(org.cidrs)}
                    placeholder="127.0.0.1/32&#10;8.8.8.8/24"
                    onChange={this.onCidrsChange}
                    onBlur={this.onCidrsBlur}
                    disabled={!interactive}
                  />
                  <IpRestrictionStatusTooltip id="add-form-ip-restriction" />
                </div>
              </Labeled>
            )}
          </div>

          {/* <this.OrganizationTabsMemoized
            databases={this.props.databases}
            packages={this.props.packages}
            selectedDatabases={org.databases}
            databasesCount={org.databasesCount}
            previewedDatabases={org.previewedDatabases}
            selectedPackages={org.packages}
            onDatabasesChange={this.onDatabasesChange}
            onPackagesChange={this.onPackagesChange}
            onDatabasesImport={this.onImportedDatabases}
          /> */}
        </div>
        <Modal
          isOpen={this.state.isDeactivateOrgModalOpen}
          onClose={this.onToggleDeactivateOrgModal}
        >
          <DeactivateOrgConfirmation
            callback={this.confirmDeactivateOrg}
            onClose={this.onToggleDeactivateOrgModal}
          />
        </Modal>
        <Modal isOpen={this.state.isAddOrgModalOpen} onClose={this.onToggleAddOrgModal}>
          <AddOrgForm
            templateOrg={this.props.org}
            databases={this.props.databases}
            packages={this.props.packages}
            onSave={this.props.createOrganization}
            onClose={this.onToggleAddOrgModal}
            openToast={this.props.openToast}
          />
        </Modal>
        <Modal
          isOpen={this.state.isDeleteOrgModalOpen}
          onClose={this.onToggleDeleteOrgModal}
        >
          <RemoveOrgConfirmation
            callback={this.confirmDeleteOrg}
            onClose={this.onToggleDeleteOrgModal}
            obj={this.props.org}
          />
        </Modal>
      </div>
    )
  }

  private OrganizationTabsMemoized = React.memo(OrganizationTabs)

  private cidrsToText = (cidrs: string[]): string => {
    return cidrs.join('\n')
  }

  private onImportedDatabases = (count: number) => {
    this.props.openToast(ORGANIZATION.DATABASES_IMPORTED(count))
  }

  private setError = (field: string, error: string) => {
    this.setState(current => ({
      ...current,
      validation: { ...current.validation, [field]: error },
    }))
  }

  private onPatchError = (errors: IBadRequestErrorData) => {
    Object.keys(errors).forEach(key => {
      this.setError(key, errors[key].join(', '))
    })
  }

  private onPatch = (data: Partial<IOrganization>) => {
    this.setState(() => ({ validation: {} }))
    this.props.onEdit(this.props.org.id, data, this.onPatchError)
  }

  private onFieldChange = <T,>(field: string, value: T): void => {
    this.setState(() => ({ org: { ...this.state.org, [field]: value } }))
  }

  private onCidrsBlur = () => {
    const { cidrs } = this.state.org
    this.onPatch({ cidrs: cidrs.filter(Boolean) })
  }

  private onCidrsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const cidrs = e.currentTarget.value.split('\n')
    this.setState(() => ({ org: { ...this.state.org, cidrs } }))
  }

  private onRenewalDateChange = (utcDate?: UTCDate) => {
    const softTerminationDate = utcDate?.toJSDate() ?? null

    this.setState(
      () => ({ org: { ...this.state.org, softTerminationDate } }),
      () => this.onPatch({ softTerminationDate })
    )
  }

  private onFieldChangeBlur = (field: string) => () =>
    this.validate(() =>
      this.onPatch({
        [field]: (this.state.org as IOrganizationIndexable)[field],
      })
    )

  private onDatabasesChange = (databases: string[], previewedDatabases: string[]) => {
    this.setState(
      () => ({ org: { ...this.state.org, databases, previewedDatabases } }),
      () => this.onPatch({ databases, previewedDatabases })
    )
  }

  private onPackagesChange = (packages: string[]) => {
    this.setState(
      () => ({ org: { ...this.state.org, packages } }),
      () => this.onPatch({ packages })
    )
  }

  private onIpRestrictionChange = (isRestricted: boolean) => {
    this.setState({ org: { ...this.state.org, isRestricted } }, () =>
      this.onPatch({ isRestricted })
    )
  }

  private onMFAStatusChange = (mfaStatus: boolean) => {
    this.setState(
      () => ({ org: { ...this.state.org, mfaStatus } }),
      () => this.onPatch({ mfaStatus })
    )
  }

  private onMigrationStatusChange = (isMigrated: boolean) => {
    this.setState({ org: { ...this.state.org, isMigrated } }, () =>
      this.onPatch({ isMigrated })
    )
  }

  private onDbConfigNameChange = (dbConfigName: string) => {
    this.setState(
      () => ({ org: { ...this.state.org, dbConfigName } }),
      () => this.onPatch({ dbConfigName })
    )
  }

  private onTypeChange = (type: OrganizationType) => {
    this.setState(
      () => ({ org: { ...this.state.org, type } }),
      () => this.onPatch({ type })
    )
  }

  private validate = async (cb: () => void) => {
    try {
      await this.schema.validate(this.state.org, { abortEarly: false })
      this.setState(() => ({ validation: {} }))
      if (!_.isEqual(this.state.org, this.props.org)) {
        cb()
      }
    } catch (e) {
      this.handleError(e)
    }
  }

  private handleError = (e: yup.ValidationError) => {
    const validation: SMap<string> = {}

    e.inner.map(err => {
      validation[err.path] = err.errors[0]
    })

    this.setState(current => ({
      validation: { ...current.validation, ...validation },
    }))
  }

  private onToggleDeactivateOrgModal = () =>
    this.setState(() => ({
      isDeactivateOrgModalOpen: !this.state.isDeactivateOrgModalOpen,
    }))

  private onToggleAddOrgModal = () =>
    this.setState(() => ({ isAddOrgModalOpen: !this.state.isAddOrgModalOpen }))

  private onToggleDeleteOrgModal = () =>
    this.setState(() => ({
      isDeleteOrgModalOpen: !this.state.isDeleteOrgModalOpen,
    }))

  private confirmDeactivateOrg = () => {
    this.props.deactivateOrganization(this.props.org)
    this.onToggleDeactivateOrgModal()
  }

  private activateOrg = () => this.props.activateOrganization(this.props.org)

  private confirmDeleteOrg = () => {
    this.props.deleteOrganization(this.props.org)
    this.props.replaceUrl('/admin/organizations')
  }

  private goToList = () => {
    this.props.pushUrl('/admin/organizations')
  }
}

const mapStateToProps = (state: IRootState): IStateProps => {
  const { user: me } = state.user

  return {
    me,
  }
}

const mapDispatchToProps = (dispatch: any): IActionProps => {
  return {
    createOrganization: (
      form: IAddOrgForm,
      onError: (errors: IBadRequestErrorData) => void,
      onSuccess: () => void
    ) => dispatch(admin.createOrganization(form, onError, onSuccess)),
    activateOrganization: (org: IOrganization) =>
      dispatch(admin.activateOrganization(org)),
    deactivateOrganization: (org: IOrganization) =>
      dispatch(admin.deactivateOrganization(org)),
    deleteOrganization: (org: IOrganization) => dispatch(admin.deleteOrganization(org)),
    replaceUrl: (url: string) => dispatch(replace(url)),
    pushUrl: (url: string) => dispatch(push(url)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditOrgForm)
