import React from 'react'
import { parseISO } from 'date-fns'
import { block as bemBlock } from '../../bem'

import { Link } from 'react-router-dom'
import { Status } from '../Status'
import { formatISOUTC } from './Users/utils'

import {
  orgStatusResolver,
  orgStatusAsText,
  orgStatusClassName,
  tokenStatusAsText,
  TokenStatus,
} from './utils'

import chevron from '../../static/admin-table-chevron.svg'
import bin from '../../static/bin.svg'
import duplicate from '../../static/duplicate.svg'
import pencil from '../../static/edit.svg'
import { ActivityDot } from '../ActivityDot'

export const CenterText = ({ value }: any) => <div className="CenteredCell">{value}</div>
export const Text = ({ value }: any) => <div className="TextCell">{value}</div>
export const TextWithHover = ({ value }: any) => (
  <div className="TextCell" title={value}>
    {value}
  </div>
)

export const DateText = ({ value, mark }: { value: any; mark?: boolean }) => {
  const parsed = React.useMemo(() => parseISO(value), [value])

  const classes = React.useMemo(() => {
    const now = new Date()
    return {
      active: value && mark && parsed > now,
      expired: value && mark && parsed <= now,
    }
  }, [value, mark])

  const [text, hover] = React.useMemo(
    () => [
      formatISOUTC(value, { empty: '--', format: 'MM/dd/yyyy' }),
      `${formatISOUTC(value, {
        empty: '',
        format: 'MM/dd/yyyy hh:mm a',
      })} (UTC)`,
    ],
    [value]
  )

  return (
    <div className={bemBlock('DateTextCell', classes)} title={hover}>
      {text}
    </div>
  )
}

export const HeaderCell = (props: {
  text: string
  isSorting: boolean
  desc: boolean
}) => (
  <div className={bemBlock('HeaderCell', { desc: props.desc && props.isSorting })}>
    <span>{props.text}</span>
    {props.isSorting && <img src={chevron} />}
  </div>
)

export const LinkCell: React.FC<{ to: string }> = ({ to, children }) => (
  <Link to={to} className="LinkCell">
    {children}
  </Link>
)

export const ActivityDotCell = (props: {
  size?: string
  title?: string
  value: boolean | null
}) => (
  <div className="CenteredCell">
    <ActivityDot size={props.size} title={props.title} value={props.value} />
  </div>
)

export const StatusCell = (data: any, setOption: (value: number) => void) => (
  <Status value={data.value} onSelect={setOption} />
)

export const ClickCell = ({ value, onClick }: { value: string; onClick: () => void }) => (
  <div className="LinkCell" onClick={onClick}>
    {value}
  </div>
)

export const DeleteCell = (props: { onClick: () => void }) => (
  <img className="Bin" src={bin} onClick={props.onClick} />
)

export const DuplicateCell = (props: { onClick: () => void }) => (
  <img className="Duplicate" src={duplicate} onClick={props.onClick} />
)

export const EditCell = (props: { onClick: () => void }) => (
  <img className="Pencil" src={pencil} onClick={props.onClick} />
)

export const OrgStatusCell: React.FC<{ org: IOrganization }> = ({ org }) => {
  const status = orgStatusResolver(org)

  return (
    <div className={`orgStatus ${orgStatusClassName(status)}`}>
      {orgStatusAsText(status, org)}
    </div>
  )
}

export const TokenStatusCell: React.FC<{ status: TokenStatus }> = ({ status }) => {
  return (
    <div
      className={bemBlock('TokenStatusCell', {
        active: status === TokenStatus.ACTIVE,
        deactivated: status === TokenStatus.DEACTIVATED,
        expired: status === TokenStatus.EXPIRED,
      })}
    >
      <span>{tokenStatusAsText(status)}</span>
    </div>
  )
}
