import { SIDEBAR } from '../../messages'

interface IDBGroup {
  label: string
  entries: IDirectoryEntry[]
  type?: IDirectoryEntry['type']
  collapsed: boolean
}

export const groupDbs = (
  dbs: IDirectoryEntry[],
  menu: IMenuLayout,
  groupOthers = true
): IDBGroup[] => {
  if (!dbs.length || !menu) {
    return []
  }

  const dbsMap = dbs.reduce((map: SMap<IDirectoryEntry>, db) => {
    map[db.id] = db
    return map
  }, {})

  const removeExtraSeparators = (entries: IDirectoryEntry[]): IDirectoryEntry[] =>
    entries.filter(
      (entry, index, array) =>
        entry.type !== 'separator' || (index > 0 && array[index - 1].type !== 'separator')
    )

  const handleGroup = (group: IMenuEntryGroup): IDBGroup => {
    const entries = removeExtraSeparators(
      group.children.reduce((acc, child) => {
        switch (child.type) {
          case 'separator':
            acc.push(child)
            break
          case 'db':
            const db = dbsMap[child.name]
            if (db) {
              delete dbsMap[child.name]
              acc.push(db)
            }
            break
        }
        return acc
      }, [])
    )
    return entries.length > 0
      ? {
          entries,
          label: group.label,
          collapsed: !group.expanded,
          type: 'group',
        }
      : null
  }

  const layout = menu.layout
    .reduce((entries, entry) => {
      if (entry.type === 'category') {
        const categoryGroups = entry.children.reduce((acc, child) => {
          const categoryGroup = handleGroup(child)
          if (categoryGroup) {
            acc.push(categoryGroup)
          }
          return acc
        }, [])
        if (categoryGroups.length) {
          entries.push({ type: 'category', label: entry.label, entries: [] })
          entries.push(...categoryGroups)
        }
        return entries
      }
      const group = handleGroup(entry)
      if (group) {
        entries.push(group)
      }
      return entries
    }, [])
    .filter(entry => entry.type !== 'group' || entry.entries?.length)

  const others = Object.values(dbsMap)

  if (groupOthers && others.length) {
    layout.push({
      type: 'group',
      label: SIDEBAR.DATA_DIRECTORY_TAB.OTHER_DATABASES,
      collapsed: true,
      entries: others,
    })
  }
  return layout
}

export const dbsCompare = (a: string, b: string): number => {
  const regex = /^[a-zA-Z0-9].*[0-9]{4}$/

  if (!a.match(regex) || !b.match(regex)) {
    return a.localeCompare(b, 'en-US', { numeric: true })
  }

  const aBase = a.slice(0, -4)
  const aYearMonth = parseInt(a.slice(-4), 10)

  const bBase = b.slice(0, -4)
  const bYearMonth = parseInt(b.slice(-4), 10)

  if (aBase !== bBase) {
    return aBase.localeCompare(bBase, 'en-US', { numeric: true })
  }
  return bYearMonth - aYearMonth
}
