export const ENV = process.env.APP_ENV || 'local'
export const IS_PRODUCTION = ENV === 'prod'
export const IS_LOCAL = ENV === 'local'

export const VERSION = process.env.APP_CURRENT_GIT_SHA

export const GA_ID = !IS_LOCAL
  ? IS_PRODUCTION
    ? 'UA-127303525-1'
    : 'UA-127291264-1'
  : null

export const AMPLITUDE_KEY = IS_PRODUCTION ? '4fa02835ca9d89137fd73110dd500030' : null

export const SENTRY_DSN = !IS_LOCAL
  ? 'https://47b9b82a99f04f94a17c023379b21d28@sentry.io/1444876'
  : null

export const SENTRY_TRACES_SAMPLE_RATE = IS_PRODUCTION ? 0.2 : 1.0
export const SENTRY_REPLAYS_SAMPLE_RATE = 0.0

export const API_URLS: SMap<string> = {
  local: 'http://localhost:5050/',
  dev: 'https://dev-api.haver.arabellalabs.com/',
  staging: 'https://staging-api.haver.arabellalabs.com/',
  prod: 'https://api.haverview.com/',
  ngrok: process.env.APP_NGROK_BACKEND_URL,
}

export const NEW_PROD_URLS: SMap<string> = {
  local: 'http://localhost:3000/',
  dev: 'https://app.dev.haverview.com/',
  staging: 'https://app.dev.haverview.com/',
  prod: 'https://app.haverview.com/',
  ngrok: 'https://localhost:3000',
}

const normalizeTrailingSlash = (url: string) =>
  url.endsWith('/') ? url.slice(0, url.length - 1) : url

export const API_URL = normalizeTrailingSlash(API_URLS[ENV])

export const NEW_PROD_URL = normalizeTrailingSlash(NEW_PROD_URLS[ENV])
