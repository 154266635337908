import * as React from 'react'

import { bindBem } from '../../bem'

import './Footnote.scss'

interface IProps {
  footnoteId: string
  onClick?: () => void
}

export const Footnote = (props: IProps) => {
  const { block } = bindBem('Footnote')
  const { footnoteId, onClick } = props
  const clickable = !!onClick
  if (!footnoteId) {
    return null
  }
  return (
    <div
      className={block({ clickable })}
      onClick={e => {
        onClick?.()
        e.stopPropagation()
      }}
    >
      {footnoteId}
    </div>
  )
}
