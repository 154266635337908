import * as React from 'react'
import { connect } from 'react-redux'

import { Options } from './Sidebar/Options'
import { IRootState } from 'store'
import * as Admin from 'store/Admin'
import { isHaverAdmin } from 'services/user'

interface IProps {
  value: string | any
  onSelect: (value: string) => void
  disabled?: boolean
}

interface IStateProps {
  menus: IMenu[]
  me: IUser
}

interface IActionProps {
  fetchMenuLayoutsList: () => void
}

export const DbConfigSelect: React.FunctionComponent<
  IProps & IStateProps & IActionProps
> = props => {
  React.useEffect(() => {
    if (!props.menus.length && !isHaverAdmin(props.me.role)) {
      props.fetchMenuLayoutsList()
    }
  }, [props.menus, props.me])
  const options = React.useMemo(
    () =>
      props.menus.map(menu => ({
        name: menu.label,
        value: menu.name,
      })),
    [props.menus]
  )
  const value = options.findIndex(option => option.value === props.value)
  return (
    <Options
      options={options}
      selectedOption={value}
      setSeriesOptions={(selectedValue: number) =>
        props.onSelect(options[selectedValue].value)
      }
      disabled={props.disabled ?? false}
      renderPlaceholder
    />
  )
}

const mapStateToProps = (state: IRootState): IStateProps => {
  const { menus } = state.admin
  const { user: me } = state.user

  return {
    me,
    menus,
  }
}

const mapDispatchToProps = (dispatch: any): IActionProps => ({
  fetchMenuLayoutsList: () => dispatch(Admin.fetchMenuLayoutsList()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DbConfigSelect)
