import * as React from 'react'

import { bindBem } from '../bem'

import checkmark from '../static/check-small.svg'

import './Checkbox.scss'

interface IProps {
  rawOnchange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChange?: (checked: boolean) => void
  checked: boolean
  label?: string
  big?: boolean
  disabled?: boolean
  children?: React.ReactNode
}

export class Checkbox extends React.Component<IProps, { checked: boolean }> {
  constructor(props: IProps) {
    super(props)
    this.state = { checked: !!props.checked }
  }
  componentDidUpdate(prevProps: IProps) {
    if (prevProps.checked !== this.props.checked) {
      this.setState({ checked: this.props.checked })
    }
  }
  render() {
    const { label, big, disabled } = this.props
    const { checked } = this.state
    const { block, element } = bindBem('Checkbox')
    return (
      <span className={block({ big, checked, disabled })}>
        <input
          type="checkbox"
          checked={checked}
          onChange={e => {
            if (this.props.rawOnchange) {
              this.props.rawOnchange(e)
            }
            if (this.props.onChange) {
              this.onChange(e.target.checked)
            }
          }}
          disabled={disabled}
        />
        <span
          className={element('State')}
          style={{ backgroundImage: checked ? `url(${checkmark})` : null }}
        />
        {label && <span className={element('Label')}>{label}</span>}
        {this.props.children}
      </span>
    )
  }
  private onChange = (checked: boolean) => {
    this.setState(() => ({ checked }))
    this.props.onChange(checked)
  }
}
