import React from 'react'
import { SHORTCUTS_PANEL } from '../messages'
import { bindBem } from '../bem'
import { isMacintosh } from '../utils'

import './KeyboardShortcutsDialog.scss'

type Shortcuts = { [key in keyof typeof SHORTCUTS_PANEL.SHORTCUTS]: string[] }

const MAC_SHORTCUTS: Shortcuts = {
  DISPLAY_DATABASE_LIST: ['D'],
  PRINT: ['⌘', 'P'],
  ZOOM: ['Z'],
  SET_TITLES: ['T'],
  TOGGLE_LEGENDS: ['⌥', 'L'],
  SCROLL_LEFT: ['←'],
  SCROLL_RIGHT: ['→'],
  SCROLL_LEFT_BY_PERIOD: ['⇧', '←'],
  SCROLL_RIGHT_BY_PERIOD: ['⇧', '→'],
  DECREMENT_OFFSET: ['⌥', '←'],
  INCREMENT_OFFSET: ['⌥', '→'],
  TOGGLE_LOG_SCALE: ['L'],
}

const WINDOWS_SHORTCUTS: Shortcuts = {
  DISPLAY_DATABASE_LIST: ['D'],
  PRINT: ['CTRL', 'P'],
  ZOOM: ['Z'],
  SET_TITLES: ['T'],
  TOGGLE_LEGENDS: ['ALT', 'L'],
  SCROLL_LEFT: ['←'],
  SCROLL_RIGHT: ['→'],
  SCROLL_LEFT_BY_PERIOD: ['⇧', '←'],
  SCROLL_RIGHT_BY_PERIOD: ['⇧', '→'],
  DECREMENT_OFFSET: ['ALT', '←'],
  INCREMENT_OFFSET: ['ALT', '→'],
  TOGGLE_LOG_SCALE: ['L'],
}

export const KeyboardShortcutsDialog = () => {
  const { block, element } = bindBem('KeyboardShortcutsDialog')
  const list = isMacintosh() ? MAC_SHORTCUTS : WINDOWS_SHORTCUTS
  const keys = Object.keys(list) as [keyof Shortcuts]
  const shortcuts = keys.map(shortcut => (
    <div className={element('Shortcut')} key={shortcut}>
      <span className={element('Name')}>{SHORTCUTS_PANEL.SHORTCUTS[shortcut]}</span>
      {list[shortcut].map((key, i) => (
        <React.Fragment key={key}>
          {i === 0 ? null : <div className={element('Plus')} />}
          <div className={element('Key')}>{key}</div>
        </React.Fragment>
      ))}
    </div>
  ))
  return <div className={block()}>{shortcuts}</div>
}
