import * as React from 'react'
import { parse, parseISO, format as dFormat } from 'date-fns'
import ReactDatePicker from 'react-date-picker'
import { bindBem } from '../bem'

import './DatePicker.scss'

export class UTCDate {
  isoDate: string

  constructor(isoDate: string) {
    this.isoDate = isoDate
  }

  static fromISOString(isoString: string) {
    return new this(isoString.substr(0, 10))
  }

  static fromJSDate(date: Date) {
    return new this(dFormat(date, 'yyyy-MM-dd'))
  }

  toISOString(isoTime = '23:59:59.999999Z') {
    return `${this.isoDate}T${isoTime}`
  }

  toJSDate() {
    return parseISO(this.toISOString())
  }

  toLocalJSDate(referenceDate = new Date()) {
    return parse(this.isoDate, 'yyyy-MM-dd', referenceDate)
  }
}

interface IProps {
  onChange: (val?: UTCDate) => void
  format?: string
  value?: UTCDate
  name: string
  locale?: string
  clearIcon?: JSX.Element
  disabled?: boolean
}

const defaultLocale = 'en-US'
const defaultDisplayFormat = 'MM/dd/yyyy'

export const DatePicker = ({
  onChange,
  value,
  name,
  clearIcon = null,
  format = defaultDisplayFormat,
  locale = defaultLocale,
  disabled = false,
}: IProps) => {
  const { block, element } = bindBem('DatePicker')

  const onChangeInternal = (dt: Date) => {
    let date = null
    try {
      date = UTCDate.fromJSDate(dt)
    } catch (e) {
      if (!(e instanceof RangeError)) {
        throw e
      }
    }
    onChange(date)
  }

  return (
    <div className={block()}>
      <ReactDatePicker
        className={element('Input')}
        calendarClassName={element('Calendar')}
        onChange={onChangeInternal}
        format={format}
        value={value ? value.toLocalJSDate() : null}
        locale={locale}
        name={name}
        clearIcon={clearIcon}
        disabled={disabled}
      />
    </div>
  )
}
