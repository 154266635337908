// eslint-disable max-len

import * as React from 'react'

export const PackageIcon: React.FC<{
  className: string
  title?: string
}> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    className={props.className}
  >
    {props.title && <title>{props.title}</title>}
    <g fill="none" fillRule="evenodd">
      <rect width="13" height="13" x=".5" y=".5" fill="#F5F5F5" stroke="#B6C2D4" rx="2" />
      <path
        fill="#788596"
        fillRule="nonzero"
        d="M7.574 3.602c.32.002.615.05.886.142.27.093.504.225.7.396.197.171.35.38.46.624a2 2 0 0 1 .164.828c0 .3-.054.569-.164.809-.11.24-.263.445-.46.615-.196.17-.43.3-.7.391-.271.091-.567.138-.886.14H6.489V10H5.434V3.602h2.14zm0 .852H6.489v2.241h1.085c.179 0 .338-.027.479-.081.14-.054.26-.13.36-.226a.97.97 0 0 0 .229-.347c.053-.135.079-.282.079-.44 0-.167-.026-.32-.08-.461a1.019 1.019 0 0 0-.588-.598c-.14-.056-.3-.085-.479-.088z"
      />
    </g>
  </svg>
)
