import * as React from 'react'

import { bindBem } from '../bem'
import * as constants from '../constants'
import { CLIENT_SUPPORT } from '../messages'
import { withResponsive } from './Responsive'
import { CLOSE } from './SVG/Close'
import ZendeskMobileButton from './ZendeskMobileButton'

import PhoneIcon from 'static/phone.svg'

import '../styles/base.scss'
import './Login.scss'

export interface IState {
  showSupportPane: boolean
}

export interface IProps {
  isPhone?: boolean
  children?: React.ReactNode
}

export const ClientSupport = () => {
  const { block, element } = bindBem('ClientSupport')
  return (
    <div className={block()}>
      <h1 className={`${element('Header')} row middle-xs`}>
        <img src={PhoneIcon} alt="support icon" />
        <span className={element('HeaderText')}>{CLIENT_SUPPORT.HEADER}</span>
      </h1>
      <p className={element('Sub')}>{CLIENT_SUPPORT.DESCRIPTION}</p>
      <p className={element('Availability')}>
        {CLIENT_SUPPORT.AVAILABLE1}, {CLIENT_SUPPORT.AVAILABLE2}
      </p>
      <div className={element('PhoneNumbers')}>
        <div>
          <a href={`tel:${constants.US_PHONE_NUMBER}`}>US {constants.US_PHONE_NUMBER}</a>
        </div>
        <div>
          <a href={`tel:${constants.UK_PHONE_NUMBER}`}>UK {constants.UK_PHONE_NUMBER}</a>
        </div>
        <div>
          <a href={`tel:${constants.SG_PHONE_NUMBER}`}>
            Singapore {constants.SG_PHONE_NUMBER}
          </a>
        </div>
      </div>
      <div className={element('EmailAddressLabel')}>{CLIENT_SUPPORT.EMAIL_ADDRESS}</div>
      <div className={element('EmailAddress')}>
        <a href={`mailto:${constants.EMAIL_ADDRESS}`}>{constants.EMAIL_ADDRESS}</a>
      </div>
    </div>
  )
}

export const Footer = () => {
  const { block, element } = bindBem('Footer')
  return (
    <footer className={block()}>
      <div className={element('Copyright')}>{CLIENT_SUPPORT.COPYRIGHT}</div>
      <div className={element('Links')}>
        <a href={constants.FEEDBACK_URL} target="_blank" rel="noreferrer">
          {CLIENT_SUPPORT.FEEDBACK}
        </a>{' '}
        |
        <a href={constants.DISCLAIMER_URL} target="_blank" rel="noreferrer">
          {CLIENT_SUPPORT.DISCLAIMER}
        </a>{' '}
        |
        <a href={constants.PRIVACY_URL} target="_blank" rel="noreferrer">
          {CLIENT_SUPPORT.PRIVACY}
        </a>
      </div>
    </footer>
  )
}

export const CloseButton = (props: { onClick: Function }) => {
  const { element } = bindBem('Login')
  return (
    <div className="row end-xs">
      <button className={element('CloseButton')} onClick={props.onClick}>
        {CLOSE}
      </button>
    </div>
  )
}

export const LoginPane: React.FunctionComponent<{
  onButtonClick?: Function
  isPhone?: boolean
}> = props => {
  const { element } = bindBem('Login')
  const colClassName = props.isPhone ? 'col-xs-12' : 'col-xs-6'

  return (
    <div
      className={`${element('Pane')} ${element(
        'LoginPane'
      )} ${colClassName} column center-xs`}
    >
      <div className="row center-xs">{props.children}</div>
    </div>
  )
}

export class BaseLogin extends React.Component<IProps, IState> {
  state = { showSupportPane: false }

  toggleSupportPane = () =>
    this.setState({ showSupportPane: !this.state.showSupportPane })

  renderPhone() {
    const { block } = bindBem('Login')
    const { isPhone } = this.props
    return (
      <div className={`${block({ isPhone })} row center-xs`}>
        <LoginPane isPhone onButtonClick={this.toggleSupportPane}>
          {this.props.children}
          <ZendeskMobileButton floating />
        </LoginPane>
      </div>
    )
  }

  renderTabletDesktop() {
    const { block } = bindBem('Login')
    const { isPhone } = this.props
    return (
      <div className={`${block({ isPhone })} row center-xs`}>
        <LoginPane>{this.props.children}</LoginPane>
      </div>
    )
  }

  render() {
    return this.props.isPhone ? this.renderPhone() : this.renderTabletDesktop()
  }
}

export const Login = withResponsive(BaseLogin)
