import { ADVANCED_FUNCTIONS } from '../messages'

// noGrowthRatesAllowed => difftyp === 1 ? 'DIFA' : 'DIFA%'
export const getBasicFuntions = (noGrowthRatesAllowed: boolean): BasicFunction[] =>
  BASIC_FUNCTIONS.filter(f => f !== (noGrowthRatesAllowed ? 'DIFA%' : 'DIFA'))

export const BASIC_FUNCTIONS: BasicFunction[] = [
  'LEVEL',
  'DIFA',
  'DIFA%',
  'DIFF%',
  'YRYR%',
  'DIFF',
  'YRYR',
  'DIFAL',
  'DIFFL',
  'YRYRL',
]

export const ADVANCED_FNS: (keyof Omit<
  typeof ADVANCED_FUNCTIONS,
  'OPTIONS' | 'PERIODS'
>)[] = [
  'diff',
  'diff%',
  'diffl',
  'movv',
  'index',
  'value',
  'ytd',
  'dytd',
  'zs',
  'na2z',
  'z2na',
  'sa',
]

export const DIFFTYPE_FNS: AnyFunction[] = [
  'LEVEL',
  'DIFA',
  'DIFF',
  'YRYR',
  'diff',
  'movv',
  'value',
  'zs',
  'na2z',
  'z2na',
  'sa',
]

export const ADVANCED_NO_FORM: readonly string[] = ['ytd', 'dytd', 'zs', 'na2z', 'z2na']

export const percentChangeMapping: {
  [key in AdvancedFnPercentChange]: 'diff%'
} = {
  'difa%': 'diff%',
  'difa%C': 'diff%',
  'diff%C': 'diff%',
  'diff%': 'diff%',
  'difv%': 'diff%',
  'difv%C': 'diff%',
}
export const levelMapping: { [key in AdvancedFnLevel]: 'movv' } = {
  movv: 'movv',
  movvC: 'movv',
  mova: 'movv',
  movaC: 'movv',
  movt: 'movv',
  movtC: 'movv',
}
export const logChangeMapping: { [key in AdvancedFnLogChange]: 'diffl' } = {
  diffl: 'diffl',
  difflC: 'diffl',
  difal: 'diffl',
  difalC: 'diffl',
  difvl: 'diffl',
  difvlC: 'diffl',
}
export const changeMapping: { [key in AdvancedFnChange]: 'diff' } = {
  difa: 'diff',
  difaC: 'diff',
  diffC: 'diff',
  diff: 'diff',
  difv: 'diff',
  difvC: 'diff',
}
export const otherFnsMapping = ADVANCED_NO_FORM.reduce((o, v) => ({ ...o, [v]: v }), {})

export const mapping = {
  ...percentChangeMapping,
  ...levelMapping,
  ...logChangeMapping,
  ...changeMapping,
  ...otherFnsMapping,
  value: 'value',
  index: 'index',
  sa: 'sa',
} as any

export const getFunctionBase = (fn: AdvancedFunction): AdvancedFunction =>
  mapping[fn] || fn

export const isBasicFunction = (fn: string): fn is BasicFunction =>
  BASIC_FUNCTIONS.includes(fn as BasicFunction)

export const isAdvancedFunction = (fn: string): fn is AdvancedFunction =>
  mapping[fn] !== undefined || fn === 'FX'

export const isFunction = (fn: string): fn is AnyFunction =>
  isAdvancedFunction(fn) || isBasicFunction(fn)
