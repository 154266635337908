import classnames from 'classnames'
import * as React from 'react'

import { bindBem } from '../../bem'
import { TOOLTIPS } from '../../messages'
import { ICON_LINE, ICON_LINE_LIGHT } from '../SVG/Chart'
import { DOTS_ICON, DOTS_ICON_LIGHT } from '../SVG/Dots'
import { F_LETTER_ICON, F_LETTER_ICON_LIGHT } from '../SVG/FLetter'
import { I_LETTER_ICON, I_LETTER_ICON_LIGHT } from '../SVG/ILetter'
import { LOG_ICON, LOG_ICON_LIGHT } from '../SVG/Log'
import { RHO_ICON, RHO_ICON_LIGHT } from '../SVG/Rho'
import { ADD_ICON_LIGHT, REVERT_ICON } from '../SVG/Transformation'
import { Z_LETTER_ICON, Z_LETTER_ICON_LIGHT } from '../SVG/ZLetter'

import './Buttons.scss'

const { block, element } = bindBem('ToolbarButton')

interface IProps {
  active?: boolean
  disabled?: boolean
  noBorder?: boolean
  graphButton?: boolean
  fxButton?: boolean
  logButton?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  children?: React.ReactNode
  className?: string
  title?: string
}

export const Button = (props: IProps) => {
  const className = block({
    active: props.active,
    noBorder: props.noBorder,
    graph: props.graphButton,
    fx: props.fxButton,
    log: props.logButton,
  })
  return (
    <button
      className={classnames(className, props.className)}
      disabled={props.disabled}
      onClick={props.onClick}
      title={props.title}
    >
      {props.children}
    </button>
  )
}

type BtnProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  active?: boolean
}

export const MoreOptionsButton = (props: BtnProps) => (
  <Button noBorder {...props}>
    {DOTS_ICON}
    {DOTS_ICON_LIGHT}
  </Button>
)

export const RevertButton = (props: BtnProps) => (
  <Button noBorder {...props} title={TOOLTIPS.UNDO}>
    {REVERT_ICON}
  </Button>
)

export const GraphButton = (
  props: BtnProps & { active?: boolean; icons?: JSX.Element[] }
) => {
  const icons = props.icons || [ICON_LINE, ICON_LINE_LIGHT]
  return (
    <Button graphButton {...props} className={block({ active: props.active })}>
      {icons}
      <div className={element('ArrowDown')} />
    </Button>
  )
}

export const FxButton = (props: BtnProps) => (
  <Button fxButton title={TOOLTIPS.FUNCTION} {...props}>
    {F_LETTER_ICON}
    {F_LETTER_ICON_LIGHT}
  </Button>
)

export const InterpolationButton = (props: BtnProps) => (
  <Button title={TOOLTIPS.INTERPOLATION} {...props}>
    {I_LETTER_ICON}
    {I_LETTER_ICON_LIGHT}
  </Button>
)

export const CorrelationButton = (props: BtnProps) => (
  <Button {...props} title={TOOLTIPS.CORRELATION}>
    {RHO_ICON}
    {RHO_ICON_LIGHT}
  </Button>
)

export const LogScaleButton = (props: BtnProps) => (
  <Button noBorder logButton {...props} title={TOOLTIPS.LOG_SCALE}>
    {LOG_ICON}
    {LOG_ICON_LIGHT}
  </Button>
)

export const ZoomButton = (props: BtnProps) => {
  // eslint-disable-next-line react/prop-types
  const { active, disabled } = props
  return (
    <Button
      className={block({ noBorder: true, active })}
      disabled={disabled}
      {...props}
      title={TOOLTIPS.ZOOM}
    >
      {Z_LETTER_ICON}
      {Z_LETTER_ICON_LIGHT}
    </Button>
  )
}

export const ButtonSeparator = (props: { isCollapsed?: boolean }) => {
  const separatorBem = bindBem('ButtonSeparator')
  return (
    <span
      className={separatorBem.block({
        isCollapsed: props.isCollapsed || false,
      })}
    />
  )
}

export const TransformationButton = (
  props: BtnProps & { active?: boolean; icons?: JSX.Element[] }
) => {
  const icon = props.icons[1] || ADD_ICON_LIGHT
  return (
    <Button graphButton {...props} className={block({ active: props.active })}>
      {icon}
      <div className={element('ArrowDown')} />
    </Button>
  )
}

export const StatsButton = (props: BtnProps & { icon: JSX.Element }) => {
  return (
    <Button {...props}>
      {props.icon}
      <div className={element('ArrowUp')} />
    </Button>
  )
}
