import * as d3 from 'd3'
import * as _ from 'lodash'
import { SERIES_CONTAINER_ID, CHART_GROUP_ID } from '../../constants'

interface IDnDConfig {
  x: number
  y: number
  width: number
  height: number
  onMouseMove: () => void
}

export const svgId = 'dnd-chart-svg'
export const rectId = 'dnd-chart-rect'
export const dndChartGroupId = 'dnd-chart-group'

const renderDnDChartGroup = (config: IDnDConfig) => {
  let selection = d3.select(`#${svgId}`)
  if (selection.size() !== 0) {
    selection.remove()
  }
  selection = d3
    .select(`#${SERIES_CONTAINER_ID}`)
    .append('svg')
    .attr('id', svgId)
    .style('position', 'absolute')
    .style('left', config.x + 'px')
    .style('top', config.y + 'px')
    .attr('width', config.width)
    .attr('height', config.height)

  const group = selection.append('g')

  group
    .append('rect')
    .attr('id', rectId)
    .attr('width', config.width)
    .attr('height', config.height)
    .attr('fill', 'white')
    .attr('opacity', 0)
    .on('mousemove', config.onMouseMove)

  return [selection, group]
}

export const dragAndDrop = (
  config: IDnDConfig,
  periodWidth: number,
  scrollByPeriod: (period: number) => void
) => {
  const [selection, group] = renderDnDChartGroup(config)
  let startX = 0
  const dragHandler = d3
    .drag()
    .on('start', () => {
      group.append('use').attr('href', `#${CHART_GROUP_ID}`).attr('id', dndChartGroupId)
      selection
        .select(`#${rectId}`)
        .attr('opacity', 0.8)
        .on('mousemove', null)
        .on('mouseout', null)
      if (d3.event) {
        startX = d3.event.x
      }
    })
    .on('drag', () => {
      selection.select(`#${dndChartGroupId}`).attr('x', d3.event.x - startX)
    })
    .on('end', () => {
      const finalOffset = d3.event.x - startX
      const period = Math.ceil(finalOffset / periodWidth)
      scrollByPeriod(-1 * period)
      selection.select(`#${dndChartGroupId}`).remove()
      selection
        .select(`#${rectId}`)
        .attr('opacity', 0)
        .on('mousemove', config.onMouseMove)
    })

  dragHandler(selection.select(`#${rectId}`))
}
export const removeDnD = () => d3.select(`#${svgId}`).remove()
