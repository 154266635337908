import React, { Children, useEffect, useRef, useState } from 'react'

import { bindBem } from '../bem'

import './KebabMenu.scss'

export const KebabMenu: React.FunctionComponent<{}> = ({ children }) => {
  const { block, element } = bindBem('KebabMenu')
  const node = useRef(null)

  const [opened, setOpened] = useState(false)

  useEffect(() => {
    if (opened) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [opened])

  const toggleOpened = () => setOpened(prev => !prev)

  const handleClickOutside = (e: MouseEvent): void => {
    if (node.current.contains(e.target)) {
      return
    }
    setOpened(false)
  }

  return (
    <div className={block({ opened })} ref={node}>
      <button onClick={toggleOpened}>
        <div className={element('Dot')}></div>
        <div className={element('Dot')}></div>
        <div className={element('Dot')}></div>
      </button>
      <div className={element('List')}>
        {children &&
          Children.toArray(children).map((child, idx) => (
            <div className={element('Item')} key={idx}>
              {child}
            </div>
          ))}
      </div>
    </div>
  )
}
