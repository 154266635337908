import * as React from 'react'
import { KebabMenu } from '../KebabMenu'
import { FilePickerLink } from '../FilePicker'
import { Link } from '../Link'
import { IMPORT_EXPORT } from '../../messages'

interface IProps {
  importSelections: (file: File) => void
  exportSelections: (e: React.MouseEvent) => void
  disabled: boolean
}

const ImportExportMenu = ({ importSelections, exportSelections, disabled }: IProps) => {
  return (
    <KebabMenu>
      <FilePickerLink
        onFilePicked={importSelections}
        accept=".txt"
        text={IMPORT_EXPORT.IMPORT}
      />
      <Link disabled={disabled} onClick={exportSelections}>
        {IMPORT_EXPORT.EXPORT}
      </Link>
    </KebabMenu>
  )
}

export default ImportExportMenu
