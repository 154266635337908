import { CLIENT } from './config'

export const USERS = {
  LOGIN: '/v3/login',
  LOGOUT: '/v3/logout',
  GET_ME: '/v1/users/me',
  GET_ME_WITH_OTP_VERIFICATION: '/v3/mfa/verify',
  RESEND_OTP: '/v3/mfa/resend',
  REQUEST_PASSWD_RESET: '/v1/users/password_reset_request',
  RESET_PASSWORD: '/v1/users/password_reset',
}

export const setLocalToken = (userId: string, sessionToken: string) => {
  CLIENT.defaults.headers = { Authorization: `Bearer ${sessionToken}` }
  localStorage.setItem('sessionToken', sessionToken)
  localStorage.setItem('userId', userId)
}

export const removeLocalToken = () => {
  localStorage.removeItem('sessionToken')
  localStorage.removeItem('userId')
  delete CLIENT.defaults.headers.Authorization
}

export const logout = async () => {
  try {
    const response = await CLIENT.post<{ redirect_url: string }>(USERS.LOGOUT)
    removeLocalToken()
    if (response.data.redirect_url) {
      window.location.replace(response.data.redirect_url)
    }
  } catch {
    // doesn't really matter if it worked or not. User can be already logged out.
  }
}

export const fetchUser = async (sessionToken?: string): Promise<IUser> => {
  const headers = sessionToken ? { Authorization: `Bearer ${sessionToken}` } : {}
  const response = await CLIENT.get<IUser>(USERS.GET_ME, { headers })
  return response.data
}

export const fetchUserWithOtpVerification = async (
  sessionToken: string,
  data: { signature_hash: string; otp: string }
): Promise<IUser> => {
  const headers = sessionToken ? { Authorization: `Bearer ${sessionToken}` } : {}
  const response = await CLIENT.post<IUser>(USERS.GET_ME_WITH_OTP_VERIFICATION, data, {
    headers,
  })
  return response.data
}

export const resendOtp = async (
  sessionToken: string
): Promise<{ data: { signature_hash: string } }> => {
  const headers = sessionToken ? { Authorization: `Bearer ${sessionToken}` } : {}
  return CLIENT.post(USERS.RESEND_OTP, {}, { headers })
}

export const requestPasswordReset = async (email: string): Promise<{}> =>
  CLIENT.post(USERS.REQUEST_PASSWD_RESET, { email })

export const resetPassword = async (
  token: string,
  password: string,
  password_2: string
): Promise<{}> =>
  CLIENT.post(USERS.RESET_PASSWORD, { password, password_2 }, { params: { token } })
