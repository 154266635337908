// eslint-disable max-len

import * as React from 'react'

const ALetterIconSm = ({ color }: any) => (
  <svg width="10" height="10" viewBox="0 0 10 10">
    <path
      fill={color}
      fillRule="nonzero"
      d="M8.57 8H5.175v-.343c.158-.011.344-.04.558-.085.214-.046.32-.102.32-.17a.538.538 0 0 0-.006-.079.55.55 0 0 0-.037-.118l-.532-1.292H3.324a21.932 21.932 0 0 0-.32.853 2.248 2.248 0 0 0-.1.355c-.01.073-.015.125-.015.154 0 .094.07.173.213.24.142.065.378.113.71.142V8H1.254v-.343c.097-.006.206-.021.327-.046a.882.882 0 0 0 .288-.103c.12-.07.222-.157.306-.26.083-.102.153-.224.208-.364a10134.2 10134.2 0 0 1 2.154-5.208h.879a434.171 434.171 0 0 1 1.349 3.349c.325.82.602 1.5.83 2.039.038.088.087.168.145.24a.939.939 0 0 0 .536.31c.109.023.207.037.295.043V8zM5.298 5.478l-.905-2.26-.892 2.26h1.797z"
    />
  </svg>
)

export const A_LETTER_ICON_LIGHT_SM = <ALetterIconSm color="#FFFFFF" />
