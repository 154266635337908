import * as React from 'react'
import { Portal } from 'react-portal'
import { Route, RouteProps } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { bindBem } from '../bem'
import { withResponsive } from './Responsive'
import ZendeskMobileButton from './ZendeskMobileButton'

import close from '../static/close-dialog.svg'

import './Modal.scss'

export interface IProps {
  isOpen: boolean
  onClose: () => void
  onClosed?: () => void
  onOpen?: () => void
  aside?: boolean
  children?: React.ReactNode
}

export class Modal extends React.Component<IProps> {
  componentDidUpdate(prevProps: IProps) {
    if (
      !prevProps.isOpen &&
      this.props.isOpen &&
      typeof this.props.onOpen === 'function'
    ) {
      this.props.onOpen()
    }
  }
  render() {
    const { isOpen, children, aside } = this.props
    const { block } = bindBem('Modal')
    return (
      <Portal>
        <TransitionGroup>
          {isOpen && (
            <CSSTransition
              key={children.toString()}
              in={isOpen}
              timeout={300}
              classNames="Modal"
              onExited={this.props.onClosed}
            >
              <div className={block({ aside })} onClick={this.onClose}>
                {children}
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </Portal>
    )
  }
  private onClose = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      this.props.onClose()
    }
  }
}

interface IDialogProps extends IProps {
  title: string
  className?: string
}

export const _AsideDialog: React.SFC<
  IDialogProps & {
    isPhone: boolean
  }
> = props => {
  const { block, element } = bindBem('AsideDialog')
  const { isPhone, ...rest } = props
  return (
    <Modal {...rest} aside>
      <div className={block({ isPhone })}>
        <div className={element('Title')}>{props.title}</div>
        <img className={element('Close')} src={close} onClick={props.onClose} />
        {props.children}
        <ZendeskMobileButton floating />
      </div>
    </Modal>
  )
}

export const AsideDialog = withResponsive(_AsideDialog)

export const AsideDialogRoute: React.FC<RouteProps & Omit<IDialogProps, 'isOpen'>> = ({
  children,
  ...props
}) => (
  <Route
    {...props}
    // eslint-disable-next-line react/no-children-prop
    children={({ match }) => (
      <AsideDialog {...props} isOpen={match !== null}>
        {children}
      </AsideDialog>
    )}
  />
)

export const Dialog: React.SFC<IDialogProps> = props => {
  const { block, element } = bindBem('Dialog')

  return (
    <Modal {...props}>
      <div className={`${block()} ${props.className || ''}`}>
        <div className={element('Title')}>{props.title}</div>
        <img className={element('Close')} src={close} onClick={props.onClose} />
        {props.children}
      </div>
    </Modal>
  )
}

export const ActionsBar: React.SFC = ({ children }) => (
  <div className="ActionsBar">{children}</div>
)
