import { bindBem } from 'bem'
import React, { Children, Component, cloneElement } from 'react'

import './TreeNodeRenderer.scss'

class TreeNodeRenderer extends Component<any, any> {
  render() {
    const {
      children,
      swapFrom = null,
      swapLength = null,
      swapDepth = null,
      scaffoldBlockPxWidth,
      lowerSiblingCounts,
      connectDropTarget,
      isOver,
      draggedNode = null,
      canDrop = false,
      treeIndex,
      treeId,
      listIndex,
      rowDirection = 'ltr',
      getPrevRow,
      node,
      path,
      ...otherProps
    } = this.props
    const { block, element } = bindBem('TreeNodeRenderer')
    // Construct the scaffold representing the structure of the tree
    const scaffoldBlockCount = lowerSiblingCounts.length
    const scaffold = []
    lowerSiblingCounts.forEach((_lowerSiblingCount, i) => {
      if (treeIndex !== listIndex && i === swapDepth) {
        // This row has been shifted, and is at the depth of
        // the line pointing to the new destination
        let highlightLineClass = ''

        if (listIndex === swapFrom + swapLength - 1) {
          // This block is on the bottom (target) line
          // This block points at the target block (where the row will go when released)
          highlightLineClass = element('HighlightBottomLeftCorner')
        } else if (treeIndex === swapFrom) {
          // This block is on the top (source) line
          highlightLineClass = element('HighlightTopLeftCorner')
        } else {
          // This block is between the bottom and top
          highlightLineClass = element('HighlightLineVertical')
        }

        scaffold.push(
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            style={{
              width: scaffoldBlockPxWidth,
              left: scaffoldBlockPxWidth * i,
            }}
            className={element('AbsoluteLineBlock') + ` ${highlightLineClass}`}
          />
        )
      }
    })

    return connectDropTarget(
      <div {...otherProps} className={block()}>
        {scaffold}

        <div
          className={element('NodeContent')}
          style={{
            left: scaffoldBlockPxWidth * scaffoldBlockCount,
            width: `calc(100% - ${scaffoldBlockPxWidth * scaffoldBlockCount}px)`,
          }}
        >
          {Children.map(children, child =>
            cloneElement(child, {
              isOver,
              canDrop,
              draggedNode,
            })
          )}
        </div>
      </div>
    )
  }
}

export default TreeNodeRenderer
