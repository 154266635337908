import { createAction, ActionType } from 'typesafe-actions'

export interface ResetAppConfig {
  keepMaintenance: boolean
}

export const ACTIONS = {
  resetApp: createAction(
    'RESET_APP',
    resolve => (config: ResetAppConfig) => resolve(config)
  ),
}

export type RootAction = ActionType<(typeof ACTIONS)[keyof typeof ACTIONS]>
