import * as React from 'react'
import { CSSTransition } from 'react-transition-group'

import seriesLoader from '../../static/graph-loader.gif'

import './Loader.scss'

const Loader: React.FC<{ loading?: boolean; src?: React.ReactChild }> = ({
  loading,
  src,
  children,
}) => {
  return (
    <>
      <CSSTransition classNames="SeriesLoader" timeout={50} in={loading} appear>
        <div className="SeriesLoader">{src || <img src={seriesLoader} />}</div>
      </CSSTransition>
      {children}
    </>
  )
}

export default Loader
