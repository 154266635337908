import * as React from 'react'

import { bindBem } from '../../bem'
import { DEACTIVATE_ORG } from '../../messages'

import { Button } from '../Button'

import close from '../../static/close-dialog.svg'

import './DeactivateOrgConfirmation.scss'

interface IProps {
  callback: () => void
  onClose: () => void
}

export const DeactivateOrgConfirmation = ({ callback, onClose }: IProps) => {
  const { block, element } = bindBem('DeactivateOrgConfirmation')
  return (
    <div className={block()}>
      <img className={element('Close')} src={close} onClick={onClose} />
      <div className={element('Title')}>{DEACTIVATE_ORG.TITLE}</div>
      <div className={element('Content')}>
        <p className={element('Info')}>{DEACTIVATE_ORG.INFO1}</p>
        <p className={element('Info')}>{DEACTIVATE_ORG.INFO2}</p>
        <Button
          className={element('Submit')}
          onClick={callback}
          text={DEACTIVATE_ORG.DEACTIVATE}
          size="small"
          style="dark"
        />
      </div>
    </div>
  )
}
