import * as React from 'react'

import { bindBem } from '../../../bem'
import { ADMIN } from '../../../messages'

import close from '../../../static/close-dialog.svg'

import './BadImportFile.scss'

interface IProps {
  onClose: () => void
}

export const BadImportFile: React.FC<IProps> = ({ onClose }) => {
  const { block, element } = bindBem('BadImportFile')

  return (
    <div className={block()}>
      <img className={element('Close')} src={close} onClick={onClose} />
      <div className={element('Title')}>{ADMIN.USERS.INVALID_FILE_TITLE}</div>
      <div className={element('Content')}>
        <p dangerouslySetInnerHTML={{ __html: ADMIN.USERS.INVALID_FILE_MESSAGE }} />
      </div>
    </div>
  )
}
