import * as React from 'react'

import { AvatarMenu } from './AvatarMenu'
import { bindBem } from '../bem'
import { Link } from 'react-router-dom'
import { ENV, IS_PRODUCTION } from '../config'
import PhoneIcon from 'static/phone.svg'
import SVG from 'react-inlinesvg'

import './Header.scss'
import './AvatarMenu.scss'

export interface IProps {
  onHelpClick: () => void
}

export const Header = (props: IProps) => {
  const { block, element } = bindBem('Header')
  const isAdminOpen = window.location.href.includes('/admin')
  const adminTitle = (
    <div>
      HAVER <span className={element('Admin')}>ADMIN</span>
    </div>
  )
  return (
    <nav className={`${block()} row middle-xs`}>
      <Link className={`${element('Logo')} to-left-edge`} to="/series" />
      <div className=" col-xs-2 col-xs-offset-5 center-xs">
        <div className={`${element('HaverName')} box`}>
          {isAdminOpen ? adminTitle : 'HAVER ANALYTICS'}
          {!IS_PRODUCTION && <div className={element('Env')}>{ENV}</div>}
        </div>
      </div>
      <div className={`${element('Buttons')} col-xs-offset-2 col-xs-2 end-xs`}>
        <div className="box">
          <button onClick={props.onHelpClick}>
            <SVG src={PhoneIcon} />
          </button>
        </div>
      </div>
      <AvatarMenu />
    </nav>
  )
}
