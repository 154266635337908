// eslint-disable max-lines
import { IRootState, TDispatch } from '.'
import { IBadRequestErrorData } from 'api/config'
import { DATABASE, ORGANIZATION, USER, USERS } from 'toasts'
import { ActionType, createAction, getType } from 'typesafe-actions'
import { sortOrgs, sortUsers } from 'utils'
import { v4 } from 'uuid'

import * as API from '../api2/admin'
import * as MENUS_API from '../api2/menu'
import { ADMIN, ORGANIZATION_FORM as ORGANIZATION_FORM_LABELS } from './../messages'
import { ACTIONS as MENU_ACTIONS } from './Menu'
import { openToast } from './User'

export const ACTIONS = {
  setOrganizations: createAction(
    'SET_ORGANIZATIONS',
    resolve => (organizations: IOrganization[]) => resolve(organizations)
  ),
  addOrganization: createAction(
    'ADD_ORGANIZATIONS',
    resolve => (org: IOrganization) => resolve(org)
  ),
  updateOrganization: createAction(
    'UPDATE_ORGANIZATIONS',
    resolve => (org: IOrganization) => resolve(org)
  ),
  activateOrganization: createAction(
    'ACTIVATE_ORGANIZATION',
    resolve => (org: IOrganization) => resolve(org)
  ),
  deactivateOrganization: createAction(
    'DEACTIVATE_ORGANIZATION',
    resolve => (org: IOrganization) => resolve(org)
  ),
  deleteOrganization: createAction(
    'DELETE_ORGANIZATION',
    resolve => (org: IOrganization) => resolve(org)
  ),
  selectOrganization: createAction(
    'SELECT_ORGANIZATION',
    resolve => (org: IOrganization) => resolve(org)
  ),
  deselectOrganization: createAction('DESELECT_ORGANIZATION', resolve => () => resolve()),
  addUser: createAction('ADD_USER', resolve => (user: IUser) => resolve(user)),
  updateUser: createAction('UPDATE_USER', resolve => (user: IUser) => resolve(user)),
  setUsers: createAction('SET_USERS', resolve => (users: IUser[]) => resolve(users)),
  deleteUser: createAction('DELETE_USER', resolve => (user: IUser) => resolve(user)),
  setDatabases: createAction(
    'SET_DATABASES',
    resolve => (dbs: IDatabase[]) => resolve(dbs)
  ),
  setDatabasesWithMenuPages: createAction(
    'SET_DATABASES_MENU_PAGES',
    resolve => (dbs: string[]) => resolve(dbs)
  ),
  updateDatabase: createAction('UPDATE_DB', resolve => (db: IDatabase) => resolve(db)),
  deleteDatabase: createAction('DELETE_DB', resolve => (db: IDatabase) => resolve(db)),
  importDatabaseMenuPages: createAction(
    'IMPORT_DB_MENU_PAGES',
    resolve => (db: string, menuPages: string[]) => resolve(db, menuPages)
  ),
  setCTreeDatabases: createAction(
    'SET_CTREE_DATABASES',
    resolve => (dbs: string[]) => resolve(dbs)
  ),
  setPackages: createAction(
    'SET_PACKAGES',
    resolve => (packages: IDBPackage[]) => resolve(packages)
  ),
  updatePackage: createAction(
    'UPDATE_PACKAGE',
    resolve => (pkg: IDBPackage) => resolve(pkg)
  ),
  deletePackage: createAction(
    'DELETE_PACKAGE',
    resolve => (pkg: IDBPackage) => resolve(pkg)
  ),
  createPackage: createAction(
    'CREATE_PACKAGE',
    resolve => (pkg: IDBPackage) => resolve(pkg)
  ),
  updateUsers: createAction(
    'UPDATE_USERS',
    resolve => (users: IUser[]) => resolve(users)
  ),
  deleteUsers: createAction(
    'DELETE_USERS',
    resolve => (users: IUser[]) => resolve(users)
  ),
  addUsers: createAction('ADD_USERS', resolve => (users: IUser[]) => resolve(users)),
  updateToken: createAction('UPDATE_TOKEN', resolve => (token: IToken) => resolve(token)),
  updateTokens: createAction(
    'UPDATE_TOKENS',
    resolve => (tokens: IToken[]) => resolve(tokens)
  ),
  setMenus: createAction(
    'SET_MENUS',
    resolve => (menus: IMenuLayout[] | IMenu[]) => resolve(menus)
  ),
  addMenu: createAction('ADD_MENU', resolve => (menu: IMenuLayout) => resolve(menu)),
  updateMenus: createAction(
    'UPDATE_MENUS',
    resolve => (menus: IMenuLayout[]) => resolve(menus)
  ),
  deleteMenu: createAction(
    'DELETE_MENU',
    resolve => (menu: IMenuLayout) => resolve(menu)
  ),
  setTokens: createAction('SET_TOKENS', resolve => (tokens: IToken[]) => resolve(tokens)),
}

export type State = {
  organizations: IOrganization[]
  selectedOrganization: IOrganization
  users: IUser[]
  tokens: IToken[]
  databases: IDatabase[]
  databasesWithMenuPages: string[]
  cTreeDatabases: string[]
  packages: IDBPackage[]
  menus: IMenuLayout[] | IMenu[]
}

const INITIAL_STATE: State = {
  organizations: [],
  selectedOrganization: null,
  users: null,
  tokens: [],
  databases: [],
  databasesWithMenuPages: [],
  cTreeDatabases: [],
  packages: [],
  menus: [],
}

export function reducer(state = INITIAL_STATE, action: AdminAction): State {
  switch (action.type) {
    case getType(ACTIONS.setOrganizations):
      return { ...state, organizations: sortOrgs(action.payload) }
    case getType(ACTIONS.selectOrganization):
      return { ...state, selectedOrganization: action.payload }
    case getType(ACTIONS.deselectOrganization):
      return { ...state, selectedOrganization: null }
    case getType(ACTIONS.addOrganization):
      return {
        ...state,
        organizations: sortOrgs([...state.organizations, action.payload]),
      }
    case getType(ACTIONS.updateOrganization):
    case getType(ACTIONS.activateOrganization):
    case getType(ACTIONS.deactivateOrganization): {
      const { organizations } = state
      const updateOrg = action.payload
      const index = organizations.findIndex(org => org.id === updateOrg.id)
      return {
        ...state,
        selectedOrganization: updateOrg,
        organizations: sortOrgs([
          ...organizations.slice(0, index),
          updateOrg,
          ...organizations.slice(index + 1),
        ]),
      }
    }
    case getType(ACTIONS.deleteOrganization):
      const [...orgs] = state.organizations
      orgs.splice(orgs.indexOf(action.payload), 1)
      return { ...state, organizations: orgs }
    case getType(ACTIONS.setUsers):
      return { ...state, users: sortUsers(action.payload) }

    case getType(ACTIONS.addUser): {
      const users = sortUsers([action.payload, ...state.users])
      return { ...state, users }
    }
    case getType(ACTIONS.deleteUser): {
      const [...users] = state.users
      users.splice(users.indexOf(action.payload), 1)
      return { ...state, users }
    }
    case getType(ACTIONS.updateUser): {
      const [...users] = state.users
      const index = users.findIndex(u => u.id === action.payload.id)
      users[index] = action.payload
      return { ...state, users: sortUsers(users) }
    }
    case getType(ACTIONS.setDatabases): {
      return { ...state, databases: action.payload }
    }
    case getType(ACTIONS.setDatabasesWithMenuPages): {
      return { ...state, databasesWithMenuPages: action.payload }
    }
    case getType(ACTIONS.updateDatabase): {
      const [...databases] = state.databases
      const index = databases.findIndex(db => db.name === action.payload.name)
      databases[index] = action.payload
      return { ...state, databases }
    }
    case getType(ACTIONS.deleteDatabase): {
      const [...databases] = state.databases
      databases.splice(databases.indexOf(action.payload), 1)
      return { ...state, databases }
    }
    case getType(ACTIONS.setCTreeDatabases): {
      return { ...state, cTreeDatabases: action.payload }
    }
    case getType(ACTIONS.setPackages): {
      return { ...state, packages: action.payload }
    }
    case getType(ACTIONS.updatePackage): {
      const [...packages] = state.packages
      const index = packages.findIndex(p => p.id === action.payload.id)

      packages[index] = action.payload
      return { ...state, packages }
    }
    case getType(ACTIONS.deletePackage): {
      const [...packages] = state.packages
      const index = packages.findIndex(p => p.id === action.payload.id)
      packages.splice(index, 1)
      return { ...state, packages }
    }
    case getType(ACTIONS.createPackage): {
      const [...packages] = state.packages
      packages.push(action.payload)
      return { ...state, packages }
    }
    case getType(ACTIONS.updateUsers): {
      const [...users] = state.users
      action.payload.forEach(user => {
        const index = users.findIndex(u => u.id === user.id)
        users[index] = user
      })
      return { ...state, users: sortUsers(users) }
    }
    case getType(ACTIONS.deleteUsers): {
      const [...users] = state.users
      action.payload.forEach(user => {
        const index = users.findIndex(u => u.id === user.id)
        users.splice(index, 1)
      })
      return { ...state, users }
    }
    case getType(ACTIONS.addUsers): {
      const users = sortUsers([...action.payload, ...state.users])
      return { ...state, users }
    }
    case getType(ACTIONS.updateToken): {
      const token = action.payload
      const [...tokens] = state.tokens

      const tokenIndex = tokens.findIndex(t => t.id === token.id)
      tokens[tokenIndex] = token

      return { ...state, tokens }
    }
    case getType(ACTIONS.updateTokens): {
      const tokens = action.payload
      const [...stateTokens] = state.tokens

      tokens.forEach(token => {
        const tokenIndex = stateTokens.findIndex(t => t.id === token.id)
        stateTokens[tokenIndex] = token
      })

      return { ...state, tokens: stateTokens }
    }
    case getType(ACTIONS.setMenus): {
      return { ...state, menus: action.payload }
    }
    case getType(ACTIONS.addMenu): {
      return { ...state, menus: [...state.menus, action.payload] }
    }
    case getType(ACTIONS.updateMenus): {
      return {
        ...state,
        menus: state.menus.map(menu => {
          const updatedMenu = action.payload.find(updated => updated.name === menu.name)
          return updatedMenu ?? menu
        }),
      }
    }
    case getType(ACTIONS.deleteMenu): {
      const [...menus] = state.menus
      const index = menus.findIndex(menu => menu.name === action.payload.name)
      menus.splice(index, 1)
      return { ...state, menus }
    }
    case getType(ACTIONS.setTokens): {
      return { ...state, tokens: action.payload }
    }
    default:
      return state
  }
}

export type AdminAction = ActionType<(typeof ACTIONS)[keyof typeof ACTIONS]>

export function fetchOrganizations() {
  return async (dispatch: TDispatch) => {
    const organizations = await API.fetchOrganizations()
    dispatch(ACTIONS.setOrganizations(organizations))
  }
}

export function fetchOrganization(id: number) {
  return async (dispatch: TDispatch, getState: () => IRootState) => {
    if (getState().admin.selectedOrganization?.id === id) {
      return
    }

    const organization = await API.fetchOrganization(id)
    dispatch(ACTIONS.selectOrganization(organization))
  }
}

export function createOrganization(
  form: API.IAddOrgForm,
  onError: (errors: IBadRequestErrorData) => void,
  onSuccess: () => void
) {
  return async (dispatch: TDispatch) => {
    try {
      const organization = await API.createOrganization(form)
      onSuccess()
      dispatch(ACTIONS.addOrganization(organization))
      dispatch(openToast(ORGANIZATION.CREATED(organization)))
    } catch (e) {
      if (!e?.response?.data) {
        throw e
      }
      onError(e.response.data)
    }
  }
}

export function organizationToastMapper(
  dispatch: TDispatch,
  organization: IOrganization
): Partial<TMap<keyof IOrganization | 'general', () => void>> {
  const toastMapper: Partial<TMap<keyof IOrganization | 'general', () => void>> = {
    general: () => dispatch(openToast(ORGANIZATION.PATCHED(organization))),
    packages: () =>
      dispatch(
        openToast(
          ORGANIZATION.PATCHED(
            organization,
            ORGANIZATION_FORM_LABELS.PACKAGES.toLocaleLowerCase()
          )
        )
      ),
    name: () =>
      dispatch(
        openToast(
          ORGANIZATION.PATCHED(
            organization,
            ORGANIZATION_FORM_LABELS.NAME_LABEL.toLocaleLowerCase()
          )
        )
      ),
    usersLimit: () =>
      dispatch(
        openToast(
          ORGANIZATION.PATCHED(
            organization,
            ORGANIZATION_FORM_LABELS.USER_LIMIT_LABEL.toLocaleLowerCase()
          )
        )
      ),
    databases: () =>
      dispatch(
        openToast(
          ORGANIZATION.PATCHED(
            organization,
            ORGANIZATION_FORM_LABELS.DATABASES.toLocaleLowerCase()
          )
        )
      ),
    previewedDatabases: () =>
      dispatch(
        openToast(
          ORGANIZATION.PATCHED(
            organization,
            ORGANIZATION_FORM_LABELS.PREVIEWED_DATABASES.toLocaleLowerCase()
          )
        )
      ),
    status: () => dispatch(openToast(ORGANIZATION.STATUS_UPDATED(organization))),
    accountNumber: () =>
      dispatch(
        openToast(
          ORGANIZATION.PATCHED(
            organization,
            ORGANIZATION_FORM_LABELS.ACCOUNT_NUMBER_LABEL.toLocaleLowerCase()
          )
        )
      ),
    cidrs: () =>
      dispatch(
        openToast(
          ORGANIZATION.PATCHED(
            organization,
            ORGANIZATION_FORM_LABELS.CIDRS.toLocaleLowerCase()
          )
        )
      ),
    ratelimit: () =>
      dispatch(
        openToast(
          ORGANIZATION.PATCHED(
            organization,
            ORGANIZATION_FORM_LABELS.RATELIMIT.toLocaleLowerCase()
          )
        )
      ),
    isRestricted: () =>
      dispatch(
        openToast(
          ORGANIZATION.PATCHED(
            organization,
            ORGANIZATION_FORM_LABELS.IS_RESTRICTED.toLocaleLowerCase()
          )
        )
      ),
  }

  return toastMapper
}

export function patchOrganization(
  id: number,
  data: Partial<IOrganization>,
  onError?: (errors: IBadRequestErrorData) => void
) {
  return async (dispatch: TDispatch, getState: () => IRootState) => {
    try {
      const organization = await API.patchOrganization(id, data)
      dispatch(ACTIONS.updateOrganization(organization))
      dispatch(fetchUsers(organization.id))

      const toastMapper = organizationToastMapper(dispatch, organization)
      const keys = Object.keys(data)
      const key = keys.length > 0 ? (keys[0] as keyof IOrganization) : 'general'

      if (key && toastMapper[key]) {
        toastMapper[key]()
      }
    } catch (e) {
      if (!e?.response?.data) {
        throw e
      }

      const errors = e.response.data
      const organization = getState().admin.selectedOrganization

      const toast: Partial<IToast> = {
        title: ADMIN.ORG.PATCH_ERROR(organization.name),
        subtitle: Object.values(errors).join('\n'),
        type: 'error',
      }

      if (onError) {
        onError(errors)
      }
      dispatch(openToast(toast))
    }
  }
}

export function activateOrganization(org: IOrganization) {
  return async (dispatch: TDispatch) => {
    const organization = await API.activateOrganization(org.id)
    dispatch(ACTIONS.activateOrganization(organization))
    dispatch(openToast(ORGANIZATION.ACTIVATED(organization)))
  }
}

export function deactivateOrganization(org: IOrganization) {
  return async (dispatch: TDispatch) => {
    const organization = await API.deactivateOrganization(org.id)
    dispatch(ACTIONS.deactivateOrganization(organization))
    dispatch(openToast(ORGANIZATION.DEACTIVATED(organization)))
  }
}

export function deleteOrganization(org: IOrganization) {
  return async (dispatch: TDispatch) => {
    await API.deleteOrganization(org.id)
    dispatch(ACTIONS.deleteOrganization(org))
    dispatch(openToast(ORGANIZATION.DELETED(org)))
  }
}

export function fetchUsers(orgId: number) {
  return async (dispatch: TDispatch) => {
    const users = await API.fetchUsers(orgId)
    dispatch(ACTIONS.setUsers(users))
  }
}

export function fetchTokens(orgId: number) {
  return async (dispatch: TDispatch) => {
    const tokens = await API.fetchTokens(orgId)
    dispatch(ACTIONS.setTokens(tokens))
  }
}

export function createUser(user: IUser) {
  return async (dispatch: TDispatch) => {
    const createdUser = await API.createUser(user)
    dispatch(ACTIONS.addUser(createdUser))
    dispatch(openToast(USER.CREATED(createdUser)))
  }
}

export function updateUser(user: IUser) {
  return async (dispatch: TDispatch) => {
    const updated_user = await API.updateUser(user)
    dispatch(ACTIONS.updateUser(updated_user))
    dispatch(openToast(USER.UPDATED(updated_user)))
  }
}

export function deleteUser(user: IUser) {
  return async (dispatch: any) => {
    await API.deleteUser(user.id)
    dispatch(ACTIONS.deleteUser(user))
    dispatch(openToast(USER.DELETED(user)))
  }
}

export function updateUserStatus(user: IUser) {
  return async (dispatch: TDispatch) => {
    const u = await API.updateUserSettings(user)
    dispatch(ACTIONS.updateUser(u))
    dispatch(openToast(USER.STATUS_UPDATED(u)))
  }
}

export function updateUserMigrationStatus(user: IUser) {
  return async (dispatch: TDispatch) => {
    const u = await API.updateUserSettings(user)
    dispatch(ACTIONS.updateUser(u))
    dispatch(openToast(USER.MIGRATION_STATUS_UPDATED(u)))
  }
}

export function updateUserApiStatus(user: IUser) {
  return async (dispatch: TDispatch) => {
    const u = await API.updateUserSettings(user)
    dispatch(ACTIONS.updateUser(u))
    dispatch(openToast(USER.API_STATUS_UPDATED(u)))
  }
}

export function updateUserRole(user: IUser) {
  return async (dispatch: TDispatch) => {
    const u = await API.updateUser(user)
    dispatch(ACTIONS.updateUser(u))
    dispatch(openToast(USER.ROLE_UPDATED(u)))
  }
}

export const fetchDatabases = () => async (dispatch: TDispatch) => {
  const dbs = await API.fetchDatabases()
  dispatch(ACTIONS.setDatabases(dbs))
}

export const fetchDatabasesWithMenuPages = () => async (dispatch: TDispatch) => {
  const dbs = await API.fetchDatabasesWithMenuPages()
  dispatch(ACTIONS.setDatabasesWithMenuPages(dbs))
}

export const updateDatabase = (db: IDatabaseDetails) => async (dispatch: TDispatch) => {
  const newDb = await API.updateDatabase(db)
  dispatch(ACTIONS.updateDatabase(newDb))
}

export const deleteDatabase = (db: IDatabaseDetails) => async (dispatch: TDispatch) => {
  await API.deleteDatabase(db)
  dispatch(ACTIONS.deleteDatabase(db))
  dispatch(openToast(DATABASE.DELETED(db)))
}

export const fetchCTreeDatabases = () => async (dispatch: TDispatch) => {
  const dbs = await API.fetchCTreeDatabases()
  dispatch(ACTIONS.setCTreeDatabases(dbs))
}

export const fetchPackages = () => async (dispatch: TDispatch) => {
  const packages = await API.fetchPackages()
  dispatch(ACTIONS.setPackages(packages))
}

export const fetchMenuLayouts = () => async (dispatch: TDispatch) => {
  const menusLayouts = await API.fetchMenuLayoutsList()
  const menus = await Promise.all(
    menusLayouts.map(menu => MENUS_API.fetchMenuLayout(menu.name))
  )
  dispatch(ACTIONS.setMenus(menus))
}

export const fetchMenuLayoutsList = () => async (dispatch: TDispatch) => {
  const menusLayouts = await API.fetchMenuLayoutsList()
  dispatch(ACTIONS.setMenus(menusLayouts))
}

export type MenuDetailsPatch = Partial<IMenuLayout> & Pick<IMenuLayout, 'name'>

export const saveMenus =
  (menusDetails: MenuDetailsPatch[], me: IUser) => async (dispatch: TDispatch) => {
    try {
      const updatedMenus = await Promise.all(
        menusDetails.map(menu => MENUS_API.saveMenuLayout(menu.name, menu))
      )
      dispatch(ACTIONS.updateMenus(updatedMenus))
      const currentUserUpdatedLayout = updatedMenus.find(
        menu => menu.name === me.dbConfigName
      )
      if (currentUserUpdatedLayout) {
        dispatch(MENU_ACTIONS.setMenu(currentUserUpdatedLayout))
      }
      dispatch(openToast({ title: ADMIN.MENUS.TOASTS.MENUS_SAVED }))
    } catch (e) {
      if (e.response?.status === 409) {
        dispatch(
          openToast({
            title: ADMIN.MENUS.TOASTS.MENU_SAVING_CONFLICT,
            type: 'error',
            position: 'top-center',
            autoClose: false,
          })
        )
      } else {
        dispatch(
          openToast({
            title: ADMIN.MENUS.TOASTS.MENU_SAVING_ERROR,
            type: 'error',
          })
        )
      }
    }
  }

export const createMenu =
  (displayName: string, copyFrom: IMenuLayout) => async (dispatch: TDispatch) => {
    try {
      const createdMenu = await MENUS_API.createMenuLayout({
        label: displayName,
        layout: copyFrom.layout,
        name: v4(),
        version: 1,
      })
      dispatch(ACTIONS.addMenu(createdMenu))
      dispatch(openToast({ title: ADMIN.MENUS.TOASTS.MENU_CREATED }))
    } catch (e) {
      dispatch(openToast({ title: ADMIN.MENUS.TOASTS.MENU_CREATING_ERROR }))
    }
  }

export const renameMenu =
  (menu: IMenuLayout, newLabel: string) => async (dispatch: TDispatch) => {
    try {
      const updatedMenu = await MENUS_API.saveMenuLayout(menu.name, {
        label: newLabel,
        version: menu.version,
      })
      dispatch(ACTIONS.updateMenus([updatedMenu]))
      dispatch(openToast({ title: ADMIN.MENUS.TOASTS.MENU_SAVED }))
    } catch (e) {
      if (e.response?.status === 409) {
        dispatch(
          openToast({
            title: ADMIN.MENUS.TOASTS.MENU_SAVING_CONFLICT,
            type: 'error',
            position: 'top-center',
            autoClose: false,
          })
        )
      } else {
        dispatch(
          openToast({
            title: ADMIN.MENUS.TOASTS.MENU_SAVING_ERROR,
            type: 'error',
          })
        )
      }
    }
  }

export const deleteMenu =
  (menu: IMenuLayout, newName: string) => async (dispatch: TDispatch) => {
    try {
      await MENUS_API.deleteMenuLayout(menu.name, newName)
      dispatch(ACTIONS.deleteMenu(menu))
      // TODO
      // if (menu.name === me.dbConfigName) {
      //   const newMenu = menus.find(menu => menu.name === newName)
      //   dispatch(MENU_ACTIONS.setMenu(newMenu))
      // }
      dispatch(openToast({ title: ADMIN.MENUS.TOASTS.MENU_DELETED }))
    } catch (e) {
      dispatch(
        openToast({
          title: ADMIN.MENUS.TOASTS.MENU_DELETING_ERROR,
          type: 'error',
        })
      )
    }
  }

export const uploadMenuPages =
  (db: string, file: File) => async (dispatch: TDispatch) => {
    try {
      const menuPages = await MENUS_API.importMenuPages(db, file)

      dispatch(ACTIONS.importDatabaseMenuPages(db, menuPages))
      dispatch(openToast({ title: ADMIN.DB.TOASTS.MENU_PAGES_IMPORTED }))
      dispatch(fetchDatabases())
      dispatch(fetchDatabasesWithMenuPages())
    } catch (e) {
      dispatch(
        openToast({
          title: ADMIN.DB.TOASTS.MENU_PAGES_IMPORT_ERROR,
          type: 'error',
        })
      )
    }
  }

export const updatePackage = (p: IDBPackage) => async (dispatch: TDispatch) => {
  const updatedPackage = await API.updatePackage(p)
  dispatch(ACTIONS.updatePackage(updatedPackage))
}

export const deletePackage = (p: IDBPackage) => async (dispatch: TDispatch) => {
  await API.removePackage(p)
  dispatch(ACTIONS.deletePackage(p))
}

export const createPackage = (p: IDBPackage) => async (dispatch: TDispatch) => {
  const createdPackage = await API.createPackage(p)
  dispatch(ACTIONS.createPackage(createdPackage))
}

export const updateUsersStatus = (users: IUser[]) => async (dispatch: TDispatch) => {
  try {
    dispatch(ACTIONS.updateUsers(await API.updateUsersSettings(users)))
    dispatch(openToast(USERS.BULK_UPDATED(users.length)))
  } catch (e) {
    dispatch(openToast(USERS.GENERAL_API_ERROR()))
  }
}

export const updateUsersApiStatus = (users: IUser[]) => async (dispatch: TDispatch) => {
  try {
    dispatch(ACTIONS.updateUsers(await API.updateUsersSettings(users)))
    dispatch(openToast(USERS.BULK_UPDATED(users.length)))
  } catch (e) {
    dispatch(openToast(USERS.GENERAL_API_ERROR()))
  }
}

export const updateUsersRole = (users: IUser[]) => async (dispatch: TDispatch) => {
  try {
    dispatch(ACTIONS.updateUsers(await API.updateUsers(users)))
    dispatch(openToast(USERS.BULK_UPDATED(users.length)))
  } catch (e) {
    dispatch(openToast(USERS.GENERAL_API_ERROR()))
  }
}

export const deleteUsers = (users: IUser[]) => async (dispatch: TDispatch) => {
  try {
    await API.deleteUsers(users)
    dispatch(ACTIONS.deleteUsers(users))
    dispatch(openToast(USERS.BULK_DELETED(users.length)))
  } catch (e) {
    dispatch(openToast(USERS.GENERAL_API_ERROR()))
  }
}

export const createUsers = (users: IUser[]) => async (dispatch: TDispatch) => {
  try {
    const addedUsers = await API.createUsers(users)
    dispatch(ACTIONS.addUsers(addedUsers))
    dispatch(openToast(USERS.BULK_CREATED(addedUsers.length)))
  } catch (e) {
    dispatch(openToast(USERS.GENERAL_API_ERROR()))
  }
}

export const extendToken =
  (token: IToken, isoDate?: string) => async (dispatch: TDispatch) => {
    try {
      const updatedToken = await API.extendToken(token, isoDate)

      dispatch(ACTIONS.updateToken(updatedToken))
      dispatch(openToast({ title: ADMIN.TOKENS.TOASTS.TOKEN_EXTENDED }))
    } catch (e) {
      dispatch(openToast({ title: ADMIN.TOKENS.TOASTS.EXTENDING_ERROR }))
    }
  }

export const deactivateToken =
  (token: IToken, isoDate?: string) => async (dispatch: TDispatch) => {
    try {
      const updatedToken = await API.deactivateToken(token, isoDate)

      dispatch(ACTIONS.updateToken(updatedToken))
      dispatch(openToast({ title: ADMIN.TOKENS.TOASTS.TOKEN_REVOKED }))
    } catch (e) {
      dispatch(openToast({ title: ADMIN.TOKENS.TOASTS.REVOKING_ERROR }))
    }
  }

export const deactivateTokens = (tokens: IToken[]) => async (dispatch: TDispatch) => {
  try {
    const updatedTokens = await API.deactivateTokensBulk(tokens)

    dispatch(ACTIONS.updateTokens(updatedTokens))
    dispatch(openToast({ title: ADMIN.TOKENS.TOASTS.TOKENS_REVOKED }))
  } catch (e) {
    dispatch(openToast({ title: ADMIN.TOKENS.TOASTS.TOKENS_REVOKING_ERROR }))
  }
}

export const extendTokens =
  (tokens: IToken[], isoDate: Date | null) => async (dispatch: TDispatch) => {
    try {
      const updatedTokens = await API.extendTokensBulk(tokens, isoDate)

      dispatch(ACTIONS.updateTokens(updatedTokens))
      dispatch(openToast({ title: ADMIN.TOKENS.TOASTS.TOKENS_EXTENDED }))
    } catch (e) {
      dispatch(openToast({ title: ADMIN.TOKENS.TOASTS.TOKENS_EXTENDING_ERROR }))
    }
  }

export const refreshTokens = (tokens: IToken[]) => async (dispatch: TDispatch) => {
  try {
    const updatedTokens = await API.refreshTokensBulk(tokens)

    dispatch(ACTIONS.updateTokens(updatedTokens))
    dispatch(openToast({ title: ADMIN.TOKENS.TOASTS.TOKENS_REFRESHED }))
  } catch (e) {
    dispatch(openToast({ title: ADMIN.TOKENS.TOASTS.TOKENS_REFRESHING_ERROR }))
  }
}
