// eslint-disable max-len

import * as React from 'react'

const InfoIcon = ({ color }: any) => (
  <svg width="27" height="27" viewBox="0 0 27 27">
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <circle
        cx="12.501"
        cy="12.501"
        r="12.375"
        stroke={color}
        strokeLinecap="square"
        strokeWidth="2"
      />
      <ellipse
        cx="12.444"
        cy="18.6"
        fill={color}
        fillRule="nonzero"
        rx="1.333"
        ry="1.4"
      />
      <path
        fill={color}
        fillRule="nonzero"
        d="M12.444 6c-1.503 0-2.851 1-3.352 2.49l-.296.878 1.675.623.296-.88c.252-.744.925-1.244 1.677-1.244.98 0 1.778.837 1.778 1.866 0 .59-.218.783-.846 1.258-.768.578-1.82 1.372-1.82 3.409v.933h1.777V14.4c0-1.08.429-1.403 1.08-1.893.67-.505 1.587-1.196 1.587-2.774C16 7.674 14.405 6 12.444 6z"
      />
    </g>
  </svg>
)

export const INFO = <InfoIcon color="#3FA2DB" />
export const INFO_LIGHT = <InfoIcon color="#FFFFFF" />
