// eslint-disable max-lines

import * as React from 'react'
import { Formik } from 'formik'
import _ from 'lodash'
import { bindBem } from '../../bem'
import { RadioGroup } from '../../components/RadioGroup'
import RadioOption from '../../components/RadioButton'
import { Options } from '../../components/Sidebar/Options'
import { Switch } from '../../components/Sidebar/Switch'
import { Input, VerticalLabeled } from '../../components/Input'
import { getScalesSchemas } from '../../utils'
import { TICKS_SCALE_FORM } from '../../messages'

import './ScalingOptionsDialog.scss'
import './TicksScaleForm.scss'

export interface IProps {
  title: string
  axis: Axis
  disabled?: boolean
  onChange: (axis: Axis) => void
  computedAxis: IncrementalAxis
}
const TICKS = [null, ..._.range(3, 26)]
const TICK_LABELS = TICKS.map(t => (t === null ? 'Auto' : t.toString()))
const SCALE_TYPES = {
  automatic: 'automatic',
  minmax: 'minmax',
  incremental: 'incremental',
}
const trimZeros = (n: number) => parseFloat(n.toFixed(4)).toString()
export interface IState {
  type: Axis['type']
  automatic: Omit<AutomaticAxis, 'ticksCount' | 'type'>
  minmax: Omit<MinMaxAxis, 'ticksCount' | 'type'>
  incremental: Omit<IncrementalAxis, 'ticksCount' | 'type'>
}
const AutoSave: React.FC<{
  onSubmit: () => void
  values: any
  isValid: boolean
}> = ({ onSubmit, values, isValid }) => {
  const [lastValues, setValues] = React.useState(values)
  React.useEffect(() => {
    if (lastValues === values || !isValid) {
      return
    }
    onSubmit()
    setValues(values)
  })
  return null
}
export class TicksScaleForm extends React.Component<IProps, IState> {
  schemas = getScalesSchemas()

  constructor(props: IProps) {
    super(props)

    const { axis, computedAxis } = props
    const form = {
      ticksCount: axis.ticksCount,
      type: axis.type,
      automatic: {
        invert: false,
      },
      minmax: {
        min: _.first(computedAxis.values),
        max: _.last(computedAxis.values),
      },
      incremental: {
        values: computedAxis.values,
      },
    }
    if (axis.type === 'automatic') {
      form.automatic.invert = axis.invert
    }
    if (axis.type === 'incremental') {
      form.incremental.values = axis.values
    }
    this.state = form
  }

  render() {
    const { block, element } = bindBem('TicksScaleForm')
    const { title, disabled } = this.props
    const { automatic, minmax, incremental } = this.state
    const ticksCount = this.props.axis.ticksCount
    const { type } = this.state

    return (
      <div className={block({ disabled })}>
        <h4>{title}</h4>
        <div className={element('TicksCountLabel')}>
          {TICKS_SCALE_FORM.NUMBER_OF_TICKS}
        </div>
        <Options
          options={TICK_LABELS}
          selectedOption={TICKS.indexOf(ticksCount)}
          setSeriesOptions={this.onSetTicksCount}
          defaultValue={TICK_LABELS[0]}
        />
        <RadioGroup onChange={this.onChangeType} value={type}>
          <RadioOption value={SCALE_TYPES.automatic}>
            {TICKS_SCALE_FORM.AUTOMATIC}
          </RadioOption>
          <div className={element('OptionBody')}>
            <Switch
              value={automatic.invert}
              onChange={this.onSetInvert}
              title={null}
              disabled={type !== SCALE_TYPES.automatic}
            >
              {TICKS_SCALE_FORM.INVERT_SCALE}
            </Switch>
          </div>
          <RadioOption value={SCALE_TYPES.minmax}>{TICKS_SCALE_FORM.MINMAX}</RadioOption>
          <Formik
            initialValues={{
              min: trimZeros(minmax.min),
              max: trimZeros(minmax.max),
            }}
            validationSchema={this.schemas.minmax}
            onSubmit={values => this.setMinMax(this.schemas.minmax.cast(values))}
            enableReinitialize
          >
            {({ values, touched, error, errors, submitForm, handleChange }) => (
              <div className={element('OptionBody')}>
                <VerticalLabeled
                  label="MIN"
                  error={type === SCALE_TYPES.minmax && touched.min && errors.min}
                >
                  <Input
                    name="min"
                    value={values.min}
                    disabled={type !== SCALE_TYPES.minmax}
                    error={touched.min && !!errors.min}
                    onChange={handleChange}
                  />
                </VerticalLabeled>

                <VerticalLabeled
                  label="MAX"
                  error={type === SCALE_TYPES.minmax && touched.max && errors.max}
                >
                  <Input
                    name="max"
                    value={values.max}
                    disabled={type !== SCALE_TYPES.minmax}
                    onChange={handleChange}
                    error={touched.max && !!errors.max}
                  />
                </VerticalLabeled>
                <AutoSave onSubmit={submitForm} values={values} isValid={!error} />
              </div>
            )}
          </Formik>
          <RadioOption value={SCALE_TYPES.incremental}>
            {TICKS_SCALE_FORM.INCREMENTAL}
          </RadioOption>
          <Formik
            initialValues={{
              values: incremental.values.map(trimZeros).join(', '),
            }}
            validationSchema={this.schemas.incremental}
            onSubmit={values =>
              this.setIncremental(this.schemas.incremental.cast(values))
            }
            validateOnBlur
            enableReinitialize
          >
            {({ values, touched, error, errors, submitForm, handleChange }) => (
              <div className={element('OptionBody')}>
                <VerticalLabeled
                  label={TICKS_SCALE_FORM.VALUES}
                  error={
                    type === SCALE_TYPES.incremental && touched.values && errors.values
                  }
                >
                  <Input
                    name="values"
                    value={values.values}
                    disabled={type !== SCALE_TYPES.incremental}
                    onChange={handleChange}
                    error={touched.values && !!errors.values}
                    placeholder={TICKS_SCALE_FORM.VALUES_PLACEHOLDER}
                  />
                </VerticalLabeled>
                <AutoSave onSubmit={submitForm} values={values} isValid={!error} />
              </div>
            )}
          </Formik>
        </RadioGroup>
      </div>
    )
  }

  private onSetTicksCount = (index: number) =>
    this.props.onChange({ ...this.props.axis, ticksCount: TICKS[index] })

  private onChangeType = (type: Axis['type']) => {
    this.setState({ type })
    const axis = {
      ticksCount: this.props.axis.ticksCount,
      ...this.state[type],
      type,
    }
    this.props.onChange(axis as Axis)
  }

  private onSetInvert = (invert: boolean) => {
    this.setState({ automatic: { invert } })
    this.props.onChange({ ...this.props.axis, type: 'automatic', invert })
  }

  private setMinMax = (minmax: { min: number; max: number }) => {
    this.setState({ minmax })
    this.props.onChange({ ...this.props.axis, type: 'minmax', ...minmax })
  }

  private setIncremental = (incremental: { values: number[] }) => {
    this.setState({ incremental })
    this.props.onChange({
      ...this.props.axis,
      type: 'incremental',
      ...incremental,
    })
  }
}

export default TicksScaleForm
