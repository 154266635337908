import { createAction, ActionType, getType } from 'typesafe-actions'
import { TDispatch } from '.'
import * as maintenanceApi from 'api/maintenance'
import { CLIENT } from 'api/config'

export const ACTIONS = {
  setMaintenance: createAction(
    'SET_MAINTENANCE',
    resolve => (maintenance: boolean) => resolve(maintenance)
  ),
}

export type State = {
  maintenance: boolean
}

const INITIAL_STATE = (): State => ({
  maintenance: false,
})

export type MaintenanceActions = ActionType<(typeof ACTIONS)[keyof typeof ACTIONS]>

export function reducer(state = INITIAL_STATE(), action: MaintenanceActions): State {
  switch (action.type) {
    case getType(ACTIONS.setMaintenance):
      return { ...state, maintenance: action.payload }
    default:
      return state
  }
}

export const healthCheck = () => {
  return async (dispatch: TDispatch) => {
    await maintenanceApi.healthCheck()
    dispatch(ACTIONS.setMaintenance(false))
  }
}

export const setupInterceptor = () => {
  return async (dispatch: TDispatch) => {
    CLIENT.interceptors.response.use(
      response => response,
      error => {
        if (error?.response?.status === 418) {
          dispatch(ACTIONS.setMaintenance(true))
        }
        return Promise.reject(error)
      }
    )
  }
}
