import * as d3 from 'd3'
import * as React from 'react'
import * as FauxDOM from 'react-faux-dom'

import { YScale, renderAxisStyles, renderYAxis } from '../../services/hd3'

export interface IProps {
  scale: YScale
  axis: IncrementalAxis
  side: 'left' | 'right'
  offset: number
}

const YAxis = (props: IProps) => {
  const svg = FauxDOM.createElement('g')
  const selection = d3.select(svg)
  renderYAxis(selection, props.scale, props.offset, props.axis.values, props.side)
  renderAxisStyles(selection)

  return <>{React.Children.map(svg.toReact(), c => c.props.children)}</>
}

export default React.memo(YAxis, (prevProps, props) => {
  const KEYS = Object.keys(props).filter(key => key !== 'scale') as keyof IProps[]
  if (!KEYS.every(k => prevProps[k] === props[k])) {
    return false
  }
  const [oldDomain, domain] = [prevProps.scale.domain(), props.scale.domain()]
  const [oldRange, range] = [prevProps.scale.range(), props.scale.range()]

  return (
    oldDomain.every((v, i) => v === domain[i]) && oldRange.every((v, i) => v === range[i])
  )
})
