import amplitude from 'amplitude-js'
import ReactGA from 'react-ga'

import { AMPLITUDE_KEY, GA_ID, IS_PRODUCTION } from './config'

const DIMENSIONS = { organization: 'dimension1' }

export const amplitudeInstance = () => {
  if (AMPLITUDE_KEY) {
    return amplitude.getInstance()
  }
}

export const gaInstance = () => {
  if (GA_ID) {
    return ReactGA
  }
}

export const init = () => {
  gaInstance()?.initialize(GA_ID, { debug: !IS_PRODUCTION })
  amplitudeInstance()?.init(AMPLITUDE_KEY)
}

export const track = (
  category: string,
  action: string,
  info?: IAnalyticsInfo | string
) => {
  if (gaInstance()) {
    // TODO: [RIG] React-ga internal issue
    // https://github.com/react-ga/react-ga/blob/master/src/utils/format.js
    const labels: string[] = []

    if (info) {
      if (typeof info !== 'string') {
        Object.keys(info).forEach(key =>
          labels.push(`${key}:${(info[key] || 'null').toString()}`)
        )
      } else {
        labels.push(info)
      }
    }

    gaInstance().event({
      category,
      action,
      label: labels.join(', ').replace(/@/g, ''),
    })
  }

  const name = `${category}.${action}`
  const properties = typeof info === 'string' ? { action: info } : info

  amplitudeInstance()?.logEvent(name, properties)
  return true
}

export const setTrackingFields = (
  userId: number,
  orgId: number,
  orgName: string,
  orgType: OrganizationType,
  userEmail: string
) => {
  const organizationNameWithID = `#${orgId} ${orgName}`

  gaInstance()?.set({
    [DIMENSIONS.organization]: organizationNameWithID,
    userId,
  })
  amplitudeInstance()?.setUserId(userEmail)
  amplitudeInstance()?.setUserProperties({
    user_id: userId,
    user_email: userEmail,
    organization: {
      id: orgId,
      name: orgName,
      label: organizationNameWithID,
      type: orgType,
    },
  })
}

export const resetTrackingFields = () => {
  gaInstance()?.set({
    [DIMENSIONS.organization]: null,
    userId: null,
  })
  amplitudeInstance()?.setUserId(null)
  amplitudeInstance()?.clearUserProperties()
}
