// eslint-disable max-lines

import { CLIENT } from '../api/config'
import { fetchAll } from './common'

import { parseISO } from 'date-fns'
import { MENU_LAYOUT_URL } from 'api2/menu'

const ORG_URL = '/v2/organizations'
const USERS_URL = '/v2/users'
const USERS_V1_URL = '/v1/users'
const DB_URL = '/v2/data/databases'
const CTREE_DB_URL = '/v1/ctree/databases'
const PACKAGE_URL = '/v2/packages'
const TOKENS_URL = '/v2/tokens'
const MENU_PAGE_DBS_URL = '/v1/menu/databases'

interface IServerOrg {
  id: number
  name: string
  type: OrganizationType
  accountNumber: string
  settings: {
    isActive: boolean
    isMigrated: boolean
    usersLimit: number
    mfaStatus: boolean
    cidrs: string[]
    isRestricted: boolean
    ratelimit?: string | null
  }
  databaseNames: string[]
  packages: string[]
  previewDatabaseNames: string[]
  dbConfigName: string
  databaseCount: number
  usersCount: number
  softTerminationDate: string
  hardTerminationDate: string
}

export interface IAddOrgForm {
  name: string
  type: OrganizationType
  usersLimit: number
  accountNumber: string
  dbConfigName: string
  databases: string[]
  previewedDatabases: string[]
  packages: string[]
  mfaStatus: boolean
  cidrs: string[]
  isRestricted: boolean
  ratelimit: string | null
}

const toOrganization = (org: IServerOrg): IOrganization => {
  const {
    id,
    name,
    type,
    settings: {
      isActive,
      isMigrated,
      usersLimit = 0,
      mfaStatus,
      cidrs,
      isRestricted,
      ratelimit,
    },
    usersCount,
    accountNumber,
    databaseCount: databasesCount,
    previewDatabaseNames,
    databaseNames: databases,
    packages,
    dbConfigName,
    softTerminationDate,
    hardTerminationDate,
  } = org
  return {
    id,
    name,
    type,
    status: isActive,
    isMigrated,
    usersCount,
    databasesCount,
    previewedDatabasesCount: previewDatabaseNames.length,
    usersLimit,
    databases,
    previewedDatabases: previewDatabaseNames,
    packages,
    dbConfigName,
    accountNumber,
    mfaStatus,
    cidrs,
    isRestricted,
    softTerminationDate: softTerminationDate ? parseISO(softTerminationDate) : null,
    hardTerminationDate: hardTerminationDate ? parseISO(hardTerminationDate) : null,
    ratelimit,
  }
}

export const fetchOrganizations = async (): Promise<IOrganization[]> => {
  const response = await fetchAll<IServerOrg>(ORG_URL, { per_page: 400 })
  return response.map(toOrganization)
}

export const fetchOrganization = async (id: number): Promise<IOrganization> => {
  const response = await CLIENT.get<IServerOrg>(`${ORG_URL}/${id}`)
  return toOrganization(response.data)
}

export const createOrganization = async (form: IAddOrgForm): Promise<IOrganization> => {
  const data = {
    name: form.name,
    type: form.type,
    databaseNames: form.databases,
    previewDatabaseNames: form.previewedDatabases,
    settings: {
      isActive: true,
      isMigrated: false,
      usersLimit: form.usersLimit,
      mfaStatus: form.mfaStatus,
      isRestricted: form.isRestricted,
      cidrs: form.cidrs,
      ratelimit: form.ratelimit,
    },
    packages: form.packages,
    dbConfigName: form.dbConfigName,
    accountNumber: form.accountNumber,
  }

  const response = await CLIENT.post<IServerOrg>(ORG_URL, data)
  return toOrganization(response.data)
}

export const patchOrganization = async (
  id: number,
  org: Partial<IOrganization>
): Promise<IOrganization> => {
  const response = await CLIENT.patch<IServerOrg>(`${ORG_URL}/${id}`, {
    name: org.name,
    type: org.type,
    databaseNames: org.databases,
    previewDatabaseNames: org.previewedDatabases,
    settings: {
      isActive: org.status,
      isMigrated: org.isMigrated,
      usersLimit: org.usersLimit,
      mfaStatus: org.mfaStatus,
      isRestricted: org.isRestricted,
      cidrs: org.cidrs,
      ratelimit: org.ratelimit,
    },
    packages: org.packages,
    dbConfigName: org.dbConfigName,
    accountNumber: org.accountNumber,
    softTerminationDate: org.softTerminationDate,
  })
  return toOrganization(response.data)
}

export const activateOrganization = (id: number) =>
  patchOrganization(id, { status: true })

export const deactivateOrganization = (id: number) =>
  patchOrganization(id, { status: false })

export const deleteOrganization = (id: number) => CLIENT.delete(`${ORG_URL}/${id}`)

export const fetchUsers = async (id: number, per_page = 100): Promise<IUser[]> =>
  fetchAll<IUser>(USERS_URL, { organization_id: id, per_page })

export const fetchTokens = async (id: number): Promise<IToken[]> =>
  fetchAll<IToken>(`${ORG_URL}/${id}/tokens`)

export const createUser = async (user: IUser): Promise<IUser> => {
  const { username, email, organizationId, settings, role } = user
  const data = {
    username,
    email,
    settings,
    organization_id: organizationId,
    role,
  }
  const response = await CLIENT.post<IUser>(USERS_URL, data)
  return response.data
}

export const updateUser = async (user: IUser): Promise<IUser> => {
  const { username, email, settings, organizationId, role } = user
  const data = {
    username,
    email,
    settings,
    organizationId,
    role,
  }
  const response = await CLIENT.patch<IUser>(`${USERS_URL}/${user.id}`, data)
  return response.data
}

export const validateEmail = async (email: string): Promise<number> => {
  const response = await CLIENT.get(`${USERS_V1_URL}/${email}`)
  return response.data.organizationId
}

export const deleteUser = async (id: number) => CLIENT.delete(`${USERS_URL}/${id}`)

export const updateUserSettings = async (user: IUser): Promise<IUser> => {
  const response = await CLIENT.patch<IUser>(`${USERS_URL}/${user.id}`, {
    settings: user.settings,
  })
  return response.data
}

export const fetchDatabases = async (): Promise<IDatabase[]> =>
  fetchAll<IDatabase>(DB_URL, { per_page: 10000 })

export const fetchDatabasesWithMenuPages = async (): Promise<string[]> => {
  const response = await CLIENT.get(`${MENU_PAGE_DBS_URL}`)
  return response.data
}

export const fetchDatabase = async (name: string): Promise<IDatabaseDetails> => {
  const response = await CLIENT.get<IDatabaseDetails>(`${DB_URL}/${name}`)
  return response.data
}

export const updateDatabase = async (db: IDatabaseDetails): Promise<IDatabase> => {
  const database: Partial<IDatabase> = {
    ...db,
    description: db.description ?? '',
  }
  const response = await CLIENT.patch<IDatabase>(`${DB_URL}/${db.name}`, database)
  return response.data
}

export const deleteDatabase = async (db: IDatabaseDetails) =>
  CLIENT.delete(`${DB_URL}/${db.name}`)

export const fetchCTreeDatabases = async (): Promise<string[]> => {
  const response = await CLIENT.get<string[]>(CTREE_DB_URL)
  return response.data
}

export const fetchPackages = async (): Promise<IDBPackage[]> =>
  fetchAll<IDBPackage>(`${PACKAGE_URL}`)

export const fetchMenuLayoutsList = async (): Promise<IMenu[]> => {
  const response = (await CLIENT.get<IMenusResponse>(MENU_LAYOUT_URL)).data
  return response.data
}

export const createPackage = async (pkg: IDBPackage): Promise<IDBPackage> => {
  const response = await CLIENT.post<IDBPackage>(`${PACKAGE_URL}`, pkg)
  return response.data
}

export const updatePackage = async (pkg: IDBPackage): Promise<IDBPackage> => {
  const response = await CLIENT.patch<IDBPackage>(`${PACKAGE_URL}/${pkg.id}`, pkg)
  return response.data
}

export const togglePackage = async (pkg: IDBPackage) =>
  pkg.isEnabled ? _enablePackage(pkg) : _disablePackage(pkg)

const _enablePackage = async (pkg: IDBPackage) =>
  CLIENT.post<IDBPackage>(`${PACKAGE_URL}/${pkg.id}/enable`, pkg)

const _disablePackage = async (pkg: IDBPackage) =>
  CLIENT.post(`${PACKAGE_URL}/${pkg.id}/disable`, pkg)

export const removePackage = async (pkg: IDBPackage) =>
  CLIENT.delete(`${PACKAGE_URL}/${pkg.id}`)

export const updateUsersSettings = async (users: IUser[]): Promise<IUser[]> => {
  const response = await CLIENT.patch<IUser[]>(
    `${USERS_URL}/bulk`,
    users.map(({ id, settings }) => ({
      id,
      settings,
    }))
  )
  return response.data
}

export const updateUsers = async (users: IUser[]): Promise<IUser[]> => {
  const response = await CLIENT.patch<IUser[]>(
    `${USERS_URL}/bulk`,
    users.map(({ id, role }) => ({
      id,
      role,
    }))
  )
  return response.data
}

export const deleteUsers = async (users: IUser[]): Promise<IUser[]> => {
  const response = await CLIENT.delete<IUser[]>(`${USERS_URL}/bulk`, {
    data: users.map(user => user.id),
  })
  return response.data
}

export const createUsers = async (users: IUser[]): Promise<IUser[]> => {
  const response = await CLIENT.post<IUser[]>(`${USERS_URL}/bulk`, users)
  return response.data
}

export const extendToken = async (token: IToken, isoDate: string): Promise<IToken> => {
  const response = await CLIENT.put(`${TOKENS_URL}/${token.id}/extend`, {
    isoDate,
  })
  return response.data
}

export const deactivateToken = async (
  token: IToken,
  isoDate: string
): Promise<IToken> => {
  const response = await CLIENT.put(`${TOKENS_URL}/${token.id}/deactivate`, {
    isoDate,
  })
  return response.data
}

export const deactivateTokensBulk = async (tokens: IToken[]): Promise<IToken[]> => {
  const response = await CLIENT.patch(`${TOKENS_URL}/bulk/deactivate`, {
    data: tokens.map(token => ({ id: token.id })),
  })
  return response.data
}

export const extendTokensBulk = async (
  tokens: IToken[],
  isoDate: Date | null
): Promise<IToken[]> => {
  const response = await CLIENT.patch(`${TOKENS_URL}/bulk/extend`, {
    isoDate,
    data: tokens.map(token => ({ id: token.id })),
  })
  return response.data
}

export const refreshTokensBulk = async (tokens: IToken[]): Promise<IToken[]> => {
  const response = await CLIENT.patch(`${TOKENS_URL}/bulk/refresh`, {
    data: tokens.map(token => ({ id: token.id })),
  })
  return response.data
}
