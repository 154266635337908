// eslint-disable max-lines
import * as React from 'react'
import { connect } from 'react-redux'

import { bindBem } from '../../bem'
import { MAX_VARIABLES, SERIES_TRANSFORMATIONS_LIMIT } from '../../constants'
import FunctionsContainer from '../../containers/FunctionsContainer'
import { SIDEBAR } from '../../messages'
import { COLORS, canApplyStackedBar, hasTrendLine } from '../../services/series'
import { IRootState } from '../../store'
import {
  ACTIONS,
  fetchCorrelation,
  unfreezeSeries,
  updateSeriesOnly,
} from '../../store/Series'
import * as transformations from '../../store/Transformations'
import * as Drop from '../Droppable'
import { AsideDialog } from '../Modal'
import SeriesSearch from '../SeriesSearch'
import DataDirectory from './DataDirectory'
import { ScatterCorrelationBox } from './ScatterCorrelationBox'
import { SeriesBox } from './SeriesBox'
import { SeriesBoxTransformation } from './SeriesBoxTransformation'

import './SeriesTab.scss'

export interface IStateProps {
  variables: IDataSeries[]
  graphTypes: ISeriesType[]
  isScatterPlot: boolean
  correlation: ICorrelation
  scatterSettings: IScatterSettings
  activeModal: SeriesModal
}

export interface IActionProps {
  deleteVariable: (index: number) => void
  moveVariable: (index: number, target: number) => void
  onChartTypeChange: (type: ISeriesType, index?: number) => void
  addInterpolation: (series: IDataSeries, index: number) => void
  removeInterpolation: (series: IDataSeries, index: number) => void
  addTrendLine: (series: IDataSeries) => void
  applyFunctionTo: (transformation: ITransformation, i: number) => IDataSeries
  applyAggregationTo: (series: IDataSeries, agg: Aggregation) => void
  revertTransformation: (series: IDataSeries, index: number) => void
  cancelScatterPlot: () => void
  toggleDataMarkers: (index: number) => void
  toggleDataLabels: (index: number) => void
  setRegressionType: (type: RegressionType) => void
  setScatterTimeline: (enabled: boolean) => void
  setScatterAxisType: (type: ScatterAxisType) => void
  setModal: (modal: SeriesModal) => void
  unfreezeSeries: (index: number) => void
}

interface ISeriesTransformation {
  index1: number
  index2: number
  initialIndex1: number
  initialIndex2: number
  transformationType: TransformationType
}

interface IState {
  draggedSeriesIndex: number
  replacedSeriesIndex: number
  isDragging: boolean
  isFunctionsOpen: boolean
  functionsOpenFor: number
  seriesTransformation?: ISeriesTransformation
}

const Dropzone = (props: Drop.IProps) => {
  return (
    <Drop.Droppable {...props}>
      <div className="Dropzone" />
    </Drop.Droppable>
  )
}

export class SeriesTab extends React.Component<IStateProps & IActionProps, IState> {
  state: IState = {
    draggedSeriesIndex: null,
    isDragging: false,
    isFunctionsOpen: false,
    functionsOpenFor: 0,
    replacedSeriesIndex: null,
  }

  static getDerivedStateFromProps(
    props: IStateProps & IActionProps,
    state: IState
  ): IState | null {
    if (state.functionsOpenFor >= props.variables.length) {
      return { ...state, functionsOpenFor: 0 }
    }
    return null
  }

  render() {
    const { element, block } = bindBem('SeriesTab')
    const { variables, correlation, isScatterPlot } = this.props
    const { isDragging, seriesTransformation } = this.state

    const scatterVars = this.isScatterOrCorrelation() ? variables.slice(0, 2) : []
    return (
      <div className={block({ isDragging })}>
        {variables.length === MAX_VARIABLES && (
          <div className={element('Error')}>{SIDEBAR.SERIES_TAB.MAX_VARIABLES_ERROR}</div>
        )}
        {this.isScatterOrCorrelation() && (
          <ScatterCorrelationBox
            variables={variables}
            scatter={isScatterPlot}
            correlation={correlation}
            onAggregate={this.props.applyAggregationTo}
            onCancel={this.props.cancelScatterPlot}
            scatterSettings={this.props.scatterSettings}
            setRegressionType={this.props.setRegressionType}
            setScatterTimeline={this.props.setScatterTimeline}
            setAxisType={this.props.setScatterAxisType}
          >
            {scatterVars.map((v, i) => (
              <React.Fragment key={v.uuid}>
                <Dropzone
                  onDrop={event => this.moveSeries(event, i)}
                  isDroppable={isDragging}
                />
                <this.SeriesBox i={i} isDroppable isScatterPlot={isScatterPlot} />
              </React.Fragment>
            ))}
          </ScatterCorrelationBox>
        )}
        {variables.map(
          (s, i) =>
            i >= scatterVars.length && (
              <React.Fragment key={s.uuid}>
                {!(seriesTransformation && i === seriesTransformation.initialIndex1) && (
                  <Dropzone
                    onDrop={event => this.moveSeries(event, i)}
                    isDroppable={isDragging}
                  />
                )}
                <this.SeriesBox i={i} isDroppable />
              </React.Fragment>
            )
        )}
        <Dropzone
          onDrop={event => this.moveSeries(event, variables.length)}
          isDroppable={isDragging}
          fill
        />
        <AsideDialog
          isOpen={this.state.isFunctionsOpen}
          onClose={this.closeFunctions}
          title={SIDEBAR.FUNCTIONS}
        >
          <FunctionsContainer
            applyTo={this.state.functionsOpenFor}
            onClose={this.closeFunctions}
          />
        </AsideDialog>
      </div>
    )
  }

  isStackedBarDisabled = (series: IDataSeries) =>
    !canApplyStackedBar(series, this.props.graphTypes, this.props.variables)

  private SeriesBox = ({
    i,
    isDroppable,
    isScatterPlot = false,
  }: {
    i: number
    isDroppable: boolean
    isScatterPlot?: boolean
  }) => {
    const { variables, graphTypes, deleteVariable, onChartTypeChange, toggleDataLabels } =
      this.props
    const {
      addInterpolation,
      removeInterpolation,
      revertTransformation,
      toggleDataMarkers,
    } = this.props
    const { draggedSeriesIndex, seriesTransformation } = this.state
    const s = variables[i]
    const isTransforming =
      seriesTransformation && i === seriesTransformation.initialIndex2
    const colorIndex = isTransforming
      ? variables[seriesTransformation.index2].colorIndex
      : s.colorIndex
    const canRevertTransformation =
      transformations.isTransformationApplied(s) &&
      this.props.variables.length < MAX_VARIABLES
    const canRevertFunction = transformations.isFunctionApplied(s)
    const { element } = bindBem('SeriesTab')
    return (
      <>
        {!this.isSeriesTransformed(i) && (
          <SeriesBox
            series={s}
            color={COLORS[colorIndex]}
            onDelete={variables.length > 1 ? () => deleteVariable(i) : undefined}
            onDragStarted={() => this.onDragStarted(i)}
            onDragStopped={this.onDragStopped}
            isDraggable={!seriesTransformation && variables.length > 1}
            isTrendLineSource={this.getTrendLine(s) !== undefined}
            isTrendlineDisabled={variables.length >= MAX_VARIABLES}
            graphType={isScatterPlot ? 'SCATTER' : graphTypes[i]}
            onChartTypeChange={(type: ISeriesType) => onChartTypeChange(type, i)}
            toggleInterpolation={() =>
              transformations.hasInterpolation(s)
                ? removeInterpolation(s, i)
                : addInterpolation(s, i)
            }
            toggleTrendLine={() => this.toggleTrendLine(s)}
            isDroppable={isDroppable && this.isDroppable(s, i)}
            openFunctionsModal={() => this.openFunctionsFor(i)}
            onDrop={event => this.transformSeries(event, draggedSeriesIndex, i)}
            isTransformationReversable={canRevertFunction || canRevertTransformation}
            revertTransformation={() => revertTransformation(s, i)}
            stackedBarDisabled={this.isStackedBarDisabled(s)}
            toggleDataMarkers={() => toggleDataMarkers(i)}
            toggleDataLabels={() => toggleDataLabels(i)}
            onDoubleClick={() => this.setReplacedSeries(i)}
            unfreezeSeries={() => this.props.unfreezeSeries(i)}
          />
        )}
        {isTransforming && (
          <div className={element('SeriesBoxTransformationWrapper')}>
            <SeriesBoxTransformation
              series1={variables[seriesTransformation.index1]}
              series2={variables[seriesTransformation.index2]}
              transformationType={this.state.seriesTransformation.transformationType}
              onCancel={this.cancelSeriesTransformation}
              onSave={this.applySeriesTransformation}
              color={COLORS[colorIndex]}
              swapSeries={this.swapSeries}
              onTransformationTypeUpdate={this.onTransformationTypeUpdate}
            />
          </div>
        )}
        <AsideDialog
          isOpen={this.props.activeModal === 'replace'}
          onClose={() => this.props.setModal(null)}
          onClosed={this.clearReplacedSeries}
          title={SIDEBAR.SERIES_TAB.REPLACE_SERIES}
        >
          <SeriesSearch replaceIndex={this.state.replacedSeriesIndex}>
            <DataDirectory replaceIndex={this.state.replacedSeriesIndex} />
          </SeriesSearch>
        </AsideDialog>
      </>
    )
  }
  private isScatterOrCorrelation = () =>
    this.props.isScatterPlot || this.props.correlation.enabled

  private getTrendLine = (series: IDataSeries) => {
    const predicate = (s: IDataSeries) => {
      if (!hasTrendLine(s)) {
        return false
      }
      return s.id === series.id && s.databaseId === series.databaseId
    }
    return this.props.variables.find(predicate)
  }

  private onDragStarted = (draggedSeriesIndex: number) => {
    this.setState({ draggedSeriesIndex, isDragging: true })
  }

  private onDragStopped = () =>
    this.setState(() => ({
      isDragging: false,
    }))

  private moveSeries = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    targetIndex: number
  ) => {
    const { className } = event.target as EventTarget & HTMLElement
    if (typeof className === 'string' && !className.includes('Dropzone')) {
      return
    }

    const currentIndex = this.state.draggedSeriesIndex
    if (currentIndex < targetIndex) {
      targetIndex -= 1
    }
    if (currentIndex !== targetIndex) {
      this.props.moveVariable(currentIndex, targetIndex)
    }
    this.setState({ draggedSeriesIndex: null })
  }

  private toggleTrendLine = (source: IDataSeries) => {
    const trendline = this.getTrendLine(source)
    if (trendline === undefined) {
      this.props.addTrendLine(source)
    } else {
      this.props.deleteVariable(this.props.variables.indexOf(trendline))
    }
  }
  private openFunctionsFor = (functionsOpenFor: number) =>
    this.setState({ isFunctionsOpen: true, functionsOpenFor })
  private closeFunctions = () => this.setState({ isFunctionsOpen: false })

  private transformSeries = (
    event: React.MouseEvent<HTMLElement>,
    draggedSeriesIndex: number,
    droppedSeriesIndex: number
  ) => {
    const { className } = event.target as EventTarget & HTMLElement
    if (
      typeof className === 'string' &&
      !className.includes('SeriesBox__transformationDropzone')
    ) {
      return
    }
    if (
      draggedSeriesIndex === null ||
      droppedSeriesIndex === null ||
      draggedSeriesIndex === droppedSeriesIndex
    ) {
      return
    }

    const { variables } = this.props
    const draggedSeries = variables[draggedSeriesIndex]
    const droppedSeries = variables[droppedSeriesIndex]

    if (
      this.countNestedTransformations(draggedSeries, droppedSeries) >=
      SERIES_TRANSFORMATIONS_LIMIT
    ) {
      return
    }

    const seriesTransformation: ISeriesTransformation = {
      index1: draggedSeriesIndex,
      index2: droppedSeriesIndex,
      initialIndex1: draggedSeriesIndex,
      initialIndex2: droppedSeriesIndex,
      transformationType: 'ADD',
    }
    this.setState({
      ...this.state,
      draggedSeriesIndex: null,
      seriesTransformation,
    })
  }

  private setReplacedSeries = (index: number) => {
    this.setState({ replacedSeriesIndex: index }, () => this.props.setModal('replace'))
  }
  private clearReplacedSeries = () => {
    this.setState({ replacedSeriesIndex: null })
  }

  private isSeriesTransformed = (index: number) => {
    const seriesTransformation = this.state.seriesTransformation
    if (this.state.seriesTransformation) {
      return (
        index === seriesTransformation.index1 || index === seriesTransformation.index2
      )
    }
    return false
  }

  private cancelSeriesTransformation = () => {
    this.setState({ ...this.state, seriesTransformation: null })
  }

  private applySeriesTransformation = async () => {
    const { variables, applyFunctionTo } = this.props
    const { seriesTransformation } = this.state
    const series1 = variables[seriesTransformation.index1]
    const series2 = variables[seriesTransformation.index2]

    const transformation: ITransformation = {
      func: this.state.seriesTransformation.transformationType,
      args: [
        transformations.seriesToTransformation(series1),
        transformations.seriesToTransformation(series2),
      ],
    }

    const series = await applyFunctionTo(transformation, seriesTransformation.index2)
    this.setState({ ...this.state, seriesTransformation: null })
    if (series) {
      if (seriesTransformation.index2 !== seriesTransformation.initialIndex2) {
        this.props.moveVariable(seriesTransformation.index2, seriesTransformation.index1)
      }
      this.props.deleteVariable(seriesTransformation.initialIndex1)
    }
  }

  private swapSeries = () =>
    this.setState({
      ...this.state,
      seriesTransformation: {
        ...this.state.seriesTransformation,
        index1: this.state.seriesTransformation.index2,
        index2: this.state.seriesTransformation.index1,
      },
    })

  private onTransformationTypeUpdate = (transformationType: TransformationType) =>
    this.setState({
      ...this.state,
      seriesTransformation: {
        ...this.state.seriesTransformation,
        transformationType,
      },
    })

  private countNestedTransformations = (
    draggingSeries: IDataSeries,
    droppingSeries: IDataSeries
  ) =>
    (draggingSeries
      ? transformations.countNestedTransformations(draggingSeries.transformation)
      : 0) +
    (droppingSeries
      ? transformations.countNestedTransformations(droppingSeries.transformation)
      : 0)

  private isDroppable = (droppingSeries: IDataSeries, index: number) => {
    const { isDragging, draggedSeriesIndex } = this.state
    const { variables } = this.props
    const draggingSeries = variables[draggedSeriesIndex]
    const nestedTransformationsCount = this.countNestedTransformations(
      draggingSeries,
      droppingSeries
    )
    return (
      isDragging &&
      draggedSeriesIndex !== index &&
      nestedTransformationsCount < SERIES_TRANSFORMATIONS_LIMIT
    )
  }
}

const mapStateToProps = (state: IRootState): IStateProps => {
  const { variables, correlation, scatterSettings, seriesSettings, activeModal } =
    state.series
  return {
    correlation,
    graphTypes: seriesSettings.graphTypes,
    isScatterPlot: seriesSettings.isScatterPlot,
    scatterSettings,
    variables,
    activeModal,
  }
}
const mapDispatchToProps = (dispatch: any): IActionProps => {
  return {
    deleteVariable: index => {
      dispatch(ACTIONS.deleteVariable(index))
      if (index < 2) {
        dispatch(fetchCorrelation())
      }
    },
    moveVariable: (index, targetIndex) => {
      dispatch(ACTIONS.moveVariable(index, targetIndex))
      if (index < 2 || targetIndex < 2) {
        dispatch(fetchCorrelation())
      }
    },
    onChartTypeChange: (type, index?) => dispatch(ACTIONS.setSeriesType(type, index)),
    setModal: modal => dispatch(ACTIONS.setActiveModal(modal)),
    addTrendLine: s => dispatch(transformations.addTrendLine(s)),
    applyAggregationTo: (series, agg) =>
      dispatch(transformations.addAggregationToSingleSeries(series, agg)),
    applyFunctionTo: (transformation, index) =>
      dispatch(updateSeriesOnly(transformation, index)),
    revertTransformation: (series, index) =>
      dispatch(transformations.removeTransformation(series, index)),
    cancelScatterPlot: () => dispatch(ACTIONS.cancelScatterPlot()),
    toggleDataMarkers: index => dispatch(ACTIONS.toggleDataMarkers(index)),
    toggleDataLabels: index => dispatch(ACTIONS.toggleDataLabels(index)),
    removeInterpolation: (series, index) =>
      dispatch(transformations.removeInterpolation(series, index)),
    addInterpolation: (series, index) =>
      dispatch(transformations.addInterpolation(series, index)),
    setRegressionType: t => dispatch(ACTIONS.setRegressionType(t)),
    setScatterTimeline: enabled => dispatch(ACTIONS.setScatterTimeline(enabled)),
    setScatterAxisType: type => dispatch(ACTIONS.setScatterAxisType(type)),
    unfreezeSeries: (index: number) => dispatch(unfreezeSeries(index)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SeriesTab)
