// eslint-disable max-lines

import {
  map as treeMap,
  defaultGetNodeKey,
  insertNode,
  changeNodeAtPath,
  removeNodeAtPath,
  addNodeUnderParent,
} from 'react-sortable-tree'
import { ADMIN } from 'messages'
import {
  ITreeItem,
  ITreeItemGroup,
  ITreeItemDb,
  ITreeItemSeparator,
  ITree,
  ITreeItemCategory,
  Path,
} from './menu.types'

export const isTreeItemGroup = (item: ITreeItem): item is ITreeItemGroup =>
  item.original.type === 'group'
export const isTreeItemCategory = (item: ITreeItem): item is ITreeItemCategory =>
  item.original.type === 'category'
export const isTreeItemDb = (item: ITreeItem): item is ITreeItemDb =>
  item.original.type === 'db'

export const extractDbNamesFromTree = (treeData: ITree): string[] => {
  const usedDbs: { [key: string]: true } = {}
  for (const treeItem of treeData) {
    if (isTreeItemGroup(treeItem)) {
      for (const groupItem of treeItem.children) {
        if (isTreeItemDb(groupItem)) {
          usedDbs[groupItem.original.name] = true
        }
      }
    }
    if (isTreeItemCategory(treeItem)) {
      for (const categoryItem of treeItem.children) {
        for (const groupItem of categoryItem.children) {
          if (isTreeItemDb(groupItem)) {
            usedDbs[groupItem.original.name] = true
          }
        }
      }
    }
  }
  return Object.keys(usedDbs)
}

export const generateTitle = (node: ITreeItem) => {
  switch (node.original.type) {
    case 'group': {
      return node.original.label
    }
    case 'db': {
      return node.original.name
    }
    case 'category': {
      return node.original.label
    }
    case 'separator': {
      return ADMIN.MENUS.SEPARATOR.LABEL
    }
  }
}
export const checkIfNodeCanHaveChildren = (node: ITreeItem): boolean => {
  return ['category', 'group'].includes(node.original.type)
}

export const checkIfNodeCanBeDropped = (
  node: ITreeItem,
  nextParent: ITreeItem | null
): boolean => {
  switch (node.original.type) {
    case 'category':
      return nextParent === null
    case 'group':
      return nextParent?.original?.type === 'category' || nextParent === null
    case 'db':
    case 'separator':
      return nextParent?.original?.type === 'group'
  }
}

export const convertMenuEntriesToTree = (menu: IMenuLayout): ITree => {
  const omit = <T, K extends keyof T>(obj: T, key: K) => {
    const { [key]: _, ...rest } = obj
    return rest
  }
  const convertSeparator = (separator: IMenuEntrySeparator): ITreeItemSeparator => ({
    original: separator,
  })
  const convertDb = (db: IMenuEntryDb): ITreeItemDb => ({
    original: db,
  })
  const convertGroup = (group: IMenuEntryGroup): ITreeItemGroup => ({
    expanded: true,
    original: omit(group, 'children'),
    editingName: false,
    children: group.children.map(entry => {
      switch (entry.type) {
        case 'db':
          return convertDb(entry)
        case 'separator':
          return convertSeparator(entry)
      }
    }),
  })
  const convertCategory = (category: IMenuEntryCategory): ITreeItemCategory => ({
    expanded: true,
    original: omit(category, 'children'),
    editingName: false,
    children: category.children.map(entry => convertGroup(entry)),
  })
  return menu.layout.map(entry => {
    switch (entry.type) {
      case 'category':
        return convertCategory(entry)
      case 'group':
        return convertGroup(entry)
    }
  })
}

export const convertTreeToMenuEntries = (menuTree: ITree): IMenuLayout['layout'] => {
  const convertSeparator = (separator: ITreeItemSeparator): IMenuEntrySeparator =>
    separator.original
  const convertDb = (db: ITreeItemDb): IMenuEntryDb => db.original
  const convertGroup = (group: ITreeItemGroup): IMenuEntryGroup => ({
    ...group.original,
    children: group.children.map(entry => {
      switch (entry.original.type) {
        case 'separator':
          return convertSeparator(entry as ITreeItemSeparator)
        case 'db':
          return convertDb(entry as ITreeItemDb)
      }
    }),
  })
  const convertCategory = (category: ITreeItemCategory): IMenuEntryCategory => ({
    ...category.original,
    children: category.children.map(entry => convertGroup(entry)),
  })
  return menuTree.map(entry => {
    switch (entry.original.type) {
      case 'category':
        return convertCategory(entry as ITreeItemCategory)
      case 'group':
        return convertGroup(entry as ITreeItemGroup)
    }
  })
}

export const setExpandedOnGroups = (treeData: ITree, expanded: boolean): ITree =>
  treeMap({
    treeData,
    getNodeKey: defaultGetNodeKey,
    callback: ({ node }: { node: ITreeItem }) => {
      if (node.original?.type === 'group') {
        return { ...node, expanded }
      }
      return { ...node }
    },
    ignoreCollapsed: false,
  }) as ITree

export const collapseGroups = (treeData: ITree): ITree =>
  setExpandedOnGroups(treeData, false)

export const expandGroups = (treeData: ITree): ITree =>
  setExpandedOnGroups(treeData, true)

export const addNewGroup = (treeData: ITree, path: Path, addInside: boolean): ITree => {
  const newNode: ITreeItemGroup = {
    original: {
      type: 'group',
      expanded: true,
      label: ADMIN.MENUS.DEFAULT_NEW_GROUP_NAME,
    },
    children: [],
    expanded: true,
    editingName: true,
  }
  const insertedData = addInside
    ? addNodeUnderParent({
        treeData,
        newNode,
        parentKey: path[path.length - 1],
        getNodeKey: defaultGetNodeKey,
      })
    : insertNode({
        treeData,
        newNode,
        depth: path.length - 1,
        minimumTreeIndex: +path[path.length - 1] + 1,
        getNodeKey: defaultGetNodeKey,
      })

  return insertedData.treeData as ITree
}

export const addNewCategory = (treeData: ITree, path: Path): ITree => {
  const newNode: ITreeItemCategory = {
    original: {
      type: 'category',
      label: ADMIN.MENUS.DEFAULT_NEW_CATEGORY_NAME,
    },
    children: [],
    expanded: true,
    editingName: true,
  }
  const insertedData = insertNode({
    treeData,
    newNode,
    depth: path.length - 1,
    minimumTreeIndex: +path[path.length - 1] + 1,
    getNodeKey: defaultGetNodeKey,
  })

  return insertedData.treeData as ITree
}

export const enterEditGroupNameMode = (
  treeData: ITree,
  node: ITreeItemGroup,
  path: Path
): ITree => {
  const updatedNode: ITreeItemGroup = {
    ...node,
    editingName: true,
  }
  return changeNodeAtPath({
    treeData,
    path,
    newNode: updatedNode,
    getNodeKey: defaultGetNodeKey,
  }) as ITree
}

export const enterEditCategoryNameMode = (
  treeData: ITree,
  node: ITreeItemCategory,
  path: Path
): ITree => {
  const updatedNode: ITreeItemCategory = {
    ...node,
    editingName: true,
  }
  return changeNodeAtPath({
    treeData,
    path,
    newNode: updatedNode,
    getNodeKey: defaultGetNodeKey,
  }) as ITree
}

export const changeGroupName = (
  treeData: ITree,
  node: ITreeItemGroup,
  path: Path,
  newName: string
): ITree => {
  const updatedNode: ITreeItemGroup = {
    ...node,
    original: {
      ...node.original,
      label: newName,
    },
    editingName: false,
  }
  return changeNodeAtPath({
    treeData,
    path,
    newNode: updatedNode,
    getNodeKey: defaultGetNodeKey,
  }) as ITree
}

export const changeCategoryName = (
  treeData: ITree,
  node: ITreeItemCategory,
  path: Path,
  newName: string
): ITree => {
  const updatedNode: ITreeItemCategory = {
    ...node,
    original: {
      ...node.original,
      label: newName,
    },
    editingName: false,
  }
  return changeNodeAtPath({
    treeData,
    path,
    newNode: updatedNode,
    getNodeKey: defaultGetNodeKey,
  }) as ITree
}

export const deleteItem = (treeData: ITree, path: Path): ITree =>
  removeNodeAtPath({
    treeData,
    path,
    getNodeKey: defaultGetNodeKey,
  }) as ITree

export const addDb = (
  treeData: ITree,
  path: Path,
  dbName: string,
  addAsFirstChild = true
): ITree => {
  const newNode: ITreeItemDb = {
    original: {
      type: 'db',
      name: dbName,
    },
  }
  const insertedData = addNodeUnderParent({
    treeData,
    newNode,
    parentKey: path[path.length - 1],
    getNodeKey: defaultGetNodeKey,
    addAsFirstChild,
  })

  return insertedData.treeData as ITree
}

export const addSeparator = (
  treeData: ITree,
  path: Path,
  addAsFirstChild = true
): ITree => {
  const newNode: ITreeItemSeparator = {
    original: {
      type: 'separator',
    },
  }
  const insertedData = addNodeUnderParent({
    treeData,
    newNode,
    parentKey: path[path.length - 1],
    getNodeKey: defaultGetNodeKey,
    addAsFirstChild,
  })

  return insertedData.treeData as ITree
}

export const toggleCollapseByDefault = (
  treeData: ITree,
  node: ITreeItemGroup,
  path: Path
): ITree => {
  const updatedNode: ITreeItemGroup = {
    ...node,
    original: {
      ...node.original,
      expanded: !node.original.expanded,
    },
  }
  return changeNodeAtPath({
    treeData,
    path,
    newNode: updatedNode,
    getNodeKey: defaultGetNodeKey,
  }) as ITree
}

export const setTreeItemExpanded = (
  treeData: ITree,
  node: ITreeItem,
  path: Path,
  expanded: boolean
): ITree => {
  const updatedNode: ITreeItem = {
    ...node,
    expanded,
  }
  return changeNodeAtPath({
    treeData,
    path,
    newNode: updatedNode,
    getNodeKey: defaultGetNodeKey,
  }) as ITree
}

export const toggleTreeItemExpand = (
  treeData: ITree,
  node: ITreeItem,
  path: Path
): ITree => setTreeItemExpanded(treeData, node, path, !node.expanded)
