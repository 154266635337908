import * as React from 'react'
import { bindBem } from '../../bem'
import { Footnote } from './Footnote'

import close from '../../static/close-dialog.svg'

import './FootnoteDialog.scss'

interface IProps {
  footnoteId: string
  title: string
  text: string
  onClose: () => void
}

export class FootnoteDialog extends React.PureComponent<IProps> {
  handleClickOutside = this.props.onClose
  render() {
    const { block, element } = bindBem('FootnoteDialog')
    const { footnoteId, title, text, onClose } = this.props
    return (
      <div className={block()}>
        <img className={element('Close')} src={close} onClick={onClose} />
        <div className={element('Title')}>
          {title} <Footnote footnoteId={footnoteId} />
        </div>
        <div className={element('Text')}> {text} </div>
      </div>
    )
  }
}
