// eslint-disable max-len

import * as React from 'react'

export const ICON_LOUPE = (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12">
    <path
      fill="#485465"
      fillRule="nonzero"
      d="M10.093 8.48a5.33 5.33 0 0 0 1.049-3.176C11.142 2.379 8.802 0 5.927 0S.713 2.379.713 5.303s2.34 5.304 5.214 5.304c1.173 0 2.251-.4 3.123-1.066l2.202 2.24a.732.732 0 0 0 1.043 0 .758.758 0 0 0 0-1.061l-2.202-2.24zm-4.166.627c-2.061 0-3.739-1.706-3.739-3.804 0-2.097 1.678-3.803 3.74-3.803 2.06 0 3.738 1.706 3.738 3.803S7.99 9.107 5.927 9.107z"
    />
  </svg>
)
