import React from 'react'

export const useClickOutside = (
  ref: React.RefObject<any>,
  enabled: boolean,
  callback: () => void
) => {
  const handleClick = React.useCallback(
    (event: Event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    },
    [callback]
  )
  React.useEffect(() => {
    if (enabled && ref.current) {
      document.addEventListener('click', handleClick)
      return () => {
        document.removeEventListener('click', handleClick)
      }
    }
  }, [enabled, ref.current])
}
