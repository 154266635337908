import { CLIENT } from 'api/config'
import { AxiosResponse } from 'axios'

export const MENU_LAYOUT_URL = '/v2/menu/layouts'
export const DATABASES_URL = '/v1/data/databases'

export const fetchMenuLayout = async (menuName: string): Promise<IMenuLayout> => {
  const response = await CLIENT.get<IMenuLayout>(`${MENU_LAYOUT_URL}/${menuName}`)
  return response.data
}

export const saveMenuLayout = async (
  menuName: string,
  menu: Partial<IMenuLayout>
): Promise<IMenuLayout> => {
  const response = await CLIENT.patch<Partial<IMenuLayout>, AxiosResponse<IMenuLayout>>(
    `${MENU_LAYOUT_URL}/${menuName}`,
    menu
  )
  return response.data
}

export const createMenuLayout = async (
  menu: Partial<IMenuLayout>
): Promise<IMenuLayout> => {
  const response = await CLIENT.post<Partial<IMenuLayout>, AxiosResponse<IMenuLayout>>(
    `${MENU_LAYOUT_URL}`,
    menu
  )
  return response.data
}

export const deleteMenuLayout = async (menuName: string, newName: string) =>
  CLIENT.delete(`${MENU_LAYOUT_URL}/${menuName}?replacement=${newName}`)

export const importMenuPages = async (db: string, file: File): Promise<string[]> => {
  const formData = new FormData()
  formData.append('menu_file', file, file.name)

  const response = await CLIENT.put(
    `${DATABASES_URL}/${db}/menus?create_database=true`,
    formData
  )
  return response.data.pages
}
