import * as React from 'react'
import { bindBem } from 'bem'

import './IconOption.scss'

export interface IProps {
  icon: string
  onClick?: () => void
  tooltip?: string
}

export const IconOption: React.FC<IProps> = ({ icon, onClick, tooltip }) => {
  const { block } = bindBem('IconOption')

  return (
    <div className={block()} onClick={onClick} title={tooltip}>
      <img src={icon} alt="option icon" />
    </div>
  )
}
