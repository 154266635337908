// eslint-disable max-lines

import * as _ from 'lodash'
import { XScale, YScale } from '../hd3'
import { freqToInterval, Freq, getMiddleDate, getDataPoint } from '../series'

export interface IStackedBarDataPoint extends RichDataPoint {
  height?: number
  accumulatedValue: number
}

export interface IStackedConfig {
  yMin: number
  yMax: number
  variables: IDataSeries[]
  xMin?: Date
  xMax?: Date
  sampledVariables: { date: Date; sampledSeries: IDataPoint[] }[]
  richVariables: IStackedBarDataPoint[][]
  axisAssignment: AxisAssignment
  types: ISeriesType[]
}

export const getStackedConfig = (
  variables: IDataSeries[],
  types: ISeriesType[],
  xDomain: Date[]
) => {
  const config: IStackedConfig = {
    yMin: 0,
    yMax: 0,
    sampledVariables: [],
    richVariables: [],
    variables,
    axisAssignment: variables.find(v => v.axisAssignment === 'left') ? 'left' : 'right',
    types,
  }
  if (variables.length === 0) {
    return config
  }
  const frequency = variables[0].frequency
  const interval = freqToInterval(frequency)

  config.xMin = xDomain[0]
  config.xMax = xDomain[1]

  let range = interval.range(config.xMin, config.xMax)
  const startDate = _.min(variables.map(v => v.dataPoints[0]?.date))

  if (startDate > config.xMin) {
    range = range.slice(range.findIndex(v => v > startDate) - 1)
  }
  if (frequency === Freq.Daily) {
    // filter out weekends for daily frequency
    range = range.filter(d => d.getDay() !== 6 && d.getDay() !== 0)
  } else {
    range = range.map(d => getMiddleDate(d, frequency))
  }

  range.forEach(date => {
    const sampledDataPoints: IDataPoint[] = []
    config.variables.forEach(series => {
      const dataPoint = getDataPoint(series.dataPoints, date, frequency)
      sampledDataPoints.push(dataPoint)
    })
    config.sampledVariables.push({ date, sampledSeries: sampledDataPoints })
  })

  const sumPositive = config.sampledVariables.map(item =>
    _.sumBy(item.sampledSeries, dp => (dp?.value > 0 ? dp.value : undefined))
  )
  const sumNegative = config.sampledVariables.map(item =>
    _.sumBy(item.sampledSeries, dp => (dp?.value < 0 ? dp.value : undefined))
  )
  // const sumAll = config.sampledVariables.map(item => _.sumBy(item.sampledSeries , dp => (!!dp ? dp.value : 0)))
  config.yMin = _.min(sumNegative) ?? _.min(sumPositive)
  config.yMax = _.max(sumPositive) ?? _.max(sumNegative)
  return config
}

export const calculateRichPoints = (config: IStackedConfig, x: XScale, y: YScale) => {
  const [min, max] = y.domain()
  const y0 = min > 0 ? y(min) : max < 0 ? y(max) : y(0)

  const richVariables = config.sampledVariables.map(obj => {
    const richDataPoints = obj.sampledSeries.map(dp => ({
      value: dp ? dp.value : 0,
      date: obj.date,
      x: x(obj.date),
      y: dp ? y(dp.value) : y0,
    }))
    return richDataPoints
  })
  const finalRichVariables = config.variables.map((_series, index) =>
    richVariables.map(richDataPoints => richDataPoints[index])
  )

  let prevSummarizedPositiveData: IStackedBarDataPoint[] = []
  let prevSummarizedNegativeData: IStackedBarDataPoint[] = []

  config.variables.forEach((_series, index) => {
    const series = finalRichVariables[index]

    const summarizedPositiveData: IStackedBarDataPoint[] = []
    const summarizedNegativeData: IStackedBarDataPoint[] = []

    const newRichVariables = series.map(dp => {
      const newPoint = {
        ...dp,
        x: Math.floor(dp.x),
        y: 0,
        height: 0,
        accumulatedValue: dp.value,
      }
      if (dp) {
        const prevPositivePoint = _.find(
          prevSummarizedPositiveData,
          item => dp.date.getTime() === item.date.getTime()
        )
        const prevNegativePoint = _.find(
          prevSummarizedNegativeData,
          item => dp.date.getTime() === item.date.getTime()
        )
        if (dp.value >= 0) {
          newPoint.accumulatedValue += prevPositivePoint
            ? prevPositivePoint.accumulatedValue
            : 0
          newPoint.y = y(newPoint.accumulatedValue)
          newPoint.height = Math.abs(
            (prevPositivePoint ? prevPositivePoint.y : y0) - newPoint.y
          )
          summarizedPositiveData.push(newPoint)
          if (prevNegativePoint) {
            summarizedNegativeData.push(prevNegativePoint)
          }
        } else {
          newPoint.accumulatedValue += prevNegativePoint
            ? prevNegativePoint.accumulatedValue
            : 0
          newPoint.y = y(newPoint.accumulatedValue)
          newPoint.height = Math.abs(
            (prevNegativePoint ? prevNegativePoint.y : y0) - newPoint.y
          )
          if (prevPositivePoint) {
            summarizedPositiveData.push(prevPositivePoint)
          }
          summarizedNegativeData.push(newPoint)
        }
      }
      return newPoint
    })
    prevSummarizedPositiveData = [...summarizedPositiveData]
    prevSummarizedNegativeData = [...summarizedNegativeData]

    finalRichVariables[index] = newRichVariables
  })
  return finalRichVariables
}
