import * as React from 'react'

const NoDataComponent = ({
  children,
  loading,
}: {
  children: React.ReactNode
  loading: boolean
}) => {
  return loading ? null : <div className="rt-noData">{children}</div>
}

export default NoDataComponent
