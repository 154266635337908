import React from 'react'

export const useWindowResize = (enabled: boolean, callback: () => void) => {
  const handleResize = React.useCallback(() => {
    callback()
  }, [callback])
  React.useEffect(() => {
    if (enabled) {
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [enabled])
}
