// eslint-disable max-lines

import * as React from 'react'
import { connect } from 'react-redux'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { IRootState } from '../../../store'
import { bindBem } from '../../../bem'
import { getUsersSchema } from '../../../utils'
import { getUserTypesForRole } from './../consts'
import { USER_FORM } from '../../../messages'

import { Labeled, Input } from '../../Input'
import { Button } from '../../Button'
import { BigSwitch } from './../BigSwitch'
import { Options } from '../../Sidebar/Options'
import { UsersBulkForm } from './UsersBulkForm'
import { Role } from './../Role'

import close from '../../../static/close-dialog.svg'

import './CreateUsersForm.scss'

export interface IProps {
  organizationId: number
  isSuperAdmin: boolean
  onSave: (users: IUser[]) => void
  onClose: () => void
  users?: Partial<IUser>[]
  limit?: number
}

export interface IStateProps {
  me: IUser
}

const DEFAULT_SETTINGS = {
  apiAccess: false,
  isActive: true,
  isMigrated: false,
  isRestricted: false,
  restrictedIp: '',
  ratelimit: null,
}

const DEFAULT_USER: Partial<IUser> = {
  id: null,
  organizationId: null,
  username: '',
  email: '',
  settings: DEFAULT_SETTINGS,
  apiToken: '',
  role: 'user',
  dbConfigName: 'us',
}

type IFormUser = Partial<IUser>

type IValidationError = {
  [K in keyof IFormUser]?: string
}

interface IValidationErrors {
  [index: number]: IValidationError
}

export const CreateUsersForm: React.FC<IProps & IStateProps> = props => {
  const usersSchema = getUsersSchema({ usersLimit: props.limit })

  const { onClose, isSuperAdmin } = props
  const { block, element } = bindBem('CreateUsersForm')

  const [users, setUsers] = React.useState<IFormUser[]>(
    props.users || [{ username: '', email: '' }]
  )
  const [errors, setErrors] = React.useState<IValidationErrors[]>()

  const onSave = async (values: IFormUser) => {
    const filteredUsers: IUser[] = notEmptyUsers().map(({ username, email }) => {
      return {
        ...DEFAULT_USER,
        ...values,
        username,
        email,
      } as unknown as IUser
    })

    const validationErrors = await validateUsers(filteredUsers, {
      checkTaken: true,
      organizationId: props.organizationId,
    })

    if (validationErrors) {
      setErrors(validationErrors)
    } else {
      props.onSave(filteredUsers)
      props.onClose()
    }
  }

  const formik = useFormik<IFormUser>({
    initialValues: {
      role: DEFAULT_USER.role,
      organizationId: props.organizationId,
      settings: DEFAULT_SETTINGS,
    },
    onSubmit: onSave,
  })

  const onUsersChange = async (updatedUsers: Partial<IUser>[]) => {
    setUsers(updatedUsers)
    setErrors(await validateUsers(notEmptyUsers(), { checkTaken: false }))
  }

  const validateUsers = async (usersToValidate: Partial<IUser>[], context = {}) => {
    try {
      await usersSchema.validate(usersToValidate, { context })
      return null
    } catch (e) {
      return extractErrors(e, new Array(usersToValidate.length))
    }
  }

  const extractErrors = (
    error: yup.ValidationError,
    validationErrors: IValidationErrors[]
  ) => {
    const matches = error.path.match(/^.*\[(\d)\]\.([a-z]*).*/)

    let index: number = validationErrors.length - 1
    let attr = 'username'

    if (matches) {
      index = parseInt(matches[1], 10)
      attr = matches[2]
    }

    const extractedError: IValidationError = { [attr]: error.message }
    validationErrors[index] = extractedError

    return validationErrors
  }

  const notEmptyUsers = () => users.filter(({ username, email }) => username || email)

  const buttonDisabled = () => {
    return !formik.isValid || notEmptyUsers().length === 0
  }

  return (
    <div className={block()}>
      <img className={element('Close')} src={close} onClick={onClose} />
      <div className={element('Title')}>{USER_FORM.ADD_BULK_TITLE}</div>
      <div className={element('Content')}>
        {isSuperAdmin && (
          <>
            <div className={element('Role')}>
              <Labeled label={USER_FORM.ROLE}>
                <Role
                  value={formik.values.role}
                  options={getUserTypesForRole(props.me.role)}
                  setOption={role => formik.setFieldValue('role', role)}
                />
              </Labeled>
            </div>
            <div className={element('APIStatus')}>
              <Labeled label={USER_FORM.API_STATUS}>
                <BigSwitch
                  value={formik.values.settings.apiAccess}
                  onChange={value => formik.setFieldValue('settings.apiAccess', value)}
                />
              </Labeled>
            </div>
            <div className={element('Access')}>
              <Labeled label={USER_FORM.ACCESS}>
                <Options
                  options={USER_FORM.ACCESS_TYPES}
                  selectedOption={Number(formik.values.settings.isRestricted)}
                  setSeriesOptions={value =>
                    formik.setFieldValue('settings.isRestricted', value)
                  }
                />
              </Labeled>
            </div>
            <Labeled label={USER_FORM.IP} error={formik.errors.settings?.restrictedIp}>
              <Input
                name="settings.restrictedIp"
                disabled={!formik.values.settings.isRestricted}
                onChange={formik.handleChange}
                onBlur={() => formik.setFieldTouched('settings.isRestricted')}
                value={formik.values.settings.restrictedIp}
                error={!!formik.errors.settings?.restrictedIp}
                placeholder={
                  formik.values.settings.isRestricted ? USER_FORM.PLACEHOLDER.IP : ''
                }
              />
            </Labeled>
            <Labeled
              label={USER_FORM.RATELIMIT}
              error={formik.errors.settings?.ratelimit}
            >
              <Input
                name="settings.ratelimit"
                onChange={formik.handleChange}
                value={formik.values.settings.ratelimit}
                error={!!formik.errors.settings?.ratelimit}
                placeholder={USER_FORM.PLACEHOLDER.RATELIMIT}
              />
            </Labeled>
          </>
        )}

        <div className={element('Users')}>
          <UsersBulkForm users={users} onChange={onUsersChange} errors={errors} />
        </div>

        <div className={element('Bottom')}>
          <div className="Info">
            <div>
              Adding: <strong>{notEmptyUsers().length} users</strong>
            </div>
          </div>

          <div>
            <Button
              className={element('Submit')}
              onClick={formik.submitForm}
              disabled={buttonDisabled()}
              text={USER_FORM.ADD}
              size="small"
              style="dark"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: IRootState): IStateProps => {
  const { user: me } = state.user

  return {
    me,
  }
}

export default connect(mapStateToProps)(CreateUsersForm)
