// eslint-disable max-lines

import * as React from 'react'
import { Route, Switch, Redirect, RouteProps, Router } from 'react-router-dom'
import { Dashboard, Main } from './components/Dashboard'
import { Login } from './components/Login'
import { connect } from 'react-redux'
import { IRootState, history } from './store'
import { fetchMe, ACTIONS as UserActions } from './store/User'
import { healthCheck, setupInterceptor } from './store/Maintenance'
import OrgAdmin from './containers/OrgAdmin'
import Organization from './containers/Organization'
import LoginForm from './containers/LoginForm'
import { Toast } from './components/Toast'
import { isAdmin, isSuperAdmin, isAccountAdmin } from './services/user'
import { withResponsive } from './components/Responsive'
import DatabaseAdmin from './containers/DatabaseAdmin'
import PackageAdmin from './containers/PackageAdmin'
import MenusAdmin from 'containers/MenusAdmin/MenusAdmin'
import Loader from './containers/SeriesContainer/Loader'
import AppLoader from './components/AppLoader'
import {
  fetchDatabases,
  fetchDatabasesWithMenuPages,
  fetchPackages,
  fetchOrganizations,
  fetchMenuLayouts,
  fetchCTreeDatabases,
} from './store/Admin'
import Maintenance from 'containers/Maintenance'
import SetPasswordForm from './containers/SetPasswordForm'

interface IProps {
  isMobile: boolean
}

interface IStateProps {
  user?: IUser
  isLoggedIn: boolean
  isLoading: boolean
  isMaintenanceMode: boolean
  toast: IToast
  databases: IDatabase[]
  databasesWithMenuPages: string[]
  cTreeDatabases: string[]
  packages: IDBPackage[]
  menus: IMenuLayout[]
  organizations: IOrganization[]
}

interface IActionProps {
  healthCheck: () => void
  setupInterceptor: () => void
  fetchMe: () => void
  fetchPackages: () => void
  fetchDatabases: () => void
  fetchDatabasesWithMenuPages: () => void
  fetchCTreeDatabases: () => void
  fetchOrganizations: () => void
  fetchMenuLayouts: () => void
  closeToast: () => void
}

const DashboardRoute = (props: RouteProps) => {
  return (
    <Dashboard>
      <Route {...props} />
    </Dashboard>
  )
}

const LoginRoute = (props: RouteProps) => (
  <Login>
    <Route {...props} />
  </Login>
)

export class Routing extends React.Component<IProps & IStateProps & IActionProps> {
  constructor(props: IProps & IStateProps & IActionProps) {
    super(props)

    this.handleDocumentLoad = this.handleDocumentLoad.bind(this)
  }

  handleDocumentLoad() {
    if (this.props.isMobile) {
      window.zE?.(() => window.zE('webWidget', 'hide'))
    }
  }

  componentDidMount() {
    this.props.setupInterceptor()
    this.props.healthCheck()

    if (this.props.isLoading) {
      this.props.fetchMe()
    }
    window.addEventListener('load', this.handleDocumentLoad)
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.handleDocumentLoad)
  }

  componentDidUpdate() {
    window.zE?.(() => window.zE('webWidget', this.props.isMobile ? 'hide' : 'show'))
  }

  render() {
    const { isLoggedIn, isLoading, toast, isMaintenanceMode } = this.props

    return (
      <Router history={history}>
        <Loader loading={isLoading} src={<AppLoader />}>
          <Switch>
            {isLoading && <Route path="/" render={() => <div />} />}
            {!isLoggedIn && <this.LoginRouter />}
            {isLoggedIn && (
              <Route path="/">
                <this.DashboardRouter />
              </Route>
            )}
          </Switch>

          <Toast toast={toast} onClose={this.props.closeToast} />
          {isMaintenanceMode && <Maintenance />}
        </Loader>
      </Router>
    )
  }

  private LoginRouter = () => {
    return (
      <Switch>
        <LoginRoute exact path="/set-password/:id" component={SetPasswordForm} />
        <LoginRoute
          exact
          path="/activate-account/:id"
          render={props => <SetPasswordForm {...props} isCreating />}
        />
        <LoginRoute exact path="/login" component={LoginForm} />
        <Redirect to="/login" />
      </Switch>
    )
  }

  private DashboardRouter = () => {
    return (
      <Switch>
        <Route path="/admin">
          {this.props.isMobile ? <Redirect to="/" /> : <this.AdminRouter />}
        </Route>
        <Route path="/">
          <Main />
        </Route>
      </Switch>
    )
  }

  private AdminResources = ({ children }: { children: JSX.Element }) => {
    const { role } = this.props.user

    React.useEffect(() => {
      if (!this.props.databases.length) {
        this.props.fetchDatabases()
      }
      if (isSuperAdmin(role)) {
        if (!this.props.databasesWithMenuPages.length) {
          this.props.fetchDatabasesWithMenuPages()
        }
        if (!this.props.cTreeDatabases.length) {
          this.props.fetchCTreeDatabases()
        }
      }
      if (!this.props.packages.length) {
        this.props.fetchPackages()
      }
      if (!this.props.organizations.length) {
        this.props.fetchOrganizations()
      }
      if (!this.props.menus.length) {
        this.props.fetchMenuLayouts()
      }
    }, [])

    return children
  }

  private AdminRouter = () => {
    const { role } = this.props.user

    if (!isAdmin(role)) {
      return null
    }
    if (isAccountAdmin(role)) {
      return <this.AccountAdminRouter />
    }
    if (isSuperAdmin(role)) {
      return <this.HaverAdminRouter />
    }
    return <this.OrgAdminRouter />
  }

  private HaverAdminRouter = () => {
    return (
      <this.AdminResources>
        <Switch>
          <DashboardRoute exact path="/admin/organizations" component={OrgAdmin} />
          <DashboardRoute
            exact
            path="/admin/organizations/:id"
            component={Organization}
          />
          <DashboardRoute exact path="/admin/databases" component={DatabaseAdmin} />
          <DashboardRoute exact path="/admin/packages" component={PackageAdmin} />
          <DashboardRoute exact path="/admin/menus" component={MenusAdmin} />
          <Redirect path="/admin" to="/admin/organizations" />
        </Switch>
      </this.AdminResources>
    )
  }

  private AccountAdminRouter = () => {
    return (
      <this.AdminResources>
        <Switch>
          <DashboardRoute exact path="/admin/organizations" component={OrgAdmin} />
          <DashboardRoute
            exact
            path="/admin/organizations/:id"
            component={Organization}
          />
          <Redirect path="/admin" to="/admin/organizations" />
        </Switch>
      </this.AdminResources>
    )
  }

  private OrgAdminRouter = () => {
    return (
      <Switch>
        <DashboardRoute exact path="/admin" component={Organization} />
        <Redirect path="/admin" to="/admin/organizations" />
      </Switch>
    )
  }
}

const mapStateToProps = (state: IRootState): IStateProps => {
  const { packages, databases, databasesWithMenuPages, cTreeDatabases, organizations } =
    state.admin
  const { user, isLoggedIn, toast } = state.user
  const { maintenance } = state.maintenance

  return {
    user,
    isLoggedIn,
    isLoading: isLoggedIn && !user,
    isMaintenanceMode: maintenance,
    toast,
    packages,
    databases,
    databasesWithMenuPages,
    cTreeDatabases,
    organizations,
    menus: [],
  }
}

const mapDispatchToProps = (dispatch: any): IActionProps => ({
  fetchOrganizations: () => dispatch(fetchOrganizations()),
  fetchPackages: () => dispatch(fetchPackages()),
  fetchMenuLayouts: () => dispatch(fetchMenuLayouts()),
  fetchDatabases: () => dispatch(fetchDatabases()),
  fetchDatabasesWithMenuPages: () => dispatch(fetchDatabasesWithMenuPages()),
  fetchCTreeDatabases: () => dispatch(fetchCTreeDatabases()),
  fetchMe: () => dispatch(fetchMe()),
  healthCheck: () => dispatch(healthCheck()),
  setupInterceptor: () => dispatch(setupInterceptor()),
  closeToast: () => dispatch(UserActions.closeToast()),
})

export default connect<IStateProps, IActionProps>(
  mapStateToProps,
  mapDispatchToProps
)(withResponsive(Routing))
