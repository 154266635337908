import * as React from 'react'
import { ITree } from 'containers/MenusAdmin/services/menu.types'

export const useAreGroupsCollapsed = (treeData: ITree): boolean =>
  React.useMemo(
    () =>
      !treeData.some(entry => {
        switch (entry.original.type) {
          case 'group':
            return entry.expanded
          case 'category':
            return entry.children.some(subEntry => {
              switch (subEntry.original.type) {
                case 'group':
                  return subEntry.expanded
                default:
                  return false
              }
            })
        }
      }),
    [treeData]
  )
