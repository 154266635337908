import { minBy, maxBy } from 'lodash'
import * as fns from 'date-fns'
import { bisector } from 'd3'
import { discontinuitySkipWeekends } from 'd3fc-discontinuous-scale'
import { freqToInterval, getEndDate, Freq } from './series'

/**
 * When the value is not a number or when precision is not set, it should return '---'
 * In other cases it should return stringified number:
 *  - when number converted with fixed precision is presented in scientific notation (with exponential)
 *    it should return scientific notation with fewer digits after the decimal point
 *    e.g. 1.23+E21 instead of 1.2345678987654321+E.21
 *  - when number converted with fixed precision is presented in normal notation
 *    it should return it without any further conversion
 *    e.g. 123.56
 */
export const formatValue = (value: number, precision?: number) => {
  if (precision != null && typeof value === 'number') {
    const fixedPrecision = value.toFixed(precision)
    if (fixedPrecision.toLowerCase().includes('e')) {
      return value.toExponential(precision)
    }
    return fixedPrecision
  }
  return '---'
}

export const getDatesExtent = (variables: IDataSeries[]) => {
  if (!variables.length) {
    return [null, null]
  }
  const extents = variables.map(v => {
    if (!v.dataPoints.length) {
      return [null, null]
    }
    return [
      v.dataPoints[0].date,
      getEndDate(v.dataPoints[v.dataPoints.length - 1].date, v.frequency),
    ]
  })
  return [minBy(extents, '0')[0], maxBy(extents, '1')[1]]
}

const DISCONTINUITY_PROVIDER = discontinuitySkipWeekends()

export const getDate = (startDate: Date, offset: number, frequency: Frequency) => {
  if (frequency === Freq.Daily) {
    return DISCONTINUITY_PROVIDER.offset(startDate, offset * 24 * 3600 * 1000)
  }
  return freqToInterval(frequency).offset(startDate, offset)
}

export const getVariableValue = (
  leftDate: Date,
  rightDate: Date,
  points: IDataPoint[],
  frequency: Frequency,
  targetFrequency: Frequency
) => {
  if (!points.length || fns.isBefore(rightDate, getEndDate(points[0].date, frequency))) {
    return null
  }
  const provider = discontinuitySkipWeekends()
  const bisect = bisector<IDataPoint, Date>(
    (a, b) => provider.clampDown(getEndDate(a.date, frequency)).valueOf() - b.valueOf()
  )
  const index = bisect.left(points, leftDate)
  if (index < 0 || index >= points.length) {
    return null
  }
  if (index === bisect.right(points, rightDate) && frequency === Freq.Daily) {
    return null
  }
  const point = points[index]
  if (frequency === targetFrequency) {
    return point.value
  }
  // target frequency is higher that current one. We need to check if it's an end of period
  const currDate = getEndDate(point.date, frequency)
  const isBetween =
    fns.isEqual(currDate, leftDate) ||
    (fns.isAfter(currDate, leftDate) && fns.isBefore(currDate, rightDate))

  if (isBetween) {
    return point.value
  }
}

export const estimateDistance = (
  startDate: Date,
  endDate: Date,
  frequency: Frequency
) => {
  if (startDate === endDate) {
    return 0
  }
  if (frequency === Freq.Daily) {
    const provider = discontinuitySkipWeekends()
    return (
      (provider.distance(startDate, endDate) as unknown as number) / (24 * 3600 * 1000)
    )
  }
  switch (frequency) {
    case Freq.Monthly:
      return fns.differenceInMonths(endDate, startDate)

    case Freq.Quarterly:
      return fns.differenceInQuarters(endDate, startDate)

    case Freq.Annually:
      return fns.differenceInYears(endDate, startDate)

    default:
      const diff = fns.differenceInWeeks(endDate, startDate)
      const remainder = startDate.getDay() < endDate.getDay() ? 1 : 0
      return diff + remainder
  }
}
