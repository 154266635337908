// eslint-disable max-lines

import * as React from 'react'

import { connect } from 'react-redux'
import { IRootState } from '../../store'

import {
  ACTIONS,
  loadRecessionCountriesList,
  setLoadRecessionCountry,
} from '../../store/Series'
import { SIDEBAR } from '../../messages'

import { AccordionSwitchGroup } from './AccordionGroup'
import { Switch } from './Switch'
import SmallRadioButton from '../SmallRadioButton'
import { RadioGroup } from '../RadioGroup'

import { bindBem } from '../../bem'
import { Modal, ActionsBar } from '../Modal'
import { OptionsListDialog } from './OptionsListDialog'
import { Button } from '../Button'
import { globalDataLabels, globalDataMarkers } from '../../services/series'

import './GraphSettingsDialog.scss'

export interface IProps {
  defaultRecessionCountry: string
  recessionType: IRecessionType
  recessionCountry?: string
  recessionCountryList: string[]
  grid: IGridSettings
  dataMarkers: boolean
  dataLabelsType: DataLabelsType
  lineShadow: boolean
  yAxisLabels: boolean
  tickmarksType: TickmarksType
  yAxisType: YAxisType
  splineLine: boolean
  dataLabels: boolean
}

export interface IState {
  isRecessionOptionsOpen: boolean
  isCountryModalOpen: boolean
}

export interface IActionProps {
  setRecessionType: (type: IRecessionType) => void
  setRecessionCountry: (country: string) => void
  loadRecessionCountriesList: () => void
  setGraphGrid: (grid: IGridSettings) => void
  toggleDataMarkers: () => void
  toggleLineShadow: () => void
  toggleYAxisLabels: () => void
  toggleSplineLine: () => void
  resetGraphSettings: () => void
  setTickMarksType: (type: TickmarksType) => void
  setYAxisType: (type: YAxisType) => void
  setDataLabelsType: (type: DataLabelsType) => void
  toggleDataLabels: () => void
}

const Separator: React.FC = () => (
  <div className={bindBem('GraphSettingsDialog').element('Separator')} />
)

export class GraphSettingsDialog extends React.Component<IProps & IActionProps, IState> {
  state: IState = { isRecessionOptionsOpen: true, isCountryModalOpen: false }
  componentDidMount() {
    this.props.loadRecessionCountriesList()
  }
  render() {
    const { block, element } = bindBem('GraphSettingsDialog')
    const {
      recessionType,
      setRecessionType,
      recessionCountry,
      setRecessionCountry,
      recessionCountryList,
      defaultRecessionCountry,
    } = this.props
    const {
      grid,
      setGraphGrid,
      dataMarkers,
      toggleDataMarkers,
      lineShadow,
      toggleLineShadow,
    } = this.props
    const {
      yAxisLabels,
      toggleYAxisLabels,
      resetGraphSettings,
      tickmarksType,
      yAxisType,
    } = this.props
    const {
      splineLine,
      toggleSplineLine,
      dataLabelsType,
      setDataLabelsType,
      dataLabels,
    } = this.props
    const { isCountryModalOpen } = this.state
    const isRecessionSet = !!recessionCountry

    return (
      <div className={block()}>
        <ActionsBar>
          <Button
            onClick={resetGraphSettings}
            text={SIDEBAR.GRAPH_OPTIONS.RESET_ALL}
            size="small"
            style="light"
          />
        </ActionsBar>
        <div className={element('Scrollable')}>
          <div className={element('Section')}>{SIDEBAR.GRAPH_OPTIONS.GENERAL}</div>
          <Switch
            title={SIDEBAR.GRAPH_OPTIONS.SPLINE_LINE}
            value={splineLine}
            onChange={toggleSplineLine}
          />
          <Separator />
          <Switch
            title={SIDEBAR.GRAPH_OPTIONS.LINE_SHADOW}
            value={lineShadow}
            onChange={toggleLineShadow}
          />
          <Separator />
          <Switch
            title={SIDEBAR.GRAPH_OPTIONS.DATA_MARKERS}
            value={dataMarkers}
            onChange={() => toggleDataMarkers()}
          />
          <Separator />

          <AccordionSwitchGroup
            title={SIDEBAR.GRAPH_OPTIONS.DATA_LABELS}
            isOpen={dataLabels}
            toggleOpen={this.toggleDataLabels}
          >
            <RadioGroup onChange={setDataLabelsType} value={dataLabelsType}>
              <SmallRadioButton value="ALL">
                {SIDEBAR.GRAPH_OPTIONS.ALL_DATA_LABELS}
              </SmallRadioButton>
              <SmallRadioButton value="LAST">
                {SIDEBAR.GRAPH_OPTIONS.LAST_DATA_LABELS}
              </SmallRadioButton>
            </RadioGroup>
          </AccordionSwitchGroup>

          <Separator />
          <AccordionSwitchGroup
            title={SIDEBAR.GRAPH_OPTIONS.RECESSION}
            isOpen={isRecessionSet}
            toggleOpen={() =>
              setRecessionCountry(isRecessionSet ? null : defaultRecessionCountry)
            }
          >
            <RadioGroup onChange={setRecessionType} value={recessionType}>
              <SmallRadioButton value={'SHADING'}>
                {SIDEBAR.GRAPH_OPTIONS.SHADING}
              </SmallRadioButton>
              <SmallRadioButton value={'LINES'}>
                {SIDEBAR.GRAPH_OPTIONS.LINES}
              </SmallRadioButton>
            </RadioGroup>
            <div className={element('Option')} onClick={() => this.onCountryDialogOpen()}>
              <span>{`${SIDEBAR.GRAPH_OPTIONS.COUNTRY} ...`}</span>
              <span>{recessionCountry}</span>
            </div>
          </AccordionSwitchGroup>
          <Modal
            isOpen={isCountryModalOpen}
            onClose={() => this.onCountryDialogClose(recessionCountry)}
          >
            <OptionsListDialog
              title={SIDEBAR.GRAPH_OPTIONS.COUNTRY_DIALOG.TITLE}
              currentValue={recessionCountry}
              values={recessionCountryList}
              onClose={country => this.onCountryDialogClose(country)}
            />
          </Modal>
          <Separator />
          <Switch
            title={SIDEBAR.GRAPH_OPTIONS.Y_AXIS_TITLES}
            value={yAxisLabels}
            onChange={toggleYAxisLabels}
          />
          <Separator />
          <Switch
            title={SIDEBAR.GRAPH_OPTIONS.Y_AXIS_LOG_SCALE}
            value={yAxisType === 'log'}
            onChange={this.toggleYAxisType}
          />

          <div className={element('Section')}>{SIDEBAR.GRAPH_OPTIONS.TICKS}</div>

          <AccordionSwitchGroup
            title={SIDEBAR.GRAPH_OPTIONS.X_AXIS_TICKS}
            isOpen={tickmarksType !== 'NONE'}
            toggleOpen={this.toggleTickMarks}
          >
            <RadioGroup onChange={this.props.setTickMarksType} value={tickmarksType}>
              <SmallRadioButton value="ALL">
                {SIDEBAR.GRAPH_OPTIONS.ALL_TICKS_MARKS}
              </SmallRadioButton>
              <SmallRadioButton value="MAJOR">
                {SIDEBAR.GRAPH_OPTIONS.MAJOR_TICKS_MARKS}
              </SmallRadioButton>
            </RadioGroup>
          </AccordionSwitchGroup>

          <div className={element('Section')}>{SIDEBAR.GRAPH_OPTIONS.GRIDS}</div>

          <Switch
            title={SIDEBAR.GRAPH_OPTIONS.HORIZONTAL_GRID}
            value={grid.horizontal}
            onChange={() => setGraphGrid({ ...grid, horizontal: !grid.horizontal })}
          />
          <Separator />
          <Switch
            title={SIDEBAR.GRAPH_OPTIONS.VERTICAL_GRID}
            value={grid.vertical}
            onChange={() => setGraphGrid({ ...grid, vertical: !grid.vertical })}
          />
        </div>
      </div>
    )
  }

  onCountryDialogOpen = () => this.setState(() => ({ isCountryModalOpen: true }))
  onCountryDialogClose = (country: string) =>
    this.setState(
      () => ({ isCountryModalOpen: false }),
      () =>
        this.props.recessionCountry !== country && this.props.setRecessionCountry(country)
    )
  resetAll = () => {
    this.props.setRecessionCountry(null)
  }
  toggleTickMarks = (toggledOn: boolean) => {
    const type: TickmarksType = toggledOn ? 'ALL' : 'NONE'
    this.props.setTickMarksType(type)
  }

  toggleDataLabels = () => {
    this.props.toggleDataLabels()
  }

  toggleYAxisType = () =>
    this.props.setYAxisType(this.props.yAxisType === 'log' ? 'linear' : 'log')
}

const mapStateToProps = (state: IRootState): IProps => {
  const { recessionCountryList, seriesSettings, defaultRecessionCountry } = state.series
  const {
    recessionType,
    recessionCountry,
    grid,
    lineShadow,
    yAxisLabels,
    tickmarksType,
    yAxisType,
    splineLine,
    dataLabelsType,
  } = seriesSettings

  const dataMarkers = globalDataMarkers(
    state.series.variables,
    state.series.seriesSettings.graphTypes
  )
  const dataLabels = globalDataLabels(
    state.series.variables,
    state.series.seriesSettings.graphTypes
  )

  return {
    defaultRecessionCountry,
    recessionType,
    recessionCountry,
    recessionCountryList,
    grid,
    dataMarkers,
    lineShadow,
    yAxisLabels,
    tickmarksType,
    yAxisType,
    splineLine,
    dataLabelsType,
    dataLabels,
  }
}

const mapDispatchToProps: IActionProps = {
  setRecessionType: ACTIONS.setRecessionType,
  setRecessionCountry: setLoadRecessionCountry,
  loadRecessionCountriesList,
  setGraphGrid: ACTIONS.setGraphGrid,
  toggleDataMarkers: ACTIONS.toggleDataMarkers,
  toggleLineShadow: ACTIONS.toggleLineShadow,
  toggleSplineLine: ACTIONS.toggleSplineLine,
  toggleYAxisLabels: ACTIONS.toggleYAxisLabels,
  resetGraphSettings: ACTIONS.resetGraphSettings,
  setTickMarksType: ACTIONS.setTickMarksType,
  setYAxisType: ACTIONS.setYAxisType,
  setDataLabelsType: ACTIONS.setDataLabelsType,
  toggleDataLabels: ACTIONS.toggleDataLabels,
}

export default connect(mapStateToProps, mapDispatchToProps)(GraphSettingsDialog)
