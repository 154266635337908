import * as React from 'react'
import { connect } from 'react-redux'

import { bindBem } from 'bem'
import { healthCheck } from 'store/Maintenance'
import { MAINTENANCE } from 'messages'

import maintenance from '../static/maintenance.svg'

import './Maintenance.scss'

export interface IActionProps {
  healthCheck: () => void
}

const HELTHCHECK_INTERVAL = 30 * 1000 // 30s

export const Maintenance = (props: IActionProps) => {
  const { block, element } = bindBem('Maintenance')

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      props.healthCheck()
    }, HELTHCHECK_INTERVAL)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return (
    <div className={block()}>
      <div className={element('Wrapper')}>
        <img className={element('Icon')} src={maintenance} alt="maintenance icon" />
        <h1 className={element('Title')}>{MAINTENANCE.TITLE}</h1>
        <span className={element('Content')}>{MAINTENANCE.CONTENT}</span>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: any): IActionProps => {
  return {
    healthCheck: () => dispatch(healthCheck()),
  }
}

export default connect(null, mapDispatchToProps)(Maintenance)
