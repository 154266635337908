import { createAction, ActionType } from 'typesafe-actions'

export const ACTIONS = {
  setSeries: createAction(
    'SET_SERIES',
    resolve => (series: IDataSeries, index?: number) => resolve({ series, index })
  ),
  setSeriesType: createAction(
    'SET_SERIES_TYPE',
    resolve => (graphType: ISeriesType, index?: number) => resolve({ graphType, index })
  ),
  setSeriesAxisAssignment: createAction(
    'SET_SERIES_AXIS_ASSIGNMENT',
    resolve => (index: number, assignment: AxisAssignment) =>
      resolve({ index, assignment })
  ),
  setRecessionType: createAction(
    'SET_RECESSION_TYPE',
    resolve => (type: IRecessionType) => resolve(type)
  ),
  setTrendlineBoundary: createAction(
    'SET_TRENDLINE_BOUNDARY',
    resolve => (uuid: string, boundary: Date[]) => resolve({ uuid, boundary })
  ),
  deleteTrendlineBoundary: createAction(
    'DELETE_TRENDLINE_BOUNDARY',
    resolve => (uuid: string) => resolve({ uuid })
  ),
  cancelScatterPlot: createAction('CANCEL_SCATTER_PLOT'),
  setRecessionCountry: createAction(
    'SET_RECESSION_COUNTRY',
    resolve => (country: string) => resolve(country)
  ),
  setRecessions: createAction(
    'SET_RECESSIONS',
    resolve => (recessions: SMap<IRecession[]>) => resolve(recessions)
  ),
  setRecessionCountryList: createAction(
    'SET_RECESSION_COUNTRIES_LIST',
    resolve => (countries: string[]) => resolve(countries)
  ),
  setSeriesTitles: createAction(
    'SET_SERIES_TITLES',
    resolve => (settings: Partial<ISeriesSettings>) => resolve(settings)
  ),
  setGraphZoom: createAction('SET_GRAPH_ZOOM', resolve => (zoom: Zoom) => resolve(zoom)),
  setGraphGrid: createAction(
    'SET_GRAPH_GRID',
    resolve => (grid: IGridSettings) => resolve(grid)
  ),
  toggleDataMarkers: createAction(
    'TOGGLE_DATA_MARKERS',
    resolve => (index?: number) => resolve({ index })
  ),
  toggleDataLabels: createAction(
    'TOGGLE_DATA_LABELS',
    resolve => (index?: number) => resolve({ index })
  ),
  toggleLineShadow: createAction('TOGGLE_LINE_SHADOW'),
  toggleYAxisLabels: createAction('TOGGLE_Y_AXIS_LABELS'),
  toggleSplineLine: createAction('TOGGLE_SPLINE_LINE'),
  resetGraphSettings: createAction('RESET_GRAPH_SETTINGS'),
  setLegend: createAction(
    'SET_LEGEND',
    resolve => (isLegendShown: boolean) => resolve(isLegendShown)
  ),
  addVariable: createAction(
    'ADD_VARIABLE',
    resolve => (series: IDataSeries, index: number) => resolve({ series, index })
  ),
  deleteVariable: createAction(
    'DELETE_VARIABLE',
    resolve => (index: number) => resolve(index)
  ),
  moveVariable: createAction(
    'MOVE_VARIABLE',
    resolve => (index: number, targetIndex: number) => resolve({ index, targetIndex })
  ),
  setLoadingSeriesId: createAction(
    'SET_LOADING_SERIES_ID',
    resolve => (seriesId: string) => resolve(seriesId)
  ),
  resetGraph: createAction('RESET_GRAPH'),
  setActiveModal: createAction(
    'SET_ACTIVE_MODAL',
    resolve => (m: SeriesModal) => resolve(m)
  ),
  nextPage: createAction(
    'NEXT_PAGE',
    resolve =>
      <T>(key: 'foundSeries', data: IPagination<T>) =>
        resolve({ key, data })
  ),
  removeTransformation: createAction(
    'REMOVE_TRANSFORMATION',
    resolve => (index: number) => resolve(index)
  ),
  toggleCorrelation: createAction(
    'TOGGLE_CORRELATION',
    resolve => (enabled: boolean) => resolve({ enabled })
  ),
  setCorrelationValue: createAction(
    'SET_CORRELATION_VALUE',
    resolve => (value: number) => resolve({ value })
  ),
  setTickMarksType: createAction(
    'SET_TICKMARKS_TYPE',
    resolve => (type: TickmarksType) => resolve(type)
  ),
  setDataLabelsType: createAction(
    'SET_DATA_LABELS_TYPE',
    resolve => (type: DataLabelsType) => resolve(type)
  ),
  changeOffset: createAction(
    'CHANGE_OFFSET',
    resolve => (value?: number) => resolve({ value })
  ),
  scrollChart: createAction(
    'SCROLL_CHART',
    resolve => (value?: number) => resolve({ value })
  ),
  scrollByPeriod: createAction(
    'SCROLL_BY_PERIOD',
    resolve => (value?: number) => resolve({ value })
  ),
  setYAxisType: createAction('SET_Y_AXIS', resolve => (type: YAxisType) => resolve(type)),
  setRegressionType: createAction(
    'SET_REGRESSION_TYPE',
    resolve => (type: RegressionType) => resolve(type)
  ),
  setScatterTimeline: createAction(
    'SET_SCATTER_TIMELINE',
    resolve => (isEnabled: boolean) => resolve(isEnabled)
  ),
  setScatterAxisType: createAction(
    'SET_SCATTER_AXIS',
    resolve => (type: ScatterAxisType) => resolve(type)
  ),
  setScale: createAction('SET_SCALE', resolve => (scale: IScale) => resolve(scale)),
  setLink: createAction(
    'SET_LINK',
    resolve => (shareLinkResponse: IShareLinkResponse) => resolve(shareLinkResponse)
  ),
  setSeriesSettings: createAction(
    'SET_SERIES_SETTINGS',
    resolve => (settings: ISeriesSettings) => resolve(settings)
  ),
  loadSeriesDump: createAction(
    'LOAD_SERIES_DUMP',
    resolve => (dump: ISeriesDump) => resolve(dump)
  ),
  setInitialScallingOptions: createAction(
    'SET_INITIAL_SCALLING_OPTIONS',
    resolve => (scale: IScale, assignments: AxisAssignment[]) =>
      resolve({ scale, assignments })
  ),
}

export type SeriesAction = ActionType<(typeof ACTIONS)[keyof typeof ACTIONS]>
