import * as React from 'react'
import enhanceWithClickOutside from 'react-click-outside'

import { bindBem } from '../../bem'
import { SIDEBAR } from '../../messages'
import { Button } from '../Button'
import { ICON_HIERARCHY_DARK } from '../SVG/Hierarchy'

import sidebarHierarchy from '../../static/sidebar-hierarchy-entry.svg'

import './HierarchyPopover.scss'

export interface IProps {
  expanded: boolean
  entries: IDirectoryEntry[]
  onNavigateBack: (levels: number) => void
  onClose: () => void
}

export class BaseHierarchyPopover extends React.Component<IProps> {
  handleClickOutside = () => this.props.onClose()

  render() {
    const { entries } = this.props
    if (!entries.length) {
      return null
    }
    const length = entries.length - 1
    const { block, element } = bindBem('HierarchyPopover')
    const { onNavigateBack } = this.props
    return (
      <div className={block({ expanded: this.props.expanded })}>
        <div className={element('Title')}>HIERARCHY</div>
        <div className={element('Entry')} onClick={() => onNavigateBack(length + 1)}>
          {SIDEBAR.DATA_DIRECTORY}
        </div>

        {entries.map((entry, i) => (
          <HierarchyPopoverEntry
            key={entry.id}
            marginLeft={i * 12}
            label={entry.description}
            onClick={() => length - i > 0 && onNavigateBack(length - i)}
          />
        ))}

        <Button
          icon={ICON_HIERARCHY_DARK}
          className="Hierarchy"
          size="small"
          style="light"
          onClick={this.props.onClose}
        />
      </div>
    )
  }
}

export const HierarchyPopover = enhanceWithClickOutside(BaseHierarchyPopover)

export const HierarchyPopoverEntry = (props: {
  label: string
  onClick: () => void
  marginLeft: number
}) => (
  <div
    className="HierarchyPopover__Entry"
    style={{ marginLeft: props.marginLeft }}
    onClick={props.onClick}
  >
    <img src={sidebarHierarchy} alt="" />
    <div>{props.label}</div>
  </div>
)
