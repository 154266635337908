// eslint-disable max-lines
import * as _ from 'lodash'
import { createSelector } from 'reselect'

import { getValuesExtent } from '../../../services/hd3-utils/scatter-plot'
import { IStackedConfig } from '../../../services/hd3-utils/stacked-bar'
import { getSumExent } from '../../../services/series'
import {
  calculateAxis,
  generateIncrementalTicks,
  generateLogTicks,
  generateTicks,
} from '../../../utils'
import { getScale, getSettings } from './common'
import * as series from './series'

const firstOrPredicate = (
  a: number,
  b: number,
  predicate: (a: number, b: number) => boolean
) => {
  const aPresent = typeof a === 'number'
  const bPresent = typeof b === 'number'
  if (aPresent && bPresent) {
    return predicate(a, b) ? a : b
  }
  return aPresent ? a : b
}

type Predicate = (a: number, b: number) => number

const firstOrLess: Predicate = (a, b) => firstOrPredicate(a, b, (i, j) => i < j)
const firstOrGreater: Predicate = (a, b) => firstOrPredicate(a, b, (i, j) => i > j)
const first: Predicate = (a, b) => firstOrPredicate(a, b, () => true)

const isScaleUniform = (type: IScale['type']) => ['uniscale', 'both'].includes(type)

export const logScaleSelector = createSelector(
  getSettings,
  settings => settings.yAxisType === 'log'
)

function getVariablesForAxis(
  variables: IDataSeries[],
  scaleType: IScale['type'],
  axisAssignment: AxisAssignment,
  config: IStackedConfig
) {
  if (isScaleUniform(scaleType)) {
    return variables
  }

  let vars = variables.filter(v => v.axisAssignment === axisAssignment)
  if (config?.axisAssignment === axisAssignment) {
    vars = [...vars, ...config.variables.filter(v => v.axisAssignment !== axisAssignment)]
  }
  return vars
}

export const calculateScale = (
  variables: IDataSeries[],
  isScatter: boolean,
  config: IStackedConfig,
  scale: IScale,
  isLogScale: boolean
): IScale<IncrementalAxis> => {
  if (isScatter) {
    return createScatterScale(variables)
  }

  let leftDomain = getDomain(variables, scale, config, 'left')
  let rightDomain = getDomain(variables, scale, config, 'right')

  leftDomain = getFinalDomain(scale.type, leftDomain, rightDomain)
  rightDomain = getFinalDomain(scale.type, rightDomain, leftDomain)

  let leftAxis = createAxis(leftDomain, scale.leftAxis)
  let rightAxis = createAxis(
    rightDomain,
    isScaleUniform(scale.type) ? scale.leftAxis : scale.rightAxis
  )

  if (isLogScale && isLogScaleAllowed(variables, 'left', scale.type)) {
    leftAxis = transformToLogScale(leftDomain, leftAxis)
  }
  if (isScaleUniform(scale.type)) {
    return {
      type: 'neither',
      leftAxis,
      rightAxis: leftAxis,
    }
  }

  if (isLogScale && isLogScaleAllowed(variables, 'right', scale.type)) {
    rightAxis = transformToLogScale(rightDomain, rightAxis)
  }
  return {
    type: 'neither',
    leftAxis,
    rightAxis,
  }
}

function getFinalDomain(
  type: IScale['type'],
  domain: number[],
  secondaryDomain: number[]
) {
  // if scle is uniform, domains must match. If scale is empty,take a value from second domain
  let lower: Predicate
  let upper: Predicate
  if (isScaleUniform(type)) {
    lower = firstOrLess
    upper = firstOrGreater
  } else {
    lower = first
    upper = first
  }
  return [lower(domain[0], secondaryDomain[0]), upper(domain[1], secondaryDomain[1])]
}

function getDomain(
  variables: IDataSeries[],
  scale: IScale,
  config: IStackedConfig,
  axisAssignment: AxisAssignment
) {
  const axis =
    axisAssignment === 'left' || isScaleUniform(scale.type)
      ? scale.leftAxis
      : scale.rightAxis
  if (axis.type === 'minmax') {
    return [axis.min, axis.max]
  }
  const vars = getVariablesForAxis(variables, scale.type, axisAssignment, config)
  const domain: number[] =
    vars.length === 0 ? [null, null] : (getSumExent(vars, dp => dp.value) as number[])

  if (config === null) {
    return domain
  }
  return [firstOrLess(domain[0], config.yMin), firstOrGreater(domain[1], config.yMax)]
}

const isLogScaleAllowed = (
  variables: IDataSeries[],
  axis: AxisAssignment,
  type: IScale['type']
) => {
  let assignedVariables = variables.filter(
    (s: IDataSeries) => isScaleUniform(type) || s.axisAssignment === axis
  )
  const canLogBeAppliedTo = (s: IDataSeries) =>
    !s.differenceType && s.dataPoints.every(dp => dp.value > 0)
  if (assignedVariables.length === 0) {
    // there's no series for this axis. Check globally.
    assignedVariables = variables
  }
  return assignedVariables.every(s => canLogBeAppliedTo(s))
}

const transformToLogScale = (
  domain: number[],
  axis: IncrementalAxis
): IncrementalAxis => {
  const log = generateLogTicks(
    domain,
    [_.first(axis.values), _.last(axis.values)],
    axis.values.length - 1
  )
  const values = log.ticks.slice(0, log.count + 1).reverse()

  return {
    type: 'incremental',
    ticksCount: log.count,
    values,
    isLog: true,
  }
}

const createScatterScale = (variables: IDataSeries[]): IScale<IncrementalAxis> => {
  const [min, max] = getValuesExtent(variables[1])
  const axis = calculateAxis(min, max)
  const ticks = generateTicks([axis.min, axis.max], axis.ticks)
  const resultAxis: IncrementalAxis = {
    type: 'incremental',
    ticksCount: ticks.length,
    values: ticks,
  }
  return {
    type: 'uniscale',
    leftAxis: resultAxis,
    rightAxis: resultAxis,
  }
}

const createAxis = (domain: number[], axis: Axis): IncrementalAxis => {
  const ticksCount = axis.ticksCount || 7
  if (axis.type === 'incremental') {
    return {
      ...axis,
      values: generateIncrementalTicks(axis.values, ticksCount),
    }
  } else if (axis.type === 'minmax') {
    return {
      ...axis,
      type: 'incremental',
      values: generateTicks([axis.min, axis.max], ticksCount),
    }
  }
  return createAutoAxis(domain, axis)
}

export const createAutoAxis = (
  domain: number[],
  axis: AutomaticAxis
): IncrementalAxis => {
  if (axis.invert) {
    domain = domain.reverse()
  }
  const calculatedAxis = calculateAxis(domain[0], domain[1], axis.ticksCount)
  if (axis.ticksCount === null) {
    calculatedAxis.ticks += 1
  }

  return {
    ...axis,
    type: 'incremental',
    values: generateTicks([calculatedAxis.min, calculatedAxis.max], calculatedAxis.ticks),
  }
}

export const globalScaleSelector = createSelector(
  series.offsetSeriesSelector,
  series.isScatterSelector,
  series.stackedConfigSelector,
  getScale,
  logScaleSelector,
  calculateScale
)

export const localScaleSelector = createSelector(
  series.zoomedSeriesSelector,
  series.isScatterSelector,
  series.stackedConfigSelector,
  getScale,
  logScaleSelector,
  calculateScale
)
