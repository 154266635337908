import * as React from 'react'

import './Button.scss'

export interface IProps {
  text?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  icon?: JSX.Element
  disabled?: boolean
  size?: 'default' | 'small' | 'block' | 'medium'
  style?: 'default' | 'light' | 'dark' | 'pale' | 'text' | 'primary' | 'danger'
  className?: string
  type?: 'button' | 'submit'
}

export const Button = (props: IProps) => {
  const {
    icon,
    text,
    onClick,
    size = 'default',
    style = 'default',
    className = '',
    disabled = false,
    type,
  } = props
  return (
    <button
      type={type || 'button'}
      className={`Button Button--theme${style} Button--${size} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      {text && <span>{text}</span>}
    </button>
  )
}
