import * as React from 'react'
import { bindBem } from '../../../bem'
import { ADMIN } from '../../../messages'
import { Button } from '../../Button'

import close from '../../../static/close-dialog.svg'

import './DeactivateTokensForm.scss'

export interface IProps {
  tokens: IToken[]
  onClose: () => void
  onDeactivate: (tokens: IToken[]) => void
}

export const DeactivateTokensForm: React.FC<IProps> = props => {
  const { tokens, onClose, onDeactivate } = props

  const { block, element } = bindBem('DeactivateTokensForm')

  const onDeactivateClick = React.useCallback(() => {
    onDeactivate(tokens)
    onClose()
  }, [tokens, onClose, onDeactivate])

  return (
    <div className={block()}>
      <img className={element('Close')} src={close} onClick={onClose} />
      <div className={element('Title')}>{ADMIN.TOKENS.MODALS.DEACTIVATE.TITLE}</div>
      <div className={element('Content')}>
        <p>{ADMIN.TOKENS.MODALS.DEACTIVATE.INFO}</p>
        <strong>{ADMIN.TOKENS.MODALS.DEACTIVATE.SELECTED}</strong>
        <ul className={element('TokensList')}>
          {tokens.map(token => (
            <li key={token.id}>{token.token}</li>
          ))}
        </ul>
      </div>
      <div className={element('Bottom')}>
        <Button
          className={element('Deactivate')}
          onClick={onDeactivateClick}
          text={ADMIN.TOKENS.MODALS.DEACTIVATE.BUTTON}
          size="small"
          style="danger"
        />
      </div>
    </div>
  )
}
