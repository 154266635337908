import { parseISO } from 'date-fns'

import { CLIENT } from '../api/config'
import { toDataSeries, isSeries, IServerSeries } from '../services/series'
import { toUTC } from '../utils'
import Axios, { AxiosRequestConfig } from 'axios'

export const MATH_URL = 'v2/data/math'
const RECESSION_URL = 'v2/data/recessions'
export const LINK_SHARE_URL = 'v2/share/series'

let lastToken = Axios.CancelToken.source()
const resetToken = () => {
  lastToken.cancel()
  lastToken = Axios.CancelToken.source()
  return lastToken.token
}
export const getSeries = async (
  databaseId: string,
  seriesId: string,
  cancellable: boolean
): Promise<IDataSeries> => {
  const config = cancellable ? { cancelToken: resetToken() } : {}

  const response = await CLIENT.get<IServerSeries>(
    `v2/data/databases/${databaseId}/series/${seriesId}`,
    config
  )
  return toDataSeries(response.data)
}
export const getExportUrl = (
  databaseId: string,
  seriesId: string,
  apiToken: string,
  format = 'json'
) =>
  `${CLIENT.defaults.baseURL}v2/data/databases/${databaseId}/series/${seriesId}?token=${apiToken}&format=${format}`

export const getExportAsFileUrl = (
  variables: IDataSeries[],
  apiToken: string,
  fields: string,
  format: string
) => {
  const ids = variables.map(v => `${v.id}@${v.databaseId}`).join(',')

  return `${CLIENT.defaults.baseURL}v2/data/databases/series/export?names=${ids}&fields=${fields}&token=${apiToken}&format=${format}`
}

export const buildMathPayload = (
  payload: TransformationOrSeries,
  aggregation?: Aggregation,
  interpolation = false,
  trendlineStart?: Date,
  trendlineEnd?: Date
): IMathPayload => ({
  payload,
  aggregation,
  interpolation,
  trendlineStart: toUTC(trendlineStart),
  trendlineEnd: toUTC(trendlineEnd),
})

export const getMaths = async (
  payload: TransformationOrSeries,
  aggregation?: Aggregation,
  interpolation = false,
  cancellable = true,
  trendlineStart?: Date,
  trendlineEnd?: Date
): Promise<IDataSeries> => {
  const config = cancellable ? { cancelToken: resetToken() } : {}

  const data = buildMathPayload(
    payload,
    aggregation,
    interpolation,
    trendlineStart,
    trendlineEnd
  )
  const response = await CLIENT.post(MATH_URL, [data], config)
  return toDataSeries(
    response.data[0],
    isSeries(payload) ? null : payload,
    interpolation,
    aggregation
  )
}

export const getCorrelation = async (
  s1: TransformationOrSeries,
  s2: TransformationOrSeries,
  start: Date,
  end: Date
): Promise<number> => {
  const data = { s1, s2, start, end }
  const response = await CLIENT.post('v2/data/corr', data)
  return response.data.correlation
}

type RawRecession = {
  id: string
  country: string
  index: number
  order: number
  startDate: string
  endDate: string | null
}

export const getRecessions = async (country: string): Promise<IRecession[]> => {
  const recessions = await fetchRecursively<RawRecession>(RECESSION_URL, {
    params: { country, per_page: 100 },
  })
  return recessions.map(r => ({
    ...r,
    startDate: parseISO(r.startDate),
    endDate: r.endDate ? parseISO(r.endDate) : null,
  }))
}

export const fetchRecursively = async <T>(
  url: string,
  options: AxiosRequestConfig
): Promise<T[]> => {
  const data = (await CLIENT.get<IPagination<T>>(url, { ...options })).data
  const result = data.data
  if (data.next) {
    const nextChunk = await fetchRecursively<T>(data.next, options)
    return [...result, ...nextChunk]
  }
  return result
}

export const createLinkShareData = async (): Promise<IShareLinkResponse> => {
  const response = await CLIENT.post(LINK_SHARE_URL)
  return response.data
}

export const updateLinkShareData = async (
  linkId: string
): Promise<IShareLinkResponse> => {
  const url = `${LINK_SHARE_URL}/${linkId}`
  const response = await CLIENT.patch(url)
  return response.data
}

export const getSharedLinkData = async (linkId: string): Promise<IShareLinkResponse> => {
  const url = `${LINK_SHARE_URL}/${linkId}`
  const response = await CLIENT.get(url)
  return response.data
}

export const uploadImage = async (
  blob: Blob,
  config: IImageUploadPayload,
  fileName: string
) => {
  const data = new FormData()
  data.append('key', config.fields.key)
  data.append('AWSAccessKeyId', config.fields.AWSAccessKeyId)
  data.append('policy', config.fields.policy)
  data.append('signature', config.fields.signature)
  data.append('file', blob, fileName)
  data.append('Content-Encoding', 'gzip')
  const headers = { 'Content-Type': 'multipart/form-data' }
  await Axios.post(config.url, data, { headers })
}
