import * as React from 'react'

import { SIDEBAR } from '../../messages'
import { Button } from '../Button'
import { InputCopy } from '../InputCopy'
import { bindBem } from '../../bem'
import { generateSharedLink, updateSharedLink, ACTIONS } from '../../store/Series'
import { connect } from 'react-redux'
import { IRootState } from '../../store'

import './LinkShare.scss'

const { SHARE_LINK } = SIDEBAR.EXPORT_TAB

interface IActionProps {
  generateSharedLink: () => void
  updateSharedLink: () => void
  setLink: (shareLinkResponse: IShareLinkResponse) => null
}

interface IStateProps {
  link?: ILink
  userId: number
}

export type IProps = IStateProps & IActionProps

export class LinkShare extends React.Component<IProps> {
  getLink = () => `${document.location.origin}/series/${this.props.link.id}`

  GenerateLinkButton = () => (
    <div className="row">
      <Button
        text={SHARE_LINK.GENERATE}
        onClick={this.props.generateSharedLink}
        size="small"
        style="dark"
      />
    </div>
  )

  ExistingLink = () => {
    const { block, element } = bindBem('LinkShare')
    return (
      <div className={block()}>
        <div className="row">
          <InputCopy value={this.getLink()} key={this.getLink()} />
        </div>

        <div className="row">
          <span className={element('InputLabel')}>{SHARE_LINK.PNG_LABEL}</span>
          <InputCopy value={this.props.link.pngLink} key={this.props.link.pngLink} />
        </div>

        <div className="row">
          <span className={element('InputLabel')}>{SHARE_LINK.SVG_LABEL}</span>
          <InputCopy value={this.props.link.svgLink} key={this.props.link.svgLink} />
        </div>

        <div className="row">
          <Button
            text={SHARE_LINK.UPDATE}
            onClick={this.props.updateSharedLink}
            size="small"
            style="light"
            disabled={this.props.link.owner !== this.props.userId}
          />
          <Button
            text={SHARE_LINK.GENERATE_NEW}
            onClick={this.props.generateSharedLink}
            size="small"
            style="dark"
          />
        </div>
      </div>
    )
  }

  render = () => (this.props.link ? <this.ExistingLink /> : <this.GenerateLinkButton />)
}

const mapStateToProps = (state: IRootState): IStateProps => ({
  link: state.series.link,
  userId: state.user.user.id,
})

const mapDispatchToProps = (dispatch: any): IActionProps => {
  return {
    generateSharedLink: () => dispatch(generateSharedLink()),
    updateSharedLink: () => dispatch(updateSharedLink()),
    setLink: shareLinkResponse => dispatch(ACTIONS.setLink(shareLinkResponse)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkShare)
