// eslint-disable max-lines
import { IRootState } from '.'
import { push } from 'connected-react-router'
import lscache from 'lscache'
import { AnyAction, Dispatch } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { ActionType, createAction, getType } from 'typesafe-actions'

import * as ga from '../analytics'
import * as api2 from '../api2/databases'
import * as databases from '../api/databases'
import { TABS } from '../services/sidebar'
import { isHaverAdmin } from '../services/user'
import { getArray } from '../utils'
import { addSeries, replaceSeries } from './Series'

export const ACTIONS = {
  openTab: createAction('OPEN_SIDEBAR_TAB', resolve => (tab: TABS) => resolve(tab)),
  receivedData: createAction('RECEIVED_DATA', resolve => (data: IData) => resolve(data)),
  receivedDatabases: createAction(
    'RECEIVED_DATABASES',
    resolve => (dbs: IDirectoryEntry[]) => resolve(dbs)
  ),
  setRoute: createAction(
    'SET_ROUTE',
    resolve => (route: IDirectoryEntry[]) => resolve(route)
  ),
  setDbs: createAction('SET_DBS', resolve => (dbNames: SMap<string>) => resolve(dbNames)),
  setFavourites: createAction(
    'SET_FAVOURITES',
    resolve => (databaseNames: string[]) => resolve(databaseNames)
  ),
  createFavourite: createAction(
    'CREATE_FAVOURITE',
    resolve => (dbName: string) => resolve(dbName)
  ),
  deleteFavourite: createAction(
    'DELETE_FAVOURITE',
    resolve => (dbName: string) => resolve(dbName)
  ),
  setSeriesOptions: createAction(
    'SET_SERIES_OPTIONS',
    resolve => (selected: number) => resolve(selected)
  ),
  saveRouteSnapshot: createAction('SAVE_ROUTE_SNAPSHOT', resolve => () => resolve()),
  loadRouteSnapshot: createAction('LOAD_ROUTE_SNAPSHOT', resolve => () => resolve()),
  setPending: createAction(
    'SET_PENDING',
    resolve => (payload: boolean) => resolve(payload)
  ),
}

export type State = {
  sidebarTab: TABS
  entries: IDirectoryEntry[]
  route: IDirectoryEntry[]
  footnotes: SMap<IFootnote>
  selectedSeriesOption: number
  dbNames: SMap<string>
  dbs: IDirectoryEntry[]
  favouriteDbs: Set<string>
  snapshot: {
    route: IDirectoryEntry[]
    entries: IDirectoryEntry[]
  }
  pending: boolean
}
const EMPTY_DATA: IData = { entries: [], footnotes: {} }
const INITIAL_STATE: State = {
  sidebarTab: TABS.DIRECTORY,
  entries: [],
  favouriteDbs: new Set(),
  route: [],
  footnotes: {},
  selectedSeriesOption: 0,
  dbNames: {},
  dbs: [],
  snapshot: {
    route: [],
    entries: [],
  },
  pending: false,
}

export type DataDirectoryAction = ActionType<(typeof ACTIONS)[keyof typeof ACTIONS]>

export function reducer(state = INITIAL_STATE, action: DataDirectoryAction): State {
  switch (action.type) {
    case getType(ACTIONS.openTab):
      return { ...state, sidebarTab: action.payload }
    case getType(ACTIONS.receivedData):
      const { entries, footnotes } = action.payload
      return { ...state, entries, footnotes }
    case getType(ACTIONS.receivedDatabases):
      const dbs = action.payload
      return { ...state, dbs }
    case getType(ACTIONS.setRoute):
      return { ...state, route: action.payload, selectedSeriesOption: 0 }
    case getType(ACTIONS.setSeriesOptions):
      return { ...state, selectedSeriesOption: action.payload }
    case getType(ACTIONS.setDbs):
      return { ...state, dbNames: action.payload }
    case getType(ACTIONS.saveRouteSnapshot):
      return {
        ...state,
        snapshot: { route: [...state.route], entries: state.entries },
      }
    case getType(ACTIONS.loadRouteSnapshot):
      return {
        ...state,
        route: state.snapshot.route,
        entries: state.snapshot.entries,
      }
    case getType(ACTIONS.setFavourites):
      return { ...state, favouriteDbs: new Set(action.payload) }
    case getType(ACTIONS.createFavourite): {
      const favouriteDbs = new Set(state.favouriteDbs)
      favouriteDbs.add(action.payload)
      return { ...state, favouriteDbs }
    }
    case getType(ACTIONS.deleteFavourite): {
      const favouriteDbs = new Set(state.favouriteDbs)
      favouriteDbs.delete(action.payload)
      return { ...state, favouriteDbs }
    }
    case getType(ACTIONS.setPending):
      return { ...state, pending: action.payload }

    default:
      return state
  }
}

export const isSeries = (entry: IDirectoryEntry) => !!entry.links

export function pushEntry(entry: IDirectoryEntry, replaceIndex?: number) {
  return async (dispatch: any, getState: () => IRootState) => {
    dispatch(ACTIONS.setPending(true))
    if (isSeries(entry)) {
      const { selectedSeriesOption, route: r } = getState().databases
      const seriesState = getState().series
      dispatch(push(seriesState.link ? `/series/${seriesState.link.id}` : '/series'))
      const seriesId = entry.links[selectedSeriesOption.toString()]
      const databaseId = r[0].id
      const series = { databaseId, seriesId }
      ga.track(
        'data directory',
        replaceIndex === undefined ? 'add variable' : 'display graph',
        {
          series: seriesId,
          db: databaseId,
        }
      )
      if (replaceIndex === undefined) {
        dispatch(addSeries(series))
      } else {
        dispatch(replaceSeries(series, replaceIndex))
      }
      dispatch(ACTIONS.setPending(false))
      return
    }

    const route = [...getState().databases.route, entry]
    const db = route[0]
    const isDb = route.length === 1
    ga.track('data directory', isDb ? 'select db' : 'select page', {
      page: entry.link,
      db: db.id,
    })
    const isAdmin = isHaverAdmin(getState().user.user.role)
    let data: IData = null
    try {
      data = await databases.getData(db.id, entry.link)
      if (isAdmin) {
        data = { ...data, isPreview: false }
      }

      const { parent } = data
      if (parent) {
        const parentIndex = route.length - 1
        route[parentIndex] = {
          ...route[parentIndex],
          description: parent.description,
          options: parent.options,
          footnoteId: parent.footnoteId,
          isPreview: data.isPreview,
        }
      } else if (route.length > 0) {
        const parentIndex = route.length - 1
        route[parentIndex].isPreview = data.isPreview
      }
      dispatch(ACTIONS.receivedData(data))
      dispatch(ACTIONS.setRoute(route))
    } catch (err) {
      dispatch(ACTIONS.receivedData(EMPTY_DATA))
      throw err
    }
    dispatch(ACTIONS.setPending(false))
  }
}

export function popEntry(popNumber = 1) {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    dispatch(ACTIONS.setPending(true))
    ga.track('data directory', 'back')
    const route = getState().databases.route.slice(0, -popNumber)
    const db = route[0]
    try {
      if (db) {
        const data = await databases.getData(db.id, route[route.length - 1].link)
        dispatch(ACTIONS.receivedData(data))
      } else {
        const dbs = getState().databases.dbs
        if (!dbs?.length) {
          const data = (await databases.getAllDatabases()).entries
          dispatch(ACTIONS.receivedDatabases(data))
        }
      }
      dispatch(ACTIONS.setRoute(route))
    } catch (err) {
      dispatch(ACTIONS.receivedData(EMPTY_DATA))
      throw err
    }
    dispatch(ACTIONS.setPending(false))
  }
}

const DB_CACHE_VERSION = 1

export function fetchDatabases(user: IUser) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => IRootState
  ) => {
    const dbs = getState().databases.dbs
    const isAdmin = isHaverAdmin(user.role)

    dispatch(ACTIONS.setPending(true))
    try {
      dispatch(ACTIONS.setRoute([]))

      if (!dbs?.length) {
        dispatch(fetchFavourites())
        dispatch(setDatabasesFromCache())
        let data = (await databases.getAllDatabases()).entries
        if (isAdmin) {
          data = data.map(entry => ({ ...entry, isPreview: false }))
        }
        dispatch(ACTIONS.receivedDatabases(data))
        cacheDatabases(databaseCacheKey(user.id), data)

        const dbNames: SMap<string> = {}
        data.forEach(db => (dbNames[db.id] = db.description))
        dispatch(ACTIONS.setDbs(dbNames))
      }
    } catch (err) {
      dispatch(ACTIONS.receivedData(EMPTY_DATA))
      throw err
    }
    dispatch(ACTIONS.setPending(false))
  }
}

export function setDatabasesFromCache() {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => IRootState
  ) => {
    const user = getState().user.user
    const cacheKey = databaseCacheKey(user.id)
    const data = getDatabasesFromCache(cacheKey)
    if (!data) {
      return
    }
    dispatch(ACTIONS.receivedDatabases(data))
    const dbNames: SMap<string> = {}
    data.forEach(db => (dbNames[db.id] = db.description))
    dispatch(ACTIONS.setDbs(dbNames))
  }
}

export function databaseCacheKey(userId: number) {
  return `databases-list-${userId}-${DB_CACHE_VERSION}`
}

export function getDatabasesFromCache(key: string): IDirectoryEntry[] | null {
  lscache.flushExpired()
  const data: IDirectoryEntry[] = lscache.get(key)
  if (!data) {
    return null
  }
  return data
}

export function cacheDatabases(
  key: string,
  entries: IDirectoryEntry[],
  ttlMinutes: number = 60 * 24 * 7
) {
  lscache.set(key, entries, ttlMinutes)
}

export function openDatabases() {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(ACTIONS.openTab(TABS.DIRECTORY))
  }
}

export function goRoot() {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(ACTIONS.setRoute([]))
  }
}

const buildRoute = async (
  db: string,
  page: string | string[],
  existingRoute: IDirectoryEntry[]
): Promise<IDirectoryEntry[]> => {
  const pageId = Array.isArray(page) ? page[0] : page
  const data: any = await databases.getPageData(db, pageId)
  const entry = databases.toDirectoryEntry(data.data, pageId, db)
  const route = [entry, ...existingRoute]
  if (data.data?.parent) {
    const parent = data.data.parent
    return buildRoute(db, parent, route)
  }
  return route
}

export const buildDataDirectory =
  (databaseName: string, seriesId: string) => async (dispatch: Dispatch) => {
    const { data_json } = await api2.getDataDirectoryEntry(databaseName, seriesId)
    const entries = getArray(data_json.concept).map(databases.toEntry)
    const page = databases.toDirectoryEntry(data_json, data_json?.parent, databaseName)
    const route = await buildRoute(databaseName, data_json.parent, [page])
    const footnotes = databases.getFootnotes(data_json.footnotetext)
    dispatch(ACTIONS.receivedData({ entries, footnotes }))
    dispatch(ACTIONS.setRoute(route))
  }

export const fetchFavourites = () => async (dispatch: Dispatch) => {
  const favs = await api2.fetchFavouriteDatabases()
  dispatch(ACTIONS.setFavourites(favs))
}

const FAV_DEBOUNCER = new Set<string>()

async function withFavDebouncer(dbname: string, fn: () => Promise<void>) {
  if (FAV_DEBOUNCER.has(dbname)) {
    return
  }
  FAV_DEBOUNCER.add(dbname)
  await fn()
  FAV_DEBOUNCER.delete(dbname)
}

export const createFavourite = (dbName: string) => async (dispatch: Dispatch) =>
  withFavDebouncer(dbName, async () => {
    const fav = await api2.createFavouriteDatabase(dbName)
    dispatch(ACTIONS.createFavourite(fav.databaseName))
  })

export const deleteFavourite = (dbName: string) => async (dispatch: Dispatch) =>
  withFavDebouncer(dbName, async () => {
    await api2.removeFavouriteDatabase(dbName)
    dispatch(ACTIONS.deleteFavourite(dbName))
  })
