import * as d3 from 'd3'
import { isEqual } from 'date-fns'
import * as React from 'react'
import * as FauxDOM from 'react-faux-dom'

import {
  AnyXScale,
  ScatterScale,
  XScale,
  YScale,
  renderXGrid,
  renderYGrid,
} from '../../services/hd3'
import * as scatter from '../../services/hd3-utils/scatter-plot'

export interface IXAxisGridProps {
  yScale: YScale
  ticks: number[]
  width: number
}

const _AxisXGrid = (props: IXAxisGridProps) => {
  const svg = FauxDOM.createElement('g')
  const selection = d3.select(svg)
  renderXGrid(selection, props.yScale, props.width, props.ticks)
  return <>{React.Children.map(svg.toReact(), c => c.props.children)}</>
}

export const AxisXGrid = React.memo(_AxisXGrid, (prevProps, props) => {
  if (!compareProps(prevProps, props)) {
    return false
  }
  return compareNumberScales(props.yScale, prevProps.yScale)
})

function getKeyProps<T = IYAxisGridProps | IYAxisGridProps>(props: T) {
  return Object.keys(props).filter(key => key !== 'scale') as (keyof T)[]
}

function compareProps<T = IYAxisGridProps | IYAxisGridProps>(prevProps: T, props: T) {
  const keys = getKeyProps(props)
  return keys.every(k => prevProps[k] === props[k])
}

export interface IYAxisGridProps {
  xScale: AnyXScale
  width: number
  height: number
  frequency: Frequency
}

const _AxisYGrid = (props: IYAxisGridProps) => {
  const svg = FauxDOM.createElement('g')
  const selection = d3.select(svg)
  if (scatter.isScatterScale(props.xScale)) {
    scatter.renderYGrid(selection, props.xScale, props.height)
  } else {
    renderYGrid(selection, props.xScale, props.width, props.height, props.frequency)
  }
  return <>{React.Children.map(svg.toReact(), c => c.props.children)}</>
}

export const AxisYGrid = React.memo(_AxisYGrid, (prevProps, props) => {
  if (!compareProps(prevProps, props)) {
    return false
  }
  if (scatter.isScatterScale(props.xScale) && scatter.isScatterScale(prevProps.xScale)) {
    return compareNumberScales(props.xScale, prevProps.xScale)
  } else if (
    scatter.isScatterScale(props.xScale) !== scatter.isScatterScale(props.xScale)
  ) {
    return false
  }
  return compareDateScales(props.xScale as XScale, prevProps.xScale as XScale)
})

function compareNumberScales(curr: ScatterScale | YScale, old: ScatterScale | YScale) {
  const [oldDomain, domain]: number[][] = [old.domain(), curr.domain()]
  const [oldRange, range]: number[][] = [old.range(), curr.range()]
  return (
    oldDomain.every((v, i) => v === domain[i]) && oldRange.every((v, i) => v === range[i])
  )
}

function compareDateScales(curr: XScale, old: XScale) {
  const [oldDomain, domain]: Date[][] = [old.domain(), curr.domain()]
  const [oldRange, range]: number[][] = [old.range(), curr.range()]
  return (
    oldDomain.every((v, i) => isEqual(v, domain[i])) &&
    oldRange.every((v, i) => v === range[i])
  )
}
