import * as React from 'react'
import * as FauxDOM from 'react-faux-dom'
import * as d3 from 'd3'
import { renderSource } from '../../services/hd3'

interface IProps {
  height: number
  value?: string
}

const Source = ({ value, height }: IProps) => {
  const svg = FauxDOM.createElement('g')
  const selection = d3.select(svg)
  renderSource(selection, value, height)

  return <>{React.Children.map(svg.toReact(), c => c.props.children)}</>
}

export default React.memo(Source)
