import * as React from 'react'

import * as _ from 'lodash'
import { CSSTransition } from 'react-transition-group'

import { bindBem } from '../../bem'
import Chart from '../../components/AChart'
import { FCTable as Table } from '../../components/Series/Table'
import { Props, collapsedTableHeight } from './common'
import { isTransformation } from 'store/Transformations'
import { getSeriesHeader } from 'services/series'

import './Mobile.scss'

const getRelativeHeight = (totalHeight: number) => {
  const height = totalHeight - collapsedTableHeight
  return height > 0 ? height : 0
}

export class MobileSeriesContainer extends React.PureComponent<Props> {
  render() {
    const { block, element } = bindBem('MobileSeriesContainer')
    const { variables, offsetSeries, isLoading, scale, correlation } = this.props
    const { recessions, recessionType, scatterSettings } = this.props
    const { startZoomDate, endZoomDate, isLegendShown, stackedConfig } = this.props
    const { size } = this.props
    const { isTableHidden, isToggleButtonClicked } = this.props

    if (isLoading) {
      return <div />
    }

    return (
      <div className={block()}>
        <div className={element('Series')}>
          <Chart
            variables={variables}
            size={{ width: size.width, height: getRelativeHeight(size.height) }}
            startZoomDate={startZoomDate}
            endZoomDate={endZoomDate}
            settings={this.getGraphSettings()}
            onEditTitle={undefined}
            recessions={recessions}
            recessionType={recessionType}
            isLegendShown={isLegendShown}
            scatterSettings={scatterSettings}
            scrollByPeriod={undefined}
            scale={scale}
            stackedConfig={stackedConfig}
            correlation={correlation}
            disableLegendEdition
            trendlineBoundaries={this.props.trendlineBoundaries}
          />
        </div>

        <CSSTransition
          in={this.props.isToggleButtonClicked}
          classNames="Table"
          timeout={300}
          onEntered={this.toggleTableHidden}
          onExited={this.toggleTableHidden}
        >
          <Table
            isHidden={isTableHidden && isToggleButtonClicked}
            handleToggle={this.toggleTableSwitchClicked}
            variables={offsetSeries}
            frequency={Math.max(...variables.map(v => v.frequency)) as Frequency}
            height={size.height}
            width={size.width}
            stats={this.props.stats}
          />
        </CSSTransition>
      </div>
    )
  }

  private toggleTableHidden = () => this.props.setTableHidden(!this.props.isTableHidden)
  private toggleTableSwitchClicked = () =>
    this.props.setToggleTable(!this.props.isToggleButtonClicked)

  private getGraphSettings = (): ISeriesSettings => {
    const first = this.props.variables[0]
    const title = isTransformation(first.transformation)
      ? getSeriesHeader(first)
      : first.description
    return {
      ...this.props.settings,
      title,
    }
  }
}
