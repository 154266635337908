import * as React from 'react'
import { connect } from 'react-redux'

import { bindBem } from '../../bem'
import { IBadRequestErrorData } from 'api/config'
import { IRootState, TDispatch } from '../../store'
import { patchOrganization as patchOrganizationAction } from '../../store/Admin'
import { ItemSelector } from './ItemSelector'
import { openToast } from '../../store/User'

import './Packages.scss'

interface IProps {
  organization: IOrganization
}

interface IStateProps {
  packages: IDBPackage[]
}

interface IActionProps {
  patchOrganization: (
    id: number,
    org: Partial<IOrganization>,
    onError: (errors: IBadRequestErrorData) => void
  ) => void
  openToast: (toast: Partial<IToast>) => void
}

const Packages = ({
  organization,
  packages,
  patchOrganization,
}: IProps & IStateProps & IActionProps) => {
  const { block } = bindBem('Packages')

  const onPatchError = (errors: IBadRequestErrorData) => {
    openToast({ title: Object.values(errors).join(', ') })
  }

  const onPackagesChange = (newPackages: string[]) => {
    patchOrganization(organization.id, { packages: newPackages }, onPatchError)
  }

  return (
    <div className={block()}>
      <ItemSelector
        items={packages}
        selectedIds={organization.packages}
        onChange={onPackagesChange}
      />
    </div>
  )
}

const mapStateToProps = (state: IRootState): IStateProps => {
  const { packages } = state.admin

  return {
    packages,
  }
}

const mapDispatchToProps = (dispatch: TDispatch): IActionProps => ({
  patchOrganization: (
    id: number,
    data: Partial<IOrganization>,
    onError: (errors: IBadRequestErrorData) => void
  ) => dispatch(patchOrganizationAction(id, data, onError)),
  openToast: (toast: Partial<IToast>) => dispatch(openToast(toast)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Packages)
