import * as React from 'react'
import * as FauxDOM from 'react-faux-dom'
import * as d3 from 'd3'
import { addClipPath } from '../../services/hd3'

interface IProps {
  width: number
  height: number
}

const ClipPath = (props: IProps) => {
  const svg = FauxDOM.createElement('g')
  const selection = d3.select(svg)
  addClipPath(selection, props.width, props.height)

  return <>{React.Children.map(svg.toReact(), c => c.props.children)}</>
}

export default React.memo(ClipPath)
