// eslint-disable no-invalid-this

import * as d3 from 'd3'
import { COLORS } from './series'
import * as _ from 'lodash'
import * as d3wrap from 'd3-textwrap'

export interface ITextBlockConfig {
  id: string
  screenWidth: number
  margin: { top: number; right: number; left: number }
  columnCount: number
  colorIndexes?: number[]
  textWrapping: boolean
  columnMargin: number
  legendRectWidth: number
  oneLineHeight: number
  fontSize: number
  fontWeight: number
}

export interface ITitleTextBlockConfig extends ITextBlockConfig {
  fontColor?: string
  printable?: boolean
}

export interface ITextBlockContent {
  columnWidth: number
  height: number
  colors: string[][]
  columns: D3Selection[]
}

export interface ISVGRect extends HTMLOrSVGElement {
  getBoundingClientRect?: () => { height: number }
  innerHTML?: string
  height?: number
  dy: SVGAnimatedLengthList
  textContent: string
}

export const wrap = (selection: D3Selection, width: number) => {
  selection.each(function () {
    const wrapper = d3wrap.textwrap().bounds({ height: 1000, width }).method('tspans')
    const text = d3.select(this)

    const node = text.node()
    if (typeof node.getComputedTextLength === 'function') {
      text.call(wrapper)
    }
  })
}

export const getTextBlockConfig = (
  titles: string[],
  config: ITextBlockConfig
): ITextBlockContent => {
  const columnWidth =
    (config.screenWidth - config.margin.left - config.margin.right) / config.columnCount -
    config.legendRectWidth -
    config.columnMargin

  const existingTmpLegend = d3.select(`#${config.id}`)
  if (existingTmpLegend.size() > 0) {
    existingTmpLegend.remove()
  }

  const columns: any[] = []
  const heights: number[] = []
  const colors: string[][] = []

  const tmpSvg = d3
    .select('body')
    .append('svg')
    .attr('id', config.id)
    .style('visibility', 'hidden')
    .attr('font-size', config.fontSize)
    .style('font-weight', config.fontWeight)

  _.range(config.columnCount).map(columnNumber => {
    const columnTitles = _.filter(
      titles,
      (_title, index) => index % config.columnCount === columnNumber
    )
    const newTitles: string[][] = columnTitles.map(item => _.split(item, '\n'))

    const columnColorIndexes = _.filter(
      config.colorIndexes || [],
      (_color, index) => index % config.columnCount === columnNumber
    )
    const columnColors = newTitles.map((rows, index): string[] => {
      const result: string[] = Array(rows.length).fill('')
      result[0] =
        columnColorIndexes.length > index ? COLORS[columnColorIndexes[index]] : ''
      return result
    })

    const legend = tmpSvg
      .selectAll()
      .data(_.flatten(newTitles) || [])
      .enter()

    const column = legend
      .append('text')
      .text(t => t)
      .call(wrap, columnWidth)

    let height = 0
    column.each(function () {
      if (config.textWrapping) {
        const children = this.childNodes as ISVGRect[]
        if (!children) {
          return
        }
        const child = children[0]
        if (child !== undefined && child.getBoundingClientRect !== undefined) {
          height += child.getBoundingClientRect().height
        }
      } else {
        height += config.oneLineHeight
      }
    })
    heights.push(height)
    columns.push(column)
    colors.push(_.flatten(columnColors))
  })

  return {
    columnWidth,
    height: _.max(heights),
    colors,
    columns,
  }
}

export const renderTextBlockLine = (
  text: D3Selection,
  x: number,
  child: ISVGRect,
  config: ITextBlockConfig,
  dots?: boolean
) => {
  const dy = child.dy ? child.dy.baseVal.getItem(0) : null
  text
    .append('tspan')
    .attr('dx', config.legendRectWidth)
    .attr('x', x)
    .attr('dy', dy ? dy.valueAsString : '1em')
    .text(`${child.textContent}${dots ? '...' : ''}`)
}
