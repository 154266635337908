import React from 'react'
import { Link, LinkProps, useLocation } from 'react-router-dom'

export const ModalLink: React.FC<LinkProps> = props => {
  const location = useLocation<SMap<unknown>>()
  let { to } = props

  const state = { background: location }
  if (typeof to === 'string') {
    to = { pathname: to, state }
  } else {
    const oldState = location.state || {}
    to = { ...to, state: { ...oldState, state } }
  }
  return (
    <Link
      {...props}
      to={{
        pathname: '/functions',
        state: { background: location },
      }}
    />
  )
}
