import * as React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'

import * as ga from './analytics'
import * as Sentry from './sentry'

import App from './App'
import { configureStore } from './store'
import { ResponsiveProvider } from './components/Responsive'

const store = configureStore()

Sentry.init()
ga.init()

const Root = () => (
  <Provider store={store}>
    <ResponsiveProvider>
      <App />
    </ResponsiveProvider>
  </Provider>
)
render(<Root />, document.getElementById('app'))

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('service-worker.js').catch(err => {
    // eslint-disable-next-line no-console
    console.log('Unable to create service worker. This happened: ', err)
  })
}
