import * as React from 'react'
import { xor } from 'lodash'

import { bindBem } from './../../../bem'
import { Checkbox } from './../../Checkbox'
import { Status } from './../../Status'
import { Role } from './../Role'
import { getUserTypesForRole } from './../consts'
import { Labeled } from '../../Input'
import { DeleteCell } from './../AdminTableCells'
import { BigSwitch } from './../BigSwitch'
import { Modal } from './../../Modal'
import { RemoveUsersConfirmation } from './../RemoveOrgConfirmation'
import { isHaverAdmin } from '../../../services/user'

import './BulkUserToolbar.scss'

interface IProps {
  me: IUser
  users: IUser[]
  selectedUsers: number[]
  selectedAll: boolean
  onToggleCheck: (status: boolean) => void
  onUsersRoleChange: (role: Role) => void
  onUsersStatusChange: (status: boolean) => void
  onUsersApiStatusChange: (status: boolean) => void
  onUsersDelete: () => void
}

interface IState {
  isDeleteUserModalOpen: boolean
  values: {
    type: Role
    status: boolean
    apiStatus: boolean
  }
}

export class BulkUserToolbar extends React.Component<IProps, IState> {
  state: IState = {
    isDeleteUserModalOpen: false,
    values: {
      type: null,
      status: null,
      apiStatus: null,
    },
  }

  componentDidUpdate(prevProps: IProps) {
    if (xor(this.props.selectedUsers, prevProps.selectedUsers).length !== 0) {
      this.setState(prev => ({
        ...prev,
        values: { type: null, status: null, apiStatus: null },
      }))
    }
  }

  onChangeRole(type: Role) {
    this.setState(prev => ({ ...prev, values: { ...prev.values, type } }))
    this.props.onUsersRoleChange(type)
  }

  onChangeStatus(status: boolean) {
    this.setState(prev => ({ ...prev, values: { ...prev.values, status } }))
    this.props.onUsersStatusChange(status)
  }

  onChangeApiStatus(apiStatus: boolean) {
    this.setState(prev => ({ ...prev, values: { ...prev.values, apiStatus } }))
    this.props.onUsersApiStatusChange(apiStatus)
  }

  onToggleDeleteUsersModal() {
    this.setState(prev => ({
      isDeleteUserModalOpen: !prev.isDeleteUserModalOpen,
    }))
  }

  confirmDeleteUsers() {
    this.onToggleDeleteUsersModal()
    this.props.onUsersDelete()
  }

  selectedUsers() {
    return this.props.users.filter(user => this.props.selectedUsers.includes(user.id))
  }

  render() {
    const { block, element } = bindBem('BulkUserToolbar')
    const {
      values: { status, apiStatus, type },
    } = this.state

    return (
      <div className={block()}>
        <div className={element('CheckAll')}>
          <Checkbox
            checked={this.props.selectedAll}
            big={true}
            onChange={(checked: boolean) => this.props.onToggleCheck(checked)}
          />
        </div>

        <div className={element('Type')}>
          <Labeled label="TYPE">
            <Role
              value={type}
              options={getUserTypesForRole(this.props.me.role)}
              setOption={role => this.onChangeRole(role)}
            />
          </Labeled>
        </div>

        <div className={element('Status')}>
          <Labeled label="STATUS">
            <Status
              value={status}
              onSelect={val => this.onChangeStatus(Boolean(val))}
              disabled={false}
              defaultValue={''}
            />
          </Labeled>
        </div>

        <div>
          <Labeled label="API STATUS">
            <BigSwitch
              value={apiStatus}
              onChange={val => this.onChangeApiStatus(Boolean(val))}
              disabled={!isHaverAdmin(this.props.me.role)}
            />
          </Labeled>
        </div>

        <div className={element('Delete')}>
          <DeleteCell onClick={() => this.onToggleDeleteUsersModal()} />
        </div>

        <Modal
          isOpen={this.state.isDeleteUserModalOpen}
          onClose={() => this.onToggleDeleteUsersModal()}
        >
          <RemoveUsersConfirmation
            onClose={() => this.onToggleDeleteUsersModal()}
            callback={() => this.confirmDeleteUsers()}
            obj={this.selectedUsers()}
          />
        </Modal>
      </div>
    )
  }
}
