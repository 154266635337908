// eslint-disable max-len

import * as React from 'react'

const IconKeyboard = ({ color }: any) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width="20" height="14" x="2" y="5" stroke={color} strokeWidth="2" rx="2" />
      <path
        fill={color}
        d="M5 8h2v2H5zM8 8h2v2H8zM11 8h2v2h-2zM14 8h2v2h-2zM17 8h2v2h-2zM5 11h2v2H5zM8 11h2v2H8zM11 11h2v2h-2zM14 11h2v2h-2zM17 11h2v2h-2zM5 14h2v2H5zM8 14h8v2H8z"
      />
      <path fill={color} d="M11 14h2v2h-2zM14 14h2v2h-2zM17 14h2v2h-2z" />
    </g>
  </svg>
)

export const ICON_KEYBOARD = <IconKeyboard color="#3FA2DB" />
