import * as React from 'react'
import { bindBem } from 'bem'
import { MenuOptions } from 'containers/MenusAdmin/Options/MenuOptions'

import './ColumnHeader.scss'

export interface IProps {
  menu: IMenuLayout
  areAllGroupsCollapsed: boolean
  renameMenuClick: () => void
  deleteMenuClick: () => void
  collapseAllClick: () => void
  expandAllClick: () => void
}

export const ColumnHeader: React.FC<IProps> = ({
  menu,
  areAllGroupsCollapsed,
  renameMenuClick,
  deleteMenuClick,
  collapseAllClick,
  expandAllClick,
}) => {
  const { block, element } = bindBem('ColumnHeader')

  return (
    <div className={block()}>
      <span className={element('Title')} title={menu.label}>
        {menu.label}
      </span>
      <MenuOptions
        edit={renameMenuClick}
        remove={deleteMenuClick}
        areAllGroupsCollapsed={areAllGroupsCollapsed}
        expandAll={expandAllClick}
        collapseAll={collapseAllClick}
      />
    </div>
  )
}
