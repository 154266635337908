import * as React from 'react'

import { bindBem } from 'bem'
import { ADMIN } from 'messages'
import { IconOption } from 'containers/MenusAdmin/Options/IconOption'

import BinIcon from 'static/red-bin.svg'

import './SeparatorOptions.scss'

export interface IProps {
  deleteSeparator: () => void
}

export const SeparatorOptions: React.FC<IProps> = ({ deleteSeparator }) => {
  const { block } = bindBem('SeparatorOptions')

  return (
    <div className={block()}>
      <IconOption
        icon={BinIcon}
        onClick={deleteSeparator}
        tooltip={ADMIN.MENUS.SEPARATOR_OPTIONS.DELETE_SEPARATOR}
      />
    </div>
  )
}
