// eslint-disable max-lines
import * as Sentry from '@sentry/react'
import {
  DeleteCell,
  EditCell,
  HeaderCell,
  TextWithHover,
} from 'components/Admin/AdminTableCells'
import Papa from 'papaparse'
import * as React from 'react'
import { connect } from 'react-redux'
import ReactTable, { CellInfo, Column, SortingRule, TableCellRenderer } from 'react-table'
import {
  openToast as openToastAction,
  resendInvite as resendInviteAction,
} from 'store/User'

import { bindBem } from '../../../bem'
import { ADMIN } from '../../../messages'
import { isHaverAdmin } from '../../../services/user'
import { IRootState, TDispatch } from '../../../store'
import * as admin from '../../../store/Admin'
import { BigSwitch } from '../../Admin/BigSwitch'
import { RemoveUserConfirmation } from '../../Admin/RemoveOrgConfirmation'
import { Role } from '../../Admin/Role'
import { Badge } from '../../Badge'
import { Button } from '../../Button'
import { Checkbox } from '../../Checkbox'
import { Fade } from '../../Fade'
import { FilePickerButton } from '../../FilePicker'
import { Modal } from '../../Modal'
import { getUserTypesForRole } from './../consts'
import { BadImportFile } from './BadImportFile'
import { BulkUserToolbar } from './BulkUserToolbar'
import CreateUsersForm from './CreateUsersForm'
import { UserForm } from './UserForm'
import { extractUsersData } from './utils'

import './Users.scss'

export interface IActionProps {
  createUser: (user: IUser) => void
  updateUser: (user: IUser) => void
  deleteUser: (user: IUser) => void
  updateUserStatus: (user: IUser) => void
  updateUserMigrationStatus: (user: IUser) => void
  updateUserApiStatus: (user: IUser) => void
  updateUserRole: (user: IUser) => void
  updateUsersStatus: (users: IUser[]) => void
  updateUsersApiStatus: (users: IUser[]) => void
  updateUsersRole: (users: IUser[]) => void
  deleteUsers: (users: IUser[]) => void
  createUsers: (users: IUser[]) => void
  resendInvite: (user: IUser) => void
  openToast: (toast: Partial<IToast>) => void
}

export interface IProps {
  organization: IOrganization
}

export interface IStateProps {
  me: IUser
  organizationUsers: IUser[]
}

export interface IState {
  sortedColumn: string
  desc: boolean
  isDeleteUserModalOpen: boolean
  isUserModalOpen: boolean
  isCreateUserModalOpen: boolean
  selectedUsers: number[]
  lastSelectedIndex: number
  isFileErrorModalOpen: boolean
  importedUsers: Partial<IUser>[]
}

enum Mode {
  Single,
  Bulk,
}

interface UserColumn extends Column {
  disabled?: (data: CellInfo) => boolean
}

export class Users extends React.Component<IProps & IActionProps & IStateProps, IState> {
  state: IState = {
    sortedColumn: 'username',
    desc: false,
    isDeleteUserModalOpen: false,
    isUserModalOpen: false,
    isCreateUserModalOpen: false,
    selectedUsers: [],
    lastSelectedIndex: -1,
    isFileErrorModalOpen: false,
    importedUsers: undefined,
  }

  private COLUMNS: UserColumn[] = [
    {
      Header: () => (
        <Checkbox
          checked={this.allSelected()}
          big={true}
          onChange={(checked: boolean) => this.onToggleCheckAll(checked)}
        />
      ),
      headerClassName: 'SelectAll',
      maxWidth: 50,
      sortable: false,
      disabled: ({ original }) => original.id === this.props.me.id,
      Cell: ({ original }) => (
        <Checkbox
          checked={this.isUserSelected(original)}
          big
          rawOnchange={e => this.handleCheckboxRawChange(e, original)}
        />
      ),
    },
    {
      Header: ADMIN.USERS.NAME,
      accessor: 'username',
      sortable: true,
      className: 'UsernameCell',
      Cell: ({ value, original }) => (
        <>
          {original.mfaStatus && (
            <div>
              <Badge>2FA</Badge>
            </div>
          )}

          <TextWithHover value={value} />
        </>
      ),
    },
    {
      Header: ADMIN.USERS.EMAIL_ADDRESS,
      accessor: 'email',
      maxWidth: 270,
      sortable: true,
      Cell: TextWithHover,
    },
    {
      Header: ADMIN.USERS.TYPE,
      accessor: 'role',
      maxWidth: 160,
      sortable: true,
      disabled: ({ original }) => this.isBulkMode() || original.id === this.props.me.id,
      Cell: ({ value, original }: { value: Role; original: IUser }) => (
        <Role
          value={value}
          defaultValue={value}
          options={getUserTypesForRole(this.props.me.role)}
          setOption={role => this.onChangeRole(original, role)}
        />
      ),
    },
    {
      Header: ADMIN.USERS.STATUS,
      accessor: 'settings.isActive',
      maxWidth: 95,
      sortable: true,
      disabled: ({ original }) => this.isBulkMode() || original.id === this.props.me.id,
      Cell: ({ value, original }) => (
        <BigSwitch
          value={value}
          onChange={val => this.onChangeStatus(original, Boolean(val))}
        />
      ),
    },
    {
      Header: ADMIN.USERS.API_STATUS,
      accessor: 'settings.apiAccess',
      maxWidth: 95,
      sortable: true,
      disabled: () => this.isBulkMode() || !isHaverAdmin(this.props.me.role),
      Cell: ({ value, original }) => (
        <BigSwitch
          value={value}
          onChange={val => this.onChangeApiStatus(original, Boolean(val))}
        />
      ),
    },
    {
      Header: ADMIN.USERS.IS_MIGRATED,
      accessor: 'settings.isMigrated',
      maxWidth: 95,
      sortable: true,
      disabled: ({ original }) => this.isBulkMode() || original.id === this.props.me.id,
      Cell: ({ value, original }) => (
        <BigSwitch
          value={value}
          onChange={val => this.onChangeMigratedStatus(original, Boolean(val))}
          labelOn="YES"
          labelOff="NO"
        />
      ),
    },
    {
      Header: '',
      sortable: false,
      maxWidth: 59,
      disabled: ({ original }) => this.isBulkMode() || this.props.me.id === original.id,
      Cell: ({ original }) => (
        <EditCell onClick={() => this.onToggleUserModal(original)} />
      ),
    },
    {
      Header: '',
      sortable: false,
      maxWidth: 59,
      disabled: ({ original }) => this.isBulkMode() || this.props.me.id === original.id,
      Cell: ({ original }) => (
        <DeleteCell onClick={() => this.onToggleDeleteUserModal(original)} />
      ),
    },
  ]

  private selectedUsers = (): IUser[] =>
    this.props.organizationUsers.filter(ou => this.state.selectedUsers.includes(ou.id))

  private selectedUser = (): IUser => this.selectedUsers()[0]

  private confirmDeleteUser = () => {
    this.props.deleteUser(this.selectedUser())
    this.onToggleDeleteUserModal()
  }

  private onToggleUserModal = (selectedUser?: IUser) => {
    this.setState(prev => ({
      isUserModalOpen: !prev.isUserModalOpen,
      selectedUsers: selectedUser ? [selectedUser.id] : [],
    }))
  }

  private onSortedChange = (sorting: SortingRule[]) => {
    if (!sorting.length) {
      return this.setState({ sortedColumn: '', desc: true })
    }
    const { id, desc } = sorting[0]
    this.setState({ sortedColumn: id, desc })
  }

  private onChangeStatus = (u: IUser, isActive: boolean) => {
    if (this.mode() === Mode.Single) {
      if (u.settings.isActive !== isActive) {
        this.props.updateUserStatus({
          ...u,
          settings: { ...u.settings, isActive },
        })
      }
    }
  }

  private onChangeMigratedStatus = (u: IUser, isMigrated: boolean) => {
    if (this.mode() === Mode.Single) {
      if (u.settings.isMigrated !== isMigrated) {
        this.props.updateUserMigrationStatus({
          ...u,
          settings: { ...u.settings, isMigrated },
        })
      }
    }
  }

  private onChangeApiStatus = (u: IUser, apiAccess: boolean) => {
    if (this.mode() === Mode.Single) {
      if (u.settings.apiAccess !== apiAccess) {
        this.props.updateUserApiStatus({
          ...u,
          settings: { ...u.settings, apiAccess },
        })
      }
    }
  }

  private onChangeRole = (u: IUser, role: Role) => {
    if (this.mode() === Mode.Single && u.role !== role) {
      this.props.updateUserRole({ ...u, role })
    }
  }

  private getColumns = (): Column[] => {
    const hideHeader = this.mode() === Mode.Bulk

    return this.COLUMNS.map(col => {
      let mCol = { ...col }

      if (hideHeader) {
        mCol = {
          ...mCol,
          Header: null,
        }
      } else if (col.sortable) {
        mCol = {
          ...mCol,
          Header: (
            <HeaderCell
              isSorting={this.state.sortedColumn === (col.id ?? col.accessor)}
              text={col.Header as string}
              desc={this.state.desc}
            />
          ),
          sortable: true,
          sortMethod: (a, b) => {
            if (typeof a === 'string') {
              return a.localeCompare(b, 'en-US', { numeric: true })
            }
            return a === b ? 0 : a < b ? -1 : 1
          },
        }
      }

      let cellRenderer = mCol.Cell

      if (col.disabled) {
        cellRenderer = this.disabledCellRenderer(cellRenderer, col.disabled)
      }

      return { ...mCol, Cell: cellRenderer }
    })
  }

  private isBulkMode = () => this.mode() === Mode.Bulk

  private disabledCellRenderer =
    (
      originalRenderer: TableCellRenderer,
      disabledResolver: (data?: CellInfo) => boolean
    ) =>
    (data: CellInfo, column: any) => {
      const Base =
        typeof originalRenderer === 'function'
          ? originalRenderer(data, column)
          : originalRenderer

      if (disabledResolver(data)) {
        return <Fade>{Base}</Fade>
      }
      return Base
    }

  private onToggleDeleteUserModal = (user?: IUser) => {
    if (this.mode() === Mode.Single) {
      this.setState(() => ({
        isDeleteUserModalOpen: !this.state.isDeleteUserModalOpen,
        selectedUsers: user ? [user.id] : [],
      }))
    }
  }

  private onToggleCreateUserModal = () => {
    this.setState(prev => ({
      isCreateUserModalOpen: !prev.isCreateUserModalOpen,
    }))
  }

  private isUserSelected = ({ id }: IUser): boolean =>
    this.state.selectedUsers.includes(id)

  private mode = (): Mode => {
    return this.state.selectedUsers.length > 1 ? Mode.Bulk : Mode.Single
  }

  private isUsersLimitReached = (): boolean => {
    return this.props.organizationUsers.length >= this.props.organization.usersLimit
  }

  private allSelected = () => {
    const allUsersExceptMe = this.props.organizationUsers.filter(
      user => user.id !== this.props.me.id
    )
    if (allUsersExceptMe.length === 0) {
      return false
    }
    return allUsersExceptMe.length === this.state.selectedUsers.length
  }

  private onToggleCheckAll = (status: boolean) => {
    if (status) {
      this.setState({
        selectedUsers: this.props.organizationUsers.reduce((acc, user) => {
          if (user.id !== this.props.me.id) {
            acc.push(user.id)
          }
          return acc
        }, []),
      })
    } else {
      this.setState({ selectedUsers: [] })
    }
  }

  private findSelectedIndex = (user: IUser) =>
    this.props.organizationUsers.findIndex(u => u.id === user.id)

  private handleNotShiftCheckboxChange = (checked: boolean, user: IUser) => {
    if (this.props.me.id === user.id) {
      return
    }

    if (checked) {
      this.setState(prevState => ({
        lastSelectedIndex: this.findSelectedIndex(user),
        selectedUsers: [...prevState.selectedUsers, user.id],
      }))
    } else {
      this.setState(prevState => ({
        lastSelectedIndex: -1,
        selectedUsers: prevState.selectedUsers.filter(id => id !== user.id),
      }))
    }
  }

  private handleShiftCheckboxChange = (lastSelectedIndex: number, user: IUser) => {
    const index = this.findSelectedIndex(user)
    const toSelect = this.props.organizationUsers
      .slice(Math.min(lastSelectedIndex, index), Math.max(lastSelectedIndex, index) + 1)
      .reduce((acc, u) => {
        if (u.id !== this.props.me.id) {
          acc.push(u.id)
        }
        return acc
      }, [])

    this.setState(prev => ({
      selectedUsers: [...prev.selectedUsers, ...toSelect].filter(
        (v, i, a) => a.findIndex(t => t === v) === i
      ),
    }))
  }

  private handleCheckboxRawChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    user: IUser
  ) => {
    const withShiftKey = (e.nativeEvent as MouseEvent)?.shiftKey
    const lastSelectedIndex = this.state.lastSelectedIndex
    const checked = e.target.checked

    if (withShiftKey && lastSelectedIndex > -1 && checked) {
      this.handleShiftCheckboxChange(lastSelectedIndex, user)
    } else {
      this.handleNotShiftCheckboxChange(checked, user)
    }
  }

  private onSelectedUsersRoleChange = (role: Role) => {
    const users = this.selectedUsers()
    this.props.updateUsersRole(users.map(user => ({ ...user, role })))
  }

  private onSelectedUsersStatusChange = (isActive: boolean) => {
    const users = this.selectedUsers()
    this.props.updateUsersStatus(
      users.map(user => ({ ...user, settings: { ...user.settings, isActive } }))
    )
  }

  private onSelectedUsersApiStatusChange = (apiAccess: boolean) => {
    const users = this.selectedUsers()
    this.props.updateUsersApiStatus(
      users.map(user => ({
        ...user,
        settings: { ...user.settings, apiAccess },
      }))
    )
  }

  private onSelectedUsersDelete = () => {
    const users = this.selectedUsers()
    this.props.deleteUsers(users)

    const selectedUsers = this.state.selectedUsers.filter(
      selectedUserId => !users.map(u => u.id).includes(selectedUserId)
    )

    this.setState({ selectedUsers })
  }

  private usersFileOk = (data: string[][]) => {
    const importedUsers = extractUsersData(data)
    if (!importedUsers) {
      this.onToggleImportError()
      return
    }
    this.setState({ importedUsers }, () => this.onToggleCreateUserModal())
  }

  private usersFilePicked = (file: File) => {
    Papa.parse(file, {
      skipEmptyLines: true,
      complete: (results: Papa.ParseResult<string[]>) => {
        results.errors.length === 0
          ? this.usersFileOk(results.data)
          : this.onToggleImportError()
      },
    })
  }

  private onToggleImportError = () => {
    this.setState(prev => ({
      isFileErrorModalOpen: !prev.isFileErrorModalOpen,
    }))
  }

  render() {
    const { block, element } = bindBem('Users')
    const { organization, organizationUsers, me, resendInvite, openToast } = this.props

    return (
      <div className={block()}>
        <div className={element('Header')}>
          <div>
            <h1>{ADMIN.USERS.TITLE}</h1>
            <h3>{organizationUsers.length} users</h3>
          </div>

          <div className="Buttons">
            {this.isUsersLimitReached() && (
              <div className={element('Error')}>
                {ADMIN.USERS.USER_LIMIT_ERROR.map((e, i) => (
                  <div key={`error_${i}`}>{e}</div>
                ))}
              </div>
            )}

            <Button
              className={element('AddButton')}
              disabled={this.isUsersLimitReached()}
              onClick={() =>
                this.setState({ importedUsers: undefined }, this.onToggleCreateUserModal)
              }
              text={ADMIN.USERS.ADD_USER}
              style="light"
              size="small"
            />

            <FilePickerButton
              onFilePicked={this.usersFilePicked}
              accept=".csv"
              className={element('ImportButton')}
              disabled={this.isUsersLimitReached()}
              text={ADMIN.USERS.IMPORT}
              style="light"
              size="small"
            />
          </div>
        </div>

        {this.mode() === Mode.Bulk && (
          <div className={element('BulkToolbar')}>
            <BulkUserToolbar
              me={me}
              users={organizationUsers}
              selectedUsers={this.state.selectedUsers}
              onToggleCheck={status => this.onToggleCheckAll(status)}
              selectedAll={this.allSelected()}
              onUsersRoleChange={this.onSelectedUsersRoleChange}
              onUsersStatusChange={this.onSelectedUsersStatusChange}
              onUsersApiStatusChange={this.onSelectedUsersApiStatusChange}
              onUsersDelete={this.onSelectedUsersDelete}
            />
          </div>
        )}
        <ReactTable
          className={element('Table')}
          data={organizationUsers}
          columns={this.getColumns()}
          onSortedChange={this.onSortedChange}
          sorted={[{ id: this.state.sortedColumn, desc: this.state.desc }]}
          showPagination={false}
          sortable={false}
          resizable={false}
          pageSize={organizationUsers.length}
        />
        <Modal
          isOpen={this.state.isCreateUserModalOpen}
          onClose={() => this.onToggleCreateUserModal()}
        >
          <CreateUsersForm
            users={this.state.importedUsers}
            organizationId={organization.id}
            onSave={(users: IUser[]) => this.props.createUsers(users)}
            onClose={() => this.onToggleCreateUserModal()}
            isSuperAdmin={isHaverAdmin(me.role)}
            limit={
              this.props.organization.usersLimit - this.props.organizationUsers.length
            }
          />
        </Modal>
        <Modal
          isOpen={this.state.isUserModalOpen}
          onClose={() => this.onToggleUserModal()}
        >
          <UserForm
            organizationId={organization.id}
            user={this.selectedUser()}
            onSave={!this.selectedUser() ? this.props.createUser : this.props.updateUser}
            onClose={() => this.onToggleUserModal()}
            deleteUsers={this.props.deleteUsers}
            isSuperAdmin={isHaverAdmin(me.role)}
            resendInvite={resendInvite}
            openToast={openToast}
          />
        </Modal>
        <Modal
          isOpen={this.state.isDeleteUserModalOpen}
          onClose={() => this.onToggleDeleteUserModal()}
        >
          <RemoveUserConfirmation
            onClose={() => this.onToggleDeleteUserModal()}
            callback={this.confirmDeleteUser}
            obj={this.selectedUser()}
          />
        </Modal>
        <Modal
          isOpen={this.state.isFileErrorModalOpen}
          onClose={this.onToggleImportError}
        >
          <BadImportFile onClose={this.onToggleImportError} />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state: IRootState, { organization }: IProps): IStateProps => {
  const { user: me } = state.user
  const { users } = state.admin
  const organizationUsers = (users || []).filter(
    u => organization && u.organizationId === organization.id
  )

  return {
    me,
    organizationUsers,
  }
}

const mapDispatchToProps = (dispatch: TDispatch): IActionProps => ({
  deleteUser: (user: IUser) => dispatch(admin.deleteUser(user)),
  createUser: (user: IUser) => dispatch(admin.createUser(user)),
  updateUser: (user: IUser) => dispatch(admin.updateUser(user)),
  updateUserStatus: (user: IUser) => dispatch(admin.updateUserStatus(user)),
  updateUserMigrationStatus: (user: IUser) =>
    dispatch(admin.updateUserMigrationStatus(user)),
  updateUserApiStatus: (user: IUser) => dispatch(admin.updateUserApiStatus(user)),
  updateUserRole: (user: IUser) => dispatch(admin.updateUserRole(user)),
  updateUsersStatus: (users: IUser[]) => dispatch(admin.updateUsersStatus(users)),
  updateUsersApiStatus: (users: IUser[]) => dispatch(admin.updateUsersApiStatus(users)),
  updateUsersRole: (users: IUser[]) => dispatch(admin.updateUsersRole(users)),
  deleteUsers: (users: IUser[]) => dispatch(admin.deleteUsers(users)),
  createUsers: (users: IUser[]) => dispatch(admin.createUsers(users)),
  resendInvite: (user: IUser) => dispatch(resendInviteAction(user)),
  openToast: (toast: Partial<IToast>) => dispatch(openToastAction(toast)),
})

export default Sentry.withProfiler(connect(mapStateToProps, mapDispatchToProps)(Users))
