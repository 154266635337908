import * as React from 'react'

import { Options } from './Sidebar/Options'

interface IProps {
  selectedOrganizationId?: number
  organizations: IOrganization[]
  onSelect: (value: IOrganization) => void
}

interface IState {
  selectedValue: number
  options: string[]
}

export class OrganizationSelect extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      options: props.organizations.map(({ name, accountNumber }) =>
        accountNumber ? `${name} (${accountNumber})` : name
      ),
      selectedValue: props.organizations.findIndex(
        ({ id }) => id === props.selectedOrganizationId
      ),
    }
  }

  handleSelect(selectedValue: number) {
    this.setState({ selectedValue }, () =>
      this.props.onSelect(this.props.organizations[selectedValue])
    )
  }

  render() {
    const { selectedValue, options } = this.state

    return (
      <div className="OrganizationSelect">
        <Options
          options={options}
          selectedOption={selectedValue}
          setSeriesOptions={index => this.handleSelect(index)}
        />
      </div>
    )
  }
}
