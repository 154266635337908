import * as React from 'react'
import * as FauxDOM from 'react-faux-dom'
import * as d3 from 'd3'
import { renderBars, XScale, YScale } from '../../services/hd3'
import { COLORS, HIGHLIGHT_COLORS } from '../../services/series'

export interface IProps {
  series: IDataSeries
  xScale: XScale
  yScale: YScale
  isShaded: boolean
  totalCount: number
  barIndex: number
  selected: number
}

const Bars = ({
  series,
  xScale,
  yScale,
  isShaded,
  totalCount,
  barIndex,
  selected,
}: IProps) => {
  const svg = FauxDOM.createElement('g')
  const selection = d3.select(svg)
  const richDataPoints: RichDataPoint[] = React.useMemo(
    () =>
      series.dataPoints.map(p => ({
        ...p,
        x: xScale(p.date),
        y: yScale(p.value),
      })),
    [series, xScale, yScale]
  )
  renderBars(
    selection,
    yScale,
    series,
    selected,
    COLORS[series.colorIndex],
    HIGHLIGHT_COLORS[series.colorIndex],
    isShaded,
    richDataPoints,
    totalCount,
    barIndex
  )
  return <>{React.Children.map(svg.toReact(), c => c.props.children)}</>
}

export default React.memo(Bars)
