import * as React from 'react'

import { bindBem } from '../../bem'
import { SwitchButton } from '../SwitchButton'

import arrow from '../../static/slim-arrow.svg'

import './AccordionGroup.scss'

interface IProps {
  defaultOpen?: boolean
  title: string
  children?: React.ReactNode
}

interface IState {
  expanded: boolean
}

export class AccordionGroup extends React.Component<IProps, IState> {
  state = {
    expanded: this.props.defaultOpen,
  }
  render() {
    const { children, title } = this.props
    const { expanded } = this.state
    const { block, element } = bindBem('AccordionGroup')
    return (
      <div className={block({ expanded })}>
        <div
          className={element('Top')}
          onClick={() => this.setState(() => ({ expanded: !expanded }))}
        >
          <div className={element('Title')}> {title}</div>
          <img className={element('Arrow')} src={arrow} />
        </div>
        {expanded && children}
      </div>
    )
  }
}

interface ISwitchGroupProps {
  isOpen?: boolean
  title: string
  toggleOpen?: (isOpen: boolean) => void
  disabled?: boolean
}

export const AccordionSwitchGroup: React.FC<ISwitchGroupProps> = props => {
  const { children, title, isOpen, toggleOpen, disabled } = props
  const { block, element } = bindBem('SwitchAccordionGroup')
  return (
    <div className={block({ expanded: isOpen, disabled })}>
      <div
        className={element('Top')}
        onClick={toggleOpen ? () => toggleOpen(!isOpen) : undefined}
      >
        <div className={element('Title')}>{title}</div>
        <SwitchButton isOn={isOpen} text="" />
      </div>
      {isOpen && children}
    </div>
  )
}
