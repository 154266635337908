// eslint-disable max-classes-per-file

const PASSWORD_SPECIAL_CHARACTERS = '$&+,:;=?@#|<>.^*()%!-[]'

const GENERAL_PASSWORD_PATTERNS: PatternType[] = [
  [/.{8,}/g, 'Password should be at least 8 characters long'],
  [/[0-9]+/g, 'Password should contain at least 1 digit'],
  [
    /[$&+,:;=?@#|<>.^*()[\]%!-]+/g,
    `Password should contain at least 1 special character (${PASSWORD_SPECIAL_CHARACTERS})`,
  ],
  [/[A-Z]+/g, 'Password should contain at least 1 capital letter'],
  [/[a-z]+/g, 'Password should contain at least 1 lower case letter'],
]

type PatternType = [RegExp, string]

class PatternValidator {
  patterns: PatternType[]

  constructor(patterns: PatternType[]) {
    this.patterns = patterns
  }

  validate(subject: string) {
    const errors = this.patterns.reduce((agg: string[], [pattern, message]) => {
      if (subject.search(pattern) === -1) {
        agg.push(message)
      }
      return agg
    }, [])

    if (errors.length > 0) {
      return errors
    }
  }
}

export class PasswordValidator extends PatternValidator {
  static general() {
    return new PasswordValidator(GENERAL_PASSWORD_PATTERNS)
  }
}
