import { TokenStatus } from 'components/Admin/utils'
import { format as formatDateTime, isPast as isPastDateTime, parseISO } from 'date-fns'

import { isEmailValid } from '../../../utils'

export const extractUsersData = (data: string[][]): Partial<IUser>[] => {
  let indexes
  try {
    indexes = findUsefullColumnsIndexes(data)
  } catch (e) {
    return undefined
  }

  const users: Partial<IUser>[] = []
  const {
    columnsIndexes: [usernameIndex, emailIndex],
    startRowIndex,
  } = indexes

  data.forEach((row, rowIndex) => {
    if (rowIndex >= startRowIndex) {
      users.push({ email: row[emailIndex], username: row[usernameIndex] })
    }
  })

  return users
}

export const findUsefullColumnsIndexes = (
  data: string[][]
): { columnsIndexes: [number, number]; startRowIndex: number } => {
  let usernameIndex = -1
  let emailIndex = -1
  let startRowIndex = 0

  const firstRow = data[0]
  const secondRow = data[1]

  if (!firstRow) {
    throw Error('No first row given')
  }
  if (firstRow.length < 2) {
    throw Error('At least two columns needed')
  }

  // First row (may be header)
  firstRow.forEach((cell, index) => {
    if (isEmailValid(cell)) {
      emailIndex = index
    }
  })

  if (emailIndex === -1 && secondRow) {
    startRowIndex = 1
    secondRow.forEach((cell, index) => {
      if (isEmailValid(cell)) {
        emailIndex = index
      }
    })
  }

  if (emailIndex === -1) {
    throw Error('No column with emails found')
  }

  usernameIndex = emailIndex === 0 ? 1 : emailIndex - 1

  return { columnsIndexes: [usernameIndex, emailIndex], startRowIndex }
}

export const formatISO = (
  iso: string,
  { empty, format }: { empty: string; format: string } = {
    empty: '--',
    format: 'MM/dd/yyyy',
  }
) => {
  if (!iso) {
    return empty
  }
  return formatDateTime(parseISO(iso), format)
}

export const formatISOUTC = (
  iso: string,
  { empty, format }: { empty: string; format: string } = {
    empty: '--',
    format: 'MM/dd/yyyy',
  }
) => {
  if (!iso) {
    return empty
  }
  const date = parseISO(iso)
  const dateUTC = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )
  return formatDateTime(dateUTC, format)
}

export const isPastISO = (iso: string) => {
  return isPastDateTime(parseISO(iso))
}

export const isTokenDeactivated = (token: IToken): boolean => {
  return token.revokedAt && isPastISO(token.revokedAt)
}

export const getTokenStatus = (token: IToken): TokenStatus => {
  if (isTokenDeactivated(token)) {
    return TokenStatus.DEACTIVATED
  } else if (isPastISO(token.expiresAt)) {
    return TokenStatus.EXPIRED
  }

  return TokenStatus.ACTIVE
}
