import * as React from 'react'
import { connect } from 'react-redux'
import fastDeepEqual from 'fast-deep-equal'

import { IRootState, TDispatch } from 'store'
import { bindBem } from 'bem'
import AdminHeader from 'components/Admin/AdminHeader'
import { Button } from 'components/Button'
import * as admin from 'store/Admin'
import { ADMIN } from 'messages'
import MenuColumn from './MenuColumn'
import {
  convertMenuEntriesToTree,
  convertTreeToMenuEntries,
} from './services/menu.service'
import { AddNewMenuColumn } from 'containers/MenusAdmin/AddNewMenuColumn'
import { Modal } from 'components/Modal'
import { AddNewMenuModal } from 'containers/MenusAdmin/AddNewMenuModal'
import { ITree } from 'containers/MenusAdmin/services/menu.types'

import './MenusAdmin.scss'

export interface IStateProps {
  me: IUser
  menus: IMenuLayout[]
  databases: IDatabase[]
}

export interface IActionProps {
  saveMenus: (menus: admin.MenuDetailsPatch[], me: IUser) => Promise<void>
  createMenu: (displayName: string, copyFrom: IMenuLayout) => Promise<void>
}

export type MenuTreeData = {
  menu: IMenuLayout
  hasChanges: boolean
  menuTree: ITree
}

export const MenusAdmin: React.FC<IStateProps & IActionProps> = props => {
  const { me, menus, databases, saveMenus, createMenu } = props
  const { block, element } = bindBem('MenusAdmin')
  const [isSaving, setSaving] = React.useState(false)
  const [addMenuModalOpen, setAddMenuModalOpen] = React.useState(false)
  const [menusTreeData, setMenusTreeData] = React.useState<MenuTreeData[]>([])

  // ref for accessing saved menus in useEffect without triggering callback before actual save
  const savedMenusRef = React.useRef<admin.MenuDetailsPatch[]>([])

  React.useEffect(() => {
    if (!menus.length) {
      return
    }
    setMenusTreeData(currentMenuTreeData =>
      menus.map(menu => {
        const savedMenu = savedMenusRef.current.find(saved => saved.name === menu.name)
        let newTree = currentMenuTreeData.find(current => current.menu.name === menu.name)
          ?.menuTree
        if (!newTree || (savedMenu && !fastDeepEqual(savedMenu.layout, menu.layout))) {
          newTree = convertMenuEntriesToTree(menu)
        }
        return {
          menu,
          menuTree: newTree,
          hasChanges: false,
        }
      })
    )
  }, [menus])

  const hasAnyChanges = React.useMemo(
    () => menusTreeData.some(menuTreeData => menuTreeData.hasChanges),
    [menusTreeData]
  )

  const onChange = React.useCallback((menuName: string, newTreeData: ITree) => {
    setMenusTreeData(currentMenuTreeData =>
      currentMenuTreeData.map(current => {
        if (current.menu.name === menuName) {
          return {
            ...current,
            menuTree: newTreeData,
            hasChanges: true,
          }
        }
        return current
      })
    )
  }, [])

  const openMenuModal = React.useCallback(() => {
    setAddMenuModalOpen(true)
  }, [])

  const closeAddMenuModal = React.useCallback(() => {
    setAddMenuModalOpen(false)
  }, [])

  const addMenu = React.useCallback(
    (name: string, copyFrom: string) => {
      createMenu(
        name,
        menus.find(menu => menu.name === copyFrom)
      ).then(() => {
        closeAddMenuModal()
      })
    },
    [menus]
  )

  const save = React.useCallback(() => {
    setSaving(true)
    const menusToSave: admin.MenuDetailsPatch[] = menusTreeData.reduce(
      (toSave, menuData) => {
        if (menuData.hasChanges) {
          toSave.push({
            name: menuData.menu.name,
            version: menuData.menu.version,
            layout: convertTreeToMenuEntries(menuData.menuTree),
          })
        }
        return toSave
      },
      []
    )
    savedMenusRef.current = menusToSave
    saveMenus(menusToSave, me).then(() => {
      setSaving(false)
    })
    setMenusTreeData(currentMenuTreeData =>
      currentMenuTreeData.map(current => ({ ...current, hasChanges: false }))
    )
  }, [menusTreeData])

  return (
    <div className={block()}>
      <AdminHeader />
      <div className={element('SaveWrapper')}>
        <Button
          className={element('Save')}
          onClick={save}
          disabled={!hasAnyChanges || isSaving}
          text={isSaving ? ADMIN.MENUS.SAVING : ADMIN.MENUS.SAVE}
          size="medium"
          style="dark"
        />
      </div>
      <div className={element('Content')}>
        {menusTreeData.map(menuTreeData => (
          <MenuColumn
            key={menuTreeData.menu.name}
            menu={menuTreeData.menu}
            allMenus={menus}
            databases={databases}
            treeData={menuTreeData.menuTree}
            onChange={onChange}
          />
        ))}
        <AddNewMenuColumn addMenu={openMenuModal} />
      </div>
      <Modal isOpen={addMenuModalOpen} onClose={closeAddMenuModal}>
        <AddNewMenuModal menus={menus} addMenu={addMenu} onClose={closeAddMenuModal} />
      </Modal>
    </div>
  )
}

const mapStateToProps = (state: IRootState): IStateProps => {
  const { menus, databases } = state.admin
  const { user: me } = state.user
  return {
    me,
    databases,
    menus: menus as IMenuLayout[],
  }
}

const mapDispatchToProps = (dispatch: TDispatch): IActionProps => ({
  saveMenus: (menus: admin.MenuDetailsPatch[], me: IUser) =>
    dispatch(admin.saveMenus(menus, me)),
  createMenu: (displayName: string, copyFrom: IMenuLayout) =>
    dispatch(admin.createMenu(displayName, copyFrom)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MenusAdmin)
