import * as menus from '../api2/menu'
import { createAction, ActionType, getType } from 'typesafe-actions'
import { TDispatch } from '.'

export const ACTIONS = {
  setMenu: createAction('SET_MENU', resolve => (menu: IMenuLayout) => resolve(menu)),
}

export type State = {
  entry: IMenuLayout
}

const INITIAL_STATE = (): State => ({
  entry: null,
})

export type MenusActions = ActionType<(typeof ACTIONS)[keyof typeof ACTIONS]>

export function reducer(state = INITIAL_STATE(), action: MenusActions): State {
  switch (action.type) {
    case getType(ACTIONS.setMenu):
      return { ...state, entry: { ...action.payload } }
    default:
      return state
  }
}

export function fetchMenu(user: IUser) {
  return async (dispatch: TDispatch) => {
    const menu = await menus.fetchMenuLayout(user.dbConfigName)
    dispatch(ACTIONS.setMenu(menu))
  }
}
