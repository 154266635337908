// eslint-disable max-len

import * as React from 'react'

export const ICON_HIERARCHY_DARK = (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22">
    <g fill="#B6C2D4" fillRule="evenodd" transform="translate(5 4)">
      <rect width="2" height="5" rx="1" />
      <rect width="2" height="5" y="7" rx="1" />
      <rect width="2" height="5" x="1.5" y="1.5" rx="1" transform="rotate(90 2.5 4)" />
      <rect width="2" height="5" x="1.5" y="8.5" rx="1" transform="rotate(90 2.5 11)" />
      <rect width="7" height="4" x="7" y="2" rx="1" />
      <rect width="7" height="4" x="7" y="9" rx="1" />
    </g>
  </svg>
)

export const ICON_HIERARCHY_LIGHT = (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22">
    <g fill="#007CC3" fillRule="evenodd">
      <rect width="2" height="5" x="5" y="4" rx="1" />
      <rect width="2" height="5" x="5" y="11" rx="1" />
      <rect width="2" height="5" x="6.5" y="5.5" rx="1" transform="rotate(90 7.5 8)" />
      <rect width="2" height="5" x="6.5" y="12.5" rx="1" transform="rotate(90 7.5 15)" />
      <rect width="7" height="4" x="12" y="6" rx="1" />
      <rect width="7" height="4" x="12" y="13" rx="1" />
    </g>
  </svg>
)
