import * as React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { bindBem } from 'bem'
import { Input } from 'components/Input'
import { ADMIN } from 'messages'
import { Dropdown } from 'components/Dropdown'
import { Button } from 'components/Button'

import close from '../../static/close-dialog.svg'

import './AddNewMenuModal.scss'

export interface IProps {
  addMenu: (name: string, copyFrom: string) => void
  onClose: () => void
  menus: IMenuLayout[]
}

export interface Option {
  value: string
  label: string
}

export interface IFormNewMenu {
  name?: string
  copyFrom?: string
}

export const AddNewMenuModal: React.FC<IProps> = ({ addMenu, onClose, menus }) => {
  const { block, element } = bindBem('AddNewMenuModal')
  const [isCreating, setCreating] = React.useState(false)

  const options = React.useMemo(
    () => menus.map(menu => ({ value: menu.name, label: menu.label })),
    [menus]
  )

  const validationSchema = React.useMemo(
    () =>
      Yup.object({
        name: Yup.string()
          .trim()
          .uppercase()
          .required(ADMIN.MENUS.MODALS.ADD_MENU.VALIDATION.NAME_REQUIRED)
          .matches(/^[a-zA-Z0-9\s]+$/, ADMIN.MENUS.MODALS.ADD_MENU.VALIDATION.NAME_FORMAT)
          .notOneOf(
            options.map(option => option.label.toUpperCase()),
            ADMIN.MENUS.MODALS.ADD_MENU.VALIDATION.NAME_EXISTS
          ),
        copyFrom: Yup.string().required(
          ADMIN.MENUS.MODALS.ADD_MENU.VALIDATION.COPY_FROM_REQUIRED
        ),
      }),
    [options]
  )

  const onSubmit = React.useCallback((values: IFormNewMenu) => {
    setCreating(true)
    addMenu(values.name.trim(), values.copyFrom)
  }, [])

  const formik = useFormik<IFormNewMenu>({
    initialValues: {},
    validationSchema,
    onSubmit,
  })

  return (
    <div className={block()}>
      <img className={element('Close')} src={close} alt="close icon" onClick={onClose} />
      <div className={element('Title')}>{ADMIN.MENUS.MODALS.ADD_MENU.TITLE}</div>
      <div className={element('Content')}>
        <div className={element('Name')}>
          <Input
            name="name"
            disabled={isCreating}
            onChange={formik.handleChange}
            onBlur={() => formik.setFieldTouched('name')}
            value={formik.values.name}
            error={formik.touched.name ? formik.errors.name : false}
            placeholder={ADMIN.MENUS.MODALS.ADD_MENU.NAME_PLACEHOLDER}
          />
        </div>
        <Dropdown
          name="copyFrom"
          isDisabled={isCreating}
          value={formik.values.copyFrom}
          onChange={val => formik.setFieldValue('copyFrom', val)}
          options={options}
          onBlur={() => formik.setFieldTouched('copyFrom')}
          error={formik.touched.copyFrom ? formik.errors.copyFrom : false}
          placeholder={ADMIN.MENUS.MODALS.ADD_MENU.COPY_FROM_PLACEHOLDER}
        />
      </div>
      <div className={element('Bottom')}>
        <div>
          <Button
            className={element('Submit')}
            onClick={formik.submitForm}
            disabled={
              isCreating || !formik.dirty || !formik.isValid || formik.isSubmitting
            }
            text={
              isCreating
                ? ADMIN.MENUS.MODALS.ADD_MENU.ADDING
                : ADMIN.MENUS.MODALS.ADD_MENU.ADD
            }
            size="small"
            style="dark"
          />
        </div>
      </div>
    </div>
  )
}
