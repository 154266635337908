import * as React from 'react'

import { bindBem } from '../bem'

import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { ModalLink } from './ModalLink'
import { AvatarMenu } from './AvatarMenu'
import { Button } from './Button'
import { isFunctionApplied } from 'store/Transformations'
import { F_LETTER_ICON, F_LETTER_ICON_LIGHT } from 'components/SVG/FLetter'
import { IRootState } from '../store'

import directory from '../static/sidebar-directory-active.svg'
import sidebarSeries from '../static/sidebar-group.svg'

import './MobileHeader.scss'

interface IProps {
  isPhone?: boolean
}

interface IStateProps {
  hasFunction: boolean
  hasVariables: boolean
}

export const MobileHeader: React.FC<IProps & IStateProps> = ({
  hasFunction,
  hasVariables,
}) => {
  const { block, element } = bindBem('MobileHeader')
  const location = useLocation()
  const isDirectory = location.pathname === '/'
  return (
    <div className={block()}>
      <div className={`${element('Logo')}`} />
      {hasVariables && (
        <ModalLink to="/functions">
          <Button
            onClick={undefined}
            style={hasFunction ? 'default' : 'text'}
            size="small"
            icon={hasFunction ? F_LETTER_ICON_LIGHT : F_LETTER_ICON}
          />
        </ModalLink>
      )}
      {isDirectory ? (
        <Link to="/series">
          <Button
            onClick={undefined}
            icon={<img src={sidebarSeries} />}
            style="text"
            size="small"
          />
        </Link>
      ) : (
        <Link to="/">
          <Button
            onClick={undefined}
            icon={<img src={directory} />}
            style="text"
            size="small"
          />
        </Link>
      )}
      <AvatarMenu small />
    </div>
  )
}

function mapStateToProps(state: IRootState): IStateProps {
  const { variables } = state.series
  const hasVariables = variables.length > 0
  const allHaveFunction = hasVariables && variables.every(v => isFunctionApplied(v))
  return { hasFunction: allHaveFunction, hasVariables }
}

export default connect(mapStateToProps)(MobileHeader)
