// eslint-disable max-lines
import React from 'react'

import { bindBem } from '../../bem'
import { ADVANCED_FUNCTIONS, FUNCTIONS, FUNCTIONS_DIALOG } from '../../messages'
import {
  ADVANCED_FNS,
  ADVANCED_NO_FORM,
  DIFFTYPE_FNS,
  getBasicFuntions,
  getFunctionBase,
} from '../../services/functions'
import { isSeries } from '../../services/series'
import {
  countNestedFunctions,
  findFunction,
  isFunction,
  isNestedFunction,
  isTransformation,
  popTransformation,
  replace,
  stripFunctions,
} from '../../store/Transformations'
import { Button } from '../Button'
import { ActionsBar, Modal } from '../Modal'
import RadioButton from '../RadioButton'
import { RadioGroup } from '../RadioGroup'
import { AccordionSwitchGroup } from './AccordionGroup'
import { AdvancedFunctionForm } from './AdvancedFunctionForms'
import CurrencyDialog from './CurrencyDialog'
import { FunctionBar } from './FunctionBar'
import { Switch } from './Switch'

import './FunctionsDialog.scss'

export interface IProps {
  hasPercentOrNegative: boolean
  canApplySA: boolean
  canApplyConversion: boolean
  series: ISingleSeries
  transformation?: ITransformation
  isGlobal?: boolean
  frequency: Frequency
  onClose: () => void
  onSave: (transformationPartial: ITransformation) => void
  noGrowthRatesAllowed: boolean
}

export interface IState {
  nestingAllowed: boolean
  transformation: ITransformation
  selectedAdvanced?: AdvancedFunction
  isFormOpen: boolean
  currency?: string
  isCurrencyModalOpen: boolean
  isFormValid: boolean
}

export class FunctionsDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    const initialState = this.getInitialState(props)
    const nestingAllowed =
      !!initialState.transformation && isNestedFunction(initialState.transformation)
    this.state = {
      nestingAllowed,
      ...initialState,
    }
  }

  getInitialState = (props: IProps): Omit<IState, 'nestingAllowed'> => {
    let transformation: ITransformation = props.transformation
      ? { ...props.transformation }
      : null
    let selectedAdvanced: AdvancedFunction = null
    if (
      transformation &&
      !this.getBasicFuntions().includes(transformation.func as BasicFunction)
    ) {
      selectedAdvanced = getFunctionBase(transformation.func as AdvancedFunction)
    }
    const fx = findFunction(transformation, 'FX')
    if (fx) {
      transformation = popTransformation(transformation, fx)
    }

    return {
      transformation,
      selectedAdvanced,
      isFormOpen: !!selectedAdvanced,
      isCurrencyModalOpen: false,
      currency: fx ? (fx.args[1] as string) : null,
      isFormValid: true,
    }
  }

  shouldComponentUpdate(_: IProps, prevState: IState) {
    // FIXME: bit of a perf-disaster
    return JSON.stringify(this.state) !== JSON.stringify(prevState)
  }

  render() {
    const { block, element } = bindBem('FunctionsDialog')
    const { transformation, nestingAllowed, currency, isFormValid } = this.state
    let t =
      !transformation || transformation.func === 'LEVEL'
        ? this.getBaseTransformation()
        : transformation
    if (currency) {
      t = { func: 'FX', args: [t, currency] }
    }
    return (
      <div className={block()}>
        <ActionsBar>
          {!this.props.isGlobal && (
            <Switch value={nestingAllowed} onChange={this.onToggleNesting} title="">
              {FUNCTIONS_DIALOG.ALLOW_NESTING}
            </Switch>
          )}
          {nestingAllowed && (
            <Button
              onClick={this.onReset}
              text={FUNCTIONS_DIALOG.RESET}
              style="light"
              size="small"
            />
          )}

          <Button
            onClick={this.onSave}
            text={FUNCTIONS_DIALOG.APPLY}
            style="dark"
            size="small"
            disabled={!isFormValid}
          />
        </ActionsBar>
        <div className={element('Content')}>
          {nestingAllowed && (
            <FunctionBar
              transformation={t}
              onRemoveFunction={this.onRemoveFunction}
              onReplace={this.onReplace}
            />
          )}
          <div className={element('SectionTitle')}>{FUNCTIONS_DIALOG.BASIC}</div>
          {nestingAllowed ? (
            <this.basicFunctionsButtons />
          ) : (
            <this.basicFunctionsRadioGroup />
          )}
          <div className={element('SectionTitle')}>{FUNCTIONS_DIALOG.ADVANCED}</div>
          {nestingAllowed ? (
            <this.advancedFunctionsButtons />
          ) : (
            <this.advancedFunctionsRadioGroup />
          )}
          <div className={element('Switches')}>
            <div className={element('Separator')} />
            <AccordionSwitchGroup
              title={FUNCTIONS_DIALOG.CURRENCY_CONVERSION}
              isOpen={!!this.state.currency}
              toggleOpen={this.toggleCurrency}
              disabled={!this.props.canApplyConversion}
            >
              <div className={element('Currency')} onClick={this.openCurrencyModal}>
                <span>{FUNCTIONS_DIALOG.CURRENCY}</span>
                <span>{this.state.currency}</span>
              </div>
            </AccordionSwitchGroup>
            <Modal
              isOpen={this.state.isCurrencyModalOpen}
              onClose={this.closeCurrencyModal}
            >
              <CurrencyDialog
                onSubmit={this.setCurrency}
                currency={this.state.currency}
              />
            </Modal>
          </div>
        </div>
      </div>
    )
  }

  private getBasicFuntions = () => getBasicFuntions(this.props.noGrowthRatesAllowed)

  private basicFunctionsRadioGroup = () => {
    const fnName = this.state.transformation ? this.state.transformation.func : 'LEVEL'
    return (
      <RadioGroup value={fnName} onChange={this.onFunctionChange}>
        {this.getBasicFuntions().map(f => (
          <RadioButton key={f} value={f} disabled={this.shouldFunctionBeDisabled(f)}>
            {FUNCTIONS[f]}
          </RadioButton>
        ))}
      </RadioGroup>
    )
  }

  private basicFunctionsButtons = () => {
    const { element } = bindBem('FunctionsDialog')
    return (
      <React.Fragment>
        {this.getBasicFuntions()
          .filter(f => f !== 'LEVEL')
          .map(f => (
            <div className={element('FunctionRow')} key={f}>
              <Button
                onClick={() => this.addFunction(f)}
                className={element('AddFnButton')}
                text="+"
                style="dark"
                size="small"
                disabled={this.shouldFunctionBeDisabled(f)}
              />
              <span className={element('FunctionLabel')}>{FUNCTIONS[f]}</span>
            </div>
          ))}
      </React.Fragment>
    )
  }

  private advancedFunctionsRadioGroup = () => {
    const selected = getFunctionBase(this.state.selectedAdvanced)
    const seriesOrTransformation =
      this.state.transformation || this.getBaseTransformation()
    return (
      <RadioGroup value={selected} onChange={this.onSetAdvanced}>
        {ADVANCED_FNS.map(fn => (
          <React.Fragment key={fn}>
            <RadioButton value={fn} disabled={this.shouldFunctionBeDisabled(fn)}>
              {ADVANCED_FUNCTIONS[fn]}
            </RadioButton>
            {this.state.isFormOpen && !this.isRadio(fn) && (
              <AdvancedFunctionForm
                onCancel={this.cancelAdvanced}
                onSave={this.setTransformation}
                function={fn}
                series={seriesOrTransformation}
                title={ADVANCED_FUNCTIONS[fn]}
                frequency={this.props.frequency}
                setFormValidation={this.setFormValidation}
              />
            )}
          </React.Fragment>
        ))}
      </RadioGroup>
    )
  }

  private advancedFunctionsButtons = () => {
    const { element } = bindBem('FunctionsDialog')
    const seriesOrTransformation =
      this.state.transformation || this.getBaseTransformation()
    return (
      <React.Fragment>
        {ADVANCED_FNS.map(f =>
          this.isRadio(f) || !this.state.isFormOpen ? (
            <div className={element('FunctionRow')} key={f}>
              <Button
                onClick={() => this.onSetAdvanced(f)}
                className={element('AddFnButton')}
                text="+"
                style="dark"
                size="small"
                disabled={this.shouldFunctionBeDisabled(f)}
              />
              <span className={element('FunctionLabel')}>{ADVANCED_FUNCTIONS[f]}</span>
            </div>
          ) : (
            <AdvancedFunctionForm
              isNested
              key={f}
              onCancel={this.cancelAdvanced}
              onSave={this.setTransformation}
              function={f}
              series={seriesOrTransformation}
              title={ADVANCED_FUNCTIONS[f]}
              frequency={this.props.frequency}
              setFormValidation={this.setFormValidation}
            />
          )
        )}
      </React.Fragment>
    )
  }

  private getBaseTransformation = (): TransformationOrSeries =>
    this.state.transformation
      ? stripFunctions(this.state.transformation)
      : this.props.series

  private shouldFunctionBeDisabled = (fn: AnyFunction) =>
    (this.props.hasPercentOrNegative && !DIFFTYPE_FNS.includes(fn)) ||
    countNestedFunctions(this.state.transformation || this.props.series) >= 10 ||
    (fn === 'sa' && !this.props.canApplySA)

  private onFunctionChange = (func: AnyFunction) =>
    this.setState({
      transformation: { func, args: [this.getBaseTransformation()] },
      selectedAdvanced: null,
      isFormValid: true,
    })
  private onToggleNesting = (nestingAllowed: boolean) => this.setState({ nestingAllowed })

  private isRadio = (fn: AdvancedFunction) =>
    this.state.selectedAdvanced !== fn || ADVANCED_NO_FORM.includes(fn)

  private onSetAdvanced = (selectedAdvanced: AdvancedFunction) => {
    if (ADVANCED_NO_FORM.includes(selectedAdvanced)) {
      if (this.state.nestingAllowed) {
        this.addFunction(selectedAdvanced)
      } else {
        this.onFunctionChange(selectedAdvanced)
      }
    }
    this.setState({ selectedAdvanced, isFormOpen: true })
  }
  private addFunction = (func: AnyFunction) => {
    const t = this.state.transformation
    if (t?.func === 'LEVEL') {
      return this.onFunctionChange(func)
    }
    const arg = t || this.getBaseTransformation()
    this.setState({
      transformation: { func, args: [arg] },
      selectedAdvanced: null,
      isFormOpen: false,
    })
  }

  private setTransformation = (t: ITransformation) => {
    const { nestingAllowed } = this.state
    const args =
      this.state.transformation && isFunction(this.state.transformation)
        ? [this.state.transformation.args[0], ...t.args.slice(1)]
        : t.args
    const transformation = nestingAllowed ? t : { ...t, args }
    this.setState({ transformation, isFormOpen: !this.state.nestingAllowed })
  }

  private cancelAdvanced = () => {
    this.setState({
      selectedAdvanced: null,
      isFormOpen: false,
      isFormValid: true,
    })
  }

  private onRemoveFunction = (t: ITransformation) => {
    let transformation = popTransformation(this.state.transformation, t)
    transformation = isSeries(transformation) ? null : transformation
    this.setState({ transformation })
  }
  private onReplace = (l: ITransformation, r: ITransformation) => {
    this.setState({ transformation: replace(this.state.transformation, l, r) })
  }

  private onReset = () => {
    this.setState(this.getInitialState(this.props))
  }

  private onSave = () => {
    let t = this.state.transformation || this.getBaseTransformation()
    if (isTransformation(t) && !this.state.nestingAllowed) {
      // This leaves only the innermost function applied
      while (isTransformation(t.args[0])) {
        const topfunc = findFunction(t, t.func)
        t = popTransformation(t, topfunc)
      }
    }
    if (this.state.currency) {
      t = { func: 'FX', args: [t, this.state.currency] }
    }
    if (!isTransformation(t)) {
      t = { func: 'LEVEL', args: [t] }
    }
    this.props.onSave(t)
    this.props.onClose()
  }

  private toggleCurrency = () => {
    if (this.state.currency) {
      this.setState({ currency: null })
    } else {
      this.openCurrencyModal()
    }
  }

  private openCurrencyModal = () => this.setState({ isCurrencyModalOpen: true })
  private closeCurrencyModal = () => this.setState({ isCurrencyModalOpen: false })

  private setCurrency = (currency?: string) =>
    this.setState({ currency, isCurrencyModalOpen: false })

  private setFormValidation = (value: boolean) => {
    this.setState({ isFormValid: value })
  }
}
