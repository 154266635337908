import * as React from 'react'

import { Options } from '../../components/Sidebar/Options'
import { ADMIN } from '../../messages'

export const Role = (props: {
  disabled?: boolean
  value: Role
  options: Role[]
  setOption: (value: Role) => void
  defaultValue?: Role
}) => {
  const { options, defaultValue } = props
  return (
    <Options
      options={options.map(role => ADMIN.USERS.ROLES[role])}
      selectedOption={options.indexOf(props.value)}
      setSeriesOptions={(index: number) => props.setOption(options[index])}
      defaultValue={defaultValue && ADMIN.USERS.ROLES[defaultValue]}
      disabled={Boolean(props.disabled)}
    />
  )
}
