import { getDate, getVariableValue } from './table'

let DATES_LOOKUP: { [key: number]: Date } = {}

let START_DATE: Date = null
let FREQ: Frequency = null

// a very short-lived cache. The difference starts being noticeable when there're more variables
// and this function becomes a hot path
export const getDateAt = (startDate: Date, i: number, frequency: Frequency): Date => {
  if (START_DATE !== startDate || FREQ !== frequency) {
    START_DATE = startDate
    FREQ = frequency
    DATES_LOOKUP = {}
  }
  let date = DATES_LOOKUP[i]
  if (!date) {
    date = getDate(startDate, i, frequency)
    DATES_LOOKUP[i] = date
  }
  return date
}

export const getAdjacentDates = (
  startDate: Date,
  offset: number,
  frequency: Frequency
) => {
  return [
    getDateAt(startDate, offset, frequency),
    getDateAt(startDate, offset + 1, frequency),
  ]
}

export class DataPointLookup {
  readonly startDate: Date
  readonly frequency: Frequency
  private data: IDataPoint[]
  private mappedData: number[]

  constructor(
    startDate: Date,
    datapoints: IDataPoint[],
    frequency: Frequency,
    size = 100
  ) {
    this.startDate = startDate
    this.frequency = frequency
    this.data = datapoints
    this.mappedData = new Array<number>(size).fill(undefined)
  }

  at = (index: number, frequency: Frequency) => {
    if (index >= this.mappedData.length) {
      return null
    }
    const currVal = this.mappedData[index]
    if (currVal !== undefined) {
      return currVal
    }
    const [left, right] = getAdjacentDates(this.startDate, index, this.frequency)
    const value = getVariableValue(left, right, this.data, frequency, this.frequency)
    this.mappedData[index] = value
    return value
  }
}
