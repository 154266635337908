import * as React from 'react'

import { bindBem } from '../../bem'

import './SidebarButton.scss'

interface IProps {
  icon: string
  label: string
  selected: boolean
  disabled: boolean
  onClick: () => void
}

export default (props: IProps) => {
  const { block, element } = bindBem('SidebarButton')
  const { selected, disabled } = props
  return (
    <div className={block({ selected, disabled })} onClick={props.onClick}>
      <img src={props.icon} className={element('Image')} />
      <div className={element('Label')}>{props.label}</div>
    </div>
  )
}
