// eslint-disable max-lines
import { IRootState } from '.'
import { track } from 'analytics'
import last from 'lodash/fp/last'

import { AGGREGATION_FORBIDDEN_TOAST } from '../messages'
import * as functions from '../services/functions'
import {
  AGG_NOT_ALLOWED,
  Freq,
  hasTrendLine,
  isSeries,
  isWeekly,
  transformationTypes,
} from '../services/series'
import { ACTIONS, addSeries, replaceSeries, updateSeries } from './Series'
import { openToast } from './User'

export const findSingleSeries = (
  t: ITransformation | TransformationArg
): ISingleSeries => {
  if (typeof t !== 'object') {
    return null
  }
  if (isSeries(t)) {
    return t
  }
  for (const arg of t.args) {
    const result = findSingleSeries(arg)
    if (result !== null && isSeries(result)) {
      return result
    }
  }
  // [MC] for dev purposes only, to be removed
  // eslint-disable-next-line no-console
  console.warn("couldn't find a series for given transformation")
  return null
}

export const seriesToTransformation = (s: IDataSeries): TransformationOrSeries => {
  if (s.transformation) {
    return { ...s.transformation, lag: s.offset }
  }
  return {
    databaseId: s.databaseId,
    seriesId: s.id,
    lag: s.offset,
  }
}

export const peelTransformation = (series: IDataSeries) => {
  const t = series.transformation
  const transformation =
    t && isTransformation(t.args[0]) ? (t.args[0] as ITransformation) : null
  return { transformation, ...series }
}

export const hasTransformation = (series: IDataSeries, func: functionName) => {
  if (!series.transformation) {
    return false
  }
  return series.transformation.func === func
}

export const findFunction = (
  t: TransformationArg,
  func: functionName
): ITransformation => {
  if (!isFunction(t)) {
    return null
  }
  if (t.func === func) {
    return t
  }
  for (const arg of t.args) {
    const res = findFunction(arg, func)
    if (res !== null) {
      return res
    }
  }
  return null
}

export const hasInterpolation = (series: IDataSeries) => series.isInterpolated

export const allHaveInterpolation = (variables: IDataSeries[]) => {
  variables = variables.filter(s => !hasTrendLine(s))
  return (
    variables.length > 0 && variables.filter(hasInterpolation).length === variables.length
  )
}

export const getAggregation = (s: IDataSeries): Aggregation => {
  const aggregation: Aggregation =
    s.originalFrequency === s.frequency ? null : (s.frequency as Aggregation)
  if (aggregation && isWeekly(aggregation)) {
    return Freq.Mon
  }
  return aggregation
}

export const addInterpolation =
  (series: IDataSeries, index: number, cancellable = true) =>
  async (dispatch: any) => {
    const singleSeries: ISingleSeries = {
      databaseId: series.databaseId,
      seriesId: series.id,
      lag: series.offset,
    }
    const agg = getAggregation(series)
    track('graph', 'interpolation')
    return dispatch(
      updateSeries(series.transformation || singleSeries, index, agg, true, cancellable)
    )
  }

export const removeInterpolation =
  (series: IDataSeries, index: number, cancellable = true) =>
  async (dispatch: any) => {
    const singleSeries: ISingleSeries = {
      databaseId: series.databaseId,
      seriesId: series.id,
      lag: series.offset,
    }
    const agg = getAggregation(series)
    return dispatch(
      updateSeries(series.transformation || singleSeries, index, agg, false, cancellable)
    )
  }

function getSeriesBoundary(series: IDataSeries): Date[] {
  let end: Date = series.startDate
  if (series.dataPoints.length > 0) {
    end = last(series.dataPoints).date
  }
  return [series.startDate, end]
}

export const addTrendLine =
  (series: IDataSeries) => async (dispatch: any, getState: () => IRootState) => {
    const trendlineSeries = await dispatch(
      addSeries(
        applyTransformation(series, { func: 'TRNDLN', args: [] }),
        null,
        null,
        series.isInterpolated
      )
    )

    if (!trendlineSeries) {
      return
    }

    await dispatch(
      ACTIONS.setTrendlineBoundary(trendlineSeries.uuid, getSeriesBoundary(series))
    )
    const { variables } = getState().series
    // find last index for that ID - there could be more than one pending request
    const index =
      variables.length -
      1 -
      Array.from(variables)
        .reverse()
        .findIndex(s => s.id === series.id)
    await dispatch(ACTIONS.setSeriesAxisAssignment(index, series.axisAssignment))
  }

const buildToastForForbiddenSeries = (series: IDataSeries[]): IToast => {
  const inlineSeries = series.map(s => `${s.id}@${s.databaseId}`).join(', ')
  return {
    isOpen: true,
    title: AGGREGATION_FORBIDDEN_TOAST.title,
    subtitle: AGGREGATION_FORBIDDEN_TOAST.description + inlineSeries,
    type: 'error',
  }
}

export const addAggregation =
  (variables: IDataSeries[], aggregation: Aggregation) => async (dispatch: any) => {
    const forbiddenSeries = variables.filter(v => v.aggregationType === AGG_NOT_ALLOWED)
    const allowedSeries = variables.filter(v => v.aggregationType !== AGG_NOT_ALLOWED)
    track('graph', 'aggregation', { type: aggregation })
    await Promise.all(
      allowedSeries.map(async series => {
        const index = variables.indexOf(series)
        await dispatch(
          updateSeries(
            seriesToTransformation(series),
            index,
            aggregation,
            series.isInterpolated,
            false
          )
        )
      })
    )
    if (forbiddenSeries.length) {
      const toast = buildToastForForbiddenSeries(forbiddenSeries)
      dispatch(openToast(toast))
    }
  }

export const addAggregationToSingleSeries =
  (series: IDataSeries, aggregation: Aggregation) =>
  async (dispatch: any, getState: () => IRootState) => {
    if (series.aggregationType === AGG_NOT_ALLOWED) {
      const toast = buildToastForForbiddenSeries([series])
      return dispatch(openToast(toast))
    }

    const index = getState().series.variables.indexOf(series)
    await dispatch(
      updateSeries(
        seriesToTransformation(series),
        index,
        aggregation,
        series.isInterpolated
      )
    )
  }

export const removeAggregationFromSingleSeries =
  (series: IDataSeries, aggregation: Aggregation) =>
  async (dispatch: any, getState: () => IRootState) => {
    const index = getState().series.variables.indexOf(series)
    await dispatch(
      updateSeries(
        seriesToTransformation(series),
        index,
        aggregation,
        series.isInterpolated
      )
    )
  }

export const removeAggregation = (variables: IDataSeries[]) => async (dispatch: any) => {
  await Promise.all(
    variables.map(async (series, index) => {
      await dispatch(
        updateSeries(
          seriesToTransformation(series),
          index,
          null,
          series.isInterpolated,
          false
        )
      )
    })
  )
}

export const hasAggregation = (variable: IDataSeries) =>
  variable.originalFrequency !== variable.frequency

export const removeTransformation =
  (series: IDataSeries, index: number) => async (dispatch: any) => {
    dispatch(ACTIONS.removeTransformation(index))
    const args = series.transformation.args.filter(
      val => isSeries(val) || isTransformation(val)
    ) as TransformationOrSeries[]

    const aggregation = getAggregation(series)
    const interpolation = series.isInterpolated

    Promise.all([
      dispatch(replaceSeries(args[0], index, aggregation, interpolation, false)),
      ...args.slice(1).map((arg, i) => {
        dispatch(
          addSeries(arg, index + i + 1, aggregation, interpolation, false, {}, false)
        )
      }),
    ])
  }

export const isTransformation = (t: TransformationArg): t is ITransformation =>
  t !== null && typeof t === 'object' && (t as ITransformation).func !== undefined

export const popTransformation = (
  root: ITransformation,
  sub: TransformationOrSeries
): ITransformation => {
  if (root === sub) {
    return sub.args[0] as ITransformation
  }
  if (!isTransformation(root)) {
    return root
  }
  return {
    ...root,
    args: [
      popTransformation(root.args[0] as ITransformation, sub),
      ...root.args.slice(1),
    ],
  }
}
export const stripFunctions = (t: TransformationOrSeries): TransformationOrSeries =>
  isFunction(t) ? stripFunctions(t.args[0] as TransformationOrSeries) : t

export const replace = (
  root: ITransformation,
  l: ITransformation,
  r: ITransformation
) => {
  if (l === r) {
    return root
  }
  if (contains(l, r)) {
    return _replace(root, l, r)
  } else if (!contains(r, l)) {
    throw Error('transformations are not in the same tree')
  }
  return _replace(root, r, l)
}

const _replace = (
  root: ITransformation,
  l: ITransformation,
  r: ITransformation
): ITransformation => {
  if (root === l) {
    return {
      ...r,
      args: [_replace(root.args[0] as ITransformation, l, r), ...r.args.slice(1)],
    }
  }
  if (root === r) {
    return { ...l, args: [root.args[0], ...l.args.slice(1)] }
  }
  return {
    ...root,
    args: [_replace(root.args[0] as ITransformation, l, r), ...root.args.slice(1)],
  }
}

export const contains = (root: ITransformation, sub: TransformationOrSeries) => {
  if (root === sub) {
    return true
  }
  for (const arg of root.args) {
    if (arg === sub) {
      return true
    }
    if (isTransformation(arg) && contains(arg, sub)) {
      return true
    }
  }
  return false
}

export const isFunction = (t: TransformationArg): t is ITransformation =>
  isTransformation(t) && functions.isFunction(t.func)

export const isNestedFunction = (t: TransformationOrSeries) =>
  isFunction(t) && isFunction(t.args[0])

export const countNestedFunctions = (t: TransformationArg): number =>
  t !== null && isTransformation(t) && isFunction(t)
    ? 1 + countNestedFunctions(t.args[0])
    : 0

export const countNestedTransformations = (t: TransformationArg): number =>
  t !== null && isTransformation(t) && transformationTypes.includes(t.func)
    ? 1 + countNestedTransformations(t.args[0]) + countNestedTransformations(t.args[1])
    : 0

export const rewriteSeries = (t: ITransformation, s: ISingleSeries): ITransformation => {
  return t.func === undefined
    ? (s as unknown as ITransformation)
    : {
        ...t,
        args: [rewriteSeries(t.args[0] as ITransformation, s), ...t.args.slice(1)],
      }
}

export const applyTransformation = (
  series: IDataSeries,
  partial: ITransformation,
  replaceTransformation = false
) => {
  const root = (!replaceTransformation && series.transformation) || {
    seriesId: series.id,
    databaseId: series.databaseId,
    lag: series.offset,
  }
  return { ...partial, args: [root, ...partial.args] }
}

export const isTransformationApplied = (series: IDataSeries) =>
  series.transformation && transformationTypes.includes(series.transformation.func)

export const isFunctionApplied = (series: IDataSeries) =>
  series.transformation && functions.isFunction(series.transformation.func)

export function updateLag(
  t: TransformationOrSeries,
  lag: number
): TransformationOrSeries {
  if (isFunction(t)) {
    return {
      ...t,
      lag: undefined,
      args: [updateLag(t.args[0] as TransformationOrSeries, lag), ...t.args.slice(1)],
    }
  }
  if (isTransformation(t)) {
    return { ...t, lag }
  }
  return { ...t, lag }
}

export function getOffset(t: TransformationArg): number {
  if (isFunction(t)) {
    return getOffset(t.args[0])
  }
  if (isTransformation(t)) {
    return t.lag || 0
  }
  if (isSeries(t)) {
    return t.lag || 0
  }
  return 0
}
