import classNames from 'classnames'
import { throttle } from 'lodash'
import * as React from 'react'

export interface IProps {
  onDrop: (event: React.MouseEvent<HTMLElement>) => void
  onHover?: () => void
  isDroppable?: boolean
  classNameHover?: string
  classNameDisabled?: string
  fill?: boolean
}

interface IState {
  hover: boolean
}

export class Droppable<P = {}> extends React.Component<IProps & P, IState> {
  static defaultProps: Partial<IProps> = {
    isDroppable: true,
    classNameHover: 'hover',
    classNameDisabled: 'disabled',
  }
  state = { hover: false }

  private onMouseOver = throttle((_e: React.MouseEvent<HTMLDivElement>) => {
    if (this.props.onHover) {
      this.props.onHover()
    }
    this.setState(() => ({ hover: true }))
  }, 16)
  private onMouseOut = throttle(
    (_e: React.MouseEvent<HTMLDivElement>) => this.setState(() => ({ hover: false })),
    16
  )

  render = () => {
    const { onDrop, isDroppable, classNameHover, classNameDisabled, fill } = this.props
    const children = React.Children.only(this.props.children) as React.ReactElement<
      React.AllHTMLAttributes<{}>
    >
    const className = classNames(children.props.className || '', {
      [classNameHover]: isDroppable && this.state.hover,
      [classNameDisabled]: !isDroppable,
      fill,
    })
    return React.cloneElement(children, {
      onMouseEnter: this.onMouseOver,
      onMouseLeave: this.onMouseOut,
      onMouseUp: isDroppable ? onDrop : undefined,
      className,
    })
  }
}
