import * as React from 'react'
import help from '../static/help.svg'
import ReactTooltip from 'react-tooltip'

interface IProps {
  place?: 'top' | 'right' | 'bottom' | 'left'
  type?: 'dark' | 'success' | 'warning' | 'error' | 'info' | 'light'
  effect?: 'float' | 'solid'
  className?: string
  id: string
}

export const HelpTooltip: React.FunctionComponent<IProps> = ({
  className,
  children,
  place = 'right',
  type = 'dark',
  effect = 'solid',
  id,
}) => {
  return (
    <>
      <img src={help} data-tip data-for={id} />
      <ReactTooltip
        id={id}
        place={place}
        effect={effect}
        type={type}
        className={className}
      >
        {children}
      </ReactTooltip>
    </>
  )
}
