import * as React from 'react'

import { bindBem } from '../bem'

import switchOn from '../static/switch-on.svg'
import switchOff from '../static/switch-off.svg'

import './SwitchButton.scss'

interface IProps {
  text: string
  isOn?: boolean
  onClick?: (enabled: boolean) => void
  disabled?: boolean
}

export const SwitchButton = (props: IProps) => {
  const { block } = bindBem('SwitchButton')
  const { isOn, text, disabled } = props
  const onClick = () => props.onClick(isOn)
  return (
    <div
      className={`${block({ isOn, disabled })} row between-xs middle-xs`}
      onClick={!disabled && props.onClick ? onClick : undefined}
    >
      <div>{text}</div>
      <img src={isOn ? switchOn : switchOff} />
    </div>
  )
}
