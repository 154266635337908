// eslint-disable max-lines
import { IBadRequestErrorData } from 'api/config'
import { pick } from 'lodash'
import * as React from 'react'
import { connect } from 'react-redux'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { IRootState } from 'store'
import * as yup from 'yup'

import { IAddOrgForm } from '../../api2/admin'
import { bindBem } from '../../bem'
import { ORGANIZATION_FORM } from '../../messages'
import { ORGANIZATION } from '../../toasts'
import { getOrgSchema } from '../../utils'
import { Button } from '../Button'
import DbConfigSelect from '../DbConfigSelect'
import { Input, Labeled, TextArea } from '../Input'
import { OrganizationSelect } from '../OrganizationSelect'
import { OrganizationTypeSelect } from '../OrganizationTypeSelect'
import { BigSwitch } from './BigSwitch'
import { IpRestrictionStatusTooltip } from './IpRestrictionStatusTooltip'
import { MfaStatusTooltip } from './MfaStatusTooltip'
import { OrganizationTabs } from './TabbedSelectors'

import close from '../../static/close-dialog.svg'

import './AddOrgForm.scss'

export interface IProps {
  onSave: (
    form: IAddOrgForm,
    onError: (errors: IBadRequestErrorData) => void,
    onSuccess: () => void
  ) => void
  onClose: () => void
  databases: { id: string; name: string }[]
  packages: IDBPackage[]
  templateOrg?: IOrganization
  openToast?: (toast: Partial<IToast>) => void
}

interface IStateProps {
  organizations: IOrganization[]
}

interface IState {
  form: IAddOrgForm
  validation: SMap<string>
}

export class AddOrgForm extends React.Component<IProps & IStateProps, IState> {
  constructor(props: IProps & IStateProps) {
    super(props)

    let formDefaults: IAddOrgForm = {
      name: '',
      type: 'company',
      usersLimit: 100,
      accountNumber: '',
      dbConfigName: 'us',
      databases: [],
      previewedDatabases: [],
      packages: [],
      mfaStatus: true,
      cidrs: [],
      isRestricted: false,
      ratelimit: null,
    }

    if (props.templateOrg) {
      formDefaults = this.applyTemplateOrg(formDefaults, props.templateOrg)
    }

    this.state = {
      form: { ...formDefaults },
      validation: {
        name: null,
        usersLimit: null,
        accountNumber: null,
        mfaStatus: null,
        cidrs: null,
        ratelimit: null,
      },
    }
  }

  private applyTemplateOrg = (
    baseOrg: IAddOrgForm,
    templateOrg: IOrganization
  ): IAddOrgForm => {
    return {
      ...baseOrg,
      ...pick(templateOrg, [
        'type',
        'usersLimit',
        'dbConfigName',
        'databases',
        'previewedDatabases',
        'packages',
      ]),
    }
  }

  private schema = getOrgSchema()

  private databasesCount = () => {
    let dbs: string[] = this.state.form.databases

    this.state.form.packages.forEach(packageId => {
      const pkg = this.props.packages.find(p => p.id === packageId)
      dbs = [...dbs, ...pkg.databases]
    })
    return Array.from(new Set(dbs)).length
  }

  render() {
    const {
      name,
      type,
      usersLimit,
      accountNumber,
      dbConfigName,
      databases,
      previewedDatabases,
      packages,
      mfaStatus,
      cidrs,
      isRestricted,
      ratelimit,
    } = this.state.form
    const { validation } = this.state
    const { onClose } = this.props
    const { block, element } = bindBem('AddOrgForm')

    return (
      <div className={block()}>
        <img className={element('Close')} src={close} onClick={onClose} />
        <div className={element('Title')}>{ORGANIZATION_FORM.TITLE}</div>
        <div className={element('Content')}>
          <Tabs>
            <TabList>
              <Tab>{ORGANIZATION_FORM.DATA_TAB}</Tab>
              <Tab>{ORGANIZATION_FORM.DATABASES_TAB}</Tab>
            </TabList>
            <TabPanel className="overflow-tab">
              <Labeled label={ORGANIZATION_FORM.NAME_LABEL} error={validation.name}>
                <Input
                  name="name"
                  onChange={this.onNameChange}
                  value={name}
                  error={!!validation.name}
                  placeholder={ORGANIZATION_FORM.NAME_PLACEHOLDER}
                  focus
                />
              </Labeled>

              <Labeled label={ORGANIZATION_FORM.DUPLICATED_DATA_FROM_LABEL}>
                <OrganizationSelect
                  organizations={this.props.organizations}
                  onSelect={org => this.onTemplateOrgSelected(org)}
                  selectedOrganizationId={this.props.templateOrg?.id}
                />
              </Labeled>

              <Labeled label={ORGANIZATION_FORM.TYPE_LABEL}>
                <OrganizationTypeSelect value={type} onSelect={this.onTypeChange} />
              </Labeled>

              <Labeled
                label={ORGANIZATION_FORM.ACCOUNT_NUMBER_LABEL}
                error={validation.accountNumber}
              >
                <Input
                  name="accountNumber"
                  onChange={this.onAccountNumberChange}
                  value={accountNumber}
                  error={!!validation.accountNumber}
                  placeholder={ORGANIZATION_FORM.ACCOUNT_NUMBER_PLACEHOLDER}
                />
              </Labeled>

              <Labeled
                label={ORGANIZATION_FORM.USER_LIMIT_LABEL}
                error={validation.usersLimit}
              >
                <Input
                  name="usersLimit"
                  type="number"
                  onChange={this.onUserLimitChange}
                  value={usersLimit}
                  error={!!validation.usersLimit}
                  placeholder={ORGANIZATION_FORM.USER_LIMIT_PLACEHOLDER}
                />
              </Labeled>

              <Labeled label={ORGANIZATION_FORM.MENU_LAYOUT}>
                <DbConfigSelect
                  value={dbConfigName}
                  onSelect={this.onDbConfigNameChange}
                />
              </Labeled>

              <Labeled label={ORGANIZATION_FORM.MFA_STATUS_LABEL}>
                <div className="BigSwitchContainer">
                  <BigSwitch value={mfaStatus} onChange={this.onMFAStatusChange} />
                  <MfaStatusTooltip id="add-form-mfastatus" />
                </div>
              </Labeled>

              <Labeled label={ORGANIZATION_FORM.IS_RESTRICTED}>
                <div className="BigSwitchContainer">
                  <BigSwitch value={isRestricted} onChange={this.onIpRestrictionChange} />
                </div>
              </Labeled>

              <Labeled label={ORGANIZATION_FORM.CIDRS} error={validation.cidrs}>
                <div className="FieldContainer">
                  <TextArea
                    name="cidrs"
                    error={!!validation.cidrs}
                    value={this.cidrsToText(cidrs)}
                    placeholder="127.0.0.1/32&#10;8.8.8.8/24"
                    onChange={this.onCidrsChange}
                  />
                  <IpRestrictionStatusTooltip id="add-form-ip-restriction" />
                </div>
              </Labeled>
              <Labeled label={ORGANIZATION_FORM.RATELIMIT} error={validation.ratelimit}>
                <Input
                  name="ratelimit"
                  error={!!validation.ratelimit}
                  value={ratelimit}
                  placeholder={ORGANIZATION_FORM.PLACEHOLDERS.RATELIMIT}
                  onChange={this.onRatelimitChange}
                />
              </Labeled>
            </TabPanel>
            <TabPanel>
              <div className={element('Search')}>
                <this.OrganizationTabsMemoized
                  selectedDatabases={databases}
                  selectedPackages={packages}
                  previewedDatabases={previewedDatabases}
                  databases={this.props.databases}
                  packages={this.props.packages}
                  databasesCount={this.databasesCount()}
                  onDatabasesChange={this.onDatabasesChange}
                  onPackagesChange={this.onPackagesChange}
                  onDatabasesImport={this.onImportedDatabases}
                />
              </div>
            </TabPanel>
          </Tabs>
        </div>
        <Button
          className={element('Submit')}
          onClick={this.onSave}
          disabled={!this.isValid()}
          text={ORGANIZATION_FORM.ADD}
          size="small"
          style="dark"
        />
      </div>
    )
  }

  private OrganizationTabsMemoized = React.memo(OrganizationTabs)

  private cidrsToText = (cidrs: string[]): string => {
    return cidrs.join('\n')
  }

  private onImportedDatabases = (count: number) => {
    this.props.openToast(ORGANIZATION.DATABASES_IMPORTED(count))
  }

  private onTemplateOrgSelected = (org: IOrganization) => {
    this.setState(prevState => {
      return { ...prevState, form: this.applyTemplateOrg(prevState.form, org) }
    })
  }

  private setError = (field: string, error: string) => {
    this.setState(current => ({
      ...current,
      validation: { ...current.validation, [field]: error },
    }))
  }

  private onSaveError = (errors: IBadRequestErrorData) => {
    Object.keys(errors).forEach(key => {
      this.setError(key, errors[key].join(', '))
    })
  }

  private onSaveSuccess = () => {
    this.props.onClose()
  }

  private normalizedFormData = () => {
    const form = this.state.form
    return { ...form, cidrs: form.cidrs.filter(Boolean) }
  }

  private onSave = async () => {
    await this.validate()
    if (!this.isValid()) {
      return
    }
    this.props.onSave(this.normalizedFormData(), this.onSaveError, this.onSaveSuccess)
  }

  private isValid = () => {
    return !(
      this.state.validation.name ||
      this.state.validation.usersLimit ||
      this.state.validation.cidrs
    )
  }

  private onMFAStatusChange = (mfaStatus: boolean) => {
    this.setState(() => ({ form: { ...this.state.form, mfaStatus } }), this.validate)
  }

  private onIpRestrictionChange = (isRestricted: boolean) => {
    this.setState(() => ({ form: { ...this.state.form, isRestricted } }), this.validate)
  }

  private onRatelimitChange = (ratelimit: string) => {
    this.setState(() => ({ form: { ...this.state.form, ratelimit } }), this.validate)
  }

  private onNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.value
    this.setState(() => ({ form: { ...this.state.form, name } }), this.validate)
  }

  private onCidrsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const cidrs = e.currentTarget.value.split('\n')
    this.setState(() => ({ form: { ...this.state.form, cidrs } }), this.validate)
  }

  private onUserLimitChange = (e: React.FormEvent<HTMLInputElement>) => {
    const usersLimit = Number(e.currentTarget.value)
    this.setState(() => ({ form: { ...this.state.form, usersLimit } }), this.validate)
  }

  private onAccountNumberChange = (e: React.FormEvent<HTMLInputElement>) => {
    const accountNumber = e.currentTarget.value
    this.setState(() => ({ form: { ...this.state.form, accountNumber } }), this.validate)
  }

  private onTypeChange = (type: OrganizationType) =>
    this.setState(() => ({ form: { ...this.state.form, type } }))

  private onDbConfigNameChange = (dbConfigName: string) =>
    this.setState(() => ({ form: { ...this.state.form, dbConfigName } }))

  private onDatabasesChange = (databases: string[], previewedDatabases: string[]) => {
    this.setState(() => ({
      form: { ...this.state.form, databases, previewedDatabases },
    }))
  }

  private onPackagesChange = (packages: string[]) =>
    this.setState(() => ({ form: { ...this.state.form, packages } }))

  private validate = async () => {
    try {
      await this.schema.validate(this.state.form, { abortEarly: false })
      this.setState(() => ({ validation: {} }))
    } catch (e) {
      this.handleError(e)
    }
  }

  private handleError = (e: yup.ValidationError) => {
    const validation: SMap<string> = {}
    e.inner.map(err => {
      validation[err.path] = err.errors[0]
    })
    this.setState(() => ({ validation }))
  }
}

const mapStateToProps = (state: IRootState): IStateProps => {
  const { organizations } = state.admin

  return {
    organizations,
  }
}

export default connect(mapStateToProps)(AddOrgForm)
