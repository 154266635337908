import * as React from 'react'

import { bindBem } from '../../bem'
import {
  REMOVE_ORG,
  REMOVE_DATABASE,
  REMOVE_USER,
  REMOVE_PACKAGE,
  REMOVE_USERS,
} from '../../messages'

import { Button } from '../Button'

import close from '../../static/close-dialog.svg'

import './RemoveOrgConfirmation.scss'

interface IProps<T> {
  callback: () => void
  onClose: () => void
  obj: T
}

export const RemoveOrgConfirmation = ({
  callback,
  onClose,
  obj,
}: IProps<IOrganization>) => {
  const { block, element } = bindBem('RemoveOrgConfirmation')
  return (
    <div className={block()}>
      <img className={element('Close')} src={close} onClick={onClose} />
      <div className={element('Title')}>{REMOVE_ORG.TITLE}</div>
      <div className={element('Content')}>
        <p className={element('Question')}>
          {REMOVE_ORG.QUESTION1} <b>{obj.name}</b>{' '}
          {REMOVE_ORG.QUESTION2({ count: obj.usersCount })}
        </p>
        <p className={element('Sub')}>{REMOVE_ORG.INFO}</p>
        <Button
          className={element('Submit')}
          onClick={callback}
          text={REMOVE_ORG.REMOVE}
          size="small"
          style="dark"
        />
      </div>
    </div>
  )
}

export const RemoveDatabaseConfirmation = ({
  callback,
  onClose,
  obj,
}: IProps<IDatabaseDetails>) => {
  const { block, element } = bindBem('RemoveOrgConfirmation')
  return (
    <div className={block()}>
      <img className={element('Close')} src={close} onClick={onClose} />
      <div className={element('Title')}>{REMOVE_DATABASE.TITLE}</div>
      <div className={element('Content')}>
        <p className={element('Question')}>
          {REMOVE_DATABASE.QUESTION1} <b>{obj.name}</b>{' '}
          {REMOVE_DATABASE.QUESTION2({ count: obj.organizationsCount })}
        </p>
        <p className={element('Sub')}>{REMOVE_DATABASE.INFO}</p>
        <Button
          className={element('Submit')}
          onClick={callback}
          text={REMOVE_DATABASE.REMOVE}
          size="small"
          style="dark"
        />
      </div>
    </div>
  )
}

export const RemoveUserConfirmation = ({ callback, onClose, obj }: IProps<IUser>) => {
  const { block, element } = bindBem('RemoveOrgConfirmation')
  return (
    <div className={block()}>
      <img className={element('Close')} src={close} onClick={onClose} />
      <div className={element('Title')}>{REMOVE_USER.TITLE}</div>
      <div className={element('Content')}>
        <p className={element('Question')}>
          {REMOVE_USER.QUESTION1} <b>{obj.username}</b> {REMOVE_USER.QUESTION2}
        </p>
        <p className={element('Sub')}>{REMOVE_USER.INFO}</p>
        <Button
          className={element('Submit')}
          onClick={callback}
          text={REMOVE_USER.REMOVE}
          size="small"
          style="dark"
        />
      </div>
    </div>
  )
}

export const RemovePackageConfirmation = ({
  callback,
  onClose,
  obj,
}: IProps<IDBPackage>) => {
  const { block, element } = bindBem('RemoveOrgConfirmation')
  return (
    <div className={block()}>
      <img className={element('Close')} src={close} onClick={onClose} />
      <div className={element('Title')}>{REMOVE_PACKAGE.TITLE}</div>
      <div className={element('Content')}>
        <p className={element('Question')}>
          {REMOVE_PACKAGE.QUESTION1} <b>{obj.name}</b> {REMOVE_PACKAGE.QUESTION2}
        </p>
        <p className={element('Sub')}>{REMOVE_PACKAGE.INFO}</p>
        <Button
          className={element('Submit')}
          onClick={callback}
          text={REMOVE_PACKAGE.REMOVE}
          size="small"
          style="dark"
        />
      </div>
    </div>
  )
}

export const RemoveUsersConfirmation = ({ callback, onClose, obj }: IProps<IUser[]>) => {
  const { block, element } = bindBem('RemoveOrgConfirmation')
  return (
    <div className={block()}>
      <img className={element('Close')} src={close} onClick={onClose} />
      <div className={element('Title')}>{REMOVE_USERS.TITLE}</div>
      <div className={element('Content')}>
        <p className={element('Question')}>{REMOVE_USERS.QUESTION}</p>

        <div className={element('List')}>
          {obj.map(user => (
            <p className={'ListItem'} key={user.id}>
              <b>{user.username}</b>
            </p>
          ))}
        </div>

        <p className={element('Sub')}>{REMOVE_USERS.INFO}</p>
        <Button
          className={element('Submit')}
          onClick={callback}
          text={REMOVE_USERS.REMOVE}
          size="small"
          style="dark"
        />
      </div>
    </div>
  )
}
