import * as React from 'react'
import { connect } from 'react-redux'

import { bindBem } from '../../bem'
// import { OrganizationTabs } from './TabbedSelectors'
import { dbsCompare } from '../../services/databases'
import { IBadRequestErrorData } from 'api/config'
import { IRootState, TDispatch } from '../../store'
import { patchOrganization as patchOrganizationAction } from '../../store/Admin'
import { txtToList, listToTxt } from './utils'
import PackageCheckbox from './PackageCheckbox'
import ImportExportMenu from './ImportExportMenu'
import { ItemSelector, IItemRendererProps } from './ItemSelector'
import { DB_STATS } from '../../messages'
import { openToast } from '../../store/User'
import { ORGANIZATION } from '../../toasts'
import { isHaverAdmin } from 'services/user'
import { cmp } from 'utils'

import './Databases.scss'

interface IProps {
  organization: IOrganization
}

interface IStateProps {
  me: IUser
  packages: IDBPackage[]
  databases: { id: string; name: string }[]
}

interface IActionProps {
  patchOrganization: (
    id: number,
    org: Partial<IOrganization>,
    onError: (errors: IBadRequestErrorData) => void
  ) => void
  openToast: (toast: Partial<IToast>) => void
}

const Databases = ({
  me,
  organization,
  packages,
  databases,
  patchOrganization,
}: IProps & IStateProps & IActionProps) => {
  const { block } = bindBem('Databases')

  const databasesFromSelectedPackages = () => {
    const pkgDbs: string[] = organization.packages.reduce((dbFromPackages, packageId) => {
      const pkg = packages.find(p => p.id === packageId)
      return pkg ? [...dbFromPackages, ...pkg.databases] : [...dbFromPackages]
    }, [])
    return Array.from(new Set(pkgDbs))
  }

  const databasePackages = React.useMemo<Map<string, IDBPackage[]>>(
    () =>
      new Map<string, IDBPackage[]>(
        databases.map(db => [
          db.id,
          packages.filter(
            pkg =>
              pkg.isEnabled &&
              organization.packages.includes(pkg.id) &&
              pkg.databases.includes(db.id)
          ),
        ])
      ),
    [organization, packages, databases]
  )

  const pkgDatabases = databasesFromSelectedPackages().filter(
    db => !organization.databases.includes(db)
  )

  const stats = isHaverAdmin(me.role)
    ? [
        {
          label: DB_STATS.DIRECT_LABEL,
          value: `${organization.databases.length}`,
        },
        {
          label: DB_STATS.FROM_PACKAGES_LABEL,
          value: `${pkgDatabases.length}`,
        },
      ]
    : null

  const onDatabasesImport = (count: number) => {
    openToast(ORGANIZATION.DATABASES_IMPORTED(count))
  }

  const onDatabasesChange = (newDatabases: string[], previewedDatabases: string[]) => {
    patchOrganization(
      organization.id,
      { databases: newDatabases, previewedDatabases },
      onPatchError
    )
  }

  const exportSelections = () => {
    listToTxt([...organization.databases, ...pkgDatabases.map(db => `${db} [P]`)])
  }

  const importSelections = async (file: File) => {
    const importedDatabases = await txtToList(file)
    const databaseIds = databases.map(database => database.id)
    const filteredToImport = importedDatabases.filter(dbToImport =>
      databaseIds.includes(dbToImport)
    )
    onDatabasesImport(filteredToImport.length)

    const databasesWithImported = Array.from(
      new Set([...organization.databases, ...filteredToImport])
    )
    onDatabasesChange(databasesWithImported, organization.previewedDatabases)
  }

  const onPatchError = (errors: IBadRequestErrorData) => {
    openToast({ title: Object.values(errors).join(', ') })
  }

  const MenuRenderer = React.useMemo(
    () => () => {
      return (
        <ImportExportMenu
          importSelections={importSelections}
          exportSelections={exportSelections}
          disabled={organization.databases.length === 0}
        />
      )
    },
    [organization.databases]
  )

  const isNonInteractive = React.useMemo(() => !isHaverAdmin(me.role), [me])

  const ItemRenderer = React.useMemo(
    () => (props: IItemRendererProps<string>) => {
      const relatedPackages = databasePackages.get(props.item.id)

      return <PackageCheckbox {...props} packages={relatedPackages} />
    },
    [organization.packages, databases]
  )

  const itemCompare = (a: any, b: any) =>
    cmp(
      databasePackages.get(a.id).length ? -1 : 1,
      databasePackages.get(b.id).length ? -1 : 1
    )

  return (
    <div className={block()}>
      <ItemSelector
        stats={stats}
        menuRenderer={MenuRenderer}
        items={databases}
        selectedIds={organization.databases}
        previewedIds={organization.previewedDatabases}
        onChange={onDatabasesChange}
        ItemRenderer={ItemRenderer}
        itemCompare={itemCompare}
        nonInteractive={isNonInteractive}
        toggleAllDisabled={true}
      />
    </div>
  )
}

const mapStateToProps = (state: IRootState): IStateProps => {
  const { user: me } = state.user
  const { packages } = state.admin
  const { dbNames } = state.databases

  const databases = Object.keys(dbNames)
    .sort(dbsCompare)
    .map(k => ({
      id: k,
      name: dbNames[k] ? `${k} ${dbNames[k]}` : k,
    }))

  return {
    me,
    databases,
    packages,
  }
}

const mapDispatchToProps = (dispatch: TDispatch): IActionProps => ({
  patchOrganization: (
    id: number,
    data: Partial<IOrganization>,
    onError: (errors: IBadRequestErrorData) => void
  ) => dispatch(patchOrganizationAction(id, data, onError)),
  openToast: (toast: Partial<IToast>) => dispatch(openToast(toast)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Databases)
