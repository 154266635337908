import * as d3 from 'd3'
import * as React from 'react'
import * as FauxDOM from 'react-faux-dom'

import { XScale, YScale } from '../../services/hd3'
import {
  IDataLabelsContent,
  renderDataLabels,
} from '../../services/hd3-utils/data-labels'
import { COLORS, PALE_COLORS } from '../../services/series'

interface IProps {
  xScale: XScale
  yScale: YScale
  dataPoints: IDataPoint[]
  colorIndex: number
  decimalPrecision: number
  type: DataLabelsType
  width: number
  height: number
}

const DataLabels = (props: IProps) => {
  if (props.type === 'NONE') {
    return null
  }
  const svg = FauxDOM.createElement('g')
  const selection = d3.select(svg)
  const dataPoints = props.type === 'LAST' ? props.dataPoints.slice(-1) : props.dataPoints
  const data: IDataLabelsContent = React.useMemo(
    () => ({
      dataPoints: dataPoints.map(({ date, value }) => ({
        date,
        value,
        x: props.xScale(date),
        y: props.yScale(value),
      })),
      color: COLORS[props.colorIndex],
      paleColor: PALE_COLORS[props.colorIndex],
      decimalPrecision: props.decimalPrecision,
    }),
    [props.dataPoints, props.colorIndex, props.type, props.xScale, props.yScale]
  )
  renderDataLabels(selection, [data], props.type, props.width, props.height)

  return <>{React.Children.map(svg.toReact(), c => c.props.children)}</>
}

export default React.memo(DataLabels)
