import * as React from 'react'
import { bindBem } from '../bem'

import './Fade.scss'

export const Fade: React.FunctionComponent<{}> = props => {
  const { element } = bindBem('Fade')
  return (
    <div className={element('Wrapper')}>
      <div className={element('Parent')}>
        <div className={element('Child')}>{props.children}</div>
      </div>
    </div>
  )
}
