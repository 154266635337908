import * as React from 'react'
import { IStackedConfig } from '../../services/hd3-utils/stacked-bar'
import { getResizeBarPosition, setResizeBarPosition } from '../../services/user'

export interface IProps {
  variables: IDataSeries[]
  offsetSeries: IDataSeries[]
  trendlineBoundaries: TrendlineBoundaries
  startZoomDate?: Date
  endZoomDate: Date
  recessions: IRecession[]
  recessionType: IRecessionType
  settings: ISeriesSettings
  scatterSettings: IScatterSettings
  scale: IScale<IncrementalAxis>
  isEditingTitles: boolean
  isLegendShown: boolean
  stackedConfig: IStackedConfig
  size: {
    width: number
    height: number
  }
  isLoading: boolean
  correlation: ICorrelation
  stats: SMap<number>[]
  activeModal: SeriesModal
}

export interface IResizeProps {
  isTableHidden: boolean
  setTableHidden: (hidden: boolean) => void
  resizeBarPosition: number
  setResizeBarPosition: (pos: number) => void
  isToggleButtonClicked: boolean
  setToggleTable: (toggled: boolean) => void
}

export function withResize<T extends IResizeProps = IResizeProps>(
  Comp: React.ComponentType<T>,
  isTableInitiallyHidden = false
): React.FC<Omit<T, keyof IResizeProps>> {
  return props => {
    const [isTableHidden, setTableHidden] = React.useState(isTableInitiallyHidden)
    const [resizeBarPosition, setResizedBarPosition] = React.useState(
      getResizeBarPosition()
    )
    const [isToggleButtonClicked, setToggleTable] = React.useState(isTableInitiallyHidden)
    const injectedProps: IResizeProps = {
      isTableHidden,
      setTableHidden,
      resizeBarPosition,
      setResizeBarPosition: React.useCallback(
        pos => {
          setResizedBarPosition(pos)
          setResizeBarPosition(pos.toString())
        },
        [resizeBarPosition]
      ),
      isToggleButtonClicked,
      setToggleTable,
    }
    return <Comp {...injectedProps} {...(props as T)} />
  }
}

export type Props = IProps & IActionProps & IResizeProps

export const collapsedTableHeight = 64
export const topBarsOffset = 34

export interface IActionProps {
  onEditTitles: (title: string, names: string[], isLegendShow: boolean) => void
  setNamesModal: (isOpen: boolean) => void
  removeInterpolation: (series: IDataSeries, index: number) => void
  removeTransformation: (series: IDataSeries, index: number) => void
  scrollByPeriod: (period: number) => void
  setModal: (modal: SeriesModal) => void
}
