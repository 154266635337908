import * as React from 'react'
import { bindBem } from 'bem'
import { ADMIN } from 'messages'

import PlusIcon from '../../static/plus.svg'

import './AddNewMenuColumn.scss'

export interface IProps {
  addMenu: () => void
}

export const AddNewMenuColumn: React.FC<IProps> = ({ addMenu }) => {
  const { block, element } = bindBem('AddNewMenuColumn')

  return (
    <div className={block()}>
      <div className={element('Wrapper')} onClick={addMenu}>
        <img src={PlusIcon} alt="plus icon" />
        <span className={element('Title')}>{ADMIN.MENUS.ADD_NEW_TITLE}</span>
      </div>
    </div>
  )
}
