import React from 'react'
import { IFormProps, InnerForm } from './AdvancedFunctionUtils'
import { Formik } from 'formik'
import { Input } from '../Input'
import { bindBem } from '../../bem'
import { AutoSave } from './AutoSave'
import * as Yup from 'yup'
import { Freq } from '../../services/series'
import * as _ from 'lodash'
import { FUNCTIONS_DIALOG } from '../../messages'
import { validators, errorMessages, regExps } from './FnFormUtils'

const { FORMAT_VALIDATION_MESSAGE } = FUNCTIONS_DIALOG

export const getValidationSchema = (frequency: Frequency) => {
  const formatValidators: [RegExp, string][] = [[regExps.year, 'YYYY']]
  const valueValidators: [(value: string) => boolean, string][] = [
    [validators.year(regExps.year), errorMessages.year],
  ]

  if (frequency >= Freq.Quarterly) {
    formatValidators.push([regExps.quarter, 'YYYYQ'])
    valueValidators.push([validators.year(regExps.quarter), errorMessages.year])
    valueValidators.push([validators.quarter(regExps.quarter), errorMessages.quarter])
  }
  if (frequency >= Freq.Monthly) {
    formatValidators.push([regExps.month, 'YYYYMM'])
    valueValidators.push([validators.year(regExps.month), errorMessages.year])
    valueValidators.push([validators.month(regExps.month), errorMessages.month])
  }
  if (frequency >= Freq.Mon) {
    formatValidators.push([regExps.day, 'YYYYMMDD'])
    valueValidators.push([validators.year(regExps.day), errorMessages.year])
    valueValidators.push([validators.month(regExps.day), errorMessages.month])
    valueValidators.push([validators.day(regExps.day), errorMessages.day])
  }

  const [formatRegExps, formats] = _.zip(...formatValidators)
  const validationMessage = FORMAT_VALIDATION_MESSAGE('left', formats as string[])

  let left = Yup.string()
    .required()
    .test({
      name: 'formatValidator',
      test: value =>
        formatRegExps
          .map((regexp: RegExp) => regexp.test(value))
          .some(item => item === true),
      message: validationMessage,
    })

  valueValidators.forEach(
    ([validator, message]) => (left = left.test('match', message, validator))
  )

  const right = Yup.number().min(1).max(32000).required()

  return Yup.object().shape({ left, right })
}

export const FnIndexForm = (props: IFormProps) => {
  const { state, setForm, setFormValidation, isNested } = props
  const left = state.args[0] as number
  const right = state.args[1] as number
  const { block, element } = bindBem('FnIndexForm')
  const initialValues = { left: left !== null ? left : '', right }
  const validationSchema = getValidationSchema(props.frequency)
  let initialErrors = {}
  try {
    validationSchema.validateSync(initialValues, { abortEarly: false })
  } catch (e) {
    const leftError = _.find(e.inner, error => error.path === 'left')
    const rightError = _.find(e.inner, error => error.path === 'right')

    initialErrors = {
      left: leftError?.message,
      right: rightError?.message,
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => setForm({ ...state, args: [values.left, values.right] })}
      validationSchema={validationSchema}
      initialErrors={initialErrors}
      enableReinitialize
    >
      {({ errors, values, submitForm, handleChange }) => (
        <InnerForm
          {...props}
          className={block()}
          onSave={submitForm}
          isValid={_.isEmpty(errors)}
        >
          <div className="row">
            <Input
              value={values.left}
              onChange={handleChange}
              type="number"
              name="left"
              error={errors.left && !!errors.left}
            />
            <span className={element('Equals')}>=</span>
            <Input
              value={values.right}
              onChange={handleChange}
              type="number"
              name="right"
              error={errors.right && !!errors.right}
            />
          </div>

          {errors && (
            <div className="row">
              {errors.left && <div className={element('Error')}>{errors.left}</div>}
              {errors.right && <div className={element('Error')}>{errors.right}</div>}
            </div>
          )}
          {!isNested && (
            <AutoSave
              onSubmit={submitForm}
              values={values}
              isValid={_.isEmpty(errors)}
              callback={() => setFormValidation(_.isEmpty(errors))}
            />
          )}
        </InnerForm>
      )}
    </Formik>
  )
}
