// eslint-disable max-len

import * as React from 'react'

export const ADMIN_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12">
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <rect width="14" height="10" stroke="#FFF" strokeWidth="1.5" rx="5" />
      <circle cx="5" cy="5" r="2" fill="#FFF" fillRule="nonzero" />
    </g>
  </svg>
)

export const USER_PROFILE_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#FFF"
      strokeWidth="1.5"
      transform="translate(1 1)"
    >
      <circle cx="7" cy="7" r="7" />
      <circle cx="7" cy="6.5" r="2.5" />
      <path d="M11.5 11.668A4.969 4.969 0 0 0 7.078 9 4.963 4.963 0 0 0 2.5 12" />
    </g>
  </svg>
)

export const LOGOUT_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15">
    <g fill="none" fillRule="evenodd" transform="translate(0 -1)">
      <rect
        width="10.5"
        height="8.5"
        x=".75"
        y="6.75"
        stroke="#FFF"
        strokeWidth="1.5"
        rx="2"
      />
      <path
        fill="#FFF"
        d="M6.05 8.715a1.5 1.5 0 0 1 .778 2.783v1.672H5.28l-.002-1.668a1.5 1.5 0 0 1 .772-2.787z"
      />
      <path
        stroke="#FFF"
        strokeWidth="1.5"
        d="M2.74 6.804V3.985c-.063-1.393.633-2.09 2.09-2.09h2.917c.996.267 1.494.824 1.494 1.672v.578"
      />
    </g>
  </svg>
)
