import * as d3 from 'd3'
import * as React from 'react'
import * as FauxDOM from 'react-faux-dom'

import { DEFAULT_GRAPH_TITLE } from '../../messages'
import { IConfig, getChartConfig } from '../../services/chart-config'
import {
  renderLegend,
  renderLegendWrapper,
  renderTitle,
  renderYAxisLabels,
} from '../../services/hd3'
import { ITextBlockContent, getTextBlockConfig } from '../../services/hd3-text-block'
import { getScatterTitles, getSeriesTitles } from '../../services/series'

interface IProps {
  title: string
  titles: string[]
  variables: IDataSeries[]
  width: number
  height: number
  onClick: () => void
  readOnly: boolean
  hasYAxisLabels: boolean
  hasLegend: boolean
  isScatter: boolean
  onHeightUpdate: (height: number) => void
}

const computeTextPlacement = (
  config: IConfig,
  variables: IDataSeries[],
  title: string,
  titles: string[],
  hasLegend: boolean,
  hasYAxisLabels: boolean
) => {
  const content: SMap<any> = {}

  content.title = getTextBlockConfig([title], config.title)

  if (hasLegend) {
    content.legend = getTextBlockConfig(titles, config.legend)
  }

  if (hasYAxisLabels) {
    content.yAxisLabelLeft = getTextBlockConfig(
      [variableDataTypeForAxisAssignment(variables, 'left')],
      config.yAxisLabelLeft
    )

    content.yAxisLabelRight = getTextBlockConfig(
      [variableDataTypeForAxisAssignment(variables, 'right')],
      config.yAxisLabelRight
    )
  }
  return content
}

const variableDataTypeForAxisAssignment = (
  variables: IDataSeries[],
  axisAssignment: 'left' | 'right'
): string | null => {
  for (const variable of variables) {
    if (variable.axisAssignment === axisAssignment) {
      return variable.dataType
    }
  }

  // If not found and it can happen when we have variable[s] on the single side only,
  // take the first one or return null
  return variables[0]?.dataType
}

const getLegendYPosition = (config: IConfig, title: ITextBlockContent) =>
  config.title.margin.top + title.height + config.legend.margin.top

const getYAxisLabelPosition = (
  config: IConfig,
  legend: ITextBlockContent,
  legendYPosition: number
) => legendYPosition + legend.height + config.yAxisLabelLeft.margin.top

const Legend = (props: IProps) => {
  const [isHover, setHover] = React.useState(false)
  const svg: FauxDOM.Element = FauxDOM.createElement('g')
  const selection = d3.select(svg)
  const getTitles = props.isScatter ? getScatterTitles : getSeriesTitles
  const titles = getTitles(props.variables, props.titles)
  const title = props.title || DEFAULT_GRAPH_TITLE
  let totalHeight: number

  React.useLayoutEffect(() => {
    props.onHeightUpdate(totalHeight)
  })

  const g = props.readOnly
    ? selection
    : renderLegendWrapper(
        selection,
        props.width,
        props.height,
        isHover,
        () => setHover(true),
        () => setHover(false),
        props.onClick
      )

  const config = React.useMemo(
    () =>
      getChartConfig(
        props.width,
        props.variables.map(v => v.colorIndex),
        props.title
      ),
    [props.width, props.variables, props.title]
  )

  const content = React.useMemo(
    () =>
      computeTextPlacement(
        config,
        props.variables,
        title,
        titles,
        !props.readOnly,
        props.hasYAxisLabels
      ),
    [config, props.variables, title, titles, props.readOnly, props.hasYAxisLabels]
  )

  renderTitle(g, config.title, content.title)

  const legendYPosition = getLegendYPosition(config, content.title)
  const yAxisLabelPosition = props.readOnly
    ? 0
    : getYAxisLabelPosition(config, content.legend, legendYPosition)
  totalHeight = legendYPosition + config.chart.chartTopMargin

  if (!props.readOnly && props.hasLegend) {
    renderLegend(g, config.legend, content.legend, legendYPosition)
    totalHeight += yAxisLabelPosition - legendYPosition
  }

  if (props.hasYAxisLabels) {
    renderYAxisLabels(
      selection,
      config.yAxisLabelLeft,
      content.yAxisLabelLeft,
      yAxisLabelPosition,
      'left',
      props.width
    )
    renderYAxisLabels(
      selection,
      config.yAxisLabelRight,
      content.yAxisLabelRight,
      yAxisLabelPosition,
      'right',
      props.width
    )

    totalHeight +=
      config.yAxisLabelRight.margin.top + config.yAxisLabelRight.oneLineHeight
  }

  return <>{React.Children.map(svg.toReact(), c => c.props.children)}</>
}

export default React.memo(Legend)
