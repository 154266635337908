// eslint-disable max-len

import React from 'react'

const Undo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
    <g fill="#B6C2D4" fillRule="evenodd">
      <path
        fillRule="nonzero"
        d="M3.738 7.846a3 3 0 0 0 1.662.497c1.607 0 2.91-1.241 2.91-2.772C8.31 4.041 7.007 2.8 5.4 2.8a.498.498 0 0 0-.51.486c0 .268.228.485.51.485 1.044 0 1.89.806 1.89 1.8 0 .995-.846 1.8-1.89 1.8-.392 0-.765-.113-1.08-.322a.526.526 0 0 0-.71.121.471.471 0 0 0 .128.676z"
      />
      <path d="M5.4 1v4.571L3 3.286z" />
    </g>
  </svg>
)

export const UNDO_ICON = <Undo />
