import { createSelector } from 'reselect'

import {
  LINE_TYPES,
  applyOffset,
  getZoomRange,
  getZoomedSeries,
} from '../../../services/hd3'
import { isApplicable } from '../../../services/hd3-utils/scatter-plot'
import { IStackedConfig, getStackedConfig } from '../../../services/hd3-utils/stacked-bar'
import { getStatistics } from '../../../utils'
import * as common from './common'

export const isScatterSelector = createSelector(
  common.getSettings,
  common.getSeries,
  (settings, variables) => settings.isScatterPlot && isApplicable(variables)
)

export const endZoomDateSelector = createSelector(
  common.getSettings,
  settings => settings.endZoomDate
)
export const graphZoomSelector = createSelector(
  common.getSettings,
  settings => settings.graphZoom
)

export const offsetSeriesSelector = createSelector(common.getSeries, series =>
  series.map(applyOffset)
)

export const zoomSelector = createSelector(
  offsetSeriesSelector,
  isScatterSelector,
  endZoomDateSelector,
  graphZoomSelector,
  (variables, isScatter, endZoomDate, graphZoom) =>
    getZoomRange(variables, endZoomDate, graphZoom, isScatter)
)

export const zoomedSeriesSelector = createSelector(
  offsetSeriesSelector,
  zoomSelector,
  common.getGraphTypes,
  (variables, [startDate, endDate], graphTypes) => {
    if (variables.length === 0) {
      return []
    }
    return variables.map((series, index) =>
      getZoomedSeries(series, startDate, endDate, LINE_TYPES.includes(graphTypes[index]))
    )
  }
)

export const stackedConfigSelector = createSelector(
  zoomedSeriesSelector,
  zoomSelector,
  common.getGraphTypes,
  (variables, range, graphTypes): IStackedConfig => {
    const vars = variables.filter((_v, i) => graphTypes[i].includes('STACKED'))
    if (vars.length === 0) {
      return null
    }
    return getStackedConfig(vars, graphTypes, range)
  }
)

export const statsSelector = createSelector(
  zoomedSeriesSelector,
  (variables): SMap<number>[] =>
    variables.map(v => getStatistics(v.dataPoints.map(dp => dp.value)))
)
