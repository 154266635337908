import * as React from 'react'
import { bindBem } from '../../bem'
import { RadioGroup } from '../../components/RadioGroup'
import RadioOption from '../../components/RadioButton'
import { ActionsBar } from '../../components/Modal'
import { Button } from '../../components/Button'
import SeriesRow from './SeriesRow'
import TicksScaleForm from './TicksScaleForm'
import { SCALING_OPTIONS_DIALOG } from '../../messages'

import './ScalingOptionsDialog.scss'

export interface IStateProps {
  initialScallingOptions: { scale: IScale; assignments: AxisAssignment[] } | undefined
  variables: IDataSeries[]
  scale: IScale
  computedScale: IScale<IncrementalAxis>
}

export interface IActionProps {
  setScallingOptions: (scale: IScale, assignments: AxisAssignment[]) => void
}

export interface IProps {
  onClose: () => void
}

interface IState {
  showMore: boolean
  scale: IScale
  axisAssignments: AxisAssignment[]
}

export type Props = IStateProps & IActionProps & IProps

export class ScalingOptionsDialog extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props)
    const scaleTypes = new Set([props.scale.leftAxis.type, props.scale.rightAxis.type])
    this.state = {
      showMore: !scaleTypes.has('automatic') || scaleTypes.size > 1,
      scale: { ...props.scale },
      axisAssignments: props.variables.map(v => v.axisAssignment),
    }
  }
  render() {
    const { block, element } = bindBem('ScalingOptionsDialog')
    const { variables, computedScale } = this.props
    const { showMore, scale } = this.state

    return (
      <div className={block()}>
        <ActionsBar>
          <Button
            text={SCALING_OPTIONS_DIALOG.RESET_ALL}
            onClick={this.resetAll}
            size="small"
            style="light"
          />
          <Button
            text={SCALING_OPTIONS_DIALOG.APPLY}
            onClick={this.apply}
            size="small"
            style="dark"
          />
        </ActionsBar>
        <div className={element('Content')}>
          <div className={element('Section')}>
            <h4>{SCALING_OPTIONS_DIALOG.TYPE_OF_SCALE}</h4>
            <RadioGroup value={scale.type} onChange={this.setScaleType}>
              <RadioOption value="neither">{SCALING_OPTIONS_DIALOG.NEITHER}</RadioOption>
              <RadioOption value="uniscale">
                {SCALING_OPTIONS_DIALOG.UNISCALE}
              </RadioOption>
              <RadioOption value="global">{SCALING_OPTIONS_DIALOG.GLOBAL}</RadioOption>
              <RadioOption value="both">{SCALING_OPTIONS_DIALOG.BOTH}</RadioOption>
            </RadioGroup>
          </div>
          <div className={element('Section')}>
            <h4>{SCALING_OPTIONS_DIALOG.SERIES_ASSIGNMENT}</h4>
            {variables.map((s, i) => (
              <SeriesRow
                key={s.uuid}
                series={s}
                value={this.state.axisAssignments[i]}
                hasLabels={i === 0}
                onChange={axis => this.setAxisAssignment(i, axis)}
              />
            ))}
          </div>
          <Button
            text={
              showMore
                ? SCALING_OPTIONS_DIALOG.LESS_OPTIONS
                : SCALING_OPTIONS_DIALOG.MORE_OPTIONS
            }
            onClick={this.toggleShowMore}
            size="small"
            style="light"
            className={element('ShowMoreBtn')}
          />
          {showMore && (
            <div className="FormContainer">
              <TicksScaleForm
                title={SCALING_OPTIONS_DIALOG.LEFT_SCALE}
                axis={scale.leftAxis}
                onChange={axis => this.setAxis(axis, 'leftAxis')}
                computedAxis={computedScale.leftAxis}
              />
              <TicksScaleForm
                title={SCALING_OPTIONS_DIALOG.RIGHT_SCALE}
                axis={scale.rightAxis}
                onChange={axis => this.setAxis(axis, 'rightAxis')}
                disabled={this.isCommonScale()}
                computedAxis={computedScale.rightAxis}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  private isCommonScale = () => this.state.scale.type !== 'neither'

  private toggleShowMore = () => this.setState({ showMore: !this.state.showMore })

  private setAxis = (value: Axis, axis: 'leftAxis' | 'rightAxis') => {
    const scale = { ...this.state.scale, [axis]: value }
    if (this.isCommonScale()) {
      scale.rightAxis = value
    }
    this.setState({ scale })
  }

  private setScaleType = (type: IScale['type']) =>
    this.setState({ scale: { ...this.state.scale, type } })

  private setAxisAssignment = (index: number, axis: AxisAssignment) => {
    const [...axisAssignments] = this.state.axisAssignments
    axisAssignments[index] = axis
    this.setState({ axisAssignments })
  }

  private resetAll = () => {
    let newState: IState

    if (this.props.initialScallingOptions) {
      newState = {
        scale: this.props.initialScallingOptions.scale,
        showMore: false,
        axisAssignments: this.props.initialScallingOptions.assignments,
      }
    } else {
      newState = {
        scale: this.props.scale,
        showMore: false,
        axisAssignments: this.props.variables.map(v => v.axisAssignment),
      }
    }
    this.setState(newState)
  }

  private apply = () => {
    this.props.setScallingOptions(this.state.scale, this.state.axisAssignments)
    this.props.onClose()
  }
}
