import * as React from 'react'

import { bindBem } from '../bem'

import './RadioGroup.scss'

export interface IContextProps {
  selectedValue: string
  onChange: (v: string) => void
}
export const RadioContext = React.createContext<IContextProps>({
  selectedValue: '',
  onChange: undefined,
})

export interface IProps {
  value: string
  onChange: (value: string) => void
  children: any
  className?: string
}

export const RadioGroup = (props: IProps) => {
  const value = {
    selectedValue: props.value,
    onChange: props.onChange,
  }
  return (
    <RadioContext.Provider value={value}>
      <div className={`RadioGroup ${props.className || ''}`.trim()}>{props.children}</div>
    </RadioContext.Provider>
  )
}

export interface IOptionProps {
  value: string
  selected: boolean
  onChange: (value: string) => void
  disabled?: boolean
  toRight?: boolean
}

const RadioOption: React.FC<IOptionProps> = ({
  value,
  children,
  selected,
  toRight,
  onChange,
  disabled,
}) => {
  const { block, element } = bindBem('RadioOption')

  return (
    <div
      tabIndex={1}
      className={block({ selected, disabled, toRight })}
      onClick={() => !disabled && onChange(value)}
      data-value={value}
    >
      <span className={element('Label')}>{children}</span>
      <div className={element('Circle')} />
    </div>
  )
}

export const Option: React.FC<
  Pick<IOptionProps, 'value' | 'disabled' | 'toRight'>
> = props => (
  <RadioContext.Consumer>
    {({ selectedValue, onChange }: IContextProps) => (
      <RadioOption
        onChange={onChange}
        // eslint-disable-next-line react/prop-types
        selected={props.value === selectedValue}
        {...props}
      />
    )}
  </RadioContext.Consumer>
)

export const Separator: React.FC = () => <div className="Separator" />
