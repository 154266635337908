// eslint-disable max-classes-per-file
import * as React from 'react'
import { Portal } from 'react-portal'

import { bindBem } from '../../bem'
import { MoreOptionsButton } from './Buttons'

import './DropdownButton.scss'

interface IBtnProps {
  active: boolean
  onClick: () => void
  title?: string
}

interface IProps {
  buttonRenderer: React.FC<IBtnProps>
  stickTo: 'left' | 'right'
  title?: string
  top?: boolean
  children?: React.ReactNode
}

interface IState {
  isOpen: boolean
}

export class DropdownButton extends React.Component<IProps> {
  static defaultProps: Partial<IProps> = { stickTo: 'right' }
  state: IState = { isOpen: false }
  isCollapsed = false // collapsed refers to the sidebar state
  buttonRef = React.createRef<HTMLDivElement>()
  dropdownRef = React.createRef<HTMLDivElement>()

  handleClickOutside = (event: Event) => {
    if (!this.dropdownRef || !this.state.isOpen) {
      return
    }
    const root = this.dropdownRef.current
    const button = this.buttonRef.current
    const target = event.target as HTMLElement
    if (root && !root.contains(target) && button && !button.contains(target)) {
      this.closePortal()
    }
  }

  handleScroll = () => {
    if (this.state.isOpen) {
      this.setDropdownPosition()
    }
  }

  setDropdownPosition = () => {
    const buttonRect = this.buttonRef.current?.getBoundingClientRect()
    const dropdownRect = this.dropdownRef.current?.getBoundingClientRect()
    const windowHeight = window.innerHeight
    if (buttonRect && dropdownRect) {
      if (
        this.props.top ||
        buttonRect.top + buttonRect.height + dropdownRect.height > windowHeight
      ) {
        this.dropdownRef.current.style.top = buttonRect.top - dropdownRect.height + 'px'
      } else {
        this.dropdownRef.current.style.top = buttonRect.top + buttonRect.height + 'px'
      }
      this.dropdownRef.current.style.left = buttonRect.left + 'px'
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener('scroll', this.handleScroll, true)
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  openPortal = () => {
    this.setState({ isOpen: true }, this.setDropdownPosition)
    document.addEventListener('scroll', this.handleScroll, true)
    document.addEventListener('click', this.handleClickOutside, true)
  }

  closePortal = () => {
    this.setState({ isOpen: false })
    document.removeEventListener('scroll', this.handleScroll, true)
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  togglePortal = () => (this.state.isOpen ? this.closePortal() : this.openPortal())

  render = () => {
    const { block, element } = bindBem('DropdownButton')
    const { children, stickTo, title, top } = this.props
    return (
      <div
        className={block({
          isCollapsed: this.isCollapsed,
          [stickTo]: true,
          isTop: top,
        })}
        ref={this.buttonRef}
      >
        <this.Button
          active={this.state.isOpen}
          onClick={this.togglePortal}
          title={title}
        />
        {this.state.isOpen && (
          <Portal isOpened={this.state.isOpen}>
            <div
              className={element('Dropdown')}
              ref={this.dropdownRef}
              onClick={this.closePortal}
            >
              {children}
            </div>
          </Portal>
        )}
      </div>
    )
  }

  private Button = (props: IBtnProps) => {
    const Btn = this.props.buttonRenderer || MoreOptionsButton
    return <Btn {...props} />
  }
}

export class CollapsedDropdownButton extends DropdownButton {
  isCollapsed = true
}
