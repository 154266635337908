import * as React from 'react'

import { ADMIN } from '../messages'
import { Options } from './Sidebar/Options'

import './Status.scss'

const STATUS_PREFIXES: JSX.Element[] = [
  // eslint-disable-next-line react/jsx-key
  <div className="Inactive" />,
  // eslint-disable-next-line react/jsx-key
  <div className="Active" />,
]
interface IProps {
  value: boolean
  onSelect: (value: number) => void
  disabled?: boolean
  defaultValue?: string
}

const options = [ADMIN.STATUS.INACTIVE, ADMIN.STATUS.ACTIVE]

export const Status: React.FunctionComponent<IProps> = props => {
  const selectedOption = () => {
    if (props.value == null) {
      return -1
    }
    return Number(props.value)
  }

  return (
    <Options
      prefixes={STATUS_PREFIXES}
      options={options}
      selectedOption={selectedOption()}
      setSeriesOptions={props.onSelect}
      disabled={props.disabled}
      defaultValue={props.defaultValue}
    />
  )
}
