import * as React from 'react'

import { bindBem } from '../bem'
import { FooterAvatarMenu } from './AvatarMenu'

import PhoneIcon from 'static/phone.svg'
import { ICON_KEYBOARD } from './SVG/Keyboard'
import { TOOLTIPS } from '../messages'

import './SidebarFooter.scss'
import './AvatarMenu.scss'

export interface IProps {
  onHelpClick: () => void
  onShortcutsClick: () => void
}

export const SidebarFooter = (props: IProps) => {
  const { block, element } = bindBem('SidebarFooter')

  return (
    <nav className={`${block()} row between-xs middle-xs`}>
      <FooterAvatarMenu />
      <div className={element('VerticalSeparator')} />
      <div className={`${element('Buttons')} end-xs`}>
        <div>
          <button onClick={props.onHelpClick} title={TOOLTIPS.SUPPORT}>
            <img src={PhoneIcon} />
          </button>

          <button onClick={props.onShortcutsClick} title={TOOLTIPS.KEYBOARD_SHORTCUTS}>
            {ICON_KEYBOARD}
          </button>
        </div>
      </div>
    </nav>
  )
}
