import * as React from 'react'
import { bindBem } from '../../../bem'
import { USER_FORM } from '../../../messages'
import { Input, VerticalLabeled } from '../../Input'

import bin from '../../../static/bin.svg'

import './UsersBulkForm.scss'

interface IProps {
  users: Partial<IUser>[]
  errors: any
  onChange: (users: Partial<IUser>[]) => void
}

export const UsersBulkForm: React.FC<IProps> = ({ users, errors, onChange }) => {
  const { block, element } = bindBem('UsersBulkForm')

  const handleChange = async (index: number, user: Partial<IUser>) => {
    const newUsers = [...users]
    newUsers[index] = user

    onChange(newUsers)
  }

  const handleDelete = (index: number) => {
    const newUsers = users.filter((_user, i) => index !== i)

    onChange(newUsers)
  }

  const addAnother = () => {
    const emptyUser: Partial<IUser> = { username: '', email: '' }
    onChange([...users, emptyUser])
  }

  return (
    <div className={block()}>
      <div className={element('Table')}>
        {users.map((user, index) => (
          <FormRow
            focus={index + 1 === users.length}
            errors={errors?.[index]}
            key={index}
            user={user}
            index={index}
            onChange={handleChange}
            onDelete={handleDelete}
            onEnter={addAnother}
            labels={index === 0}
          />
        ))}
      </div>
      <div className={element('AddAnotherLink')} onClick={addAnother}>
        + Add another
      </div>
    </div>
  )
}

interface IFormRowProps {
  user: Partial<IUser>
  index: number
  labels: boolean
  errors: { [P in keyof IUser]: string }
  focus?: boolean
  onChange: (index: number, user: Partial<IUser>) => void
  onDelete: (index: number) => void
  onEnter: () => void
}

const FormRow: React.FC<IFormRowProps> = ({
  user,
  errors,
  index,
  labels,
  onDelete,
  onChange,
  onEnter,
  focus,
}) => {
  const handleChange = (obj: Partial<IUser>) => {
    onChange(index, { ...user, ...obj })
  }

  const renderInput = (
    name: 'username' | 'email',
    propagateEnterKey = false,
    focused = false
  ) => (
    <>
      <Input
        focus={focused}
        onKeyUp={event => event.keyCode === 13 && propagateEnterKey && onEnter?.()}
        onChange={e => handleChange({ [name]: e.target.value })}
        value={user[name]}
        error={!!errors?.[name]}
        placeholder={(USER_FORM.PLACEHOLDER as any)[name.toUpperCase()]}
      />
      {errors?.[name] && <div className={'Error'}>{errors[name]}</div>}
    </>
  )

  return (
    <div className="UserFormRow">
      <div className="Inputs">
        <div className="Cell">
          {labels ? (
            <VerticalLabeled label="NAME">
              {renderInput('username', false, focus)}
            </VerticalLabeled>
          ) : (
            renderInput('username', false, focus)
          )}
        </div>

        <div className="Cell">
          {labels ? (
            <VerticalLabeled label="EMAIL">
              {renderInput('email', true, false)}
            </VerticalLabeled>
          ) : (
            renderInput('email', true, false)
          )}
        </div>
      </div>

      <div className="Bin">
        <img src={bin} onClick={() => onDelete(index)} />
      </div>
    </div>
  )
}
